import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getApiClient} from "../util/auth";
import PrimaryButton from "./PrimaryButton";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function QuizResultModal (props) {
    const { t } = useTranslation();
    const api = getApiClient();
    const navigate = useNavigate();

    const [title, set_title] = useState('');
    const [cover_image, set_cover_image] = useState('');
    const [description, set_description] = useState('');
    const [button_text, set_button_text] = useState('')

    const navigateProfile = () => {
        api
            .get("/user/viewProfile")
            .then((response) => {
                switch (response.data?.user?.Role) {
                    case "Individual Learner":
                        navigate("/profile");
                        return;
                    case "Organization Learner":
                        navigate("/profile-company");
                        return;
                    case "Association Learner":
                        navigate("/profile-association");
                        return;
                    default:
                        return;
                }
            });
    };

    const have_evaluation_form = () => {
        return props?.is_created_evaluation_form;
    }

    useEffect(() => {
        if (props?.result_status === 'PASS') {
            set_title(t('Congratulations! You had pass the quiz!'));
            set_cover_image('/assets/images/passed.png');

            if (have_evaluation_form()) {
                set_description(t('You can continue to Evaluation now.'));
                set_button_text(t('TAKE ME TO EVALUATION'));
            } else {
                set_description('');
                set_button_text(t('GO TO PROFILE'));
            }
        } else if (props?.result_status === 'RETRY') {
            set_title(t(':( You have failed your quiz.'));
            set_description(t('No need to worry - you are welcome to retake the quiz.'));
            set_button_text(t('RETAKE QUIZ'));
            set_cover_image('/assets/images/failed_retake.png');
        } else if (props?.result_status === 'CANNOT_RETRY') {
            set_title(t(':( You have failed your quiz.'));
            set_description(t('You cannot retake the quiz anymore.'));
            set_cover_image('/assets/images/failed_cannot_retake.png');

            if (have_evaluation_form()) {
                set_button_text(t('TAKE ME TO EVALUATION'));
            } else {
                set_button_text(t('GO TO PROFILE'));
            }
        }
    }, [props?.result_status, props?.course, props?.quiz_remaining_remaining_attempts, props?.is_created_evaluation_form]);

    const handleClickActionButton = (event) => {
        event.preventDefault();

        if (props?.result_status === 'PASS') {
            if (have_evaluation_form()) {
                navigate(`/evaluation-form?course_id=${props?.course?.id}`);
            } else {
                navigateProfile();
                return;
            }
        } else if (props?.result_status === 'RETRY') {
            window.location.reload();
        } else if (props?.result_status === 'CANNOT_RETRY') {
            if (have_evaluation_form()) {
                navigate(`/evaluation-form?course_id=${props?.course?.id}`);
            } else {
                navigateProfile();
                return;

            }
        }
    }

    const handleViewCourseButton = (event) => {
        event.preventDefault();

        navigate("/courses/show-module?course_id=" + props?.course?.id);
    }

    return (
        <>
            <Modal
                show={props.is_show_modal}
                onHide={() => {
                    props.on_modal_hide();
                }}
                backdrop="static"
                centered={true}
                scrollable={true}
            >
                <Modal.Body className={"p-0"}>
                    <Container className={"p-3"}>
                        <Row className={"mb-5"}>
                            <Col>
                                <img
                                    src={cover_image}
                                    className={"img-fluid"}
                                />
                            </Col>
                        </Row>
                        <Row className={"mb-5"}>
                            <Col>
                                <div className={"h2"}>{title}</div>
                                <div>{description}</div>
                            </Col>
                        </Row>
                        {
                            props?.result_status !== 'PASS'
                            &&
                            <Row className={"mb-5"}>
                                <Col>
                                    {
                                        props?.result_status === 'RETRY'
                                        &&
                                        <p>{t('Note: We encourage you to review the course content and training videos before attempting the quiz once more. You may do so by clicking the button below to navigate to the course video page.')}</p>
                                    }
                                    <PrimaryButton className={"w-100"} onClick={handleViewCourseButton}>{t('View Course Video')}</PrimaryButton>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <PrimaryButton className={"w-100"} onClick={handleClickActionButton}>{button_text}</PrimaryButton>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}
