import React from "react";
import PhoneInput from "react-phone-input-2";

export default function PhoneDisplay(props){
    const number = props.number;

    if(!number){
        return '--';
    }
    
    return <PhoneInput 
        disabled
        disableDropdown
        buttonStyle={{display: 'none'}}
        inputStyle={{border:"none", padding: 0, height: 'auto', width: '80%', fontSize: '16px'}}
        specialLabel={""}
        value={number}
    />
}