import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiClient } from "../util/auth";
import EyeIcon from "../components/EyeIcon";
import EditIcon from "../components/EditIcon";
import { parseDbStringToObj } from "../util/lang";

export default function AdminInsightsAndTrendsActionColumn(props) {
    const navigate = useNavigate();
    const insights_and_trends = props.insights_and_trends;
    const isSuperAdmin = props.isSuperAdmin;

    const [checkbox, setCheckbox] = useState(insights_and_trends?.Status === 'Published');

    const api = getApiClient();
    const handleChange = (event) => {
        event.preventDefault();

        let alert_text = `Confirm to publish the insights and trends?`;
        if (insights_and_trends?.Status === 'Published') {
            alert_text = 'Confirm unpublish? Once unpublished, public cannot view this insights and trends.';
        }

        if (window.confirm(alert_text)) {
            api
                .post("/admin/switchInsightsAndTrendsStatus", {
                    insights_and_trends_id: insights_and_trends.id,
                })
                .then((response) => {
                    setCheckbox(!checkbox);
                    props.checkbox_updated();
                })
                .catch((error) => {
                    alert(error.response.data.message);
                    console.log(error);
                });
        }
    };

    const handleEdit = (event) => {
        if(insights_and_trends?.Status === 'Published') {
            alert('If you want to edit the insights and trends, please unpublish the insights and trends first!');
            return;
        } else {
            navigate("/cms/admin/content_management/edit/insights_and_trends/" + insights_and_trends.id);
        }
    }

    return (
        <div className={"d-flex align-items-center"}>
            <button
                className={"btn p-0"}
                onClick={() => {
                    navigate("/cms/admin/content_management/view/insights_and_trends/" + insights_and_trends.id);
                }
                }
                title={"view"}
            >
                <EyeIcon />
            </button>
            {!isSuperAdmin
            ?
            <button
                style={{ marginRight: "3px" }}
                className={"btn p-0"}
                onClick={handleEdit}
                title={"Edit"}
            >
                <EditIcon />
            </button>
            :
            ''
            }

            {!isSuperAdmin
            ?
            <div className="form-check form-switch m-0" style={{ minHeight: "auto" }}>
                <input className="form-check-input" type="checkbox" role="switch" onChange={handleChange} checked={checkbox} />
            </div>
            :
            ''
            }
            {!isSuperAdmin
            ?
            <div>{insights_and_trends.Status === 'Draft' ? 'Draft' : (checkbox ? 'Published' : 'Archived')}</div>
            :
            ''
            }
        </div>
    );
}
