import React from "react";
import { useTranslation } from 'react-i18next';

export default function EduLevel(){
    const { t } = useTranslation();

    return <>
        <option value="" default hidden>--</option>
        <option value={"Primary School"}>{t('Primary School')}</option>
        <option value={"High School"}>{t('High School')}</option>
        <option value={"High Diploma"}>{t('High Diploma')}</option>
        <option value={"Associate Degree"}>{t('Associate Degree')}</option>
        <option value={"Bachelor’s Degree"}>{t('Bachelor’s Degree')}</option>
        <option value={"Master’s Degree"}>{t('Master’s Degree')}</option>
        <option value={"Doctoral or Professional Degree"}>{t('Doctoral or Professional Degree')}</option>
        <option value={"Other"}>{t('Other')}</option>
    </>;
}