import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiClient } from "../util/auth";
import EyeIcon from "../components/EyeIcon";
import EditIcon from "../components/EditIcon";
import { parseDbStringToObj } from "../util/lang";

export default function CourseActionColumn(props) {
    const navigate = useNavigate();
    const course = props.course;
    const [checkbox, setCheckbox] = useState(course?.Course_Status === 'Published');

    const user = localStorage.getItem("user");
    const user_id = parseDbStringToObj(user)?.id;

    const api = getApiClient();
    const handleChange = (event) => {
        event.preventDefault();

        let alert_text = 
        `Confirm to publish the course? Once it is published, below contents CANNOT be updated or added anymore even if it is reverted to unpublished status:
        • New Module
        • New Class
        • Class Materials
        • Videos
        • Photos
        • Quiz Setting
        • Evaluation Form`;
        if (course?.Course_Status === 'Published') {
            alert_text = 'Confirm unpublish? Once unpublished the learner cannot view the course.';
        }

        if (window.confirm(alert_text)) {
            api
                .post("/user/switchCourseArchiveStatus", {
                    course_id: course.id,
                })
                .then((response) => {
                    setCheckbox(!checkbox);
                    props.checkbox_updated();
                })
                .catch((error) => {
                    alert(error.response.data.message);
                    console.log(error);
                });
        }
    };

    const handleEdit = (event) => {
        if(course?.Course_Status === 'Published') {
            alert('If you want to edit the course, please unpublish the course first!');
            return;
        } else {
            navigate("/cms/edit/course/" + course.id);
        }
    }

    const isTerminated = () => {
        return course?.Is_Terminated === 'Yes';
    }

    return (
        <div className={"d-flex align-items-center"}>
            {course.Course_Owner == user_id
            ?
            <button
                className={"btn p-0"}
                onClick={() => {
                    navigate("/cms/course/" + course.id);
                }
                }
                title={"view"}
            >
                <EyeIcon />
            </button>
            :
            ''
            }
            {course.Course_Owner == user_id && !isTerminated()
            ?
            <button
                style={{ marginRight: "3px" }}
                className={"btn p-0"}
                onClick={handleEdit}
                title={"Edit"}
            >
                <EditIcon />
            </button>
            :
            ''
            }
            {/* {course.Course_Owner == user_id
            ?
            <button className={"btn p-0"} title={"Copy"}>
                <i className="bi bi-clipboard"></i>
            </button>
            :
            ''
            } */}

            {course.Course_Owner == user_id && !isTerminated()
            ?
            <div className="form-check form-switch m-0" style={{ minHeight: "auto" }}>
                <input className="form-check-input" type="checkbox" role="switch" onChange={handleChange} checked={checkbox} />
            </div>
            :
            ''
            }
            {course.Course_Owner == user_id && !isTerminated()
            ?
            <div>{course.Course_Status === 'Draft' ? 'Draft' : (checkbox ? 'Published' : 'Archived')}</div>
            :
            ''
            }
        </div>
    );
}
