import Paragraph from "./Paragraph";
import LineLimiter from "./LineLimiter";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getApiClient } from "../util/auth";
import { getTextByCode } from "../util/lang";
import { useTranslation } from 'react-i18next';
import moment from "moment";

export default function MyCourses(props) {
  const { t } = useTranslation();

  const [courses, setCourses] = useState([]);
  const [page_number, set_page_number] = useState(1);
  const [page_size, set_page_size] = useState(15);

  const CURRENT_LANGUAGE = props?.CURRENT_LANGUAGE ?? 'EN';

  const navigate = useNavigate();

  const api = getApiClient();

  useEffect(() => {
    api
      .post(props.view_courses_endpoint)
      .then((response) => {
        // original
        let allStatusCourses = response.data.courses;

        // filter with Complete_Status to only show in progress courses
        let inProgressCourses = allStatusCourses.filter((course) => {
          return course.Complete_Status === 'Not Finish'
        })
        setCourses(inProgressCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let lastUpdate;
  const searchCourses = (e) => {
    let s = e.target.value;
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 500) {
        if (s === "") {
          let cd = document.getElementsByClassName("course-div");
          for (let i = 0; i < cd.length; i++) {
            cd[i].classList.remove("hidden");
          }
        }
        else {
          let cd = document.getElementsByClassName("course-div");
          for (let i = 0; i < cd.length; i++) {
            if (cd[i].textContent.toLowerCase().includes(s.toLowerCase())) {
              cd[i].classList.remove("hidden");
            }
            else {
              cd[i].classList.add("hidden");
            }
          }
        }
      }
    }, 500);
  };

  if (courses.length === 0) {
    return <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
      <div className="row align-items-center mb-3">
        <div className="col">
          <div className={"fw-bold font-size-20px text-decoration-underline"}>
            {t('My Courses')}
          </div>
          <div>
            <div className="my-5">{t('You have not enrolled in any courses yet.')}</div>
            <button className={"btn bg-eb6201 text-white"} onClick={() => { navigate('/courses') }}>
              {t('Explore Courses')}
            </button>
          </div>
        </div>

      </div>
    </div>;
  }

  const calculatePagesCount = (pageSize, totalCount) => {
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  return (
    <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
      <div className="row align-items-center mb-3 flex-column flex-sm-row">
        <div className="col">
          <div className={"fw-bold font-size-20px text-decoration-underline"}>
            {t('My Courses')}
          </div>
        </div>
        <div className="col">
          <div className="input-group">
            <span
              className="input-group-text bg-white border-end-0"
              id="search"
            >
              <i className="bi bi-search"></i>
            </span>
            <input
              type="text"
              className="form-control border-start-0"
              placeholder={t("Search")}
              onChange={searchCourses}
            />
          </div>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <div className="col">{t('In Progress')}</div>
      </div>
      <div className="row mb-3">
        {courses.length === 0 ? (
          <Paragraph>{t('You have not registered for any courses yet.')}</Paragraph>

        ) : (
          courses.slice((page_number - 1) * page_size, page_number * page_size).map((c) => <CourseCard course={c} key={c.id} CURRENT_LANGUAGE={CURRENT_LANGUAGE}></CourseCard>)
        )}
      </div>
      <div className="row align-items-center">
        <div className="col mb-3 profile-mycourse-showing">
          {t('Showing')}
          <button className={"btn profile-mycourse-showing"} onClick={() => { set_page_size(15) }}>15</button>
          |
          <button className={"btn profile-mycourse-showing"} onClick={() => { set_page_size(30) }}>30</button>
        </div>
        <div className="col-auto text-lg-right text-center mb-3 profile-mycourse-showing">
          <button className={"btn profile-mycourse-showing"} onClick={() => { set_page_number(Math.max(1, page_number - 1)) }}>&lt; {t('Previous')}</button>

          {
            [...Array(calculatePagesCount(page_size, courses.length)).keys()].map((index) => (index + 1)).map((page) => (
              <button className={"btn profile-mycourse-showing"} onClick={() => { set_page_number(page) }}>{page}</button>
            ))
          }

          <button className={"btn profile-mycourse-showing"} onClick={() => { set_page_number(Math.min(calculatePagesCount(page_size, courses.length), page_number + 1)) }}>{t('Next')} &gt;</button>
        </div>
      </div>
    </div>
  );
}

function CourseCard(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [course, setCourse] = useState(props.course);
  const off_the_shelf_date = moment(course?.Off_The_Shelf_Date).format('Y-MM-DD');

  const CURRENT_LANGUAGE = props?.CURRENT_LANGUAGE;

  const handleViewClass = () => {
    navigate(`/courses/show/${course.id}`);
  };

  return (
    <div className="col-md-4 col-sm-6 mb-3 course-div">
      <div
        className="d-flex h-100 flex-column"
        style={{ boxShadow: "0px 5px 5px #0000001A", borderRadius: "15px", overflow: "hidden" }}
      >
        <img
          alt="Course_Photo"
          className="img-fluid w-100"
          src={
            course.Course_Photo ?? "/assets/images/home_courses_thumbnail_2.png"
          }
          style={{
            aspectRatio: "3 / 2",
            objectFit: "cover",
          }}
        />
        <div className="p-3 d-flex h-100 flex-column">
          <div
            style={{ fontSize: "16px", lineHeight: "24px", marginTop: "20px", marginBottom: "10px", minHeight: "48px" }}
            className="fw-bold limit-text limit-text-2"
          >
            {getTextByCode(course.Course_Name, CURRENT_LANGUAGE)}
          </div>
          <div>
            <a href="#" style={{ color: "#266599", textDecoration: "none", fontSize: "14px" }}>
              {course.Trainer}
            </a>
          </div>
          <LineLimiter style={{ fontSize: "14px", minHeight: "86px", height:"86px" }}>{getTextByCode(course.Course_Descriptions, CURRENT_LANGUAGE)}</LineLimiter>
          <div className="" style={{ color: "#266599", fontSize: "14px", marginTop: "10px" }}>
            {t('EXPIRED ON')} {off_the_shelf_date}
          </div>
        </div>
        <div>
          <button
            className={"w-100 btn bg-eb6201 text-white"}
            style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
            onClick={handleViewClass}
          >
            {t('View')}
          </button>
        </div>
      </div>
    </div>
  );
}
