export default function LineLimiter(props) {
  const style = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line'
  };

  return (
    <div style={{ ...style, ...props.style }} className={props.className ? props.className : "line-clamp-4"}>
      {props.children}
    </div>
  );
}
