import React from "react";

export default function NumberOfAssociationMember(){

    return <>
        <option value="" default hidden>--</option>
        <option value="1-1000">1-1000</option>
        <option value="1001-5000">1001-5000</option>
        <option value="5001-30000">5001-30000</option>
        <option value="30001-100000">430001-100000</option>
        <option value="100001 or over">100001 or over</option>
    </>;
}