import Layout from "../components/Layout";
import React, { useEffect, useState } from "react";
import Section from "../components/Section";
import H2 from "../components/H2";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getApiClient } from "../util/auth";
import PrimaryButton from "../components/PrimaryButton";
import { getTextByCode } from "../util/lang";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../getCurrentLanguage";
import { t } from "i18next";

export default function EvaluationForm() {
    const navigate = useNavigate();
    const api = getApiClient();
    const { t } = useTranslation();
    const role = localStorage.getItem("role");

    //setup param for course id
    const [searchParams, setSearchParams] = useSearchParams();
    const [questions, setQuestions] = useState([]);
    const [longQuestions, setLongQuestions] = useState([]);
    const [question_answers, set_question_answers] = useState({});
    const [view_course_response, set_view_course_response] = useState();

    // Get question from API
    useEffect(() => {
        const params = {
            course_id: searchParams.get("course_id"),
        };
        api.post("/user/view_evaluation_form", params)
            .then((response) => {
                // Short questions
                const q = response.data.evaluation_questions.filter(q => q.Question_Type === "Short");
                setQuestions(q);

                // Long questions
                const lq = response.data.evaluation_questions.filter(q => q.Question_Type === "Long")
                    .filter((question) => {
                        return question?.Question;
                    });
                setLongQuestions(lq);
            })
            .catch((error) => {
                if (error.response?.data?.message) {
                    alert(t(error.response.data.message));
                }
                navigate({
                    pathname: "/courses/show-module",
                    search: createSearchParams({
                        course_id: searchParams.get("course_id"),
                    }).toString(),
                });
            });

        api.post(role === "Individual Learner" ? "/individual_learner/view_course" : role === "Organization Learner" ? "/organization_learner/view_course" : "/association_learner/view_course", { course_id: searchParams.get("course_id") })
            .then((response) => {
                set_view_course_response(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // design state , index of questions into array of answers

    const handleSubmit = async (event) => {
        event.preventDefault();

        for (const question of questions) {
            if (!question_answers?.[question.id]) {

                alert(t('Please answer all questions!'));
                return;
            }
        }

        for (const long_question of longQuestions) {
            if (!question_answers?.[long_question.id]) {
                alert(t('Please answer all questions!'));
                return;
            }
        }

        const do_evaluation_parameters = [];
        for (const evaluation_question_id in question_answers) {
            do_evaluation_parameters.push({
                evaluation_question_id: evaluation_question_id,
                learner_answer: question_answers[evaluation_question_id],
            });
        }

        api.post('/user/doEvaluation', {
            course_id: searchParams.get("course_id"),
            learner_answers: do_evaluation_parameters,
        })
            .then((response) => {
                alert(t('Thank you for your time.'));
                navigate(role === "Individual Learner" ? '/profile' : role === "Organization Learner" ? '/profile-company' : '/profile-association');
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    };

    return (
        <Layout>
            <Section>
                <Container className="max-width-1304">
                    <Row className="">
                        <Col>
                        <div className={"mb-2"}>{t('Evaluation')}</div>
                            <H2 style={{ fontSize: 40 }} className={"mt-0 mb-4"}>
                                {getTextByCode(view_course_response?.course?.[0]?.Course_Name, CURRENT_LANGUAGE)}
                            </H2>
                            <label className={"font-size-14px"}>{t('(Learner will answer the evaluation form by rating from 1 - 7 & NA, with 7 being very satisfied and 1 being very dissatisfied.)')}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <form class={"eval-form"} onSubmit={handleSubmit}>
                                <div style={{ overflow: "auto", position: "relative" }}>
                                    <div className={"arrow-right font-size-16px fw-bolder text-eb6201"} style={{ minWidth: "650px" }}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Col className="col-3 col-md-6">{t('Training Course Evaluation Questionnaire')}</Col>
                                            <Col className="col-9 col-md-6">
                                                <Row>
                                                    <Col></Col>
                                                    <Col></Col>
                                                    <Col></Col>
                                                    <Col></Col>
                                                    <Col></Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col>1</Col>
                                                    <Col>2</Col>
                                                    <Col>3</Col>
                                                    <Col>4</Col>
                                                    <Col>5</Col>
                                                    <Col>6</Col>
                                                    <Col>7</Col>
                                                    <Col>{t('N/A')}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    {
                                        questions.map((question, index) => (
                                            <Question question_index={index + 1} question={question} key={index} question_answers={question_answers} set_question_answers={set_question_answers} />
                                        ))
                                    }
                                    {
                                        longQuestions.length > 0
                                        &&
                                        <div className={"p-3"}>
                                            <H2 style={{ fontSize: 20 }}>{t('Additional Question')}</H2>
                                            {
                                                longQuestions.map((question, index) => (
                                                    <LongQuestion question_index={index + 1} question={question} key={index} question_answers={question_answers} set_question_answers={set_question_answers} />
                                                ))
                                            }
                                        </div>
                                    }
                                </div>

                                <div className={"mt-5"}>
                                    <PrimaryButton
                                        type={"submit"}
                                    >
                                        {t('SUBMIT')}
                                    </PrimaryButton>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </Layout>
    );
}

function Question(props) {
    const { t } = useTranslation();

    const question = props.question;
    const question_answers = props.question_answers;
    const set_question_answers = props.set_question_answers;
    const question_index = props.question_index;
    const [render, rerender] = useState(false);

    const update_question_answers = (event, question_id) => {
        question_answers[question_id] = event.target.value;
        set_question_answers(question_answers);
        rerender(!render);
    };

    return <>
        <div className={"mb-2"} key={question.id} style={{ minWidth: "650px" }}>
            <Row>
                <Col className="col-3 col-md-6"><div className={"font-size-16px text-4a4a4a"}>{question_index}. {t(question.Question)}</div></Col>
                <Col className="col-9 col-md-6">
                    <Row>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="1" checked={question_answers?.[question.id] === "1"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="2" checked={question_answers?.[question.id] === "2"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="3" checked={question_answers?.[question.id] === "3"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="4" checked={question_answers?.[question.id] === "4"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="5" checked={question_answers?.[question.id] === "5"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="6" checked={question_answers?.[question.id] === "6"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="7" checked={question_answers?.[question.id] === "7"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                        <Col><input className="form-check-input" name={`short-questions-${question.id}`} type="radio" value="N/A" checked={question_answers?.[question.id] === "N/A"} onChange={(e) => update_question_answers(e, question.id)} /></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </>
}

function LongQuestion(props) {
    const question = props.question;
    const question_answers = props.question_answers;
    const set_question_answers = props.set_question_answers;
    const question_index = props.question_index;
    const [render, rerender] = useState(false);

    const update_question_answers = (event, question_id) => {
        question_answers[question_id] = event.target.value;
        set_question_answers(question_answers);
        rerender(!render);
    };

    return <>
        <div className={"mb-3"} key={question.id}>
            <div className={"fw-bold"}><label>{question_index}. {question.Question}</label></div>
            <label className={"font-size-14px"}>{t('Feedback')}</label>
            <textarea
                className="form-control font-size-14px"
                rows="5"
                style={{ resize: "none" }}
                placeholder={t("Please type in here.")}
                value={question_answers?.[question.id] ?? ''}
                onChange={(e) => update_question_answers(e, question.id)}
            />
        </div>
    </>
}
