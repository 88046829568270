import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import H2 from "../../components/H2";
import ClassVideo from "../../components/ShowModule/ClassVideo";
import ClassItem from "../../components/ShowModule/ClassItem";
import { Collapse } from "bootstrap/dist/js/bootstrap.esm";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getApiClient } from "../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../util/lang";
import { get_filename_from_url } from "../../services/url-service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

const sort_class_indicator = (a, b) => {
  if (a.ModuleId < b.ModuleId) {
    return -1;
  } else if (a.ModuleId == b.ModuleId) {
    return a.Class_Sequence - b.Class_Sequence;
  } else {
    return 1;
  }
};

const get_viewed_class_responses_by_module_id = async (modules, course_id) => {
  let viewed_class_responses_by_module_id = {};
  for (const module of modules) {
    const viewed_class_response = await getApiClient().post('/user/getViewedClassId', {
      course_id: course_id,
      module_id: module.id,
    });
    viewed_class_responses_by_module_id[module.id] = viewed_class_response?.data;
  }
  return viewed_class_responses_by_module_id;
}

export default function CourseShowModule() {
  const [activeCollapses, setActiveCollapses] = useState([]);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");

  const course_id = searchParams.get("course_id");
  const class_id = searchParams.get("class_id");

  const [viewingClass, setViewingClass] = useState("");

  const [course, setCourse] = useState();
  const [modules, setModules] = useState();
  const [classes, setClasses] = useState();
  const [view_quiz_response, set_view_quiz_response] = useState();
  const [is_created_evaluation_form, set_is_created_evaluation_form] = useState(false);
  const [is_created_quiz, set_is_created_quiz] = useState(false);
  const [viewed_class_responses, set_viewed_class_responses] = useState({});

  // the position of current class among all classes
  // we assume classes should be sorted by : ModuleId ASC, Class.Sequence ASC
  const [currentPosition, setCurrentPosition] = useState(0);


  /**
   * TODO rewrite needed :
   * - use useParam instead of useSearchParams so we can define and name the param in route
   * - Error handling when class is empty
   *
   * Here is what's going on :
   * - before arriving at this page, user click a `Course`
   * - no information about which module / class to view
   * - so we try to get the very first class available for this class
   */
  const api = getApiClient();
  useEffect(() => {
    getAllInCourse();

    if (!class_id) {
      getFirstClassByCourseId();
    }

    api.post("/user/view_quiz", { course_id: course_id })
      .then((response) => {
        set_view_quiz_response(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchParams]);

  const fetch_viewed_class_api = async (modules, course_id) => {
    const responses = await get_viewed_class_responses_by_module_id(modules, course_id);
    set_viewed_class_responses(responses);
  };

  const fetch_api_to_check_all_viewed = async (modules, course_id) => {
    let viewed_class_responses_by_module_id = {};
    for (const module of modules) {
      const viewed_class_response = await api.post('/user/getViewedClassId', {
        course_id: course_id,
        module_id: module.id,
      });
      viewed_class_responses_by_module_id[module.id] = viewed_class_response?.data;
    }

    for (const class_item of classes) {
      if (!viewed_class_responses_by_module_id?.[class_item.ModuleId]?.viewed_class_id.includes(class_item.id)) {
        return false;
      }
    }

    return true;
  };

  const handleClickEvaluationForm = async (event) => {
    event.preventDefault();

    const is_all_viewed = await fetch_api_to_check_all_viewed(modules, course_id);
    if (is_all_viewed) {
      api.post("/user/view_evaluation_form", {
        course_id: course_id,
      })
        .then((response) => {
          navigate(`/evaluation-form?course_id=${course_id}`);
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            alert(t(error.response.data.message));
          }
        });
    } else {
      alert(t('Please complete the course before taking evaluation form.'));
    }
  };

  /**
   * Get all Classes, all Modules in the Course
   * specified by course_id
   */
  const getAllInCourse = () => {
    const params = {
      course_id: course_id,
    };

    let api_view_course_url = '';
    if(role === "Organization Learner") {
      api_view_course_url = '/organization_learner/view_course';
    } else if(role === 'Individual Learner') {
      api_view_course_url = '/individual_learner/view_course';
    } else if(role === 'Association Learner') {
      api_view_course_url = '/association_learner/view_course';
    }

    api.post(api_view_course_url, params)
      .then(async (response) => {
        setCourse(response?.data?.course?.[0]);
        setModules(response?.data?.modules);
        set_is_created_evaluation_form(response?.data?.is_created_evaluation_form === "Yes");
        set_is_created_quiz(response?.data?.is_created_quiz === "Yes");

        let res_classes = response?.data?.classes;
        res_classes.sort(sort_class_indicator);
        setClasses(res_classes);

        await fetch_viewed_class_api(response?.data?.modules, course_id);

        if (class_id) {
          const target_classes = res_classes.filter((class_item) => class_item.id === parseInt(class_id));
          if (target_classes.length > 0) {
            const target_class = target_classes[0];

            setCurrentPosition(res_classes.indexOf(target_class));
            setViewingClass(target_class);
            /**
             * Toggling the module is very confusing, when page loads, it should expand the module the class
             * is currently in. For some reason this is NOT managed in the state, but uses
             * HTML ID + css + class manipulation to achieve.
             * <Collapse> is a bootstrap.esm module
             */
            if (activeCollapses.length === 0) {
              onClickCollapse('', `course-module-${target_class.ModuleId}`);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
        alert(error.response.data.message);
      });
  }

  /**
   * Find the course, and get ONE class only
   * It is the first class in the course, with the lowest Module ID and lowest class sequence
   */
  const getFirstClassByCourseId = () => {
    const params = {
      course_id: searchParams.get("course_id"),
    };

    let api_view_first_class_url = '';
    let api_view_class_url = '';
    if(role === "Organization Learner") {
      api_view_first_class_url = '/organization_learner/view_first_class_by_course_id';
      api_view_class_url = '/organization_learner/view_class';
    } else if(role === 'Individual Learner') {
      api_view_first_class_url = '/individual_learner/view_first_class_by_course_id';
      api_view_class_url = '/individual_learner/view_class';
    } else if(role === 'Association Learner') {
      api_view_first_class_url = '/association_learner/view_first_class_by_course_id';
      api_view_class_url = '/association_learner/view_class';
    }

    api.post(api_view_first_class_url, params)
      .then((response) => {
        const first_class_in_course = response.data.class;
        const params2 = {
          course_id: first_class_in_course.CourseId,
          module_id: first_class_in_course.ModuleId,
          class_id: first_class_in_course.id
        };

        api.post(api_view_class_url, params2)
          .then((view_class_res) => {
            setViewingClass(view_class_res.data.class[0]);

            if (activeCollapses.length === 0) {
              onClickCollapse('', `course-module-${view_class_res.data.class[0].ModuleId}`);
            }
          });
      })
      .catch((error) => {
        console.log(error.response);
        alert(t(error.response.data.message));
      });
  }

  const onClickCollapse = (event, html_id) => {
    // html_id looks like this : `course-module-${module.id}`
    const element = document.querySelector("#" + html_id);

    if (element.classList.contains("show")) {
      setActiveCollapses(
        activeCollapses.filter((filter_id) => filter_id !== html_id)
      );
    } else {
      setActiveCollapses([html_id, ...activeCollapses]);
    }

    const collapse = new Collapse(element);
    collapse.toggle();
  };

  const handleSelectClass = (cid) => {
    document.documentElement.scrollTo(0, 0);
    setSearchParams({ course_id: course_id, class_id: cid });
  }

  return (
    <Layout>
      <div className={"border-top"} style={{ marginBottom: 28 }}></div>

      <Container className="max-width-1304">
        <Row>
          <div className={"col-12 col-md-9"}>
            <Row>
              <Col>
                <div>{course?.Course_Code}&nbsp;{getTextByCode(course?.Course_Name, CURRENT_LANGUAGE)}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <H2 style={{ fontSize: "40px" }}>
                  {t('Class')} {viewingClass?.Class_Sequence}:&nbsp;{getTextByCode(viewingClass?.Class_Name, CURRENT_LANGUAGE)}
                </H2>
                <div style={{ fontSize: "20px" }} className={"text-535353"}>
                </div>
              </Col>
            </Row>
            {
              viewingClass?.Class_Video
              &&
              <Row className="mb-3">
                <Col>
                  <ClassVideo
                    key={viewingClass.id}
                    viewing_class={viewingClass}
                    viewed_class_responses={viewed_class_responses}
                    saved_view_class_status={() => {
                      fetch_viewed_class_api(modules, course_id);
                    }}
                  />
                </Col>
              </Row>
            }

            {viewingClass.Class_Video === '' &&
              <Row className="my-5">
                <Col>
                  <span>{t('No video uploaded for this class.....')}</span>
                </Col>
              </Row>
            }
            <Row className="align-items-center">
              <Col>
                <H2 style={{ fontSize: "20px" }} className={"m-0"}>
                  By {course?.Trainer}
                </H2>
                <div style={{ fontSize: "20px", whiteSpace: "pre-line", verticalAlign: "bottom" }} className={"text-535353"}>
                  {course?.Trainer_Organization} - {course?.Trainer_Description}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <H2 style={{ fontSize: "28px" }}>{t('Class Description')}</H2>
                <div style={{ width: "100%" }} className="hkqaa-divider" />
              </Col>
            </Row>
            <Row className="font-size-14px">
              <Col xs={"auto"}>
                <div style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>{getTextByCode(viewingClass.Class_Descriptions, CURRENT_LANGUAGE)}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={"auto"}>
                <H2 style={{ fontSize: "28px" }}>{t('Material For Download')}</H2>
                <div style={{ width: "100%" }} className="hkqaa-divider" />
              </Col>
            </Row>
            <Row className="mb-3">
              {
                (() => {
                  const materials = parseDbStringToObj(viewingClass?.Class_Materials);
                  if (!Array.isArray(materials)) {
                    return '';
                  }

                  return materials.map((href) => (
                    <Col sm={6} md={3} className="text-center mb-3" key={href}>
                      <div className={"border"}>
                        <div className={"p-3"}>
                          <div className={"mb-2"}>
                            <img
                              src={"/assets/images/file.svg"}
                              className={"img-fluid"}
                              alt=""
                            />
                          </div>
                          <div className={"font-size-14px"} style={{ wordWrap: 'break-word' }}>{decodeURIComponent(escape(decodeURIComponent( get_filename_from_url(href).substring(37) )))}</div>
                        </div>
                        <div>
                          <a href={href} download target={"_blank"} rel="noreferrer">
                            <button
                              className={"w-100 btn bg-eb6201 text-white fw-bold"}
                            >
                              {t('Download')}
                            </button>
                          </a>
                        </div>
                      </div>
                    </Col>
                  ));
                })()
              }
            </Row>
          </div>
          <Col>
            {modules?.map((module) => (
              <Row className={"mb-3"} key={module.id}>
                <Col>
                  <div>
                    <button
                      className={
                        activeCollapses.includes("course-module-" + module.id)
                          ? "btn w-100 text-start shadow-none border bg-4a4a4a text-white rounded-0 d-flex justify-content-between"
                          : "btn w-100 text-start shadow-none border rounded-0 d-flex justify-content-between"
                      }
                      style={{
                        borderColor: "#4a4a4a",
                      }}
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={(event) => {
                        onClickCollapse(event, "course-module-" + module.id);
                      }}
                    >
                      <div>{t('Module')} {module?.Module_Sequence}: {getTextByCode(module?.Module_Title, CURRENT_LANGUAGE)}</div>
                      <div>
                        {activeCollapses.includes("course-module-" + module.id)
                          ? "-"
                          : "+"}
                      </div>
                    </button>
                  </div>
                  <div className="collapse" id={"course-module-" + module.id}>
                    <div className="card card-body rounded-0 text-4a4a4a border-top-0 p-0">
                      {classes?.filter((item) => { return item.ModuleId === module.id })?.map((classItem) => {
                        return <ClassItem
                          key={classItem.id}
                          classItem={classItem}
                          viewingClass={viewingClass}
                          viewed_class_responses={viewed_class_responses}
                          saved_view_class_status={() => {
                            fetch_viewed_class_api(modules, course_id);
                          }}
                          handleSelectClass={handleSelectClass} />
                      }
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
            {
              is_created_quiz
              &&
              <div className={"mb-3"}>
                <button className={"w-100 btn bg-4a4a4a text-white fw-bold rounded-0"}
                  onClick={
                    async () => {
                      const is_all_viewed = await fetch_api_to_check_all_viewed(modules, course_id);

                      if (view_quiz_response?.can_retake === false) {
                        alert(t('No more remaining attempts.'));
                      } else {
                        if (is_all_viewed) {
                          navigate(`/course/${course_id}/quiz`);
                        } else {
                          alert(t('Please complete the course before taking quiz.'));
                        }
                      }
                    }
                  }>
                  {t('QUIZ')}
                </button>
              </div>
            }
            {
              is_created_evaluation_form
              &&
              <div>
                <button
                  className={"w-100 btn bg-4a4a4a text-white fw-bold rounded-0"}
                  onClick={handleClickEvaluationForm}
                >
                  {t('EVALUATION FORM')}
                </button>
              </div>
            }
          </Col>
        </Row>

      </Container>

      <Container className="max-width-1304 mb-5">
        <Row className="align-items-end">
          <Col xs={9}>
            <Row>
              <Col>
                <div className={"hkqaa-divider"} style={{ borderWidth: 1 }} />
              </Col>
            </Row>
            <Row className="fw-bold font-size-18px">
              {classes && currentPosition !== 0 &&
                <Col className="cursor-pointer"
                  onClick={() => handleSelectClass(classes[currentPosition - 1].id)}
                >
                  &lt; {t('Previous Class')}
                </Col>
              }

              {classes && currentPosition < classes.length - 1 &&
                <Col
                  onClick={() => handleSelectClass(classes[currentPosition + 1].id)}
                  className="text-end cursor-pointer"
                >
                  {t('Next Class')} &gt;
                </Col>
              }
            </Row>
          </Col>

        </Row>
      </Container>
    </Layout>
  );
}
