import Layout from "../components/Layout";
import Section from "../components/Section";
import React from "react";
import Banner from "../components/Banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrivacyPolicyComponent from "../components/PrivacyPolicy";
import {useTranslation} from "react-i18next";
import CURRENT_LANGUAGE from "../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner.png">
          {t('Privacy Policy')}
      </Banner>
      <Section>
        <Container>
          <Row>
            <Col>
              <PrivacyPolicyComponent lang={CURRENT_LANGUAGE} />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}
