/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CopyRightLogin from "../components/CopyRightLogin";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Signup() {
  const [category, setCategory] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();


  const handleSubmit = (event) => {
    event.preventDefault();
    if(category === dictionary_value["Individual"]) {navigate("/signup-individual")}
    else if(category === dictionary_value["Organization"]) {navigate("/signup-organization")}
    else if(category === dictionary_value["Trainer"]) {navigate("/signup-trainer")}
    else if(category === dictionary_value["Association"]) {navigate("/signup-association")}

  }

  const dictionary_key = {
    individual: t("Individual"),
    organization: t("Organization"),
    trainer: t("Trainer/Academy"),
    association: t("Association")
  };
  const dictionary_value = {
    Individual: "individual",
    Organization: "organization",
    Trainer: "trainer",
    Association: "association"
  };

  return (
    <div
      className="signup-bg vh-100 d-flex flex-column"
      style={{
        backgroundImage:
          "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png), url(/assets/images/home_background_insights_1.png)",
        backgroundPosition: "0px 5%, 95% 5%, 90% 70%",
        backgroundSize: "auto auto, auto auto, 300px",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundAttachment: "scroll, scroll",
      }}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <Container
          style={{
            maxWidth: "450px",
            boxShadow: "0px 3px 25px #0000001F",
            padding: 40,
          }}
        >
          <Row>
            <Col>
              <div className="text-center mb-3">
                <Link to="/">
                  <img src="/assets/images/logo.png" width={175} alt="" />
                </Link>
              </div>
              <div className="d-flex font-size-24px text-4a4a4a mb-3">
                <a className="flex-grow-1 text-reset text-center text-decoration-none" href="#" style={{ borderBottom: "3px solid #eb6201" }}>{t('Sign up')}</a>
                <Link
                  className="flex-grow-1 text-reset text-center text-decoration-none"
                  to="/login"
                  style={{ borderBottom: "2px solid #dee2e6" }}
                >
                  {t('Log in')}
                </Link>
              </div>
              <form
                onSubmit={handleSubmit}
              >
                <div className="mb-5">
                  <label className="form-label fw-bold">{t('Account category')}</label>
                  <div className="dropdown">
                    <button
                      className="w-100 btn dropdown-toggle text-a2a2a2 dropdown-icon-none d-flex justify-content-between"
                      id={"signup-category"}
                      type="button"
                      style={{ border: "1px solid #A2A2A2" }}
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}
                    >
                      {category === null
                        ? t("Choose your category")
                        : dictionary_key[category]}{" "}
                      <i className="bi bi-chevron-right" />
                    </button>
                    <ul
                      className={
                        showDropdown
                          ? "dropdown-menu right-0 show"
                          : "dropdown-menu right-0"
                      }
                      style={{ right: 0 }}
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setShowDropdown(false);
                            setCategory(dictionary_value["Individual"]);
                          }}
                        >
                          {t('Individual')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setShowDropdown(false);
                            setCategory(dictionary_value["Organization"]);
                          }}
                        >
                          {t('Organization')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setShowDropdown(false);
                            setCategory(dictionary_value["Trainer"]);
                          }}
                        >
                          {t('Trainer/Academy')}
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setShowDropdown(false);
                            setCategory(dictionary_value["Association"]);
                          }}
                        >
                          {t('Association')}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  type="submit"
                  className={"w-100 btn bg-eb6201 text-white fw-bold"}
                >
                  {t('Next')}
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      <CopyRightLogin></CopyRightLogin>
    </div>
  );
}
