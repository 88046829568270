import React, { useState, useEffect } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import { getApiClient } from "../util/auth";
import { useTranslation } from 'react-i18next';

export default function CourseProgress(props) {
    const { t } = useTranslation();

    // const user = props.user;
    const [yearly_target_completion_rate_response, set_yearly_target_completion_rate_response] = useState();
    const [graph_percentage, set_graph_percentage] = useState(0);

    const api = getApiClient();
    useEffect(() => {
        api.post("/user/getYearlyTargetCompletionRate", { user_id: props.user?.id })
            .then((response) => {
                set_yearly_target_completion_rate_response(response.data);

                if (response.data?.number_of_completed_course && response.data?.number_of_yearly_target) {
                    let calculated_graph_percentage = (response.data?.number_of_completed_course /  response.data?.number_of_yearly_target) * 100;
                    
                    set_graph_percentage(calculated_graph_percentage > 100 ? 100 : calculated_graph_percentage);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.user]);

  return (
    <div className="align-items-center border p-3">
        <div style={{color:'#EB6101'}}>
            {t('Completion Rate')} : <span className="fs-3">{yearly_target_completion_rate_response?.number_of_completed_course}</span>/{yearly_target_completion_rate_response?.number_of_yearly_target}
        </div>
        <div className="my-4" style={{width:'100%', backgroundColor:'#C9C9C9',height:32,borderRadius:14,overflow:'hidden'}}>
            <div style={{width:`${graph_percentage}%`, backgroundColor:'#EB6101',height:32,borderRadius:14}}></div>
        </div>
        <div className="fs-6" style={{color:'#4a4a4a'}}>
            <div>
                {t('Yearly Target')}: {yearly_target_completion_rate_response?.number_of_yearly_target} {t('courses')}
            </div>
            <div>
                {t('Training Completion Rate')}: {yearly_target_completion_rate_response?.number_of_completed_course}/{yearly_target_completion_rate_response?.number_of_yearly_target}
            </div>
      </div>
    </div>
  );
}
