import LayoutCms from "../../../../components/LayoutCms";
import CourseForm from "../../../../components/CourseForm";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import CourseTabNavigation from "../../../../components/CourseTabNavigation";
import ShadowContainer from "../../../../components/ShadowContainer";
import { getApiClient } from "../../../../util/auth";
import { parseDbStringToObj } from "../../../../util/lang";

export default function AdminMembershipViewCourse(props) {
    const params = useParams();
    const id = params.course_id;
    const api = getApiClient();

    const navigate = useNavigate();

    const role = localStorage.getItem("role");

    const [data, setData] = useState({});

    const [course_name_en, set_course_name_en] = useState('');
    const [course_name_tc, set_course_name_tc] = useState('');
    const [course_name_sc, set_course_name_sc] = useState('');
    const [course_master_language, set_course_master_language] = useState('EN');

    const [user, setUser] = useState(null);

    const [course_owner, set_course_owner] = useState(null);

    //console.log(props);

    const presentJson = (input_string) => {
        if (!input_string) {
            return '';
        }

        try {
            const json = JSON.parse(input_string);
            const array = [];

            if (json.EN || json.English) {
                array.push('English');
            }
            if (json.TC) {
                array.push('繁體');
            }
            if (json.Cantonese) {
                array.push('廣東話');
            }
            if (json.SC) {
                array.push('简体');
            }
            if (json.Mandarin) {
                array.push('普通话');
            }

            return array.join(', ');
        } catch (e) {
            return '';
        }
    };

    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            navigate('/');
            return;
        }

        api.get("/user/viewProfile")
            .then((response) => {
                setUser(response.data.user);
            })
            .catch((error) => {
                //TODO error handling
                console.log(error);
            });

        api.post("/admin/viewCourse", {
            course_id: id,
        })
            .then((response) => {
                setData(response.data);

                set_course_owner(response.data.course_owner[0]);

                set_course_name_en(parseDbStringToObj(response.data.course[0].Course_Name).EN);
                set_course_name_tc(parseDbStringToObj(response.data.course[0].Course_Name).TC);
                set_course_name_sc(parseDbStringToObj(response.data.course[0].Course_Name).SC);
                set_course_master_language(response.data.course[0].Course_Master_Language);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getVideoUrlFromAPI = (preview_video) => {
        getApiClient()
          .post("/user/previewVideo", {
            s3_url: preview_video,
          })
          .then((response) => {
            window.open(response?.data?.s3_signed_url, '_blank', 'noreferrer');
          })
          .catch((error) => {
            console.log(error?.response?.data?.message ?? error);
          })
    }

    return (
        <LayoutCms route={'membership.viewCourses'}>
            <div className={"row align-items-center"}>
                <div className={"col-7"}>
                    <div className={"text-secondary h1"}>View Course</div>
                    <div className={"text-adb5bd"}>View selected course.</div>
                </div>
            </div>

            <CourseTabNavigation activeNavItem={"course"} readOnly={true} />

            <ShadowContainer>
                <div className="row">
                    <label className="text-5c5c5c col-sm-2 col-form-label">
                        Course Code
                    </label>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Course Code"}
                            readOnly={true}
                            value={data?.course?.[0]?.Course_Code}
                        />
                    </div>
                </div>
            </ShadowContainer>

            <CourseForm
                data={data}
                readOnlyForAll={true}
                SubmitButton=''
                CourseMasterLanguage={() => (
                    <div className="col-sm-4">
                        {
                            (() => {
                                if(data?.course?.[0]?.Course_Master_Language === 'EN') {
                                    return 'English'
                                } else if(data?.course?.[0]?.Course_Master_Language === 'TC') {
                                    return '廣東話'
                                } else if(data?.course?.[0]?.Course_Master_Language === 'SC') {
                                    return '普通话'
                                }
                            })()
                        }
                    </div>
                )}
                CourseFee={() => (
                    <div className="col-sm-4">
                        {
                            (() => {
                                if (data?.course?.[0]?.Course_Fee === 'Free') {
                                    return 'Free'
                                }

                                if(course_owner?.Role === 'Trainer/Academy' || course_owner?.Role === 'Association') {
                                    return 'Pay $' + data?.course?.[0]?.Course_Fee_Amount;
                                }
                            })()
                        }
                    </div>
                )}
                MaterialLanguage={() => (
                    <div className="col-sm-4">
                        {presentJson(data?.course?.[0]?.Material_Language)}
                    </div>
                )}
                Audio={() => (
                    <div className="col-sm-4">
                        {presentJson(data?.course?.[0]?.Video_Language_Audio)}
                    </div>
                )}
                Subtitle={() => (
                    <div className="col-sm-4">
                        {presentJson(data?.course?.[0]?.Video_Language_Subtitle)}
                    </div>
                )}
                PreviewVideo={() => (
                    <div className="mb-3 row">
                        <div className="col-sm-6">
                            <div className={"text-5c5c5c mb-3"}>Preview Video</div>
                            {
                                data?.course?.[0]?.Preview_Video &&
                                <button 
                                    type="button" 
                                    onClick={() => { 
                                        getVideoUrlFromAPI(data?.course?.[0]?.Preview_Video) 
                                    }}
                                >
                                    Preview Video
                                </button>
                            }
                        </div>
                    </div>
                )}
                CoursePhoto={() => (
                    <div className="mb-3 row">
                        <div className="col-sm-6">
                            <div className={"text-5c5c5c mb-3"}>Course Photo</div>
                            {
                            data?.course?.[0]?.Course_Photo
                            ?
                            <img
                                className="img-fluid"
                                src={data?.course?.[0]?.Course_Photo}
                                alt="Course_Photo"
                                width="300px"
                                height="300px"
                            />
                            :
                            ''
                            }
                        </div>
                    </div>
                )}
                OfferCertificate={() => (
                    <div className="col-sm-1">
                        {
                            (() => {
                                if(data?.course?.[0]?.Certificate_Offered === 'Yes') {
                                    return 'Yes'
                                } else if(data?.course?.[0]?.Certificate_Offered === 'No' || data?.course?.[0]?.Certificate_Offered === null) {
                                    return 'No'
                                }
                            })()
                        }
                    </div>
                )}
                Categories={() => (
                    <div className="col-sm-4">
                        {
                            (() => {
                                if (!data?.course?.[0]?.Course_Categories) {
                                    return '';
                                }

                                const categories_object = JSON.parse(data?.course?.[0]?.Course_Categories);
                                return Object.values(categories_object).join(', ');
                            })()
                        }
                    </div>
                )}
                Industries={() => (
                    <div className="col-sm-4">
                        {
                            (() => {
                                if (!data?.course?.[0]?.Industries) {
                                    return '';
                                }

                                const industries_object = JSON.parse(data?.course?.[0]?.Industries);
                                return Object.values(industries_object).join(', ');
                            })()
                        }
                    </div>
                )}
                IsPublic={() => (
                    <div className="col-sm-4">
                        {
                            (() => {
                                if (data?.course?.[0]?.Is_Public === 'False') {
                                    return 'Members Only';
                                } else if(data?.course?.[0]?.Is_Public === 'True') {
                                    return 'Public';
                                }
                            })()
                        }
                    </div>
                )}
                course_name_en={course_name_en} 
                course_name_tc={course_name_tc} 
                course_name_sc={course_name_sc} 
                course_master_language={course_master_language} 
                user={course_owner}
                IsHideSaveCancelButton={true}
            />
        </LayoutCms>
    );
}
