import LayoutCms from "../../../components/LayoutCms";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getApiClient } from "../../../util/auth";
import UploadNewBR from "../../../components/UploadNewBR";
import CmsEditProfContactInfo from "../../../components/Cms/EditProfile/ContactInfoTab";
import CmsEditProfAccountInfo from "../../../components/Cms/EditProfile/AccountInfoTab";
import CmsEditProfAboutUs from "../../../components/Cms/EditProfile/AboutUsTab";
import CmsEditProfCert from "../../../components/Cms/EditProfile/CertificateTab";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import { parseDbStringToObj } from "../../../util/lang";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Industry from "../../../components/Options/Industry";
import Country from "../../../components/Options/Country";
import NumberOfAssociationMember from "../../../components/Options/NumberOfAssociationMember";
import UploadBox from "../../../components/UploadBox";
import PreviewBox from "../../../components/PreviewBox";

export default function AssociationProfileEditCms() {
  const [user, setUser] = useState(null);

  const [role, setRole] = useState("");

  const [association_logo, setAssociationLogo] = useState();

  const [association_name_eng, set_association_name_eng] = useState("");
  const [association_name_tc, set_association_name_tc] = useState("");
  const [association_name_sc, set_association_name_sc] = useState("");

  // Address
  const [address_eng, set_address_eng] = useState("");
  const [address_tc, set_address_tc] = useState("");
  const [address_sc, set_address_sc] = useState("");

  // City
  const [city_eng, set_city_eng] = useState("");
  const [city_tc, set_city_tc] = useState("");
  const [city_sc, set_city_sc] = useState("");

  // Province
  const [province_eng, set_province_eng] = useState("");
  const [province_tc, set_province_tc] = useState("");
  const [province_sc, set_province_sc] = useState("");

  // Country
  const [general_country, set_general_country] = useState("");

  const [business_registration, set_business_registration] = useState("");
  const [no_of_member, set_no_of_member] = useState(null);
  const [preferred_language, set_preferred_language] = useState("EN");
  const [association_website, set_association_website] = useState("");

  const [industry, set_industry] = useState('');

  //3
  const [personal_email_2nd, set_personal_email_2nd] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  //4
  const [about_desc_en, set_about_desc_en] = useState("");
  const [about_desc_tc, set_about_desc_tc] = useState("");
  const [about_desc_sc, set_about_desc_sc] = useState("");
  const [association_photo, set_association_photo] = useState("");

  //5
  const [logo_show, set_logo_show] = useState();
  const [name_show, set_name_show] = useState();

  const navigate = useNavigate();

  const api = getApiClient();
  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let res_user = response.data.user;

        if(response.data.user.Role !== 'Association') {
          alert('User not authorized! You will go back to Home Page.');
          navigate('/');
          return;
        }

        setRole(res_user.Role);

        //Conact Person
        res_user.Contact_Person_First_Name = parseDbStringToObj(res_user.Contact_Person_First_Name);
        res_user.Contact_Person_Last_Name = parseDbStringToObj(res_user.Contact_Person_Last_Name);

        // CONTACT - Address City Province Country (not general)
        res_user.Contact_Address = parseDbStringToObj(res_user.Contact_Address);
        res_user.Contact_City = parseDbStringToObj(res_user.Contact_City);
        res_user.Contact_Province = parseDbStringToObj(res_user.Contact_Province);
        res_user.Contact_Country = parseDbStringToObj(res_user.Contact_Country);

        setUser(res_user);

        set_general_country(parseDbStringToObj(res_user.General_Country).EN);

        setAssociationLogo(res_user.Association_Logo);

        const association_name = JSON.parse(res_user.Association_Name);
        set_association_name_eng(association_name?.EN ?? '');
        set_association_name_tc(association_name?.TC ?? '');
        set_association_name_sc(association_name?.SC ?? '');

        const address = JSON.parse(res_user.General_Address);
        set_address_eng(address?.EN ?? '');
        set_address_tc(address?.TC ?? '');
        set_address_sc(address?.SC ?? '');

        const city = JSON.parse(res_user.General_City);
        set_city_eng(city?.EN ?? '');
        set_city_tc(city?.TC ?? '');
        set_city_sc(city?.SC ?? '');

        const province = JSON.parse(res_user.General_Province);
        set_province_eng(province?.EN ?? '');
        set_province_tc(province?.TC ?? '');
        set_province_sc(province?.SC ?? '');


        set_business_registration(res_user.Business_Registration);
        set_no_of_member(res_user.No_of_Member);
        set_preferred_language(res_user.Preferred_Language);
        set_association_website(res_user.Association_Website);
        set_industry(res_user.Industry);

        //3
        set_personal_email_2nd(res_user.Personal_Email_2nd);
        setCreatedAt(moment(res_user?.createdAt)?.format('DD MMM YYYY'));

        //4
        const about_desc = JSON.parse(res_user.About_Us_Descriptions);
        set_about_desc_en(about_desc?.EN ?? '');
        set_about_desc_tc(about_desc?.TC ?? '');
        set_about_desc_sc(about_desc?.SC ?? '');
        set_association_photo(res_user?.About_Us_Association_Photo);

        //5
        if (res_user.Certificate_Association_Logo_Show) {
          set_logo_show(res_user.Certificate_Association_Logo_Show);
        } else {
          set_logo_show(0);
        }
        if (res_user.Certificate_Association_Name_Show) {
          set_name_show(res_user.Certificate_Association_Name_Show);
        } else {
          set_name_show(0);
        }
      })
      .catch((error) => {
        //TODO error handling
        console.log(error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const param = {
      association_logo: association_logo,
      association_name: {
        EN: association_name_eng,
        SC: association_name_sc,
        TC: association_name_tc,
      },
      association_email: user.Association_Email,
      general_address: { EN: address_eng, SC: address_sc, TC: address_tc },
      general_city: { EN: city_eng, SC: city_sc, TC: city_tc },
      general_province: { EN: province_eng, SC: province_sc, TC: province_tc },

      general_country: { EN: general_country, SC: "簡體國家", TC: "繁體國家" },

      business_registration: business_registration,
      no_of_member: no_of_member,
      preferred_language: preferred_language,
      association_website: association_website,
      industry: industry,

      //2
      salutation: user.Salutation,
      title: user.Title,
      department_1: user.Department_1,
      mobile_number: user.Mobile_Number,
      fax_number: user.Fax_Number,

      // contact_person_last_name: user.Contact_Person_Last_Name,
      contact_person_first_name: user.Contact_Person_First_Name,
      contact_person_last_name: user.Contact_Person_Last_Name,

      contact_address: user.Contact_Address,
      contact_city: user.Contact_City,
      contact_province: user.Contact_Province,
      contact_country: user.Contact_Country,

      //3
      personal_email_2nd: personal_email_2nd,

      //4
      about_us_description: { EN: about_desc_en, SC: about_desc_sc, TC: about_desc_tc },
      about_us_association_photo: association_photo,

      //5
      certificate_association_logo_show: logo_show,
      certificate_association_name_show: name_show,
    };

    //console.log(param);
    api
      .post("/association/edit_profile", param)
      .then(function (response) {
        alert("Saved successfully.");
        navigate("/cms/association-profile");
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  };

  const [tab, setTab] = useState(1);

  const liClass = "me-4";
  const liClassHighlight = "border-bottom border-width-2 me-4 border-bottom-eb6201 -mb-2px";

  const linkClass = "text-adb5bd text-decoration-none fw-bold";
  const linkClassHightlight = "text-eb6201 text-decoration-none fw-bold";

  if (!user) {
    return <>Loading...</>;
  } else {
    return (
      <LayoutCms route={'profile.edit'}>
        <form onSubmit={handleSubmit}>
          <div className={"position-relative"}>
            <div className={"text-secondary font-size-20px fw-700"}>Edit Profile</div>
            <div className={"text-adb5bd"}>{user.Username}</div>

            <div className={"my-3"}>
              <ul
                className={"d-flex p-0 border-bottom border-width-2 cms-edit-profile-headers"}
                style={{ listStyle: "none", flexFlow: "wrap" }}
              >
                <li className={tab === 1 ? liClassHighlight : liClass}>
                  <Link
                    className={tab === 1 ? linkClassHightlight : linkClass}
                    onClick={() => setTab(1)}
                    to="#"
                  >
                    General Information
                  </Link>
                </li>
                <li className={tab === 2 ? liClassHighlight : liClass}>
                  <Link
                    className={tab === 2 ? linkClassHightlight : linkClass}
                    onClick={() => setTab(2)}
                    to="#"
                  >
                    Contact Information
                  </Link>
                </li>
                <li className={tab === 3 ? liClassHighlight : liClass}>
                  <Link
                    className={tab === 3 ? linkClassHightlight : linkClass}
                    onClick={() => setTab(3)}
                    to="#"
                  >
                    Account Information
                  </Link>
                </li>
                <li className={tab === 4 ? liClassHighlight : liClass}>
                  <Link
                    className={tab === 4 ? linkClassHightlight : linkClass}
                    onClick={() => setTab(4)}
                    to="#"
                  >
                    About Us
                  </Link>
                </li>
                <li className={ tab === 5 ? liClassHighlight : liClass }>
                  <Link
                    className={ tab === 5 ? linkClassHightlight : linkClass}
                    onClick={ ()=> setTab(5) }
                    to="#"
                  >
                    Certificate
                  </Link>
                </li>
              </ul>
            </div>
            {tab === 1 ?
              <ShadowContainer>
                <div className="mb-3 row">
                  <div className="col-sm-6">
                    <div className={"text-5c5c5c mb-3"}>{"Upload association logo"}</div>
                    <UploadBox
                      onUploaded={(api_responses) => { setAssociationLogo(api_responses?.[0]?.urls?.[0]) }}
                      width_ratio={1}
                      height_ratio={1}
                      tolerance={0.1}
                      allowImageEdit
                    />
                  </div>
                </div>
                {
                  association_logo
                  &&
                  <div className="mb-3 row">
                    <div className="col-sm-6">
                      <PreviewBox url={association_logo} onDelete={() => { setAssociationLogo(null); }} />
                    </div>
                  </div>
                }


                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Association Name<span className={"text-danger"}>*</span>
                  </label>
                  <Col sm={4}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Association Name"}
                      value={association_name_eng}
                      onChange={(e) => set_association_name_eng(e.target.value)}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                  Association Name (繁)
                  </label>
                  <Col sm={4}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Association Name (繁)"}
                      value={association_name_tc}
                      onChange={(e) => set_association_name_tc(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                  Association Name (简)
                  </label>
                  <Col sm={4}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Association Name (简)"}
                      value={association_name_sc}
                      onChange={(e) => set_association_name_sc(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address
                  </label>
                  <Col sm={4}>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={address_eng}
                        onChange={(e) => set_address_eng(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={city_eng}
                        onChange={(e) => set_city_eng(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={province_eng}
                        onChange={(e) => set_province_eng(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address (繁)
                  </label>
                  <Col sm={4}>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={address_tc}
                        onChange={(e) => set_address_tc(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={city_tc}
                        onChange={(e) => set_city_tc(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={province_tc}
                        onChange={(e) => set_province_tc(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3 row">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address (简)
                  </label>
                  <div className="col-sm-4">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={address_sc}
                        onChange={(e) => set_address_sc(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={city_sc}
                        onChange={(e) => set_city_sc(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={province_sc}
                        onChange={(e) => set_province_sc(e.target.value)}
                      />
                    </div>

                  </div>
                </div>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Country
                  </label>
                  <div className="col-sm-4">
                    <div className="mb-3">
                      <select className="form-select"
                        value={general_country}
                        onChange={(e) => set_general_country(e.target.value)}
                      >
                        <option default hidden>--</option>
                        <Country lang="EN" />
                      </select>
                    </div>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Business Registration<span className={"text-danger"}>*</span>
                  </label>
                  <Col>
                    <PrimaryButton>
                      <a href={business_registration}
                        target="_blank" rel="noopener noreferrer"
                        className={"text-reset"}
                        onChange={(e) => set_business_registration(e.target.value)}
                        required={true}
                      >
                        View BR
                      </a>
                    </PrimaryButton>
                    <UploadNewBR
                      business_registration={business_registration}
                      set_business_registration={set_business_registration}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Industry
                  </label>
                  <Col sm={4}>
                    <select
                      className="form-select"
                      onChange={(e) => set_industry(e.target.value)}
                      value={industry}
                    >
                      <Industry />
                    </select>
                  </Col>
                </Row>
                <div className="mb-3 row">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    No. of Member
                  </label>
                  <Col sm={4}>
                    <select
                      className="form-select"
                      onChange={(e) => set_no_of_member(e.target.value)}
                      value={no_of_member}
                    >
                      <NumberOfAssociationMember />
                    </select>
                  </Col>
                </div>
                {/* <div className="mb-3 row">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Preferred Language
                  </label>
                  <div className="col-sm-4">
                    <select
                      className="form-select"
                      value={preferred_language}
                      onChange={(e) => set_preferred_language(e.target.value)}
                    >
                      <option value="EN">English</option>
                      <option value="TC">繁體中文</option>
                      <option value="SC">简体中文</option>
                    </select>
                  </div>
                </div> */}
                <div className="mb-3 row">
                  <label className="text-5c5c5c col-sm-2 col-form-label">
                    Association Website
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Association Website"}
                      value={association_website}
                      onChange={(e) => set_association_website(e.target.value)}
                    />
                  </div>
                </div>
              </ShadowContainer>
              : ''
            }

            {tab === 2 ?
              <CmsEditProfContactInfo
                user={user} setUser={setUser}
              ></CmsEditProfContactInfo> : ''}

            {tab === 3 ?
              <CmsEditProfAccountInfo
                personal_email_2nd={personal_email_2nd}
                set_personal_email_2nd={set_personal_email_2nd}
                createdAt={createdAt}
              >
              </CmsEditProfAccountInfo> : ''}

            {tab === 4 ?
              <CmsEditProfAboutUs
                role={role}
                about_desc_en={about_desc_en} set_about_desc_en={set_about_desc_en}
                about_desc_tc={about_desc_tc} set_about_desc_tc={set_about_desc_tc}
                about_desc_sc={about_desc_sc} set_about_desc_sc={set_about_desc_sc}
                association_photo={association_photo} set_association_photo={set_association_photo}
              ></CmsEditProfAboutUs> : ''}

            {tab === 5 ?
              <CmsEditProfCert
                logo={logo_show}
                setLogo={set_logo_show}
                name={name_show}
                setName={set_name_show}
              ></CmsEditProfCert> : ''}


            <div
              className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
              style={{ left: 240 }}
            >
              <div className={"d-flex"}>
                <div className={"me-3"}>
                  <PrimaryButton type={"submit"}>Save</PrimaryButton>
                </div>
                <div className={"me-3"}>
                  {/** We hidden the Certificate tab, so if certificate re-open, the below number need to change to 5 **/}
                  {tab < 5 ?
                    <PrimaryButton type={"button"}
                      onClick={
                        () => {
                          if (tab < 5) {
                            setTab(tab + 1);
                          }
                        }
                      }>Next</PrimaryButton>
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </form>
      </LayoutCms>
    );
  }
}
