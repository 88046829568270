import LayoutCms from "../../../components/LayoutCms";
import React, { useEffect, useState } from "react";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import TrainerCourseActionColumn from "../../../components/TrainerCourseActionColumn";
import EditIcon from "../../../components/EditIcon";
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { getApiClient } from "../../../util/auth";

export default function Index() {
  const api = getApiClient();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");

  const [allCourses, setAllCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState(allCourses);

  //for selected option
  const [filterBy, setFilterBy] = useState('All');
  const [draftCourses, setDraftCourses] = useState([]);
  const [publishedCourses, setPublishedCourses] = useState([]);
  const [archiveCourses, setArchiveCourses] = useState([]);
  const [expiredCourses, setExpiredCourses] = useState([]);

  const fetch_view_own_courses = () => {
    if(role !== 'Trainer/Academy') {
      alert('User not authorized! You will go back to Home Page.');
      if(role === null || role === '') {
        navigate('/login');
      } else {
        navigate('/');
      }
      return;
    }

    api
      .post("/user/view_own_courses") //no param needed for this API
      .then((response) => {
        setAllCourses(response.data.courses);
        setFilteredCourses(response.data.courses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch_view_own_courses();
  }, []);

  const handle_checkbox_updated = () => {
    fetch_view_own_courses();
  };

  const columns = [
    {
      name: 'Course Code',
      selector: row => row.Course_Code,
      sortable: true,
    },
    {
      name: 'Course Title',
      cell: (row) => {
        if(row.Course_Name) {
          const courseName = JSON.parse(row.Course_Name);
          return (<div>
            <div><span title={courseName.EN}>{courseName.EN}</span></div>
            <div><span title={courseName.TC}>{courseName.TC}</span></div>
            <div><span title={courseName.SC}>{courseName.SC}</span></div>
          </div>);
        }
      },
    },
    {
      name: 'Expiry Date',
      selector: row => row.Off_The_Shelf_Date,
      format: row => row.Off_The_Shelf_Date ? moment(row.Off_The_Shelf_Date).format('DD MMM YYYY') : '',
      sortable: true,
    },
    {
      name: 'Fees',
      selector: (row) => {
        if(row.Course_Fee_Amount) {
          return (<div>HK$ {row.Course_Fee_Amount}</div>);
        }
      },
      sortable: true,
    },
    {
      name: 'Enrollment',
      cell: (row) => {
        if(row?.Enrollment_Number) {
          return (<div>
            {row?.Enrollment_Number}
            <button
              type="button"
              style={{ marginLeft: "10px" }}
              className={"btn p-0"}
              onClick={() => {
                navigate(`/cms/trainer-course/${row.id}/new-enrollment`)
              }}
              title={"ViewEnrollment"}
            >
              <EditIcon />
            </button>
          </div>);
        }
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.Course_Status + (row.Is_Terminated === 'Yes' ? ' (Terminated)' : ''),
      sortable: true,
    },
    {
      name: 'Actions',
      grow: 2,
      cell: (row) => {
        if(allCourses.length > 0) {
          return (<TrainerCourseActionColumn course={row} checkbox_updated={handle_checkbox_updated} />);
        }
      },
    },
  ];

  const handleFilter = (e) => {
    let value = e.target.value;
    if(value === 'All') {
      setFilteredCourses(allCourses);
      setFilterBy('All');
    } else if(value === 'Draft') {
      let selectedData = allCourses.filter(course => course.Course_Status.match(value));
      setFilteredCourses(selectedData);
      setDraftCourses(selectedData);
      setFilterBy('Draft');
    } else if(value === 'Published') {
      let selectedData = allCourses.filter(course => course.Course_Status.match(value));
      setFilteredCourses(selectedData);
      setPublishedCourses(selectedData);
      setFilterBy('Published');
    } else if(value === 'Archive') {
      let selectedData = allCourses.filter(course => course.Course_Status.match(value));
      setFilteredCourses(selectedData);
      setArchiveCourses(selectedData);
      setFilterBy('Archive');
    } else if(value === 'Expired') {
      let selectedData = allCourses.filter(course => (moment().utc(8).format("YYYY-MM-DD HH:mm:ss") > moment(course.Off_The_Shelf_Date).utc(8).format("YYYY-MM-DD HH:mm:ss")));
      setFilteredCourses(selectedData);
      setExpiredCourses(selectedData);
      setFilterBy('Expired');
    }
  }

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter(value => {
      return value.Course_Code.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Course_Name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Trainer_Name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Course_Fee.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Course_Fee_Amount.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Course_Status.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      String(moment(value.Off_The_Shelf_Date).utc(8).format('DD MMM YYYY')).toLowerCase().match(new RegExp(searchTerm, 'g'))
    });
  }

  const handleSearch = async (e) => {
    //user typed keywords
    let value = e.target.value;

    //checking condition to search the keywords base on the selected option
    let coursesArray = allCourses;
    if(filterBy === 'All') {
      coursesArray = allCourses;
    } else if(filterBy === 'Draft') {
      coursesArray = draftCourses;
    } else if(filterBy === 'Published') {
      coursesArray = publishedCourses;
    } else if(filterBy === 'Archive') {
      coursesArray = archiveCourses;
    } else if(filterBy === 'Expired') {
      coursesArray = expiredCourses;
    }

    //check condition to handle search user typed keywords
    if (value.length > 0) {
      let search = await arraySearch(coursesArray, value);
      setFilteredCourses(search);
    } else {
      setFilteredCourses(coursesArray);
    }
  }

  return (
    <LayoutCms route={'courses.index'}>
      <div className={"row align-items-center"}>
        <div className={"col-7"}>
          <div className={"text-secondary font-size-20px fw-700"}>Course List</div>
        </div>
        <div className={"col"}>
          <div className={"row justify-content-end gx-1"}>
            <div className={"col-4"} style={{ textAlign: "right" }}>
              <PrimaryButton
                style={{ fontSize: "14px" }}
                type={"button"}
                onClick={() => {
                  navigate("/cms/create/course");
                }}
              >
                Add New Course
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <ShadowContainer>
        <div className={"d-flex align-items-center"}>
          <div className={"ms-auto d-flex align-items-center"}>
            <div className={"mx-2"}>Filter By</div>
            <div className={"mx-2"}>
              <select className="form-select" onChange={handleFilter}>
                <option value="All">All</option>
                <option value="Draft">Draft</option>
                <option value="Published">Published</option>
                <option value="Archive">Archive</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
            <div className={"mx-2"}>
              <input className="form-control" placeholder={"Search"} onChange={handleSearch} />
            </div>
          </div>
        </div>

        <div className={"course-tbl-div"}>
          <DataTable
            columns={columns}
            data={filteredCourses}
            pagination
            paginationPerPage={30}
            paginationRowsPerPageOptions={[30, 50, 100]}
            noDataComponent="You do not have any courses"
          />
        </div>
      </ShadowContainer>
    </LayoutCms>
  );
}
