import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../../components/LayoutCms";
import { Button, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import ExclamationCircleIcon from "../../../../components/ExclamationCircleIcon";
import EditIcon from "../../../../components/EditIcon";
import { getApiClient, getTokens, removeTokens } from "../../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../../util/lang";
import DataTable from "react-data-table-component";
import moment from "moment";
import AdminTerminateCourseActionColumn from "../../../../components/AdminTerminateCourseActionColumn";
import AdminEditSubscriptionExpiryDateColumn from "../../../../components/AdminEditSubscriptionExpiryDateColumn";

export default function AdminMembershipViewCourses() {
    const navigate = useNavigate();
    const api = getApiClient();

    const [admin, setAdmin] = useState('');

    const [selected_start_date, set_selected_start_date] = useState(null);
    const [selected_end_date, set_selected_end_date] = useState(null);

    const [courses_response, set_courses_response] = useState();
    const [filteredCourses, setFilteredCourses] = useState([]);

    const [isShowCoursesReportResult, setIsShowCoursesReportResult] = useState(false);

    const [total_courses_created, set_total_courses_created] = useState('');
    const [total_courses_published, set_total_courses_published] = useState('');
    const [total_courses_terminated, set_total_courses_terminated] = useState('');

    const [organization_courses_created, set_organization_courses_created] = useState('');
    const [organization_courses_published, set_organization_courses_published] = useState('');
    const [organization_courses_terminated, set_organization_courses_terminated] = useState('');

    const [association_courses_created, set_association_courses_created] = useState('');
    const [association_courses_published, set_association_courses_published] = useState('');
    const [association_courses_terminated, set_association_courses_terminated] = useState('');

    const [trainer_courses_created, set_trainer_courses_created] = useState('');
    const [trainer_courses_published, set_trainer_courses_published] = useState('');
    const [trainer_courses_terminated, set_trainer_courses_terminated] = useState('');

    //for selected option
    const [filterBy, setFilterBy] = useState('All');

    //for detect is it super admin
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        if (getTokens()) {
            api.get("/user/viewProfile")
                .then((response) => {
                    setAdmin(response.data?.user);
                    if(response.data?.user?.Is_Super_Admin === 'Yes') {
                        setIsSuperAdmin(true);
                    } else {
                        setIsSuperAdmin(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    removeTokens();
                    alert(error.response.data.message ?? error.message ?? error);
                    navigate("/admin");
                    return;
                });
        }
    }, []);

    if (!admin) {
        return <>Loading...</>;
    }

    const handleGenerateReportByDate = () => {
        if(selected_start_date === null || selected_end_date === null) {
            alert('Error! You must select start date and end date before generate report!');
            return;
        }

        api.post("/admin/viewAllCoursesByDate", { 
                start_date: moment(selected_start_date).utc(8).format("YYYY-MM-DD"),
                end_date: moment(selected_end_date).utc(8).format("YYYY-MM-DD")
            })
            .then((response) => {
                set_courses_response(response.data);
                setFilteredCourses(response.data);

                const original_courses_array = response.data.all_courses;

                let total_courses_published_array = original_courses_array.filter(course => course.Course_Status === 'Published' && course.Is_Terminated === 'No');
                let total_courses_terminated_array = original_courses_array.filter(course => course.Is_Terminated === 'Yes');
                set_total_courses_created(response.data.number_of_courses ?? '');
                set_total_courses_published(total_courses_published_array.length ?? '');
                set_total_courses_terminated(total_courses_terminated_array.length ?? '');

                const organization_courses_array = original_courses_array.filter(course => course.Role === 'Organization');
                let organization_courses_published_array = organization_courses_array.filter(course => course.Course_Status === 'Published' && course.Is_Terminated === 'No');
                let organization_courses_terminated_array = organization_courses_array.filter(course => course.Is_Terminated === 'Yes');
                set_organization_courses_created(organization_courses_array.length ?? '');
                set_organization_courses_published(organization_courses_published_array.length ?? '');
                set_organization_courses_terminated(organization_courses_terminated_array.length ?? '');

                const trainer_courses_array = original_courses_array.filter(course => course.Role === 'Trainer/Academy');
                let trainer_courses_published_array = trainer_courses_array.filter(course => course.Course_Status === 'Published' && course.Is_Terminated === 'No');
                let trainer_courses_terminated_array = trainer_courses_array.filter(course => course.Is_Terminated === 'Yes');
                set_trainer_courses_created(trainer_courses_array.length ?? '');
                set_trainer_courses_published(trainer_courses_published_array.length ?? '');
                set_trainer_courses_terminated(trainer_courses_terminated_array.length ?? '');

                const association_courses_array = original_courses_array.filter(course => course.Role === 'Association');
                let association_courses_published_array = association_courses_array.filter(course => course.Course_Status === 'Published' && course.Is_Terminated === 'No');
                let association_courses_terminated_array = association_courses_array.filter(course => course.Is_Terminated === 'Yes');
                set_association_courses_created(association_courses_array.length ?? '');
                set_association_courses_published(association_courses_published_array.length ?? '');
                set_association_courses_terminated(association_courses_terminated_array.length ?? '');

                setIsShowCoursesReportResult(true);

                //for after admin terminated course, refresh the data with selected Filter By role
                if(filterBy !== 'All') {
                    console.log(filterBy);
                    let filteredByCourseObject = {};
                    filteredByCourseObject.auth = original_courses_array.auth;
                    filteredByCourseObject.number_of_courses = original_courses_array.number_of_courses;
                    filteredByCourseObject.all_courses = original_courses_array.filter(course => course.Role === filterBy);
                    setFilteredCourses(filteredByCourseObject);
                }
            })
            .catch((error) => {
                alert(error.response.data.message ?? error.message ?? error);
                set_courses_response([]);
                setFilteredCourses([]);
                setIsShowCoursesReportResult(false);
            });
    }

    const convertArrayOfObjectsToCSV = (array) => {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    
    const downloadCSV = (selected_for_csv) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(selected_for_csv);
        if (csv == null) return;
    
        const filename = `AllCoursesInfo_${moment().utc(8).format("YYYY-MM-DD")}.csv`;
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${"\ufeff" + csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const handleExportAllCourses = () => {
        api.post("/admin/viewAllCoursesToGenerateCsv")
            .then((response) => {
                console.log(response.data);
                if(response.data.number_of_courses > 0) {
                    downloadCSV(response.data.courses_array_for_csv);
                } else {
                    alert('No courses at this moment.');
                    return;
                }
            })
            .catch((error) => {
                alert(error.response.data.message ?? error.message ?? error);
            });
    }

    const handleFilter = (e) => {
        let value = e.target.value;

        //need to assign the original auth and course data into the object
        let selectedData = {};
        selectedData.auth = courses_response.auth;
        selectedData.number_of_courses = courses_response.number_of_courses;

        if(value === 'All') {
            setFilterBy('All');
            setFilteredCourses(courses_response);
        } else if(value === 'Organization') {
            setFilterBy('Organization');
            selectedData.all_courses = courses_response.all_courses.filter(course => course.Role === 'Organization');
            setFilteredCourses(selectedData);
        } else if(value === 'Association') {
            setFilterBy('Association');
            selectedData.all_courses = courses_response.all_courses.filter(course => course.Role === 'Association');
            setFilteredCourses(selectedData);
        } else if(value === 'Trainer/Academy') {
            setFilterBy('Trainer/Academy');
            selectedData.all_courses = courses_response.all_courses.filter(course => course.Role === 'Trainer/Academy');
            setFilteredCourses(selectedData);
        }
    }

    const remarksPopover = (row) => {
        return <Popover id="popover-trigger-focus" title="Remarks">
                {row.Terminate_Remarks}
        </Popover>
    }

    const columns = [
        {
            name: "Course Code",
            selector: (row) => row.Course_Code,
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "Course Name (ENG)",
            selector: (row) => parseDbStringToObj(row.Course_Name)?.EN,
            sortable: true,
            wrap: true,
            width: '200px',
        },
        {
            name: "Course Name (TC)",
            selector: (row) => parseDbStringToObj(row.Course_Name)?.TC,
            sortable: true,
            wrap: true,
            width: '200px',
        },
        {
            name: "Course Owner",
            selector: (row) => {
                let course_owner_eng = '';
                if(row.Role === 'Organization') {
                    course_owner_eng = parseDbStringToObj(row.Organization_Name).EN;
                } else if(row.Role === 'Association') {
                    course_owner_eng = parseDbStringToObj(row.Association_Name).EN;
                } else if(row.Role === 'Trainer/Academy') {
                    course_owner_eng = parseDbStringToObj(row.Trainer_Name).EN;
                }
                return course_owner_eng;
            },
            sortable: true,
            wrap: true,
            width: '150px',
        },
        {
            name: "Membership Type",
            selector: (row) => row.Role,
            sortable: true,
            wrap: true,
            width: '160px',
        },
        {
            name: "Trainer Name",
            selector: (row) => row.Trainer,
            sortable: true,
            wrap: true,
            width: '150px',
        },
        {
            name: "Course Fee",
            selector: (row) => {
                let course_fee = '';
                if(row.Course_Fee === 'Pay') {
                    course_fee = `HK$ ${row.Course_Fee_Amount}`;
                } else {
                    course_fee = `HK$ --`;
                }
                return course_fee;
            },
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "Course Create Date",
            selector: (row) => row.createdAt,
            format: (row) => row.createdAt ? moment(row.createdAt).utc(8).format("DD MMM YYYY") : '',
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "Course Publish Date",
            selector: (row) => row.First_Published_Date,
            format: (row) => row.First_Published_Date ? moment(row.First_Published_Date).utc(8).format("DD MMM YYYY") : '',
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "Course Off-shelf Date",
            selector: (row) => row.Off_The_Shelf_Date,
            format: (row) => row.Off_The_Shelf_Date ? moment(row.Off_The_Shelf_Date).utc(8).format("DD MMM YYYY") : '',
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "Quiz",
            selector: (row) => {
                let created_quiz = '';
                if(row.Number_Of_Quiz > 0) {
                    created_quiz = 'Yes'
                } else {
                    created_quiz = 'No';
                }
                return created_quiz;
            },
            sortable: true,
            wrap: true,
            width: '100px',
        },
        {
            name: "Offer Certificate",
            selector: (row) => row.Certificate_Offered,
            sortable: true,
            wrap: true,
            width: '100px',
        },
        {
            name: "No. of Course Enrollment",
            selector: (row) => {
                let number_of_course_enrollment = '';
                if(row.Course_Total_Enrollment_Number > 0) {
                    number_of_course_enrollment = row.Course_Total_Enrollment_Number;
                } else {
                    number_of_course_enrollment = '';
                }
                return number_of_course_enrollment;
            },
            sortable: true,
            wrap: true,
            width: '140px',
        },
        {
            name: "First Taken Date",
            selector: (row) => row.First_Taken_Date,
            format: (row) => row.First_Taken_Date !== null ? moment(row.First_Taken_Date).utc(8).format("DD MMM YYYY") : '',
            sortable: true,
            wrap: true,
            width: '130px',
        },
        {
            name: "No. of Course Viewed",
            selector: (row) => {
                let number_of_course_viewed = '';
                if(row.Course_Viewed_Number > 0) {
                    number_of_course_viewed = row.Course_Viewed_Number;
                } else {
                    number_of_course_viewed = '';
                }
                return number_of_course_viewed;
            },
            sortable: true,
            wrap: true,
            width: '140px',
        },
        {
            name: "No. of Course Completion",
            selector: (row) => {
                let number_of_course_completion = '';
                if(row.Course_Completion_Number > 0) {
                    number_of_course_completion = row.Course_Completion_Number;
                } else {
                    number_of_course_completion = '';
                }
                return number_of_course_completion;
            },
            sortable: true,
            wrap: true,
            width: '140px',
        },
        {
            name: "Subscription Expiry Date",
            selector: (row) => row.Subscription_Expiry_Date,
            format: (row) => {
                return (<AdminEditSubscriptionExpiryDateColumn course={row} subscription_expiry_date={row.Subscription_Expiry_Date} handleGenerateReportByDate={handleGenerateReportByDate} isSuperAdmin={isSuperAdmin} />);
            },
            sortable: true,
            wrap: true,
            width: '160px',
        },
        {
            name: "Action",
            sortable: false,
            width: '160px',
            cell: (row) => {
                return (<AdminTerminateCourseActionColumn course={row} handleGenerateReportByDate={handleGenerateReportByDate} isSuperAdmin={isSuperAdmin} />);
            },
        },
        {
            name: 'Remarks',
            cell: (row) => {
                if(row.Terminate_Remarks !== null && row.Terminate_Remarks !== '') {
                    return (
                        <>
                            <span title="Remarks">
                                <OverlayTrigger trigger="focus" placement="bottom" overlay={remarksPopover(row)}>
                                    <button className={"btn"}>
                                        <ExclamationCircleIcon />
                                    </button>
                                </OverlayTrigger>
                            </span>
                        </>
                    );
                } else {
                    return '';
                }
            },
            sortable: false,
            wrap: true,
        },
    ];

    return (
        <LayoutCms route={'membership.viewCourses'}>

            {/** ----------- starting main section with Courses content ----------- */}
            <div className={"row align-items-center"}>
                <div className={"col"}>
                    <div className={"text-secondary font-size-20px fw-700"}>
                        Courses
                        <PrimaryButton
                            type={"button"}
                            className={"mb-2"}
                            style={{float:'right'}}
                            onClick={handleExportAllCourses}
                        >
                            Export All Courses
                        </PrimaryButton>
                    </div>
                </div>
            </div>

            <ShadowContainer>
                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={3}>Report Period : Start Date</Col>
                            <Col xs={4}><input type="date" className="form-control" value={selected_start_date} max={moment().utc(8).format('Y-MM-DD')} onChange={(e)=> set_selected_start_date(e.target.value)} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={3}>Report Period : End Date</Col>
                            <Col xs={4}><input type="date" className="form-control" value={selected_end_date} max={moment().utc(8).format('Y-MM-DD')} onChange={(e)=> set_selected_end_date(e.target.value)} /></Col>
                        </Row>
                    </Col>
                </Row>

                {isShowCoursesReportResult &&
                <div>
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Report Generate Date</Col>
                                <Col>{moment().utc(8).format("DD/MM/YYYY") ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Report Generate Time</Col>
                                <Col>{moment().utc(8).format("HH:mm") ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                }

                <PrimaryButton
                    type={"submit"}
                    className={"mb-2"}
                    onClick={handleGenerateReportByDate}
                >
                    Generate Report
                </PrimaryButton>

                <hr />

                {isShowCoursesReportResult &&
                <div>
                    <div className={"text-secondary font-size-20px fw-700"}>Courses Report Result</div>
                    {/** Total info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={3}></Col>
                                <Col>Total</Col>
                                <Col>Trainer/Academy</Col>
                                <Col>Organization</Col>
                                <Col>Association</Col>
                            </Row>
                            <hr />
                            <Row className={"align-items-left mb-3"}>
                                <Col xs={3}>No. of Courses Being Created</Col>
                                <Col>{total_courses_created}</Col>
                                <Col>{trainer_courses_created}</Col>
                                <Col>{organization_courses_created}</Col>
                                <Col>{association_courses_created}</Col>
                            </Row>
                            <hr />
                            <Row className={"align-items-left mb-3"}>
                                <Col xs={3}>No. of Courses Being Published</Col>
                                <Col>{total_courses_published}</Col>
                                <Col>{trainer_courses_published}</Col>
                                <Col>{organization_courses_published}</Col>
                                <Col>{association_courses_published}</Col>
                            </Row>
                            <hr />
                            <Row className={"align-items-left mb-3"}>
                                <Col xs={3}>No. of Courses Being Terminated</Col>
                                <Col>{total_courses_terminated}</Col>
                                <Col>{trainer_courses_terminated}</Col>
                                <Col>{organization_courses_terminated}</Col>
                                <Col>{association_courses_terminated}</Col>
                            </Row>
                            <hr />
                        </Col>
                    </Row>
                </div>
                }

            </ShadowContainer>

            <Row className={"align-items-center"}>
                <Col className={"d-flex align-items-center gx-4"}>
                    <span className={"text-secondary font-size-20px fw-700"}>Course List</span>
                </Col>
                <Col xs={"auto"} className={"d-flex align-items-center"}>
                    <div className={"mx-2"}>Filter By</div>
                    <div className={"mx-2"}>
                        <select className="form-select" onChange={handleFilter}>
                            <option value="All">All</option>
                            <option value="Organization">Organization</option>
                            <option value="Association">Association</option>
                            <option value="Trainer/Academy">Trainer/Academy</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <ShadowContainer>
                <DataTable
                    columns={columns}
                    data={filteredCourses?.all_courses ?? []}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[30,50,100]}
                />
            </ShadowContainer>
        </LayoutCms>
    );
}
