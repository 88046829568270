export function getInsightsAndTrends() {
  return [
    {
      id: 1,
      thumbnail: "/assets/images/home_sights_thumbnail_1.png",
      title: "The Implications of Climate-related Risks for Financial System",
      short_description:
        "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher shares the view on the implications of climate-related risks for financial system.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/1-Eric+Usher.mp4",
      description: "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher, shares the view on the implications of climate-related risks for financial system.",
    },
    {
      id: 2,
      thumbnail: "/assets/images/home_sights_thumbnail_2.png",
      title:
        "Current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation",
      short_description:
        "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/2-Martin+Skancke.mp4",
      description: "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation.",
    },
    {
      id: 3,
      thumbnail: "/assets/images/home_sights_thumbnail_3.png",
      title:
        "International Standard Development for Promoting Green and Sustainable Finance",
      short_description:
        "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft discusses the international standard development for promoting green and sustainable finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/3-Dr+Nigel+Croft.mp4",
      description: "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft, discusses the international standard development for promoting green and sustainable finance.",
    },
    {
      id: 4,
      thumbnail: "/assets/images/Insight_Green Debt Instruments New Standards from ISO.jpg",
      title: "Green Debt Instruments: New Standards from ISO",
      short_description:
        "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/4-Dr+John+Shideler%2C.mp4",
      description: "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.",
    },
  ];
}

export function getInsightsAndTrendsDetails() {
  return [
    {
      id: 1,
      thumbnail: "/assets/images/home_sights_thumbnail_1.png",
      title: "The Implications of Climate-related Risks for Financial System",
      short_description:
        "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher shares the view on the implications of climate-related risks for financial system.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/1-Eric+Usher.mp4",
      description: "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher, shares the view on the implications of climate-related risks for financial system.",
    },
    {
      id: 2,
      thumbnail: "/assets/images/home_sights_thumbnail_2.png",
      title:
        "Current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation",
      short_description:
        "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/2-Martin+Skancke.mp4",
      description: "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation.",
    },
    {
      id: 3,
      thumbnail: "/assets/images/home_sights_thumbnail_3.png",
      title:
        "International Standard Development for Promoting Green and Sustainable Finance",
      short_description:
        "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft discusses the international standard development for promoting green and sustainable finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/3-Dr+Nigel+Croft.mp4",
      description: "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft, discusses the international standard development for promoting green and sustainable finance.",
    },
    {
      id: 4,
      thumbnail: "/assets/images/Insight_Green Debt Instruments New Standards from ISO.jpg",
      title: "Green Debt Instruments: New Standards from ISO",
      short_description:
        "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/4-Dr+John+Shideler%2C.mp4",
      description: "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.",
    },
    {
      id: 5,
      thumbnail: "/assets/images/home_sights_thumbnail_5.png",
      title: "Introduction of ISO 14100",
      short_description:
        "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/5-Dr+Li+Pengcheng.mp4",
      description: "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.",
    },
    {
      id: 6,
      thumbnail: "/assets/images/home_sights_thumbnail_6.jpg",
      title:
        "Opportunities in Green Financing and Sustainable Development",
      short_description:
        "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/6-KT+Ting.mp4",
      description: "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
    },
  ];
}

export function getInsightsAndTrendsDetailsAPI(id) {
  var js = {
    "1": {
      id: 1,
      thumbnail: "/assets/images/home_sights_thumbnail_1.png",
      title: "The Implications of Climate-related Risks for Financial System",
      short_description:
        "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher shares the view on the implications of climate-related risks for financial system.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/1-Eric+Usher.mp4",
      description: "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher, shares the view on the implications of climate-related risks for financial system.",
    },
    "2": {
      id: 2,
      thumbnail: "/assets/images/home_sights_thumbnail_2.png",
      title:
        "Current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation",
      short_description:
        "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/2-Martin+Skancke.mp4",
      description: "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation.",
    },
    "3": {
      id: 3,
      thumbnail: "/assets/images/home_sights_thumbnail_3.png",
      title:
        "International Standard Development for Promoting Green and Sustainable Finance",
      short_description:
        "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft discusses the international standard development for promoting green and sustainable finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/3-Dr+Nigel+Croft.mp4",
      description: "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft, discusses the international standard development for promoting green and sustainable finance.",
    },
    "4": {
      id: 4,
      thumbnail: "/assets/images/Insight_Green Debt Instruments New Standards from ISO.jpg",
      title: "Green Debt Instruments: New Standards from ISO",
      short_description:
        "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/4-Dr+John+Shideler%2C.mp4",
      description: "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.",
    },
    "5": {
      id: 5,
      thumbnail: "/assets/images/home_sights_thumbnail_5.png",
      title: "Introduction of ISO 14100",
      short_description:
        "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/5-Dr+Li+Pengcheng.mp4",
      description: "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.",
    },
    "6": {
      id: 6,
      thumbnail: "/assets/images/home_sights_thumbnail_6.jpg",
      title:
        "Opportunities in Green Financing and Sustainable Development",
      short_description:
        "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/6-KT+Ting.mp4",
      description: "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
    },
  };

  return js[id];
}

export function getInsightsAndTrendsDetails_Filter(order_by = "", search = "") {
  var j = [
    {
      id: 1,
      thumbnail: "/assets/images/home_sights_thumbnail_1.png",
      title: "The Implications of Climate-related Risks for Financial System",
      short_description:
        "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher shares the view on the implications of climate-related risks for financial system.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/1-Eric+Usher.mp4",
      description: "Head of The United Nations Environment Programme Finance Initiative, Mr Eric Usher, shares the view on the implications of climate-related risks for financial system.",
    },
    {
      id: 2,
      thumbnail: "/assets/images/home_sights_thumbnail_2.png",
      title:
        "Current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation",
      short_description:
        "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/2-Martin+Skancke.mp4",
      description: "Member of Task Force on Climate-related Financial Disclosures, Chair of the Principles for Responsible Investment, Mr Martin Skancke, talks about current status and future perspectives of Task Force on Climate-Related Financial Disclosures (TCFD) implementation.",
    },
    {
      id: 3,
      thumbnail: "/assets/images/home_sights_thumbnail_3.png",
      title:
        "International Standard Development for Promoting Green and Sustainable Finance",
      short_description:
        "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft discusses the international standard development for promoting green and sustainable finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/3-Dr+Nigel+Croft.mp4",
      description: "Associate Technical Director of HKQAA, Chair, ISO Joint Technical Coordination Group for Management System Standards, Dr Nigel Croft, discusses the international standard development for promoting green and sustainable finance.",
    },
    {
      id: 4,
      thumbnail: "/assets/images/Insight_Green Debt Instruments New Standards from ISO.jpg",
      title: "Green Debt Instruments: New Standards from ISO",
      short_description:
        "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/4-Dr+John+Shideler%2C.mp4",
      description: "Chair of Technical Committee ISO/TC 207/SC 4 Sub-committee for developing ISO 14030 Standards on environmental performance evaluation – Green debt instruments, Dr John Shideler, shares update on new standards from ISO for green debt instruments.",
    },
    {
      id: 5,
      thumbnail: "/assets/images/home_sights_thumbnail_5.png",
      title: "Introduction of ISO 14100",
      short_description:
        "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.......",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/5-Dr+Li+Pengcheng.mp4",
      description: "China National Institute of Standardization, Convenor for developing ISO 14100 Guidance on environmental Criteria for projects, assets and activities to support the development of green finance, Dr Li Pengcheng, introduces ISO 14100 Guidance on environmental criteria for projects, assets and activities to support the development of green finance.",
    },
    {
      id: 6,
      thumbnail: "/assets/images/home_sights_thumbnail_6.jpg",
      title:
        "Opportunities in Green Financing and Sustainable Development",
      short_description:
        "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
      published_date: "2022-07-06",
      video: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/6-KT+Ting.mp4",
      description: "Chief Operating Officer of HKQAA, Mr. K.T. Ting, sheds light on the development of green finance in worldwide and the potential business opportunities brought by green finance.",
    },
  ];

  var jj = j;

  if(search === "") {
    jj = j;
  }
  else {
    jj = j.filter(function (e) {
      let t = e.title.toLowerCase().includes(search.toLowerCase());
      let d = e.description.toLowerCase().includes(search.toLowerCase());
      return t || d;
    });
  }

  if (order_by === "" || order_by === "By Date") {
    jj.sort(function (a, b) {
      return new Date(b.published_date).getTime() - new Date(a.published_date).getTime();;
    });
  }
  else if (order_by === "A to Z") {
    jj.sort(function (a, b) {
      a = a.title.toLowerCase();
      b = b.title.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });
  }
  else if (order_by === "Z to A") {
    jj.sort(function (a, b) {
      a = a.title.toLowerCase();
      b = b.title.toLowerCase();
      return a > b ? -1 : a < b ? 1 : 0;
    });
  }
  return jj;
}
