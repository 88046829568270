import LayoutCms from "../../../components/LayoutCms";
import React, { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import AddOrEditMemberForm from "../../../components/AddOrEditMemberForm";
import AddMembersGetCSVTemplate from "../../../components/AddMembersGetCSVTemplate";
import PrimaryButton from "../../../components/PrimaryButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function AddOrEditUser() {
  const location = useLocation();

  let user = null;
  // TODO double check if this condition is solid
  // user may be set using navigate(), see routes/cms/members/index.js:goEditMember()
  if (location.state != null && "user" in location.state) {
    user = location.state.user;
  }

  const [show, setShow] = useState(false);

  const clickedCSVInput = (event) => {
    document.getElementById('csvFileInput').click();
  }

  const submitAddMembersCSV = (file) => {
    let formData = new FormData();
    formData.append("file", file.target.files[0]);

    const token = localStorage.getItem('token');
    const api = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}`,
      timeout: 60000,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": token
      },
    });

    api
      .post(`${process.env.REACT_APP_API_HOST}/association/addMembersWithCSV`, formData)
      .then(function (response) {
        let success_message = '';
        let failed_message = '';
        for(let i = 0; i < response.data.success_users.length; i++) {
          success_message += `"username: ${response.data.success_users[i].username} , success_message: ${response.data.success_users[i].success_message}"\n`;
        }
        for(let j = 0; j < response.data.failed_users.length; j++) {
          failed_message += `"username: ${response.data.failed_users[j].username} , failed_message: ${response.data.failed_users[j].failed_message}"\n`;
        }
        alert(`Success users: \n${success_message}\n\nFailed users: \n${failed_message}`);
        window.location.reload();
      })
      .catch(function (error) {
        alert(error.response.data.message);
        window.location.reload();
      });
  }

  return (
    <LayoutCms route={'members.create'}>
      <Row className={"align-items-center"}>
        <Col xs={7}>
          <div className={"text-secondary font-size-20px fw-700"}>
            {user ? `Edit Member` : "Add Member"}
          </div>
        </Col>
        <Col>
          {user ? (
            ""
          ) : (
            <Row className={"gx-1"}>
              <Col className={"font-size-14px"}>
                <PrimaryButton
                  className={"w-100 text-white hover-eb7e01"}
                  variant="outline-secondary"
                  type={"button"}
                  onClick={clickedCSVInput}
                >
                  Upload User with CSV
                  <input 
                    id="csvFileInput"
                    type="file"
                    name="file"
                    accept=".csv,text/csv"
                    style={{display:'none'}}
                    onChange={submitAddMembersCSV}
                  />
                </PrimaryButton>
              </Col>
              <Col className={"font-size-14px"}>
                <PrimaryButton
                  className={"w-100 text-white hover-eb7e01"}
                  variant="outline-secondary"
                  type={"button"}
                  onClick={() => setShow(true)}
                >
                  Get a CSV Template
                  { show && <AddMembersGetCSVTemplate setShow={setShow}></AddMembersGetCSVTemplate> }
                </PrimaryButton>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <AddOrEditMemberForm user={user}></AddOrEditMemberForm>
      <div style={{height:100}}></div>
    </LayoutCms>
  );
}
