import React, { useEffect, useState } from "react";
import { getApiClient } from "../util/auth";
import EditIcon from "../components/EditIcon";
import { parseDbStringToObj } from "../util/lang";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import moment from "moment";

export default function AdminEditSubscriptionExpiryDateColumn(props) {
    const course = props.course;
    const isSuperAdmin = props.isSuperAdmin;

    const api = getApiClient();

    const [showModal, setShowModal] = useState(false);

    const [subscription_expiry_date, set_subscription_expiry_date] = useState(props.subscription_expiry_date ?? null);

    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleProcessEditSubscriptionExpiryDate = (event) => {
        event.preventDefault();
        console.log("~ handleProcessEditSubscriptionExpiryDate ~")
        handleShowModal(event);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleSaveSubscriptionExpiryDate = (e) => {
        e.preventDefault();
        let confirmEditCourseSubscriptionExpiryDate = window.confirm(`Are you sure to edit course subscription expiry date for the course (${course.Course_Code})?`);
        if(confirmEditCourseSubscriptionExpiryDate === true) {
            api
                .post("/admin/editCourseSubscriptionExpiryDate", {
                    course_id: course.id,
                    subscription_expiry_date: moment(subscription_expiry_date).utc(8).format("YYYY-MM-DD")
                })
                .then((response) => {
                    alert(response?.data?.message);
                    setShowModal(false);
                    props.handleGenerateReportByDate(); //fetch the updated data again
                })
                .catch((error) => {
                    console.log(error);
                    alert(error.response?.data?.message);
                });
        }
    };

    const handleCancelEditCourseSubscriptionExpiryDate = (e) => {
        e.preventDefault();
        setShowModal(false);
    };

    return (
        <>
            {/* form modal */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size={"md"}
                scrollable={true}
                centered
            >
                <Modal.Header closeButton><p className="m-0 font-size-20px text-a2a2a2 fw-700 m-2">Edit Subscription Expiry Date</p></Modal.Header>
                <Modal.Body>
                    <Form className={"d-flex w-100"}>
                        <Row style={{ flexFlow: "column", width: "100%", margin: "0" }}>
                            <Col>
                                Select the subscription expiry date for course <b style={{color: '#eb6201'}}>{course.Course_Code} {parseDbStringToObj(course.Course_Name).EN}.</b>
                            </Col>
                            <Col>
                                <input 
                                    type="date"
                                    name="Subscription_Expiry_Date" 
                                    className="form-control" 
                                    value={subscription_expiry_date}
                                    onChange={(e) => set_subscription_expiry_date(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Button
                                    className={"ms-auto bg-eb6201 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                    onClick={handleSaveSubscriptionExpiryDate}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={"ms-auto bg-b1b1b1 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                    onClick={handleCancelEditCourseSubscriptionExpiryDate}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className={"d-flex align-items-center"}>
                <span>{course.Subscription_Expiry_Date ? moment(course.Subscription_Expiry_Date).utc(8).format("DD MMM YYYY") : ''}</span>
                <span title="Edit">
                    <button
                        style={{ marginLeft: "8px" }}
                        className={"btn p-0"}
                        onClick={handleProcessEditSubscriptionExpiryDate}
                    >
                        <EditIcon />
                    </button>
                </span>
            </div>
        </>
    );
}
