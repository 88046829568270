import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../../components/LayoutCms";
import { Button, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import ExclamationCircleIcon from "../../../../components/ExclamationCircleIcon";
import { getApiClient, getTokens, removeTokens } from "../../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../../util/lang";
import DataTable from "react-data-table-component";
import moment from "moment";
import AdminTerminateUserActionColumn from "../../../../components/AdminTerminateUserActionColumn";

export default function AdminMembershipViewUsers() {
    const navigate = useNavigate();
    const api = getApiClient();

    const [admin, setAdmin] = useState('');

    const [selected_start_date, set_selected_start_date] = useState(null);
    const [selected_end_date, set_selected_end_date] = useState(null);

    const [members_response, set_members_response] = useState();
    const [filteredMembers, setFilteredMembers] = useState([]);

    const [isShowUserReportResult, setIsShowUserReportResult] = useState(false);

    const [total_account_registered, set_total_account_registered] = useState('');
    const [total_active_account, set_total_active_account] = useState('');
    const [total_inactive_account, set_total_inactive_account] = useState('');
    const [total_terminate_account, set_total_terminate_account] = useState('');

    const [organization_account_registered, set_organization_account_registered] = useState('');
    const [organization_active_account, set_organization_active_account] = useState('');
    const [organization_inactive_account, set_organization_inactive_account] = useState('');
    const [organization_terminate_account, set_organization_terminate_account] = useState('');

    const [organization_learner_account_registered, set_organization_learner_account_registered] = useState('');
    const [organization_learner_active_account, set_organization_learner_active_account] = useState('');
    const [organization_learner_inactive_account, set_organization_learner_inactive_account] = useState('');
    const [organization_learner_terminate_account, set_organization_learner_terminate_account] = useState('');

    const [association_account_registered, set_association_account_registered] = useState('');
    const [association_active_account, set_association_active_account] = useState('');
    const [association_inactive_account, set_association_inactive_account] = useState('');
    const [association_terminate_account, set_association_terminate_account] = useState('');

    const [association_learner_account_registered, set_association_learner_account_registered] = useState('');
    const [association_learner_active_account, set_association_learner_active_account] = useState('');
    const [association_learner_inactive_account, set_association_learner_inactive_account] = useState('');
    const [association_learner_terminate_account, set_association_learner_terminate_account] = useState('');

    const [trainer_account_registered, set_trainer_account_registered] = useState('');
    const [trainer_active_account, set_trainer_active_account] = useState('');
    const [trainer_inactive_account, set_trainer_inactive_account] = useState('');
    const [trainer_terminate_account, set_trainer_terminate_account] = useState('');

    const [individual_learner_account_registered, set_individual_learner_account_registered] = useState('');
    const [individual_learner_active_account, set_individual_learner_active_account] = useState('');
    const [individual_learner_inactive_account, set_individual_learner_inactive_account] = useState('');
    const [individual_learner_terminate_account, set_individual_learner_terminate_account] = useState('');

    //for selected option
    const [filterBy, setFilterBy] = useState('All');

    //for detect is it super admin
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        if (getTokens()) {
            getApiClient()
                .get("/user/viewProfile")
                .then((response) => {
                    setAdmin(response.data?.user);
                    if(response.data?.user?.Is_Super_Admin === 'Yes') {
                        setIsSuperAdmin(true);
                    } else {
                        setIsSuperAdmin(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    removeTokens();
                    alert(error.response.data.message ?? error.message ?? error);
                    navigate("/admin");
                    return;
                });
        }
    }, []);

    if (!admin) {
        return <>Loading...</>;
    }

    const handleGenerateReportByDate = () => {
        if(selected_start_date === null || selected_end_date === null) {
            alert('Error! You must select start date and end date before generate report!');
            return;
        }

        getApiClient()
            .post("/admin/viewAllUsersByDate", { 
                start_date: moment(selected_start_date).utc(8).format("YYYY-MM-DD"),
                end_date: moment(selected_end_date).utc(8).format("YYYY-MM-DD")
            })
            .then((response) => {
                set_members_response(response.data);
                setFilteredMembers(response.data);

                const original_members_array = response.data.all_users;

                let total_active_account_array = original_members_array.filter(member => member.Status === 'Active');
                let total_inactive_account_array = original_members_array.filter(member => member.Status === 'Disable');
                let total_terminate_account_array = original_members_array.filter(member => member.Status === 'Terminated');
                set_total_account_registered(response.data.number_of_users ?? '');
                set_total_active_account(total_active_account_array.length ?? '');
                set_total_inactive_account(total_inactive_account_array.length ?? '');
                set_total_terminate_account(total_terminate_account_array.length ?? '');

                const organization_account_registered_array = original_members_array.filter(member => member.Role === 'Organization');
                let organization_active_account_array = organization_account_registered_array.filter(member => member.Status === 'Active');
                let organization_inactive_account_array = organization_account_registered_array.filter(member => member.Status === 'Disable');
                let organization_terminate_account_array = organization_account_registered_array.filter(member => member.Status === 'Terminated');
                set_organization_account_registered(organization_account_registered_array.length ?? '');
                set_organization_active_account(organization_active_account_array.length ?? '');
                set_organization_inactive_account(organization_inactive_account_array.length ?? '');
                set_organization_terminate_account(organization_terminate_account_array.length ?? '');

                const organization_learner_account_registered_array = original_members_array.filter(member => member.Role === 'Organization Learner');
                let organization_learner_active_account_array = organization_learner_account_registered_array.filter(member => member.Status === 'Active');
                let organization_learner_inactive_account_array = organization_learner_account_registered_array.filter(member => member.Status === 'Disable');
                let organization_learner_terminate_account_array = organization_learner_account_registered_array.filter(member => member.Status === 'Terminated');
                set_organization_learner_account_registered(organization_learner_account_registered_array.length ?? '');
                set_organization_learner_active_account(organization_learner_active_account_array.length ?? '');
                set_organization_learner_inactive_account(organization_learner_inactive_account_array.length ?? '');
                set_organization_learner_terminate_account(organization_learner_terminate_account_array.length ?? '');

                const association_account_registered_array = original_members_array.filter(member => member.Role === 'Association');
                let association_active_account_array = association_account_registered_array.filter(member => member.Status === 'Active');
                let association_inactive_account_array = association_account_registered_array.filter(member => member.Status === 'Disable');
                let association_terminate_account_array = association_account_registered_array.filter(member => member.Status === 'Terminated');
                set_association_account_registered(association_account_registered_array.length ?? '');
                set_association_active_account(association_active_account_array.length ?? '');
                set_association_inactive_account(association_inactive_account_array.length ?? '');
                set_association_terminate_account(association_terminate_account_array.length ?? '');

                const association_learner_account_registered_array = original_members_array.filter(member => member.Role === 'Association Learner');
                let association_learner_active_account_array = association_learner_account_registered_array.filter(member => member.Status === 'Active');
                let association_learner_inactive_account_array = association_learner_account_registered_array.filter(member => member.Status === 'Disable');
                let association_learner_terminate_account_array = association_learner_account_registered_array.filter(member => member.Status === 'Terminated');
                set_association_learner_account_registered(association_learner_account_registered_array.length ?? '');
                set_association_learner_active_account(association_learner_active_account_array.length ?? '');
                set_association_learner_inactive_account(association_learner_inactive_account_array.length ?? '');
                set_association_learner_terminate_account(association_learner_terminate_account_array.length ?? '');

                const trainer_account_registered_array = original_members_array.filter(member => member.Role === 'Trainer/Academy');
                let trainer_active_account_array = trainer_account_registered_array.filter(member => member.Status === 'Active');
                let trainer_inactive_account_array = trainer_account_registered_array.filter(member => member.Status === 'Disable');
                let trainer_terminate_account_array = trainer_account_registered_array.filter(member => member.Status === 'Terminated');
                set_trainer_account_registered(trainer_account_registered_array.length ?? '');
                set_trainer_active_account(trainer_active_account_array.length ?? '');
                set_trainer_inactive_account(trainer_inactive_account_array.length ?? '');
                set_trainer_terminate_account(trainer_terminate_account_array.length ?? '');

                const individual_learner_account_registered_array = original_members_array.filter(member => member.Role === 'Individual Learner');
                let individual_learner_active_account_array = individual_learner_account_registered_array.filter(member => member.Status === 'Active');
                let individual_learner_inactive_account_array = individual_learner_account_registered_array.filter(member => member.Status === 'Disable');
                let individual_learner_terminate_account_array = individual_learner_account_registered_array.filter(member => member.Status === 'Terminated');
                set_individual_learner_account_registered(individual_learner_account_registered_array.length ?? '');
                set_individual_learner_active_account(individual_learner_active_account_array.length ?? '');
                set_individual_learner_inactive_account(individual_learner_inactive_account_array.length ?? '');
                set_individual_learner_terminate_account(individual_learner_terminate_account_array.length ?? '');

                setIsShowUserReportResult(true);

                //for after admin terminated user, refresh the data with selected Filter By role
                if(filterBy !== 'All') {
                    console.log(filterBy);
                    let filteredByUserObject = {};
                    filteredByUserObject.auth = original_members_array.auth;
                    filteredByUserObject.number_of_users = original_members_array.number_of_users;
                    filteredByUserObject.all_users = original_members_array.filter(member => member.Role === filterBy);
                    setFilteredMembers(filteredByUserObject);
                }
            })
            .catch((error) => {
                alert(error.response.data.message ?? error.message ?? error);
                set_members_response([]);
                setFilteredMembers([]);
                setIsShowUserReportResult(false);
            });
    }

    const convertArrayOfObjectsToCSV = (array) => {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    
    const downloadCSV = (selected_for_csv) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(selected_for_csv);
        if (csv == null) return;
    
        const filename = `AllUsersInfo_${moment().utc(8).format("YYYY-MM-DD")}.csv`;
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${"\ufeff" + csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const handleExportAllUsers = () => {
        getApiClient()
            .post("/admin/viewAllUsersToGenerateCsv")
            .then((response) => {
                console.log(response.data);
                if(response.data.number_of_users > 0) {
                    downloadCSV(response.data.users_array_for_csv);
                } else {
                    alert('No users at this moment.');
                    return;
                }
            })
            .catch((error) => {
                alert(error.response.data.message ?? error.message ?? error);
            });
    }

    const handleFilter = (e) => {
        let value = e.target.value;

        //need to assign the original auth and course data into the object
        let selectedData = {};
        selectedData.auth = members_response.auth;
        selectedData.number_of_users = members_response.number_of_users;

        if(value === 'All') {
            setFilterBy('All');
            setFilteredMembers(members_response);
        } else if(value === 'Organization') {
            setFilterBy('Organization');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Organization');
            setFilteredMembers(selectedData);
        } else if(value === 'Organization Learner') {
            setFilterBy('Organization Learner');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Organization Learner');
            setFilteredMembers(selectedData);
        } else if(value === 'Association') {
            setFilterBy('Association');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Association');
            setFilteredMembers(selectedData);
        } else if(value === 'Association Learner') {
            setFilterBy('Association Learner');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Association Learner');
            setFilteredMembers(selectedData);
        } else if(value === 'Trainer/Academy') {
            setFilterBy('Trainer/Academy');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Trainer/Academy');
            setFilteredMembers(selectedData);
        } else if(value === 'Individual Learner') {
            setFilterBy('Individual Learner');
            selectedData.all_users = members_response.all_users.filter(member => member.Role === 'Individual Learner');
            setFilteredMembers(selectedData);
        }
    }

    const remarksPopover = (row) => {
        return <Popover id="popover-trigger-focus" title="Remarks">
                {row.Terminate_Remarks}
        </Popover>
    }

    const columns = [
        {
            name: "Username",
            selector: (row) => row.Username,
            sortable: true,
            wrap: true,
        },
        {
            name: "Type of Membership",
            selector: (row) => row.Role,
            sortable: true,
            wrap: true,
        },
        {
            name: "Contact Person Name (ENG)",
            selector: (row) => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + " " + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
            sortable: true,
            wrap: true,
        },
        {
            name: "Contact Person Name (TC)",
            selector: (row) => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
            sortable: true,
            wrap: true,
        },
        {
            name: "Email Address",
            selector: (row) => {
                let email = '';
                if(row.Role === 'Individual Learner') {
                    email = row.Personal_Email;
                } else if(row.Role === 'Organization' || row.Role === 'Organization Learner') {
                    email = row.Organization_Email;
                } else if(row.Role === 'Association' || row.Role === 'Association Learner') {
                    email = row.Association_Email;
                } else if(row.Role === 'Trainer/Academy') {
                    email = row.Trainer_Email;
                }
                return email;
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Company Name (ENG)",
            selector: (row) => {
                let company_name_eng = '';
                if(row.Role === 'Individual Learner') {
                    company_name_eng = '';
                } else if(row.Role === 'Organization' || row.Role === 'Organization Learner') {
                    company_name_eng = parseDbStringToObj(row.Organization_Name).EN;
                } else if(row.Role === 'Association' || row.Role === 'Association Learner') {
                    company_name_eng = parseDbStringToObj(row.Association_Name).EN;
                } else if(row.Role === 'Trainer/Academy') {
                    company_name_eng = parseDbStringToObj(row.Trainer_Name).EN;
                }
                return company_name_eng;
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Company Name (TC)",
            selector: (row) => {
                let company_name_tc = '';
                if(row.Role === 'Individual Learner') {
                    company_name_tc = '';
                } else if(row.Role === 'Organization' || row.Role === 'Organization Learner') {
                    company_name_tc = parseDbStringToObj(row.Organization_Name).TC;
                } else if(row.Role === 'Association' || row.Role === 'Association Learner') {
                    company_name_tc = parseDbStringToObj(row.Association_Name).TC;
                } else if(row.Role === 'Trainer/Academy') {
                    company_name_tc = parseDbStringToObj(row.Trainer_Name).TC;
                }
                return company_name_tc;
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) => row.Status,
            sortable: true,
            wrap: true,
        },
        {
            name: "Date of Joining",
            selector: (row) => row.createdAt,
            format: (row) => moment(row.createdAt).utc(8).format("DD MMM YYYY"),
            sortable: true,
            wrap: true,
        },
        {
            name: "Action",
            sortable: false,
            width: '160px',
            cell: (row) => {
                return (<AdminTerminateUserActionColumn user={row} handleGenerateReportByDate={handleGenerateReportByDate} isSuperAdmin={isSuperAdmin} />);
            },
        },
        {
            name: 'Remarks',
            cell: (row) => {
                if(row.Terminate_Remarks !== null && row.Terminate_Remarks !== '') {
                    return (
                        <>
                            <span title="Remarks">
                                <OverlayTrigger trigger="focus" placement="bottom" overlay={remarksPopover(row)}>
                                    <button className={"btn"}>
                                        <ExclamationCircleIcon />
                                    </button>
                                </OverlayTrigger>
                            </span>
                        </>
                    );
                } else {
                    return '';
                }
            },
            sortable: false,
            wrap: true,
          },
    ];

    return (
        <LayoutCms route={'membership.viewUsers'}>

            {/** ----------- starting main section with Users content ----------- */}
            <div className={"row align-items-center"}>
                <div className={"col"}>
                    <div className={"text-secondary font-size-20px fw-700"}>
                        Users
                        <PrimaryButton
                            type={"button"}
                            className={"mb-2"}
                            style={{float:'right'}}
                            onClick={handleExportAllUsers}
                        >
                            Export All Users
                        </PrimaryButton>
                    </div>
                </div>
            </div>

            <ShadowContainer>
                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={3}>Report Period : Start Date</Col>
                            <Col xs={4}><input type="date" className="form-control" value={selected_start_date} max={moment().utc(8).format('Y-MM-DD')} onChange={(e)=> set_selected_start_date(e.target.value)} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={3}>Report Period : End Date</Col>
                            <Col xs={4}><input type="date" className="form-control" value={selected_end_date} max={moment().utc(8).format('Y-MM-DD')} onChange={(e)=> set_selected_end_date(e.target.value)} /></Col>
                        </Row>
                    </Col>
                </Row>

                {isShowUserReportResult &&
                <div>
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Report Generate Date</Col>
                                <Col>{moment().utc(8).format("DD/MM/YYYY") ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Report Generate Time</Col>
                                <Col>{moment().utc(8).format("HH:mm") ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                }

                <PrimaryButton
                    type={"submit"}
                    className={"mb-2"}
                    onClick={handleGenerateReportByDate}
                >
                    Generate Report
                </PrimaryButton>

                <hr />

                {isShowUserReportResult &&
                <div>
                    <div className={"text-secondary font-size-20px fw-700"}>User Report Result</div>
                    {/** Total info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={3}>Total Account Registered</Col>
                                <Col>{members_response?.number_of_users ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Total Active Account</Col>
                                <Col>{total_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Total Inactive Account</Col>
                                <Col>{total_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>Total Terminate Account</Col>
                                <Col>{total_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />
                    {/** Organization and Organization Learner info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={6}>No. of Organization Account Registered</Col>
                                <Col>{organization_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Active Organization Account</Col>
                                <Col>{organization_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Inactive Organization Account</Col>
                                <Col>{organization_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Terminate Organization Account</Col>
                                <Col>{organization_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={6}>No. of Organization's Staff Account Registered</Col>
                                <Col>{organization_learner_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Active Organization's Staff Account</Col>
                                <Col>{organization_learner_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Inactive Organization's Staff Account</Col>
                                <Col>{organization_learner_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Terminate Organization's Staff Account</Col>
                                <Col>{organization_learner_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />
                    {/** Association and Association Learner info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={6}>No. of Association Account Registered</Col>
                                <Col>{association_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Active Association Account</Col>
                                <Col>{association_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Inactive Association Account</Col>
                                <Col>{association_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Terminate Association Account</Col>
                                <Col>{association_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={6}>No. of Association's Member Account Registered</Col>
                                <Col>{association_learner_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Active Association's Member Account</Col>
                                <Col>{association_learner_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Inactive Association's Member Account</Col>
                                <Col>{association_learner_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={6}>No. of Terminate Association's Member Account</Col>
                                <Col>{association_learner_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />
                    {/** Trainer/Academy info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={3}>No. of Trainer/Academy Account Registered</Col>
                                <Col>{trainer_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Active Trainer/Academy Account</Col>
                                <Col>{trainer_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Inactive Trainer/Academy Account</Col>
                                <Col>{trainer_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Terminate Trainer/Academy Account</Col>
                                <Col>{trainer_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />
                    {/** Individual Learner info */}
                    <Row className={"search-results-div"}>
                        <Col>
                            <Row className={"align-items-center mt-3 mb-3"}>
                                <Col xs={3}>No. of Individual Account Registered</Col>
                                <Col>{individual_learner_account_registered ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Active Individual Account</Col>
                                <Col>{individual_learner_active_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Inactive Individual Account</Col>
                                <Col>{individual_learner_inactive_account ?? ''}</Col>
                            </Row>
                            <Row className={"align-items-center mb-3"}>
                                <Col xs={3}>No. of Terminate Individual Account</Col>
                                <Col>{individual_learner_terminate_account ?? ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                }

            </ShadowContainer>

            <Row className={"align-items-center"}>
                <Col className={"d-flex align-items-center gx-4"}>
                    <span className={"text-secondary font-size-20px fw-700"}>User List</span>
                </Col>
                <Col xs={"auto"} className={"d-flex align-items-center"}>
                    <div className={"mx-2"}>Filter By</div>
                    <div className={"mx-2"}>
                        <select className="form-select" onChange={handleFilter}>
                            <option value="All">All</option>
                            <option value="Organization">Organization</option>
                            <option value="Organization Learner">Organization Learner</option>
                            <option value="Association">Association</option>
                            <option value="Association Learner">Association Learner</option>
                            <option value="Trainer/Academy">Trainer/Academy</option>
                            <option value="Individual Learner">Individual Learner</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <ShadowContainer>
                <DataTable
                    columns={columns}
                    data={filteredMembers?.all_users ?? []}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[30,50,100]}
                />
            </ShadowContainer>
        </LayoutCms>
    );
}
