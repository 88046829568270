import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import H2 from "../../components/H2";
import LineLimiter from "../../components/LineLimiter";
import Banner from "../../components/Banner";
import CourseCategoryNavBar from "../../components/CourseCategoryNavBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "../../components/Section";
import { parseDbStringToObj } from "../../util/lang";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language
import CourseCategory from "../../components/Options/CourseCategory";
import InterestedTopics from "../../components/Options/InterestedTopics";

export default function CourseIndex() {
  const { t } = useTranslation();

  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]); //filtered courses by selected category(use for search function in filtered courses)
  const [filteredCoursesAll, setFilteredCoursesAll] = useState([]); //all filtered courses by selected category

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
  });

  useEffect(() => {
    api
      .post("/public/view_courses", {
      })
      .then(function (response) {
        let courseList = response.data.courses.reverse();
        setCourses(courseList);
        setFilteredCourses(courseList);
        setFilteredCoursesAll(courseList);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });
  }, []);

  let lastUpdate;
  const search = (e) => {
    let s = e.target.value;
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 100) {
        if (s === "") {
          setFilteredCourses(filteredCoursesAll);
        }
        else {
          let c = filteredCourses.filter(function (e) {
            let cn = e.Course_Code + " - " + parseDbStringToObj(e.Course_Name)[CURRENT_LANGUAGE];
            let co = e.Role === "Trainer/Academy" ? parseDbStringToObj(e.Trainer_Name)[CURRENT_LANGUAGE] : parseDbStringToObj(e.Association_Name)[CURRENT_LANGUAGE];
            let cd = parseDbStringToObj(e.Course_Descriptions)[CURRENT_LANGUAGE];
            let t = cn.toLowerCase().includes(s.toLowerCase());
            let d = cd.toLowerCase().includes(s.toLowerCase());
            let r = co.toLowerCase().includes(s.toLowerCase());
            return t || d || r;
          });
          setFilteredCourses(c);
        }
      }
    }, 100);
  };

  const handleFilterCategory = (e) => {
    let value = e.target.value;

    if(value === 'All Category') {
      setFilteredCourses(courses);
      setFilteredCoursesAll(courses);
    } else {
      let selectedData = courses.filter(course => (course.Course_Categories).includes(value));
      setFilteredCourses(selectedData);
      setFilteredCoursesAll(selectedData);
    }
  }

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/course-banner.png" backgroundPosition="right">{t('Courses')}</Banner>

      <Section>
        <Container className="max-width-1304">
          <Row className="align-items-end" style={{ marginBottom: 30 }}>
            <Col>
              <H2>{t('Courses')}</H2>
              <div style={{ width: 60 }} className="hkqaa-divider" />
            </Col>
            <Col
              style={{
                flex: "0 0 auto",
                // width: "calc(50% - 40px)",
                minHeight: 1,
              }}
              md={4}
              className={"d-md-flex d-none"}
            ></Col>
            <Col sm={4} md={3} className="d-flex align-items-center mb-3 mb-md-0">
              <span style={{ minWidth: "70px" }}>{t('Filter By')}</span>
              <select 
                id="filter-select" 
                className="form-select border-004a87"
                onChange={handleFilterCategory}
              >
                {/** We will used InterestedTopics select option here because it is same as course category */}
                <InterestedTopics publicCoursesPage={true}></InterestedTopics>
              </select>
            </Col>
            <Col>
              <form>
                <div className="input-group">
                  <span
                    className="input-group-text bg-white border-end-0 border-004a87"
                    id="search"
                  >
                    <i className="bi bi-search" />
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 border-004a87"
                    placeholder={t("Search")}
                    onChange={search}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <CourseCategoryNavBar />
          <Row className="gy-4">
            {
              /**
               * @property course.CourseCode
               * @property course.CourseName
               * @property course.CoursePhoto
               * @property course.CourseDescriptions
               */
              filteredCourses.map((course) => {
                const courseName = parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE];
                const courseDescription = parseDbStringToObj(course.Course_Descriptions)[CURRENT_LANGUAGE];

                return (
                  <Col xs={6} md={4} key={course.id}>
                    <div
                      className="d-flex h-100 flex-column"
                      style={{ boxShadow: "0px 5px 5px #0000001A", borderRadius: "15px" }}
                    >
                      <img
                        // src={course.CoursePhoto || '/assets/images/esg_101.png'}
                        src={course.Course_Photo || '/assets/images/home_courses_thumbnail_2.png'}
                        style={{ aspectRatio: "137 / 86", objectFit: "cover" }}
                        className="img-fluid w-100"
                        alt={"banner image for " + courseName}
                      />
                      <div className="p-3 d-flex h-100 flex-column"  style={{overflowWrap:"break-word" }}>
                        <div
                          style={{ fontSize: "16px", lineHeight: "24px", marginTop: "20px" }}
                          className="fw-bold"
                        >
                          {course.Course_Code + ' - ' + courseName}
                        </div>
                        <div className="mb-3" style={{overflowWrap:"break-word" }}>
                          <OwnerLink 
                            owner_id={course.Course_Owner ?? null} 
                            owner={course.Role === "Trainer/Academy" ? parseDbStringToObj(course.Trainer_Name)[CURRENT_LANGUAGE] : parseDbStringToObj(course.Association_Name)[CURRENT_LANGUAGE]} 
                            role={course.Role}
                          ></OwnerLink>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                          <LineLimiter>
                            {courseDescription}
                          </LineLimiter>
                        </div>
                        <div className="mt-auto">
                          <Link
                            to={"/courses/show-data/" + course.id}  //here need to use /show-data  NOT /show because need for public
                            style={{ color: "#266599", textDecoration: "none" }}
                            state={{ courseId: course.id }}
                          >
                            {t('EXPLORE MORE')} &gt;
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            }
          </Row>
          <Row className="justify-content-center" style={{ marginTop: 50, marginBottom: 50 }}>
            <Col xs="auto">
              {/* <a href="#" className="btn bg-eb6201 text-white fw-bold">
                {t('DISCOVER MORE')}
              </a> */}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}

function OwnerLink(props) {
  if (!props.owner_id) {
    return <a style={{ color: "#212529", textDecoration: "none" }}>
      {props.owner}
    </a>
  }
  else {
    let owner_link = props.role === "Trainer/Academy" ? `trainers/show/${props.owner_id}` : `association/show/${props.owner_id}`;
    return <a href={owner_link} style={{ color: "#266599", textDecoration: "none" }}>
      {props.owner}
    </a>
  }
}