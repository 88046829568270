import React, { useState } from "react";
import axios from "axios";

export default function UploadNewBR(props) {
    const [uploaded_file_name, set_uploaded_file_name] = useState("");

    // for handle upload new BR
    const handleNewBrUpload = (event) => {
        const file = event.target.files[0];

        let formData = new FormData();
        formData.append("file", file); // the form append name MUST BE "file" for matching api

        const api = axios.create({
            baseURL: `${process.env.REACT_APP_API_HOST}`,
            timeout: 30000,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        api
        .post(`${process.env.REACT_APP_API_HOST}/s3/user`, formData)
        .then(function (response) {
            // set the uploaded file name label to display user uploaded file name
            set_uploaded_file_name(file.name);

            // define param for uploaded new BR and returned s3 file url link
            const s3_url = response.data.urls[0];
            // set back s3 url with the new BR in the general information tab
            props.set_business_registration(s3_url);
        })
        .catch(function (error) {
            let d = document.querySelector(".cXBrtO span");
            d.innerHTML = "Upload Failed! Please try again.";
            alert(error.response.data.message);
        });
    };

    return (
        <>
            <label className="col-sm-2 ms-5 text-decoration-underline">
                Upload new BR
                <input 
                    type="file"
                    name="file"
                    accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .pdf, .PDF"
                    onChange={handleNewBrUpload}
                    style={{display: 'none'}}
                />
            </label>
            <label>{uploaded_file_name}</label>
        </>
    );
}