import React from "react";
import { Link, useNavigate, useNavigationType } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/Layout";
import Section from "../components/Section";
import PrimaryButton from "../components/PrimaryButton";
import {useTranslation} from "react-i18next";


export default function ComingSoon() {
    const { t } = useTranslation();

    const navigate = useNavigate();

  return (
    <Layout>
      <Section>
        <div className="d-flex justify-content-center align-items-center flex-column">
            <img style={{width:220,height:'auto'}}
            src='/assets/images/clock.png' />
            <div className="mt-4 mb-4">
            <h1 className="fw-900">{t('Coming Soon')}</h1>
            <div>{t('Great things are worth the wait')}</div>
            </div>
            <div style={{height:50}}></div>
            <PrimaryButton onClick={()=>navigate(-1)}>{t('Go back')}</PrimaryButton>
        </div>
      </Section>
    </Layout>
  );
}
