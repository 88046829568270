import { Link, useParams } from "react-router-dom";
import React from "react";

export default function TrainerCourseTabNavigation(props) {

    const params = useParams();
    const course_id = params.course_id ?? '';
    const activeNavItem = props?.activeNavItem;

    const cssActiveLink = "border-bottom border-width-2 me-4 border-bottom-eb6201 -mb-2px text-eb6201";
    const cssNormalLink = "me-4 text-adb5bd";

    const all_links =
    {
        new_enrollment: `/cms/trainer-course/${course_id}/new-enrollment`,
        enrolled_learner: `/cms/trainer-course/${course_id}/enrolled-learner`,
        archived: `/cms/trainer-course/${course_id}/archived`,
    };

    return (
        <div className={"my-3"}>
            <ul
                className={"d-flex p-0 border-bottom border-width-2 flex-flow-column-sm"}
                style={{ listStyle: "none" }}
            >
                <li className={activeNavItem === "new_enrollment" ? cssActiveLink : cssNormalLink}>
                    <Link
                        className={"text-decoration-none fw-bold text-reset"}
                        to={all_links.new_enrollment}
                    >
                        New Enrollment
                    </Link>
                </li>
                <li className={activeNavItem === "enrolled_learner" ? cssActiveLink : cssNormalLink}>
                    <Link
                        className={"text-decoration-none fw-bold text-reset"}
                        to={all_links.enrolled_learner}
                    >
                        Enrolled Learner
                    </Link>
                </li>
                <li className={activeNavItem === "archived" ? cssActiveLink : cssNormalLink}>
                    <Link
                        className={"text-decoration-none fw-bold text-reset"}
                        to={all_links.archived}
                    >
                        Archived
                    </Link>
                </li>
            </ul>
        </div>
    );
}
