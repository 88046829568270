import React from "react";

export default function Country(props){

    const lang = props.lang ?? 'EN';

    const countries = [
        {
          "key": "Australia",
          "EN": "Australia",
          "TC": "澳洲",
          "SC": "澳洲"
        },
        {
          "key": "Austria",
          "EN": "Austria",
          "TC": "奧地利",
          "SC": "奥地利"
        },
        {
          "key": "Argentina",
          "EN": "Argentina",
          "TC": "阿根廷",
          "SC": "阿根廷"
        },
        {
          "key": "Belgium",
          "EN": "Belgium",
          "TC": "比利時",
          "SC": "比利时"
        },
        {
          "key": "Brazil",
          "EN": "Brazil",
          "TC": "巴西",
          "SC": "巴西"
        },
        {
          "key": "Cambodia",
          "EN": "Cambodia",
          "TC": "柬埔寨",
          "SC": "柬埔寨"
        },
        {
          "key": "Canada",
          "EN": "Canada",
          "TC": "加拿大",
          "SC": "加拿大"
        },
        {
          "key": "Chile",
          "EN": "Chile",
          "TC": "智利",
          "SC": "智利"
        },
        {
          "key": "China",
          "EN": "China",
          "TC": "中國",
          "SC": "中国"
        },
        {
          "key": "Colombia",
          "EN": "Colombia",
          "TC": "哥倫比亞",
          "SC": "哥伦比亚"
        },
        {
          "key": "Costa Rica",
          "EN": "Costa Rica",
          "TC": "哥斯大黎加",
          "SC": "哥斯达黎加"
        },
        {
          "key": "Cuba",
          "EN": "Cuba",
          "TC": "古巴",
          "SC": "古巴"
        },
        {
          "key": "Czech Republic",
          "EN": "Czech Republic",
          "TC": "捷克",
          "SC": "捷克"
        },
        {
          "key": "Denmark",
          "EN": "Denmark",
          "TC": "丹麥",
          "SC": "丹麦"
        },
        {
          "key": "Finland",
          "EN": "Finland",
          "TC": "芬蘭",
          "SC": "芬兰"
        },
        {
          "key": "France",
          "EN": "France",
          "TC": "法國",
          "SC": "法国"
        },
        {
          "key": "Georgia",
          "EN": "Georgia",
          "TC": "格魯吉亞",
          "SC": "格鲁吉亚"
        },
        {
          "key": "Germany",
          "EN": "Germany",
          "TC": "德國",
          "SC": "德国"
        },
        {
          "key": "Greece",
          "EN": "Greece",
          "TC": "希臘",
          "SC": "希腊"
        },
        {
          "key": "Greenland",
          "EN": "Greenland",
          "TC": "格陵蘭",
          "SC": "格陵兰"
        },
        {
          "key": "Guatemala",
          "EN": "Guatemala",
          "TC": "瓜地馬拉",
          "SC": "危地马拉"
        },
        {
          "key": "Honduras",
          "EN": "Honduras",
          "TC": "洪都拉斯",
          "SC": "洪都拉斯"
        },
        {
          "key": "Hong Kong (China)",
          "EN": "Hong Kong (China)",
          "TC": "香港 (中國)",
          "SC": "香港 (中国)"
        },
        {
          "key": "Hungary",
          "EN": "Hungary",
          "TC": "匈牙利",
          "SC": "匈牙利"
        },
        {
          "key": "Iceland",
          "EN": "Iceland",
          "TC": "冰島",
          "SC": "冰岛"
        },
        {
          "key": "India",
          "EN": "India",
          "TC": "印度",
          "SC": "印度"
        },
        {
          "key": "Indonesia",
          "EN": "Indonesia",
          "TC": "印尼",
          "SC": "印度尼西亚"
        },
        {
          "key": "Ireland",
          "EN": "Ireland",
          "TC": "愛爾蘭",
          "SC": "爱尔兰"
        },
        {
          "key": "Italy",
          "EN": "Italy",
          "TC": "義大利",
          "SC": "意大利"
        },
        {
          "key": "Japan",
          "EN": "Japan",
          "TC": "日本",
          "SC": "日本"
        },
        {
          "key": "Korea",
          "EN": "Korea",
          "TC": "韓國",
          "SC": "韩国"
        },
        {
          "key": "Macau (China)",
          "EN": "Macau (China)",
          "TC": "澳門 (中國)",
          "SC": "澳门 (中国)"
        },
        {
          "key": "Malaysia",
          "EN": "Malaysia",
          "TC": "馬來西亞",
          "SC": "马来西亚"
        },
        {
          "key": "Netherlands",
          "EN": "Netherlands",
          "TC": "荷蘭",
          "SC": "荷兰"
        },
        {
          "key": "New Zealand",
          "EN": "New Zealand",
          "TC": "紐西蘭",
          "SC": "新西兰"
        },
        {
          "key": "Norway",
          "EN": "Norway",
          "TC": "挪威",
          "SC": "挪威"
        },
        {
          "key": "Philippines",
          "EN": "Philippines",
          "TC": "菲律賓",
          "SC": "菲律宾"
        },
        {
          "key": "Portugal",
          "EN": "Portugal",
          "TC": "葡萄牙",
          "SC": "葡萄牙"
        },
        {
          "key": "Russia",
          "EN": "Russia",
          "TC": "俄羅斯",
          "SC": "俄罗斯"
        },
        {
          "key": "Singapore",
          "EN": "Singapore",
          "TC": "新加坡",
          "SC": "新加坡"
        },
        {
          "key": "Spain",
          "EN": "Spain",
          "TC": "西班牙",
          "SC": "西班牙"
        },
        {
          "key": "Sri Lanka",
          "EN": "Sri Lanka",
          "TC": "斯里蘭卡",
          "SC": "斯里兰卡"
        },
        {
          "key": "Sweden",
          "EN": "Sweden",
          "TC": "瑞典",
          "SC": "瑞典"
        },
        {
          "key": "Switzerland",
          "EN": "Switzerland",
          "TC": "瑞士",
          "SC": "瑞士"
        },
        {
          "key": "Taiwan",
          "EN": "Taiwan",
          "TC": "臺灣",
          "SC": "台湾"
        },
        {
          "key": "Thailand",
          "EN": "Thailand",
          "TC": "泰國",
          "SC": "泰国"
        },
        {
          "key": "Turkey",
          "EN": "Turkey",
          "TC": "土耳其",
          "SC": "土耳其"
        },
        {
          "key": "United Kingdom",
          "EN": "United Kingdom",
          "TC": "英國",
          "SC": "英国"
        },
        {
          "key": "United States",
          "EN": "United States",
          "TC": "美國",
          "SC": "美国"
        },
        {
          "key": "Vietnam",
          "EN": "Vietnam",
          "TC": "越南",
          "SC": "越南"
        },
        {
          "key": "Other Countries",
          "EN": "Other Countries",
          "TC": "其他國家",
          "SC": "其他国家"
        }
       ];

    return <>
      <option default hidden>--</option>
      {countries.map((c)=><option key={c.key} value={c.key}>{c[lang]}</option>)}
      </>
}
