import React from "react";
import { useTranslation } from 'react-i18next';

export default function Industry() {
    const { t } = useTranslation();

    return <>
        <option value="" default hidden>--</option>
        <option value={"Arts, entertainment and recreation"}>{t('Arts, entertainment and recreation')}</option>
        <option value={"Catering"}>{t('Catering')}</option>
        <option value={"Construction"}>{t('Construction ')}</option>
        <option value={"Education"}>{t('Education ')}</option>
        <option value={"Elderly Care & Service Centre"}>{t('Elderly Care & Service Centre')}</option>
        <option value={"Electricity and gas supply"}>{t('Electricity and gas supply')}</option>
        <option value={"Financial and insurance"}>{t('Financial and insurance')}</option>
        <option value={"Food and beverage"}>{t('Food and beverage')}</option>
        <option value={"Health and social work"}>{t('Health and social work')}</option>
        <option value={"Hotel"}>{t('Hotel ')}</option>
        <option value={"Import/export, wholesale and retail trades"}>{t('Import/export, wholesale and retail trades')}</option>
        <option value={"Information and communications"}>{t('Information and communications')}</option>
        <option value={"Innovation Technology"}>{t('Innovation Technology')}</option>
        <option value={"Manufacturing"}>{t('Manufacturing')}</option>
        <option value={"NGO"}>{t('NGO')}</option>
        <option value={"Professional, business Consulting"}>{t('Professional, business Consulting')}</option>
        <option value={"Real estate and property management"}>{t('Real estate and property management')}</option>
        <option value={"Social enterprise"}>{t('Social enterprise')}</option>
        <option value={"Transportation, storage and courier services"}>{t('Transportation, storage and courier services')}</option>
        <option value={"Tourism"}>{t('Tourism')}</option>
        <option value={"Waste management & Recycling"}>{t('Waste management & Recycling')}</option>
        <option value={"Service"}>{t('Service ')}</option>
        <option value={"Others"}>{t('Others')}</option>
    </>
}