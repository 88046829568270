import React, { useEffect, useState } from "react";
import { getApiClient } from "../../util/auth";
import { getTextByCode } from "../../util/lang";
import { Link, useNavigate } from "react-router-dom";

export default function ClassItem(props) {
    const classItem = props.classItem;
    const courseId = props.courseId;
    const class_id = classItem.id;
    // const module_id = classItem.ModuleId;
    const viewingClass = props.viewingClass;
    // const viewed_class_responses = props.viewed_class_responses;
    // const course_id = classItem.CourseId;
    const [finished, set_finished] = useState(false);
    const [is_hardcode, set_is_hardcode] = useState(false);

    // useEffect(() => {
    //     set_finished(viewed_class_responses?.[module_id]?.viewed_class_id.includes(class_id));
    // }, [props.viewed_class_responses]);

    const hightlight = classItem.id === viewingClass.id && 'bg-f2f2f2';
    const tickboxBgColor = finished ? "#eb6201" : "#ffffff";

    // const markClassAsFinished = () => {
    //     const params = {
    //         course_id: course_id,
    //         module_id: module_id,
    //         class_id: class_id,
    //     }
    //     getApiClient().post('/user/saveViewClassStatus', params)
    //         .then((response)=>{
    //             set_finished(true);
    //             console.log(response.data.message);
    //         })
    //         .catch((error) => {
    //             console.log(error.response);
    //             alert(error.response.data.message);
    //         });
    // }

    return <div
        className={`text-4a4a4a p-3 ${hightlight} cursor-pointer`}
        style={
            classItem?.status === "Done"
                ? { opacity: 0.5 }
                : {}
        }
    >
        <Link className="classChange-btn text-4a4a4a text-decoration-none false cursor-pointer" to={"/courses/show-module-data/" + courseId + "/" + class_id} style={hightlight ? { fontWeight: 'bold' } : {}}>
            Class {classItem?.Class_Index}: {classItem?.Class_Title_EN}
        </Link>
        {/* <div onClick={()=>props.handleSelectClass(classItem.id)}
             style={hightlight ? {fontWeight:'bold'}: {}}>
            Class {classItem?.id}: {classItem?.Class_Title_EN}
        </div> */}

        <HardcodeCheckbox is_hardcode={{ is_hardcode }} finished={{ finished }} tickboxBgColor={{ tickboxBgColor }}></HardcodeCheckbox>

    </div>;
}

function HardcodeCheckbox(props) {
    if (!props.is_hardcode) {
        return <div>
            <label className={"form-check-label me-2"}>
                {props.finished ? 'Finished' : 'Pending'}
            </label>

            <input
                className="form-check-input"
                checked={props.finished}
                type="checkbox"
                style={{
                    backgroundColor: props.tickboxBgColor,
                    borderColor: "#eb6201",
                }}
                onChange={(event) => {
                    event.preventDefault();

                    // if (!finished) {
                    //     markClassAsFinished();
                    // }
                }}
            />
        </div>
    }
    else {
        return <div></div>;
    }
}