import Layout from "../../../../components/LayoutCms";
import React, {useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import CourseTabNavigation from "../../../../components/CourseTabNavigation";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import CourseTitle from "../../../../components/CourseTitle";
import {getApiClient} from "../../../../util/auth";
import {useNavigate, useParams} from "react-router-dom";

export default function AdminMembershipViewEvaluationForm(props) {
  const navigate = useNavigate();
  const { course_id } = useParams();

  const role = localStorage.getItem("role");

  const [showModel, setShowModel] = useState(false);
  const checkbox_class =
      "form-check-input rounded-0 shadow-none bg-eb6201-checked border-eb6201-checked background-image-initial";

  const [evaluation_template_questions, set_evaluation_template_questions] = useState();
  const [long_question_one, set_long_question_one] = useState();
  const [long_question_two, set_long_question_two] = useState();
  const [template_question_ids, set_template_question_ids] = useState([]);
  const [own_evaluation_form, set_own_evaluation_form] = useState();

  const [readOnly, set_readOnly] = useState(props.readOnly);
  const [isEditing, set_is_editing] = useState(props.isEditing);
  const [status, set_status] = useState('');

  const get_long_question_1 = (data) => {
    const long_questions = data?.evaluation_questions?.filter((evaluation_question) => {
      return evaluation_question?.Question_Type === 'Long';
    });

    if (!long_questions) {
      return null;
    }

    return long_questions[0] ?? null;
  };

  const get_long_question_2 = (data) => {
    const long_questions = data?.evaluation_questions?.filter((evaluation_question) => {
      return evaluation_question?.Question_Type === 'Long';
    });

    if (!long_questions) {
      return null;
    }

    return long_questions[1] ?? null;
  };

  const find_short_question_id = (question) => {
    const questions = own_evaluation_form?.evaluation_questions?.filter((evaluation_question) => {
      return evaluation_question?.Question === question;
    });

    if (!questions) {
      return null;
    }

    return questions[0]?.id ?? undefined;
  };

  const isArchive = () => {
    return status === 'Archive';
  }

  useEffect(() => {
    if (course_id) {
      // Get Evaluation Form for Edit
      getApiClient()
          .post("/user/listEvaluationTemplateQuestions")
          .then((template_response) => {
            set_evaluation_template_questions(template_response.data);

            getApiClient()
                .post("/admin/viewCourse", {
                  course_id: course_id,
                })
                .then((response) => {
                  set_own_evaluation_form(response.data);

                  const ids = [];

                  for (const evaluation_question of response.data?.evaluation_questions) {
                    for (const evaluation_template_question of template_response.data?.evaluation_template_questions) {
                      if (
                          evaluation_question?.Question === evaluation_template_question?.Question
                            && evaluation_question?.Active_Status === 'Active'
                            && evaluation_question?.Question_Type === 'Short'
                      ) {
                        ids.push(evaluation_template_question.id);
                      }
                    }
                  }
                  const long_question_1 = get_long_question_1(response.data);
                  const long_question_2 = get_long_question_2(response.data);

                  set_long_question_one(long_question_1?.Question ?? '');
                  set_long_question_two(long_question_2?.Question ?? '');
                  set_template_question_ids(ids);
                })
                .catch((error) => {
                  console.log(error);
                });
          })
          .catch((error) => {
            console.log(error);
          });

      // Get course object to read its status
      getApiClient()
        .post("/admin/viewCourse", {
          course_id: course_id,
        })
        .then((response) => {
            set_status(response?.data?.course?.[0]?.Course_Status);
        });
    }
  }, []);

  const handleActiveAll = (event) => {
    if (event.target.checked) {
      const ids = [];
      for (const evaluation_template_question of evaluation_template_questions?.evaluation_template_questions) {
        ids.push(evaluation_template_question.id);
      }
      set_template_question_ids([]);
      set_template_question_ids(ids);
    } else {
      set_template_question_ids([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event.target);
    const templates = [];
    let total_actived_questions = 0; // for checking minimum select 7 evaluation questions
    for (const evaluation_template_question of evaluation_template_questions?.evaluation_template_questions) {
      templates.push({
        id: find_short_question_id(evaluation_template_question.Question),
        question: evaluation_template_question.Question,
        active_status: template_question_ids.includes(evaluation_template_question.id) ? "Active" : "Disable",
        question_type: "Short",
      });

      if(template_question_ids.includes(evaluation_template_question.id)) {
        total_actived_questions += 1;
      }
    }

    if(total_actived_questions < 7) {
      alert('Please select minimum 7 evaluation questions before save the evaluation form.');
      return;
    }
  };

  const printModal = async() => {
    let elem = document.getElementsByClassName("print-content");
    let domClone = elem[0].cloneNode(true);

    let $printSection = document.getElementById("printSection");

    if(!$printSection) {
        let $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
    }

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
  }

  return (
    <Layout route={'membership.viewCourses'}>
      <Modal
          show={showModel}
          onHide={() => {
            setShowModel(false);
          }}
          size={"xl"}
          scrollable={true}
      >
        <Modal.Body className={"p-0"}>
          <div className="print-content">
            <Container className={"p-3"}>
              <Row className={"align-items-center mb-2"}>
                <Col>
                  <img src="/assets/images/logo.png" width={175} />
                </Col>
                <Col xs={"auto"}>
                  <Button
                      className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                      type={"button"}
                      onClick={printModal}
                  >
                    Print
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className={"fw-bold mb-3"}>
                  <div>Evaluation Result</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className={"mb-2"}>
                    <Col>Course code</Col>
                    <Col>CC123456</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Course owner</Col>
                    <Col>BCDE Limited</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Username</Col>
                    <Col>123456</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Staff name</Col>
                    <Col>Lee Wai Chi</Col>
                  </Row>
                </Col>
                <Col>
                  <Row className={"mb-2"}>
                    <Col>Course name</Col>
                    <Col>ISO 90001Foundation training</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Quiz Date</Col>
                    <Col>17 March 2022</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Staff ID</Col>
                    <Col>123456</Col>
                  </Row>
                  <Row className={"mb-2"}>
                    <Col>Email</Col>
                    <Col>leewc2@gmail.com</Col>
                  </Row>
                </Col>
              </Row>
            </Container>

            <div style={{ background: "#E5F3FF" }} className={"p-3"}>
              <Container className={"p-0"}>
                <Row>
                  <Col xs={6} className={"fw-bold mb-2"}>Rating</Col>
                  <Col className={"text-center"}>1</Col>
                  <Col className={"text-center"}>2</Col>
                  <Col className={"text-center"}>3</Col>
                  <Col className={"text-center"}>4</Col>
                </Row>
                <Row>
                  <Col xs={6}>1. Knowledge of the subject matter.</Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input
                          className={checkbox_class}
                          type={"checkbox"}
                          checked={true}
                      />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>2. Ability to explain and illustrate concepts.</Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input
                          className={checkbox_class}
                          type={"checkbox"}
                          checked={true}
                      />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="form-check">
                      <input className={checkbox_class} type={"checkbox"} />
                    </div>
                  </Col>
                </Row>
              </Container>

              <hr />

              <Container className={"py-3"}>
                <Row>
                  <Col>
                    <form>
                      <label>Feedback</label>
                      <textarea
                          className="form-control"
                          rows="5"
                          style={{ resize: "none" }}
                          placeholder={"I like the course very much because it helps me a lot on my job. Trainer are nice. It's all good."}
                      />
                    </form>
                  </Col>
                </Row>
              </Container>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <CourseTitle readOnly={readOnly} isEditing={isEditing} isAdmin={true} />

      <CourseTabNavigation readOnly={readOnly} isEditing={isEditing} activeNavItem={"evaluation-form"} />

      <form onSubmit={handleSubmit}>
        <ShadowContainer>
          <div className={"mb-3"}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                onChange={handleActiveAll}
                disabled={readOnly || isArchive()}
              />
              <label className="form-check-label">Active All</label>
            </div>
          </div>

          <div className={"mb-3"}>
            <div className={"font-size-14px"}>
              Evaluation Questions{" "}
              <span style={{ color: "#A2A2A2" }}>
                (Learner will answer the evaluation form by rating from 1 - 7 &
                NA)
              </span>
            </div>
            <div className={"text-eb6201 font-size-12px"}>
              Minimum Select 7 Evaluation Questions
            </div>
          </div>

          {evaluation_template_questions?.evaluation_template_questions?.map((evaluation_template_question, index) => (
            <div className={"mb-3"} key={index}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={template_question_ids.includes(evaluation_template_question.id)}
                  disabled={readOnly || isArchive()}
                  onChange={(event) => {
                    const id = evaluation_template_question.id;

                    if (event.target.checked) {
                      set_template_question_ids([...template_question_ids, id]);
                    } else {
                      const filtered = template_question_ids.filter((item_id) => {
                        return item_id != id;
                      });

                      set_template_question_ids(filtered);
                    }
                  }}
                />
                <label className="form-check-label">{evaluation_template_question?.Question}</label>
              </div>
            </div>
          ))}
        </ShadowContainer>

        <ShadowContainer>
          <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">Question 1</label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                value={long_question_one}
                onChange={(e) => set_long_question_one(e.target.value)}
                readOnly={readOnly}
                disabled={readOnly || isArchive()}
              ></textarea>
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">Question 2</label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                value={long_question_two}
                onChange={(e) => set_long_question_two(e.target.value)}
                readOnly={readOnly}
                disabled={readOnly || isArchive()}
              ></textarea>
            </div>
          </div>
        </ShadowContainer>

        <div
          className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
          style={{ left: 240 }}
        >
          <div className={"d-flex"}>
            {/* <div className={"me-3"}>
              <Button
                  className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                  type={"button"}
                  onClick={() => {
                    setShowModel(true);
                  }}
              >
                Evaluation result (delete later)
              </Button>
            </div> */}
            <div className={"me-3"}>
              <Button variant={"secondary"} type={"button"} onClick={()=>navigate('/cms/admin/membership/viewCourses')}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
      <div style={{height:100}}></div>
    </Layout>
  );
}
