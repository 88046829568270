import React, { useRef, useState } from 'react'

import { Form } from 'react-bootstrap'

import ReactCrop, { centerCrop, makeAspectCrop, } from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { getProcessedBlob } from "./imagePreviewUtils";
import PrimaryButton from "../PrimaryButton";

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier hence we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function ImageCrop(
  {
    initialImageSource,
    onUploadFile = () => {
    },
    aspectRatio: aspect = 1,
  }) {
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)

  function onImageLoad(e) {
    const {width, height} = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect))
    setScale(1)
    setRotate(0)
  }

  async function handleOnUpload() {
    const blob = await getProcessedBlob(
      imgRef.current,
      completedCrop,
      scale,
      rotate,
      initialImageSource.type
    )
    const convertedFile = new File([blob], initialImageSource.name, {
      type: initialImageSource.type,
      lastModified: initialImageSource.lastModified
    })
    onUploadFile(convertedFile)
  }

  return (
    <div>
      {Boolean(initialImageSource.preview) && (
        <div>
          <div>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={initialImageSource.preview}
                style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
          <div>
            <Form.Label>Zoom {scale}x</Form.Label>
            <Form.Range
              value={scale}
              min={1}
              max={5}
              step={0.1}
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </div>
          <div>
            <Form.Label>Rotate</Form.Label>
            <Form.Range
              value={rotate}
              min={-180}
              max={180}
              step={1}
              onChange={(e) =>
                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
              }
            />
          </div>
          <div>
            <PrimaryButton onClick={handleOnUpload}>
              Upload Image
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  )
}
