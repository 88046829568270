 import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button} from "react-bootstrap";
import {getApiClient} from "../../util/auth";
import {getTextByCode} from "../../util/lang";
import moment from "moment";

export default function EvaluationModal (props) {
    const checkbox_class =
        "form-check-input rounded-0 shadow-none bg-eb6201-checked border-eb6201-checked background-image-initial";
    const api = getApiClient();

    const [learnerRole, setLearnerRole] = useState("");

    const [evaluation_result_response, set_evaluation_result_response] = useState();
    const [multiple_choice_questions, set_multiple_choice_questions] = useState();
    const [question_answers, set_question_answers] = useState({});
    const [long_questions, set_long_questions] = useState();

    useEffect(() => {
        if (!(props?.learner_id && props?.course?.id)) {
            return;
        }
        api.post('/user/viewLearnerEvaluationResultByCourse', {
            learner_id: props.learner_id,
            course_id: props.course.id,
        })
            .then((response) => {
                set_evaluation_result_response(response.data);

                setLearnerRole(response.data.role);

                const filtered_multiple_choice_questions = response.data?.evalutaion_questions_list
                    ?.filter((question) => {
                        return question?.Question_Type == "Short";
                    })
                    ?.filter((question) => {
                        return question?.Active_Status == "Active";
                    });
                set_multiple_choice_questions(filtered_multiple_choice_questions);


                let choice_answers = {};
                for (const answer of response.data?.learner_evaluation_answer ?? []) {
                    choice_answers[answer.EvaluationQuestionId] = answer.Learner_Answer;
                }
                set_question_answers(choice_answers);


                const filtered_long_questions = response.data?.evalutaion_questions_list
                    ?.filter((question) => {
                        return question?.Question_Type == "Long";
                    })
                    ?.filter((question) => {
                        return question?.Active_Status == "Active";
                    });
                set_long_questions(filtered_long_questions);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.learner_id, props.course]);

    const printModal = async() => {
        let elem = document.getElementsByClassName(`print-EvaluationContent-${evaluation_result_response?.username}`);
        let domClone = elem[0].cloneNode(true);

        let $printSection = document.getElementById("printSection");

        if(!$printSection) {
            let $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }

    return (
        <>
            <Modal
                show={props.is_show_modal}
                onHide={() => {
                    props.on_modal_hide();
                }}
                size={"xl"}
                scrollable={true}
            >
                <Modal.Body className={"p-0"}>
                    <div className={`print-EvaluationContent-${evaluation_result_response?.username}`}>
                        <Container className={"p-3"}>
                            <Row className={"align-items-center mb-2"}>
                                <Col>
                                    <img src="/assets/images/logo.png" width={175} />
                                </Col>
                                <Col xs={"auto"}>
                                    <Button
                                        className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                                        type={"button"}
                                        onClick={printModal}
                                    >
                                        Print
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"fw-bold mb-3"}>
                                    <div>Evaluation Result</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className={"mb-2"}>
                                        <Col>Course code</Col>
                                        <Col>{ evaluation_result_response?.course_code }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Course owner</Col>
                                        <Col>{ evaluation_result_response?.course_owner }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Username</Col>
                                        <Col>{ evaluation_result_response?.username }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>{ learnerRole === "Association Learner" ? "Member name" : "Staff name" }</Col>
                                        <Col>{ evaluation_result_response?.learner_name }</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className={"mb-2"}>
                                        <Col>Course name</Col>
                                        <Col>{ getTextByCode(props?.course?.Course_Name, 'EN') }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Evaluation Date</Col>
                                        <Col>{ evaluation_result_response?.evaluation_date ? moment(evaluation_result_response?.evaluation_date).format('Y-MM-DD') : '' }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>{ learnerRole === "Association Learner" ? "Member ID" : "Staff ID" }</Col>
                                        <Col>{ learnerRole === "Association Learner" ? evaluation_result_response?.membership_no : evaluation_result_response?.staff_id }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Email</Col>
                                        <Col>{ evaluation_result_response?.email }</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        <div style={{ background: "rgba(162, 162, 162, 0.1)" }} className={"p-3"}>
                            <Container className={"p-0"}>
                                <Row>
                                    <Col xs={6} className={"fw-bold mb-2"}>Rating</Col>
                                    <Col className={"text-center"}>1</Col>
                                    <Col className={"text-center"}>2</Col>
                                    <Col className={"text-center"}>3</Col>
                                    <Col className={"text-center"}>4</Col>
                                    <Col className={"text-center"}>5</Col>
                                    <Col className={"text-center"}>6</Col>
                                    <Col className={"text-center"}>7</Col>
                                    <Col className={"text-center"}>N/A</Col>
                                </Row>
                                {
                                    multiple_choice_questions?.map((evaluation_question, index) => (
                                        <Row key={evaluation_question.id}>
                                            <Col xs={6}>{index + 1}. {evaluation_question?.Question}</Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '1'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '2'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '3'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '4'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '5'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '6'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == '7'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={question_answers?.[evaluation_question?.id] == 'N/A'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Container>

                            <hr />

                            <div className={"mb-3"}>
                                <Container className={"p-3"}>
                                    <Row>
                                        <Col>
                                            Feedback
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                            <Container className={"p-0"}>
                                {
                                    long_questions?.map((question, index) => (
                                        <Row className={"gy-3"} key={question?.id}>
                                            <Col>
                                                <div>{index + 1}. {question?.Question}</div>
                                                <div>{question_answers?.[question?.id]}</div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Container>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
