export function getHomeAssociations() {
  return [
    {
      id: 1,
      thumbnail: "/assets/images/Pharmaceutical distribution association_PDA_logo.png",
      title: "The Pharmaceutical Distributors Association of Hong Kong",
      short_description:
        "The Pharmaceutical Distributors Association of Hong Kong (PDA) was formed in March 2005. We have 13 committee members (at October 2016) and with more than 50 memberships in 2016. Members are wholesalers and Secondary Labelling manufacturing License holders with a full line of warehousing and supply chain operation in drugs and medical devices and GMP standard pharmaceutical Secondary Labelling operation. Our member companies provide a significant percentage on the smaller share of the remaining approximately 30% of the prescription medicines and over the counter drugs trade in Hong Kong. The Association was formed to provide information on matters relating to the wholesaling, retailing, Secondary Labelling and devices markets to its members. We valued the Association to improve the relationship between our member companies and the Government, Hospital Authority central procurement office, Department of Health (Pharmaceutical Service), healthcare related societies and the community.",
    },
    {
      id: 2,
      thumbnail:
        "/assets/images/Hong Kong Food Hygiene Administrators Association_HKFHAA_logo.png",
      title: "Hong Kong Food Hygiene Administrators Association",
      short_description:
        "The Association is enthusiastic to promote food hygiene in Hong Kong and are very concerned in hygiene operation and food safety in hotel/catering management. Over the years the Association has actively organized food hygiene training courses, seminars, activities and workshops, hoping to share the latest food hygiene and safety information with colleagues so as to enhance the level of food safety management in Hong Kong and neighboring cities.",
    },
    {
      id: 3,
      thumbnail: "/assets/images/The Hong Kong Food Council_logo.png",
      title: "The Hong Kong Food Council",
      short_description:
        "The Hong Kong Food Council was established in 1984 as an independent and non-profit organization. The members of the Association are major food groups and manufacturers in Hong Kong, covering a variety of food industries, including rice, sugar, salt, cooking oil, sauces, preserved fruits, candy and bread, vegetables and fruits, flour, canned food, tea, wine and catering services. Since its establishment, the Association has maintained close ties with relevant government departments and institutions, and has often held luncheon lectures, seminars, study tours, training courses and participated in large-scale food exhibitions, striving to promote exchanges among the industry and improve the quality of people in the industry.",
    },
    {
      id: 4,
      thumbnail: "/assets/images/Hong Kong Customer Contact Association_HKCCA_logo.png",
      title: "Hong Kong Customer Contact Association",
      short_description:
        "HKCCA is a non-profit organization representing the entire on-line customer service and interaction in Hong Kong. We invite all companies and organizations that engage in on-line customer interaction to join the association for promoting the well being of the industry. We are committed to improving customer satisfaction by supporting members in continuous quality improvement and productivity growth in contact centres and online customer interaction.",
    },
  ];
}

export function getAssociations(search = "") {
  var j = [
    {
      id: 1,
      thumbnail: "/assets/images/Pharmaceutical distribution association_PDA_logo.png",
      title: "The Pharmaceutical Distributors Association of Hong Kong",
      short_description:
        "The Pharmaceutical Distributors Association of Hong Kong (PDA) was formed in March 2005. We have 13 committee members (at October 2016) and with more than 50 memberships in 2016. Members are wholesalers and Secondary Labelling manufacturing License holders with a full line of warehousing and supply chain operation in drugs and medical devices and GMP standard pharmaceutical Secondary Labelling operation. Our member companies provide a significant percentage on the smaller share of the remaining approximately 30% of the prescription medicines and over the counter drugs trade in Hong Kong. The Association was formed to provide information on matters relating to the wholesaling, retailing, Secondary Labelling and devices markets to its members. We valued the Association to improve the relationship between our member companies and the Government, Hospital Authority central procurement office, Department of Health (Pharmaceutical Service), healthcare related societies and the community.",
    },
    {
      id: 2,
      thumbnail: "/assets/images/Hong Kong Food Hygiene Administrators Association_HKFHAA_logo.png",
      title: "Hong Kong Food Hygiene Administrators Association",
      short_description:
        "The Association is enthusiastic to promote food hygiene in Hong Kong and are very concerned in hygiene operation and food safety in hotel/catering management. Over the years the Association has actively organized food hygiene training courses, seminars, activities and workshops, hoping to share the latest food hygiene and safety information with colleagues so as to enhance the level of food safety management in Hong Kong and neighboring cities.",
    },
    {
      id: 3,
      thumbnail: "/assets/images/The Hong Kong Food Council_logo.png",
      title: "The Hong Kong Food Council",
      short_description:
        "The Hong Kong Food Council was established in 1984 as an independent and non-profit organization. The members of the Association are major food groups and manufacturers in Hong Kong, covering a variety of food industries, including rice, sugar, salt, cooking oil, sauces, preserved fruits, candy and bread, vegetables and fruits, flour, canned food, tea, wine and catering services. Since its establishment, the Association has maintained close ties with relevant government departments and institutions, and has often held luncheon lectures, seminars, study tours, training courses and participated in large-scale food exhibitions, striving to promote exchanges among the industry and improve the quality of people in the industry.",
    },
    {
      id: 4,
      thumbnail: "/assets/images/Hong Kong Customer Contact Association_HKCCA_logo.png",
      title: "Hong Kong Customer Contact Association",
      short_description:
        "HKCCA is a non-profit organization representing the entire on-line customer service and interaction in Hong Kong. We invite all companies and organizations that engage in on-line customer interaction to join the association for promoting the well being of the industry. We are committed to improving customer satisfaction by supporting members in continuous quality improvement and productivity growth in contact centres and online customer interaction.",
    },
  ];


  var jj = j;

  if (search === "") {
    jj = j;
  }
  else {
    jj = j.filter(function (e) {
      let t = e.title.toLowerCase().includes(search.toLowerCase());
      let d = e.short_description.toLowerCase().includes(search.toLowerCase());
      return t || d;
    });
  }
  return jj;
}

export function getAssociationsAPI(id) {
  var js = {
    "1": {
      id: 1,
      thumbnail: "/assets/images/Pharmaceutical distribution association_PDA_logo.png",
      title: "The Pharmaceutical Distributors Association of Hong Kong",
      short_description:
        "The Pharmaceutical Distributors Association of Hong Kong (PDA) was formed in March 2005. We have 13 committee members (at October 2016) and with more than 50 memberships in 2016. Members are wholesalers and Secondary Labelling manufacturing License holders with a full line of warehousing and supply chain operation in drugs and medical devices and GMP standard pharmaceutical Secondary Labelling operation. Our member companies provide a significant percentage on the smaller share of the remaining approximately 30% of the prescription medicines and over the counter drugs trade in Hong Kong. The Association was formed to provide information on matters relating to the wholesaling, retailing, Secondary Labelling and devices markets to its members. We valued the Association to improve the relationship between our member companies and the Government, Hospital Authority central procurement office, Department of Health (Pharmaceutical Service), healthcare related societies and the community.",
    },
    "2": {
      id: 2,
      thumbnail: "/assets/images/Hong Kong Food Hygiene Administrators Association_HKFHAA_logo.png",
      title: "Hong Kong Food Hygiene Administrators Association",
      short_description:
        "The Association is enthusiastic to promote food hygiene in Hong Kong and are very concerned in hygiene operation and food safety in hotel/catering management. Over the years the Association has actively organized food hygiene training courses, seminars, activities and workshops, hoping to share the latest food hygiene and safety information with colleagues so as to enhance the level of food safety management in Hong Kong and neighboring cities.",
    },
    "3": {
      id: 3,
      thumbnail: "/assets/images/The Hong Kong Food Council_logo.png",
      title: "The Hong Kong Food Council",
      short_description:
        "The Hong Kong Food Council was established in 1984 as an independent and non-profit organization. The members of the Association are major food groups and manufacturers in Hong Kong, covering a variety of food industries, including rice, sugar, salt, cooking oil, sauces, preserved fruits, candy and bread, vegetables and fruits, flour, canned food, tea, wine and catering services. Since its establishment, the Association has maintained close ties with relevant government departments and institutions, and has often held luncheon lectures, seminars, study tours, training courses and participated in large-scale food exhibitions, striving to promote exchanges among the industry and improve the quality of people in the industry.",
    },
    "4": {
      id: 4,
      thumbnail: "/assets/images/Hong Kong Customer Contact Association_HKCCA_logo.png",
      title: "Hong Kong Customer Contact Association",
      short_description:
        "HKCCA is a non-profit organization representing the entire on-line customer service and interaction in Hong Kong. We invite all companies and organizations that engage in on-line customer interaction to join the association for promoting the well being of the industry. We are committed to improving customer satisfaction by supporting members in continuous quality improvement and productivity growth in contact centres and online customer interaction.",
    }
  };

  return js[id];
}
