import LayoutCms from "../../../components/LayoutCms";
import CourseForm from "../../../components/CourseForm";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import TrainerCourseTabNavigation from "../../../components/TrainerCourseTabNavigation";
import ShadowContainer from "../../../components/ShadowContainer";
import PhoneDisplay from "../../../components/PhoneDisplay";
import { getApiClient } from "../../../util/auth";
import DataTable from 'react-data-table-component';
import moment from 'moment';

export default function EnrolledLearner(props) {
    const params = useParams();
    const course_id = params.course_id;
    const api = getApiClient();

    const [course_code, set_course_code] = useState('');
    const [course_name, set_course_name] = useState('');

    const [allEnroller, setAllEnroller] = useState([]);
    const [enrolledLearner, setEnrolledLearner] = useState([]);
    
    const fetch_enrollers_by_course_id = () => {
        api
            .post("/trainer_academy/viewEnrollersByCourseId", {
                course_id: course_id
            })
            .then((response) => {
                const course = response.data.course[0];
                set_course_code(course.Course_Code);
                const courseName = JSON.parse(course.Course_Name);
                set_course_name(courseName.EN);

                const allEnrollers = response.data.users;
                setAllEnroller(allEnrollers);

                let filteredEnrolledLearner = allEnrollers.filter((enroller) => {
                    return enroller.Enrollment_Status === 'Enrolled';
                });
                setEnrolledLearner(filteredEnrolledLearner);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetch_enrollers_by_course_id();
    }, []);

    const columns = [
        {
            name: "Enroll Date",
            selector: row => row.Enroll_Date,
            format: row => row.Enroll_Date ? moment(row.Enroll_Date).format('DD MMM YYYY') : '',
            sortable: true
        },
        {
            name: "Member Type",
            selector: row => row.Role,
            sortable: true
        },
        {
            name: "User ID",
            selector: row => row.id,
            sortable: true,
            grow: 0
        },
        {
            name: "Name",
            cell: (row) => {
                const first_name = JSON.parse(row.Contact_Person_First_Name);
                const last_name = JSON.parse(row.Contact_Person_Last_Name);
                return (<div>
                    <div><span title={first_name.EN + ' ' + last_name.EN}>{first_name.EN + ' ' + last_name.EN}</span></div>
                    <div><span title={first_name.TC + ' ' + last_name.TC}>{first_name.TC + ' ' + last_name.TC}</span></div>
                    <div><span title={first_name.SC + ' ' + last_name.SC}>{first_name.SC + ' ' + last_name.SC}</span></div>
                </div>);
            },
            sortable: true
        },
        {
            name: "Organization Name",
            grow: 2,
            cell: (row) => {
                if(row.Role === 'Individual Learner') {
                    return (<div>--</div>);
                } else if(row.Role === 'Organization') {
                    const organizationName = JSON.parse(row.Organization_Name);
                    return (<div>
                        <div><span title={organizationName.EN}>{organizationName.EN}</span></div>
                        <div><span title={organizationName.TC}>{organizationName.TC}</span></div>
                        <div><span title={organizationName.SC}>{organizationName.SC}</span></div>
                    </div>);
                } else if(row.Role === 'Association') {
                    const associationName = JSON.parse(row.Association_Name);
                    return (<div>
                        <div><span title={associationName.EN}>{associationName.EN}</span></div>
                        <div><span title={associationName.TC}>{associationName.TC}</span></div>
                        <div><span title={associationName.SC}>{associationName.SC}</span></div>
                    </div>);
                }
            },
            sortable: true
        },
        {
            name: "Email Address",
            cell: (row) => {
                if(row.Role === 'Individual Learner') {
                    return row.Personal_Email;
                } else if(row.Role === 'Organization') {
                    return row.Organization_Email;
                } else if(row.Role === 'Association') {
                    return row.Association_Email;
                }
            },
            sortable: true,
            width: '250px'
        },
        {
            name: "Contact Number",
            selector: row => <PhoneDisplay number={row.Mobile_Number} />,
            sortable: true,
            grow: 2
        },
        {
            name: "Token ID",
            selector: row => row.Token_ID,
            sortable: true
        },
        {
            name: "Order Qty",
            selector: row => row.Purchase_Course_Quantity,
            sortable: true
        }
    ];

    return (
        <LayoutCms route={'courses.show'}>
            <div className={"row align-items-center"}>
                <div className={"col-7"}>
                    <div className={"text-secondary h1"}>View Course</div>
                    <div className={"text-adb5bd"}>{course_code} {course_name}</div>
                </div>
            </div>

            <TrainerCourseTabNavigation activeNavItem={"enrolled_learner"} readOnly={true} />

            <ShadowContainer>
                <div className={"course-tbl-div"}>
                    <DataTable
                        columns={columns}
                        data={enrolledLearner}
                        pagination
                        paginationPerPage={30}
                        paginationRowsPerPageOptions={[30, 50, 100]}
                    />
                </div>

            </ShadowContainer>
        </LayoutCms>
    );
}
