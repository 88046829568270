import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import LayoutCms from "../../../components/LayoutCms";
import ShadowContainer from "../../../components/ShadowContainer";
import { getApiClient } from "../../../util/auth";
import { getLangNameByCode, parseDbStringToObj } from "../../../util/lang";
import PhoneDisplay from "../../../components/PhoneDisplay";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from 'react-i18next';

export default function ProfileCms() {
  const { i18n, t } = useTranslation();

  const [user, setUser] = useState('');

  const navigate = useNavigate();

  const api = getApiClient();
  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let res_user = response.data.user;

        if(response.data.user.Role !== 'Organization') {
          alert('User not authorized! You will go back to Home Page.');
          navigate('/');
          return;
        }

        //Conact Person
        res_user.Contact_Person_First_Name = parseDbStringToObj(res_user.Contact_Person_First_Name);
        res_user.Contact_Person_Last_Name = parseDbStringToObj(res_user.Contact_Person_Last_Name);
        // CONTACT - Address City Province Country (not general)
        res_user.Contact_Address = parseDbStringToObj(res_user.Contact_Address);
        res_user.Contact_City = parseDbStringToObj(res_user.Contact_City);
        res_user.Contact_Province = parseDbStringToObj(res_user.Contact_Province);
        res_user.Contact_Country = parseDbStringToObj(res_user.Contact_Country);
        // GENERAL - Address City Province Country
        res_user.General_Address = parseDbStringToObj(res_user.General_Address);
        res_user.General_City = parseDbStringToObj(res_user.General_City);
        res_user.General_Province = parseDbStringToObj(res_user.General_Province);
        res_user.General_Country = parseDbStringToObj(res_user.General_Country);

        res_user.About_Us_Descriptions = parseDbStringToObj(res_user.About_Us_Descriptions);

        setUser(res_user);

        //Organization only can view English version
        if(localStorage.getItem('i18nLanguage') !== "EN") {
          i18n.changeLanguage("EN");
          localStorage.setItem('i18nLanguage', "EN");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const companyName = JSON.parse(
    user?.Organization_Name ? user?.Organization_Name : "{}"
  );

  if (!user) {
    return <>Loading...</>;
  }

  return (
    <LayoutCms route={'profile.index'}>
      <div className={"text-secondary font-size-20px fw-700"}>View Profile</div>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>General Information</div>
        <Row className={"align-items-center mb-3"}>
          <Col xs={3}>
            <img
              src={user.Organization_Logo ? user.Organization_Logo : "https://via.placeholder.com/300"}
              alt="Organization Logo"
              className={"img-fluid"}
              width="300px"
              height="300px"
            />
          </Col>
          <Col>
            <div className={"break-word"}>{companyName.EN}</div>
            <div className={"break-word"}>{user.Organization_Email}</div>
          </Col>
        </Row>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>Company Name</Col>
              <Col xs={7} className={"font-black break-word"}>{companyName.EN}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Company Name (繁)</Col>
              <Col xs={7} className={"font-black break-word"}>{companyName.TC}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Company Name (简)</Col>
              <Col xs={7} className={"font-black break-word"}>{companyName.SC}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Business Registration</Col>
              <Col  xs={7} className={"text-eb6201"}><a href={user.Business_Registration} target="_blank" rel="noopener noreferrer">[View BR]</a></Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Industry</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Industry}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>No. of Staffs</Col>
              <Col  xs={7} className={"font-black break-word"}>{user.No_of_Staff}</Col>
            </Row>
            {/* <Row className={"mb-3"}>
              <Col xs={5}>Preferred Language</Col>
              <Col  xs={7} className={"font-black break-word"}>{getLangNameByCode(user.Preferred_Language)}</Col>
            </Row> */}
            <Row className={"mb-3"}>
              <Col xs={5}>Company Website</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Organization_Website}</Col>
            </Row>

          </Col>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={3}>Country</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Country.EN}
                </div>
              </Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={3}>Address</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.EN}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.EN}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.EN}
                </div>
              </Col>
            </Row>



            <Row className={"mb-3"}>
              <Col xs={3}>Address (繁)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.TC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.TC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.TC}
                </div>
              </Col>
            </Row>



            <Row className={"mb-3"}>
              <Col xs={3}>Address (简)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.SC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.SC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.SC}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Contact Information</div>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Salutation !== '--' ? user.Salutation : ''}&nbsp;
                {user.Contact_Person_First_Name.EN}&nbsp;
                {user.Contact_Person_Last_Name.EN}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person (繁)</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Contact_Person_Last_Name.TC}
                {user.Contact_Person_First_Name.TC}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person (简)</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Contact_Person_Last_Name.SC}
                {user.Contact_Person_First_Name.SC}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Title</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Title}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Department</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Department_1}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>E-mail</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Organization_Email}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Phone No.</Col>
              <Col xs={7} className={"font-black break-word"}>
                <PhoneDisplay number={user?.Mobile_Number} />
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Fax No.</Col>
              <Col xs={7} className={"font-black break-word"}>
                <PhoneDisplay number={user?.Fax_Number} />
              </Col>
            </Row>
          </Col>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={3}>Country</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Country.EN}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Address.EN}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_City.EN}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Province.EN}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address (繁)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Address.TC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_City.TC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Province.TC}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address (简)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Address.SC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_City.SC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Province.SC}</div>
              </Col>
            </Row>

          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Account Information</div>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>Registered Date</Col>
              <Col xs={7} className={"font-black break-word"}>{moment(user?.createdAt)?.format('DD MMM YYYY')}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Second Email</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Personal_Email_2nd}</Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>About Us</div>
        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.EN}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description (繁)</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.TC}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description (简)</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.SC}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>Company Photos</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black"}>
                {user.About_Us_Company_Photo ?
                  <img
                    src={user.About_Us_Company_Photo}
                    alt="About_Us_Company_Photo"
                    className={"img-fluid"}
                    width="300px"
                    height="300px"
                  />
                  :
                  ''
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Certificate</div>

        <Row>
          <Col>
            <Row className={"mb-3"}>
              <Col xs={5}>Company Logo</Col>
              <Col className={"font-black"}>{user.Certificate_Company_Logo_Show ? 'Shown' : 'Not Shown'}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Company Name</Col>
              <Col className={"font-black"}>{user.Certificate_Company_Name_Show ? 'Shown' : 'Not Shown'}</Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>
    </LayoutCms>
  );
}
