import Layout from "../../components/Layout";
import H2 from "../../components/H2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { coursesDataHardcode } from "../../services/courses-service";
import { getApiClient } from "../../util/auth";
import { parseDbStringToObj, getLangNameByCode } from "../../util/lang";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

const CourseShowHC = () => {
  const { t } = useTranslation();

  const api = getApiClient();
  const navigate = useNavigate();
  const location = useLocation();

  const role = localStorage.getItem("role");
  const user = localStorage.getItem("user");

  const params = useParams();
  let course_id = parseInt(params.id);

  const [course, setCourse] = useState([]);
  const [modules, setModules] = useState([]);
  const [classes, setClasses] = useState([]);
  const [is_created_quiz, set_is_created_quiz] = useState('No');
  const [preview_video, set_preview_video] = useState(null);
  const [course_photo, set_course_photo] = useState(null);

  const [course_owner_role, set_course_owner_role] = useState(null);
  const [course_owner_name, set_course_owner_name] = useState(null);
  const [logined_name, set_logined_name] = useState(null);


  const [totalVideoTime, setTotalVideoTime] = useState(0);
  const [downloadableMaterial, setDownloadableMaterial] = useState("Yes");

  const [show, setShow] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginModalMessage, setLoginModalMessage] = useState(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
  const [tab, setTab] = useState('about_this_course');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [enrollments, setEnrollments] = useState([]);

  const [canStartCourseButton, setCanStartCourseButton] = useState('');

  const [disableEnrollButton, setDisableEnrollButton] = useState(false);

  useEffect(() => {
    fetch_view_course_module_class();
  }, []);

  const fetch_view_course_module_class = () => {
    let view_course_api_endpoint = `/public/view_course`;
    // if(role === 'Organization Learner') {
    //   view_course_api_endpoint = `/organization_learner/view_course`;
    // } else if(role === 'Individual Learner') {
    //   view_course_api_endpoint = `/individual_learner/view_course`;
    // } else if(role === 'Association Learner') {
    //   view_course_api_endpoint = `/association_learner/view_course`;
    // }
    api.post(view_course_api_endpoint, { course_id: course_id })
      .then(function (response) {
        setCourse(response.data.course[0]);
        setModules(response.data.modules);
        setClasses(response.data.classes);
        set_preview_video(response.data.course[0].Preview_Video);
        set_course_photo(response.data.course[0].Course_Photo);
        // console.log("preview_video : " + preview_video);
        // console.log("course_photo : " + course_photo);

        set_course_owner_role(response.data.course_owner_role);
        set_course_owner_name(parseDbStringToObj(response.data.course_owner_name).EN);
        
        if(role === "Association Learner") {
          set_logined_name(user ? parseDbStringToObj( JSON.parse(user).Association_Name ).EN : 'Undefined');
        }

        // for total video duration
        let response_classes = response.data.classes;
        let filtered_class_duration = response_classes.filter(the_class => the_class.Video_Duration !== null);
        let class_total_video_duration = 0;
        for(let i = 0; i < filtered_class_duration.length; i++) {
          class_total_video_duration += parseFloat(filtered_class_duration[i].Video_Duration);
        }
        class_total_video_duration = (class_total_video_duration / 60 / 60);
        setTotalVideoTime(Math.ceil(class_total_video_duration * 10) / 10);

        set_is_created_quiz(response.data.is_created_quiz);

        fetch_check_enrollment_status(
          response.data.course[0].Course_Fee, 
          response.data.course_owner_role, 
          parseDbStringToObj(response.data.course_owner_name).EN, 
          user ? parseDbStringToObj( JSON.parse(user).Association_Name ).EN : 'Undefined'
        );

        // checking is it smaller than 7 days before the course Off The Shelf Date
        if(moment(response.data.course[0].Off_The_Shelf_Date).utc(8).subtract(7, 'day').format('YYYY-MM-DD') < moment().utc(8).format('YYYY-MM-DD')) {
          setDisableEnrollButton(true);
        }
      })
      .catch((error) => {
        console.log("catch")
        console.log(error);
        // error.response.status === 400
        // error.response.data.message
      });
  }

  const fetch_check_enrollment_status = (course_fee, course_owner_role, course_owner_name, logined_name) => {
    if(role !== null) {
      // for already login
      api.post(`/user/checkEnrollmentStatusByCourseId`, { course_id: course_id })
        .then(function (response) {
          //console.log(response.data)
          const enrollments = response.data.enrollments;
          const exist_enrolled_courses = enrollments.filter(enrollment => enrollment.Enrollment_Status === 'Enrolled');
          const exist_enrolling_courses = enrollments.filter(enrollment => enrollment.Enrollment_Status === 'Enrolling');
          const exist_rejected_courses = enrollments.filter(enrollment => enrollment.Enrollment_Status === 'Rejected');

          const assigned = response.data.assigned;

          if(role === "Organization") {
            // Association can only enroll Trainer/Academy course
            if(course_owner_role === 'Trainer/Academy') {
              if(course_fee === "Pay" || course_fee === "Paid") {
                // Organization can enroll multiple times for their learners, so the button will always be "Enroll the course"
                setCanStartCourseButton('Enroll the course');
              } else {
                setCanStartCourseButton('No role');
                setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
              }
            } else {
              setCanStartCourseButton('No role');
              setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
            }
          } else if(role === "Association") {
            // Association can only enroll Trainer/Academy course
            if(course_owner_role === 'Trainer/Academy') {
              if(course_fee === "Pay" || course_fee === "Paid") {
                // Association can enroll multiple times for their learners, so the button will always be "Enroll the course"
                setCanStartCourseButton('Enroll the course');
              } else {
                setCanStartCourseButton('No role');
                setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
              }
              // Association can enroll to send email to the trainer, so the button will always be "Enroll the course"
            } else {
              setCanStartCourseButton('No role');
              setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
            }
          } else if(role === "Trainer/Academy") {
            // Trainer/Academy cannot enroll any courses
            setCanStartCourseButton('No role');
            if(course_owner_role === 'Trainer/Academy') {
              if(course_fee === "Pay" || course_fee === "Paid") {
                setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
              } else {
                setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
              }
            } else {
              setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
            }
          } else if(role === "Individual Learner") {
            // checking with different condition
            if(exist_enrolling_courses.length > 0) {
              setCanStartCourseButton('Pending');
            } else if(exist_enrolled_courses.length > 0 && exist_enrolling_courses.length == 0) {
              setCanStartCourseButton('Start the course');
            } else {
              if(course_owner_role === 'Trainer/Academy') {
                setCanStartCourseButton('Enroll the course');
              } else {
                // send email to Association
                setCanStartCourseButton('Enroll the course');
              }
            }
          } else if(role === "Organization Learner") {
            // checking with different condition
            if(assigned.length > 0) {
              setCanStartCourseButton('Start the course');
            } else {
              setCanStartCourseButton('No role');
              if(course_owner_role === 'Trainer/Academy') {
                if(course_fee === "Pay" || course_fee === "Paid") {
                  setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
                } else {
                  setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
                }
              } else {
                setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
              }
            }
          } else if(role === "Association Learner") {
            // checking with different condition
            if(assigned.length > 0) {
              setCanStartCourseButton('Start the course');
            } else {
              setCanStartCourseButton('No role');
              if(course_owner_role === 'Trainer/Academy') {
                if(course_fee === "Pay" || course_fee === "Paid") {
                  setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
                } else {
                  setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
                }
              } else {
                if(course_owner_name === logined_name) {
                  setLoginModalMessage('Please contact your Association directly.');
                } else {
                  setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
                }
              }
            }
          }
        })
        .catch((error) => {
          // Trainer/Academy cannot enroll any courses
          setCanStartCourseButton('No role');
          if(course_owner_role === 'Trainer/Academy') {
            if(course_fee === "Pay" || course_fee === "Paid") {
              setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
            } else {
              setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
            }
          } else {
            setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
          }
        });
    } else {
      // for no role (has not login yet)
      setCanStartCourseButton('No role');
      if(course_owner_role === 'Trainer/Academy') {
        if(course_fee === "Pay" || course_fee === "Paid") {
          setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.'));
        } else {
          setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
        }
      } else {
        setLoginModalMessage(t('You do not have permission to enroll the course. Please use the Individual account to enroll the course.'));
      }
    }
  }

  const fetch_enroll_course = () => {
    api.post(`/user/enrollCourse`, { course_id: course_id })
      .then(function (response) {
        if(response?.data?.message === `Enroll the course Success.`) {
          alert(t('The enrollment request for the course is well received and is now being processed. Please check your email with the enrollment details.'));
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(t('Enroll the course failed.'));
      });
  }

  const handleEnroll = () => {
    if(window.confirm(t("Are you sure to enroll this course?")) == true) {
      fetch_enroll_course();
    }
  }


  function checkRole() {
    if(canStartCourseButton === 'No role') {
      return <span
        variant="primary"
        className="enroll-btn w-100 btn bg-fbdfcc text-4a4a4a fw-bold rounded-0 p-5"
        onClick={() => {
          if(disableEnrollButton === true) {
            alert(t('This course will expire within 7 days. Members are currently unable to enroll in this course.'));
          } else {
            setShowLoginModal(true)
          }
        }}
      >
        {t('Enroll the course')}
      </span>
    } else if(canStartCourseButton === 'Enroll the course') {
      return <span 
        className="enroll-btn w-100 btn bg-fbdfcc text-4a4a4a fw-bold rounded-0 p-5" 
        onClick={() => {
          if(disableEnrollButton === true) {
            alert(t('This course will expire within 7 days. Members are currently unable to enroll in this course.'));
          } else {
            handleEnroll()
          }
        }}
      >
        {t('Enroll the course')}
      </span>
    } else if(canStartCourseButton === 'Start the course') {
      return <Link to={"/courses/show-module?course_id=" + course?.id} className="enroll-btn w-100 btn bg-fbdfcc text-4a4a4a fw-bold rounded-0 p-5">
        {t('Start the course')}
      </Link>
    } else if(canStartCourseButton === 'Pending') {
      return <span className="w-100 btn bg-6c757d text-white fw-bold rounded-0 p-5" style={{cursor:'not-allowed'}}>
        {t('Pending...')}
      </span>
    }
  }

  return (
    <Layout>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header className={"border-0 pb-0"} closeButton>
          <Modal.Title>
            <div style={{ fontSize: "initial" }}>{t('Course preview')}</div>
            <div>{parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"pb-0"}>
          <div className={"mb-3"}>
            {parseDbStringToObj(course.Course_Descriptions)[CURRENT_LANGUAGE]}
          </div>
          <VideoTab src={course.Preview_Video ?? false}></VideoTab>
        </Modal.Body>
        <Modal.Footer className={"border-0 pt-0"}>
          {checkRole()}
        </Modal.Footer>
      </Modal>
      <Modal show={showLoginModal} onHide={() => {
        setShowLoginModal(false)
      }} centered size="lg">
        <Modal.Header className={"border-0 pb-0"} closeButton />
        <Modal.Body className={"pb-0"}>
          <div className={"mb-3"}>
            {loginModalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer className={"border-0 pt-0"}>
          <Col>
            <Button
              variant="primary"
              className="w-100 bg-eb6201 text-white fw-bold shadow-none border-eb6201 my-0"
              onClick={() => {
                setShowLoginModal(false);
                navigate('/signup');
              }}
            >
              {t('Sign Up')}
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-100 bg-eb6201 text-white fw-bold shadow-none border-eb6201 my-0"
              onClick={() => {
                setShowLoginModal(false);
                navigate('/login');
              }}
            >
              {t('Log In')}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
      <div className={"border-top"} style={{ marginBottom: 28 }} />
      <Container className="max-width-1304">
        <Row>
          <Col className={"col-12 col-md-9"}>
            <Row>
              <Col>
                <div>{parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <H2 style={{ fontSize: "40px" }}>{parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}</H2>
            </Row>
            <Row className="mb-3">
              <Col>
              {course.Preview_Video === null
                ?
                <div
                  className={"position-relative"}
                >
                  <img
                    alt="course_image"
                    src={course.Course_Photo || '/assets/images/home_courses_thumbnail_2.png'}
                    className="img-fluid w-100"
                    style={{ aspectRatio: 137 / 86, objectFit: "cover" }}
                  />
                </div>
                :
                <div
                  className={"position-relative"}
                  onClick={handleShow}
                  style={{ cursor: "pointer" }}
                >

                  {/* for api data */}
                  <img
                    alt="course_image"
                    src={course.Course_Photo || '/assets/images/home_courses_thumbnail_2.png'}
                    className="img-fluid w-100"
                    style={{ aspectRatio: 137 / 86, objectFit: "cover" }}
                  />


                  {/* {videoUrl ? (
                    <iframe
                      src={videoUrl}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ aspectRatio: "16 / 9", width: "100%" }}
                    />
                  ) :
                    <img
                      alt="course_image"
                      src={course.Course_Photo}
                      className="img-fluid w-100"
                      style={{ aspectRatio: 137 / 86, objectFit: "cover" }}
                    />
                  } */}
                  <div
                    className={
                      "position-absolute bottom-0 start-0 end-0 text-center text-white py-2"
                    }
                    style={{ background: "rgb(74 74 74 / 80%)" }}
                  >
                    {t('Preview this course')}
                  </div>
                </div>
                }
              </Col>
            </Row>
            <Row className="g-5">
              <Col xs={"auto"}>
                <H2>
                  <button
                    className={tab === 'about_this_course' ? "btn p-0 shadow-0 fw-900 course-title" : 'btn p-0 shadow-0 fw-900 course-title'}
                    style={{
                      outline: "0", boxShadow: "none"
                    }}
                    onClick={() => {
                      setTab('about_this_course')
                    }}
                  >
                    {t('About this course')}
                  </button>
                </H2>

                {
                  tab === 'about_this_course'
                  &&
                  <div style={{ width: "100%" }} className="hkqaa-divider" />
                }
              </Col>
              <Col xs={"auto"}>
                <H2>
                  <button
                    className={tab === 'about_trainer' ? "btn p-0 shadow-0 fw-900 course-title" : 'btn p-0 shadow-0 fw-900 course-title'}
                    style={{ outline: "0", boxShadow: "none" }}
                    onClick={() => {
                      setTab('about_trainer')
                    }}
                  >
                    {t('About trainer')}
                  </button>
                </H2>

                {
                  tab === 'about_trainer'
                  &&
                  <div style={{ width: "100%" }} className="hkqaa-divider" />
                }
              </Col>
            </Row>
            <Row className="font-size-16px">
              <Col xs={"auto"} className="font-size-16px text-535353">
                {tab === 'about_this_course' ? <p style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>{parseDbStringToObj(course.Course_Descriptions)[CURRENT_LANGUAGE]}</p> : (
                  <>
                    <p>{t('Trainer')} : {course.Trainer}</p>
                    <p>{t('Trainer Organization')} : {course.Trainer_Organization}</p>
                    <div style={{ whiteSpace: "pre-line", verticalAlign: "bottom" }}>{course.Trainer_Description ? <p>{course.Trainer_Description}</p> : ""}</div>
                  </>
                )}
              </Col>
            </Row>
            {modules ?
              <>
                <Row className="mb-3">
                  <Col xs={"auto"}>
                    <H2 className="course-title">{t('Modules')}</H2>
                    <div style={{ width: "100%" }} className="hkqaa-divider" />
                  </Col>
                </Row>
                {modules.map((module, index) => (
                  <Row key={index} style={{ marginBottom: 33 }}>
                    <Col>
                      <div
                        className={"font-size-18px fw-bold"}
                      >
                        {t('Module')} {module.Module_Sequence ? module.Module_Sequence : ''}: {parseDbStringToObj(module.Module_Title)[CURRENT_LANGUAGE]}
                      </div>
                      <div
                        className={"font-size-16px"}
                        style={{ lineHeight: "24px", whiteSpace: "pre-line", verticalAlign: "bottom" }}
                      >
                        {parseDbStringToObj(module.Module_Descriptions)[CURRENT_LANGUAGE]}
                      </div>
                    </Col>
                  </Row>
                ))}
              </> : null}
          </Col>
          <Col>
            <div
              className="card card-body rounded-0"
              style={{ borderTopColor: "#eb6201", borderTopWidth: 3 }}
            >
              <div className={"font-size-18px fw-bold mb-3"}>
                {parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>{t('Module No.')}</div>
                <div className={"font-size-16px fw-900 text-767979"}>{modules ? modules.length : 0}</div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>{t('Class No.')}</div>
                <div className={"font-size-16px fw-900 text-767979"}>{classes ? classes.length : 0}</div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>
                  {t('Total Duration Hour')}
                </div>
                <div className={"font-size-16px fw-900 text-767979"}>
                  {totalVideoTime} {t('Hours')}
                </div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>
                  {t('Course Language')}
                </div>
                <div className={"font-size-16px fw-900 text-767979"}>{getLangNameByCode(course.Course_Master_Language)}</div>
                {/* <div className={"font-size-16px fw-900 text-767979"}>
                  {course.materialLanguages ?? Object.keys(course.materialLanguages).filter(function (key) {
                    return course.materialLanguages[key];
                  }).join(', ')}
                </div> */}
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>{t('Quiz')}</div>
                <div className={"font-size-16px fw-900 text-767979"}>{t(is_created_quiz)}</div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>{t('Certificate')}</div>
                <div className={"font-size-16px fw-900 text-767979"}>{course.Certificate_Offered ? t(course.Certificate_Offered) : t('No')}</div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>
                  {t('Material For Download')}
                </div>
                <div className={"font-size-16px fw-900 text-767979"}>{t(downloadableMaterial)}</div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>
                  {t('Course Due Date')}
                </div>
                <div className={"font-size-16px fw-900 text-767979"}>
                  {moment(course.Off_The_Shelf_Date)?.utc(8).format('DD MM YYYY')}
                </div>
              </div>
              <div className={"mb-3"}>
                <div className={"font-size-18px text-4a4a4a"}>
                  {t('Course Fee')}
                </div>
                <div className={"font-size-16px fw-900 text-767979"}>
                {course.Course_Fee === 'Pay' ? t('HKD') + ' ' + course.Course_Fee_Amount : t(course.Course_Fee)}
                </div>
              </div>
            </div>
            <div>
              {checkRole()}
            </div>
          </Col>
        </Row>
      </Container >
    </Layout >
  );
}


function VideoTab(props) {
  if (props.src) {
    return <video
      key={props.src}
      controlsList="nodownload"
      onContextMenu={e => e.preventDefault()}
      controls={true}
      className={"w-100 mh-100"}>
      <source src={props.src} />
    </video>
  }
  else {
    return;
  }
}


export default CourseShowHC;
