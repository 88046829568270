import React from "react";

export default function TermsAndConditionIndividualComponent(props) {
    if(props?.lang === "TC") {
        return (
            <div>
                <p style={{ textAlign: "justify" }}><strong>網站條款和條件(&ldquo;條款&rdquo;) &ndash;一般公眾</strong></p>
                <p style={{ textAlign: "justify" }}><strong>更新於2022年5月13日</strong></p>
                <p style={{ textAlign: "justify" }}><strong>概述</strong></p>
                <p style={{ textAlign: "justify" }}>本Lightshare網站（下稱&ldquo;<strong>網站</strong>&rdquo;）由註冊辦公地址位於香港北角渣華道191號嘉華中心19樓的香港品質保證局（下稱&ldquo;<strong>HKQAA</strong>&rdquo;或 &ldquo;<strong>我們</strong>&rdquo;）經營。</p>            
                <p style={{ textAlign: "justify" }}>HKQAA向用戶提供本網站所有資料、工具及服務，惟您須接受本網站所載的所有條款、條件、政策及通知。</p>
                <p style={{ textAlign: "justify" }}>通過訪問我們的網站，您參與了我們的服務（下稱&ldquo;<strong>服務</strong>&rdquo;），並同意接受以下條款和條件（下稱&ldquo;<strong>條款和條件</strong>&rdquo;）的約束，包括本條款和條件中提到的和/或在<a href="/terms-and-condition" target="_blank" rel="noopener noreferrer">條款和條件</a>中提供的附加條款、條件和政策。這些條款和條件適用於本網站的所有用戶，包括但不限於瀏覽器用戶、供應商、客戶、商家和/或內容提供者。</p>
                <p style={{ textAlign: "justify" }}>在訪問或使用我們的網站之前，請您仔細閱讀這些條款和條件。訪問或使用本網站的任何部分，即表示您同意接受這些條款和條件的約束。</p>
                <p style={{ textAlign: "justify" }}>如果您不同意這些條款和條件，請停止繼續購買服務或訪問我們的網站。</p>
                <p style={{ textAlign: "justify" }}><strong>一般條款</strong></p>
                <p style={{ textAlign: "justify" }}>我們保留隨時以任何理由拒絕任何人使用我們網站或服務的權利。您理解，您提供的任何信息可能在未加密的情況下傳輸，並涉及(a)通過各種網絡傳輸; 以及(b)為符合和適應連接網絡或設備的技術要求而進行的更改。如果HKQAA 在網站接受信用卡付款，您的信用卡信息在網絡傳輸過程中將會被加密。您的個人信息和您提供的任何個人資料的處理受我們隱私政策的約束，詳見<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隱私政策</a>。</p>
                <p style={{ textAlign: "justify" }}>本協定中使用的標題僅為方便而設，不會限制或以其他方式影響本條款和條件。</p>
                <p style={{ textAlign: "justify" }}><strong>服務變更</strong></p>
                <p style={{ textAlign: "justify" }}>我們保留隨時修改或中斷服務（或其任何部分或內容）而不需通知用戶的權利。</p>
                <p style={{ textAlign: "justify" }}>對於服務的任何修改、價格變動、暫停或中斷，我們不對您或任何第三方承擔責任。</p>
                <p style={{ textAlign: "justify" }}><strong>註冊</strong></p>
                <p style={{ textAlign: "justify" }}>如欲使用本網站的某些綜合服務，您必須在本網站向HKQAA註冊帳戶。您負有完全責任保護您的賬戶安全。您還應對使用您的賬戶或與您的賬戶有關的所有活動負完全責任，並同意在發生任何未經授權的訪問或使用您的賬戶時立即通知我們。您承認並同意，我們不對任何與未經授權訪問或使用您賬戶相關的損害、損失、成本和開支或責任負責。</p>
                <p style={{ textAlign: "justify" }}>您同意按照我們在註冊時的提示，提供有關您或您所代表的實體的真實、準確、最新和完整的信息。在我們網站上創建帳戶時，您不得冒充他人或以其他方式提供虛假信息。如果您代表一家公司、組織或法律實體，或以其他方式代表其他人（如您的員工）進行註冊，則您聲明並保證您有權使該等實體或個人受到本條款和條件的約束。如果我們有理由相信您在註冊時向我們提供的信息不真實、不准確、非最新或不完整，我們有權暫停您使用本網站。我們可能會要求您提供如商業登記文件等證明文件，您須按指示提供該等文件。您理解，如果您未能按要求提供此類文件，您將無法在本網站註冊帳戶。</p>
                <p style={{ textAlign: "justify" }}><strong>我們的服務</strong></p>
                <p style={{ textAlign: "justify" }}>本網站是一個為用戶提供在線課程託管和教育資料流通的綜合服務平台，HKQAA亦會不時提供額外服務，包括但不限於求職配對服務。</p>
                <p style={{ textAlign: "justify" }}>用戶在本網站註冊賬戶並在遵守本條款和條件以及適用的附加條款的情況下，可訪問本網站各課程內容提供者（下稱&ldquo;<strong>課程內容提供者</strong>&rdquo;）提供的各種課程內容（需付費），並有權享受本網站提供的所有其他服務，如求職配對服務。對於網站上的課程出勤率，在成功完成某些課程後，將向學員頒發數字證書。對於某些課程，可要求提供實物證書，但需額外收費。 </p>
                <p style={{ textAlign: "justify" }}>我們保留權利但沒有義務將我們的服務全部或部分限定於任何個人、地理區域或司法管轄區域。我們可以根據具體情況行使這項權利。雖然本網站可在全球範圍內訪問，但本網站上提供的功能、服務並非都可向所有人或所有地理位置提供。您進一步承認並同意，如發生任何意外或非計劃停機，或因任何理由，包括但不限於系統故障和技術干擾，導致部分或全部服務不可使用，在此期間，您訪問和使用本網站以及我們的服務可能會被暫停。 HKQAA有權因定期維護或其他原因而在所安排的停止服務時間暫停使用本網站或提供服務，而毋須向您承擔任何責任。</p>
                <p style={{ textAlign: "justify" }}>我們有權在不另行通知您的情況下，隨時對有關我們的服務和收費的所有說明進行更改。我們保留在不另行通知您的情況下隨時終止任何服務的權利。</p>
                <p style={{ textAlign: "justify" }}><strong>資訊準確、完整和及時</strong></p>
                <p style={{ textAlign: "justify" }}>如果本網站提供的信息不准確、不完整或不及時，我們概不負責。本網站所提供的資料僅供一般參考之用，在未諮詢主要的、更準確、更完整或更及時的信息來源前，不應被用作作出決定的唯一依據。任何依賴本網站資料的風險由您自行承擔。您從本網站獲得的任何信息不應構成本條款和條件中未明確載明的任何保證。</p>
                <p style={{ textAlign: "justify" }}>本網站可能包含某些歷史信息。歷史信息必然不是最新的信息，僅供您參考。我們保留隨時修改本網站內容的權利，但我們沒有義務更新網站上的任何信息。您同意您有責任檢查我們網站的變更。</p>
                <p style={{ textAlign: "justify" }}><strong>課程內容許可</strong></p>
                <p style={{ textAlign: "justify" }}>本網站允許用戶上傳、提交、存儲、發送、傳輸內容和數據，包括但不限於您的在線課程視頻、課程資料、宣傳資料（合稱&ldquo;<strong>課程內容</strong>&rdquo;）。我們也鼓勵用戶在您感興趣的領域與我們網上社區進行互動和交流。</p>
                <p style={{ textAlign: "justify" }}>在您註冊課程內容並全額支付相關費用後，課程內容提供者將授予您非排他的、免版稅的、全球的、可撤銷許可的有限權利，但僅供您個人基於非商業用途使用課程內容。如果將課程內容或我們的服務用於本條款和條件未列明的任何用途，則屬於侵犯HKQAA及/或課程內容提供者的專有權利。</p>
                <p style={{ textAlign: "justify" }}>在使用任何課程內容時，您不得:</p>
                <ol type="i" style={{ textAlign: "justify" }}>
                    <li>以任何形式轉載任何課程內容或材料；</li>
                    <li>未經HKQAA授權，將任何課程內容或材料出售、出租或轉授給第三方；</li>
                    <li>為商業目的複製或以其他方式利用任何課程內容或材料；或</li>
                    <li>未經HKQAA授權擅自再分發任何課程內容或資料。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>對於培訓組織和協會或其他商業實體，如果任何課程內容是專門用於再分發的，則必須僅在您的組織內再分發。</p>
                <p style={{ textAlign: "justify" }}>您特此確認並同意，HKQAA僅作為課程內容提供者的服務商和平台提供者，向您提供課程內容。任何課程內容所表達的觀點及意見並不必然反映HKQAA的意見。特別需要說明的是，本網站的任何內容均不應被解釋為法律、稅務、投資、財務或其他專業建議。 HKQAA在本網站上載課程內容並不意味著HKQAA已獲任何第三者認可、贊助或推薦，本網站的任何資料亦不構成對所討論事項或有關法律的全面、完整或準確的陳述。以商品名稱、商標、製造商或其他方式提及任何產品、服務或流程，並不構成或暗示HKQAA的認可、推薦或偏愛。您須自行評估使用HKQAA或課程內容所提供的任何資料的優點及風險。</p>
                <p style={{ textAlign: "justify" }}>HKQAA如認為任何本網站的用戶生成內容違反以下“可接受使用政策”，則有權刪除該用戶生成內容。</p>
                <p style={{ textAlign: "justify" }}><strong>可接受使用政策</strong></p>
                <p style={{ textAlign: "justify" }}>您在本網站的行為應符合我們以下“可接受使用政策”。 HKQAA 有權（但無義務）監督及審查您使用本網站時的帳戶、內容及行為。 HKQAA如發現您在本網站上的任何內容或行為違反本條款和條件，則有權暫停您訪問本網站。</p>
                <p style={{ textAlign: "justify" }}>您在此同意不得:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>複製、修改、改編、翻譯、反向工程、反彙編、反編譯或從我們的服務中創建衍生作品或分發任何內容；</li>
                    <li>為本用戶協議未明確授權的任何目的更改、刪除、篡改、複製、展示或使用我們的商標及 HKQAA擁有的其他知識產權；</li>
                    <li>使用我們的服務進行任何未經 HKQAA授權的創收活動，或其他超出HKQAA與您簽訂協議範圍的活動；</li>
                    <li>提交、分享、張貼、交流或以其他方式採取任何煽動或鼓勵明顯或隱含非法、辱罵、騷擾、威脅、仇恨、種族主義、貶損、損害任何名譽、色情、不雅、褻瀆、淫穢或其他令人反感的行為（包括裸體）的行動；</li>
                    <li>在軟件使用過程中，從事搜索、收集或參與使用旨在從我們的網站（包括從我們的網站的任何用戶）收集數據的軟件（包括間諜軟件）；</li>
                    <li>傳播任何病毒、其他計算機指令或有意或可能中斷、損壞或乾擾計算機或相關係統使用的技術手段；</li>
                    <li>跟踪、騷擾、威脅或傷害任何第三方；</li>
                    <li>冒充任何第三方；</li>
                    <li>參與任何欺詐或非法活動，包括但不限於網絡釣魚、洗錢或欺詐；</li>
                    <li>通過訪問我們的網站獲取信息，以建立類似或競爭性的網站、應用程序或其他內容;</li>
                    <li>將本網站用於任何非法或未經授權，或違反任何所適用的法律（包括但不限於版權和隱私法）或侵犯其他第三方權利的目的；</li>
                    <li>使用任何HTML技術，通過框架、視窗、彈出窗口、彈出式廣告或任何其他非標準鏈接方式顯示本網站，建立鏈接至本網站或轉載本網站的任何內容，除非HKQAA明確授權；或</li>
                    <li>提倡、鼓勵或協助任何第三方從事上述任何行為。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>此外，您在任何時候都不得上傳以下內容:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>侵犯他人或實體的任何專利、商標、商業秘密、版權或其他知識產權；</li>
                    <li>違反與第三方的合同或信託協議；</li>
                    <li>冒充任何人或實體，或虛假陳述或以其他方式歪曲您與某個人或實體的關係；</li>
                    <li>含有性暴露內容或推廣性愛服務；</li>
                    <li>含有誹謗言論； </li>
                    <li>含有仇恨或歧視性言論； </li>
                    <li>促進或支持任何恐怖或仇恨團體；</li>
                    <li>含有關於如何裝配爆炸裝置或簡易火器的說明； </li>
                    <li>剝削或者危害未成年人； </li>
                    <li>描述或鼓勵自殘或自殺；</li>
                    <li>描述任何暴力行為，包括但不限於虐待動物和酷刑；</li>
                    <li>推行欺詐性或可疑的賺錢計劃，提出任何非法交易或使用欺騙性營銷手段；</li>
                    <li>包含任何虛假或誤導性信息；或</li>
                    <li>違反任何所適用的法律。</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>支付條款</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>課程費用</span>.</p>
                <p style={{ textAlign: "justify" }}>您承認並同意，HKQAA 或課程內容提供者（如適用）須就任何課程內容的報名收取費用（除非該課程明確註明為免費課程）。課程內容的費用應在每個課程內容的顯示頁面上明示。 HKQAA 有權隨時更改有關費用及付款方法，但須事先通知您。所有應付予HKQAA的費用，均須按HKQAA與您所議定的付款條款即時繳付。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>直接付費給課程內容提供者</span></p>
                <p style={{ textAlign: "justify" }}>如果我們明確表示，課程內容的款項應直接支付給提供課程內容提供者，課程內容提供者將通過電子郵件直接與您聯繫，確認您的課程報名並提供付款指示。在此情況下，HKQAA 僅作為您與課程內容提供者交易中的促進者。您應自行負責與課程內容提供者之間的交易。 HKQAA不會參與您與課程內容提供者之間的協議或交易。</p>
                <p style={{ textAlign: "justify" }}>如您與課程內容提供者結清款項，課程內容提供者將給予您參加課程的代幣。您負有完全責任確保該代幣的安全和保密。如果您丟失了這些代幣，我們和課程內容提供者將不會給您補發。此類代幣不可退款或兌換現金或其他折扣，只能用於參加課程。您不得向第三方出售、轉讓或分配此類代幣。</p>
                <p style={{ textAlign: "justify" }}>所有支付給HKQAA或課程內容提供者（如適用）的款項，應以HKQAA指定的貨幣支付，且概不退還。 HKQAA概不負責任何捐贈、付款、稅款或向任何第三方付款，所有此類費用應由您自行承擔。</p>
                <p style={{ textAlign: "justify" }}><strong>第三方內容和交流</strong></p>
                <p style={{ textAlign: "justify" }}>本網站可能包含某些特性和功能鏈接到第三方內容或為您提供第三方內容的訪問，這些第三方的內容與我們完全無關。我們不負責審查或評估該等內容或其準確性，我們不對任何第三方材料或網站，或第三方任何其他材料、產品或服務作出保證，亦不承擔任何責任。在與本網站或其他網站進行任何联系或互動前，請您自行獨立判斷。</p>
                <p style={{ textAlign: "justify" }}>對於您購買或使用與任何第三方網站相關的商品、服務、資源、內容或任何其他交易，我們不承擔任何責任。在您進行任何交易前，請仔細審查第三方的政策和慣例，並確保您了解這些政策和慣例。對於第三方產品的投訴、索賠、關注或問題應直接向第三方提出。</p>
                <p style={{ textAlign: "justify" }}><strong>用戶評論、反饋和其他建議</strong></p>
                <p style={{ textAlign: "justify" }}>如果經我們請求，您發送某些具體的建議（例如參賽作品），或您未經我們請求而發送創意、建議、計劃或其他材料（合稱&ldquo;<strong>評論</strong>&rdquo;），無論通過電子郵件、郵政郵件或者其他形式，您同意，我們可以在任何時候編輯、複製、發布、分發、翻譯或以其他方式在任何媒體上使用您轉發給我們的任何評論而不受到任何限制。我們沒有義務：（1）對任何評論進行保密；（2）因任何評論而承擔賠償責任；或（3）回應任何評論。對於我們認為帶有非法、攻擊、威脅、誹謗、污衊、色情、淫穢性質或其他令人反感或侵犯任何一方知識產權或本條款和條件的評論，我們有權利但沒有義務監控、編輯或刪除全部或部分評論。</p>
                <p style={{ textAlign: "justify" }}>您同意您的評論不會侵犯任何第三方的權利，包括著作權、商標、隱私、人格或其他個人或專有權利。您進一步同意，您的評論不包含誹謗性的或非法的、辱罵性的或淫穢的內容，也不包含可能以任何方式影響我們服務運行或任何相關網站運行的計算機病毒或其他惡意軟件。您不可以使用虛假的電子郵件地址，假裝成別人而不是你自己，或以其他方式就評論的來源誤導我們或第三方。您對您所發表的任何評論及其準確性負全部責任。我們對您或任何第三方發布的任何評論不負任何責任。</p>
                <p style={{ textAlign: "justify" }}><strong>個人數據保護</strong></p>
                <p style={{ textAlign: "justify" }}>您通過本網站提交的個人數據受我們隱私政策的約束，該政策可通過<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隱私政策</a>訪問。</p>
                <p style={{ textAlign: "justify" }}><strong>版權和知識產權政策</strong></p>
                <p style={{ textAlign: "justify" }}>我們致力於保護合法所有者的知識產權。如果您認為您的材料被侵權複製，請通過<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>發送以下信息:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>您的姓名、地址、電話號碼和電子郵件地址；</li>
                    <li>涉嫌受到侵權的作品的描述；</li>
                    <li>您聲稱侵權並要求刪除的材料的描述以及該等材料的鏈接； </li>
                    <li>您善意地相信該材料的使用未經版權所有人或其代理人授權或符合法律規定的相關聲明；</li>
                    <li>版權所有人（或其授權人）的電子簽名或手寫簽名；以及</li>
                    <li>有關您所提供的信息是準確的以及您是版權所有人或其授權人的聲明。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>如果您不遵守上述要求，HKQAA有權不處理您的申請。</p>
                <p style={{ textAlign: "justify" }}><strong>投訴和糾紛處理</strong></p>
                <p style={{ textAlign: "justify" }}>如果您認為任何課程內容或網站上發布的任何其他內容侵犯了您的專有權利或違反了可接受使用政策，請通過<a href="/contact-us" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>[聯繫我們]</span></a>向我們報告。 HKQAA應在確認收到您的投訴後[3]個工作日內，對投訴進行相應調查。在確認收到您對任何賬戶或課程內容的投訴後，HKQAA可刪除或暫停訪問特定課程內容，或相關課程賬戶，以待HKQAA作出決定。 HKQAA對所有投訴有最終決定權。</p>
                <p style={{ textAlign: "justify" }}><strong>免責條款; 責任限制</strong></p>
                <p style={{ textAlign: "justify" }}>我們不保證您對我們網站的使用將是不間斷、及時、安全或無錯誤。我們不保證本網站提供的信息和內容是準確或可靠的。您同意，我們有權隨時無限期地刪除本網站或終止提供任何服務，無需另行通知您。</p>
                <p style={{ textAlign: "justify" }}>您明確同意，您使用或無法使用本網站的風險由您自行承擔。本網站或通過本網站提供給您的服務（除非我們明確表示）如提供的是“和”和“可用”，即表明在法律允許的範圍內，不包含任何明示或默示的任何類型的聲明、保證或條件，包括所有默示的適銷性、適銷性質量、特定用途的適用性、持久性、所有權和不侵權的保證或條件。</p>
                <p style={{ textAlign: "justify" }}>您承認並同意，在法律許可的範圍內，HKQAA 將不會就下列情況承擔責任：(i)內容錯誤或不准確；(ii)由於您訪問或使用我們的網站而造成的人身傷害、財產損害或其他損害；(iii)未經授權訪問或使用我們的服務器、任何個人信息或用戶數據：(iv)向本網站傳播或從本網站傳播的中斷；(v)任何可能在本網站或通過本網站傳播的漏洞、病毒、特洛伊木馬或類似病毒; 或(vi)因本網站任何內容或使用本網站發布或共享的任何內容而產生的任何類型的損害、損失、成本、開支或責任。</p>
                <p style={{ textAlign: "justify" }}>在適用法律允許的最大範圍內，您承認並同意，在任何情況下，HKQAA不會對您或任何第三方因本條款而引致或與本條款有關的任何直接的、特殊的、偶然性的、懲罰性的或間接性損失（包括利潤損失、收入損失或數據損失）或獲得替代性產品的成本承擔賠償責任，無論該等賠償責任如何引致，無論該等賠償責任是否基於合約、保證、侵權（包括疏忽）、嚴格責任或其他原因而引致，以及無論HKQAA是否已被告知該等損害賠償的可能性。</p>
                <p style={{ textAlign: "justify" }}><strong>損害賠償</strong></p>
                <p style={{ textAlign: "justify" }}>您同意賠償和使HKQAA及其母公司、附屬公司、聯屬公司、合夥人、高級職員、董事、代理人、承辦商、授權人、服務供應商、分包商、供應商、實習生及僱員免於遭受任何因您違反本條款和條件或其引用包含的文件，或您違反任何法律或侵犯第三方權利而由任何第三方提出的索賠或要求（包括律師費）之損害。</p>
                <p style={{ textAlign: "justify" }}><strong>獨立性</strong></p>
                <p style={{ textAlign: "justify" }}>如果本條款和條件的任何條款被認定為非法、無效或不可執行，則該等條款仍應在適用法律允許的最大範圍內可執行，且不可執行的部分應被視為與本條款和條件分離，該認定不應影響任何其他剩餘條款的有效性和可執行性。</p>
                <p style={{ textAlign: "justify" }}><strong>終止</strong></p>
                <p style={{ textAlign: "justify" }}>雙方在本條款和條件終止日前產生的義務和責任在本條款和條件終止後仍然有效。</p>
                <p style={{ textAlign: "justify" }}>本條款和條件在您或我們終止之前有效。您可隨時通過通知我們您不再希望使用我們的服務或停止使用我們的網站而終止本條款和條件。</p>
                <p style={{ textAlign: "justify" }}>如果我們認為您未遵守本條款和條件，我們也有權隨時終止本協議而無需另行通知您。您仍將對截至並包括終止日期的所有到期款項承擔責任；我們可能因此拒絕您訪問我們的服務（或其中的任何部分）。</p>
                <p style={{ textAlign: "justify" }}><strong>棄權</strong></p>
                <p style={{ textAlign: "justify" }}>我們未行使或未執行本條款和條件的任何權利或規定，不構成我們對該等權利或規定的棄權。</p>
                <p style={{ textAlign: "justify" }}><strong>完整協定</strong></p>
                <p style={{ textAlign: "justify" }}>本條款和條件以及我們發佈在本網站或與服務有關的任何政策或操作規則構成我們雙方之間的完整協議和理解，取代雙方此前或臨時協議、通信和建議，無論是口頭或書面形式，包括但不限於任何先前版本的條款和條件。</p>
                <p style={{ textAlign: "justify" }}>本條款和條件解釋中的任何歧義不得對起草方造成不利影響。</p>
                <p style={{ textAlign: "justify" }}><strong>法律適用</strong></p>
                <p style={{ textAlign: "justify" }}>本條款和條件及我們為您提供服務的任何單獨協議應受香港特別行政區法律管轄並按香港特別行政區法律解釋。任何因本條款和條件而產生或與本條款和條件有關的爭議或索賠，均受香港特別行政區法院的專屬管轄。</p>
                <p style={{ textAlign: "justify" }}><strong>條款和條件的變更</strong></p>
                <p style={{ textAlign: "justify" }}>您可隨時在此頁面查看條款和條件的最新版本。我們保留自行更新、更改或替換本條款和條件的權利，並會不時更新及/或更改我們的網站。本條款和條件的更改將適用於在本條款和條件規定的生效日期或之後所下訂單或使用的服務。您有責任定期檢查本頁面的變化。在發布任何更改後，您繼續使用我們的服務或訪問本網站，即表示您接受這些更改。</p>
                <p style={{ textAlign: "justify" }}><strong>聯繫方式</strong></p>
                <p style={{ textAlign: "justify" }}>有關本條款和條件的問題，請發送至<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>。</p>
    
    
            </div>
        );
    } else if(props?.lang === "SC") {
        return (
            <div>
                <p style={{ textAlign: "justify" }}><strong>网站条款和条件 (&ldquo;条款&rdquo;) &ndash;一般公众</strong></p>
                <p style={{ textAlign: "justify" }}><strong>更新于2022年5月13日</strong></p>
                <p style={{ textAlign: "justify" }}><strong>概述</strong></p>
                <p style={{ textAlign: "justify" }}>本Lightshare网站（下称&ldquo;<strong>网站</strong>&rdquo;）由注册办公地址位于香港北角渣华道191号嘉华中心19楼的香港品质保证局（下称&ldquo;<strong>HKQAA</strong>&rdquo;或 &ldquo;<strong>我们</strong>&rdquo;）经营。</p>
                <p style={{ textAlign: "justify" }}>HKQAA向用户提供本网站所有资料、工具及服务，惟您须接受本网站所载的所有条款、条件、政策及通知。 </p>
                <p style={{ textAlign: "justify" }}>通过访问我们的网站，您参与了我们的服务（下称&ldquo;<strong>服务</strong>&rdquo;），并同意接受以下条款和条件（下称&ldquo;<strong>条款和条件</strong>&rdquo;）的约束，包括本条款和条件中提到的和/或在 <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer">条款和条件</a>中提供的附加条款、条件和政策。这些条款和条件适用于本网站的所有用户，包括但不限于浏览器用户、供应商、客户、商家和/或内容提供者。</p>
                <p style={{ textAlign: "justify" }}>在访问或使用我们的网站之前，请您仔细阅读这些条款和条件。访问或使用本网站的任何部分，即表示您同意接受这些条款和条件的约束。</p>
                <p style={{ textAlign: "justify" }}>如果您不同意这些条款和条件，请停止继续购买服务或访问我们的网站。</p>
                <p style={{ textAlign: "justify" }}><strong>一般条款</strong></p>
                <p style={{ textAlign: "justify" }}>我们保留随时以任何理由拒绝任何人使用我们网站或服务的权利。您理解，您提供的任何信息可能在未加密的情况下传输，并涉及(a)通过各种网络传输; 以及(b)为符合和适应连接网络或设备的技术要求而进行的更改。如果HKQAA 在网站接受信用卡付款，您的信用卡信息在网络传输过程中将会被加密。您的个人信息和您提供的任何个人资料的处理受我们隐私政策的约束，详见<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隐私政策</a>。</p>
                <p style={{ textAlign: "justify" }}>本协议中使用的标题仅为方便而设，不会限制或以其他方式影响本条款和条件。</p>
                <p style={{ textAlign: "justify" }}><strong>服务变更</strong></p>
                <p style={{ textAlign: "justify" }}>我们保留随时修改或中断服务（或其任何部分或内容）而不需通知用户的权利。</p>
                <p style={{ textAlign: "justify" }}>对于服务的任何修改、价格变动、暂停或中断，我们不对您或任何第三方承担责任。</p>
                <p style={{ textAlign: "justify" }}><strong>注册</strong></p>
                <p style={{ textAlign: "justify" }}>如欲使用本网站的某些综合服务，您必须在本网站向HKQAA注册帐户。您负有完全责任保护您的账户安全。您还应对使用您的账户或与您的账户有关的所有活动负完全责任，并同意在发生任何未经授权的访问或使用您的账户时立即通知我们。您承认并同意，我们不对任何与未经授权访问或使用您账户相关的损害、损失、成本和开支或责任负责。</p>
                <p style={{ textAlign: "justify" }}>您同意按照我们在注册时的提示，提供有关您或您所代表的实体的真实、准确、最新和完整的信息。在我们网站上创建帐户时，您不得冒充他人或以其他方式提供虚假信息。如果您代表一家公司、组织或法律实体，或以其他方式代表其他人（如您的员工）进行注册，则您声明并保证您有权使该等实体或个人受到本条款和条件的约束。如果我们有理由相信您在注册时向我们提供的信息不真实、不准确、非最新或不完整，我们有权暂停您使用本网站。我们可能会要求您提供如商业登记文件等证明文件，您须按指示提供该等文件。您理解，如果您未能按要求提供此类文件，您将无法在本网站注册帐户。</p>
                <p style={{ textAlign: "justify" }}><strong>我们的服务</strong></p>
                <p style={{ textAlign: "justify" }}>本网站是一个为用户提供在线课程托管和教育资料流通的综合服务平台，HKQAA亦会不时提供额外服务，包括但不限于求职配对服务。</p>
                <p style={{ textAlign: "justify" }}>用户在本网站注册账户并在遵守本条款和条件以及适用的附加条款的情况下，可访问本网站各课程内容提供者（下称&ldquo;<strong>课程内容提供者</strong>&rdquo;）提供的各种课程内容（需付费），并有权享受本网站提供的所有其他服务，如求职配对服务。对于网站上的课程出勤率，在成功完成某些课程后，将向学员颁发数字证书。对于某些课程，可要求提供实物证书，但需额外收费。</p>
                <p style={{ textAlign: "justify" }}>我们保留权利但没有义务将我们的服务全部或部分限定于任何个人、地理区域或司法管辖区域。我们可以根据具体情况行使这项权利。虽然本网站可在全球范围内访问，但本网站上提供的功能、服务并非都可向所有人或所有地理位置提供。您进一步承认并同意，如发生任何意外或非计划停机，或因任何理由，包括但不限于系统故障和技术干扰，导致部分或全部服务不可使用，在此期间，您访问和使用本网站以及我们的服务可能会被暂停。HKQAA有权因定期维护或其他原因而在所安排的停止服务时间暂停使用本网站或提供服务，而毋须向您承担任何责任。</p>
                <p style={{ textAlign: "justify" }}>我们有权在不另行通知您的情况下，随时对有关我们的服务和收费的所有说明进行更改。我们保留在不另行通知您的情况下随时终止任何服务的权利。</p>
                <p style={{ textAlign: "justify" }}><strong>信息准确、完整和及时</strong></p>
                <p style={{ textAlign: "justify" }}>如果本网站提供的信息不准确、不完整或不及时，我们概不负责。本网站所提供的资料仅供一般参考之用，在未咨询主要的、更准确、更完整或更及时的信息来源前，不应被用作作出决定的唯一依据。任何依赖本网站资料的风险由您自行承担。您从本网站获得的任何信息不应构成本条款和条件中未明确载明的任何保证。</p>
                <p style={{ textAlign: "justify" }}>本网站可能包含某些历史信息。历史信息必然不是最新的信息，仅供您参考。我们保留随时修改本网站内容的权利，但我们没有义务更新网站上的任何信息。您同意您有责任检查我们网站的变更。</p>
                <p style={{ textAlign: "justify" }}><strong>课程内容许可</strong></p>
                <p style={{ textAlign: "justify" }}>本网站允许用户上传、提交、存储、发送、传输内容和数据，包括但不限于您的在线课程视频、课程资料、宣传资料（合称&ldquo;<strong>课程内容</strong>&rdquo;）。我们也鼓励用户在您感兴趣的领域与我们网上社区进行互动和交流。</p>
                <p style={{ textAlign: "justify" }}>在您注册课程内容并全额支付相关费用后，课程内容提供者将授予您非排他的、免版税的、全球的、可撤销许可的有限权利，但仅供您个人基于非商业用途使用课程内容。如果将课程内容或我们的服务用于本条款和条件未列明的任何用途，则属于侵犯HKQAA及/或课程内容提供者的专有权利。</p>
                <p style={{ textAlign: "justify" }}>在使用任何课程内容时，您不得:</p>
                <ol type="i" style={{ textAlign: "justify" }}>
                    <li>以任何形式转载任何课程内容或材料；</li>
                    <li>未经HKQAA授权，将任何课程内容或材料出售、出租或转授给第三方；</li>
                    <li>为商业目的复制或以其他方式利用任何课程内容或材料；或</li>
                    <li>未经HKQAA授权擅自再分发任何课程内容或资料。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>对于培训组织和协会或其他商业实体，如果任何课程内容是专门用于再分发的，则必须仅在您的组织内再分发。</p>
                <p style={{ textAlign: "justify" }}>您特此确认并同意，HKQAA仅作为课程内容提供者的服务商和平台提供者，向您提供课程内容。任何课程内容所表达的观点及意见并不必然反映HKQAA的意见。特别需要说明的是，本网站的任何内容均不应被解释为法律、税务、投资、财务或其他专业建议。HKQAA在本网站上载课程内容并不意味着HKQAA已获任何第三者认可、赞助或推荐，本网站的任何资料亦不构成对所讨论事项或有关法律的全面、完整或准确的陈述。以商品名称、商标、制造商或其他方式提及任何产品、服务或流程，并不构成或暗示HKQAA的认可、推荐或偏爱。您须自行评估使用HKQAA或课程内容所提供的任何资料的优点及风险。</p>
                <p style={{ textAlign: "justify" }}>HKQAA如认为任何本网站的用户生成内容违反以下“可接受使用政策”，则有权删除该用户生成内容。</p>
                <p style={{ textAlign: "justify" }}><strong>可接受使用政策</strong></p>
                <p style={{ textAlign: "justify" }}>您在本网站的行为应符合我们以下“可接受使用政策”。HKQAA 有权（但无义务）监督及审查您使用本网站时的帐户、内容及行为。HKQAA如发现您在本网站上的任何内容或行为违反本条款和条件，则有权暂停您访问本网站。</p>
                <p style={{ textAlign: "justify" }}>您在此同意不得:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>复制、修改、改编、翻译、反向工程、反汇编、反编译或从我们的服务中创建派生作品或分发任何内容；</li>
                    <li>为本用户协议未明确授权的任何目的更改、删除、篡改、复制、展示或使用我们的商标及 HKQAA拥有的其他知识产权；</li>
                    <li>使用我们的服务进行任何未经 HKQAA授权的创收活动，或其他超出 HKQAA与您签订协议范围的活动；</li>
                    <li>提交、分享、张贴、交流或以其他方式采取任何煽动或鼓励明显或隐含非法、辱骂、骚扰、威胁、仇恨、种族主义、贬损、损害任何名誉、色情、不雅、亵渎、淫秽或其他令人反感的行为（包括裸体）的行动；</li>
                    <li>在软件使用过程中，从事搜索、收集或参与使用旨在从我们的网站（包括从我们的网站的任何用户）收集数据的软件（包括间谍软件）；</li>
                    <li>传播任何病毒、其他计算机指令或有意或可能中断、损坏或干扰计算机或相关系统使用的技术手段；</li>
                    <li>跟踪、骚扰、威胁或伤害任何第三方；</li>
                    <li>冒充任何第三方；</li>
                    <li>参与任何欺诈或非法活动，包括但不限于网络钓鱼、洗钱或欺诈；</li>
                    <li>通过访问我们的网站获取信息，以建立类似或竞争性的网站、应用程序或其他内容; </li>
                    <li>将本网站用于任何非法或未经授权，或违反任何所适用的法律（包括但不限于版权和隐私法）或侵犯其他第三方权利的目的；</li>
                    <li>使用任何HTML技术，通过框架、视窗、弹出窗口、弹出式广告或任何其他非标准链接方式显示本网站，建立链接至本网站或转载本网站的任何内容，除非HKQAA明确授权；或 </li>
                    <li>提倡、鼓励或协助任何第三方从事上述任何行为。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>此外，您在任何时候都不得上传以下内容:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>侵犯他人或实体的任何专利、商标、商业秘密、版权或其他知识产权；</li>
                    <li>违反与第三方的合同或信托协议；</li>
                    <li>冒充任何人或实体，或虚假陈述或以其他方式歪曲您与某个人或实体的关系；</li>
                    <li>含有性暴露内容或推广性爱服务；</li>
                    <li>含有诽谤言论；</li>
                    <li>含有仇恨或歧视性言论；</li>
                    <li>促进或支持任何恐怖或仇恨团体；</li>
                    <li>含有关于如何装配爆炸装置或简易火器的说明；</li>
                    <li>剥削或者危害未成年人；</li>
                    <li>描述或鼓励自残或自杀；</li>
                    <li>描述任何暴力行为，包括但不限于虐待动物和酷刑</li>
                    <li>推行欺诈性或可疑的赚钱计划，提出任何非法交易或使用欺骗性营销手段；</li>
                    <li>包含任何虚假或误导性信息；或</li>
                    <li>违反任何所适用的法律。</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>支付条款</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>课程费用</span>.</p>
                <p style={{ textAlign: "justify" }}>您承认并同意，HKQAA 或课程内容提供者（如适用）须就任何课程内容的报名收取费用（除非该课程明确注明为免费课程）。课程内容的费用应在每个课程内容的显示页面上明示。HKQAA 有权随时更改有关费用及付款方法，但须事先通知您。所有应付予HKQAA的费用，均须按HKQAA与您所议定的付款条款即时缴付。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>直接付费给课程内容提供者</span></p>
                <p style={{ textAlign: "justify" }}>如果我们明确表示，课程内容的款项应直接支付给提供课程内容提供者，课程内容提供者将通过电子邮件直接与您联系，确认您的课程报名并提供付款指示。在此情况下，HKQAA 仅作为您与课程内容提供者交易中的促进者。 您应自行负责与课程内容提供者之间的交易。HKQAA不会参与您与课程内容提供者之间的协议或交易。</p>
                <p style={{ textAlign: "justify" }}>如您与课程内容提供者结清款项，课程内容提供者将给予您参加课程的代币。您负有完全责任确保该代币的安全和保密。如果您丢失了这些代币，我们和课程内容提供者将不会给您补发。此类代币不可退款或兑换现金或其他折扣，只能用于参加课程。 您不得向第三方出售、转让或分配此类代币。</p>
                <p style={{ textAlign: "justify" }}>所有支付给HKQAA或课程内容提供者（如适用）的款项，应以HKQAA指定的货币支付，且概不退还。HKQAA概不负责任何捐赠、付款、税款或向任何第三方付款，所有此类费用应由您自行承担。</p>
                <p style={{ textAlign: "justify" }}><strong>第三方内容和交流</strong></p>
                <p style={{ textAlign: "justify" }}>本网站可能包含某些特性和功能链接到第三方内容或为您提供第三方内容的访问，这些第三方的内容与我们完全无关。我们不负责审查或评估该等内容或其准确性，我们不对任何第三方材料或网站，或第三方任何其他材料、产品或服务作出保证，亦不承担任何责任。在与本网站或其他网站进行任何联系或互动前，请您自行独立判断。</p>
                <p style={{ textAlign: "justify" }}>对于您购买或使用与任何第三方网站相关的商品、服务、资源、内容或任何其他交易，我们不承担任何责任。在您进行任何交易前，请仔细审查第三方的政策和惯例，并确保您了解这些政策和惯例。对于第三方产品的投诉、索赔、关注或问题应直接向第三方提出。</p>
                <p style={{ textAlign: "justify" }}><strong>用户评论、反馈和其他建议</strong></p>
                <p style={{ textAlign: "justify" }}>如果经我们请求，您发送某些具体的建议（例如参赛作品），或您未经我们请求而发送创意、建议、计划或其他材料（合称&ldquo;<strong>评论</strong>&rdquo;），无论通过电子邮件、邮政邮件或者其他形式，您同意，我们可以在任何时候编辑、复制、发布、分发、翻译或以其他方式在任何媒体上使用您转发给我们的任何评论而不受到任何限制。我们没有义务：（1）对任何评论进行保密；（2）因任何评论而承担赔偿责任；或（3）回应任何评论。对于我们认为带有非法、攻击、威胁、诽谤、污蔑、色情、淫秽性质或其他令人反感或侵犯任何一方知识产权或本条款和条件的评论，我们有权利但没有义务监控、编辑或删除全部或部分评论。</p>
                <p style={{ textAlign: "justify" }}>您同意您的评论不会侵犯任何第三方的权利，包括著作权、商标、隐私、人格或其他个人或专有权利。您进一步同意，您的评论不包含诽谤性的或非法的、辱骂性的或淫秽的内容，也不包含可能以任何方式影响我们服务运行或任何相关网站运行的计算机病毒或其他恶意软件。您不可以使用虚假的电子邮件地址，假装成别人而不是你自己，或以其他方式就评论的来源误导我们或第三方。您对您所发表的任何评论及其准确性负全部责任。我们对您或任何第三方发布的任何评论不负任何责任。</p>
                <p style={{ textAlign: "justify" }}><strong>个人数据保护</strong></p>
                <p style={{ textAlign: "justify" }}>您通过本网站提交的个人数据受我们隐私政策的约束，该政策可通过<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隐私和政策</a>访问。</p>
                <p style={{ textAlign: "justify" }}><strong>版权和知识产权政策</strong></p>
                <p style={{ textAlign: "justify" }}>我们致力于保护合法所有者的知识产权。如果您认为您的材料被侵权复制，请通过<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>发送以下信息:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>您的姓名、地址、电话号码和电子邮件地址；</li>
                    <li>涉嫌受到侵权的作品的描述；</li>
                    <li>您声称侵权并要求删除的材料的描述以及该等材料的链接； </li>
                    <li>您善意地相信该材料的使用未经版权所有人或其代理人授权或符合法律规定的相关声明；</li>
                    <li>版权所有人（或其授权人）的电子签名或手写签名；以及</li>
                    <li>有关您所提供的信息是准确的以及您是版权所有人或其授权人的声明。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>如果您不遵守上述要求，HKQAA有权不处理您的申请。</p>
                <p style={{ textAlign: "justify" }}><strong>投诉和纠纷处理</strong></p>
                <p style={{ textAlign: "justify" }}>如果您认为任何课程内容或网站上发布的任何其他内容侵犯了您的专有权利或违反了可接受使用政策，请通过<a href="/contact-us" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>[联系我们]</span></a>向我们报告。HKQAA应在确认收到您的投诉后[3]个工作日内，对投诉进行相应调查。在确认收到您对任何账户或课程内容的投诉后，HKQAA可删除或暂停访问特定课程内容，或相关课程账户，以待HKQAA作出决定。HKQAA对所有投诉有最终决定权。</p>
                <p style={{ textAlign: "justify" }}><strong>免责条款；责任限制</strong></p>
                <p style={{ textAlign: "justify" }}>我们不保证您对我们网站的使用将是不间断、及时、安全或无错误。我们不保证本网站提供的信息和内容是准确或可靠的。您同意，我们有权随时无限期地删除本网站或终止提供任何服务，无需另行通知您。</p>
                <p style={{ textAlign: "justify" }}>您明确同意，您使用或无法使用本网站的风险由您自行承担。本网站或通过本网站提供给您的服务（除非我们明确表示）如提供的是“和”和“可用”，即表明在法律允许的范围内，不包含任何明示或默示的任何类型的声明、保证或条件，包括所有默示的适销性、适销性质量、特定用途的适用性、持久性、所有权和不侵权的保证或条件。</p>
                <p style={{ textAlign: "justify" }}>您承认并同意，在法律许可的范围内，HKQAA 将不会就下列情况承担责任：(i)内容错误或不准确；(ii)由于您访问或使用我们的网站而造成的人身伤害、财产损害或其他损害；(iii)未经授权访问或使用我们的服务器、任何个人信息或用户数据：(iv)向本网站传播或从本网站传播的中断；(v)任何可能在本网站或通过本网站传播的漏洞、病毒、特洛伊木马或类似病毒; 或(vi)因本网站任何内容或使用本网站发布或共享的任何内容而产生的任何类型的损害、损失、成本、开支或责任。</p>
                <p style={{ textAlign: "justify" }}>在适用法律允许的最大范围内，您承认并同意，在任何情况下，HKQAA不会对您或任何第三方因本条款而引致或与本条款有关的任何直接的、特殊的、偶然性的、惩罚性的或间接性损失（包括利润损失、收入损失或数据损失）或获得替代性产品的成本承担赔偿责任，无论该等赔偿责任如何引致，无论该等赔偿责任是否基于合约、保证、侵权（包括疏忽）、严格责任或其他原因而引致，以及无论HKQAA是否已被告知该等损害赔偿的可能性。</p>
                <p style={{ textAlign: "justify" }}><strong>损害赔偿</strong></p>
                <p style={{ textAlign: "justify" }}>您同意赔偿和使HKQAA及其母公司、附属公司、联属公司、合伙人、高级职员、董事、代理人、承办商、授权人、服务供应商、分包商、供应商、实习生及雇员免于遭受任何因您违反本条款和条件或其引用包含的文件，或您违反任何法律或侵犯第三方权利而由任何第三方提出的索赔或要求（包括律师费）之损害。</p>
                <p style={{ textAlign: "justify" }}><strong>独立性</strong></p>
                <p style={{ textAlign: "justify" }}>如果本条款和条件的任何条款被认定为非法、无效或不可执行，则该等条款仍应在适用法律允许的最大范围内可执行，且不可执行的部分应被视为与本条款和条件分离，该认定不应影响任何其他剩余条款的有效性和可执行性。</p>
                <p style={{ textAlign: "justify" }}><strong>终止</strong></p>
                <p style={{ textAlign: "justify" }}>双方在本条款和条件终止日前产生的义务和责任在本条款和条件终止后仍然有效。</p>
                <p style={{ textAlign: "justify" }}>本条款和条件在您或我们终止之前有效。您可随时通过通知我们您不再希望使用我们的服务或停止使用我们的网站而终止本条款和条件。</p>
                <p style={{ textAlign: "justify" }}>如果我们认为您未遵守本条款和条件，我们也有权随时终止本协议而无需另行通知您。您仍将对截至并包括终止日期的所有到期款项承担责任；我们可能因此拒绝您访问我们的服务（或其中的任何部分）。</p>
                <p style={{ textAlign: "justify" }}><strong>弃权</strong></p>
                <p style={{ textAlign: "justify" }}>我们未行使或未执行本条款和条件的任何权利或规定，不构成我们对该等权利或规定的弃权。</p>
                <p style={{ textAlign: "justify" }}><strong>完整协议</strong></p>
                <p style={{ textAlign: "justify" }}>本条款和条件以及我们发布在本网站或与服务有关的任何政策或操作规则构成我们双方之间的完整协议和理解，取代双方此前或临时协议、通信和建议，无论是口头或书面形式，包括但不限于任何先前版本的条款和条件。</p>
                <p style={{ textAlign: "justify" }}>本条款和条件解释中的任何歧义不得对起草方造成不利影响。</p>
                <p style={{ textAlign: "justify" }}><strong>法律适用</strong></p>
                <p style={{ textAlign: "justify" }}>本条款和条件及我们为您提供服务的任何单独协议应受香港特别行政区法律管辖并按香港特别行政区法律解释。任何因本条款和条件而产生或与本条款和条件有关的争议或索赔，均受香港特别行政区法院的专属管辖。</p>
                <p style={{ textAlign: "justify" }}><strong>条款和条件的变更</strong></p>
                <p style={{ textAlign: "justify" }}>您可随时在此页面查看条款和条件的最新版本。我们保留自行更新、更改或替换本条款和条件的权利，并会不时更新及/或更改我们的网站。本条款和条件的更改将适用于在本条款和条件规定的生效日期或之后所下订单或使用的服务。您有责任定期检查本页面的变化。在发布任何更改后，您继续使用我们的服务或访问本网站，即表示您接受这些更改。</p>
                <p style={{ textAlign: "justify" }}><strong>联系方式</strong></p>
                <p style={{ textAlign: "justify" }}>有关本条款和条件的问题，请发送至<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>。</p>
    
    
            </div>
        );
    } else {
        return (
            <div>
                <p style={{ textAlign: "justify" }}><strong>Website</strong> <strong>Terms &amp; Conditions (&ldquo;Terms&rdquo;) &ndash; For General Public</strong></p>
                <p style={{ textAlign: "justify" }}><strong>Last Updated: 13 May 2022</strong></p>
                <p style={{ textAlign: "justify" }}><strong>Overview</strong></p>
                <p style={{ textAlign: "justify" }}>This website Lightshare (&ldquo;<strong>Website</strong>&rdquo;) is operated by the Hong Kong Quality Assurance Agency (hereinafter referred as &ldquo;<strong>HKQAA</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;), whose registered office is at 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong.</p>
                <p style={{ textAlign: "justify" }}>HKQAA offers this Website, including all information, tools and services available from this Website to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                <p style={{ textAlign: "justify" }}>By visiting our Website, you engage in our service (&ldquo;<strong>Service</strong>&rdquo;) and agree to be bound by the following terms and conditions (&ldquo;<strong>Terms and Conditions</strong>&rdquo;, &ldquo;<strong>Terms</strong>&rdquo;), including those additional terms and conditions and policies referenced herein and/or available at <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>. These Terms and Conditions apply to all users of the Website, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of the content.</p>
                <p style={{ textAlign: "justify" }}>Please read these Terms and Conditions carefully before accessing or using our Website. By accessing or using any part of the Website, you agree to be bound by these Terms and Conditions.</p>
                <p style={{ textAlign: "justify" }}>If you find yourself unable to agree to these Terms and Conditions, you must not proceed to purchase any Services or access our Website.</p>
                <p style={{ textAlign: "justify" }}><strong>General Conditions</strong></p>
                <p style={{ textAlign: "justify" }}>We reserve the right to refuse Service or the use of our Website to anyone for any reason at any time. You understand that any information provided by you may be transferred unencrypted and may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. If HKQAA accepts credit card payment on the Website, your credit card information will always be encrypted during transfer over networks. The treatment of your personal data is subject to our Privacy Policy available at <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                <p style={{ textAlign: "justify" }}>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms and Conditions.</p>
                <p style={{ textAlign: "justify" }}><strong>Modifications of Services</strong></p>
                <p style={{ textAlign: "justify" }}>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
                <p style={{ textAlign: "justify" }}>We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.</p>
                <p style={{ textAlign: "justify" }}><strong>Registration</strong></p>
                <p style={{ textAlign: "justify" }}>In order to use certain integrated services on our Website, you must register an account with HKQAA on the Website. You are wholly responsible to keep your login credentials confidential and secured. You are also wholly responsible for all activities that occur on or in connection with your account and you agree to notify us immediately of any unauthorized access or use of your account. You acknowledge and agree that we are not responsible or liable for any damages, losses, costs and expenses, or liabilities related to any unauthorized access to or use of your account.</p>
                <p style={{ textAlign: "justify" }}>You agree to provide true, accurate, current, and complete information about yourself or the entity that you are representing as prompted by us at registration. You must not impersonate others or otherwise provide false information when creating an account on our Website. If you are representing a company, organization or a legal entity or otherwise registering on behalf of someone else (e.g. your employees), you represent and warrant that you have the authority to bind such entity or individuals to these Terms and Conditions. We have the right to suspend your use of the Website if we reasonably believe that the information you provided to us at registration is not true, accurate, current or complete. We may require supporting identification document from you such as business registration documents and you shall provide such documents as instructed. You understand that if you fail to provide such documents as requested, you shall not be able to register an account on the Website.</p>
                <p style={{ textAlign: "justify" }}><strong>Our Services</strong></p>
                <p style={{ textAlign: "justify" }}>This Website is an integrated service platform that facilitates the hosting of online courses and circulation of educational materials for users and other add-on services to be introduced by HKQAA from time to time, including but not limited to job-matching services.</p>
                <p style={{ textAlign: "justify" }}>By registering an account on the Website, users gain access to various Course Content provided by our respective course content providers (&ldquo;<strong>Course Content Providers</strong>&rdquo;) (subject to payment) and the right to enjoy all other Services on the Website such as job-matching services, subject to these Terms and Conditions and the applicable additional terms. In relation to the attendance of courses on the Website, upon successful completion of certain courses, a digital certificate shall be issued to attendees. For certain courses, physical certificates may be available upon request, additional fees shall apply.</p>
                <p style={{ textAlign: "justify" }}>We reserve the right but are not obligated, to limit our Service or any part of our Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. Although the Website may be accessible worldwide, not all features, Services provided or offered through or on the Website are available to all persons or in all geographic locations. You further acknowledge that your access and use of the Website and our Services offered may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason including but not limited to system failures and technical interruptions. HKQAA has the absolute right, without any liability to you, to suspend your access to our Website or our Service at any time, for all scheduled downtime for the purpose of periodic maintenance or otherwise</p>
                <p style={{ textAlign: "justify" }}>All descriptions of our Services and fees are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any Services at any time without notice.</p>
                <p style={{ textAlign: "justify" }}><strong>Accuracy, Completeness And Timeliness Of Information</strong></p>
                <p style={{ textAlign: "justify" }}>We are not responsible if information made available on this Website is not accurate, complete, or current. The material on this Website is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this Website is at your own risk. No information obtained by you from the Website shall create any warranty not expressly stated by us in these Terms and Conditions.</p>
                <p style={{ textAlign: "justify" }}>This Website may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this Website at any time, but we have no obligation to update any information on our Website. You agree that it is your responsibility to monitor changes to our Website.</p>
                <p style={{ textAlign: "justify" }}><strong>Licence to Course Content</strong></p>
                <p style={{ textAlign: "justify" }}>The Website allows users to upload, submit, store, send, transmit content and data, including but not limited to your online course videos, course materials, promotional materials (collectively, &ldquo;<strong>Course Content</strong>&rdquo;). We also encourage users to interact and communicate with the community on our Website in your areas of interest.</p>
                <p style={{ textAlign: "justify" }}>Upon your enrolment in Course Content, and subject to your full payment of applicable fees, the Course Content Provider shall grant you a limited rights to a non-exclusive, royalty-free, worldwide, revocable licence to use the Course Content solely for your personal non-commercial use only. Use of the Course Content or our Services for any purposes that is not contemplated under these Terms and Conditions is a violation of HKQAA&rsquo;s and/or the Course Content Provider&rsquo;s proprietary rights.</p>
                <p style={{ textAlign: "justify" }}>In your use of any Course Content, you must not:</p>
                <ol type="i" style={{ textAlign: "justify" }}>
                    <li>republish any Course Content or materials in whatever form;</li>
                    <li>sell, rent or sub-license any Course Content or material to a third party without HKQAA&rsquo;s authorization;</li>
                    <li>reproduce, duplicate, copy or otherwise exploit any Course Content or material for commercial purpose; and</li>
                    <li>redistribute any Course Content or materials without HKQAA&rsquo;s authorization.</li>
                </ol>
                <p style={{ textAlign: "justify" }}>In respect of training organizations and associations or other business entities, if any Course Content is specifically made available for redistribution, it must only be redistributed within your organization.</p>
                <p style={{ textAlign: "justify" }}>You hereby acknowledge and agree that HKQAA merely serves as a facilitator and platform provider for Course Content Providers to offer Course Content to you. The view and opinions expressed in any Course Content or any other content displayed on the Website do not necessarily reflect those of HKQAA. In particular, nothing in this Website shall be construed as legal, tax, investment, financial or other professional advice. HKQAA&rsquo;s hosting of Course Content on the Website does not imply any endorsement, sponsorship or recommendation by HKQAA of any third party, nor does any information on the Website constitute a comprehensive, complete or accurate statement of the matters discussed or the law relating thereto. Reference to any products, services, process or any service by trade name, trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favouring by HKQAA. You assume sole responsibility of evaluating the merits and risks associated with the use of any information provided by HKQAA or from Course Content.</p>
                <p style={{ textAlign: "justify" }}>HKQAA reserves the right to remove any user generated content from the Website if HKQAA is of the opinion that such content violates the Acceptable Use Policy as stipulated below.</p>
                <p style={{ textAlign: "justify" }}><strong>Acceptable Use Policy</strong></p>
                <p style={{ textAlign: "justify" }}>Your conduct on this Website shall comply with our acceptable use policy set forth below. HKQAA may (but is not obligated to) monitor and review your account, content, and conduct when you use our Website. HKQAA shall have the right to suspend your access to the Website if HKQAA finds any of your content or conduct on the Website is in violation of these Terms and Conditions.</p>
                <p style={{ textAlign: "justify" }}>You hereby agree not to:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>copy, modify, alter, adapt, translate, reverse engineer, disassemble, decompile, create derivative works from, or distribute any content from our services;</li>
                    <li>alter, remove, tamper, copy, display or use our trademarks and all other HKQAA-owned intellectual property for any purpose not explicitly authorized in these Terms and Conditions;</li>
                    <li>use our products or services for any revenue-generating endeavours not authorized by HKQAA; or other activities that are beyond the scope of any agreements entered into between HKQAA and you;</li>
                    <li>submit, share, post, communicate or otherwise take any actions that incites or encourages actions that is, explicit or implicitly illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic, indecent, profane, obscene, or otherwise objectionable (including nudity);</li>
                    <li>engage in spidering or harvesting or participate in the use of software, including spyware, designed to collect data from our Website, including from any user of our Website;</li>
                    <li>transmit any virus, other computer instruction, or technological means intended to, or that may, disrupt, damage, or interfere with the use of computers or related systems;</li>
                    <li>stalk, harass, threaten or harm any third party;</li>
                    <li>impersonate any third party;</li>
                    <li>participate in any fraudulent or illegal activity, including but not limited to phishing, money laundering, or fraud;</li>
                    <li>access our Website to obtain information to build a similar or competitive website, application or otherwise;</li>
                    <li>use the Website for any illegal or unauthorized purpose, or purposes that violates any applicable laws (including but not limited to, copyright and privacy laws) or rights of other third parties;</li>
                    <li>set up links to the Website using any HTML techniques that display the Website within a frame, partial window, pop-up, pop-under, or any other non-standard linking methods, or redistribute any content from the Website unless expressly authorized by HKQAA; or</li>
                    <li>advocate, encourage or assist any third party in doing any of the foregoing.</li>
                </ol>
                <p style={{ textAlign: "justify" }}>Furthermore, you shall at all times, be prohibited from uploading content that:</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>infringes any patent, trademark, trade secret, copyright or other intellectual property right of another person or entity;</li>
                    <li>violates any contractual or fiduciary agreements with any other third parties;</li>
                    <li>impersonates any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                    <li>is sexually explicit or promotes a sexual service;</li>
                    <li>is defamatory;</li>
                    <li>contains hateful or discriminatory speech;</li>
                    <li>promotes or support any terror or hate groups;</li>
                    <li>contains instructions on how to assemble explosive devices or improvised firearms;</li>
                    <li>exploits or endangers minors;</li>
                    <li>depicts or encourages self-harm or suicide;</li>
                    <li>depicts any acts of violence, including but not limited to animal cruelty and torture;</li>
                    <li>promotes fraudulent or dubious money-making schemes, propose any unlawful transaction or use of deceptive marketing practices;</li>
                    <li>contains any false or misleading information; or</li>
                    <li>violates any applicable laws.</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>Payment</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Course Fee</span>.</p>
                <p style={{ textAlign: "justify" }}>You acknowledge and agree that HKQAA or where applicable, Course Content Provider shall charge a fee for the enrolment of for any Course Content (unless it is expressly stated as a free course). The fee for the Course Content shall be as indicated on the display page for each Course Content. In HKQAA&rsquo;s sole opinion, the fees and payment methods may be varied from time to time with prior notice to you. All fees payable to HKQAA shall be paid immediately in according with the payment terms as agreed between HKQAA and you.</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Direct Payment to Course Content Providers</span></p>
                <p style={{ textAlign: "justify" }}>If it is expressly indicated by us that payments for Course Content shall be paid directly to such offering Course Content Provider, the Course Content Provider would contact you directly via email to confirm your enrolment of the Course and provide payment instructions. In such circumstances, HKQAA shall only act as a facilitator between you and the Course Content Provider in these transaction. You are solely responsible for the transaction between you and the Course Content Provider. HKQAA shall not be a party to the agreement or transaction between you and the Course Content Provider.</p>
                <p style={{ textAlign: "justify" }}>Once you have settled the payment with the Course Content Provider, the Course Content Provider shall grant you tokens to attend the Course. You are fully responsible for keeping such tokens secured and confidential. We and the Course Content Provider will not reissue such tokens to you if you lose them. Such tokens are not refundable or exchangeable for cash or other discounts and can only be used for the purpose of gaining access to the Course. You shall not sell, transfer, or assign such tokens to a third party.</p>
                <p style={{ textAlign: "justify" }}>All payment made to HKQAA or where applicable, to the Course Content Providers are in the currencies as indicated by HKQAA are non-refundable. HKQAA shall not be responsible for any contributions, payments, taxes or payment to any third party. All such costs shall be solely borne by you.</p>
                <p style={{ textAlign: "justify" }}><strong>Third Party Content and Interactions</strong></p>
                <p style={{ textAlign: "justify" }}>The Website may contain certain features and functionalities that link to or provide you with access to third party content, that is completely not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. Please exercise your own independent judgment before proceeding with any contact or interaction, in connection with the Website or otherwise.</p>
                <p style={{ textAlign: "justify" }}>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party&rsquo;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>User Comments, Feedback And Other Submissions</strong></p>
                <p style={{ textAlign: "justify" }}>If at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, &ldquo;<strong>Comments</strong>&rdquo;), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any Comments that you forward to us. We are and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay compensation for any Comments; or (3) to respond to any Comments. We may, but have no obligation to, monitor, edit or remove Comments or part of the Comments that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms and Conditions.</p>
                <p style={{ textAlign: "justify" }}>You agree that your Comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary rights. You further agree that your Comments will not contain libellous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any Comments posted by you or any third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>Personal Data Protection</strong></p>
                <p style={{ textAlign: "justify" }}>Your submission of personal data through the Website is governed by our Privacy Policy, which can be accessed via <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> .</p>
                <p style={{ textAlign: "justify" }}><strong>Copyright and Intellectual Property Policy</strong></p>
                <p style={{ textAlign: "justify" }}>We are committed to protect the intellectual property of the rightful owners. If you believe that your material has been copied in a way that constitutes copyright infringement, please send the following information via <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> :</p>
                <ol type="a" style={{ textAlign: "justify" }}>
                    <li>your name, address, telephone number and email address;</li>
                    <li>description of the work that you claim is being infringed;</li>
                    <li>description of the material that you claim is infringing and are requesting be removed along with the link to such material;</li>
                    <li>a statement that you have &ldquo;a good faith belief that the use of the material is in the manner complained of is not authorized by the copyright owner, its agent, or the law&rdquo;;</li>
                    <li>an electronic or physical signature of the copyright owner (or an authorized person to act for the copyright owner); and</li>
                    <li>a statement by you that the information you are providing is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li>
                </ol>
                <p style={{ textAlign: "justify" }}>HKQAA has the right not to process your request if you do not follow these requirements.</p>
                <p style={{ textAlign: "justify" }}><strong>Complaints and Disputes Handling</strong></p>
                <p style={{ textAlign: "justify" }}>If you believe that any of the Course Content or any other content as posted on the Website is in violation of your proprietary rights or is otherwise violating the Acceptable Use Policy, please report to us via <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> . HKQAA shall acknowledge receipt of your complaint within 3 business days by post, fax or email and shall look into the complaint accordingly. Upon acknowledgement of your complaint regarding any account or Course Content, HKQAA may remove or temporarily suspend access to the particular Course Content or terminate the subject account on the Website pending a determination by HKQAA. HKQAA has the final determination in all complaints.</p>
                <p style={{ textAlign: "justify" }}><strong>Disclaimer Of Warranties; Limitation Of Liability</strong></p>
                <p style={{ textAlign: "justify" }}>We do not guarantee, represent, or warrant that your use of our Website will be uninterrupted, timely, secure, or error-free. We do not warrant that the information and content as provided on the Website will be accurate or reliable. You agree that from time to time we may remove the Website for indefinite periods of time or cancel any of our Services offered at any time, without notice to you.</p>
                <p style={{ textAlign: "justify" }}>You expressly agree that your use of, or inability to use, the Website is at your sole risk. The Website or services delivered to you through the Website are (except as expressly stated by us) provided &lsquo;as is&rsquo; and &lsquo;as available&rsquo; for your use, without any representation, warranties or conditions of any kind, either express or implied to the extent permitted by law, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                <p style={{ textAlign: "justify" }}>You acknowledge and agree that HKQAA shall, to the extent permitted by law, have no liability for any: (i) errors, mistakes, or inaccuracies of content; (ii) personal injury, property damage, or other harm resulting from your access to or use of our Website; (iii) any unauthorised access to or use of our servers, any personal information, or user data; (iv) any interruption of transmission to or from our Website; (v) any bugs, viruses, trojan horses, or the like that may be transmitted on or through our Website; or (vi) any damages, losses, costs, expenses, or liabilities of any kind incurred as a result of any content or the use of any content posted or shared through our Website.</p>
                <p style={{ textAlign: "justify" }}>To the fullest extent permitted by applicable laws, you acknowledge and agree that in no event will HKQAA be liable to you or to any third party for any direct, indirect, special, incidental, punitive, or consequential damages (including for loss of profits, revenue, or data) or for the cost of obtaining substitute products, arising out of or in connection with these Terms, however caused, whether such liability arises from any claim based upon contract, warranty, tort (including negligence), strict liability or otherwise, and whether or not HKQAA has been advised of the possibility of such damages.</p>
                <p style={{ textAlign: "justify" }}><strong>Indemnification</strong></p>
                <p style={{ textAlign: "justify" }}>You agree to indemnify, defend and hold harmless HKQAA and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including attorneys&rsquo; fees, made by any third party due to or arising out of your breach of these Terms and Conditions or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>Severability</strong></p>
                <p style={{ textAlign: "justify" }}>In the event that any provision of these Terms and Conditions is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms and Conditions, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                <p style={{ textAlign: "justify" }}><strong>Termination</strong></p>
                <p style={{ textAlign: "justify" }}>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                <p style={{ textAlign: "justify" }}>These Terms and Conditions are effective unless and until terminated by either you or us. You may terminate these Terms and Conditions at any time by notifying us that you no longer wish to use our Services, or when you cease using our Website.</p>
                <p style={{ textAlign: "justify" }}>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms and Conditions, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                <p style={{ textAlign: "justify" }}><strong>Waiver</strong></p>
                <p style={{ textAlign: "justify" }}>The failure of us to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</p>
                <p style={{ textAlign: "justify" }}><strong>Entire Agreement</strong></p>
                <p style={{ textAlign: "justify" }}>These Terms and Conditions and any policies or operating rules posted by us on this Website or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms and Conditions).</p>
                <p style={{ textAlign: "justify" }}>Any ambiguities in the interpretation of these Terms and Conditions shall not be construed against the drafting party.</p>
                <p style={{ textAlign: "justify" }}><strong>Governing Law</strong></p>
                <p style={{ textAlign: "justify" }}>These Terms and Conditions and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Hong Kong SAR. Any dispute or claim arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Hong Kong SAR.</p>
                <p style={{ textAlign: "justify" }}><strong>Changes To Terms and Conditions</strong></p>
                <p style={{ textAlign: "justify" }}>You can review the most current version of the Terms and Conditions at any time on this page. We reserve the right to update, change or replace any part of these Terms and Conditions by posting updates and/or changes to our Website from time to time. Any changes to the Terms and Conditions will apply to orders placed or Services used on or after the effective date stated in these Terms and Conditions. It is your responsibility to check this page periodically for changes. Your continued use of our Services or access to the Website following the posting of any changes constitutes acceptance of those changes.</p>
                <p style={{ textAlign: "justify" }}><strong>Contact Information</strong></p>
                <p style={{ textAlign: "justify" }}>Questions about the Terms and Conditions should be sent to us at <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> .</p>


            </div>
        );
    }
}
