import Layout from "../../components/Layout";
import Section from "../../components/Section";
import H2 from "../../components/H2";
import H5 from "../../components/H5";
import LineLimiter from "../../components/LineLimiter";
import { getApiClient } from "../../util/auth";
import { parseDbStringToObj } from "../../util/lang";
import React, { useEffect, useState } from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Paragraph from "../../components/Paragraph";
import Banner from "../../components/Banner";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import '../../App.css';
import { useTranslation } from "react-i18next";
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function InsightsAndTrends() {
  const { t } = useTranslation();

  const api = getApiClient();

  const [filter, setFilter] = useState("By Date");
  const [search, setSearch] = useState("");

  const [all_insights_and_trends, set_all_insights_and_trends] = useState([]);

  useEffect(() => {
    api
      .post("/public/view_all_insights_and_trends")
      .then(function (response) {
        set_all_insights_and_trends(response.data.all_insights_and_trends);
        setInsights(response.data.all_insights_and_trends);
      })
      .catch(function (error) {
        console.log(error?.data?.response?.message);
      });
  }, []);

  const getInsightsAndTrendsDetails_Filter = (data, order_by = "", search = "") => {
    var jj = data;

    if(search === "") {
      jj = data;
    } else {
      jj = data.filter(function (e) {
        let t = e.Insights_And_Trends_Title.toLowerCase().includes(search.toLowerCase());
        let d = e.Insights_And_Trends_Content.toLowerCase().includes(search.toLowerCase());
        return t || d;
      });
    }

    if (order_by === "" || order_by === "By Date" || order_by === "按日期") {
      jj.sort(function (a, b) {
        return new Date(b.Last_Published_Date).getTime() - new Date(a.Last_Published_Date).getTime();;
      });
    } else if (order_by === "A to Z" || order_by === "A至Z") {
      jj.sort(function (a, b) {
        a = parseDbStringToObj(a.Insights_And_Trends_Title)[CURRENT_LANGUAGE].toLowerCase();
        b = parseDbStringToObj(b.Insights_And_Trends_Title)[CURRENT_LANGUAGE].toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
    } else if (order_by === "Z to A" || order_by === "Z至A") {
      jj.sort(function (a, b) {
        a = parseDbStringToObj(a.Insights_And_Trends_Title)[CURRENT_LANGUAGE].toLowerCase();
        b = parseDbStringToObj(b.Insights_And_Trends_Title)[CURRENT_LANGUAGE].toLowerCase();
        return a > b ? -1 : a < b ? 1 : 0;
      });
    }
    return jj;
  }

  const insightsAndTrends = getInsightsAndTrendsDetails_Filter(all_insights_and_trends, filter, search);
  const [insights, setInsights] = useState(insightsAndTrends);

  let lastUpdate;
  const searchInsights = (e) => {
    let s = e.target.value;
    setSearch(s)
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 500) {
        if (s === "") {
          setInsights(getInsightsAndTrendsDetails_Filter(all_insights_and_trends, filter, ""))
        }
        else {
          setInsights(getInsightsAndTrendsDetails_Filter(all_insights_and_trends, filter, s));
        }
      }
    }, 500);
  };

  const filterInsights = (e) => {
    let f = e.target.value;
    setFilter(f);
    setInsights(getInsightsAndTrendsDetails_Filter(all_insights_and_trends, f, search));
  }

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner_insight.png" backgroundPosition="right">
        {t('Insights And Trends')}
      </Banner>
      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 0%",
            backgroundSize: "280px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="align-items-center mb-3 g-0">
            <Col xs={12} md={true}>
              <div className={ "frontend-page-mobile-container" } style={{ paddingRight: 32 }}>
                <img
                  className={"img-fluid w-100"}
                  alt="thumbnail"
                  src={"/assets/images/insights-and-trends-thumbnail.png"}
                />
              </div>
            </Col>
            <Col>
              <div className="insights-container" style={{ paddingLeft: 32 }}>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {t('Insights and Trends')}
                </H2>
                <H5 style={{ fontSize: 18 }} className={"mb-4"}>
                  {t('Stay ahead of megatrends with visionary perspectives')}
                </H5>
                <p style={{ fontSize: 16 }}>
                  {t('"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA). This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.')}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col
              style={{
                flex: "0 0 auto",
                // width: "calc(50% - 40px)",
                minHeight: 1,
              }}
              md={4}
              className={"d-md-flex d-none"}
            ></Col>
            <Col sm={4} md={3} className="d-flex align-items-center mb-3 mb-md-0">
              <span style={{ minWidth: "70px" }}>{t('Filter By')}</span>
              <select id="filter-select" className="form-select border-004a87" onChange={filterInsights}>
                <option>{t('By Date')}</option>
                <option>{t('A to Z')}</option>
                <option>{t('Z to A')}</option>
              </select>
            </Col>
            <Col className={"col-md-5 col-sm-12"}>
              <div className="input-group">
                <span
                  className="input-group-text bg-white border-end-0 border-004a87"
                  id="search"
                >
                  <i className="bi bi-search" />
                </span>
                <input
                  type="text"
                  className="form-control border-start-0 border-004a87"
                  placeholder={t("Search")}
                  onChange={searchInsights}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3 gy-3">
            {insights.map((insightsAndTrend) => (
              <Col className="d-flex flex-column" xs={6} md={4} key={insightsAndTrend.id}>
                <img
                  src={insightsAndTrend.Insights_And_Trends_Photo}
                  alt="Insights_And_Trends_Photo"
                  className="img-fluid w-100"
                  style={{ aspectRatio: "300 / 200", objectFit: "cover" }}
                />
                <div
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "20px",
                    minHeight: "72px",
                  }}
                  className="fw-900 limit-text limit-text-3"
                >
                  {parseDbStringToObj(insightsAndTrend.Insights_And_Trends_Title)[CURRENT_LANGUAGE]}
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    lineHeight: "24px",
                    marginTop: "20px",
                  }}
                >
                  {moment(insightsAndTrend.Last_Published_Date)?.format('DD MM YYYY')}
                </div>
                <LineLimiter>
                  {parseDbStringToObj(insightsAndTrend.Insights_And_Trends_Content)[CURRENT_LANGUAGE]}
                </LineLimiter>
                <div className="mt-auto pt-3">
                  <Link
                    to={"/insights-and-trends/show/" + insightsAndTrend.id}
                    style={{ color: "#266599", textDecoration: "none" }}
                  >
                    {t('EXPLORE MORE')} &gt;
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
          <div className="d-none row">
            <div className="col">{t('Showing')} 15 | 30 | 60 | 90</div>
            <div className="col-auto">
              &lt; {t("Previous")} 2 3 4 5 6 ...... 20 {t("Next")} &gt;
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
}
