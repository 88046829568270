import {
  Button,
  Col,
  Container,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppVersion from "./Cms/AppVersion";
import { getApiClient } from "../util/auth";
import { getTextByCode } from "../util/lang";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default function LayoutCms(props) {

  const { height, width } = useWindowDimensions();

  const showSidebarMobile = width > 991 ? true : false;

  const currentRouteGroup = props.route?.split('.')?.[0];
  const currentRoute = props?.route;

  const [isShowProfile, setIsShowProfile] = useState(
    currentRouteGroup === 'profile'
  );
  const [isShowStaff, setIsShowStaff] = useState(
    currentRouteGroup === 'staffs'
  );
  const [isShowMember, setIsShowMember] = useState(
    currentRouteGroup === 'members'
  );
  const [isShowMenu, setIsShowMenu] = useState(showSidebarMobile);
  const [isShowCourses, setIsShowCourses] = useState(
    currentRouteGroup === 'courses'
  );
  const [isShowMembership, setIsShowMembership] = useState(
    currentRouteGroup === 'membership'
  );
  const [isShowContentManagement, setIsShowContentManagement] = useState(
    currentRouteGroup === 'content_management'
  );
  const [view_profile_response, set_view_profile_response] = useState();

  const [role, setRole] = useState();

  const getNavLinkClass = (isCurrentRoute) => {
    if (isCurrentRoute) {
      return 'd-block px-3 py-2 text-decoration-none text-white bg-eb6201';
    }

    return 'd-block px-3 py-2 text-reset text-decoration-none';
  };

  const getReportClass = (isCurrentRoute) => {
    if (isCurrentRoute) {
      return 'd-block pe-3 py-2 text-decoration-none text-white bg-eb6201';
    }

    return 'd-block pe-3 py-2 text-reset text-decoration-none';
  };

  useEffect(() => {
    getApiClient()
      .get("/user/viewProfile")
      .then((response) => {
        set_view_profile_response(response.data);
        setRole(response.data.user.Role);
      })
      .catch((error) => {
        alert(error.response.data.message ?? error.message ?? error);
      });
  }, []);

  const navigate = useNavigate();

  //for reload the reused addOrEdit component
  const changeLocation = (href) => {
    navigate(href, { replace: true });
    window.location.reload();
  }

  return (
    <>
      <Navbar bg="white" expand="lg" className={"shadow"} style={{ position: "sticky", zIndex: 1021, top: "0" }}>
        <Container fluid>
          <button className={"btn"} onClick={() => setIsShowMenu(!isShowMenu)} title={"Main Menu"}>
            <span className="navbar-toggler-icon" />
          </button>

          <Navbar.Brand href="/" className="me-auto">
            <img alt="hkqaa logo"
              src="/assets/images/logo.png"
              height="40"
              className="d-inline-block align-top"
            />
            {/* <AppVersion /> */}
          </Navbar.Brand>

          <div>
            <NavDropdown
              id={"header-nav"}
              title={
                <span>
                  <img
                    style={{ width: "36px", height: "36px" }}
                    alt="user profile"
                    src={
                      role === 'Organization' 
                      ? 
                      view_profile_response?.user?.Organization_Logo ?? "https://via.placeholder.com/300" 
                      : 
                      role === 'Trainer/Academy' 
                      ? 
                      view_profile_response?.user?.Trainer_Logo ?? "https://via.placeholder.com/300" 
                      : 
                      role === 'Association' 
                      ? 
                      view_profile_response?.user?.Association_Logo ?? "https://via.placeholder.com/300" 
                      : 
                      role === 'Admin'
                      ?
                      view_profile_response?.user?.Profile_Photo ?? "https://via.placeholder.com/300"
                      :
                      "https://via.placeholder.com/300"
                    }
                    className={"rounded-circle img-fluid me-3"}
                  />
                  <span className={"text-5c5c5c"}>{role === 'Organization' ? getTextByCode(view_profile_response?.user?.Organization_Name, 'EN') : role === 'Trainer/Academy' ? getTextByCode(view_profile_response?.user?.Trainer_Name, 'EN') : role === 'Association' ? getTextByCode(view_profile_response?.user?.Association_Name, 'EN') : role === 'Admin' ? 'HKQAA' : ''}</span>
                </span>
              }
            >
              {role === 'Admin'
              ?
              <NavDropdown.Item href="/admin">Logout</NavDropdown.Item>
              :
              <NavDropdown.Item href="/login">Logout</NavDropdown.Item>
              }
            </NavDropdown>
          </div>
          {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
          {/*<Navbar.Collapse id="basic-navbar-nav">*/}
          {/*    <Nav className="me-auto">*/}
          {/*        <Nav.Link href="/">Home</Nav.Link>*/}
          {/*    </Nav>*/}
          {/*</Navbar.Collapse>*/}
        </Container>
      </Navbar>

      <Container fluid className={"p-0"} style={{ backgroundColor: "#F5F5F8" }}>
        <div className="d-flex align-item-start">
          {isShowMenu ? (
            <Col
              style={{ flex: "0 0 240px", top: "68px" }}
              className={"sticky-top vh-100 bg-white shadow cms-sidebar d-block"}
            >
              <div className={"d-flex flex-column flex-shrink-0 py-3 h-100"}>
                {role !== 'Admin' &&
                <Button
                  onClick={() => setIsShowProfile(!isShowProfile)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Profile
                </Button>
                }
                <div className={`collapse ${isShowProfile ? "show" : ""}`}>
                  <Link
                    to={role === "Organization" ? "/cms/profile" : role === "Trainer/Academy" ? "/cms/trainer-profile" : role === "Association" ? "/cms/association-profile" : "/login"}
                    className={getNavLinkClass(currentRoute === 'profile.index')}
                  >
                    <span className={"ps-3"}>View Profile</span>
                  </Link>
                  <Link
                    to={role === "Organization" ? "/cms/profile/edit" : role === "Trainer/Academy" ? "/cms/trainer-profile/edit" : role === "Association" ? "/cms/association-profile/edit" : "/login"}
                    className={getNavLinkClass(currentRoute === 'profile.edit')}
                  >
                    <span className={"ps-3"}>Edit Profile</span>
                  </Link>
                </div>

                {role === 'Organization' &&
                <Button
                  onClick={() => setIsShowStaff(!isShowStaff)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Staff
                </Button>
                }
                <div className={`collapse ${isShowStaff ? "show" : ""}`}>
                  <Link
                    to={"/cms/staffs"}
                    className={getNavLinkClass(currentRoute === 'staffs.index')}
                  >
                    <span className={"ps-3"}>Staff Management</span>
                  </Link>
                  <Link
                    to={"/cms/staffs/create"}
                    className={getNavLinkClass(currentRoute === 'staffs.create')}
                    onClick={() => changeLocation("/cms/staffs/create")}
                  >
                    <span className={"ps-3"}>Add Staff</span>
                  </Link>
                  {/* <Link
                    to={"/cms/staffs/assigned-user"}
                    className={getNavLinkClass(currentRoute === 'staffs.assigned-user')}
                  >
                    <span className={"ps-3"}>Assigned User</span>
                  </Link> */}
                </div>

                {role === 'Association' &&
                <Button
                  onClick={() => setIsShowMember(!isShowMember)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Member
                </Button>
                }
                <div className={`collapse ${isShowMember ? "show" : ""}`}>
                  <Link
                    to={"/cms/members"}
                    className={getNavLinkClass(currentRoute === 'members.index')}
                  >
                    <span className={"ps-3"}>Member Management</span>
                  </Link>
                  <Link
                    to={"/cms/members/create"}
                    className={getNavLinkClass(currentRoute === 'members.create')}
                    onClick={() => changeLocation("/cms/members/create")}
                  >
                    <span className={"ps-3"}>Add Member</span>
                  </Link>
                  {/* <Link
                    to={"/cms/members/assigned-user"}
                    className={getNavLinkClass(currentRoute === 'members.assigned-user')}
                  >
                    <span className={"ps-3"}>Assigned User</span>
                  </Link> */}
                </div>

                {role !== 'Admin' &&
                <Button
                  onClick={() => setIsShowCourses(!isShowCourses)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Courses
                </Button>
                }
                <div className={`collapse ${isShowCourses ? "show" : ""}`}>
                  <Link
                    to={role === 'Organization' ? "/cms/courses/all" : role === 'Trainer/Academy' ? "/cms/trainer-courses/all" : role === 'Association' ? "/cms/association-courses/all" : "/login"}
                    className={
                      getNavLinkClass(
                        currentRoute === 'courses.index' ||
                        currentRoute === 'courses.show' ||
                        currentRoute === 'courses.showModules' ||
                        currentRoute === 'courses.showClasses' ||
                        currentRoute === 'courses.showQuiz' ||
                        currentRoute === 'courses.showEvaluations' ||
                        currentRoute === 'courses.edit' ||
                        currentRoute === 'courses.editModules' ||
                        currentRoute === 'courses.editClasses' ||
                        currentRoute === 'courses.editQuiz' ||
                        currentRoute === 'courses.editEvaluations'
                      )
                    }
                  >
                    <span className={"ps-3"}>View Course</span>
                  </Link>
                  <Link
                    to={"/cms/create/course"}
                    className={
                      getNavLinkClass(
                        currentRoute === 'courses.create' ||
                        currentRoute === 'courses.addModules' ||
                        currentRoute === 'courses.addClasses' ||
                        currentRoute === 'courses.addQuiz' ||
                        currentRoute === 'courses.addEvaluations'
                      )
                    }
                    onClick={() => changeLocation("/cms/create/course")}
                  >
                    <span className={"ps-3"}>Add Course</span>
                  </Link>
                </div>

                {role !== 'Admin' &&
                <div>
                  <Link
                    to={role === "Organization" ? "/cms/reports" : role === "Trainer/Academy" ? "/cms/trainer-reports" : role === "Association" ? "/cms/association-reports" : "/login"}
                    className={getReportClass(currentRoute === 'reports.index')}
                  >
                    <span className={"ps-3"} style={{ marginLeft: '-2px' }}>Reports</span>
                  </Link>
                  {/* <AppVersion /> */}
                </div>
                }

                {role === 'Admin' &&
                <Button
                  onClick={() => setIsShowMembership(!isShowMembership)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Control and Report
                </Button>
                }
                <div className={`collapse ${isShowMembership ? "show" : ""}`}>
                  <Link
                    to={"/cms/admin/membership/viewUsers"}
                    className={getNavLinkClass(currentRoute === 'membership.viewUsers')}
                  >
                    <span className={"ps-3"}>Users</span>
                  </Link>
                  <Link
                    to={"/cms/admin/membership/viewCourses"}
                    className={getNavLinkClass(currentRoute === 'membership.viewCourses')}
                  >
                    <span className={"ps-3"}>Courses</span>
                  </Link>
                </div>

                {role === 'Admin' &&
                <Button
                  onClick={() => setIsShowContentManagement(!isShowContentManagement)}
                  className={"rounded-0 text-body text-start"}
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  Content Management
                </Button>
                }
                <div className={`collapse ${isShowContentManagement ? "show" : ""}`}>
                  {/* <Link
                    to={"/cms/admin/content_management/home_page"}
                    className={getNavLinkClass(currentRoute === 'content_management.home_page')}
                  >
                    <span className={"ps-3"}>Homepage</span>
                  </Link> */}
                  <Link
                    to={"/cms/admin/content_management/insights_and_trends"}
                    className={getNavLinkClass(currentRoute === 'content_management.insights_and_trends')}
                  >
                    <span className={"ps-3"}>Insights and Trends</span>
                  </Link>
                  <Link
                    to={"/cms/admin/content_management/courses"}
                    className={getNavLinkClass(currentRoute === 'content_management.courses')}
                  >
                    <span className={"ps-3"}>Courses</span>
                  </Link>
                  <Link
                    to={"/cms/admin/content_management/trainer_academy"}
                    className={getNavLinkClass(currentRoute === 'content_management.trainer_academy')}
                  >
                    <span className={"ps-3"}>Trainer / Academy</span>
                  </Link>
                  <Link
                    to={"/cms/admin/content_management/association"}
                    className={getNavLinkClass(currentRoute === 'content_management.association')}
                  >
                    <span className={"ps-3"}>Association</span>
                  </Link>
                </div>

                <div className={"mt-auto"}>
                  <Button
                    className={"rounded-0 text-body text-start"}
                    style={{ backgroundColor: "white", borderColor: "white" }}
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("role");
                      localStorage.removeItem("user");
                      if(role === 'Admin') {
                        navigate("/admin");
                      } else {
                        navigate("/login");
                      }
                    }}
                  >
                    Log Out
                  </Button>
                </div>
              </div>
            </Col>
          ) : (
            <></>
          )}
          <div className={"p-5 w-100 cms-container mx-auto col " + (isShowMenu ? "overlay" : "fullScreen")}>{props.children}</div>
        </div>
      </Container>
    </>
  );
}