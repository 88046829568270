import React from "react";

export default function PrivacyPolicyOrganization(props) {
  if(props?.lang === "TC") {
    return (
      <div>
        <p><strong>隱私政策</strong></p>
        <p><strong>更新於2022年5月13日</strong></p>
        <p><strong>1. 引言</strong></p>
        <p><span style={{ fontWeight: 400 }}>本隱私政策（連同我們的條款和條件以及其中提及的任何其他文件）描述您在使用我們的服務（下稱&ldquo;<strong>服務</strong>&rdquo;）或我們的網站（下稱 &ldquo;<strong>網站</strong>&rdquo;）時，由註冊辦公室位於香港北角渣華道191號嘉華中心19樓的香港品質保證局（以下簡稱&ldquo;<strong>HKQAA</strong>&rdquo;或&ldquo;<strong>我們</strong>&rdquo;）收集的您（&ldquo;<strong>您</strong>&rdquo;或&ldquo;<strong>您的</strong>&rdquo;）的信息，我們如何使用或披露該等信息，以及我們為保護該等信息而採取的措施。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們的網站和服務可能包含本隱私政策不涵蓋的第三方網站的鏈接。因此，我們請您查閱其他網站和應用程序的隱私聲明，以了解其對信息的保密實踐。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們已盡可能清晰和簡明地草擬本隱私政策。請仔細閱讀，以了解我們關於您的信息的政策以及我們將如何處理這些信息。您使用或訪問我們的網站或服務，即表示您同意我們依照本隱私政策的規定收集、使用和披露信息。本隱私政策可能會不時更改，您在繼續使用本網站或服務之時，即視為您已經同意該等更改。請您定期查看本隱私政策的更新。如果您不接受或不同意本隱私政策，請立即停止使用我們的網站。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們理解，如果您是來自香港以外的司法管轄區的用戶，可能要求我們提供更多有關數據處理的額外資料。請參閱適用於您的本隱私政策的附錄。我們承認，本隱私政策中未明確提及的某些司法管轄區的法律可能會為您提供有關您個人數據保護的額外權利，我們希望本政策中規定的權利也會為您提供有關您個人數據的此類保護。</span></p>
        <p><span style={{ fontWeight: 400 }}>如果您對本隱私政策有任何問題，請發送電子郵件至<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>或寫信至我們的註冊地址:香港北角渣華道191號嘉華中心19樓的香港品質保證局</span></p>
        <p><strong>2. 我們可能收集的內容</strong></p>
        <p><span style={{ fontWeight: 400 }}>2.1 個人資料或個人信息（統稱為&ldquo;<strong>個人數據</strong>&rdquo;），指可識別的自然人有關的各種信息，不包括身份已被刪除的數據（匿名數據））。</span><span style={{ fontWeight: 400 }}><br /></span></p>
        <p><span style={{ fontWeight: 400 }}>2.2 我們可能會收集、使用、儲存和傳輸有關您的不同種類的個人數據，這些數據可能包括但不限於以下數據:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>個人身份識別數據，包括但不限於您的姓氏、名字、電子郵件地址、信用卡信息和電話號碼； </span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>技術數據，可能包括您用於訪問本網站的設備上的互聯網協議(IP)地址、您的登錄數據、瀏覽器類型和版本、時區設置和位置、瀏覽器插件類型和版本、操作系統和平台等技術；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>配置文件數據，可能包括您的用戶名和密碼、您的預訂信息、您的興趣、偏好、反饋意見和調查結果；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>使用數據，可能包括您如何使用我們的網站和服務的信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>營銷和傳播數據，可能包括您在接收我們和第三方營銷方面的偏好，以及您的傳播偏好。</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>交互數據，可能包括您向本網站任何論壇提供的任何信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>cookies數據；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>我們從其他來源獲得的關於您的第三方信息；以及</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>分析，包括第三方分析服務（如穀歌分析），以評估您使用本網站的情況，編寫活動報告，收集人口統計數據，分析性能指標，並收集和評估有關我們網站和互聯網使用的其他信息。</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>2.3 我們還為任何目的收集、使用和分享匯總數據，例如統計數據或人口統計數據。匯總數據可能來源於您的個人數據但該數據在法律上不被視為個人數據，因為該數據不會直接或間接透露您的身份。例如，我們可能會匯總您的使用數據，以計算用戶訪問特定網站功能的比例。但是，如果我們將匯總數據與您的個人數據合併或連接以致能夠直接或間接識別您的身份，我們會將該合併數據視為本隱私政策項下的個人數據。</span></p>
        <p><span style={{ fontWeight: 400 }}>2.4 我們不會收集關於您的敏感個人數據(包括有關您的種族或民族、宗教或哲學信仰、政治觀點、工會會員、健康、基因和生物特徵數據的信息)。我們也不會收集任何關於刑事定罪和犯罪的信息。</span></p>
        <p><strong>3. 我們如何收集和使用您的數據</strong></p>
        <p><span style={{ fontWeight: 400 }}>3.1 我們將採取合理的步驟，以確保對您個人數據的處理和使用是合法、公平和透明的，不會對您的權利產生不利影響。我們只會在以下任一情況下處理您的個人數據:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>您已同意為一個或多個特定用途而處理您的個人數據；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為履行您作為一方當事人的合同所必需，或者是在簽訂合同前應您的要求所採取的措施；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為遵守我們應遵守的合同或法律義務所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為系統管理和向我們的廣告商報告匯總信息所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為保護您或其他自然人的切身利益所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為履行一項以公共利益為目的的任務所必需的；和/或</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為了我們或第三方的合法利益如我們的財務支付所必需的，但該等利益凌駕於數據主體的基本權利和自由的情況除外，特別是在數據主體是兒童的情況下。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.2 如果我們在向您提供服務的過程中從其他數據主體處接收到您的個人數據，我們希望您負有完全責任確保本隱私政策的內容引起他們的注意，並在此過程中您已取得他們的同意。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們（或代表我們的第三方數據處理商、代理人和分包商）可能通過不同的方法收集、存儲和使用您的個人數據，包括通過您與我們的直接互動收集您的個人數據。您可以通過我們的網站填寫表格，或相應的通過郵寄、電話、電子郵件或其他方式，向我們提供您的信息，包括您在以下情況下提供的個人數據：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>訪問我們的網站；</span></li>
          <li><span style={{ fontWeight: 400 }}>使用我們的任何服務；</span></li>
          <li><span style={{ fontWeight: 400 }}>在我們的網站上創建帳戶；</span></li>
          <li><span style={{ fontWeight: 400 }}>訂閱我們的服務或出版物；</span></li>
          <li><span style={{ fontWeight: 400 }}>要求將營銷信息發送給您；</span></li>
          <li><span style={{ fontWeight: 400 }}>參加競賽、晉升或調查； 或</span></li>
          <li><span style={{ fontWeight: 400 }}>提供給我們一些反饋意見。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.3 除上述情況外，我們可以下列方式使用有關資料:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>確保我們網站的內容有效地呈現給您；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>開展與會員身份有關的服務，如就業匹配；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>使您的用戶體驗個性化，並允許我們提供您最感興趣的內容和產品；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>管理競賽、推廣、調查或其他此類活動。其中一些活動有額外的規則，可能包含關於我們如何使用和披露您個人信息的額外信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>提供您要求的或（在您同意的情況下）我們認為您可能感興趣的信息和服務；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>防止或發現任何盜用或欺詐；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>如果您已選擇接收我們的營銷信息（例如定期通訊），我們基於營銷、廣告和意見調查之目的可能通過電子郵件、短信、電話或任何其他電子手段與您聯繫。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>為了能夠通過您可能感興趣的信息與您聯繫，我們將整合、分析和使用您與我們的的整體互動。我們可能會將您的個人數據用於商業營銷、廣告和意見調查之目的。對您個人數據的使用期限不會超過我們認為必要的時間，但可能是不受限制的。如果您不再希望收到此類營銷信息，請參閱下文內容。如果您沒有選擇接收電子郵件時事通訊，您將不會收到此類電子郵件。註冊或參與網站其他功能（如營銷計劃和“會員專屬”內容)的用戶將可以選擇是否加入我們的電子郵件列表以及接收我們的電子郵件通訊。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.4 我們可能會保留最常用的瀏覽活動及購買歷史記錄，以提供最有幫助的信息，但我們同意對該等信息保密，並且不會從該等信息中識別出您的身份。</span></p>
        <p><span style={{ fontWeight: 400 }}>此外，如果您不希望我們因本政策第3節所述的任何原因使用您的個人數據，您可以隨時通過<a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a>聯繫我們，撤回對我們的直接營銷溝通的同意，我們將從我們的系統中刪除您的數據或將您的數據移至我們的“退訂名單”。然而，您承認這將限制我們向您提供可能最好的服務的能力。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.5 您有權在任何時候通過<a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a>联系我们，撤回对我们的直接营销沟通的同意，我们将从我们的系统中删除您的数据或将您的数据移至我们的“退订名单”。然而，您承认这将限制我们向您提供可能最好的服务的能力。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.6 如上文所述，在您許可和/或法律允許的情況下，我們也可以將您的個人數據用於營銷目的，為此，我們可能通過電子郵件和/或電話聯繫您，向您提供我們服務的信息、新聞和優惠。我們同意，我們不會做本隱私政策項下未經您同意的任何事情，我們也不會向您發送任何未經請求的營銷或垃圾郵件。我們將採取一切合理的步驟，確保我們充分保護您的權利並遵守我們在所適用的數據處理法律下的義務。</span></p>
        <p><strong>4. 網站瀏覽信息數據</strong></p>
        <p><span style={{ fontWeight: 400 }}>4.1 我們使用cookies來區分用戶並改善我們的網站。有關更多Cookies信息，請參閱我們的Cookies政策。</span></p>
        <p><strong>5. 我們存儲您數據的位置以及安全</strong></p>
        <p><span style={{ fontWeight: 400 }}>5.1 為向您提供服務，我們可能會將收集的您的個人數據轉移到您居住的國家以外存儲。這些國家的數據保護和其他法律可能不會像您自己國家那樣全面，如果我們確實在您居住的國家以外存儲或轉移個人數據，我們將採取所有合理的步驟，以確保您的個人數據在符合所適用的法律的情況下得到安全可靠的處理。您接受本隱私政策即表明您同意我們在必要時將個人數據存儲或轉移到我們的海外設施。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.2 數據安全對我們來說非常重要。為保護您個人數據，我們已採取適當有形的、電子的和管理性措施，以防止您個人數據被非法或偶然破壞、意外丟失、未經授權的披露、訪問或其他非法形式的處理。此外，我們有權將訪問您個人數據的人員限制在我們的員工、代理人、承包商和其他有業務需要知道的第三方，他們僅會按照我們的指示處理您的個人數據，並且他們負有保密義務。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.3 我們已經制定了處理任何涉嫌個人數據洩露的程序，並將在法律要求我們這樣做的情況下通知您和任何適用的監管機構。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.4 由於互聯網的開放性，我們不能保證我們所儲存和收集的您個人數據不會受到未經授權的訪問。儘管我們採取了安全措施，但通過互聯網傳送數據可能並非完全安全，因此，我們建議您在通過互聯網傳送數據給我們時採取適當的預防措施。我們不對任何數據或通訊被盜竊、丟失、損壞或未經授權訪問而承擔任何責任。通過使用我們的網站，您承認您理解並同意承擔這些風險。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.5 您在我們的網站註冊時，對於我們給您的密碼，您有責任保管好該密碼，請不要和任何人分享該密碼。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.6 我們根據業務需要和適用的法律要求保存個人數據。我們不會保存您的個人數據超過必要的時間。但是，為了遵守我們所適用的司法管轄區的法律要求，我們可能會保存個人數據較長一段時間。我們將審查我們的數據保存義務，以確保我們不會保存數據超過法律規定的時間。</span></p>
        <p><strong>6. 披露您的信息</strong></p>
        <p><span style={{ fontWeight: 400 }}>6.1 未經您事先同意，我們不會將您個人數據披露給除本隱私政策所述之外的任何第三方。我們可能會與我們的合法附屬實體共享您個人數據。如果我們有法律義務或者我們善意地認為，為了保護他人的財產、安全或權利有必要披露您的個人數據，我們也可以這樣做。我們也可以與他人交換信息，以防範欺詐或信用風險。</span></p>
        <p><span style={{ fontWeight: 400 }}>6.2 我們可以與第三方簽訂合同，由第三方以我們的名義向您提供服務，這些服務可能包括支付處理、搜索引擎設施、廣告和營銷。在某些情況下，第三方可能要求訪問您部分或全部個人數據，第三方有合同義務對此類信息嚴格保密。</span></p>
        <p><span style={{ fontWeight: 400 }}>6.3 若為此目的而需要您個人數據，我們將採取所有合理的步驟，以確保您個人數據將按照您的權利、我們的義務以及所適用的條款項下第三方的義務，安全、穩妥地進行處理。</span></p>
        <p><strong>7. 您的權利</strong></p>
        <p><span style={{ fontWeight: 400 }}>7.1 當您通過我們的網站提交信息時，您可能會被給予限制我們使用您個人數據的選項。我們旨在使您對我們使用您個人數據有強有力的控制，包括選擇不接收我們的電子郵件，您可以通過使用本隱私政策中提供的上述鏈接取消訂閱來做到這一點。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.2 您有權利：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>要求查閱、刪除或更正我們所持有的您個人數據而無需承擔任何費用；</span></li>
          <li><span style={{ fontWeight: 400 }}>要求將您的個人數據提供給另一人（數據遷移）；</span></li>
          <li><span style={{ fontWeight: 400 }}>被告知正在進行的數據處理；</span></li>
          <li><span style={{ fontWeight: 400 }}>限制處理您個人數據； </span></li>
          <li><span style={{ fontWeight: 400 }}>反對處理您個人數據；以及</span></li>
          <li><span style={{ fontWeight: 400 }}>向監管當局投訴。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>7.3 您還享有如下面第10節所述的與自動化決策和分析相關的權利。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.4 您有權要求我們不為營銷目的處理您個人數據。我們會在收集您個人數據前，告知您我們是否打算將您的個人數據用於上述目的，或是否打算將您的個人數據披露給任何第三方。您可以選擇不勾選我們用於收集您個人數據的表格上的某些方格，從而不同意此類處理。您也可以在任何時候通過<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> 或本隱私政策中列出的郵遞地址與我們聯繫，以行使您的權利。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.5 為行使上述任何權利，或如果您對本網站或本隱私政策有任何其他問題，請通過<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>與我們聯繫。</span></p>
        <p><strong>8. 其他網站的鏈接</strong></p>
        <p><span style={{ fontWeight: 400 }}>我們的網站可能包含至第三方網站的廣告或鏈接。我們的網站上出現這些鏈接並不構成我們對相關網站的批准、授權或表示雙方存在隸屬關係。如果您點擊這些鏈接或使用第三方服務，您將離開我們的網站，在共享您個人數據之前，您必須諮詢相關網站的隱私政策。</span></p>
        <p><span style={{ fontWeight: 400 }}>對於我們與第三方合作向您提供的產品和/或服務，您可能需要向第三方提供個人數據，以便註冊和訪問第三方的產品或服務。第三方的身份將在註冊時註明。如果您選擇註冊，您將把您的個人數據傳輸給第三方，第三方對您個人數據的使用將受其隱私政策的約束。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們不對第三方使用您個人數據負責，我們也不能保證第三方擁有與我們相同級別的數據保護政策。</span></p>
        <p><strong>9. 更改</strong></p>
        <p><span style={{ fontWeight: 400 }}>我們可能會不時更改我們的私隱政策。我們未來可能對隱私政策作出的任何更改將在本頁公佈，並在適當和可行的情況下通過電子郵件通知您。您繼續使用我們的網站或服務將被視為您接受隱私政策的更改。</span></p>
        <p><strong>10. 自動決策和分析</strong></p>
        <p><span style={{ fontWeight: 400 }}>10.1 如果我們將個人數據用於自動決策的目的，而這些決策對您具有法律意義（或類似重大影響），您有權根據適用的情況對該等決策提出質疑，要求人工干預，表達自己的觀點，並從我們這裡得到決策說明。</span></p>
        <p><span style={{ fontWeight: 400 }}>10.2 第10.1條所述權利不適用於下列情況:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>該決定是您與我們之間簽訂或履行合同所必需的;</span></li>
          <li><span style={{ fontWeight: 400 }}>該決定是法律授權的; 或</span></li>
          <li><span style={{ fontWeight: 400 }}>你已經明確表示同意。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>10.3 當我們將您個人數據用於分析目的時，應適用以下規定：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>將提供解釋分析的明確信息，包括其重要性和可能的後果；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>將使用適當的數學或統計程序；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>實施必要的技術和組織措施，以盡量減少錯誤的風險，並使這些錯誤易於糾正；以及</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>為資料分析而處理的所有個人數據應得到妥善保管，以防止資料分析所產生的歧視性影響。</span></li>
        </ol>
        <p><strong>11. 條款和條件</strong></p>
        <p><span style={{ fontWeight: 400 }}>為管理我們網站的使用，請您通過</span><a href="http://hkqaa.org/b5_spage.php?id=2" target="_blank" rel="noreferrer"><span style={{ fontWeight: 400 }}>[鏈接]</span></a><span style={{ fontWeight: 400 }}>查看我們的條款和條件，以建立本網站的使用、免責聲明和責任限制。</span></p>
        <p><strong>12. 您的同意</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您繼續使用我們的網站或服務，您將被視為已承認並接受我們的隱私政策。 </span></p>
        <p><strong>13. 獨立性&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果本隱私政策的任何部分或條款被禁止或被視為無效或不可執行，此無效或不可執行只依附於該部分或條款，本隱私政策的其餘條款的效力不受影響。</span></p>
        <p><strong>14. 爭議解決</strong></p>
        <p><span style={{ fontWeight: 400 }}>14.1 因本隱私政策可能產生或涉及的任何爭議，各方將盡最大努力真誠地進行協商和解決。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.2 如果任何此類爭議無法通過友好協商解決，或任何一方或者雙方均不願意通過協商解決，在訴諸訴訟之前，任何一方均可向另一方提出書面建議，即在雙方完全認可的一名調解員的協助下進行協商解決。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.3 與爭議有關的所有協商都將在保密的情況下進行並且不損害雙方在進一步訴訟中的權利。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.4 如果雙方在調解時就爭議的解決達成協議，則該協議應以書面形式訂立，經雙方正式授權的代表簽訂後，該協議應為雙方最終的協議並對雙方均有約束力。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.5 任何爭議均不影響雙方在本隱私政策項下的持續義務。</span></p>
        <p><strong>15. 法律適用</strong></p>
        <p><span style={{ fontWeight: 400 }}>本政策受香港特別行政區(下稱&ldquo;<strong>香港</strong>&rdquo;)法律管轄，並按該法律解釋。因本政策條款和條件而產生的或與之相關的任何糾紛、爭議、差異或索賠，包括本政策的存在、有效性、解釋、履行、違約或終止，或與其有關的非合同義務的爭議均應提交香港國際仲裁中心(HKIAC)，由香港國際仲裁中心根據提交仲裁時其有效的仲裁管理規則做出最終裁決。仲裁地點為香港。仲裁員人數為一名。仲裁程序應以英語進行。</span></p>
        <p><strong>16. 我們的聯繫方式</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您對本隱私政策有任何問題，請使用以下信息與我們聯繫::</span></p>
        <p><span style={{ fontWeight: 400 }}>地址: 香港北角渣華道191號嘉華中心19樓</span></p>
        <p><span style={{ fontWeight: 400 }}>電話號碼:  +852 2202-9111</span></p>
        <p><span style={{ fontWeight: 400 }}>電子郵件地址: <a href="mailto:lightshare@hkqaa.org">lightshare@hkqaa.org</a></span></p>
        <p><strong>附錄</strong></p>
        <p><strong>A. 歐洲經濟區用戶的隱私權&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您在與我們互動期間居住在歐洲經濟區(European Economic Area)，則適用此部分條款。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們遵守《通用數據保護條例》(GDPR)賦予歐盟公民的權利。這些權利如下:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>更正您的信息-更新您的個人信息以確保其是最新和準確的權利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>撤回同意-撤回您對目前正在您同意下進行的任何處理(如營銷)的同意的權利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>獲取您的信息副本-收到我們所持有的關於您個人信息副本的權利； </span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>刪除您的信息-在特定情況下要求我們刪除個人信息的權利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>數據遷移-要求我們將您提供給我們的個人信息轉移給另一控制器的權利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>限制處理-在某些有限情況下請求限制處理您的數據的權利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>反對處理-有權要求我們停止為營銷目的處理您的數據，以及在其他有限情況下，如要求我們不以完全自動化的方式處理您的數據或不為目標內容分析您的信息等(也稱為分析)。 </span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>您的個人數據可能被轉移和/或存儲在歐洲經濟區以外，包括數據保護水平可能低於《通用數據保護條例》的國家。當我們將個人信息從歐洲經濟區向歐洲經濟區以外轉移時，我們將遵守特定的規則。我們將使用《通用數據保護條例》允許的適當保障措施來保護您的個人數據。</span></p>
        <p><span style={{ fontWeight: 400 }}>您可以通過電子郵件地址</span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}>與我們聯繫，以行使上述任何權利。</span></p>
        <p><strong>B. 加州居民隱私權</strong></p>
        <p><span style={{ fontWeight: 400 }}>對於加州居民，CCPA還要求企業提供選擇不出售個人信息這項特定的權利。我們不出售直接識別您身份的個人信息。然而，CCPA對&ldquo;銷售&ldquo;和&ldquo;個人信息&ldquo;的寬泛定義可能將數字分析和廣告生態系統中的共同信息流視為銷售。與大多數公司一樣，我們可能參與數字網絡廣告，這可能構成CCPA下的“銷售”。您可以通過發郵件至<a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a>選擇不出售您的個人信息，您將需要提供您的姓名和您在與我們合作時使用的電子郵件地址。 </span></p>
        <p><span style={{ fontWeight: 400 }}>我們不會在知情的情況下收集13歲以下兒童的個人信息。如果我們獲知13歲以下兒童的個人信息已被收集，我們將採取一切合理的步驟刪除這些信息。如適用法律要求，我們可在向未成年人提供服務或產品前徵求其父母或監護人的同意。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們不會因您行使任何CCPA權利而歧視您。</span></p>
        <p><strong>C. 中國大陸</strong></p>
        <p><span style={{ fontWeight: 400 }}>本節適用於(除一般隱私政策外)處理居住在中華人民共和國內地的數據主體的個人信息。中國大陸居民可能擁有與其個人信息相關的某些權利，而這些權利在其他地區或國家是無法獲得的。 HKQAA尊重每位數據主體的權利，並將始終根據適用的法律保護您的個人信息。</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>個人信息。 </span></span><span style={{ fontWeight: 400 }}> 在本節中，個人信息是指與已識別的或可識別的自然人相關的任何類型的信息(通過電子方式或其他方式記錄)，但不包括經過匿名處理的信息。個人信息可能包括敏感個人信息(定義見下文)。 </span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>同意。 </span></span><span style={{ fontWeight: 400 }}> 我們將按照本隱私政策及相關條款收集、存儲、使用、處理、傳輸、提供、披露或刪除您的個人信息。如果所適用的內地數據保護法律和法規要求我們獲得單獨的同意，我們只會在獲得必要的同意後處理您的個人信息。 </span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>個人敏感信息。 </span></span><span style={{ fontWeight: 400 }}>我們將收集和處理個人敏感信息，例如您的信用卡或借記卡信息、處理您付款的帳戶信息。您在我們網站上的訪問和活動，將通過cookies或其他跟踪技術收集。為了向您提供服務，您有必要向我們提供該等敏感個人信息。如果您不同意，您的用戶體驗和對我們網站的使用可能會受到限制。 </span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>存儲您的個人信息。 </span></span><span style={{ fontWeight: 400 }}> 您的個人信息將儲存在香港以及其他地區或國家。如果適用的中國內地法律要求我們就跨境轉賬向您提供進一步通知並取得您的單獨同意，我們將進一步提供此類通知並獲得您的單獨同意。 </span></p>
        <p><span style={{ fontWeight: 400 }}>您可以通過電子郵件地址</span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}>與我們聯繫，行使上述任何權利。 </span></p>
        <p><strong>Cookie政策</strong></p>
        <p><strong>更新於2022年3月13日</strong></p>
        <p><span style={{ fontWeight: 400 }}>我們的網站使用cookies將您與網站上的其他用戶區分開來，這有助於我們在您瀏覽網站時為您提供更好的體驗，也允許我們改進我們的網站。您可以通過更改web瀏覽器的配置來拒絕存儲cookies，但這樣做可能會影響您對使用我們網站和服務的體驗。</span></p>
        <p><span style={{ fontWeight: 400 }}>一個cookie是一個由字母和數字組成的小文件，如果您同意，我們會將cookies存儲在您的瀏覽器或電腦硬盤上。Cookies包含了被傳輸到您計算機硬盤驅動器上的信息。</span></p>
        <p><span style={{ fontWeight: 400 }}>我們使用以下cookies:</span></p>
        <ul>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>絕對必要cookies</span></span><span style={{ fontWeight: 400 }}>。是我們網站運行所需的cookies，它們包括例如可使您登錄本網站的安全區域、使用購物車或使用電子賬單服務的cookies。 </span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>分析或性能cookies</span></span><span style={{ fontWeight: 400 }}>。這些cookies使我們識別和計算用戶數量，並查看用戶在使用我們網站時的活動。這有助於我們改進網站工作的方式，例如，確保用戶可以很容易地發現要找的內容。 </span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>功能cookies</span></span><span style={{ fontWeight: 400 }}>。這些 Cookie 用於在您返回我們的網站時，識別您的身份，從而使我們能夠為您提供個性化內容、通過稱呼您的名字來問候您、記住您的偏好（例如，您選擇的語言或區域）。 </span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>目標cookies</span></span><span style={{ fontWeight: 400 }}>。這些cookies記錄您在我們網站訪問過的頁面和您所點擊的鏈接。我們將使用這些信息，使我們的網站和網站上的廣告更加迎合您的興趣。為此目的，我們也可能會與第三方分享這些信息。 </span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>請注意，第三方也可能使用我們無法控制的cookies。這些第三方可能包括例如廣告網絡和外部服務提供商，如網絡流量分析服務提供商。</span></p>
      </div>
    );
  } else if(props?.lang === "SC") {
    return (
      <div>
        <p><strong>隐私政策</strong></p>
        <p><strong>更新于2022年5月13日</strong></p>
        <p><strong>1. 引言</strong></p>
        <p><span style={{ fontWeight: 400 }}>本隐私政策（连同我们的条款和条件以及其中提及的任何其他文件）描述您在使用我们的服务（下称&ldquo;<strong>服务</strong>&rdquo;）或我们的网站（下称 &ldquo;<strong>网站</strong>&rdquo;）时，由注册办公室位于香港北角渣华道191号嘉华中心19楼的香港品质保证局（以下简称&ldquo;<strong>HKQAA</strong>&rdquo;或&ldquo;<strong>我们</strong>&rdquo;）收集的您（&ldquo;<strong>您</strong>&rdquo;或&ldquo;<strong>您的</strong>&rdquo;）的信息，我们如何使用或披露该等信息，以及我们为保护该等信息而采取的措施。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们的网站和服务可能包含本隐私政策不涵盖的第三方网站的链接。因此，我们请您查阅其他网站和应用程序的隐私声明，以了解其对信息的保密实践。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们已尽可能清晰和简明地草拟本隐私政策。请仔细阅读，以了解我们关于您的信息的政策以及我们将如何处理这些信息。您使用或访问我们的网站或服务，即表示您同意我们依照本隐私政策的规定收集、使用和披露信息。本隐私政策可能会不时更改，您在继续使用本网站或服务之时，即视为您已经同意该等更改。请您定期查看本隐私政策的更新。如果您不接受或不同意本隐私政策，请立即停止使用我们的网站。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们理解，如果您是来自香港以外的司法管辖区的用户，可能要求我们提供更多有关数据处理的额外资料。请参阅适用于您的本隐私政策的附录。我们承认，本隐私政策中未明确提及的某些司法管辖区的法律可能会为您提供有关您个人数据保护的额外权利，我们希望本政策中规定的权利也会为您提供有关您个人数据的此类保护。</span></p>
        <p><span style={{ fontWeight: 400 }}>如果您对本隐私政策有任何问题，请发送电子邮件至 <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>或写信至我们的注册地址:香港北角渣华道191号嘉华中心19楼的香港品质保证局</span></p>
        <p><strong>2. 我们可能收集的内容</strong></p>
        <p><span style={{ fontWeight: 400 }}>2.1 个人资料或个人信息（统称为&ldquo;<strong>个人数据</strong>&rdquo;），指可识别的自然人有关的各种信息，不包括身份已被删除的数据（匿名数据））。</span><span style={{ fontWeight: 400 }}><br /></span></p>
        <p><span style={{ fontWeight: 400 }}>2.2 我们可能会收集、使用、储存和传输有关您的不同种类的个人数据，这些数据可能包括但不限于以下数据:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>个人身份识别数据，包括但不限于您的姓氏、名字、电子邮件地址、信用卡信息和电话号码； </span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>技术数据，可能包括您用于访问本网站的设备上的互联网协议(IP)地址、您的登录数据、浏览器类型和版本、时区设置和位置、浏览器插件类型和版本、操作系统和平台等技术；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>配置文件数据，可能包括您的用户名和密码、您的预订信息、您的兴趣、偏好、反馈意见和调查结果；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>使用数据，可能包括您如何使用我们的网站和服务的信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>营销和传播数据，可能包括您在接收我们和第三方营销方面的偏好，以及您的传播偏好。</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>交互数据，可能包括您向本网站任何论坛提供的任何信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>cookies数据；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>我们从其他来源获得的关于您的第三方信息；以及</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>分析，包括第三方分析服务（如谷歌分析），以评估您使用本网站的情况，编写活动报告，收集人口统计数据，分析性能指标，并收集和评估有关我们网站和互联网使用的其他信息。</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>2.3 我们还为任何目的收集、使用和分享汇总数据，例如统计数据或人口统计数据。汇总数据可能来源于您的个人数据但该数据在法律上不被视为个人数据，因为该数据不会直接或间接透露您的身份。例如，我们可能会汇总您的使用数据，以计算用户访问特定网站功能的比例。但是，如果我们将汇总数据与您的个人数据合并或连接以致能够直接或间接识别您的身份，我们会将该合并数据视为本隐私政策项下的个人数据。</span></p>
        <p><span style={{ fontWeight: 400 }}>2.4 我们不会收集关于您的敏感个人数据(包括有关您的种族或民族、宗教或哲学信仰、政治观点、工会会员、健康、基因和生物特征数据的信息)。我们也不会收集任何关于刑事定罪和犯罪的信息。</span></p>
        <p><strong>3. 我们如何收集和使用您的数据</strong></p>
        <p><span style={{ fontWeight: 400 }}>3.1 我们将采取合理的步骤，以确保对您个人数据的处理和使用是合法、公平和透明的，不会对您的权利产生不利影响。我们只会在以下任一情况下处理您的个人数据:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>您已同意为一个或多个特定用途而处理您的个人数据；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为履行您作为一方当事人的合同所必需，或者是在签订合同前应您的要求所采取的措施；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为遵守我们应遵守的合同或法律义务所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为系统管理和向我们的广告商报告汇总信息所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为保护您或其他自然人的切身利益所必需的；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为履行一项以公共利益为目的的任务所必需的；和/或</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为了我们或第三方的合法利益如我们的财务支付所必需的，但该等利益凌驾于数据主体的基本权利和自由的情况除外，特别是在数据主体是儿童的情况下。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.2 如果我们在向您提供服务的过程中从其他数据主体处接收到您的个人数据，我们希望您负有完全责任确保本隐私政策的内容引起他们的注意，并在此过程中您已取得他们的同意。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们（或代表我们的第三方数据处理商、代理人和分包商）可能通过不同的方法收集、存储和使用您的个人数据，包括通过您与我们的直接互动收集您的个人数据。您可以通过我们的网站填写表格，或相应的通过邮寄、电话、电子邮件或其他方式，向我们提供您的信息，包括您在以下情况下提供的个人数据：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>访问我们的网站；</span></li>
          <li><span style={{ fontWeight: 400 }}>使用我们的任何服务；</span></li>
          <li><span style={{ fontWeight: 400 }}>在我们的网站上创建帐户；</span></li>
          <li><span style={{ fontWeight: 400 }}>订阅我们的服务或出版物；</span></li>
          <li><span style={{ fontWeight: 400 }}>要求将营销信息发送给您；</span></li>
          <li><span style={{ fontWeight: 400 }}>参加竞赛、晋升或调查； 或</span></li>
          <li><span style={{ fontWeight: 400 }}>提供给我们一些反馈意见。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.3 除上述情况外，我们可以下列方式使用有关资料:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>确保我们网站的内容有效地呈现给您；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>开展与会员身份有关的服务，如就业匹配；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>使您的用户体验个性化，并允许我们提供您最感兴趣的内容和产品；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>管理竞赛、推广、调查或其他此类活动。其中一些活动有额外的规则，可能包含关于我们如何使用和披露您个人信息的额外信息；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>提供您要求的或（在您同意的情况下）我们认为您可能感兴趣的信息和服务；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>防止或发现任何盗用或欺诈；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>如果您已选择接收我们的营销信息（例如定期通讯），我们基于营销、广告和意见调查之目的可能通过电子邮件、短信、电话或任何其他电子手段与您联系。 </span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>为了能够通过您可能感兴趣的信息与您联系，我们将整合、分析和使用您与我们的的整体互动。我们可能会将您的个人数据用于商业营销、广告和意见调查之目的。对您个人数据的使用期限不会超过我们认为必要的时间，但可能是不受限制的。如果您不再希望收到此类营销信息，请参阅下文内容。如果您没有选择接收电子邮件时事通讯，您将不会收到此类电子邮件。注册或参与网站其他功能（如营销计划和“会员专属”内容）的用户将可以选择是否加入我们的电子邮件列表以及接收我们的电子邮件通讯。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.4 我们可能会保留最常用的浏览活动及购买历史记录，以提供最有帮助的信息，但我们同意对该等信息保密，并且不会从该等信息中识别出您的身份。</span></p>
        <p><span style={{ fontWeight: 400 }}>此外，如果您不希望我们因本政策第3节所述的任何原因使用您的个人数据，您可以随时通过<a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a>联系并告知我们，我们将从我们的系统中删除您的数据。然而，您承认这将限制我们向您提供可能最好的产品和服务的能力。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.5 您有权在任何时候通过[插入电子邮件地址]联系我们，撤回对我们的直接营销沟通的同意，我们将从我们的系统中删除您的数据或将您的数据移至我们的“退订名单”。然而，您承认这将限制我们向您提供可能最好的服务的能力。</span></p>
        <p><span style={{ fontWeight: 400 }}>3.6 如上文所述，在您许可和/或法律允许的情况下，我们也可以将您的个人数据用于营销目的，为此，我们可能通过电子邮件和/或电话联系您，向您提供我们服务的信息、新闻和优惠。我们同意，我们不会做本隐私政策项下未经您同意的任何事情，我们也不会向您发送任何未经请求的营销或垃圾邮件。我们将采取一切合理的步骤，确保我们充分保护您的权利并遵守我们在所适用的数据处理法律下的义务。</span></p>
        <p><strong>4. 网站浏览信息数据</strong></p>
        <p><span style={{ fontWeight: 400 }}>4.1 我们使用cookies来区分用户并改善我们的网站。有关更多Cookies信息，请参阅我们的Cookies政策。</span></p>
        <p><strong>5. 我们存储您数据的位置以及安全</strong></p>
        <p><span style={{ fontWeight: 400 }}>5.1 为向您提供服务，我们可能会将收集的您的个人数据转移到您居住的国家以外存储。这些国家的数据保护和其他法律可能不会像您自己国家那样全面，如果我们确实在您居住的国家以外存储或转移个人数据，我们将采取所有合理的步骤，以确保您的个人数据在符合所适用的法律的情况下得到安全可靠的处理。您接受本隐私政策即表明您同意我们在必要时将个人数据存储或转移到我们的海外设施。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.2 数据安全对我们来说非常重要。为保护您个人数据，我们已采取适当有形的、电子的和管理性措施，以防止您个人数据被非法或偶然破坏、意外丢失、未经授权的披露、访问或其他非法形式的处理。此外，我们有权将访问您个人数据的人员限制在我们的员工、代理人、承包商和其他有业务需要知道的第三方，他们仅会按照我们的指示处理您的个人数据，并且他们负有保密义务。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.3 我们已经制定了处理任何涉嫌个人数据泄露的程序，并将在法律要求我们这样做的情况下通知您和任何适用的监管机构。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.4 由于互联网的开放性，我们不能保证我们所储存和收集的您个人数据不会受到未经授权的访问。尽管我们采取了安全措施，但通过互联网传送数据可能并非完全安全，因此，我们建议您在通过互联网传送数据给我们时采取适当的预防措施。我们不对任何数据或通讯被盗窃、丢失、损坏或未经授权访问而承担任何责任。通过使用我们的网站，您承认您理解并同意承担这些风险。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.5 您在我们的网站注册时，对于我们给您的密码，您有责任保管好该密码，请不要和任何人分享该密码。</span></p>
        <p><span style={{ fontWeight: 400 }}>5.6 我们根据业务需要和适用的法律要求保存个人数据。我们不会保存您的个人数据超过必要的时间。但是，为了遵守我们所适用的司法管辖区的法律要求，我们可能会保存个人数据较长一段时间。我们将审查我们的数据保存义务，以确保我们不会保存数据超过法律规定的时间。</span></p>
        <p><strong>6. 披露您的信息</strong></p>
        <p><span style={{ fontWeight: 400 }}>6.1 未经您事先同意，我们不会将您个人数据披露给除本隐私政策所述之外的任何第三方。我们可能会与我们的合法附属实体共享您个人数据。如果我们有法律义务或者我们善意地认为，为了保护他人的财产、安全或权利有必要披露您的个人数据，我们也可以这样做。我们也可以与他人交换信息，以防范欺诈或信用风险。</span></p>
        <p><span style={{ fontWeight: 400 }}>6.2 我们可以与第三方签订合同，由第三方以我们的名义向您提供服务，这些服务可能包括支付处理、搜索引擎设施、广告和营销。在某些情况下，第三方可能要求访问您部分或全部个人数据，第三方有合同义务对此类信息严格保密。</span></p>
        <p><span style={{ fontWeight: 400 }}>6.3 若为此目的而需要您个人数据，我们将采取所有合理的步骤，以确保您个人数据将按照您的权利、我们的义务以及所适用的条款项下第三方的义务，安全、稳妥地进行处理。</span></p>
        <p><strong>7. 您的权利</strong></p>
        <p><span style={{ fontWeight: 400 }}>7.1 通过我们的网站提交信息时，您可能会被给予限制我们使用您个人数据的选项。我们旨在使您对我们使用您个人数据有强有力的控制，包括选择不接收我们的电子邮件，您可以通过使用本隐私政策中提供的上述链接取消订阅来做到这一点。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.2 您有权利：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>要求查阅、删除或更正我们所持有的您个人数据而无需承担任何费用；</span></li>
          <li><span style={{ fontWeight: 400 }}>要求将您的个人数据提供给另一人（数据迁移）；</span></li>
          <li><span style={{ fontWeight: 400 }}>被告知正在进行的数据处理；</span></li>
          <li><span style={{ fontWeight: 400 }}>限制处理您个人数据；</span></li>
          <li><span style={{ fontWeight: 400 }}>反对处理您个人数据；以及</span></li>
          <li><span style={{ fontWeight: 400 }}>向监管当局投诉。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>7.3 您还享有如下面第10节所述的与自动化决策和分析相关的权利。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.4 您有权要求我们不为营销目的处理您个人数据。我们会在收集您个人数据前，告知您我们是否打算将您的个人数据用于上述目的，或是否打算将您的个人数据披露给任何第三方。您可以选择不勾选我们用于收集您个人数据的表格上的某些方格，从而不同意此类处理。 您也可以在任何时候通过<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> 或本隐私政策中列出的邮递地址与我们联系，以行使您的权利。</span></p>
        <p><span style={{ fontWeight: 400 }}>7.5 为行使上述任何权利，或如果您对本网站或本隐私政策有任何其他问题，请通过<a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>与我们联系。</span></p>
        <p><strong>8. 其他网站的链接</strong></p>
        <p><span style={{ fontWeight: 400 }}>我们的网站可能包含至第三方网站的广告或链接。我们的网站上出现这些链接并不构成我们对相关网站的批准、授权或表示双方存在隶属关系。如果您点击这些链接或使用第三方服务，您将离开我们的网站，在共享您个人数据之前，您必须咨询相关网站的隐私政策。</span></p>
        <p><span style={{ fontWeight: 400 }}>对于我们与第三方合作向您提供的产品和/或服务，您可能需要向第三方提供个人数据，以便注册和访问第三方的产品或服务。第三方的身份将在注册时注明。如果您选择注册，您将把您的个人数据传输给第三方，第三方对您个人数据的使用将受其隐私政策的约束。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们不对第三方使用您个人数据负责，我们也不能保证第三方拥有与我们相同级别的数据保护政策。</span></p>
        <p><strong>9. 更改</strong></p>
        <p><span style={{ fontWeight: 400 }}>我们可能会不时更改我们的私隐政策。我们未来可能对隐私政策作出的任何更改将在本页公布，并在适当和可行的情况下通过电子邮件通知您。您继续使用我们的网站或服务将被视为您接受隐私政策的更改。</span></p>
        <p><strong>10. 自动决策和分析</strong></p>
        <p><span style={{ fontWeight: 400 }}>10.1 如果我们将个人数据用于自动决策的目的，而这些决策对您具有法律意义（或类似重大影响），您有权根据适用的情况对该等决策提出质疑，要求人工干预，表达自己的观点， 并从我们这里得到决策说明。</span></p>
        <p><span style={{ fontWeight: 400 }}>10.2 第10.1条所述权利不适用于下列情况:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>该决定是您与我们之间签订或履行合同所必需的;</span></li>
          <li><span style={{ fontWeight: 400 }}>该决定是法律授权的; 或</span></li>
          <li><span style={{ fontWeight: 400 }}>你已经明确表示同意。</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>10.3 当我们将您个人数据用于分析目的时，应适用以下规定：</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>将提供解释分析的明确信息，包括其重要性和可能的后果；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>将使用适当的数学或统计程序；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>应实施必要的技术和组织措施，以尽量减少错误的风险，并使这些错误易于纠正；以及</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>为资料分析而处理的所有个人数据应得到妥善保管，以防止资料分析所产生的歧视性影响。</span></li>
        </ol>
        <p><strong>11. 条款和条件</strong></p>
        <p><span style={{ fontWeight: 400 }}>为管理我们网站的使用，请您通过</span><a href="http://hkqaa.org/gb_spage.php?id=2" target="_blank" rel="noreferrer"><span style={{ fontWeight: 400 }}>[鏈接]</span></a><span style={{ fontWeight: 400 }}>查看我们的条款和条件，以建立本网站的使用、免责声明和责任限制。</span></p>
        <p><strong>12. 您的同意</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您继续使用我们的网站或服务，您将被视为已承认并接受我们的隐私政策。 </span></p>
        <p><strong>13. 独立性&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果本隐私政策的任何部分或条款被禁止或被视为无效或不可执行，此无效或不可执行只依附于该部分或条款，本隐私政策的其余条款的效力不受影响。</span></p>
        <p><strong>14. 争议解决</strong></p>
        <p><span style={{ fontWeight: 400 }}>14.1 因本隐私政策可能产生或涉及的任何争议，各方将尽最大努力真诚地进行协商和解决。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.2 如果任何此类争议无法通过友好协商解决，或任何一方或者双方均不愿意通过协商解决，在诉诸诉讼之前，任何一方均可向另一方提出书面建议，即在双方完全认可的一名调解员的协助下进行协商解决。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.3 与争议有关的所有协商都将在保密的情况下进行并且不损害双方在进一步诉讼中的权利。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.4 如果双方在调解时就争议的解决达成协议，则该协议应以书面形式订立，经双方正式授权的代表签订后，该协议应为双方最终的协议并对双方均有约束力。</span></p>
        <p><span style={{ fontWeight: 400 }}>14.5 任何争议均不影响双方在本隐私政策项下的持续义务。</span></p>
        <p><strong>15. 法律适用</strong></p>
        <p><span style={{ fontWeight: 400 }}>本政策受香港特别行政区(下称&ldquo;<strong>香港</strong>&rdquo;)法律管辖，并按该法律解释。 因本政策条款和条件而产生的或与之相关的任何纠纷、争议、差异或索赔，包括本政策的存在、有效性、解释、履行、违约或终止，或与其有关的非合同义务的争议均应提交香港国际仲裁中心(HKIAC)，由香港国际仲裁中心根据提交仲裁时其有效的仲裁管理规则做出最终裁决。仲裁地点为香港。仲裁员人数为一名。仲裁程序应以英语进行。</span></p>
        <p><strong>16. 我们的联系方式</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您对本隐私政策有任何问题，请使用以下信息与我们联系:</span></p>
        <p><span style={{ fontWeight: 400 }}>地址: 香港北角渣华道191号嘉华中心19楼</span></p>
        <p><span style={{ fontWeight: 400 }}>电话号码:  +852 2202-9111</span></p>
        <p><span style={{ fontWeight: 400 }}>电子邮件地址: <a href="mailto:lightshare@hkqaa.org">lightshare@hkqaa.org</a></span></p>
        <p><strong>附录</strong></p>
        <p><strong>A. 欧洲经济区用户的隐私权&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>如果您在与我们互动期间居住在欧洲经济区(European Economic Area)，则适用此部分条款。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们遵守《通用数据保护条例》(GDPR)赋予欧盟公民的权利。这些权利如下:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>更正您的信息-更新您的个人信息以确保其是最新和准确的权利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>撤回同意-撤回您对目前正在您同意下进行的任何处理(如营销)的同意的权利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>获取您的信息副本-收到我们所持有的关于您个人信息副本的权利； </span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>删除您的信息-在特定情况下要求我们删除个人信息的权利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>数据迁移-要求我们将您提供给我们的个人信息转移给另一控制器的权利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>限制处理-在某些有限情况下请求限制处理您的数据的权利；</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>反对处理-有权要求我们停止为营销目的处理您的数据，以及在其他有限情况下，如要求我们不以完全自动化的方式处理您的数据或不为目标内容分析您的信息等(也称为分析)。</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>您的个人数据可能被转移和/或存储在欧洲经济区以外，包括数据保护水平可能低于《通用数据保护条例》的国家。当我们将个人信息从欧洲经济区向欧洲经济区以外转移时，我们将遵守特定的规则。我们将使用《通用数据保护条例》允许的适当保障措施来保护您的个人数据。</span></p>
        <p><span style={{ fontWeight: 400 }}>您可以通过电子邮件地址</span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}>与我们联系，以行使上述任何权利。</span></p>
        <p><strong>B. 加州居民隐私权</strong></p>
        <p><span style={{ fontWeight: 400 }}>对于加州居民，CCPA还要求企业提供选择不出售个人信息这项特定的权利。我们不出售直接识别您身份的个人信息。然而，CCPA对&ldquo;销售&ldquo;和&ldquo;个人信息&ldquo;的宽泛定义可能将数字分析和广告生态系统中的共同信息流视为销售。与大多数公司一样，我们可能参与数字网络广告，这可能构成CCPA下的“销售”。您可以通过发邮件至<a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a>选择不出售您的个人信息，您将需要提供您的姓名和您在与我们合作时使用的电子邮件地址。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们不会在知情的情况下收集13岁以下儿童的个人信息。如果我们获知13岁以下儿童的个人信息已被收集，我们将采取一切合理的步骤删除这些信息。如适用法律要求，我们可在向未成年人提供服务或产品前征求其父母或监护人的同意。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们不会因您行使任何CCPA权利而歧视您。</span></p>
        <p><strong>C. 中国大陆</strong></p>
        <p><span style={{ fontWeight: 400 }}>本节适用于(除一般隐私政策外)处理居住在中华人民共和国内地的数据主体的个人信息。中国大陆居民可能拥有与其个人信息相关的某些权利，而这些权利在其他地区或国家是无法获得的。HKQAA尊重每位数据主体的权利，并将始终根据适用的法律保护您的个人信息。</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>个人信息。</span></span><span style={{ fontWeight: 400 }}> 在本节中，个人信息是指与已识别的或可识别的自然人相关的任何类型的信息(通过电子方式或其他方式记录)，但不包括经过匿名处理的信息。个人信息可能包括敏感个人信息(定义见下文)。</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>同意。</span></span><span style={{ fontWeight: 400 }}> 我们将按照本隐私政策及相关条款收集、存储、使用、处理、传输、提供、披露或删除您的个人信息。如果所适用的内地数据保护法律和法规要求我们获得单独的同意，我们只会在获得必要的同意后处理您的个人信息。</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>个人敏感信息。</span></span><span style={{ fontWeight: 400 }}>我们将收集和处理个人敏感信息，例如您的信用卡或借记卡信息、处理您付款的帐户信息。您在我们网站上的访问和活动，将通过cookies或其他跟踪技术收集。 为了向您提供服务，您有必要向我们提供该等敏感个人信息。如果您不同意，您的用户体验和对我们网站的使用可能会受到限制。</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>存储您的个人信息。</span></span><span style={{ fontWeight: 400 }}> 您的个人信息将储存在香港以及其他地区或国家。如果适用的中国内地法律要求我们就跨境转账向您提供进一步通知并取得您的单独同意，我们将进一步提供此类通知并获得您的单独同意。</span></p>
        <p><span style={{ fontWeight: 400 }}>您可以通过电子邮件地址</span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}>与我们联系，行使上述任何权利。</span></p>
        <p><strong>Cookie政策</strong></p>
        <p><strong>更新于2022年3月13日</strong></p>
        <p><span style={{ fontWeight: 400 }}>我们的网站使用cookies将您与网站上的其他用户区分开来，这有助于我们在您浏览网站时为您提供更好的体验，也允许我们改进我们的网站。您可以通过更改web浏览器的配置来拒绝存储cookies，但这样做可能会影响您对使用我们网站和服务的体验。</span></p>
        <p><span style={{ fontWeight: 400 }}>一个cookie是一个由字母和数字组成的小文件，如果您同意，我们会将cookies存储在您的浏览器或电脑硬盘上。Cookies包含了被传输到您计算机硬盘驱动器上的信息。</span></p>
        <p><span style={{ fontWeight: 400 }}>我们使用以下cookies:</span></p>
        <ul>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>绝对必要cookies</span></span><span style={{ fontWeight: 400 }}>。 是我们网站运行所需的cookies，它们包括例如可使您登录本网站的安全区域、使用购物车或使用电子账单服务的cookies。</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>分析或性能cookies</span></span><span style={{ fontWeight: 400 }}>。 这些cookies使我们识别和计算用户数量，并查看用户在使用我们网站时的活动。这有助于我们改进网站工作的方式，例如，确保用户可以很容易地发现要找的内容。</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>功能cookies</span></span><span style={{ fontWeight: 400 }}>。 这些 Cookie 用于在您返回我们的网站时，识别您的身份，从而使我们能够为您提供个性化内容、通过称呼您的名字来问候您、记住您的偏好（例如，您选择的语言或区域）。</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>目标cookies</span></span><span style={{ fontWeight: 400 }}>。 这些cookies记录您在我们网站访问过的页面和您所点击的链接。我们将使用这些信息，使我们的网站和网站上的广告更加迎合您的兴趣。为此目的，我们也可能会与第三方分享这些信息。</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>请注意，第三方也可能使用我们无法控制的cookies。 这些第三方可能包括例如广告网络和外部服务提供商，如网络流量分析服务提供商。</span></p>
      </div>
    );
  } else {
    return (
      <div>
        <p><strong>Privacy Policy</strong></p>
        <p><strong>Last Updated:13 May 2022</strong></p>
        <p><strong>1. Introduction</strong></p>
        <p><span style={{ fontWeight: 400 }}>This Privacy Policy (together with our terms and conditions and any other documents referred to in it) describes the type of information that the Hong Kong Quality Assurance Agency (hereinafter referred as &ldquo;<strong>HKQAA</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;) , whose registered office is at 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong, collect from you (&ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>your</strong>&rdquo;) through the use of our services (&ldquo;<strong>Services</strong>&rdquo;), or the use of our website (&ldquo;<strong>Website</strong>&rdquo;), how that information may be used or disclosed by us and the safeguards we use to protect it.</span></p>
        <p><span style={{ fontWeight: 400 }}>Our Website and Services may contain links to third party websites that are not covered by this Privacy Policy. We therefore ask you to review the privacy statements of other websites and applications to understand their information practices.</span></p>
        <p><span style={{ fontWeight: 400 }}>We have drafted this Privacy Policy to be as clear and concise as possible. Please read it carefully to understand our policies regarding your information and how we will treat it. By using or accessing our Website or the Services, you agree to the collection, use and disclosure of information in accordance with this Privacy Policy. This Privacy Policy may be changed from time to time and your continued use of the Website or the Services is deemed to be acceptance of such changes. Please check regularly for updates to this Privacy Policy. If you do not accept and do not agree with this Privacy Policy, you must stop using our Website immediately.</span></p>
        <p><span style={{ fontWeight: 400 }}>We understand that some of our users are from jurisdictions other than Hong Kong and may require us to provide additional information to you about our data processing activities. Please refer to the appendices to this Privacy Policy that applies to you. We acknowledge that laws in certain jurisdiction not expressly mentioned in this Privacy Policy may afford additional rights to you in respect of your personal data. We anticipate that the rights set out in this Policy also provide you with such protection in relation to your personal data.</span></p>
        <p><span style={{ fontWeight: 400 }}>If you have any questions on this Privacy Policy, please email them to <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> or write to us at our registered address: 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong.</span></p>
        <p><strong>2. What we may collect</strong></p>
        <p><span style={{ fontWeight: 400 }}>2.1 Personal data, or personal information, (collectively referred as &ldquo;<strong>Personal Data</strong>&rdquo;) means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</span><span style={{ fontWeight: 400 }}><br /></span></p>
        <p><span style={{ fontWeight: 400 }}>2.2 We may collect, use, store and transfer different kinds of Personal Data about you which may include but not limited to the following:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>personal data that identifies you as an individual, including but not limited to your first name, last name, email address, credit card information, and phone number;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>technical data, which may include internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this Website;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>profile data, which may include your username and password, reservations made by you, your interests, preferences, feedback, and survey responses;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>usage data, which may include information about how you use our Website and Services.</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>marketing and communications data, which may include your preferences in receiving marketing from us and our third parties and your communication preferences.</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>interaction data, which may include any information that you might provide to any discussion forums on the Website;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>cookies data;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>third parties information which we receive about you from other sources; and&nbsp;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>analytics including third-party analytics services (such as Google Analytics) to evaluate your use of the Website, compile reports on activity, collect demographic data, analyse performance metrics, and collect and evaluate other information relating to our Website and internet usage.</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>2.3 We also collect, use, and share aggregated data such as statistical or demographic data for any purpose. Aggregated data may be derived from your Personal Data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your usage data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect aggregated data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.</span></p>
        <p><span style={{ fontWeight: 400 }}>2.4 We do not collect any sensitive Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</span></p>
        <p><strong>3. How we may collect and use your data</strong></p>
        <p><span style={{ fontWeight: 400 }}>3.1 We will take reasonable steps to ensure that your Personal Data is processed and used lawfully, fairly, and transparently, without adversely affecting your rights. We will only process your Personal Data if at least one of the following basis applies:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>you have given consent to the processing of your Personal Data for one or more specific purposes;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary for the performance of a contract to which you are a party or in order to take steps at the request of you prior to entering into a contract;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary for compliance with a contractual or legal obligation to which we are subject;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary for system administration and to report aggregate information to our advertisers;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary to protect the vital interests of you or of another natural person;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary for the performance of a task carried out in the public interest; and/or</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>processing is necessary for the purposes of the legitimate interests pursued by us or by a third party such as our financial payments, except where such interests are overridden by the fundamental rights and freedoms of the data subject, in particular where the data subject is a child.</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.2 If we receive Personal Data in the course of providing our Services to you from another data subject, we expect you to have complete responsibility for ensuring that the contents of this Privacy Policy are brought to their attention and you have obtained their consent in the process.</span></p>
        <p><span style={{ fontWeight: 400 }}>We (or third party data processors, agents and sub-contractors acting on our behalf) may collect, store and use your Personal Data by way of different methods to collect data from and about you including through direct interactions with us. You may give us your information by filling in forms via our Website or by corresponding with us by post, phone, email or otherwise. This includes Personal Data you provide when you:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>visit our Website;</span></li>
          <li><span style={{ fontWeight: 400 }}>use any of our Services;</span></li>
          <li><span style={{ fontWeight: 400 }}>create an account on our Website;</span></li>
          <li><span style={{ fontWeight: 400 }}>subscribe to our Services or publications;</span></li>
          <li><span style={{ fontWeight: 400 }}>request marketing information to be sent to you;</span></li>
          <li><span style={{ fontWeight: 400 }}>enter a competition, promotion, or survey; oror</span></li>
          <li><span style={{ fontWeight: 400 }}>provide us with some feedback.</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>3.3 In addition to the above, we may use the information in the following ways:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>to ensure that content from our Website is presented effectively to you;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>to carry out Services relating to your membership such as job-matching;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>to personalise your user experience and to allow us to deliver the type of content and product offerings in which you are most interested;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>to administer a contest, promotion, survey or other such activities. Some of these activities have additional rules, which could contain additional information about how we use and disclose your personal information;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>provide information, and services that you request, or (with your consent) which we think may interest you;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>prevent or detect any misuse or fraud;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>if you have opted-in to receive our marketing information (e.g. periodic newsletters), we may contact you via e-mails, SMS, telephone or any other electronic means for marketing, advertising, and opinion research purposes.</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>In order to be able to contact you with information which may be of interest to you, your overall interaction us will be combined, analysed and used. We may use your Personal Data for marketing, advertising, and opinion research purposes. The period of use of the Personal Data will not be longer than we deem necessary but may be unlimited. If you would no longer like to receive such marketing information from us, please refer to the  section below. If you have not opted-in to receive e-mail newsletters, you will not receive these e-mails. Users who register or participate in other website features such as marketing programs and ‘members-only’ content will be given a choice whether they would like to be on our e-mail list and receive e-mail communications from us.</span></p>
        <p><span style={{ fontWeight: 400 }}>3.4 We may keep a record of browsing activity and purchase history which are used the most to enable us to provide the most helpful information but we agree to keep such information confidential and you will not be identified from this information.</span></p>
        <p><span style={{ fontWeight: 400 }}>In addition, if you don&rsquo;t want us to use your Personal Data for any of the other reasons set out in this section 3, you can let us know at any time by contacting us at <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> , and we will delete your data from our systems. However, you acknowledge this will limit our ability to provide the best possible products and services to you.</span></p>
        <p><span style={{ fontWeight: 400 }}>3.5 You have the right to withdraw consent to our direct marketing communication at any time by contacting us at <a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a> and we will either delete your data from our systems or move your data to our &ldquo;unsubscribe list&rdquo;. However, you acknowledge this will limit our ability to provide the best possible services to you.</span></p>
        <p><span style={{ fontWeight: 400 }}>3.6 As already indicated above, with your permission and/or where permitted by law, we may also use your Personal Data for marketing purposes which may lead to us contacting you by email and/or telephone with information, news and offers on our Services. We agree that we will not do anything that we have not agreed to under this Privacy Policy, and we will not send you any unsolicited marketing or spam. We will take all reasonable steps to ensure that we fully protect your rights and comply with our obligations under the applicable data processing law.</span></p>
        <p><strong>4. Cookies</strong></p>
        <p><span style={{ fontWeight: 400 }}>4.1 We use cookies to distinguish users and to improve our Website. Please read our Cookies Policy for more cookies information.</span></p>
        <p><strong>5. Where we store your data and security</strong></p>
        <p><span style={{ fontWeight: 400 }}>5.1 To provide Services to you, we may transfer your collected Personal Data to storage outside of the country where you live. The data protection and other laws of these countries may not be as comprehensive as those in your own country, If we do store or transfer Personal Data outside of the country where you live, we will take all reasonable steps to ensure that your Personal Data is treated safely and securely in compliance with the applicable laws. Your acceptance of this Privacy Policy shall be your consent permitting us to store or transfer Personal Data to our overseas facilities if it is necessary for us to do so.</span></p>
        <p><span style={{ fontWeight: 400 }}>Data security is of great importance to us. To protect your Personal Data, we have put in place suitable physical, electronic, and managerial measures to prevent unlawful or accidental destruction, accidental loss, unauthorized disclosure or access or other unlawful forms of processing. In addition, we limit access to your Personal Data to employees, agents, contractors and other third parties who have a business need to know. They will only process your Personal Data on our instructions and they are subject to a duty of confidentiality.</span></p>
        <p><span style={{ fontWeight: 400 }}>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</span></p>
        <p><span style={{ fontWeight: 400 }}>Due to the open nature of the internet, it is impossible to guarantee that any of your Personal Data stored and collected will be free from unauthorized access. Notwithstanding the security measures that we take, the transmission of data via the internet may not be completely secure and we advise you to take suitable precautions when transmitting to us data via the internet. We disclaim any liability for any theft, loss of, damage or unauthorized access of any data or communications. By using our Website, you acknowledge that you understand and agree to assume these risks.</span></p>
        <p><span style={{ fontWeight: 400 }}>If we give you a password upon registration on our Website, you are solely responsible for keeping this password confidential. Please do not share it with anyone.</span></p>
        <p><span style={{ fontWeight: 400 }}>We retain Personal Data based on the business needs and the applicable statutory requirements. We will not keep your Personal Data longer than it is necessary. We may however be required to retain Personal Data for a longer period of time to ensure we comply with the laws in the applicable jurisdiction. We will review our data retention obligations to ensure we are not retaining data for longer than we are legally obliged to.</span></p>
        <p><strong>6. Disclosing your information</strong></p>
        <p><span style={{ fontWeight: 400 }}>We will not disclose your Personal Data to any third parties aside from what is stated in this Privacy Policy without your prior consent. We may share your Personal Data with our legally affiliated entities. We may also disclose it if we have a legal obligation to do so, or in good faith believe that such action is necessary in order to protect other people&rsquo;s property, safety or rights. We may also exchange information with others to protect against fraud or credit risks.</span></p>
        <p><span style={{ fontWeight: 400 }}>We may contract with third parties to supply services to you on our behalf. These may include payment processing, search engine facilities, advertising, and marketing. In some cases, the third parties may require access to some or all of your Personal Data. Such third parties will be contractually obliged to treat such information in the strictest confidence. </span></p>
        <p><span style={{ fontWeight: 400 }}>Where any of your Personal Data is required for such a purpose, we will take all reasonable steps to ensure that your Personal Data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under the applicable.</span></p>
        <p><strong>7. Your rights</strong></p>
        <p><span style={{ fontWeight: 400 }}>7.1 When you submit information via our Website, you may be given options to restrict our use of your Personal Data. We aim to give you strong controls on our use of your Personal Data (including the ability to opt-out of receiving emails from us which you may do so by unsubscribing using the links provided above in this Privacy Policy).</span></p>
        <p><span style={{ fontWeight: 400 }}>7.2 You have the right to:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>request access to, deletion or correction of, your Personal Data held by us at no cost to you;</span></li>
          <li><span style={{ fontWeight: 400 }}>request that your Personal Data be transferred to another person (data portability);;</span></li>
          <li><span style={{ fontWeight: 400 }}>be informed of what data processing is taking place;</span></li>
          <li><span style={{ fontWeight: 400 }}>restrict processing;</span></li>
          <li><span style={{ fontWeight: 400 }}>to object to processing of your Personal Data; and</span></li>
          <li><span style={{ fontWeight: 400 }}>complain to a supervisory authority.</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>7.3 You also have rights with respect to automated decision-making and profiling as set out in section 10 below.</span></p>
        <p><span style={{ fontWeight: 400 }}>7.4 You have the right to ask us not to process your Personal Data for marketing purposes. We will inform you before collecting your Personal Data if we intend to use your Personal Data for such purposes or if we intend to disclose your Personal Data to any third party for such purposes. You may choose not to provide your consent to such processing by not checking certain boxes on the forms we use to collect your Personal Data. You can also exercise your right at any time by contacting us at <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a> or at the postal address set out in this Privacy Policy.</span></p>
        <p><span style={{ fontWeight: 400 }}>7.5 To enforce any of the foregoing rights or if you have any other questions about our Website or this Privacy Policy, please contact us at <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a>.</span></p>
        <p><strong>8. Links to other sites</strong></p>
        <p><span style={{ fontWeight: 400 }}>Our Website may contain advertisements or links to third-party websites. The presence of these links on our Website does not constitute an approval, authorization or affiliation with the websites in question. If you click on these links or use the services of a third party, you will leave our Website and you must consult the privacy policy of the website in question before sharing your personal data.</span></p>
        <p><span style={{ fontWeight: 400 }}>Our Website may contain advertisements or links to third-party websites. The presence of these links on our Website does not constitute an approval, authorization or affiliation with the websites in question. If you click on these links or use the services of a third party, you will leave our Website and you must consult the privacy policy of the website in question before sharing your personal data.</span></p>
        <p><span style={{ fontWeight: 400 }}>Certain products and/or services are offered to you as part of our Services in collaboration with third parties and you may be required to provide Personal Data to them in order to register and access their products or services. The identity of these third parties will be indicated at the time of registration. If you choose to register, you will transmit your Personal Data to these third parties and their use of your personal data will be subject to their privacy policies.</span></p>
        <p><span style={{ fontWeight: 400 }}>We are not responsible for the use of your Personal Data by such third parties and we cannot guarantee that they have the same level of data protection policy as us.</span></p>
        <p><strong>9. Changes</strong></p>
        <p><span style={{ fontWeight: 400 }}>We may change our Privacy Policy from time to time. Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate and feasible, may be notified to you by e-mail. You continued use of our Website or Services will be deemed as an acceptable of the changes to the Privacy Policy.</span></p>
        <p><strong>10. Automated Decision-Making and Profiling</strong></p>
        <p><span style={{ fontWeight: 400 }}>10.1 In the event that we use Personal Data for the purpose of automated decision-making and those decisions have a legal (or similarly significant effect) on you, you have the right to challenge to such decisions under the applicable, requesting human intervention, expressing their own point of view, and obtaining an explanation of the decision from us.</span></p>
        <p><span style={{ fontWeight: 400 }}>10.2 The rights described in section 10.1 does not apply in the following circumstances:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li><span style={{ fontWeight: 400 }}>the decision is necessary for the entry into, or performance of, a contract between you and us;</span></li>
          <li><span style={{ fontWeight: 400 }}>the decision is authorised by law; or</span></li>
          <li><span style={{ fontWeight: 400 }}>you have given your explicit consent.</span></li>
        </ol>
        <p><span style={{ fontWeight: 400 }}>10.3 Where we use your Personal Data for profiling purposes, the following shall apply:</span></p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Clear information explaining the profiling will be provided, including its significance and the likely consequences;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Appropriate mathematical or statistical procedures will be used;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Technical and organisational measures necessary to minimise the risk of errors and to enable such errors to be easily corrected shall be implemented; and</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>All Personal Data processed for profiling purposes shall be secured in order to prevent discriminatory effects arising out of profiling.</span></li>
        </ol>
        <p><strong>11. Terms and Conditions</strong></p>
        <p><span style={{ fontWeight: 400 }}>Please also visit our Terms and Conditions at </span><a href="http://hkqaa.org/en_spage.php?id=2" target="_blank" rel="noreferrer"><span style={{ fontWeight: 400 }}>Terms and Conditions</span></a><span style={{ fontWeight: 400 }}> establishing the use, disclaimers, and limitations of liability governing the use of our Website.</span></p>
        <p><strong>12. Your Consent</strong></p>
        <p><span style={{ fontWeight: 400 }}>By your continued use of our Website or our Services, you are deemed to have acknowledged and accepted our Privacy Policy.</span></p>
        <p><strong>13. Severance&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>If any part or provision of this Privacy Policy is prohibited or deemed to be void or unenforceable, that part or provision shall be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions hereof.</span></p>
        <p><strong>14. Dispute Resolution</strong></p>
        <p><span style={{ fontWeight: 400 }}>14.1 All parties will use their best efforts to negotiate in good faith and settle any dispute that may arise out of or relate to this Privacy Policy or any breach of it.</span></p>
        <p><span style={{ fontWeight: 400 }}>14.2 If any such dispute cannot be settled amicably through ordinary negotiations between the parties, or either or both is or are unwilling to engage in this process, either party may propose to the other in writing that structured negotiations be entered into with the assistance of a fully accredited mediator before resorting to litigation.</span></p>
        <p><span style={{ fontWeight: 400 }}>14.3 All negotiations connected with the relevant dispute(s) will be conducted in confidence and without prejudice to the rights of the parties in any further proceedings.</span></p>
        <p><span style={{ fontWeight: 400 }}>14.4 If the parties agree on a resolution of the dispute at mediation, the agreement shall be reduced to writing and, once signed by the duly authorised representatives of both parties, shall be final and binding on them.</span></p>
        <p><span style={{ fontWeight: 400 }}>14.5 Any dispute shall not affect the parties’ ongoing obligations under this Privacy Policy.</span></p>
        <p><strong>15. Governing Law</strong></p>
        <p><span style={{ fontWeight: 400 }}>This Policy shall be governed by, and construed in accordance with, the laws of Hong Kong Special Administrative Region (&ldquo;<strong>Hong Kong</strong>&rdquo;). Any dispute, controversy, difference or claim arising out of or relating to these Terms and Conditions, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one. The arbitration proceedings shall be conducted in English.</span></p>
        <p><strong>16. Communicate with Us</strong></p>
        <p><span style={{ fontWeight: 400 }}>If you have any questions or concerns regarding this Privacy Policy, please contact us using information as follows:</span></p>
        <p><span style={{ fontWeight: 400 }}>Address: 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong.</span></p>
        <p><span style={{ fontWeight: 400 }}>Telephone Number: +852 2202-9111</span></p>
        <p><span style={{ fontWeight: 400 }}>Email address: <a href="mailto:lightshare@hkqaa.org">lightshare@hkqaa.org</a></span></p>
        <p><strong>Appendix</strong></p>
        <p><strong>A. Privacy rights of users in European Economic Area&nbsp;</strong></p>
        <p><span style={{ fontWeight: 400 }}>This section applies if you are based in the European Economic Area during your interactions with us.</span></p>
        <p><span style={{ fontWeight: 400 }}>We comply with the rights given to EU citizens under the General Data Protection Regulation (GDPR). Those rights are as follows:</span></p>
        <ul>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Correcting Your Information – the right to have Your personal information updated to ensure it is up-to-date and accurate;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Withdrawing Consent – the right to withdraw Your consent to any processing that is currently being done under Your consent, e.g. marketing;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Obtaining a copy of Your Information – the right to receive a copy of the personal information We hold about You;</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Deleting Your information – right to request that We delete personal information in certain circumstances.</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Data Portability – the right to have Us transfer to another controller the personal information that You have provided us with.</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Restricting Processing – the right to request a restriction on the processing of Your data in some limited circumstances.</span></li>
          <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Right to object to processing – right to request that We stop processing Your data for marketing purposes and in other limited circumstances such as asking Us not to process your data by wholly automated means or not to analyse Your information for targeted content etc. (also known as profiling).</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>Your personal data may be transferred and/or stored outside the EEA, including to countries which may have a lower level of data protection than under the GDPR. We shall comply with specific rules when We transfer personal information from inside the EEA to outside the EEA. We will use appropriate safeguards as permitted by the GDPR to protect Your personal data.</span></p>
        <p><span style={{ fontWeight: 400 }}>You can exercise any of the rights above by contacting us at email address: </span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}> .</span></p>
        <p><strong>B. US - California Privacy Rights</strong></p>
        <p><span style={{ fontWeight: 400 }}>For California residents, the CCPA also requires that businesses provide a specific right to opt out of the sale of personal information. We do not sell personal information that directly identifies You. However, the CCPA&rsquo;s broad definition of &ldquo;sale&rdquo; and &ldquo;personal information&rdquo; may deem the common flow of information in the digital analytics and advertising ecosystem to be a sale. Like most companies, We may participate in digital network advertising that may constitute a &ldquo;sale&rdquo; under the CCPA. You can opt out of the sale of your personal information by emailing us at <a href="mailto:unsubscribe@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>unsubscribe@hkqaa.org</span></a> . You will need to provide your name and the email address you have used when engaging with us.</span></p>
        <p><span style={{ fontWeight: 400 }}>We do not knowingly collect personal information from children under the age of 13. If We are aware that personal information of children under 13 has been collected, We will take all reasonable steps to remove such information. Where required by applicable law, We may ask for consent from a parent or guardian before We provide any services or products to a minor.</span></p>
        <p><span style={{ fontWeight: 400 }}>We will not discriminate against You for exercising any of Your CCPA rights.</span></p>
        <p><strong>C. Mainland China</strong></p>
        <p><span style={{ fontWeight: 400 }}>This section applies (in addition to the general Privacy Policy) to the processing of personal information of data subjects staying within the mainland People&rsquo;s Republic of China. Individuals located in Mainland China may have certain rights relating to their Personal Information that is not otherwise unavailable in other regions or countries. HKQAA respects each data subject rights and shall all times protect your Personal Information in accordance with the applicable laws.</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>Personal Information.</span></span><span style={{ fontWeight: 400 }}> In this Section, Personal Information means any type of information (recorded via electronic means or otherwise) associated with an identified or identifiable natural person, but excluding information after anonymisation handling. Personal&nbsp;Information&nbsp;may include Sensitive Personal Information (defined below).</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>Consent.</span></span><span style={{ fontWeight: 400 }}> We will collect, store, use, process, transmit, provide, disclose or delete your Personal Information in accordance with this Privacy Policy and the applicable sections. If the applicable Mainland data protection laws and regulations require us to obtain separate consent, we will only process your Personal Information upon obtaining the requisite consent.</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>Sensitive Personal Information.</span></span><span style={{ fontWeight: 400 }}> We will collect and handle sensitive Personal Information, for example your credit or debit card information, account information for the processing of your payment. Your visit and activities on our Website, collected through cookies or other tracking technologies. It is necessary for you to provide us with such Sensitive Personal Information for the purpose of providing Services to you. If you do not provide us with your consent, your user experience and use of our Website may be limited.</span></p>
        <p><span style={{ fontWeight: 400 }}><span style={{ textDecoration: "underline" }}>Storing your Personal Information.</span></span><span style={{ fontWeight: 400 }}> Your Personal Information shall be stored in Hong Kong as well in other regions or countries. If applicable Mainland Chinese laws require us to provide you with further notice and obtain your separate consent for the cross-border transfer, we will further provide such notice and obtain your separate consent.</span></p>
        <p><span style={{ fontWeight: 400 }}>You can exercise any of the rights above by contacting us at email address: </span><a href="mailto:lightshare@hkqaa.org"><span style={{ fontWeight: 400 }}>lightshare@hkqaa.org</span></a><span style={{ fontWeight: 400 }}> .</span></p>
        <p><strong>Cookie Policy</strong></p>
        <p><strong>Last Updated: 13 March 2022</strong></p>
        <p><span style={{ fontWeight: 400 }}>Our Website uses cookies to distinguish you from other users on our Website. This helps us to provide you with a better experience when you browse our Website and also allows us to improve our Website. You may decline the storage of cookies by changing the configuration of your web browser, however, doing so may affect your experience in using our Website and our Services.</span></p>
        <p><span style={{ fontWeight: 400 }}>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer&rsquo;s hard drive.</span></p>
        <p><span style={{ fontWeight: 400 }}>We use the following cookies:</span></p>
        <ul>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>Strictly necessary cookies</span></span><span style={{ fontWeight: 400 }}>. These are cookies that are required for the operation of our Website. They include, for example, cookies that enable you to log into secure areas of our Website, use a shopping cart or make use of e-billing services.</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>Analytical or performance cookies</span></span><span style={{ fontWeight: 400 }}>. These allow us to recognise and count the number of users and to see how users move around our Website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>Functionality cookies</span></span><span style={{ fontWeight: 400 }}>. These are used to recognise you when you return to our Website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</span></li>
          <li><span style={{ textDecoration: 'underline' }}><span style={{ fontWeight: 400 }}>Targeting cookies</span></span><span style={{ fontWeight: 400 }}>. These cookies record your visit to our Website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</span></li>
        </ul>
        <p><span style={{ fontWeight: 400 }}>Please note that third parties may also use cookies, over which we have no control. These third parties may include, for example, advertising networks and providers of external services like web traffic analysis services.</span></p>
      </div>
    );
  }
}
