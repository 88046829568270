import Layout from "../../../../components/LayoutCms";
import React, {useEffect, useState} from "react";
import { Button, Col, Row } from "react-bootstrap";
import CourseTabNavigation from "../../../../components/CourseTabNavigation";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import CourseTitle from "../../../../components/CourseTitle";
import {getApiClient} from "../../../../util/auth";
import {useNavigate, useParams} from "react-router-dom";
import CourseNameCard from "../../../../components/Cms/CourseNameCard";

export default function Quiz(props) {
    // Props
    const readOnly = props.readOnly;
    const isEditing = props.isEditing;
    
    // react hooks
    const navigate = useNavigate();
    const { course_id } = useParams();

    // states
    // Course 
    const [course, setCourse] = useState({});
    const [quiz_id, set_quiz_id] = useState('');

    const emptyQuestion = {title:'',choices:['','','',''], choicesId:[-1,-2,-3,-4], correct_answer:null};

    // Questions - default to 3 empty questions
    const [questions, setQuestions] = useState([
        {title:'',choices:['','','',''], choicesId:[-1,-2,-3,-4], correct_answer:null},
        {title:'',choices:['','','',''], choicesId:[-1,-2,-3,-4], correct_answer:null},
        {title:'',choices:['','','',''], choicesId:[-1,-2,-3,-4], correct_answer:null}
    ]);

    // Quiz Settings
    const [enable_passing_grade, set_enable_passing_grade] = useState(false);
    const [passing_grade_percentage, set_passing_grade_percentage] = useState(0);
    const [allow_retake, set_allow_retake] = useState(false);
    const [retake_time, set_retake_time] = useState(99);
    const [show_test_result, set_show_test_result] = useState(false);

    const role = localStorage.getItem("role");


    useEffect(() => {
        if (course_id) {
        getApiClient()
            .post("/user/view_own_course", {course_id : course_id})
            .then((response) => {
                if(isEditing) {
                    //Checking if the course is terminated, the course owner cannot edit
                    if(response?.data?.course?.[0]?.Is_Terminated === 'Yes') {
                        alert('The course has been terminated by HKQAA admin. You cannot edit the course!');
                        navigate(-1);
                        return;
                    }
                }

                setCourse(response.data.course[0]);
            })
            .catch((error) => {
                console.log(error);
            });

        getApiClient()
            .post("/user/view_own_quiz", { course_id: course_id })
            .then((response) => {
                const quiz = response.data.quiz[0];
                set_enable_passing_grade(quiz.Set_Passing_Grade ? true : false);
                set_passing_grade_percentage(quiz.Passing_Grade_Percentage); // int of 0 - 100
                set_allow_retake(quiz.Allow_Retake ? true : false);
                set_retake_time(quiz.Number_Of_Allowed_Retake);
                set_show_test_result(quiz.Show_Result ? true : false);

                set_quiz_id(quiz.id);

                let q_choices = response.data.questions_choices;
                let newQuestions = [];
                q_choices.map((qc, index)=>{
                    let thisQuestion = newQuestions.find(element => element?.id === qc.quiz_question_id);
                    if(thisQuestion){
                        thisQuestion.choices.push(qc.Choice_Title);
                        thisQuestion.choicesId.push(qc.quiz_question_choice_id);
                        if(qc.Is_Correct_Answer === "True"){
                            thisQuestion.correct_answer = qc.quiz_question_choice_id;
                        }
                    }else{
                        newQuestions.push({
                            id:qc.quiz_question_id,
                            title:qc.Question_Title, 
                            choices: [qc.Choice_Title],
                            choicesId: [qc.quiz_question_choice_id],
                            correct_answer: qc.Is_Correct_Answer === "True" ? qc.quiz_question_choice_id : null,
                        });
                    }
                })
                setQuestions(newQuestions);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);


  const isArchive = () => {
    return course?.Course_Status === 'Archive';
  }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!course_id) {
            return alert('Please create the course first.');
        } else if (questions.length < 3) {
            return alert(`Minimum three questions are required. Please add ${3-questions.length} more questions.`);
        }else if ( questions.findIndex(element => element.correct_answer === null) !== -1){
            // at least one question has not selected the correct answer
            return alert('Please select the corret answer choice for each question!');
        }

        const params = {
            "course_id": course_id,
            "id": quiz_id,
            "quiz_questions": parseQuestions(),
            "set_passing_grade": enable_passing_grade ? "1" : "0",
            "passing_grade_percentage": passing_grade_percentage,
            "allow_retake": allow_retake ? "1" : "0",
            "retake_time": parseInt(retake_time),
            "show_result": show_test_result ? "1" : "0",
        }

        // if the key ID exists the API will perform an UPDATE instead of INSERT
        if( !quiz_id ){
            delete params['id'];
        }

        // return console.log(params);
        getApiClient()
        .post("/user/addOrEditQuiz", params )
        .then((response) => {
            navigate(`/cms/course/${course_id}/edit/evaluation-form`);
        }).catch(() => {
            console.log("/user/addOrEditQuiz encounters error");
        });
    };

    const parseQuestions = () => {
        let result = [];
        questions.map((q) => {
            let choices = [];
            q.choices.map((c, index_c) => {
                const cor_ans = q.correct_answer === q.choicesId[index_c];
                choices.push({
                    quiz_question_choice_id: q.choicesId[index_c] > 0 ? q.choicesId[index_c] : '',
                    choice_title: c,
                    is_correct_answer: cor_ans,
                })
            })

            result.push({
                question_title: q.title,
                quiz_question_id: q.id,
                choices
            });
        })

        return result;
    }

  const handleTitle = (e, index) => {
    let temp = questions.slice();
    temp[index].title = e.target.value;
    setQuestions(temp);
  }

  const handleChoice = (e, index_q, index_c) => {
    let temp = questions.slice();
    temp[index_q].choices[index_c] = e.target.value;
    setQuestions(temp);
  }

  const handleCorrectAnswer = (e, index_q, index_c) => {
    let temp = questions.slice();
    temp[index_q].correct_answer = index_c;
    setQuestions(temp);
  }

  const handleAddQuestion = () => {
    let temp = questions.slice();
    temp.push(emptyQuestion);
    setQuestions(temp);
  }

  const handleDelete = (e, index) => {
    if( ! window.confirm('Confirm delete?') ){ return; }
    
    if(questions[index]?.id){
        getApiClient()
        .post("/user/deleteQuizQuestion", {course_id : course_id, quiz_question_id: questions[index].id})
        .then((response) => {
            let temp = questions.slice();
            temp.splice(index, 1);
            setQuestions(temp);
        })
        .catch((error) => {
          console.log(error);
        });
    }else{ // if not, just delete from UI 
        let temp = questions.slice();
        temp.splice(index, 1);
        setQuestions(temp);
    }  
  }

  return (
    <Layout route={props.readOnly ? 'courses.showQuiz' : isEditing ? 'courses.editQuiz' : 'courses.addQuiz'}>
        <CourseTitle readOnly={readOnly} isEditing={isEditing} />
        <CourseTabNavigation readOnly={readOnly} isEditing={isEditing} activeNavItem={"quiz"} />
        <CourseNameCard course={course} />
        
        <form onSubmit={handleSubmit}>
            <div className="text-eb6201 font-size-12px">
                Minimum 3 questions required
            </div>

            {questions.map((question, key)=>{
                return <QuizQuestion 
                    key={`quizquest-${key}`}
                    question={question}
                    index={key}
                    readOnly={readOnly}
                    isArchive={isArchive}
                    canDelete={course.Course_Status === 'Draft' && key > 2}
                    handleTitle={handleTitle}
                    handleChoice={handleChoice}
                    handleCorrectAnswer={handleCorrectAnswer}
                    handleDelete={handleDelete}
                />
            })}

            {!readOnly && course?.Course_Status !== 'Archive' &&
            <div className="mb-3">
                <PrimaryButton onClick={()=>handleAddQuestion()}>+ Add New Question</PrimaryButton>
            </div>
            }

            {/* ============================================================
                    Quiz Settings
            ============================================================ */}
            <div className={"text-secondary h1"}>
                Quiz Setting
            </div>

            <ShadowContainer>
                <div className="form-check form-switch mb-3">
                <input className="form-check-input"
                    type="checkbox" role="switch"
                    checked={enable_passing_grade}
                    onChange={(e) => set_enable_passing_grade(e.target.checked)}
                    disabled={readOnly || isArchive()}
                />
                <label>Set passing grade</label>
                </div>
            
                <div className="form-check form-switch d-flex align-items-center mb-3">
                    <label>Passing grade percentage</label>
                    <div className="input-group ms-3" style={{ width: 300 }}>
                        <input className="form-control"
                            type="number" min={0} max={100} 
                            value={passing_grade_percentage}
                            onChange={(e) => set_passing_grade_percentage(e.target.value)}
                            readOnly={readOnly}
                            disabled={readOnly || isArchive() || !enable_passing_grade}
                            required={enable_passing_grade ? true : false}
                        />
                        <span className="input-group-text bg-white">%</span>
                    </div>
                </div>
            
                <div className="form-check form-switch mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        name={"allow_retake"}
                        checked={allow_retake}
                        onChange={(e) => set_allow_retake(e.target.checked)}
                        disabled={readOnly || isArchive()}
                    />
                    <label>Allow learners to retake tests (1-99 times)</label>
                </div>

                <div className="form-check form-switch d-flex align-items-center mb-3">
                    <label>Retake time</label>
                    <div className="input-group ms-3" style={{ width: 300 }}>
                        <input className="form-control"
                            type="number" min={1} max={99} 
                            value={retake_time}
                            onChange={(e) => set_retake_time(e.target.value)}
                            readOnly={readOnly}
                            disabled={readOnly || isArchive() || !allow_retake}
                            required={allow_retake ? true : false}
                        />
                    </div>
                </div>

                {/* <div className="form-check form-switch mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={show_test_result}
                        onChange={(e) => set_show_test_result(e.target.checked)}
                        disabled={readOnly || isArchive()}
                    />
                    <label className="form-check-label">
                        Show test results after completion
                    </label>
                </div> */}
            </ShadowContainer>

            <div className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
            style={{ left: 240 }} >
            <div className={"d-flex"}>
                {readOnly
                ?
                ''
                :
                <PrimaryButton className="mx-2" type={"submit"}>
                    Save & Next
                </PrimaryButton>
                }
                <Button className="mx-2" variant={"secondary"}
                onClick={()=>navigate(role === "Organization" ? '/cms/courses/all' : role === "Trainer/Academy" ? '/cms/trainer-courses/all' : '/cms/association-courses/all')}>
                    Cancel
                </Button>
            </div>
            </div>
        </form>
        <div style={{height:100}}></div>
    </Layout>
  );
}

function QuizQuestion({question, index, readOnly, isArchive, canDelete, 
    handleTitle, handleChoice, handleCorrectAnswer, handleDelete}){

    const choicesLabels = ['A', 'B', 'C', 'D'];

    return <ShadowContainer key={index}>
        <div>Question {index + 1}</div>
        <hr/>
        <Row>
            <Col md={6}>
                <label className="text-5c5c5c col-form-label">Question</label>
                <textarea className="form-control" style={{resize: "none"}}
                    rows="5" required
                    value={question.title}
                    onChange={(e) => handleTitle(e, index)}
                    readOnly={readOnly}
                />
            </Col>
        </Row>
        <label className="text-5c5c5c col-form-label">
            Enter the answer choices and select correct one
        </label>
        
        {question.choices.map((choice, key) => (
            <Row className="my-3">
                <Col md={2}>
                    <div className="d-flex justify-content-center align-items-center p-0"
                        style={{ width:100,height:'100%',border:'1px solid #ccc', borderRadius:3}}>
                        <input type="radio" className="form-check-input"
                        disabled={readOnly || isArchive()}
                        checked={question.correct_answer === question.choicesId[key]}
                        onChange={(e) => handleCorrectAnswer(e, index, question.choicesId[key])} />
                        <label className="form-check-label mx-2">{choicesLabels[key]}</label>
                    </div>
                </Col>
                <Col md={4}>
                    <input required type="text" className="form-control" readOnly={readOnly}
                    value={choice}
                    onChange={(e) => handleChoice(e, index, key)} />
                </Col>
            </Row>
        ))}

        {canDelete &&
            <Button className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
            onClick={(e) => handleDelete(e, index) }>Delete question</Button>
        }
    </ShadowContainer>;
}