import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CourseTabNavigation from "../../../components/CourseTabNavigation";
import LayoutCms from "../../../components/LayoutCms";
import UploadBox from "../../../components/UploadBox";
import PreviewBox from "../../../components/PreviewBox";
import ShadowContainer from "../../../components/ShadowContainer";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getApiClient } from "../../../util/auth";
import { getTextByCode } from "../../../util/lang";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import CourseTitle from "../../../components/CourseTitle";
import CourseCategory from "../../../components/Options/CourseCategory";
import IndustrySelected from "../../../components/Options/IndustrySelected";
import PreviewCertificate from "../../../components/Cms/PreviewCertificate";

export default function CreateOrEditCourse(props) {
  const navigate = useNavigate();

  // no id = creating a new course
  const params = useParams();
  const course_id = params.course_id;

  const isEditing = props.isEditing;
  const readOnly = props.readOnly;

  const [course_fee, set_course_fee] = useState(null);
  const [course_fee_amount, set_course_fee_amount] = useState(null);

  const [course_name_en, set_course_name_en] = useState('');
  const [course_name_tc, set_course_name_tc] = useState('');
  const [course_name_sc, set_course_name_sc] = useState('');
  const [course_description_en, set_course_description_en] = useState('');
  const [course_description_tc, set_course_description_tc] = useState('');
  const [course_description_sc, set_course_description_sc] = useState('');
  const [trainer, set_trainer] = useState('');
  const [trainer_organization, set_trainer_organization] = useState('');
  const [trainer_description, set_trainer_description] = useState('');
  const [off_the_shelf_date, set_off_the_shelf_date] = useState('');
  const [course_master_language, set_course_master_language] = useState(null);
  const [material_language_en, set_material_language_en] = useState(false);
  const [material_language_tc, set_material_language_tc] = useState(false);
  const [material_language_sc, set_material_language_sc] = useState(false);
  const [video_language_audio_en, set_video_language_audio_en] = useState(false);
  const [video_language_audio_tc, set_video_language_audio_tc] = useState(false);
  const [video_language_audio_sc, set_video_language_audio_sc] = useState(false);
  const [video_language_subtitle_en, set_video_language_subtitle_en] = useState(false);
  const [video_language_subtitle_tc, set_video_language_subtitle_tc] = useState(false);
  const [video_language_subtitle_sc, set_video_language_subtitle_sc] = useState(false);
  const [preview_video, set_preview_video] = useState(null);
  const [course_photo, set_course_photo] = useState(null);
  const [certificate_offered, set_certificate_offered] = useState('No');
  const [categories, set_categories] = useState([]);
  const [industries, set_industries] = useState([]);
  const [status, set_status] = useState('');
  const [is_public, set_is_public] = useState('False');

  const [showModal, setShowModal] = useState(false);

  const role = localStorage.getItem("role");
  const [user, setUser] = useState(null);

  const api = getApiClient();
  useEffect(() => {
    if(role !== 'Organization' && role !== 'Trainer/Academy' && role !== 'Association') {
      alert('User not authorized! You will go back to Home Page.');
      if(role === null || role === '') {
        navigate('/login');
      } else {
        navigate('/');
      }
      return;
    }

    api
      .get("/user/viewProfile")
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        //TODO error handling
        console.log(error);
      });
    
    if (course_id) {
      api
        .post("/user/view_own_course", {
          course_id: course_id,
        })
          .then((response) => {
            //Checking if the course is terminated, the course owner cannot edit
            if(response?.data?.course?.[0]?.Is_Terminated === 'Yes') {
              alert('The course has been terminated by HKQAA admin. You cannot edit the course!');
              navigate(-1);
              return;
            }

            set_course_name_en(getTextByCode(response?.data?.course?.[0]?.Course_Name, 'EN'));
            set_course_name_tc(getTextByCode(response?.data?.course?.[0]?.Course_Name, 'TC'));
            set_course_name_sc(getTextByCode(response?.data?.course?.[0]?.Course_Name, 'SC'));
            set_course_description_en(getTextByCode(response?.data?.course?.[0]?.Course_Descriptions, 'EN'));
            set_course_description_tc(getTextByCode(response?.data?.course?.[0]?.Course_Descriptions, 'TC'));
            set_course_description_sc(getTextByCode(response?.data?.course?.[0]?.Course_Descriptions, 'SC'));
            set_trainer(response?.data?.course?.[0]?.Trainer);
            set_trainer_organization(response?.data?.course?.[0]?.Trainer_Organization);
            set_trainer_description(response?.data?.course?.[0]?.Trainer_Description);
            set_off_the_shelf_date(moment(response?.data?.course?.[0]?.Off_The_Shelf_Date).format('Y-MM-DD') ?? '');
            set_course_master_language(response?.data?.course?.[0]?.Course_Master_Language);
            set_course_photo(response?.data?.course?.[0]?.Course_Photo);
            set_preview_video(response?.data?.course?.[0]?.Preview_Video);

            set_material_language_en(getTextByCode(response?.data?.course?.[0]?.Material_Language, 'EN') ?? false);
            set_material_language_tc(getTextByCode(response?.data?.course?.[0]?.Material_Language, 'TC') ?? false);
            set_material_language_sc(getTextByCode(response?.data?.course?.[0]?.Material_Language, 'SC') ?? false);

            set_video_language_audio_en(getTextByCode(response?.data?.course?.[0]?.Video_Language_Audio, 'English') ?? false);
            set_video_language_audio_tc(getTextByCode(response?.data?.course?.[0]?.Video_Language_Audio, 'Cantonese') ?? false);
            set_video_language_audio_sc(getTextByCode(response?.data?.course?.[0]?.Video_Language_Audio, 'Mandarin') ?? false);

            set_video_language_subtitle_en(getTextByCode(response?.data?.course?.[0]?.Video_Language_Subtitle, 'EN') ?? false);
            set_video_language_subtitle_tc(getTextByCode(response?.data?.course?.[0]?.Video_Language_Subtitle, 'TC') ?? false);
            set_video_language_subtitle_sc(getTextByCode(response?.data?.course?.[0]?.Video_Language_Subtitle, 'SC') ?? false);

            set_certificate_offered(response?.data?.course?.[0]?.Certificate_Offered);

            set_course_fee(response?.data?.course?.[0]?.Course_Fee);
            set_course_fee_amount(parseInt(response?.data?.course?.[0]?.Course_Fee_Amount ?? null, 10));
            if(response?.data?.course?.[0]?.Course_Fee == 'Free') {
              set_course_fee_amount(null);
              if(role !== "Organization") {
                document.getElementById("Pay_Number").value = null;
                document.getElementById("Pay_Number").disabled = true;
              }
            }

            set_status(response?.data?.course?.[0]?.Course_Status);

            if (response?.data?.course?.[0]?.Course_Categories) {
              const categories_object = JSON.parse(response?.data?.course?.[0]?.Course_Categories);
              set_categories(Object.values(categories_object));
            }
            if (response?.data?.course?.[0]?.Industries) {
              const industries_object = JSON.parse(response?.data?.course?.[0]?.Industries);
              set_industries(Object.values(industries_object));
            }

            set_is_public(response?.data?.course?.[0]?.Is_Public);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, []);

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handlePreview = (event) => {
    event.preventDefault();
    console.log("~ handlePreview ~")
    handleShowModal(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if(course_master_language == null) {
      return alert('Please select one Course Master Language');
    }

    if(!formData.get("material_language_en") &&
    !formData.get("material_language_tc") &&
    !formData.get("material_language_sc") ){
      return alert('Please select at least one Material Language');
    }

    if(!formData.get("video_language_audio_en") &&
    !formData.get("video_language_audio_tc") &&
    !formData.get("video_language_audio_sc") ){
      return alert('Please select at least one  Audio Language');
    }

    let categories_data = {};
    for (const [index, category] of categories.entries()) {
      categories_data[String(index + 1)] = category;
    }

    let industries_data = {};
    for (const [index, industry] of industries.entries()) {
      industries_data[String(index + 1)] = industry;
    }

    if (categories.length == 0) {
      return alert('Please select minimum 1 course categories');
    } else if (categories.length > 3) {
      return alert('Please select maximum 3 course categories');
    }

    if (industries.length == 0) {
      return alert('Please select minimum 1 industries');
    } else if (industries.length > 3) {
      return alert('Please select maximum 3 industries');
    }

    if(course_fee === 'Pay' && course_fee_amount <= 0) {
      return alert('If you set the course fee be Pay, value must be greater than or equal to 1');
    }

    const params = {
      // if id exists, DB will UPDATE instead of INSERT
      id: course_id ? course_id : undefined,
      course_name_en: formData.get("course_name_en"),
      course_name_tc: formData.get("course_name_tc"),
      course_name_sc: formData.get("course_name_sc"),
      course_master_language: course_master_language,
      course_descriptions_en: formData.get("course_descriptions_en"),
      course_descriptions_tc: formData.get("course_descriptions_tc"),
      course_descriptions_sc: formData.get("course_descriptions_sc"),

      course_categories: categories_data,
      industries: industries_data,
      course_fee: course_fee,
      course_fee_amount: course_fee_amount,

      material_language: {
        EN: formData.get("material_language_en") === "on",
        TC: formData.get("material_language_tc") === "on",
        SC: formData.get("material_language_sc") === "on",
      },
      video_language_audio: {
        English: formData.get("video_language_audio_en") === "on",
        Cantonese: formData.get("video_language_audio_tc") === "on",
        Mandarin: formData.get("video_language_audio_sc") === "on",
      },
      video_language_subtitle: {
        EN: formData.get("video_language_subtitle_en") === "on",
        TC: formData.get("video_language_subtitle_tc") === "on",
        SC: formData.get("video_language_subtitle_sc") === "on",
      },

      preview_video: preview_video,
      course_photo: course_photo,

      trainer: formData.get("trainer"),
      trainer_organization: formData.get("trainer_organization"),
      trainer_description: trainer_description,

      off_the_shelf_date: formData.get("off_the_shelf_date"),

      enrollment_validity: formData.get("enrollment_validity"),
      certificate_offered: certificate_offered,

      is_public: is_public,
    };

    getApiClient()
      .post("/user/addOrEditCourse", params)
      .then((response) => {
        if(isEditing || params.id ){
          const exisitng_course_id = course_id;  // get by useParams() from url
          alert(`Course saved successfully.`);
          navigate(`/cms/course/${exisitng_course_id}/edit/module`);
        }else{
          // Creating New Course
          const new_course = response.data.course;
          alert(`Course ${new_course.Course_Code} created!`);
          navigate(`/cms/course/${new_course.id}/edit/module`);
        }

      })
      .catch((error) => {
        console.log("/user/addOrEditCourse encounters error");
        if(error?.response?.statusText === 'Payload Too Large') {
          alert('Your input text too long, maximum 5000 characters.');
        } else {
          alert(error?.response?.data?.message);
        }
      });
  }

  const isArchived = () => { 
    return status === 'Archive';
  }

  return (
    <LayoutCms route={isEditing ? 'courses.edit' : 'courses.create'}>
      <CourseTitle isEditing={isEditing}/>

      {/* When creating a new course there should not be Navigation bar */}
      {isEditing ?
      <CourseTabNavigation activeNavItem={'course'} isEditing={isEditing} readOnly={readOnly} />
      :''}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size={"lg"}
        scrollable={true}
        centered
      >
        <Modal.Header closeButton><p className="m-0 font-size-20px text-a2a2a2">Preview Certificate</p></Modal.Header>
        <Modal.Body>
          <PreviewCertificate 
            course_name_en={course_name_en} 
            course_name_tc={course_name_tc} 
            course_name_sc={course_name_sc} 
            course_master_language={course_master_language} 
            user={user}
          />
        </Modal.Body>
      </Modal>

      <form onSubmit={(e)=>handleSubmit(e)}>
        <ShadowContainer>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Name (ENG)<span className={"text-danger"}>*</span>
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                type="text"
                className="form-control"
                placeholder={"Course Name (ENG)"}
                name={"course_name_en"}
                required={true}
                value={course_name_en}
                onChange={(e) => set_course_name_en(e.target.value)}
                maxlength="100"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Name (繁)<span className={"text-danger"}>*</span>
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                type="text"
                className="form-control"
                placeholder={"Course Name (繁)"}
                name={"course_name_tc"}
                required={true}
                value={course_name_tc}
                onChange={(e) => set_course_name_tc(e.target.value)}
                maxlength="50"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Name (简)<span className={"text-danger"}>*</span>
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                type="text"
                className="form-control"
                placeholder={"Course Name (简)"}
                name={"course_name_sc"}
                required={true}
                value={course_name_sc}
                onChange={(e) => set_course_name_sc(e.target.value)}
                maxlength="50"
              />
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Master Language<span className={"text-danger"}>*</span>
            </label>
            <Col sm={1}>
              <div className="form-check">
                <input
                  className="form-check-input" type="radio" name={"course_master_language"} value={"EN"}
                  checked={course_master_language === "EN"}
                  onChange={(e) => set_course_master_language(e.target.value)}
                  disabled={isArchived()}
                />
                <label className="form-check-label">English</label>
              </div>
            </Col>
            <Col sm={1} className="d-flex align-items-center">
              <div className="form-check">
                <input
                  className="form-check-input" type="radio" name={"course_master_language"} value={"TC"}
                  checked={course_master_language === "TC"}
                  onChange={(e) => set_course_master_language(e.target.value)}
                  disabled={isArchived()}
                />
                <label className="form-check-label">廣東話</label>
              </div>
            </Col>
            <Col sm={1} className="d-flex align-items-center">
              <div className="form-check">
                <input
                  className="form-check-input" type="radio" name={"course_master_language"} value={"SC"}
                  checked={course_master_language === "SC"}
                  onChange={(e) => set_course_master_language(e.target.value)}
                  disabled={isArchived()}
                />
                <label className="form-check-label">普通话</label>
              </div>
            </Col>
          </Row>
          <Col sm={12} md={12} lg={6} className="mb-3">
            <label className="text-5c5c5c col-form-label">
              Course Descriptions (ENG)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"course_descriptions_en"}
                required={true}
                value={course_description_en}
                onChange={(e) => set_course_description_en(e.target.value)}
                maxlength="5000"
              />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="mb-3">
            <label className="text-5c5c5c col-form-label">
              Course Descriptions (繁)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"course_descriptions_tc"}
                required={true}
                value={course_description_tc}
                onChange={(e) => set_course_description_tc(e.target.value)}
                maxlength="5000"
              />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="mb-3">
            <label className="text-5c5c5c col-form-label">
              Course Descriptions (简)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"course_descriptions_sc"}
                required={true}
                value={course_description_sc}
                onChange={(e) => set_course_description_sc(e.target.value)}
                maxlength="5000"
              />
            </div>
          </Col>
        </ShadowContainer>

        <ShadowContainer>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Category
              <div className={"font-size-12px text-danger"}>Max. 3 categories and press "Ctrl" for multiple selection</div>
            </label>
            <Col sm={10} md={10} lg={4}>
              <select 
                className="form-select" name={"course_categories"} multiple onChange={(e) => {
                const value = Array.from(e.target.selectedOptions, option => option.value);
                !isArchived() && set_categories(value);
              }}>
                <CourseCategory categories={categories} lang='EN'/>
              </select>
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Industry
              <div className={"font-size-12px text-danger"}>Max. 3 industries and press "Ctrl" for multiple selection</div>
            </label>
            <Col sm={10} md={10} lg={4}>
              <select 
              className="form-select" name={"industries"} multiple
                      onChange={(e) => {
                        const value = Array.from(e.target.selectedOptions, option => option.value);
                        !isArchived() && set_industries(value);
                      }}
              >

                <IndustrySelected industries={industries} lang='EN' />

              </select>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Course Fee<span className={"text-red"}>*</span>
            </label>
            <Col sm={2}>
              <div className="form-check">
                {/** Only organization need to default Free */}
                {role === "Organization"
                ?
                <input
                className="form-check-input" type="radio" name={"course_fee"} value={"Free"}
                       checked={true}
                       onChange={(e) => set_course_fee(e.target.value)}
                />
                :
                <input
                className="form-check-input" type="radio" name={"course_fee"} value={"Free"}
                       checked={course_fee === "Free"}
                       onChange={(e) => { 
                         set_course_fee(e.target.value);
                         document.getElementById("Pay_Number").value = null;
                         set_course_fee_amount(null);
                         document.getElementById("Pay_Number").disabled = true;
                       }}
                />
                }
                <label className="form-check-label">Free</label>
              </div>
            </Col>
            {(role === 'Trainer/Academy' || role === 'Association')
            ?
            <>
              <Col sm={2}>
                <div className="form-check me-2">
                  <input 
                    className="form-check-input" type="radio" name={"course_fee"} value={"Paid"}
                    checked={course_fee === "Paid"}
                    onChange={(e) => set_course_fee(e.target.value)}
                  />
                  <label className="form-check-label">Paid</label>
                </div>
              </Col>
              <Col sm={2} className="d-flex align-items-center">
                <div className="form-check me-2">
                  <input 
                  className="form-check-input" type="radio" name={"course_fee"} value={"Pay"}
                        checked={course_fee === "Pay"}
                        onChange={(e) => { 
                          set_course_fee(e.target.value);
                          document.getElementById("Pay_Number").disabled = false;
                        }}
                  />
                  <label className="form-check-label">Pay</label>
                </div>

                <input
                  id="Pay_Number"
                  type="number"
                  min="1"
                  className="form-control"
                  placeholder={"HK$"}
                  name={"course_fee_amount"}
                  onChange={(e) => set_course_fee_amount(e.target.value)}
                  value={course_fee_amount} required={course_fee === "Pay"} />
              </Col>
            </>
            :
            ""
            }
          </Row>
        </ShadowContainer>

        <ShadowContainer>
          <Row className="mb-4 align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Material Language<span className={"text-red"}>*</span>
            </label>
            <Col className="d-flex">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"material_language_en"}
                  value={"on"}
                  checked={material_language_en}
                  onChange={(e) => !isArchived() && set_material_language_en(e.target.checked)}
                />
                <label className="form-check-label">English</label>
              </div>
              <div className="form-check form-switch mx-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"material_language_tc"}
                  value={"on"}
                  checked={material_language_tc}
                  onChange={(e) => !isArchived() && set_material_language_tc(e.target.checked)}
                />
                <label className="form-check-label">繁體</label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"material_language_sc"}
                  value={"on"}
                  checked={material_language_sc}
                  onChange={(e) => !isArchived() && set_material_language_sc(e.target.checked)}
                />
                <label className="form-check-label">简体</label>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Video Language
            </label>
          </Row>

          <Row className="align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">Audio<span className={"text-red"}>*</span></label>
            <Col className="d-flex">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_audio_en"}
                  value={"on"}
                  checked={video_language_audio_en}
                  onChange={(e) => !isArchived() && set_video_language_audio_en(e.target.checked)}
                />
                <label className="form-check-label">English</label>
              </div>
              <div className="form-check form-switch mx-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_audio_tc"}
                  value={"on"}
                  checked={video_language_audio_tc}
                  onChange={(e) => !isArchived() && set_video_language_audio_tc(e.target.checked)}
                />
                <label className="form-check-label">廣東話</label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_audio_sc"}
                  value={"on"}
                  checked={video_language_audio_sc}
                  onChange={(e) => !isArchived() && set_video_language_audio_sc(e.target.checked)}
                />
                <label className="form-check-label">普通话</label>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Subtitle
            </label>
            <Col className="d-flex">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_subtitle_en"}
                  value={"on"}
                  checked={video_language_subtitle_en}
                  onChange={(e) => !isArchived() && set_video_language_subtitle_en(e.target.checked)}
                />
                <label className="form-check-label">English</label>
              </div>
              <div className="form-check form-switch mx-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_subtitle_tc"}
                  value={"on"}
                  checked={video_language_subtitle_tc}
                  onChange={(e) => !isArchived() && set_video_language_subtitle_tc(e.target.checked)}
                />
                <label className="form-check-label">繁體</label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name={"video_language_subtitle_sc"}
                  value={"on"}
                  checked={video_language_subtitle_sc}
                  onChange={(e) => !isArchived() && set_video_language_subtitle_sc(e.target.checked)}
                />
                <label className="form-check-label">简体</label>
              </div>
            </Col>
          </Row>
        </ShadowContainer>

        <ShadowContainer>
          <div className="mb-3 row">
            <div className="col-sm-6">
              <div className={"text-5c5c5c mb-3"}>{"Preview Video"}</div>
              <UploadBox
                  disabled={isArchived()}
                  onUploaded={(api_responses) => { set_preview_video(api_responses?.[0]?.urls?.[0]) }}
                  accept={{"video/*": ['.mp4']}}
                  endpoint={`/s3/video`}
                  maxSize={600 * 1024 * 1024}
              />
            </div>
          </div>

          {
            //the video tag needs a key otherwise the preview will not be refreshed when state change
              preview_video
              &&
              <div className="mb-3 row">
                <div className="col-sm-6">
                  <PreviewBox 
                  disabled={isArchived()}
                  url={preview_video} onDelete={() => { set_preview_video(null); }} />
                </div>
              </div>
          }


          <div className="mb-3 row">
            <div className="col-sm-6">
              <div className={"text-5c5c5c mb-3"}>{"Course Photo"}</div>
              <UploadBox
                  disabled={isArchived()}
                  onUploaded={(api_responses) => { set_course_photo(api_responses?.[0]?.urls?.[0]) }}
                  width_ratio={3}
                  height_ratio={2}
                  tolerance={0.1}
                  allowImageEdit
              />
            </div>
          </div>

          {
            course_photo
              &&
              <div className="mb-3 row">
                <div className="col-sm-6">
                  <PreviewBox 
                  disabled={isArchived()}
                  url={course_photo} onDelete={() => { set_course_photo(null); }} />
                </div>
              </div>
          }
        </ShadowContainer>

        <ShadowContainer>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Trainer
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                  type="text"
                  className="form-control"
                  placeholder={""}
                  name={"trainer"}
                  value={trainer}
                  onChange={(e) => set_trainer(e.target.value)}
                  maxlength="100"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Trainer's Organization
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                  type="text"
                  className="form-control"
                  placeholder={""}
                  name={"trainer_organization"}
                  value={trainer_organization}
                  onChange={(e) => set_trainer_organization(e.target.value)}
                  maxlength="100"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Trainer's Description
            </label>
            <Col sm={10} md={10} lg={4}>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"trainer_description"}
                value={trainer_description}
                onChange={(e) => set_trainer_description(e.target.value)}
                maxlength="5000"
              />
            </Col>
          </Row>
        </ShadowContainer>

        <ShadowContainer>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Off-the-shelf Date<span className={"text-danger"}>*</span>
            </label>
            <Col sm={10} md={10} lg={4}>
              <input
                  type="date"
                  required
                  className="form-control"
                  placeholder={""}
                  name={"off_the_shelf_date"}
                  value={off_the_shelf_date}
                  min={moment().add(7, 'day').format('Y-MM-DD')}
                  max={moment().add(24, 'M').format('Y-MM-DD')}

                  //TODO may need to change to use Creation Date + 24 months instead of NOW() + 24 months
                  onChange={(e) => set_off_the_shelf_date(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label className="font-size-12px text-danger col-form-label">
              *Enrollment validity: until 7 days before the expiry date.
            </label>
          </Row>
        </ShadowContainer>

        <ShadowContainer>
          <Row className="mb-3 align-items-center">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Does this course offer certificate?
            </label>
            <Col sm={2}>
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name={"certificate_offered"}
                    value={"Yes"}
                    checked={certificate_offered === "Yes"}
                    onChange={(e) => set_certificate_offered(e.target.value)}
                    disabled={isArchived()}
                />
                <label className="form-check-label">Yes</label>
              </div>
            </Col>
            <Col sm={2} className="d-flex align-items-center">
              <div className="form-check me-2">
                <input
                    className="form-check-input"
                    type="radio"
                    name={"certificate_offered"}
                    value={"No"}
                    checked={certificate_offered === "No" ? true : certificate_offered === null ? true : false}
                    onChange={(e) => set_certificate_offered(e.target.value)}
                    disabled={isArchived()}
                />
                <label className="form-check-label">No</label>
              </div>
            </Col>
            <label className="font-size-12px text-danger col-form-label">
              *The company name, participant name, and course title are shown based on the selected course master language.
            </label>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col>
             <Button variant={"secondary"} type={"button"} onClick={handlePreview}>
                Preview certificate
              </Button>
            </Col>
          </Row>
        </ShadowContainer>

        {role === 'Association'
        ?
        <ShadowContainer>
          <Row className="mb-3">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Published Course
            </label>
            <Col sm={2} className="d-flex align-items-center">
              <div className="form-check">
                <input
                className="form-check-input" type="radio" name={"is_public"} value={"False"}
                       checked={is_public === "False"}
                       onChange={(e) => set_is_public(e.target.value)}
                />
                <label className="form-check-label">Members Only</label>
              </div>
            </Col>
            <Col sm={2} className="d-flex align-items-center">
              <div className="form-check me-2">
                <input 
                className="form-check-input" type="radio" name={"is_public"} value={"True"}
                       checked={is_public === "True"}
                       onChange={(e) => set_is_public(e.target.value)}
                />
                <label className="form-check-label">Public</label>
              </div>
            </Col>
          </Row>
        </ShadowContainer>
        :
        ''
        }

        <div
          className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
          style={{ left: 240 }}
        >
          <div className={"d-flex"}>
            <div className={"me-3"}>
              <Button
                className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                type={"submit"}
              >
                {course_id ? 'Save' : 'Create'}
              </Button>
            </div>
            <div className={"me-3"}>
              <Button variant={"secondary"} type={"button"} onClick={()=>navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div style={{height:100}}></div>
    </LayoutCms>
  );
}
