import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { getApiClient } from "../util/auth";
import { removeTokens } from "../util/auth";
import CopyRightLogin from "../components/CopyRightLogin";
import { useTranslation } from "react-i18next";

export default function Login(props) {
  removeTokens();
  const [formValues, setFormValues] = useState({ username: '', password: '' });
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const api = getApiClient();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValues.username || !formValues.password) {
      return alert(t("Please enter username and password"));
    }

    api.post(`/auth/login`, { ...formValues })
      .then(function (response) {
        const data = response.data;
        if (data.auth && data.token && data.user) {
          //login success
          localStorage.setItem("auth", JSON.stringify(data.auth));
          localStorage.setItem("token", data.token);
          localStorage.setItem("role", data.role);
          localStorage.setItem("user", JSON.stringify(data.user));

          // Navigate to different pages depending on user.Role
          switch (data.role) {
            case "Individual Learner":
              return navigate("/profile");
            case "Organization Learner":
              return navigate("/profile-company");
            case "Organization":
              return navigate("/cms/profile");
            case "Trainer/Academy":
              return navigate("/cms/trainer-profile");
            case "Association":
              return navigate("/cms/association-profile");
            case "Association Learner":
              return navigate("/profile-association");
            default:
              return navigate("/login");
          }
        } else {
          // status code 200 but some data missing for some reason
          alert(t("Unknown server error"));
          return navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          alert(t(error.response.data.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((formValues) => { return { ...formValues, [name]: value } });
  }

  return (
    <div
      className="signup-bg vh-100 d-flex flex-column"
      style={{
        backgroundImage:
          "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png), url(/assets/images/home_background_insights_1.png)",
        backgroundPosition: "0px 5%, 95% 5%, 90% 70%",
        backgroundSize: "auto auto, auto auto, 300px",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundAttachment: "scroll, scroll",
      }}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <Container
          style={{
            maxWidth: "450px",
            boxShadow: "0px 3px 25px #0000001F",
            padding: 40,
          }}
        >
          <Row>
            <Col>
              <div className="text-center mb-3">
                <Link to="/">
                  <img src="/assets/images/logo.png" width={175} alt="logo" />
                </Link>
              </div>
              <div className="d-flex font-size-24px text-4a4a4a mb-3">
                <Link
                  className="flex-grow-1 text-reset text-center text-decoration-none"
                  to="/signup"
                  style={{ borderBottom: "2px solid #dee2e6" }}
                >
                  {t('Sign Up')}
                </Link>
                <Link
                  className="flex-grow-1 text-reset text-center text-decoration-none"
                  to="/login"
                  style={{ borderBottom: "3px solid #eb6201" }}
                >
                  {t('Log In')}
                </Link>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label fw-bold">{t('Username')}</label>

                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="username"
                    >
                      <i className="bi bi-person" />
                    </span>
                    <input
                      type="text"
                      className="form-control border-start-0"
                      name='username'
                      value={formValues.username}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label fw-bold">{t('Password')}</label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="password"
                    >
                      <i className="bi bi-lock" />
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control border-start-0"
                      name='password'
                      defaultValue={formValues.password}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                    <span
                      className="input-group-text bg-white"
                      id="eye"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    >
                      <i className={passwordShown ? "bi bi-eye-slash" : "bi bi-eye"} />
                    </span>
                  </div>
                </div>
                <div className="mb-3 form-check d-flex justify-content-center">
                  <div>
                    <input
                      className="form-check-input border-color-eb6201"
                      type="checkbox"
                      value=""
                      id="keep-login"
                    />
                    <label className="form-check-label" htmlFor="keep-login">
                      {t('keep me logged in')}
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className={"w-100 btn bg-eb6201 text-white fw-bold"}
                >
                  {t('LOG IN')}
                </button>
              </form>
            </Col>
          </Row>
          <Row className="text-4a4a4a mt-3">
            <Col>
              <Link to="/forgot-password" className={"text-reset"}>
                {t('Forgot password')}?
              </Link>
            </Col>
            <Col className="text-end">
              <Link to="/forgot-username" className={"text-reset"}>
                {t('Forgot username')}?
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <CopyRightLogin></CopyRightLogin>
    </div>
  );
}
