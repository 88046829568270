import { map } from "lodash";
import React from "react";

export default function IndustrySelected(props){
    const lang = props.lang ?? 'EN';
    const industries = props.industries;
    const ind_json = [
        {
          "key": "Arts, entertainment and recreation",
          "EN": "Arts, entertainment and recreation",
          "TC": "藝術、娛樂及康樂活動"
        },
        {
          "key": "Catering",
          "EN": "Catering",
          "TC": "餐飲業"
        },
        {
          "key": "Construction",
          "EN": "Construction",
          "TC": "建造業"
        },
        {
          "key": "Education",
          "EN": "Education",
          "TC": "教育業"
        },
        {
          "key": "Elderly Care & Service Centre",
          "EN": "Elderly Care & Service Centre",
          "TC": "安老院舍和安老服務業"
        },
        {
          "key": "Electricity and gas supply",
          "EN": "Electricity and gas supply",
          "TC": "電力及燃氣供應"
        },
        {
          "key": "Financial and insurance",
          "EN": "Financial and insurance",
          "TC": "金融及保險業"
        },
        {
          "key": "Food and beverage",
          "EN": "Food and beverage",
          "TC": "食品與飲料業"
        },
        {
          "key": "Health and social work ",
          "EN": "Health and social work ",
          "TC": "保健及社會工作業"
        },
        {
          "key": "Hotel ",
          "EN": "Hotel ",
          "TC": "酒店業"
        },
        {
          "key": "Import/export, wholesale and retail trades",
          "EN": "Import/export, wholesale and retail trades",
          "TC": "進出口貿易、批發及零售業業"
        },
        {
          "key": "Information and communications",
          "EN": "Information and communications",
          "TC": "資訊及通訊業"
        },
        {
          "key": "Innovation Technology",
          "EN": "Innovation Technology",
          "TC": "創新科技業"
        },
        {
          "key": "Manufacturing",
          "EN": "Manufacturing",
          "TC": "製造業"
        },
        {
          "key": "NGO",
          "EN": "NGO",
          "TC": "非牟利機構"
        },
        {
          "key": "Professional, business Consulting ",
          "EN": "Professional, business Consulting ",
          "TC": "專業、業務諮詢業"
        },
        {
          "key": "Real estate and property management",
          "EN": "Real estate and property management",
          "TC": "地產、物業管理業"
        },
        {
          "key": "Social enterprise",
          "EN": "Social enterprise",
          "TC": "社會企業"
        },
        {
          "key": "Transportation, storage and courier services",
          "EN": "Transportation, storage and courier services",
          "TC": "運輸、倉庫及速遞服務業"
        },
        {
          "key": "Tourism ",
          "EN": "Tourism ",
          "TC": "旅遊業"
        },
        {
          "key": "Waste management & Recycling",
          "EN": "Waste management & Recycling",
          "TC": "廢棄物管理及回收業"
        },
        {
          "key": "Service",
          "EN": "Service",
          "TC": "服務業"
        },
        {
          "key": "Others ",
          "EN": "Others ",
          "TC": "其他行業"
        }
       ];

    return ind_json.map((i)=><option value={i.key} selected={industries.includes(i.key)}>{i[lang]}</option>);
}