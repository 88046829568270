import React, {useEffect, useState} from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import {getApiClient} from "../util/auth";
import EyeIcon from "./EyeIcon";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

const course_code_column = {
    name: t('Course Code'),
    selector: row => row.Course_Code,
    sortable: true,
};
const course_title_column = {
    name: t('Course Title'),
    cell: (row) => {
        const courseName = JSON.parse(row.Course_Name);
        return (<div>
            <div>{courseName.EN}</div>
            <div>{courseName.TC}</div>
            <div>{courseName.SC}</div>
        </div>);
    },
};
const date_taken_column = {
    name: t('Date Taken'),
    selector: row => row.Taken_Date,
    format: row => row.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '',
    sortable: true,
};
const completion_date_column = {
    name: t('Completion Date/ Date Completed'),
    selector: row => row.Completed_Date,
    format: (row) => {
        if (row?.Complete_Status !== 'Finished') {
            return '';
        }

        return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
    },
    sortable: true,
};
const certificate_column = {
    name: t('Certificate'),
    selector: row => row.Certificate_URL,
    format: (row) => {
        if(row.Certificate_URL === null || row.Certificate_URL === "") {
            return '';
        }

        return <>
            <Button 
                className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
                type="button" 
                onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
            >
                Print
            </Button>
        </>
    },
    sortable: true,
};

export {
    course_code_column,
    course_title_column,
    date_taken_column,
    completion_date_column,
    certificate_column,
};

export default function AssignedCoursesDatatable(props) {
    const { t } = useTranslation();

    const [view_all_assigned_courses_response, set_view_all_assigned_courses_response] = useState();

    const columns = [
        {
            name: t('Course Code'),
            selector: row => row.Course_Code,
            sortable: true,
        },
        {
            name: t('Course Title'),
            cell: (row) => {
                const courseName = JSON.parse(row.Course_Name);
                return (<div>
                    <div>{courseName.EN}</div>
                    <div>{courseName.TC}</div>
                    <div>{courseName.SC}</div>
                </div>);
            },
        },
        {
            name: t('Date Taken'),
            selector: row => row.Taken_Date,
            format: row => row.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: t('Completion Date/ Date Completed'),
            selector: row => row.Completed_Date,
            format: (row) => {
                if (row?.Complete_Status !== 'Finished') {
                    return '';
                }
        
                return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
            },
            sortable: true,
        },
        {
            name: t('Quiz'),
            cell: (row) => {
                if (!row?.Quiz_Result) {
                    return '';
                }
                return (
                    <div>
                        <div>{row.Correct_Percentage}% {t(row.Quiz_Result)}</div>
                    </div>
                );
            },
        },
        {
            name: t('Certificate'),
            selector: row => row.Certificate_URL,
            format: (row) => {
                if(row.Certificate_URL === null || row.Certificate_URL === "") {
                    return '';
                }

                return <>
                    <Button 
                        className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
                        type="button" 
                        onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
                    >
                        {t('Print')}
                    </Button>
                </>
            },
            sortable: true,
        },
        {
            name: t('Watch again'),
            cell: (row) => {
                let expired = false;
                const now = new Date();
                const expiryDate = new Date(row?.Off_The_Shelf_Date)
                if( now >= expiryDate){
                    console.log(' This course has EXPIRED');
                    expired = true;
                }

                return (
                    <>
                        <span title={t("Watch again")}>
                            <button
                                className={"btn"}
                                onClick={() => {
                                    if(expired){
                                        alert(t('This course has expired.'));
                                    }else{
                                        if (props?.on_watch_again_clicked) {
                                            props.on_watch_again_clicked(row);
                                        }
                                    }
                                }}
                            >
                                <EyeIcon />
                            </button>
                        </span>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApiClient().post('/user/viewAllAssignedCoursesDetails')
            .then((response) => {
                set_view_all_assigned_courses_response(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <DataTable
                columns={columns}
                data={view_all_assigned_courses_response?.learner_courses_details_list ?? []}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[30,50,100]}
                noDataComponent={t('There are no records to display')}
            />
        </>
    );
}
