export default function H2(props) {
  const style = {
    lineHeight: "48px",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: "900",
  };

  return (
    <div style={{ ...style, ...props.style }} className={`${props.className} font-size-24px font-size-md-48px`}>
      {props.children}
    </div>
  );
}
