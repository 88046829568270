import React, {useEffect, useState} from "react";
import {getApiClient} from "../../util/auth";
import {getTextByCode} from "../../util/lang";
import { useTranslation } from "react-i18next";
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function ClassItem(props){
    const { t } = useTranslation();

    const classItem = props.classItem;
    const class_id = classItem.id;
    const module_id = classItem.ModuleId;
    const viewingClass = props.viewingClass;
    const viewed_class_responses = props.viewed_class_responses;
    const course_id = classItem.CourseId;
    const [finished, set_finished] = useState(false);

    useEffect(() => {
        set_finished(viewed_class_responses?.[module_id]?.viewed_class_id.includes(class_id));
    }, [props.viewed_class_responses]);

    const hightlight = classItem.id === viewingClass.id && 'bg-f2f2f2';
    const tickboxBgColor = finished ? "#eb6201" : "#ffffff";

    const markClassAsFinished = () => {
        const params = {
            course_id: course_id,
            module_id: module_id,
            class_id: class_id,
        }
        getApiClient().post('/user/saveViewClassStatus', params)
            .then((response)=>{
                set_finished(true);

                if (props.saved_view_class_status) {
                    props.saved_view_class_status();
                }
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    }

    return <div
        className={`text-4a4a4a p-3 ${hightlight} cursor-pointer`}
        style={
            classItem?.status === "Done"
                ? { opacity: 0.5 }
                : {}
        }
    >
        <div onClick={()=>props.handleSelectClass(classItem.id)}
             style={hightlight ? {fontWeight:'bold'}: {}}>
            {t('Class')} {classItem?.Class_Sequence}: {getTextByCode(classItem?.Class_Name, CURRENT_LANGUAGE)}
        </div>
        <div>
            <label className={"form-check-label me-2"}>
                {finished ? t('Finished') : t('Pending')}
            </label>

            <input
                className="form-check-input"
                checked={finished}
                type="checkbox"
                style={{
                    backgroundColor: tickboxBgColor,
                    borderColor: "#eb6201",
                }}
                onChange={(event) => {
                    event.preventDefault();

                    if (!finished) {
                        markClassAsFinished();
                    }
                }}
            />
        </div>
    </div>;
}
