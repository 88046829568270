import { Link } from "react-router-dom";
import React from "react";

export default function TermsAndConditionTrainerComponent(props) {
    if(props?.lang === "TC") {
        return (
            <div>
                <p><strong>用戶協議&ndash;內容提供者</strong></p>
                <p style={{ textAlign: "justify" }}><strong>更新於2022年5月13日</strong></p>
                <p style={{ textAlign: "justify" }}>本Lightshare網站（下稱&ldquo;<strong>網站</strong>&rdquo;）由註冊辦公地址位於香港北角渣華道191號嘉華中心19樓的香港品質保證局（下稱&ldquo;<strong>HKQAA</strong>&rdquo;或&ldquo;<strong>我們</strong>&rdquo;）經營。</p>
                <p style={{ textAlign: "justify" }}>HKQAA向用戶提供本網站所有資料、工具及服務（下稱&ldquo;<strong>服务</strong>&rdquo;），惟您須接受本網站所載明的所有條款、條件、政策及通知。</p>
                <p style={{ textAlign: "justify" }}>本網站是一個為用戶提供在線課程託管和教育資料流通的綜合服務平台。此外， HKQAA亦會不時提供額外服務，包括但不限於求職配對服務、課程推廣及其他相關服務。</p>
                <p style={{ textAlign: "justify" }}><strong>在訪問或使用本網站前，請仔細閱讀本用戶協議。您在訪問或使用本網站時，即表示同意接受本用戶協議。</strong></p>
                <p style={{ textAlign: "justify" }}><strong>一般條款</strong></p>
                <p style={{ textAlign: "justify" }}>我們保留隨時以任何理由拒絕任何人使用我們網站或服務的權利。您理解，您提供的任何信息可能在未加密的情況下傳輸，並涉及(a)通過各種網絡傳輸; 以及(b)為符合和適應連接網絡或設備的技術要求而進行的更改。如果HKQAA 在網站接受信用卡付款，您的信用卡信息在網絡傳輸過程中將會被加密。您的個人信息和您提供的任何個人資料的處理受我們隱私政策的約束，詳見<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隱私和政策</a>。</p>
                <p style={{ textAlign: "justify" }}>本協議中使用的標題僅為方便而設，不會限製或以其他方式影響本用戶協議。</p>
                <p style={{ textAlign: "justify" }}><strong>服務變更</strong></p>
                <p style={{ textAlign: "justify" }}>我們保留隨時修改或中斷服務（或其任何部分或內容）而不需通知用戶的權利。</p>
                <p style={{ textAlign: "justify" }}>對於服務的任何修改、價格變動、暫停或中斷，我們不對您或任何第三方承擔責任。</p>
                <p style={{ textAlign: "justify" }}><strong>註冊</strong></p>
                <p style={{ textAlign: "justify" }}>如欲參加本網站的活動，您必須在本網站向HKQAA註冊帳戶。您負有完全責任保護您的賬戶安全。您還應對使用您的賬戶或與您的賬戶有關的所有活動負完全責任，並同意在發生任何未經授權的訪問或使用您的賬戶時立即通知我們。您承認並同意，我們不對任何與未經授權訪問或使用您賬戶相關的損害、損失、成本和開支或責任負責。</p>
                <p style={{ textAlign: "justify" }}>您同意按照我們在註冊時的提示，提供有關您或您所代表的實體的真實、準確、最新和完整的信息。在我們網站上創建帳戶時，您不得冒充他人或以其他方式提供虛假信息。如果您代表一家公司、組織或法律實體，或以其他方式代表其他人進行註冊，則您聲明並保證您有權使該等實體或個人受到本用戶協議和所有適用的附加條款的約束。如果我們有理由相信您在註冊時向我們提供的信息不真實、不准確、非最新或不完整，我們有權暫停您使用本網站。我們可能會要求您提供如商業登記文件等證明文件。您理解，如果您未能按要求提供此類文件，您將無法在本網站註冊帳戶。</p>
                <p style={{ textAlign: "justify" }}><strong>我們的服務</strong></p>
                <p style={{ textAlign: "justify" }}>我們保留權利但沒有義務將我們的服務全部或部分限定於任何個人、地理區域或司法管轄區域。我們可以根據具體情況行使這項權利。雖然本網站可在全球範圍內訪問，但本網站上提供的功能、服務並非都可向所有人或所有地理位置提供。您進一步承認並同意，如發生任何意外或非計劃停機，或因任何理由，包括但不限於系統故障和技術干擾，導致部分或全部服務不可使用，在此期間，您訪問和使用本網站以及我們的服務可能會被暫停。 HKQAA有權因定期維護或其他原因而在所安排的停止服務時間暫停使用本網站或提供服務，而毋須向您承擔任何責任。</p>
                <p style={{ textAlign: "justify" }}>我們有權在不另行通知您的情況下，隨時對有關我們的服務和託管費用（定義如下）的所有說明進行更改。我們保留在不另行通知您的情況下隨時終止任何服務的權利。</p>
                <p style={{ textAlign: "justify" }}>HKQAA並不保證所提供或顯示的任何資料的可用性、準確性、完整性、可靠性或及時性。如果您選擇使用HKQAA的服務，您須對您依賴任何此類數據或信息承擔責任。您應定期備份所有提供給 HKQAA的內容，包括課程內容。在任何情況下，HKQAA對網站內任何內容或課程內容的刪除或遺失概不負責。</p>
                <p style={{ textAlign: "justify" }}><strong>課程內容/用戶生成內容</strong></p>
                <p style={{ textAlign: "justify" }}>本網站允許您上傳、提交、存儲、發送以及傳輸內容和數據，包括但不限於您的在線課程視頻、課程材料、宣傳材料(合稱&ldquo;<strong>課程內容</strong>&rdquo;)。雖然您有權自行設計每一課程內容的一般流程及內容，但課程內容必須採用 HKQAA不時指示的可接受格式，並須符合我們的“可接受使用政策”。。</p>
                <p style={{ textAlign: "justify" }}>當您向我們的網站或通過我們的網站上傳、提交、存儲、發送或傳輸任何課程內容，即表示您以所有方式和媒介授予我們對無論現在已知或以後的發明或設計（包括但不限於社交媒體渠道、第三方網站和平台）非排他性的、免版稅的、無限的、普遍的、可轉授權的、長期的、不可撤銷的權利，即我們有權複製、許可、分發、修改、改編、公開表演、公開展示、創作衍生作品，以及為了向最終用戶播放或傳播課程內容，宣傳、營銷和推廣我們的網站和服務的目的使用您的課程內容。並且您同意，為了包括但不限於推廣和宣傳課程內容、本網站以及為課程參與者頒發證書之目的，該等課程內容可與任何其他元素、材料、宣傳版權或版權材料關聯使用，包括表演或展示。該等授權於您向HKQAA提交課程內容時生效，並於您或HKQAA從網站上刪除該等課程內容時終止。除法律禁止外，為改善用戶體驗及維護網站，您承認並同意HKQAA可保留該等課程內容的檔案副本。</p>
                <p style={{ textAlign: "justify" }}>您特此確認並同意，HKQAA僅作為您向用戶提供課程內容的推動者及平台提供者。任何課程內容所表達的觀點及意見並不必然反映HKQAA的意見。特別需要說明的是，本網站的任何內容（包括課程內容）均不應被解釋為法律、稅務、投資、財務或其他專業建議。 HKQAA在本網站上載課程內容並不意味著HKQAA已獲任何第三者認可、贊助或推薦，本網站的任何資料亦不構成對所討論事項或有關法律的全面、完整或準確的陳述。以商品名稱、商標、製造商或其他方式提及任何產品、服務或流程，並不構成或暗示HKQAA的認可、推薦或偏愛。您須自行評估使用HKQAA或課程內容所提供的任何資料的優點及風險。</p>
                <p style={{ textAlign: "justify" }}>HKQAA如認為課程內容違反以下“可接受使用政策”，則有權刪除該等課程內容。</p>
                <p style={{ textAlign: "justify" }}>您可以在任何時間因任何原因從網站上撤回任何或全部課程內容。</p>
                <p style={{ textAlign: "justify" }}><strong>可接受使用政策</strong></p>
                <p style={{ textAlign: "justify" }}>在您上傳、提交、存儲、發送或傳輸課程內容時，您同意在本網站上的所有課程內容和您的行為應符合我們以下“可接受使用政策”。 HKQAA有權（但無義務）監督及審查您使用本網站時的帳戶、內容及行為。 HKQAA如發現您的課程內容或行為違反本用戶協議，有權刪除您任何課程內容或暫停您訪問本網站。</p>
                <p style={{ textAlign: "justify" }}>您和/或您的課程內容不得:</p>
                <ol>
                    <li>侵犯他人或實體的任何專利、商標、商業秘密、版權或其他知識產權；</li>
                    <li>違反與第三方的合同或信託協議；</li>
                    <li>冒充任何人或實體，或虛假陳述或以其他方式歪曲您與某個人或實體的關係；</li>
                    <li>含有性暴露內容或推廣性愛服務；</li>
                    <li>含有誹謗言論；</li>
                    <li>含有仇恨或歧視性言論；</li>
                    <li>促進或支持任何恐怖或仇恨團體；</li>
                    <li>含有關於如何裝配爆炸裝置或簡易火器的說明；</li>
                    <li>剝削或者危害未成年人；</li>
                    <li>描述或鼓勵自殘或自殺；</li>
                    <li>描述任何暴力行為，包括但不限於虐待動物和酷刑；</li>
                    <li>推行欺詐性或可疑的賺錢計劃，提出任何非法交易或使用欺騙性營銷手段；</li>
                    <li>包含任何虛假或誤導性信息；或</li>
                    <li>違反任何所適用的法律。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>除上述規定外，您在此同意，您在使用本網站時不得:</p>
                <ol>
                    <li>複製、修改、改編、翻譯、反向工程、反彙編、反編譯或從我們的服務中創建衍生作品或分發任何內容；</li>
                    <li>為本用戶協議未明確授權的任何目的更改、刪除、篡改、複製、展示或使用我們的商標及HKQAA擁有的其他知識產權；</li>
                    <li>使用我們的服務進行任何未經 HKQAA授權的創收活動，或其他超出HKQAA與您簽訂協議範圍的活動；</li>
                    <li>提交、分享、張貼、交流或以其他方式採取任何煽動或鼓勵明顯或隱含非法、辱罵、騷擾、威脅、仇恨、種族主義、貶損、損害任何名譽、色情、不雅、褻瀆、淫穢或其他令人反感的行為（包括裸體）的行動；</li>
                    <li>在軟件使用過程中，從事搜索、收集或參與使用旨在從我們的網站（包括從我們的網站的任何用戶）收集數據的軟件（包括間諜軟件）；</li>
                    <li>傳播任何病毒、其他計算機指令或有意或可能中斷、損壞或乾擾計算機或相關係統使用的技術手段；</li>
                    <li>跟踪、騷擾、威脅或傷害任何第三方；</li>
                    <li>冒充任何第三方；</li>
                    <li>參與任何欺詐或非法活動，包括但不限於網絡釣魚、洗錢或欺詐；</li>
                    <li>通過訪問我們的網站獲取信息，以建立類似或競爭性的網站、應用程序或其他內容;</li>
                    <li>將本網站用於任何非法或未經授權，或違反任何所適用的法律（包括但不限於版權和隱私法）或侵犯其他第三方權利的目的；</li>
                    <li>使用任何HTML技術，通過框架、視窗、彈出窗口、彈出式廣告或任何其他非標準鏈接方式顯示本網站，建立鏈接至本網站或轉載本網站的任何內容，除非HKQAA明確授權；或</li>
                    <li>提倡、鼓勵或協助任何第三方從事上述任何行為。</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>费用</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>託管費</span>。您確認並同意 HKQAA於上載課程時開始收取託管費。在事先通知您的情況下，HKQAA有權更改託管費及付款方式。為免生疑問，HKQAA不會就上載課程內容收取費用，只有當網站會員報名參加您所提供的課程時才會收取費用。所有應付予HKQAA的費用，均須按HKQAA與您所議定的付款條款即時繳付。</p>
                <p style={{ textAlign: "justify" }}>所有給HKQAA的付款均以港元支付，一經支付概不退還。 HKQAA概不負責任何捐贈、付款、稅款或向任何第三方付款，所有此類費用應由您單獨承擔。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>支付豁免</span>。 您確認，您無權就上載任何課程內容至HKQAA而向HKQAA索取任何費用、版稅、佣金或任何形式的款項。您特此放棄就在本網站分發課程內容而向HKQAA索取任何版稅、收益或酬金的權利。</p>
                <p style={{ textAlign: "justify" }}>對於因製作課程內容或向HKQAA提交課程內容而產生的任何費用、開支或其他責任，HKQAA概不負責。</p>
                <p style={{ textAlign: "justify" }}><strong>第三方内容和交流</strong></p>
                <p style={{ textAlign: "justify" }}>本網站可能包含某些特性和功能鏈接到第三方內容或為您提供第三方內容的訪問，這些第三方內容與我們完全無關。我們不負責審查或評估該等內容或其準確性，我們不對任何第三方材料或網站，或第三方任何其他材料、產品或服務作出保證，亦不承擔任何責任。在與本網站或其他網站進行任何联系或互動前，請您自行獨立判斷。</p>
                <p style={{ textAlign: "justify" }}>對於您購買或使用與任何第三方網站相關的商品、服務、資源、內容或任何其他交易，我們不承擔任何責任。在您進行任何交易前，請仔細審查第三方的政策和慣例，並確保您了解這些政策和慣例。對於第三方產品的投訴、索賠、關注或問題應直接向第三方提出。</p>
                <p style={{ textAlign: "justify" }}><strong>用戶評論、反饋和其他建議</strong></p>
                <p style={{ textAlign: "justify" }}>如果經我們請求，您發送某些具體的建議（例如參賽作品），或您未經我們請求而發送創意、建議、計劃或其他材料（合稱&ldquo;<strong>評論</strong>&rdquo;），無論通過電子郵件、郵政郵件或者其他形式，您同意，我們可以在任何時候編輯、複製、發布、分發、翻譯或以其他方式在任何媒體上使用您轉發給我們的任何評論而不受到任何限制。我們沒有義務：（1）對任何評論進行保密；（2）因任何評論而承擔賠償責任；或（3）回應任何評論。對於我們認為帶有非法、攻擊、威脅、誹謗、污衊、色情、淫穢性質或其他令人反感或侵犯任何一方知識產權或本用戶協議的評論，我們有權利但沒有義務監控、編輯或刪除全部或部分評論。</p>
                <p style={{ textAlign: "justify" }}>您同意您的評論不會侵犯任何第三方的權利，包括著作權、商標、隱私、人格或其他個人或專有權利。您進一步同意，您的評論不包含誹謗性的或非法的、辱罵性的或淫穢的內容，也不包含可能以任何方式影響我們服務運行或任何相關網站運行的計算機病毒或其他惡意軟件。您不可以使用虛假的電子郵件地址，假裝成別人而不是你自己，或以其他方式就評論的來源誤導我們或第三方。您對您所發表的任何評論及其準確性負全部責任。我們對您或任何第三方發布的任何評論不負任何責任。</p>
                <p style={{ textAlign: "justify" }}><strong>個人數據保護</strong></p>
                <p style={{ textAlign: "justify" }}>我們非常重視您的隱私。您通過本網站提交的個人數據受我們隱私政策的約束，該政策可通過<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隐私和政策</a>訪問。</p>
                <p style={{ textAlign: "justify" }}><strong>知識產權</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>所有權</span>。 除課程內容外，HKQAA對本網站及本網站“界面外觀”，包括所有軟件、創意、程序、數據、文本、媒體、域名、商業秘密、商標、標誌、版權及所有其他知識產權 （下稱“HKQAA內容”），擁有所有權利及利益。本網站受香港法律及適用的國際法律保護。未經我們事先書面同意，您不得複製或重複使用HKQAA內容的任何部分。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>HKQAA商標的使用</span>。 僅在宣傳及推廣課程內容或參與本網站的活動期間，HKQAA授予您使用HKQAA商標的有限許可。您只能以參考的方式使用這些商標，而不能將這些商標用作任何材料中最顯著的視覺元素。您不得直接或間接暗示HKQAA對您、您的課程內容或您所進行的任何活動進行贊助、認可或雙方存在從屬關係。</p>
                <p style={{ textAlign: "justify" }}><strong>投訴和糾紛處理</strong></p>
                <p style={{ textAlign: "justify" }}>您同意，如收到投訴，HKQAA有權取消或暫停提供任何課程內容。在收到有關您或您課程內容的投訴後，HKQAA可暫停訪問您在網站上的特定課程內容或您的帳戶，以待HKQAA作出決定。 HKQAA會立即將投訴轉給您，您須盡最大努力配合HKQAA所進行的調查。 HKQAA對所有投訴有最終決定權。</p>
                <p style={{ textAlign: "justify" }}><strong>獨立合同方</strong></p>
                <p style={{ textAlign: "justify" }}>本用戶協議所載的任何內容均不得視為或解釋為您與HKQAA建立合資或合作關係。任何一方不得被視為根據本用戶協議授權其為任何其他方的代理人、僱員或法律代表。任何一方均無權控制任何其他方的活動和經營，其地位是並且在任何時候都將繼續是獨立訂約人的地位。未經另一方事先書面授權，任何一方均無權約束或委託另一方。任何一方均不得聲稱自己具有違反本條規定的任何權力或關係。</p>
                <p style={{ textAlign: "justify" }}>您與HKQAA是非排他性的關係，即您可向第三者提供類似的服務，並可從事其他商業活動。但您承認並同意，在本用戶協議有效期內，您向 HKQAA提交的所有課程內容均是獨家內容。未經HKQAA明確同意，您不得在其他平台或網站上提供、分發、出售或以其他方式傳播課程內容（無論免費或付費）。</p>
                <p style={{ textAlign: "justify" }}><strong>免責條款；責任限制</strong></p>
                <p style={{ textAlign: "justify" }}>我們不保證您對我們網站的使用將是不間斷、及時、安全或無錯誤。我們不保證本網站提供的信息和內容是準確或可靠的。您同意，我們有權隨時無限期地刪除本網站或終止提供任何服務，無需另行通知您。</p>
                <p style={{ textAlign: "justify" }}>您明確同意，您使用或無法使用本網站的風險由您自行承擔。本網站或通過本網站提供給您的服務（除非我們明確表示）如提供的是“和”和“可用”，即表明在法律允許的範圍內，不包含任何明示或默示的任何類型的聲明、保證或條件，包括所有默示的適銷性、適銷性質量、特定用途的適用性、持久性、所有權和不侵權的保證或條件。</p>
                <p style={{ textAlign: "justify" }}>您承認並同意，在法律許可的範圍內，HKQAA 將不會就下列情況承擔責任：(i)內容錯誤或不准確；(ii)由於您訪問或使用我們的網站而造成的人身傷害、財產損害或其他損害；(iii)未經授權訪問或使用我們的服務器、任何個人信息或用戶數據：(iv)向本網站傳播或從本網站傳播的中斷；(v)任何可能在本網站或通過本網站傳播的漏洞、病毒、特洛伊木馬或類似病毒; 或(vi)因本網站任何內容或使用本網站發布或共享的任何內容而產生的任何類型的損害、損失、成本、開支或責任。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>間接損失</span>在適用法律允許的最大範圍內，您承認並同意，在任何情況下，HKQAA不會對您或任何第三方因本協議而引致或與本協議有關的任何直接的、特殊的、偶然性的、懲罰性的或間接性損失（包括利潤損失、收入損失或數據損失）或獲得替代性產品的成本承擔賠償責任，無論該等賠償責任如何引致，無論該等賠償責任是否基於合約、保證、侵權（包括疏忽）、嚴格責任或其他原因而引致，以及無論HKQAA是否已被告知該等損害賠償的可能性。</p>
                <p style={{ textAlign: "justify" }}><strong>責任豁免</strong></p>
                <p style={{ textAlign: "justify" }}>如果您與一個或多個用戶或其他第三方之間存在爭議，您免於我們（和我們的附屬公司、人員、董事、僱員和代理人）遭受因該爭議引起的或與該爭議有關的所有類型的、已知和未知的索賠、要求和損害賠償（包括直接的和間接的損害賠償）。在達成本豁免條款時，您明確放棄將本豁免的覆蓋範圍限制為僅包括您在同意本豁免條款時可能知道或懷疑存在對您有利的主張的任何保護（無論是法定的還是其他的保護）。</p>
                <p style={{ textAlign: "justify" }}><strong>損害賠償</strong></p>
                <p style={{ textAlign: "justify" }}>您同意賠償和使HKQAA及其母公司、附屬公司、聯屬公司、合夥人、高級職員、董事、代理人、承辦商、授權人、服務供應商、分包商、供應商、實習生及僱員免於遭受任何因您違反本用戶協議或其引用包含的文件，或您違反任何法律或侵犯第三方權利而由任何第三方提出的索賠或要求（包括律師費）之損害。</p>
                <p style={{ textAlign: "justify" }}><strong>獨立性</strong></p>
                <p style={{ textAlign: "justify" }}>如果本用戶協議的任何條款被認定為非法、無效或不可執行，則該等條款仍應在適用法律允許的最大範圍內可執行，且不可執行的部分應被視為與本用戶協議分離，該認定不應影響任何其他剩餘條款的有效性和可執行性。</p>
                <p style={{ textAlign: "justify" }}><strong>終止</strong></p>
                <p style={{ textAlign: "justify" }}>雙方在本協議終止日前產生的義務和責任在本協議終止後仍然有效。</p>
                <p style={{ textAlign: "justify" }}>本用戶協議在您或我們終止之前有效。您可隨時通過通知我們您不再希望使用我們的服務或停止使用我們的網站而終止本用戶協議。</p>
                <p style={{ textAlign: "justify" }}>如果我們認為您未遵守本用戶協議，我們也有權隨時終止本協議而無需另行通知您。您仍將對截至並包括終止日期的所有到期款項承擔責任；我們可能因此拒絕您訪問我們的服務（或其中的任何部分）。</p>
                <p style={{ textAlign: "justify" }}><strong>棄權</strong></p>
                <p style={{ textAlign: "justify" }}>我們未行使或未執行本用戶協議的任何權利或規定，不構成我們對該等權利或規定的棄權。</p>
                <p style={{ textAlign: "justify" }}><strong>完整協議</strong></p>
                <p style={{ textAlign: "justify" }}>本用戶協議和我們發佈在本網站或與服務有關的任何政策或操作規則構成我們雙方之間的完整協議和理解，取代雙方此前或臨時協議、通信和建議，無論是口頭或書面形式，包括但不限於任何先前版本的用戶協議。</p>
                <p style={{ textAlign: "justify" }}>本用戶協議解釋中的任何歧義不得對起草方造成不利影響。</p>
                <p style={{ textAlign: "justify" }}><strong>可訪問性聲明</strong></p>
                <p style={{ textAlign: "justify" }}>本網站採用無障礙網頁設計，符合萬維網聯盟（W3C）網站內容無障礙指南（WCAG）2.0 Level AA標準。如您對網頁的可訪問性有任何查詢或意見，請通過電話或電郵與我們聯絡。</p>
                <p style={{ textAlign: "justify" }}>電話： +852 2202-9111</p>
                <p style={{ textAlign: "justify" }}>電子郵件地址： <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a></p>
                <p style={{ textAlign: "justify" }}><strong>法律適用</strong></p>
                <p style={{ textAlign: "justify" }}>本用戶協議及我們為您提供服務的任何單獨協議應受香港特別行政區法律管轄並按香港特別行政區法律解釋。任何因本用戶協議而產生或與本用戶協議有關的爭議或索賠，均受香港特別行政區法院的專屬管轄。</p>
                <p style={{ textAlign: "justify" }}><strong>用戶協議條款的變更</strong></p>
                <p style={{ textAlign: "justify" }}>您可隨時在此頁面查看本用戶協議的最新版本。</p>
                <p style={{ textAlign: "justify" }}>我們保留自行更新、更改或替換本用戶協議的權利，並會不時更新及/或更改我們的網站。您有責任定期檢查本網站的變化。在發布本用戶協議的任何更改後，您繼續使用我們的服務或訪問本網站，即表示您接受這些更改。</p>
                <p style={{ textAlign: "justify" }}>我證明我已經閱讀、理解並同意受本用戶協議和隱私政策條款的約束。</p>
            </div>
        );
    } else if(props?.lang === "SC") {
        return (
            <div>
                <p><strong>用户协议&ndash;内容提供者</strong></p>
                <p style={{ textAlign: "justify" }}><strong>更新于2022年5月13日</strong></p>
                <p style={{ textAlign: "justify" }}>本Lightshare网站（下称&ldquo;<strong>网站</strong>&rdquo;）由注册办公地址位于香港北角渣华道191号嘉华中心19楼的香港品质保证局（下称&ldquo;<strong>HKQAA</strong>&rdquo;或&ldquo;<strong>我们</strong>&rdquo;）经营。</p>
                <p style={{ textAlign: "justify" }}>HKQAA向用户提供本网站所有资料、工具及服务（下称&ldquo;<strong>服务</strong>&rdquo;），惟您须接受本网站所载明的所有条款、条件、政策及通知。</p>
                <p style={{ textAlign: "justify" }}>本网站是一个为用户提供在线课程托管和教育资料流通的综合服务平台。 此外， HKQAA亦会不时提供额外服务，包括但不限于求职配对服务、课程推广及其他相关服务。</p>
                <p style={{ textAlign: "justify" }}><strong>在访问或使用本网站前，请仔细阅读本用户协议。您在访问或使用本网站时，即表示同意接受本用户协议。</strong></p>
                <p style={{ textAlign: "justify" }}><strong>一般条款</strong></p>
                <p style={{ textAlign: "justify" }}>我们保留随时以任何理由拒绝任何人使用我们网站或服务的权利。您理解，您提供的任何信息可能在未加密的情况下传输，并涉及(a)通过各种网络传输;以及(b)为符合和适应连接网络或设备的技术要求而进行的更改。如果HKQAA在网站接受信用卡付款，您的信用卡信息在网络传输过程中将会被加密。您的个人信息和您提供的任何个人资料的处理受我们隐私政策的约束，详见<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隱私和政策</a>。</p>
                <p style={{ textAlign: "justify" }}>本协议中使用的标题仅为方便而设，不会限制或以其他方式影响本用户协议。</p>
                <p style={{ textAlign: "justify" }}><strong>服务变更</strong></p>
                <p style={{ textAlign: "justify" }}>我们保留随时修改或中断服务（或其任何部分或内容）而不需通知用户的权利。</p>
                <p style={{ textAlign: "justify" }}>对于服务的任何修改、价格变动、暂停或中断，我们不对您或任何第三方承担责任。</p>
                <p style={{ textAlign: "justify" }}><strong>注册</strong></p>
                <p style={{ textAlign: "justify" }}>如欲参加本网站的活动，您必须在本网站向HKQAA注册帐户。您负有完全责任保护您的账户安全。您还应对使用您的账户或与您的账户有关的所有活动负完全责任，并同意在发生任何未经授权的访问或使用您的账户时立即通知我们。您承认并同意，我们不对任何与未经授权访问或使用您账户相关的损害、损失、成本和开支或责任负责。</p>
                <p style={{ textAlign: "justify" }}>您同意按照我们在注册时的提示，提供有关您或您所代表的实体的真实、准确、最新和完整的信息。在我们网站上创建帐户时，您不得冒充他人或以其他方式提供虚假信息。如果您代表一家公司、组织或法律实体，或以其他方式代表其他人进行注册，则您声明并保证您有权使该等实体或个人受到本用户协议和所有适用的附加条款的约束。如果我们有理由相信您在注册时向我们提供的信息不真实、不准确、非最新或不完整，我们有权暂停您使用本网站。我们可能会要求您提供如商业登记文件等证明文件。您理解，如果您未能按要求提供此类文件，您将无法在本网站注册帐户。</p>
                <p style={{ textAlign: "justify" }}><strong>我们的服务</strong></p>
                <p style={{ textAlign: "justify" }}>我们保留权利但没有义务将我们的服务全部或部分限定于任何个人、地理区域或司法管辖区域。我们可以根据具体情况行使这项权利。虽然本网站可在全球范围内访问，但本网站上提供的功能、服务并非都可向所有人或所有地理位置提供。您进一步承认并同意，如发生任何意外或非计划停机，或因任何理由，包括但不限于系统故障和技术干扰，导致部分或全部服务不可使用，在此期间，您访问和使用本网站以及我们的服务可能会被暂停。HKQAA有权因定期维护或其他原因而在所安排的停止服务时间暂停使用本网站或提供服务，而毋须向您承担任何责任。</p>
                <p style={{ textAlign: "justify" }}>我们有权在不另行通知您的情况下，随时对有关我们的服务和托管费用（定义如下）的所有说明进行更改。我们保留在不另行通知您的情况下随时终止任何服务的权利。</p>
                <p style={{ textAlign: "justify" }}>HKQAA并不保证所提供或显示的任何资料的可用性、准确性、完整性、可靠性或及时性。如果您选择使用HKQAA的服务，您须对您依赖任何此类数据或信息承担责任。您应定期备份所有提供给 HKQAA的内容，包括课程内容。在任何情况下，HKQAA对网站内任何内容或课程内容的删除或遗失概不负责。</p>
                <p style={{ textAlign: "justify" }}><strong>课程内容/用户生成内容</strong></p>
                <p style={{ textAlign: "justify" }}>本网站允许您上传、提交、存储、发送以及传输内容和数据，包括但不限于您的在线课程视频、课程材料、宣传材料(合称&ldquo;<strong>课程内容</strong>&rdquo;)。虽然您有权自行设计每一课程内容的一般流程及内容，但课程内容必须采用 HKQAA不时指示的可接受格式，并须符合我们的“可接受使用政策”。</p>
                <p style={{ textAlign: "justify" }}>当您向我们的网站或通过我们的网站上传、提交、存储、发送或传输任何课程内容，即表示您以所有方式和媒介授予我们对无论现在已知或以后的发明或设计（包括但不限于社交媒体渠道、第三方网站和平台）非排他性的、免版税的、无限的、普遍的、可转授权的、长期的、不可撤销的权利，即我们有权复制、许可、分发、修改、改编、公开表演、公开展示、创作衍生作品，以及为了向最终用户播放或传播课程内容，宣传、营销和推广我们的网站和服务的目的使用您的课程内容。并且您同意，为了包括但不限于推广和宣传课程内容、本网站以及为课程参与者颁发证书之目的，该等课程内容可与任何其他元素、材料、宣传版权或版权材料关联使用，包括表演或展示。该等授权于您向HKQAA提交课程内容时生效，并于您或HKQAA从网站上删除该等课程内容时终止。除法律禁止外，为改善用户体验及维护网站，您承认并同意HKQAA可保留该等课程内容的档案副本。</p>
                <p style={{ textAlign: "justify" }}>您特此确认并同意，HKQAA仅作为您向用户提供课程内容的推动者及平台提供者。任何课程内容所表达的观点及意见并不必然反映HKQAA的意见。特别需要说明的是，本网站的任何内容（包括课程内容）均不应被解释为法律、税务、投资、财务或其他专业建议。HKQAA在本网站上载课程内容并不意味着HKQAA已获任何第三者认可、赞助或推荐，本网站的任何资料亦不构成对所讨论事项或有关法律的全面、完整或准确的陈述。以商品名称、商标、制造商或其他方式提及任何产品、服务或流程，并不构成或暗示HKQAA的认可、推荐或偏爱。您须自行评估使用HKQAA或课程内容所提供的任何资料的优点及风险。</p>
                <p style={{ textAlign: "justify" }}>HKQAA如认为课程内容违反以下“可接受使用政策”，则有权删除该等课程内容。</p>
                <p style={{ textAlign: "justify" }}>您可以在任何时间因任何原因从网站上撤回任何或全部课程内容。</p>
                <p style={{ textAlign: "justify" }}><strong>可接受使用政策</strong></p>
                <p style={{ textAlign: "justify" }}>在您上传、提交、存储、发送或传输课程内容时，您同意在本网站上的所有课程内容和您的行为应符合我们以下“可接受使用政策”。HKQAA有权（但无义务）监督及审查您使用本网站时的帐户、内容及行为。HKQAA如发现您的课程内容或行为违反本用户协议，有权删除您任何课程内容或暂停您访问本网站。</p>
                <p style={{ textAlign: "justify" }}>您和/或您的课程内容不得:</p>
                <ol>
                    <li>侵犯他人或实体的任何专利、商标、商业秘密、版权或其他知识产权；</li>
                    <li>违反与第三方的合同或信托协议；</li>
                    <li>冒充任何人或实体，或虚假陈述或以其他方式歪曲您与某个人或实体的关系；</li>
                    <li>含有性暴露内容或推广性爱服务；</li>
                    <li>含有诽谤言论；</li>
                    <li>含有仇恨或歧视性言论；</li>
                    <li>促进或支持任何恐怖或仇恨团体；</li>
                    <li>含有关于如何装配爆炸装置或简易火器的说明；</li>
                    <li>剥削或者危害未成年人；</li>
                    <li>描述或鼓励自残或自杀；</li>
                    <li>描述任何暴力行为，包括但不限于虐待动物和酷刑；</li>
                    <li>推行欺诈性或可疑的赚钱计划，提出任何非法交易或使用欺骗性营销手段；</li>
                    <li>包含任何虚假或误导性信息；或</li>
                    <li>违反任何所适用的法律。</li>
                </ol>
                <p style={{ textAlign: "justify" }}>除上述规定外，您在此同意，您在使用本网站时不得:</p>
                <ol>
                    <li>复制、修改、改编、翻译、反向工程、反汇编、反编译或从我们的服务中创建衍生作品或分发任何内容；</li>
                    <li>为本用户协议未明确授权的任何目的更改、删除、篡改、复制、展示或使用我们的商标及 HKQAA拥有的其他知识产权；</li>
                    <li>使用我们的服务进行任何未经 HKQAA授权的创收活动，或其他超出HKQAA与您签订协议范围的活动；</li>
                    <li>提交、分享、张贴、交流或以其他方式采取任何煽动或鼓励明显或隐含非法、辱骂、骚扰、威胁、仇恨、种族主义、贬损、损害任何名誉、色情、不雅、亵渎、淫秽或其他令人反感的行为（包括裸体）的行动；</li>
                    <li>在软件使用过程中，从事搜索、收集或参与使用旨在从我们的网站（包括从我们的网站的任何用户）收集数据的软件（包括间谍软件）；</li>
                    <li>传播任何病毒、其他计算机指令或有意或可能中断、损坏或干扰计算机或相关系统使用的技术手段；</li>
                    <li>跟踪、骚扰、威胁或伤害任何第三方；</li>
                    <li>冒充任何第三方；</li>
                    <li>参与任何欺诈或非法活动，包括但不限于网络钓鱼、洗钱或欺诈；</li>
                    <li>通过访问我们的网站获取信息，以建立类似或竞争性的网站、应用程序或其他内容;</li>
                    <li>将本网站用于任何非法或未经授权，或违反任何所适用的法律（包括但不限于版权和隐私法）或侵犯其他第三方权利的目的；</li>
                    <li>使用任何HTML技术，通过框架、视窗、弹出窗口、弹出式广告或任何其他非标准链接方式显示本网站，建立链接至本网站或转载本网站的任何内容，除非HKQAA明确授权；或</li>
                    <li>提倡、鼓励或协助任何第三方从事上述任何行为。</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>费用</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>托管费</span>。您确认并同意 HKQAA于上载课程时开始收取托管费。 在事先通知您的情况下，HKQAA有权更改托管费及付款方式。为免生疑问，HKQAA不会就上载课程内容收取费用，只有当网站会员报名参加您所提供的课程时才会收取费用。所有应付予HKQAA的费用，均须按HKQAA与您所议定的付款条款即时缴付。</p>
                <p style={{ textAlign: "justify" }}>所有给HKQAA的付款均以港元支付，一经支付概不退还。HKQAA概不负责任何捐赠、付款、税款或向任何第三方付款，所有此类费用应由您单独承担。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>支付豁免</span>。 您确认，您无权就上载任何课程内容至HKQAA而向HKQAA索取任何费用、版税、佣金或任何形式的款项。您特此放弃就在本网站分发课程内容而向HKQAA索取任何版税、收益或酬金的权利。</p>
                <p style={{ textAlign: "justify" }}>对于因制作课程内容或向HKQAA提交课程内容而产生的任何费用、开支或其他责任，HKQAA概不负责。</p>
                <p style={{ textAlign: "justify" }}><strong>第三方内容和交流</strong></p>
                <p style={{ textAlign: "justify" }}>本网站可能包含某些特性和功能链接到第三方内容或为您提供第三方内容的访问，这些第三方内容与我们完全无关。我们不负责审查或评估该等内容或其准确性，我们不对任何第三方材料或网站，或第三方任何其他材料、产品或服务作出保证，亦不承担任何责任。在与本网站或其他网站进行任何联系或互动前，请您自行独立判断。</p>
                <p style={{ textAlign: "justify" }}>对于您购买或使用与任何第三方网站相关的商品、服务、资源、内容或任何其他交易，我们不承担任何责任。在您进行任何交易前，请仔细审查第三方的政策和惯例，并确保您了解这些政策和惯例。对于第三方产品的投诉、索赔、关注或问题应直接向第三方提出。</p>
                <p style={{ textAlign: "justify" }}><strong>用户评论、反馈和其他建议</strong></p>
                <p style={{ textAlign: "justify" }}>如果经我们请求，您发送某些具体的建议（例如参赛作品），或您未经我们请求而发送创意、建议、计划或其他材料（合称&ldquo;<strong>评论</strong>&rdquo;），无论通过电子邮件、邮政邮件或者其他形式，您同意，我们可以在任何时候编辑、复制、发布、分发、翻译或以其他方式在任何媒体上使用您转发给我们的任何评论而不受到任何限制。我们没有义务：（1）对任何评论进行保密；（2）因任何评论而承担赔偿责任；或（3）回应任何评论。对于我们认为带有非法、攻击、威胁、诽谤、污蔑、色情、淫秽性质或其他令人反感或侵犯任何一方知识产权或本用户协议的评论，我们有权利但没有义务监控、编辑或删除全部或部分评论。</p>
                <p style={{ textAlign: "justify" }}>您同意您的评论不会侵犯任何第三方的权利，包括著作权、商标、隐私、人格或其他个人或专有权利。您进一步同意，您的评论不包含诽谤性的或非法的、辱骂性的或淫秽的内容，也不包含可能以任何方式影响我们服务运行或任何相关网站运行的计算机病毒或其他恶意软件。您不可以使用虚假的电子邮件地址，假装成别人而不是你自己，或以其他方式就评论的来源误导我们或第三方。您对您所发表的任何评论及其准确性负全部责任。我们对您或任何第三方发布的任何评论不负任何责任。</p>
                <p style={{ textAlign: "justify" }}><strong>个人数据保护</strong></p>
                <p style={{ textAlign: "justify" }}>我们非常重视您的隐私。您通过本网站提交的个人数据受我们隐私政策的约束，该政策可通过<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">隐私和政策</a>访问。</p>
                <p style={{ textAlign: "justify" }}><strong>知识产权</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>所有权</span>。 除课程内容外，HKQAA对本网站及本网站“界面外观”，包括所有软件、创意、程序、数据、文本、媒体、域名、商业秘密、商标、标志、版权及所有其他知识产权 （下称“HKQAA内容”），拥有所有权利及利益。本网站受香港法律及适用的国际法律保护。未经我们事先书面同意，您不得复制或重复使用HKQAA内容的任何部分。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>HKQAA商标的使用</span>。 仅在宣传及推广课程内容或参与本网站的活动期间，HKQAA授予您使用HKQAA商标的有限许可。您只能以参考的方式使用这些商标，而不能将这些商标用作任何材料中最显著的视觉元素。您不得直接或间接暗示HKQAA对您、您的课程内容或您所进行的任何活动进行赞助、认可或双方存在从属关系。</p>
                <p style={{ textAlign: "justify" }}><strong>投诉和纠纷处理</strong></p>
                <p style={{ textAlign: "justify" }}>您同意，如收到投诉，HKQAA有权取消或暂停提供任何课程内容。在收到有关您或您课程内容的投诉后，HKQAA可暂停访问您在网站上的特定课程内容或您的帐户，以待HKQAA作出决定。HKQAA会立即将投诉转给您，您须尽最大努力配合HKQAA所进行的调查。HKQAA对所有投诉有最终决定权。</p>
                <p style={{ textAlign: "justify" }}><strong>独立合同方</strong></p>
                <p style={{ textAlign: "justify" }}>本用户协议所载的任何内容均不得视为或解释为您与HKQAA建立合资或合作关系。任何一方不得被视为根据本用户协议授权其为任何其他方的代理人、雇员或法律代表。任何一方均无权控制任何其他方的活动和经营，其地位是并且在任何时候都将继续是独立订约人的地位。未经另一方事先书面授权，任何一方均无权约束或委托另一方。任何一方均不得声称自己具有违反本条规定的任何权力或关系。</p>
                <p style={{ textAlign: "justify" }}>您与HKQAA是非排他性的关系，即您可向第三者提供类似的服务，并可从事其他商业活动。但您承认并同意，在本用户协议有效期内，您向 HKQAA提交的所有课程内容均是独家内容。未经HKQAA明确同意，您不得在其他平台或网站上提供、分发、出售或以其他方式传播课程内容（无论免费或付费）。</p>
                <p style={{ textAlign: "justify" }}><strong>免责条款；责任限制</strong></p>
                <p style={{ textAlign: "justify" }}>我们不保证您对我们网站的使用将是不间断、及时、安全或无错误。我们不保证本网站提供的信息和内容是准确或可靠的。您同意，我们有权随时无限期地删除本网站或终止提供任何服务，无需另行通知您。</p>
                <p style={{ textAlign: "justify" }}>您明确同意，您使用或无法使用本网站的风险由您自行承担。本网站或通过本网站提供给您的服务（除非我们明确表示）如提供的是“和”和“可用”，即表明在法律允许的范围内，不包含任何明示或默示的任何类型的声明、保证或条件，包括所有默示的适销性、适销性质量、特定用途的适用性、持久性、所有权和不侵权的保证或条件。</p>
                <p style={{ textAlign: "justify" }}>您承认并同意，在法律许可的范围内，HKQAA 将不会就下列情况承担责任：(i)内容错误或不准确；(ii)由于您访问或使用我们的网站而造成的人身伤害、财产损害或其他损害；(iii)未经授权访问或使用我们的服务器、任何个人信息或用户数据：(iv)向本网站传播或从本网站传播的中断；(v)任何可能在本网站或通过本网站传播的漏洞、病毒、特洛伊木马或类似病毒; 或(vi)因本网站任何内容或使用本网站发布或共享的任何内容而产生的任何类型的损害、损失、成本、开支或责任。</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>间接损失</span>。在适用法律允许的最大范围内，您承认并同意，在任何情况下，HKQAA不会对您或任何第三方因本协议而引致或与本协议有关的任何直接的、特殊的、偶然性的、惩罚性的或间接性损失（包括利润损失、收入损失或数据损失）或获得替代性产品的成本承担赔偿责任，无论该等赔偿责任如何引致，无论该等赔偿责任是否基于合约、保证、侵权（包括疏忽）、严格责任或其他原因而引致，以及无论HKQAA是否已被告知该等损害赔偿的可能性。</p>
                <p style={{ textAlign: "justify" }}><strong>责任豁免</strong></p>
                <p style={{ textAlign: "justify" }}>如果您与一个或多个用户或其他第三方之间存在争议，您免于我们（和我们的附属公司、人员、董事、雇员和代理人）遭受因该争议引起的或与该争议有关的所有类型的、已知和未知的索赔、要求和损害赔偿（包括直接的和间接的损害赔偿）。在达成本豁免条款时，您明确放弃将本豁免的覆盖范围限制为仅包括您在同意本豁免条款时可能知道或怀疑存在对您有利的主张的任何保护（无论是法定的还是其他的保护）。</p>
                <p style={{ textAlign: "justify" }}><strong>损害赔偿</strong></p>
                <p style={{ textAlign: "justify" }}>您同意赔偿和使HKQAA及其母公司、附属公司、联属公司、合伙人、高级职员、董事、代理人、承办商、授权人、服务供应商、分包商、供应商、实习生及雇员免于遭受任何因您违反本用户协议或其引用包含的文件，或您违反任何法律或侵犯第三方权利而由任何第三方提出的索赔或要求（包括律师费）之损害。</p>
                <p style={{ textAlign: "justify" }}><strong>独立性</strong></p>
                <p style={{ textAlign: "justify" }}>如果本用户协议的任何条款被认定为非法、无效或不可执行，则该等条款仍应在适用法律允许的最大范围内可执行，且不可执行的部分应被视为与本用户协议分离，该认定不应影响任何其他剩余条款的有效性和可执行性。</p>
                <p style={{ textAlign: "justify" }}><strong>终止</strong></p>
                <p style={{ textAlign: "justify" }}>双方在本协议终止日前产生的义务和责任在本协议终止后仍然有效。</p>
                <p style={{ textAlign: "justify" }}>本用户协议在您或我们终止之前有效。您可随时通过通知我们您不再希望使用我们的服务或停止使用我们的网站而终止本用户协议。</p>
                <p style={{ textAlign: "justify" }}>如果我们认为您未遵守本用户协议，我们也有权随时终止本协议而无需另行通知您。您仍将对截至并包括终止日期的所有到期款项承担责任；我们可能因此拒绝您访问我们的服务（或其中的任何部分）。</p>
                <p style={{ textAlign: "justify" }}><strong>弃权</strong></p>
                <p style={{ textAlign: "justify" }}>我们未行使或未执行本用户协议的任何权利或规定，不构成我们对该等权利或规定的弃权。</p>
                <p style={{ textAlign: "justify" }}><strong>完整协议</strong></p>
                <p style={{ textAlign: "justify" }}>本用户协议和我们发布在本网站或与服务有关的任何政策或操作规则构成我们双方之间的完整协议和理解，取代双方此前或临时协议、通信和建议，无论是口头或书面形式，包括但不限于任何先前版本的用户协议。</p>
                <p style={{ textAlign: "justify" }}>本用户协议解释中的任何歧义不得对起草方造成不利影响。</p>
                <p style={{ textAlign: "justify" }}><strong>可访问性声明</strong></p>
                <p style={{ textAlign: "justify" }}>本网站采用无障碍网页设计，符合万维网联盟（W3C）网站内容无障碍指南（WCAG）2.0 Level AA标准。如您对网页的可访问性有任何查询或意见，请通过电话或电邮与我们联络。</p>
                <p style={{ textAlign: "justify" }}>电话： +852 2202-9111</p>
                <p style={{ textAlign: "justify" }}>电子邮件地址： <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a></p>
                <p style={{ textAlign: "justify" }}><strong>法律适用</strong></p>
                <p style={{ textAlign: "justify" }}>本用户协议及我们为您提供服务的任何单独协议应受香港特别行政区法律管辖并按香港特别行政区法律解释。任何因本用户协议而产生或与本用户协议有关的争议或索赔，均受香港特别行政区法院的专属管辖。</p>
                <p style={{ textAlign: "justify" }}><strong>用户协议条款的变更</strong></p>
                <p style={{ textAlign: "justify" }}>您可随时在此页面查看本用户协议的最新版本。</p>
                <p style={{ textAlign: "justify" }}>我们保留自行更新、更改或替换本用户协议的权利，并会不时更新及/或更改我们的网站。您有责任定期检查本网站的变化。在发布本用户协议的任何更改后，您继续使用我们的服务或访问本网站，即表示您接受这些更改。</p>
                <p style={{ textAlign: "justify" }}>我证明我已经阅读、理解并同意受本用户协议和隐私政策条款的约束。</p>
            </div>
        );
    } else {
        return (
            <div>
                <p><strong>User Agreement &ndash; For Content Providers</strong></p>
                <p style={{ textAlign: "justify" }}><strong>Last Updated: 13 May 2022</strong></p>
                <p style={{ textAlign: "justify" }}>This website Lightshare (&ldquo;<strong>Website</strong>&rdquo;) is operated by the Hong Kong Quality Assurance Agency (hereinafter referred as &ldquo;<strong>HKQAA</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;), whose registered office is at 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong.&nbsp;</p>
                <p style={{ textAlign: "justify" }}>HKQAA offers this Website, including all information, tools and services available from this Website (&ldquo;<strong>Services</strong>&rdquo;) to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.&nbsp;</p>
                <p style={{ textAlign: "justify" }}>This Website is an integrated service platform that facilitates the hosting of online courses and circulation of educational materials for users. It also provides additional services to be introduced by HKQAA from time to time, which include but not limited to job-matching services, course promotions and other related services.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><strong>Please read this User Agreement carefully before accessing or using our Website. By accessing or using any part of the, you agree to be bound by this User Agreement.&nbsp;</strong></p>
                <p style={{ textAlign: "justify" }}><strong>General Conditions</strong></p>
                <p style={{ textAlign: "justify" }}>We reserve the right to refuse Services or the use of our Website to anyone for any reason at any time. You understand that any information provided by you may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. If HKQAA accepts credit card payment on the Website, your credit card information will always be encrypted during transfer over networks. The treatment of your personal data and any personal data provided by you is subject to our Privacy Policy available at <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                <p style={{ textAlign: "justify" }}>The headings used in this agreement are included for convenience only and will not limit or otherwise affect this User Agreement.</p>
                <p style={{ textAlign: "justify" }}><strong>Modifications of Services</strong></p>
                <p style={{ textAlign: "justify" }}>We reserve the right at any time to modify or discontinue the Services (or any part or content thereof) without notice at any time.</p>
                <p style={{ textAlign: "justify" }}>We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Services.</p>
                <p style={{ textAlign: "justify" }}><strong>Registration</strong></p>
                <p style={{ textAlign: "justify" }}>In order to participate on our Website, you must register an account with HKQAA on the Website. You are wholly responsible to keep your login credentials confidential and secured. You are also wholly responsible for all activities that occur on or in connection with your account and you agree to notify us immediately of any unauthorized access or use of your account. You acknowledge and agree that we are not responsible or liable for any damages, losses, costs and expenses, or liabilities related to any unauthorized access to or use of your account.</p>
                <p style={{ textAlign: "justify" }}>You agree to provide true, accurate, current, and complete information about yourself or the entity that you are representing as prompted by us at registration. You must not impersonate others or otherwise provide false information when creating an account on our Website. If you are representing a company, organization or a legal entity or otherwise registering on behalf of someone else, you represent and warrant that you have the authority to bind such entity to this User Agreement and all applicable additional terms. We have the right to suspend your use of the Website if we reasonably believe that the information you provided to us at registration is not true, accurate, current or complete. We may require supporting identification document from you such as business registration documents. You understand that if you fail to provide such documents as requested, you shall not be able to register an account on the Website.</p>
                <p style={{ textAlign: "justify" }}><strong>Our Services</strong></p>
                <p style={{ textAlign: "justify" }}>We reserve the right but are not obligated, to limit our Services or any part of our Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. Although the Website may be accessible worldwide, not all features, Services provided or offered through or on the Website are available to all persons or in all geographic locations. You further acknowledge that you access and use of the Website and our Services offered may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason including but not limited to system failures and technical interruptions. HKQAA has the absolute right, without any liability to you, to suspend your access to our Website or our Services at any time, for all scheduled downtime for the purpose of periodic maintenance or other events.</p>
                <p style={{ textAlign: "justify" }}>All descriptions of our Services and Hosting Fees (as defined below) are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any Services at any time without notice.&nbsp;&nbsp;</p>
                <p style={{ textAlign: "justify" }}>HKQAA does not guarantee the availability, accuracy, completeness, reliability, or timeliness of any data or information provided or displayed by HKQAA. If you choose to use the Services of HKQAA, you are responsible for your reliance on any such data or information. You should maintain periodic backups of all content including Course Content that you provide to HKQAA. Under no event shall HKQAA be liable for the removal or loss of any content or Course Content from the Website.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><strong>Course Content / User Generated Content</strong></p>
                <p style={{ textAlign: "justify" }}>The Website allows you to upload, submit, store, send, transmit content and data, including but not limited to your online course videos, course materials, promotional materials (collectively, &ldquo;<strong>Course Content</strong>&rdquo;). While you shall have liberty in designing the general flow and the content in each Course Content, the Course Content must be in an acceptable format as instructed by HKQAA from time to time and shall at time comply with our Acceptable Use Policy.&nbsp;</p>
                <p style={{ textAlign: "justify" }}>When you upload, submit, store, send or transmit any Course Content to or through our Site, you grant us a non-exclusive, royalty-free, unlimited, universal, sublicensable, perpetual, and irrevocable licence in any and all manner and media, whether now known or hereinafter invented or devised (including but not limited to social media channels, and third party websites and platforms), to reproduce, license, distribute, modify, adapt, publicly perform, publicly display, create derivative works of, and to use your Course Content for the purpose streaming or circulating the Course Content to end users, to advertise, market and promote our Website and Services, and you agree that such Course Content may be used, including performed or displayed, in connection with any other elements, materials, copyrights of publicity, or copyrighted materials including but not limited to promoting and advertising Course Content, the Website, and issuing certificates for attendees of Course. The licence commences upon your submission of Course Content to HKQAA and ends when you or HKQAA removes such Course Content from the Website. Unless prohibited by law, you acknowledge that HKQAA may retain archival copies of such Course Content for improvement of user experience and maintenance of Website.</p>
                <p style={{ textAlign: "justify" }}>You hereby acknowledge and agree that HKQAA merely serves as a facilitator and platform provider for you to offer Course Content to Users. The view and opinions expressed in any Course Content do not necessarily reflect those of HKQAA. In particular, nothing in this Website including Course Content shall be construed as legal, tax, investment, financial or other professional advice. HKQAA&rsquo;s hosting of Course Content on the Website does not imply any endorsement, sponsorship or recommendation by HKQAA of any third party, nor does any information on the Website constitutes a comprehensive, complete or accurate statement of the matters discussed or the law relating thereto. Reference to any products, services, process or any service by trade name, trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favouring by HKQAA. You assume sole responsibility of evaluating the merits and risks associated with the use of any information provided by HKQAA or from Course Content.&nbsp;</p>
                <p style={{ textAlign: "justify" }}>HKQAA reserves the right to remove any Course Content if HKQAA is of the opinion that such content violates the Acceptable Use Policy as stipulated below.</p>
                <p style={{ textAlign: "justify" }}>You may withdraw any or all of the Course Content from the Website at any time and for any reason.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><strong>Acceptable Use Policy</strong></p>
                <p style={{ textAlign: "justify" }}>In your uploading, submitting, storing, sending or transmitting of Course Content, you hereby agree that all Course Content and your conduct on this Website shall comply with our acceptable use policy set forth below. HKQAA may (but is not obligated to) monitor and review your account, content, and conduct when you use our Website. HKQAA shall have the right to remove any of your Course Content or suspend your access to the Website if HKQAA finds your Course Content or conduct in violation of this User Agreement.</p>
                <p style={{ textAlign: "justify" }}>You and/or your Course Content shall not:</p>
                <ol>
                    <li>infringe any patent, trademark, trade secret, copyright or other intellectual property right of another person or entity;</li>
                    <li>violate any contractual or fiduciary agreements with any other third parties;</li>
                    <li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                    <li>be sexually explicit or promotes a sexual service;</li>
                    <li>be defamatory;</li>
                    <li>contain hateful or discriminatory speech;</li>
                    <li>promote or support any terror or hate groups;</li>
                    <li>contain instructions on how to assemble explosive devices or improvised firearms;</li>
                    <li>exploits or endangers minors;</li>
                    <li>depicts or encourages self-harm or suicide;</li>
                    <li>depicts any acts of violence, including but not limited to animal cruelty and torture;</li>
                    <li>promote fraudulent or dubious money-making schemes, propose any unlawful transaction or use of deceptive marketing practices;</li>
                    <li>contain any false or misleading information; or</li>
                    <li>violate any applicable laws.</li>
                </ol>
                <p style={{ textAlign: "justify" }}>In addition to the above, you hereby agree that in your use of the Website, you shall not:</p>
                <ol>
                    <li>copy, modify, alter, adapt, translate, reverse engineer, disassemble, decompile, create derivative works from, or distribute any content from our services;</li>
                    <li>alter, remove, tamper, copy, display or use our trademarks and all other HKQAA-owned intellectual property for any purpose not explicitly authorized in this User Agreement;</li>
                    <li>use our Services for any revenue-generating endeavours not authorized by HKQAA; or other activities that are beyond the scope of any agreements entered into between HKQAA and you;</li>
                    <li>submit, share, post, communicate or otherwise take any actions that incites or encourages actions that is, explicit or implicitly illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, pornographic, indecent, profane, obscene, or otherwise objectionable (including nudity);</li>
                    <li>engage in spidering or harvesting or participate in the use of software, including spyware, designed to collect data from our Website, including from any user of our Website;</li>
                    <li>transmit any virus, other computer instruction, or technological means intended to, or that may, disrupt, damage, or interfere with the use of computers or related systems;</li>
                    <li>stalk, harass, threaten or harm any third party;</li>
                    <li>impersonate any third party;</li>
                    <li>participate in any fraudulent or illegal activity, including but not limited to phishing, money laundering, or fraud;</li>
                    <li>access our Website to obtain information to build a similar or competitive website, application or otherwise;</li>
                    <li>use the Website for any illegal or unauthorized purpose, or purposes that violates any applicable laws (including but not limited to, copyright and privacy laws) or rights of other third parties;</li>
                    <li>set up links to the Website using any HTML techniques that display the Website within a frame, partial window, pop-up, pop-under, or any other non-standard linking methods, or redistribute any content from the Website unless expressly authorized by HKQAA; or</li>
                    <li>advocate, encourage or assist any third party in doing any of the foregoing.</li>
                </ol>
                <p style={{ textAlign: "justify" }}><strong>Fees</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Hosting Fee</span>. You acknowledge and agree that HKQAA shall charge a Hosting Fee upon commencement of an uploaded course. In HKQAA&rsquo;s sole opinion, the Hosting Fee and payment methods may be varied from time to time with prior notice to you. For the avoidance of doubt, HKQAA does not charge for the uploading of content to the Website, it will only become chargeable when a member of the Website signs up for the course provided by you. All fees payable to HKQAA shall be paid immediately in according with the payment terms as agreed between HKQAA and you.</p>
                <p style={{ textAlign: "justify" }}>All payment made to HKQAA are in Hong Kong dollars are non-refundable. HKQAA shall not be responsible for any contributions, payments, taxes or payment to any third party. All such costs shall be solely borne by you.</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Payment Waiver.</span> You acknowledge that by uploading any Course Content to HKQAA, you will not be entitled to any fees, royalties, commissions or payment of any kind from HKQAA. You hereby waive any right to collect any royalties, proceeds or remuneration from HKQAA for the distribution of Course Content on the Website.</p>
                <p style={{ textAlign: "justify" }}>HKQAA shall not be responsible for any costs, expenses or other liabilities you may incur for the production of Course Content or for the submission of Course Content to HKQAA.</p>
                <p style={{ textAlign: "justify" }}><strong>Third Party Content and Interactions</strong></p>
                <p style={{ textAlign: "justify" }}>The Website may contain certain features and functionalities that link to or provide you with access to third party content, that is completely not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. Please exercise your own independent judgment before proceeding with any contact or interaction, in connection with the Website or otherwise.</p>
                <p style={{ textAlign: "justify" }}>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party&rsquo;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>User Comments, Feedback And Other Submissions</strong></p>
                <p style={{ textAlign: "justify" }}>If at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, &ldquo;<strong>Comments</strong>&rdquo;), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay compensation for any Comments; or (3) to respond to any Comments. We may, but have no obligation to, monitor, edit or remove Comments or part of the Comments that we determine in our sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or this User Agreement.</p>
                <p style={{ textAlign: "justify" }}>You agree that your Comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary rights. You further agree that your Comments will not contain libellous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any Comments you make and their accuracy. We take no responsibility and assume no liability for any Comments posted by you or any third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>Personal Data Protection</strong></p>
                <p style={{ textAlign: "justify" }}>Your privacy is important to us. Your submission of personal data through the Website is governed by our Privacy Policy, which can be accessed via&nbsp;<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy.</a></p>
                <p style={{ textAlign: "justify" }}><strong>Intellectual Property</strong></p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Ownership. </span>Other than the Course Content, HKQAA owns all rights, title, and interest in and to the Website and the &ldquo;look and feel&rdquo; of the Website, including all software, ideas, processes, data, text, media, domain names, trade secrets, trademarks, logos, copyrights and all other intellectual property rights. (&ldquo;<strong>HKQAA Content</strong>&rdquo;) The Website is protected under Hong Kong and applicable international laws. You may not duplicate, copy, or reuse any portion of HKQAA Content without our prior written consent.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Use of HKQAA trademarks.</span> During the term, HKQAA grants to you a limited licence to use the HKQAA trademarks strictly in connection with advertising and promoting the Course Content or your participation in the Website. You may only use such marks in a referential manner and may not use the marks as the most prominent visual element in any materials. You shall not directly or indirectly suggest HKQAA&rsquo;s sponsorship, affiliation or endorsement of you, your Course Content or any activities that you conduct.</p>
                <p style={{ textAlign: "justify" }}><strong>Complaints and Disputes Handling</strong></p>
                <p style={{ textAlign: "justify" }}>You agree that HKQAA has the right to remove or temporarily suspend provision of any Course Content that is subject to a complaint. Upon receipt of a complaint regarding you or your Course Content, HKQAA may suspend access to the particular Course Content or your account on the Website pending a determination by HKQAA. HKQAA shall then promptly refer the complaint to you and you shall use your best endeavours to cooperate with any investigations conducted by HKQAA. HKQAA has the final determination in all complaints.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><strong>Independent Contractor</strong></p>
                <p style={{ textAlign: "justify" }}>Nothing contained in this User Agreement shall be deemed or construed as creating a joint venture or partnership between you and HKQAA. No party is by virtue of this User Agreement authorized as an agent, employee or legal representative of any other party. No party shall have the power to control the activities and operations of any other and their status is, and at all times, will continue to be, that of independent contractors with respect to each other. No party shall have any power or authority to bind or commit the other Party without the prior written authorization of such other party. No party shall hold itself out as having any authority or relationship in contravention of this section.</p>
                <p style={{ textAlign: "justify" }}>Your relationship with HKQAA is non-exclusive, meaning that you may provide similar services to third parties, and you may engage in other business activities. However, you acknowledge and agree that during the term of this User Agreement, all Course Content that you submit to HKQAA is submitted on an exclusive basis. You shall not make available, distribute, sell or otherwise circulate Course Content (whether on a complimentary or paid basis) on other platforms or websites without HKQAA&rsquo;s express consent.&nbsp;</p>
                <p style={{ textAlign: "justify" }}><strong>Disclaimer Of Warranties; Limitation Of Liability</strong></p>
                <p style={{ textAlign: "justify" }}>We do not guarantee, represent, or warrant that your use of our Website will be uninterrupted, timely, secure, or error-free. We do not warrant that the information and content as provided on the Website will be accurate or reliable. You agree that from time to time we may remove the Website for indefinite periods of time or cancel any of our Services offered at any time, without notice to you.</p>
                <p style={{ textAlign: "justify" }}>You expressly agree that your use of, or inability to use, the Website is at your sole risk. The Website or services delivered to you through the Website are (except as expressly stated by us) provided &lsquo;as is&rsquo; and &lsquo;as available&rsquo; for your use, without any representation, warranties or conditions of any kind, either express or implied to the extent permitted by law, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                <p style={{ textAlign: "justify" }}>You acknowledge and agree that HKQAA shall, to the extent permitted by law, have no liability for any: (i) errors, mistakes, or inaccuracies of content; (ii) personal injury, property damage, or other harm resulting from your access to or use of our Website; (iii) any unauthorized access to or use of our servers, any personal information, or user data; (iv) any interruption of transmission to or from our Website; (v) any bugs, viruses, trojan horses, or the like that may be transmitted on or through our Website; or (vi) any damages, losses, costs, expenses, or liabilities of any kind incurred as a result of any content or the use of any content posted or shared through our Website.</p>
                <p style={{ textAlign: "justify" }}><span style={{ textDecoration: "underline" }}>Consequential Losses.</span> To the fullest extent permitted by applicable law, you acknowledge and agree that in no event will HKQAA be liable to you or to any third party for any direct, indirect, special, incidental, punitive, or consequential damages (including for loss of profits, revenue, or data) or for the cost of obtaining substitute products, arising out of or in connection with these Terms, however caused, whether such liability arises from any claim based upon contract, warranty, tort (including negligence), strict liability or otherwise, and whether or not HKQAA has been advised of the possibility of such damages.<strong>Release</strong>If you have a dispute with one or more users or other third party, you release us (and our affiliates, officers, directors, employees and agents) from all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favour at the time of agreeing to this release.</p>
                <p style={{ textAlign: "justify" }}><strong>Release</strong></p>
                <p style={{ textAlign: "justify" }}>If you have a dispute with one or more users or other third party, you release us (and our affiliates, officers, directors, employees and agents) from all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favour at the time of agreeing to this release.</p>
                <p style={{ textAlign: "justify" }}>You agree to indemnify, defend and hold harmless HKQAA and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including attorneys&rsquo; fees, made by any third party due to or arising out of your breach of this User Agreement or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                <p style={{ textAlign: "justify" }}><strong>Severability</strong></p>
                <p style={{ textAlign: "justify" }}>In the event that any provision of this User Agreement is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from this User Agreement, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                <p style={{ textAlign: "justify" }}><strong>Termination</strong></p>
                <p style={{ textAlign: "justify" }}>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                <p style={{ textAlign: "justify" }}>This User Agreement&nbsp;is effective unless and until terminated by either you or us. You may terminate this User Agreement&nbsp;at any time by notifying us that you no longer wish to use our Services, or when you cease using our Website.</p>
                <p style={{ textAlign: "justify" }}>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of this User Agreement, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                <p style={{ textAlign: "justify" }}><strong>Waiver</strong></p>
                <p style={{ textAlign: "justify" }}>The failure of us to exercise or enforce any right or provision of this User Agreement shall not constitute a waiver of such right or provision.</p>
                <p style={{ textAlign: "justify" }}><strong>Entire Agreement</strong></p>
                <p style={{ textAlign: "justify" }}>This User Agreement and any policies or operating rules posted by us on this Website or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of our Website and Services, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the User Agreement).</p>
                <p style={{ textAlign: "justify" }}>Any ambiguities in the interpretation of this User Agreement shall not be construed against the drafting party.</p>
                <p style={{ textAlign: "justify" }}><strong>Accessibility Statement</strong></p>
                <p style={{ textAlign: "justify" }}>This Website adopts web accessibility design and conforms to the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.0 Level AA standard. Should you have any enquiries or comments on its accessibility, please contact us by phone or email.</p>
                <p style={{ textAlign: "justify" }}>Telephone Number: +852 2202-9111</p>
                <p style={{ textAlign: "justify" }}>Email address: <a href="mailto:lightshare@hkqaa.org" target="_blank" rel="noopener noreferrer"><span style={{ color: "#0000ff" }}>lightshare@hkqaa.org</span></a></p>
                <p style={{ textAlign: "justify" }}><strong>Governing Law</strong></p>
                <p style={{ textAlign: "justify" }}>This User Agreement and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Hong Kong SAR. Any dispute or claim arising out of or in connection with this User Agreement shall be subject to the exclusive jurisdiction of the courts of Hong Kong SAR.</p>
                <p style={{ textAlign: "justify" }}><strong>Changes To terms of User Agreement</strong></p>
                <p style={{ textAlign: "justify" }}>You can review the most current version of the terms and conditions at any time at this page.</p>
                <p style={{ textAlign: "justify" }}>We reserve the right, at our sole discretion, to update, change or replace any part of this User Agreement by posting updates and changes to our Website. It is your responsibility to check our Website periodically for changes. Your continued use of or access to our Website or the Service following the posting of any changes to this User Agreement constitutes acceptance of those changes.</p>
                <p style={{ textAlign: "justify" }}>I certify that I have read, understood, and agree to be bound by the terms of this User Agreement and Privacy Policy.</p>
            </div>
        );
    }
}
