import React from "react";

export default function CourseCategory(props){

    const categories = props?.categories;
    const lang = props?.lang ?? 'EN';


    const cat_json = [
        {
          "key": "Sustainability",
          "EN": "Sustainability",
          "TC": "可持續發展"
        },
        {
          "key": "Innovation",
          "EN": "Innovation",
          "TC": "創新"
        },
        {
          "key": "Capacity Building",
          "EN": "Capacity Building",
          "TC": "能力提升"
        },
        {
          "key": "Management",
          "EN": "Management",
          "TC": "管理"
        },
        {
          "key": "Green Finance",
          "EN": "Green Finance",
          "TC": "綠色金融"
        },
        {
          "key": "Soft Skill",
          "EN": "Soft Skill",
          "TC": "軟技能"
        },
        {
          "key": "ISO",
          "EN": "ISO",
          "TC": "ISO"
        },
        {
          "key": "Medical",
          "EN": "Medical",
          "TC": "醫療"
        },
        {
          "key": "Pharmaceutical",
          "EN": "Pharmaceutical",
          "TC": "製藥"
        },
        {
          "key": "Greater Bay Area",
          "EN": "Greater Bay Area",
          "TC": "大灣區"
        },
        {
          "key": "Cosmetic",
          "EN": "Cosmetic",
          "TC": "化妝品"
        },
        {
          "key": "Construction",
          "EN": "Construction",
          "TC": "建造業"
        },
        {
          "key": "Food & Beverage",
          "EN": "Food & Beverage",
          "TC": "餐飲"
        },
        {
          "key": "Retail",
          "EN": "Retail",
          "TC": "零售"
        },
        {
          "key": "Property Management",
          "EN": "Property Management",
          "TC": "物業管理"
        },
        {
          "key": "Elderly Care Service",
          "EN": "Elderly Care Service",
          "TC": "安老服務"
        },
        {
          "key": "Hotel",
          "EN": "Hotel",
          "TC": "酒店"
        },
        {
          "key": "Law and Legislation",
          "EN": "Law and Legislation",
          "TC": "法律法規"
        },
        {
          "key": "I.T.",
          "EN": "I.T.",
          "TC": "資訊科技"
        },
        {
          "key": "Education",
          "EN": "Education",
          "TC": "教育"
        },
        {
          "key": "Service",
          "EN": "Service",
          "TC": "服務"
        },
        {
          "key": "Communication",
          "EN": "Communication",
          "TC": "溝通技巧"
        },
        {
          "key": "Others",
          "EN": "Others",
          "TC": "其他"
        }
       ];


    return <>
      {/* <option key="category-default" default hidden>--</option> */}
      {cat_json.map((c)=>
        <option key={c.key} selected={categories?.includes(c.key)}>{c[lang]}</option>
      )}
    </>
}
