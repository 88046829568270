import Layout from "../components/Layout";
import React, { useEffect, useState } from "react";
import Section from "../components/Section";
import H2 from "../components/H2";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { getApiClient } from "../util/auth";
import PrimaryButton from "../components/PrimaryButton";
import { parseDbStringToObj } from "../util/lang";
import { useTranslation } from "react-i18next";
import QuizResultModal from "../components/QuizResultModal";
import CURRENT_LANGUAGE from "../getCurrentLanguage";

export default function Quiz() {
    const api = getApiClient();
    const navigate = useNavigate();
    const params = useParams();

    const role = localStorage.getItem("role");

    const course_id = params.course_id ?? '';
    const [course, setCourse] = useState('');

    const [questions, setQuestions] = useState([]);
    const [quiz, setQuiz] = useState('');
    const [remainingAttempts, setRemainingAttempts] = useState(0);

    const [answers, setAnswers] = useState([]);
    const [is_show_modal, set_is_show_modal] = useState(false);

    const [result_status, set_result_status] = useState('INITIAL');
    const [quiz_remaining_remaining_attempts, set_quiz_remaining_remaining_attempts] = useState(0);
    const [is_created_evaluation_form, set_is_created_evaluation_form] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        // TODO need to check if user has already taken this quiz before
        // if he has, and he has no attempts left, he should not be allowed to do the test
        api.post("/user/view_quiz", { course_id: course_id })
            .then((response) => {
                if (response.data?.can_retake === false) {
                    alert(t('No more remaining attempts.'));
                } else {
                    setQuiz(response.data.quiz[0]);
                    setRemainingAttempts(response.data?.remaining_attempts);
                    setQuestions(parseQuestionsResponse(response.data.questions_choices));
                }
            })
            .catch((error) => {
                if (error.response?.data?.message) {
                    alert(t(error.response.data.message));
                }
                navigate({
                    pathname: "/courses/show-module",
                    search: createSearchParams({
                        course_id: course_id,
                    }).toString(),
                });
            });

        api.post(role === "Individual Learner" ? "/individual_learner/view_course" : role === "Organization Learner" ? "/organization_learner/view_course" : "/association_learner/view_course", { course_id: course_id })
            .then((response) => {
                set_is_created_evaluation_form(response?.data?.is_created_evaluation_form === "Yes");
                setCourse(response.data.course[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const parseQuestionsResponse = (res) => {
        const res_qc = res;
        let questionsObj = [];

        res_qc.forEach((qc) => {
            // if question not yet exist
            if (!questionsObj.find(element => element.id === qc.quiz_question_id)) {
                // not found - insert question and choice
                const choice = {
                    id: qc.quiz_question_choice_id,
                    title: qc.Choice_Title
                };
                const question = {
                    id: qc.quiz_question_id,
                    title: qc.Question_Title,
                    choices: [choice]
                };

                questionsObj.push(question);
            } else {
                // found - only insert choice
                const choice = {
                    id: qc.quiz_question_choice_id,
                    title: qc.Choice_Title
                };
                questionsObj.find(element => element.id === qc.quiz_question_id).choices.push(choice);
            }
        })
        return questionsObj;
    }

    const handleChooseAnswer = (question_id, choice_id) => {
        const answer = { quiz_question_id: question_id, quiz_question_choice_id: choice_id }

        let newAnswers = answers.filter(a => a.quiz_question_id !== question_id);
        newAnswers.push(answer);

        console.log(newAnswers);
        setAnswers(newAnswers);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!answers || answers.length !== questions.length) {
            return alert(t('Please answer all questions!'));
        }

        const params = {
            course_id: course_id,
            quiz_id: quiz.id,
            learner_answers: answers,
        }

        api.post('/user/doQuiz', params)
            .then((response) => {
                alert(t('Quiz submitted successfully!'));

                if (response?.data?.quiz_result === "Pass") {
                    set_result_status('PASS');
                } else {
                    if (response?.data?.can_retake === true) {
                        set_quiz_remaining_remaining_attempts(response?.data?.remaining_attempts);
                        set_result_status('RETRY');
                    } else {
                        set_quiz_remaining_remaining_attempts(response?.data?.remaining_attempts);
                        set_result_status('CANNOT_RETRY');
                    }
                }

                set_is_show_modal(true);
            })
            .catch((error) => {
                if (error.response?.data?.message) {
                    alert(t(error.response.data.message));
                }

                console.log(error);
            });
    };

    return (
        <Layout>
            <QuizResultModal
                is_show_modal={is_show_modal}
                on_modal_hide={() => {
                    set_is_show_modal(false);
                }}
                result_status={result_status}
                course={course}
                quiz_remaining_remaining_attempts={quiz_remaining_remaining_attempts}
                is_created_evaluation_form={is_created_evaluation_form}
            />
            <Section>
                <Container className="max-width-1304">
                    <Row className="">
                        <Col>
                            <div className={"mb-2"}>{t('Quiz')}</div>
                            <H2 style={{ fontSize: 40 }} className={"mt-0 mb-4"}>
                                {parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}
                            </H2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"col-12 text-6e6e6e font-size-16px"}>
                            {t('Passing rate')}: <span className={"text-eb6201 fw-bold font-size-16px"}>{quiz.Passing_Grade_Percentage}%</span>
                        </Col>
                    </Row>
                    <Row className={"mb-4"}>
                        <Col className={"col-12 text-6e6e6e font-size-16px"}>
                            {t('Remaining Quiz Attempts')}: {
                                quiz.Allow_Retake &&
                                <span className={"text-eb6201 fw-bold font-size-16px"}>{remainingAttempts} {t('time(s)')}</span>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <div className={"font-size-16px fw-bolder text-eb6201 mb-3"}>{t('Please fill up below all questions and click submit quiz button.')}</div>
                                    {questions.map((q,qno) => {
                                        return <QuestionDiv key={q.id}
                                            question={q}
                                            question_number = {parseInt(qno)+1}
                                            handleChooseAnswer={handleChooseAnswer}
                                            answers={answers} />
                                    }
                                    )}
                                </div>

                                <div className={"mt-5"}>
                                    <PrimaryButton
                                        type={"submit"}
                                    >
                                        {t('SUBMIT QUIZ')}
                                    </PrimaryButton>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </Layout>
    );
}


function QuestionDiv(props) {
    const question = props.question;
    const choices = question.choices;
    const question_number = props.question_number;

    const choicesLetters = ['A', 'B', 'C', 'D'];
    const answers = props.answers;

    const { t } = useTranslation();

    return <>
        <div key={question.id} className={"mb-3"}>
            <div className={"font-size-16px"}><span className={"fw-bold"}>{t('Question')}: {question_number}</span><br/>{question.title}</div>

            <Row className={"font-size-16px mt-3"}>
                {choices.map((c, index) => (
                    <Col sm={12} key={c.id}>
                        <div className="form-check">
                            <input className="form-check-input"
                                type="radio"
                                checked={answers.find(a => a.quiz_question_choice_id === c.id)}
                                onChange={() => props.handleChooseAnswer(question.id, c.id)}
                            />
                            <label className="form-check-label">
                                <span className={"fw-bold"}>{choicesLetters[index]}. </span>
                                <span>{c.title}</span>
                            </label>
                        </div>
                    </Col>
                ))
                }
            </Row>
        </div>
    </>
}
