import React, {useEffect, useState} from "react";
import { getApiClient } from "../../util/auth";
import axios from "axios";

export default function ClassVideo(props) {
    const api = getApiClient();
    const viewing_class = props.viewing_class;
    const viewed_class_responses = props.viewed_class_responses;
    const hardcode = props.hardcode ?? false;
    const [should_save_view_class_status, set_should_save_view_class_status] = useState(true);

    const [canSendAPI, setCanSendAPI] = useState(true);

    useEffect(() => {
        axios.get(props.viewing_class.Class_Video, {
            responseType: 'blob',
        })
            .then((response) => {
                console.log(response.data);
            });
    }, [props.viewing_class]);

    const handleTimeUpdate = (event) => {
        const class_id = viewing_class.id;
        const module_id = viewing_class.ModuleId;
        const course_id = viewing_class.CourseId;
        const is_viewed = viewed_class_responses?.[module_id]?.viewed_class_id.includes(class_id);

        if (is_viewed) {
            return;
        }

        if (!should_save_view_class_status) {
            return;
        }

        const viewed_percentage = (event.target.currentTime / event.target.duration) * 100;
        const params = {
            course_id: course_id,
            module_id: module_id,
            class_id: class_id,
        }

        if (viewed_percentage >= 70) {
            // checking if can send api
            if(canSendAPI) {
                // set to false to avoid send again with same request
                setCanSendAPI(false);
                api.post('/user/saveViewClassStatus', params)
                    .then((response) => {
                        set_should_save_view_class_status(false);

                        if (props.saved_view_class_status) {
                            props.saved_view_class_status();
                        }
                        // set back to true to allow send next request
                        setCanSendAPI(true);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        // set back to true to allow send next request
                        setCanSendAPI(true);
                    });
            }
        }
    }

    function VideoTab(props) {
        if (hardcode) {
            // for hardcode
            return <source key={viewing_class.id} src={viewing_class.Video_Url} />
        }
        else {
            // for api
            return <source key={viewing_class.id} src={viewing_class.Class_Video} />
        }
    }


    return (
        <video controlsList="nodownload" onContextMenu={e => e.preventDefault()} controls={true} className={"w-100 mh-100"} onTimeUpdate={(event) => {
            handleTimeUpdate(event);
        }}>
            {
                <VideoTab viewing_class={viewing_class} hardcode={hardcode}></VideoTab>
            }
        </video>
    );
}
