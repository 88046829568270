import React from "react";
import moment from 'moment';
import { parseDbStringToObj } from "../../util/lang";
import { useTranslation } from 'react-i18next';

export default function PreviewCertificate(props){
    const { t } = useTranslation();

    const course_name_en = props.course_name_en;
    const course_name_tc = props.course_name_tc;
    const course_name_sc = props.course_name_sc;
    const course_master_language = props.course_master_language ?? 'EN';
    const user = props.user;
    
    let course_name, company_name, course_owner_logo;
    let show_company_name = false;
    let show_course_owner_logo = false;

    //set company name and course owner logo with different role
    if(user.Role === "Organization") {
        if(course_master_language === 'EN') {
            course_name = course_name_en;
            company_name = parseDbStringToObj(user.Organization_Name).EN;
        } else if(course_master_language === 'TC') {
            course_name = course_name_tc;
            company_name = parseDbStringToObj(user.Organization_Name).TC;
        } else if(course_master_language === 'SC') {
            course_name = course_name_sc;
            company_name = parseDbStringToObj(user.Organization_Name).SC;
        }

        course_owner_logo = user.Organization_Logo;

        //checking is it shows the company name
        if(user.Certificate_Company_Name_Show === 0 || user.Certificate_Company_Name_Show === null) {
            show_company_name = false;
        } else if(user.Certificate_Company_Name_Show === 1) {
            show_company_name = true;
        }

        //checking is it shows the course owner logo
        if(user.Certificate_Company_Logo_Show === 0 || user.Certificate_Company_Logo_Show === null) {
            show_course_owner_logo = false;
        } else if(user.Certificate_Company_Logo_Show === 1) {
            show_course_owner_logo = true;
        }
    } else if(user.Role === "Trainer/Academy") {
        if(course_master_language === 'EN') {
            course_name = course_name_en;
            company_name = parseDbStringToObj(user.Trainer_Name).EN;
        } else if(course_master_language === 'TC') {
            course_name = course_name_tc;
            company_name = parseDbStringToObj(user.Trainer_Name).TC;
        } else if(course_master_language === 'SC') {
            course_name = course_name_sc;
            company_name = parseDbStringToObj(user.Trainer_Name).SC;
        }

        course_owner_logo = user.Trainer_Logo;

        //checking is it shows the trainer name
        if(user.Certificate_Trainer_Name_Show === 0 || user.Certificate_Trainer_Name_Show === null) {
            show_company_name = false;
        } else if(user.Certificate_Trainer_Name_Show === 1) {
            show_company_name = true;
        }

        //checking is it shows the course owner logo
        if(user.Certificate_Trainer_Logo_Show === 0 || user.Certificate_Trainer_Logo_Show === null) {
            show_course_owner_logo = false;
        } else if(user.Certificate_Trainer_Logo_Show === 1) {
            show_course_owner_logo = true;
        }
    } else if(user.Role === "Association") {
        if(course_master_language === 'EN') {
            course_name = course_name_en;
            company_name = parseDbStringToObj(user.Association_Name).EN;
        } else if(course_master_language === 'TC') {
            course_name = course_name_tc;
            company_name = parseDbStringToObj(user.Association_Name).TC;
        } else if(course_master_language === 'SC') {
            course_name = course_name_sc;
            company_name = parseDbStringToObj(user.Association_Name).SC;
        }

        course_owner_logo = user.Association_Logo;

        //checking is it shows the association name
        if(user.Certificate_Association_Name_Show === 0 || user.Certificate_Association_Name_Show === null) {
            show_company_name = false;
        } else if(user.Certificate_Association_Name_Show === 1) {
            show_company_name = true;
        }

        //checking is it shows the course owner logo
        if(user.Certificate_Association_Logo_Show === 0 || user.Certificate_Association_Logo_Show === null) {
            show_course_owner_logo = false;
        } else if(user.Certificate_Association_Logo_Show === 1) {
            show_course_owner_logo = true;
        }
    }


    return <>
        {/** Attendance Certificate Sample */}
        <AttendanceCertificate 
            course_name={course_name} 
            company_name={company_name} 
            course_owner_logo={course_owner_logo} 
            show_company_name={show_company_name} 
            show_course_owner_logo={show_course_owner_logo}
        />
        <hr/>
        <CompletionCertificate 
            course_name={course_name} 
            company_name={company_name} 
            course_owner_logo={course_owner_logo} 
            show_company_name={show_company_name} 
            show_course_owner_logo={show_course_owner_logo}
        />
    </>
}

function AttendanceCertificate(props) {
    const course_name = props.course_name;
    const company_name = props.company_name;
    const course_owner_logo = props.course_owner_logo;
    const show_company_name = props.show_company_name;
    const show_course_owner_logo = props.show_course_owner_logo;

    return <>
        {/** Attendance Certificate Sample */}
        <p className="m-0 font-size-20px text-a2a2a2">Attendance Certificate Sample</p>
        <div>
            {/** Attendance Certificate image template */}
            <img 
                src="/assets/images/attendance_certificate_template.png" 
                alt="attendance_certificate_template" 
                width='595.28px' 
                height='841.89px' 
                style={{border: '1px solid black'}}
            />
            {/** Contact Name */}
            <div 
                name="Contact_Name" 
                style={{
                    position: 'absolute', 
                    top: '340px', 
                    marginLeft: '120px', 
                    width: '350px',
                    height: '60px',
                    fontFamily: 'Times New Roman'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '20px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    TAI MAN CHAN
                </span>
            </div>
            {/** Course Name */}
            <div
                style={{
                    position: 'absolute',
                    top: '430px',
                    marginLeft: '120px',
                    width: '350px',
                    height: '60px',
                    wordBreak: 'break-word'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '13px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {course_name}
                </span>
            </div>
            {/** Organization or Trainer/Academy or Association Name */}
            {show_company_name
            ?
            <div
                style={{
                    position: 'absolute',
                    top: '690px',
                    marginLeft: '56px',
                    width: '240px',
                    height: '40px',
                    wordBreak: 'break-word'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '13px',  
                        textAlign: 'left',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {company_name}
                </span>
            </div>
            :
            <></>
            }
            {/** Course Owner Logo */}
            {show_course_owner_logo && course_owner_logo !== null
            ?
            <div
                style={{
                    position: 'absolute',
                    top: '640px',
                    marginLeft: '420px',
                    width: '120px',
                    height: '115px'
                }}
            >
                <img 
                    src={course_owner_logo}
                    alt="Course_Owner_Logo"
                    style={{
                        display: 'block', 
                        width: '120px',
                        height: '115px'
                    }}
                />
            </div>
            :
            <></>
            }
            {/** Training Hours */}
            <div
                style={{
                    position: 'absolute',
                    top: '773px',
                    marginLeft: '443px',
                    width: '30px',
                    height: '40px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    0
                </span>
            </div>
            {/** Completion Date */}
            <div
                style={{
                    position: 'absolute',
                    top: '790px',
                    marginLeft: '480px',
                    width: '60px',
                    height: '20px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'right',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {moment().utc(8).format("YYYY-MM-DD")}
                </span>
            </div>
            {/** Certificate No. */}
            <div
                style={{
                    position: 'absolute',
                    top: '805px',
                    marginLeft: '465px',
                    width: '75px',
                    height: '20px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'right',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    00001
                </span>
            </div>
        </div>
    </>
}

function CompletionCertificate(props) {
    const course_name = props.course_name;
    const company_name = props.company_name;
    const course_owner_logo = props.course_owner_logo;
    const show_company_name = props.show_company_name;
    const show_course_owner_logo = props.show_course_owner_logo;

    return <>
        {/** Completion Certificate Sample */}
        <p className="m-0 font-size-20px text-a2a2a2">Completion Certificate Sample</p>
        <div>
            {/** Completion Certificate image template */}
            <img 
                src="/assets/images/completion_certificate_template.png" 
                alt="completion_certificate_template" 
                width='595.28px' 
                height='841.89px' 
                style={{border: '1px solid black'}}
            />
            {/** Contact Name */}
            <div 
                name="Contact_Name" 
                style={{
                    position: 'absolute', 
                    top: '1265px', 
                    marginLeft: '120px', 
                    width: '350px',
                    height: '60px',
                    fontFamily: 'Times New Roman'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '20px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    TAI MAN CHAN
                </span>
            </div>
            {/** Course Name */}
            <div
                style={{
                    position: 'absolute',
                    top: '1355px',
                    marginLeft: '120px',
                    width: '350px',
                    height: '60px',
                    wordBreak: 'break-word'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '13px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {course_name}
                </span>
            </div>
            {/** Organization or Trainer/Academy or Association Name */}
            {show_company_name
            ?
            <div
                style={{
                    position: 'absolute',
                    top: '1595px',
                    marginLeft: '56px',
                    width: '240px',
                    height: '40px',
                    wordBreak: 'break-word'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '13px',  
                        textAlign: 'left',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {company_name}
                </span>
            </div>
            :
            <></>
            }
            {/** Course Owner Logo */}
            {show_course_owner_logo && course_owner_logo !== null
            ?
            <div
                style={{
                    position: 'absolute',
                    top: '1545px',
                    marginLeft: '420px',
                    width: '120px',
                    height: '115px'
                }}
            >
                <img 
                    src={course_owner_logo}
                    alt="Course_Owner_Logo"
                    style={{
                        display: 'block', 
                        width: '120px',
                        height: '115px'
                    }}
                />
            </div>
            :
            <></>
            }
            {/** Training Hours */}
            <div
                style={{
                    position: 'absolute',
                    top: '1678px',
                    marginLeft: '443px',
                    width: '30px',
                    height: '40px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'center',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    0
                </span>
            </div>
            {/** Completion Date */}
            <div
                style={{
                    position: 'absolute',
                    top: '1695px',
                    marginLeft: '480px',
                    width: '60px',
                    height: '20px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'right',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    {moment().utc(8).format("YYYY-MM-DD")}
                </span>
            </div>
            {/** Certificate No. */}
            <div
                style={{
                    position: 'absolute',
                    top: '1710px',
                    marginLeft: '465px',
                    width: '75px',
                    height: '20px'
                }}
            >
                <span 
                    style={{
                        display: 'block', 
                        fontSize: '10px',  
                        textAlign: 'right',
                        fontFamily: 'Times New Roman',
                        fontWeight: 'bold'
                    }}
                >
                    00001
                </span>
            </div>
        </div>
    </>
}