import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Paragraph from "../components/Paragraph";
import {
  getHighlightTrainerAcademy,
  getLatestTrainerAcademy,
} from "../services/trainer-academy-service";
import H2 from "../components/H2";
import { Link } from "react-router-dom";
import CourseCategoryNavBar from "../components/CourseCategoryNavBar";
import Banner from "../components/Banner";
import { Container, Row, Col } from "react-bootstrap";
import { parseDbStringToObj } from "../util/lang";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function TrainerAcademy() {
  const { t } = useTranslation();

  const [highlightTrainers, setHighlightTrainers] = useState([]);
  const [latestTrainers, setLatestTrainers] = useState([]);
  const [latestTrainers_all, setLatestTrainersAll] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
  });

  useEffect(() => {
    api
      .post("/public/view_trainers", {
      })
      .then(function (response) {
        let allTrainers = response.data.trainers;
        setLatestTrainers(allTrainers);
        setLatestTrainersAll(allTrainers);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });

    api
      .post("/public/view_homepage_trainers", {
      })
      .then(function (response) {
        setHighlightTrainers(response.data.home_page_trainers);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });
  }, []);

  let lastUpdate;
  const search = (e) => {
    let s = e.target.value;
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 500) {
        if (s === "") {
          setLatestTrainers(latestTrainers_all)
        }
        else {
          let c = latestTrainers.filter(function (e) {
            let t = e.Trainer_Name.toLowerCase().includes(s.toLowerCase());
            let d = e.About_Us_Descriptions.toLowerCase().includes(s.toLowerCase());
            return t || d;
          });
          setLatestTrainers(c);
        }
      }
    }, 500);
  };

  return (
    <Layout>
      <Banner
        backgroundImage="/assets/images/hero_banner_trainer.png"
        backgroundSize={"cover"}
      >
        {t('Trainer / Academy')}
      </Banner>
      <Section>
        <Container className="max-width-1304">
          <Row className="mb-4">
            <Col>
              <H2>{t('Highlight Trainer / Academy')}</H2>
              <div style={{ width: 250 }} className="hkqaa-divider" />
            </Col>
          </Row>
          <Row className="gy-3" style={{ marginBottom: 65 }}>
            {highlightTrainers.map((trainerAcademy) => (
              <Col className="d-flex flex-column" xs={6} md={3} key={trainerAcademy.id}>
                <img
                  src={trainerAcademy.Trainer_Logo}
                  alt="Trainer_Logo"
                  className="img-fluid w-100 rounded"
                  style={{
                    objectFit: "cover",
                    height: "100% !important",
                    width: "100% !important",
                    maxHeight: "300px",
                    aspectRatio: "1 / 1"
                  }}
                />

                <div
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "20px",
                    minHeight: "72px",
                  }}
                  className="fw-900 limit-text limit-text-3"
                >
                  {parseDbStringToObj(trainerAcademy.Trainer_Name)[CURRENT_LANGUAGE]} - {t(trainerAcademy.Industry)}
                </div>
                <Paragraph className="limit-text limit-text-md-9 limit-text-sm-4">{parseDbStringToObj(trainerAcademy.About_Us_Descriptions)[CURRENT_LANGUAGE]}</Paragraph>
                <div className="mt-auto">
                  <Link
                    to={"/trainers/show/" + trainerAcademy.id}
                    style={{ color: "#266599", textDecoration: "none" }}
                  >
                    {t('EXPLORE MORE')} &gt;
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="align-items-center flex-column flex-sm-row" style={{ marginBottom: 30 }}>
            <Col>
              <H2>{t('Latest Trainer / Academy')}</H2>
              <div style={{ width: 250 }} className="hkqaa-divider" />
            </Col>
            <Col>
              <form>
                <div className="input-group">
                  <span
                    className="input-group-text bg-white border-end-0 border-004a87"
                    id="search"
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 border-004a87"
                    placeholder={t("Search")}
                    onChange={search}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <CourseCategoryNavBar />
          <Row className="gy-4">
            {latestTrainers.map((trainerAcademy) => (
              <Col className="d-flex flex-column" xs={6} md={3} key={trainerAcademy.id}>
                <img
                  src={trainerAcademy.Trainer_Logo || '/assets/images/home_courses_thumbnail_2.png'}
                  alt="Trainer_Logo"
                  className="img-fluid w-100 rounded"
                  style={{
                    objectFit: "cover",
                    height: "100% !important",
                    width: "100% !important",
                    maxHeight: "300px",
                    aspectRatio: "1 / 1"
                  }}
                />
                <div
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "20px",
                    minHeight: "72px",
                  }}
                  className="fw-900 limit-text limit-text-3"
                >
                  {parseDbStringToObj(trainerAcademy.Trainer_Name)[CURRENT_LANGUAGE]} - {t(trainerAcademy.Industry)}
                </div>
                <Paragraph className="limit-text limit-text-md-9 limit-text-sm-4">{parseDbStringToObj(trainerAcademy.About_Us_Descriptions)[CURRENT_LANGUAGE]}</Paragraph>
                <div className="mt-auto">
                  <Link
                    to={"/trainers/show/" + trainerAcademy.id}
                    style={{ color: "#266599", textDecoration: "none" }}
                  >
                    {t('EXPLORE MORE')} &gt;
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Col xs={"auto"}>
              {/* <a href="#" className="btn bg-eb6201 text-white fw-bold">
                {t('DISCOVER MORE')}
              </a> */}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}
