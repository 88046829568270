import axios from "axios";
import history from "./history";
import { useNavigate } from "react-router-dom";

export function storeTokens(accessToken) {
  window.localStorage.setItem("token", accessToken);
}

export function removeTokens() {
  window.localStorage.clear();
}

export function getTokens() {
  return localStorage.getItem('token');
}

export function getUser() {
  return process.browser ? window.localStorage.getItem("user") : null;
}

export function getApiClient(){
  const token = localStorage.getItem('token')
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
    headers: { "x-access-token": token },
  });

  instance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response) {
      if (error.response.status === 403 && error.response.data?.message === "Invalid Token") {
        history.push('/');
        window.location.reload();
        removeTokens();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  });

  return instance;
}
