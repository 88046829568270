import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LayoutCms from "../../../../components/LayoutCms";
import UploadBox from "../../../../components/UploadBox";
import PreviewBox from "../../../../components/PreviewBox";
import ShadowContainer from "../../../../components/ShadowContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getApiClient } from "../../../../util/auth";
import { getTextByCode } from "../../../../util/lang";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

export default function AdminContentManagementCreateOrEditInsightsAndTrends(props) {
    const navigate = useNavigate();

    // no id = creating a new insights and trends
    const params = useParams();
    const insights_and_trends_id = params.insights_and_trends_id;

    const isEditing = props.isEditing;
    const readOnly = props.readOnly;

    const [insights_and_trends_title_en, set_insights_and_trends_title_en] = useState('');
    const [insights_and_trends_title_tc, set_insights_and_trends_title_tc] = useState('');
    const [insights_and_trends_title_sc, set_insights_and_trends_title_sc] = useState('');
    const [insights_and_trends_content_en, set_insights_and_trends_content_en] = useState('');
    const [insights_and_trends_content_tc, set_insights_and_trends_content_tc] = useState('');
    const [insights_and_trends_content_sc, set_insights_and_trends_content_sc] = useState('');
    const [insights_and_trends_video, set_insights_and_trends_video] = useState(null);
    const [insights_and_trends_photo, set_insights_and_trends_photo] = useState(null);
    const [status, set_status] = useState('');

    const role = localStorage.getItem("role");
    const [admin, setAdmin] = useState(null);

    const api = getApiClient();
    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            if(role === null || role === '') {
                navigate('/login');
            } else {
                navigate('/');
            }
            return;
        }

        api
            .get("/user/viewProfile")
            .then((response) => {
                setAdmin(response.data.user);
            })
            .catch((error) => {
                console.log(error);
            });
        
        if (insights_and_trends_id) {
            api
                .post("/admin/viewInsightsAndTrends", {
                    insights_and_trends_id: insights_and_trends_id,
                })
                .then((response) => {
                    if(response.data.insights_and_trends[0].Status === 'Published' && isEditing) {
                        alert('If you want to edit the insights and trends, please unpublish the insights and trends first!');
                        navigate('/cms/admin/content_management/insights_and_trends');
                        return;
                    } else {
                        set_insights_and_trends_title_en(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Title, 'EN'));
                        set_insights_and_trends_title_tc(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Title, 'TC'));
                        set_insights_and_trends_title_sc(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Title, 'SC'));
                        set_insights_and_trends_content_en(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Content, 'EN'));
                        set_insights_and_trends_content_tc(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Content, 'TC'));
                        set_insights_and_trends_content_sc(getTextByCode(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Content, 'SC'));
                        set_insights_and_trends_photo(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Photo);
                        set_insights_and_trends_video(response?.data?.insights_and_trends?.[0]?.Insights_And_Trends_Video);

                        set_status(response?.data?.insights_and_trends?.[0]?.Status);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const params = {
            // if id exists, DB will UPDATE instead of INSERT
            id: insights_and_trends_id ? insights_and_trends_id : undefined,
            insights_and_trends_title_en: formData.get("insights_and_trends_title_en"),
            insights_and_trends_title_tc: formData.get("insights_and_trends_title_tc"),
            insights_and_trends_title_sc: formData.get("insights_and_trends_title_sc"),
            insights_and_trends_content_en: formData.get("insights_and_trends_content_en"),
            insights_and_trends_content_tc: formData.get("insights_and_trends_content_tc"),
            insights_and_trends_content_sc: formData.get("insights_and_trends_content_sc"),

            insights_and_trends_video: insights_and_trends_video,
            insights_and_trends_photo: insights_and_trends_photo,
        };

        api
            .post("/admin/addOrEditInsightsAndTrends", params)
            .then((response) => {
                if(isEditing || params.id ) {
                    alert(`Insights And Trends saved successfully.`);
                    navigate(`/cms/admin/content_management/insights_and_trends`);
                } else {
                    alert(`Insights And Trends created successfully.`);
                    navigate(`/cms/admin/content_management/insights_and_trends`);
                }

            })
            .catch((error) => {
                console.log("/admin/addOrEditInsightsAndTrends encounters error");
                if(error?.response?.statusText === 'Payload Too Large') {
                    alert('Your input text too long, maximum 5000 characters.');
                } else {
                    alert(error?.response?.data?.message);
                }
            });
    }

    const isArchived = () => { 
        return status === 'Archive';
    }

    return (
        <LayoutCms route={'content_management.insights_and_trends'}>
            <div className={"row align-items-center"}>
                    <div className={"col-7"}>
                        <div className={"text-secondary font-size-20px fw-700"}>
                            {props.readOnly ? 'View' : props.isEditing ? 'Edit' : 'Create'} Insights and Trends Post
                        </div>
                    </div>
            </div>

            <form onSubmit={(e)=>handleSubmit(e)}>
                <ShadowContainer>
                    <Row className="mb-3">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Post Title (ENG)<span className={"text-danger"}>*</span>
                        </label>
                        <Col sm={10} md={10} lg={4}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Post Title (ENG)"}
                            name={"insights_and_trends_title_en"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_title_en}
                            onChange={(e) => set_insights_and_trends_title_en(e.target.value)}
                            maxlength="100"
                        />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Post Title (繁)<span className={"text-danger"}>*</span>
                        </label>
                        <Col sm={10} md={10} lg={4}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Post Title (繁)"}
                            name={"insights_and_trends_title_tc"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_title_tc}
                            onChange={(e) => set_insights_and_trends_title_tc(e.target.value)}
                            maxlength="50"
                        />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Post Title (简)<span className={"text-danger"}>*</span>
                        </label>
                        <Col sm={10} md={10} lg={4}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Post Title (简)"}
                            name={"insights_and_trends_title_sc"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_title_sc}
                            onChange={(e) => set_insights_and_trends_title_sc(e.target.value)}
                            maxlength="50"
                        />
                        </Col>
                    </Row>
                    <Col sm={12} md={12} lg={6} className="mb-3">
                        <label className="text-5c5c5c col-form-label">
                            Post Content (ENG)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
                        <textarea
                            className="form-control"
                            rows="5"
                            style={{ resize: "none" }}
                            name={"insights_and_trends_content_en"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_content_en}
                            onChange={(e) => set_insights_and_trends_content_en(e.target.value)}
                            maxlength="5000"
                        />
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} className="mb-3">
                        <label className="text-5c5c5c col-form-label">
                            Post Content (繁)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
                        <textarea
                            className="form-control"
                            rows="5"
                            style={{ resize: "none" }}
                            name={"insights_and_trends_content_tc"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_content_tc}
                            onChange={(e) => set_insights_and_trends_content_tc(e.target.value)}
                            maxlength="5000"
                        />
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} className="mb-3">
                        <label className="text-5c5c5c col-form-label">
                            Post Content (简)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
                        <textarea
                            className="form-control"
                            rows="5"
                            style={{ resize: "none" }}
                            name={"insights_and_trends_content_sc"}
                            required={true}
                            disabled={readOnly}
                            value={insights_and_trends_content_sc}
                            onChange={(e) => set_insights_and_trends_content_sc(e.target.value)}
                            maxlength="5000"
                        />
                        </div>
                    </Col>
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-3 row">
                        <div className="col-sm-6">
                        <div className={"text-5c5c5c mb-3"}>{"Post Video"}</div>
                        <UploadBox
                            disabled={readOnly}
                            onUploaded={(api_responses) => { set_insights_and_trends_video(api_responses?.[0]?.urls?.[0]) }}
                            accept={{"video/*": ['.mp4']}}
                            endpoint={`/s3/video`}
                            maxSize={600 * 1024 * 1024}
                        />
                        </div>
                    </div>

                    {
                        //the video tag needs a key otherwise the preview will not be refreshed when state change
                        insights_and_trends_video
                        &&
                        <div className="mb-3 row">
                            <div className="col-sm-6">
                                <PreviewBox 
                                    disabled={readOnly}
                                    url={insights_and_trends_video} 
                                    onDelete={() => { set_insights_and_trends_video(null); }} 
                                />
                            </div>
                        </div>
                    }


                    <div className="mb-3 row">
                        <div className="col-sm-6">
                            <div className={"text-5c5c5c mb-3"}>{"Post Photo"}</div>
                            <UploadBox
                                disabled={readOnly}
                                onUploaded={(api_responses) => { set_insights_and_trends_photo(api_responses?.[0]?.urls?.[0]) }}
                                width_ratio={3}
                                height_ratio={2}
                                tolerance={0.1}
                                allowImageEdit
                            />
                        </div>
                    </div>

                    {
                        insights_and_trends_photo
                        &&
                        <div className="mb-3 row">
                            <div className="col-sm-6">
                                <PreviewBox 
                                    disabled={readOnly}
                                    url={insights_and_trends_photo} 
                                    onDelete={() => { set_insights_and_trends_photo(null); }} 
                                />
                            </div>
                        </div>
                    }
                </ShadowContainer>

                <div
                    className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
                    style={{ left: 240 }}
                >
                    <div className={"d-flex"}>
                        {!readOnly
                        ?
                        <div className={"me-3"}>
                            <Button
                                className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                                type={"submit"}
                            >
                                {insights_and_trends_id ? 'Save' : 'Create'}
                            </Button>
                        </div>
                        :
                        ''
                        }
                        <div className={"me-3"}>
                            <Button variant={"secondary"} type={"button"} onClick={()=>navigate(-1)}>
                                {readOnly ? 'Back' : 'Cancel'}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>

            <div style={{height:100}}></div>
        </LayoutCms>
    );
}
