import LayoutCms from "../../../../components/LayoutCms";
import React, { useEffect, useState } from "react";
import CourseTabNavigation from "../../../../components/CourseTabNavigation";
import { getApiClient } from "../../../../util/auth";
import CourseTitle from "../../../../components/CourseTitle";
import CreateModule from "../../../../components/Cms/CreateModule";
import CourseNameCard from "../../../../components/Cms/CourseNameCard";
import { useNavigate, useParams } from "react-router-dom";
import EditModule from "../../../../components/Cms/EditModule";
import PrimaryButton from "../../../../components/PrimaryButton";
import ShadowContainer from "../../../../components/ShadowContainer";
import { parseDbStringToObj } from "../../../../util/lang";
import DraggableDataTable from "./draggableDataTable";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";

export default function Module(props) {
  const navigate = useNavigate();
  
  const params = useParams();
  const readOnly = props.readOnly;
  const isEditing = props.isEditing;

  // TODO throw error if params.course_id is text / invalid / negative etc

  const cid = params.course_id;
  const [course, setCourse] = useState('');
  const [modules, setModules] = useState('');

  const [editModuleId , setEditModuleId] = useState('');
  const [creatingNewModule, setCreatingNewModule] = useState(false);

  useEffect(() => {
    getApiClient()
      .post("/user/view_own_course", {course_id : cid })
      .then((response) => {
        if(isEditing) {
          //Checking if the course is terminated, the course owner cannot edit
          if(response?.data?.course?.[0]?.Is_Terminated === 'Yes') {
            alert('The course has been terminated by HKQAA admin. You cannot edit the course!');
            navigate(-1);
            return;
          }
        }

        setCourse(response.data.course[0]);
        // TODO: Currently the API doesn't return sorted modules on the
        //  Module_Sequence so sorting it on frontend
        const unsortedModules = response.data?.modules ?? [];
        const sortedModules = unsortedModules.sort((a, b) => a.Module_Sequence - b.Module_Sequence);
        setModules(sortedModules);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  , []);

  const updateModuleSequence = (modules) => {
    const modules_ids = modules.map(module => module.id);
    console.log(modules_ids);
    getApiClient()
      .post("/user/editModulesSequence", {course_id : cid, modules_ids })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  const modulesDisplayColumnsDraggable = [
    {
      field: 'id',
      headerName: 'Module ID',
      rowDrag: true,
      width: 150
    },
    {
      headerName: 'Sequence',
      valueGetter: row => row.data.Module_Sequence,
    },
    {
      headerName: 'Title',
      valueGetter: row => parseDbStringToObj(row.data.Module_Title).EN
    },
    {
      headerName: 'Description',
      valueGetter: row => parseDbStringToObj(row.data.Module_Descriptions).EN,
    },
    {
      headerName: 'Updated At',
      valueGetter: (row) => new Date(row.data.updatedAt).toLocaleString(),
    },
    !readOnly &&
    {
      headerName: 'Edit',
      cellRenderer: (row) => {
        if( creatingNewModule ){
          return <Button disabled variant="outline-secondary">Edit</Button>;
        }else{
          return <PrimaryButton onClick={()=>setEditModuleId(row.data.id)}>Edit</PrimaryButton>;
        }
      },
    },
  ];

  const modulesDisplayColumns = [
    {
      name: 'Module ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Sequence',
      selector: row => row.Module_Sequence,
      sortable: true,
    },
    // {
    //   name: 'Code',
    //   selector: row => row.Module_Code,
    //   sortable: true,
    // },
    {
      name: 'Title',
      selector: row => parseDbStringToObj(row.Module_Title).EN,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => parseDbStringToObj(row.Module_Descriptions).EN,
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: row => new Date(row.updatedAt).toLocaleString(),
      sortable: true,
    },
    !readOnly &&
    {
      name: 'Edit',
      cell: (row) => {
        if( creatingNewModule ){
          return <span title="Edit"><Button disabled variant="outline-secondary">Edit</Button></span>;
        }else{
          return <span title="Edit"><PrimaryButton onClick={()=>setEditModuleId(row.id)}>Edit</PrimaryButton></span>;
        }
      },
    },
    readOnly &&
    {
      name: 'View',
      cell: (row) => {
        if( creatingNewModule ){
          return <span title="View"><Button disabled variant="outline-secondary">View</Button></span>;
        }else{
          return <span title="View"><PrimaryButton onClick={()=>setEditModuleId(row.id)}>View</PrimaryButton></span>;
        }
      },
    },
  ];

  return (
    <LayoutCms route={props.readOnly ? 'courses.showModules' : isEditing ? 'courses.editModules' : 'courses.addModules'}>
      <CourseTitle readOnly={readOnly} isEditing={isEditing} />
      <CourseTabNavigation readOnly={readOnly} isEditing={isEditing}  activeNavItem={"module"} />
      <CourseNameCard course={course} />

      {!readOnly &&
      <ShadowContainer className={"edit-module-tbl"}>
        {modules.length === 0 ? <center className={"m-4"}>There are no records to display</center> : ''}
        {modules.length > 0 && (
          <DraggableDataTable
            tableData={modules}
            columnDefs={modulesDisplayColumnsDraggable}
            updateSequence={updateModuleSequence}
          />
        )}
      </ShadowContainer>
      }

      {readOnly &&
      <ShadowContainer className={"edit-module-tbl"}>
        <DataTable
          columns={modulesDisplayColumns}
          data={modules}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30,50,100]}
        />
      </ShadowContainer>
      }


      {editModuleId &&
        <EditModule key={editModuleId}
          module={ modules.filter((m)=> m.id === editModuleId) }
          setEditModuleId={setEditModuleId}
          course_id={cid}
          readOnlyForAll={readOnly}
          />
      }
      {creatingNewModule &&
        <CreateModule course_id={cid} setCreatingNewModule={setCreatingNewModule} />
      }
      {!creatingNewModule && !readOnly && !editModuleId && course?.Course_Status !== 'Archive' &&
        <PrimaryButton onClick={()=>setCreatingNewModule(true)}>Create new Module</PrimaryButton>
      }
    </LayoutCms>
  );
}
