import LayoutCms from "../../../../components/LayoutCms";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminViewLearnerCard from "../../../../components/AdminViewLearnerCard";
import AdminViewCompanyCard from "../../../../components/AdminViewCompanyCard";

export default function ViewUser() {
    const location = useLocation();

    const navigate = useNavigate();
    const role = localStorage.getItem("role");

    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            navigate('/');
            return;
        }
    }, []);

    // define for selected user
    let user = null;

    // if (location.state != null && "user" in location.state) {
    //     user = location.state.user;
    // }
    /** it is because we used open new tab to view user, so we will use localStorage to save and get selected user data */
    if (localStorage.getItem("selectedUser") != null) {
        user = JSON.parse(localStorage.getItem("selectedUser"));
    }

    // to make sure is Admin
    if(role === 'Admin') {
        return (
            <LayoutCms route={'membership.viewUsers'}>
                {(user?.Role === 'Individual Learner' || user?.Role === 'Organization Learner' || user?.Role === 'Association Learner')
                ?
                <AdminViewLearnerCard user={user} IsHideCancelButton={true} />
                :
                <AdminViewCompanyCard user={user} IsHideCancelButton={true} />
                }
            </LayoutCms>
        );
    } else {
        return ('');
    }
}
