export function getLangNameByCode(code) {

    var displayLanguage = localStorage.getItem('i18nLanguage') ?? 'EN';

    var english = {'EN':'English', 'TC':'英文', 'SC':'英文'};
    var tchin = {'EN':'Traditional Chinese', 'TC':'繁體中文', 'SC':'繁体中文'};
    var schin = {'EN':'Simplified Chinese', 'TC':'簡體中文', 'SC':'简体中文'};

  switch (code) {
    case "EN":
      return english[displayLanguage];
    case "TC":
      return tchin[displayLanguage];
    case "SC":
      return schin[displayLanguage];
    default:
      return "English";
  }
}

export function getTextByCode(jsonString, lang='EN') {
  try {
    if(!jsonString) return '';

    if(lang === 'EN' || lang === 'TC' || lang === 'SC' || lang === 'English' || lang === 'Cantonese' || lang === 'Mandarin'){
      return JSON.parse(jsonString)[lang];
    }else{
      return JSON.parse(jsonString).EN;
    }
  } catch (error) {
    console.log("Malformed JSON error... " + error);
    console.log(jsonString);
    return "";
  }
}

export function parseDbStringToObj(dbString){
  try{
    if(!dbString){
      return {EN:'',TC:'',SC:''};
    }else{
      if(typeof dbString === 'object'){
        //convert from Object Literal to JSON Object
        //this converts double quote (JSON) to single quote (obj)
        // console.log('converting...');
        return JSON.parse(JSON.stringify(dbString));
      }else{
        // console.log('direct parse' + typeof dbString + ':' + dbString);
        return JSON.parse(dbString);
      }
    }
  }catch(error){
    //malformed
    console.log("[parseDbJson] Malformed JSON error... " + error);
    console.log(dbString);
    return {EN:'',TC:'',SC:''};
  }
}

export function prepareDbString(obj, value, lang='EN'){
  const result = {...obj, [lang]:value};
  // console.log("wa");
  return result;    //this is JavaScript Obj
}
