import React, { useEffect } from "react";
import { getApiClient } from "../util/auth";

export default function AddStaffsGetCSVTemplate(props) {
    const api = getApiClient();
  
    useEffect(() => {
        api
          .post("/organization/downloadAddStaffsCSVTemplate")
          .then((response) => {
            const blob = new Blob(["\ufeff", response.data], { type: 'text/csv; charset=utf-8' });
            return blob;
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'AddStaffsCSVTemplate.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            props.setShow(false);
          })
          .catch((error) => {
            console.log(error);
            console.log("Trying to get the add staff csv template has been corrupted");
          });
    }, []);
  
    return (
        <>

        </>
    );
}