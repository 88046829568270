import React, {useCallback, useMemo, useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const DraggableDataTable = ({ columnDefs, updateSequence, tableData = [] }) => {
  const PAGE_SIZE = 7;
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState(tableData);
  const defaultColDef = useMemo(() => {
    return {
      width: 300,
      height: 'auto',
      sortable: true,
      filter: true,
    };
  }, []);

  const onRowDragEnd = useCallback((e, data) => {
    if (e.node !== e.overNode) {
      let updatedData = data.slice();
      const offset = e.api.paginationProxy.currentPage * PAGE_SIZE;
      const nodePosition = e.node.rowIndex;
      const overNodePosition = e.overNode.rowIndex + offset;
      moveInArray(updatedData, nodePosition, overNodePosition);
      updateSequence(updatedData);
      setRowData(updatedData);
    }
    function moveInArray(arr, fromIndex, toIndex) {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }, []);

  return (
    <div style={containerStyle}>
      <div className="draggable-table-wrapper">
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            onRowDragEnd={(e) => onRowDragEnd(e, rowData)}
            pagination={true}
            paginationPageSize={PAGE_SIZE}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default DraggableDataTable;
