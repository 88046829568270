import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../components/LayoutCms";
import { Button, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import EyeIcon from "../../../components/EyeIcon";
import ExclamationCircleIcon from "../../../components/ExclamationCircleIcon";
import EvaluationModal from "../../../components/Cms/EvaluationModal";
import QuizModal from "../../../components/Cms/QuizModal";
import PhoneDisplay from "../../../components/PhoneDisplay";
import {getApiClient} from "../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../util/lang";
import DataTable from "react-data-table-component";
import moment from "moment";

const SearchResult = ({ selected_course_id, own_coureses_response, search_result }) => {
  const [course, set_course] = useState();

  const navigate = useNavigate();
  
  const role = localStorage.getItem("role");

  useEffect(() => {
    if(role !== 'Trainer/Academy') {
      alert('User not authorized! You will go back to Home Page.');
      if(role === null || role === '') {
        navigate('/login');
      } else {
        navigate('/');
      }
      return;
    }

    const selected_courses = own_coureses_response?.courses?.filter((course) => {
      return selected_course_id == course.id;
    });
    const selected_course = selected_courses?.[0];
    set_course(selected_course);
  }, [selected_course_id, own_coureses_response]);

  if (!course) {
    return null;
  }

  return (
      <>
        <div className={"text-secondary font-size-20px fw-700"}>Search Result info</div>
        <Row className={"search-results-div"}>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Code</Col>
              <Col>#{course?.Course_Code}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Name</Col>
              <Col>{getTextByCode(course?.Course_Name, 'EN')}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Owner</Col>
              <Col>{getTextByCode(course?.Trainer_Name, 'EN')}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Upload date</Col>
              <Col>{course?.createdAt ? moment(course?.createdAt).format('DD MMM YYYY') : '' }</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Off the shelf date</Col>
              <Col>{course?.Off_The_Shelf_Date ? moment(course?.Off_The_Shelf_Date).format('DD MMM YYYY') : '' }</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Report date</Col>
              <Col>{moment().utc(8).format('DD MMM YYYY LT') }</Col>
            </Row>
          </Col>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={6}>Course confirmed quantities</Col>
              <Col>{search_result?.course_confirmed_quantities ?? 0}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={6}>Course attended</Col>
              <Col>{search_result?.course_attended ? `${search_result?.course_attended}/${search_result?.course_confirmed_quantities ?? 0}` : `0/${search_result?.course_confirmed_quantities ?? 0}`}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={6}>Course completed</Col>
              <Col>{search_result?.course_completed ? `${search_result?.course_completed}/${search_result?.course_confirmed_quantities ?? 0}` : `0/${search_result?.course_confirmed_quantities ?? 0}`}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={6}>Evaluation completed</Col>
              <Col>{search_result?.evaluation_completed ? `${search_result?.evaluation_completed}/${search_result?.course_confirmed_quantities ?? 0}` : `0/${search_result?.course_confirmed_quantities ?? 0}`}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={6}>Quiz</Col>
              <Col>{`Pass ${search_result?.quiz_completed_pass ?? 0}  |  Fail ${search_result?.quiz_completed_fail ?? 0}`}</Col>
            </Row>
          </Col>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Course fee</Col>
              <Col>{course?.Course_Fee === "Pay" ?  "HKD " + course?.Course_Fee_Amount : course?.Course_Fee}</Col>
            </Row>
          </Col>
        </Row>
      </>
  );
}

export default function TrainerReportsIndex() {
  const api = getApiClient();
  const [own_coureses_response, set_own_coureses_response] = useState();
  const [selected_course_id, set_selected_course_id] = useState();
  const [search_result, set_search_result] = useState();
  const [modal_course, set_modal_course] = useState();
  const [modal_learner_id, set_modal_learner_id] = useState();
  const [modal_quiz_id, set_modal_quiz_id] = useState();
  const [is_show_evaluation_modal, set_is_show_evaluation_modal] = useState(false);
  const [is_show_quiz_modal, set_is_show_quiz_modal] = useState(false);

  const [individual_enrollers, set_individual_enrollers] = useState([]);
  const [organization_enrollers, set_organization_enrollers] = useState([]);
  const [organization_learners, set_organization_learners] = useState([]);
  const [association_enrollers, set_association_enrollers] = useState([]);
  const [association_learners, set_association_learners] = useState([]);

  const [filteredEnrollers, setFilteredEnrollers] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const [selectedRole, setSelectedRole] = useState('');

  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedAssociation, setSelectedAssociation] = useState('');

  const [individual_learner_for_csv, set_individual_learner_for_csv] = useState([]);
  const [organization_for_csv, set_organization_for_csv] = useState([]);
  const [association_for_csv, set_association_for_csv] = useState([]);
  const [selected_for_csv, set_selected_for_csv] = useState([]);

  const [organization_learner_for_csv, set_organization_learner_for_csv] = useState([]);
  const [association_learner_for_csv, set_association_learner_for_csv] = useState([]);

  const fetch_organization_learner = (row) => {
    api
      .post("/user/viewLearnerReportsByEnrolledUserAndCourse",
      {
        course_id: row.CourseId,
        enrolled_user_id: row.UserId
      })
      .then((response) => {
        setSelectedOrganization(`${parseDbStringToObj(row?.Organization_Name)?.EN} (${row.UserId})`);

        set_organization_learners((response?.data?.assigned_staffs).filter((learner) => { return learner.Token_ID == row.Token_ID }));

        set_organization_learner_for_csv(response?.data?.organization_learner_for_csv);

        //show the organization learner data table
        let organization_learner = document.getElementById("Organization Learner DataTable");
        organization_learner.classList.remove("display-none");
      })
      .catch((error) => {
        console.log(error);
        if(error?.response?.data?.message === "No any assigned learner details from this organization / association with this course.") {
          //show the organization learner data table
          let organization_learner = document.getElementById("Organization Learner DataTable");
          organization_learner.classList.remove("display-none");
          set_organization_learners([]);
          set_organization_learner_for_csv([]);
        }
      });
  }

  const fetch_association_learner = (row) => {
    api
      .post("/user/viewLearnerReportsByEnrolledUserAndCourse",
      {
        course_id: row.CourseId,
        enrolled_user_id: row.UserId
      })
      .then((response) => {
        setSelectedAssociation(`${parseDbStringToObj(row?.Association_Name)?.EN} (${row.UserId})`);

        set_association_learners((response?.data?.assigned_staffs).filter((learner) => { return learner.Token_ID == row.Token_ID }));

        set_association_learner_for_csv(response?.data?.association_learner_for_csv);

        //show the association learner data table
        let association_learner = document.getElementById("Association Learner DataTable");
        association_learner.classList.remove("display-none");
      })
      .catch((error) => {
        console.log(error);
        if(error?.response?.data?.message === "No any assigned learner details from this organization / association with this course.") {
          //show the association learner data table
          let association_learner = document.getElementById("Association Learner DataTable");
          association_learner.classList.remove("display-none");
          set_association_learners([]);
          set_association_learner_for_csv([]);
        }
      });
  }

  const remarksPopover = (row) => {
    return <Popover id="popover-trigger-focus" title="Remarks">
            {row.Remarks}
    </Popover>
  }

  const individual_columns = [
    {
      name: 'User ID',
      selector: row => row.UserId,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Name',
      selector: row => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Chinese Name',
      selector: row => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email Address',
      selector: row => row.Personal_Email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token ID',
      selector: row => row.Token_ID ?? '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned Date',
      selector: row => row.Assigned_Date,
      format: row => row.Assigned_Date ? moment(row.Assigned_Date).format('DD MMM YYYY') : '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Date Taken',
      selector: row => row.Taken_Date,
      format: (row) => {
        if (!row?.Taken_Date) {
          return '';
        }
        return row?.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Completion Date/ Date Completed',
      selector: row => row.Completed_Date,
      format: (row) => {
        if (row?.Complete_Status !== 'Finished') {
          return '';
        }

        return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Quiz Result',
      cell: (row) => {
        if (!row?.Quiz_Result) {
          return '';
        }

        return (
            <>
              {row?.Quiz_Result}
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_quiz_id(row?.QuizId);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_quiz_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
            </>
        );
      },
      sortable: false,
    },
    {
      name: 'Evaluation result',
      cell: (row) => {
        if (row?.Evaluation > 0) {
          return (
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_evaluation_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
          );
        }
      },
      sortable: false,
    },
    {
      name: 'Certificate',
      selector: row => row.Certificate_URL,
      format: (row) => {
        if(row.Certificate_URL === null || row.Certificate_URL === "") {
            return '';
        }

        return <>
          <Button 
            className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
            type="button" 
            onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
          >
            Print
          </Button>
        </>
      },
      sortable: true,
    },
    {
      name: 'Remarks',
      cell: (row) => {
        return (
          <>
            <span title="Remarks">
              <OverlayTrigger trigger="focus" placement="bottom" overlay={remarksPopover(row)}>
                <button className={"btn"}>
                  <ExclamationCircleIcon />
                </button>
              </OverlayTrigger>
            </span>
          </>
        );
      },
      sortable: false,
      wrap: true,
    },
  ];

  const organization_columns = [
    {
      name: 'User ID',
      selector: row => row?.UserId,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Organization Name',
      selector: row => parseDbStringToObj(row?.Organization_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Contact Person',
      selector: row => parseDbStringToObj(row?.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row?.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email Address',
      selector: row => row?.Organization_Email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Contact Number',
      selector: row => <PhoneDisplay number={row?.Mobile_Number} />,
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Token ID',
      selector: row => row?.Token_ID ?? '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token Quantities',
      selector: row => row?.Purchase_Course_Quantity ?? '',
      sortable: true,
    },
    {
      name: 'Confirmed Date',
      selector: row => row?.Confirmed_Date,
      format: (row) => {
        return row?.Confirmed_Date ? moment(row?.Confirmed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Details / Remarks',
      cell: (row) => {
        return (
          <>
            <span title="View">
              <button
                  className={"btn"}
                  onClick={
                    () => {
                      fetch_organization_learner(row)
                    }
                  }
              >
                <EyeIcon />
              </button>
            </span>
            <span title="Remarks">
              <OverlayTrigger trigger="focus" placement="bottom" overlay={remarksPopover(row)}>
                <button className={"btn"}>
                  <ExclamationCircleIcon />
                </button>
              </OverlayTrigger>
            </span>
          </>
        );
      },
      sortable: false,
    },
  ];

  const organization_learner_columns = [
    {
      name: 'User ID',
      selector: row => row.UserId,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Assigned Learner Name',
      selector: row => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
      grow: 4,
    },
    {
      name: 'Chinese Name',
      selector: row => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token ID',
      selector: row => row.Token_ID ?? '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned Date',
      selector: row => row.Assigned_Date,
      format: row => row.Assigned_Date ? moment(row.Assigned_Date).format('DD MMM YYYY') : '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Date Taken',
      selector: row => row.Taken_Date,
      format: (row) => {
        if (!row?.Taken_Date) {
          return '';
        }
        return row?.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Completion Date/ Date Completed',
      selector: row => row.Completed_Date,
      format: (row) => {
        if (row?.Complete_Status !== 'Finished') {
          return '';
        }

        return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Quiz Result',
      cell: (row) => {
        if (!row?.Quiz_Result) {
          return '';
        }

        return (
            <>
              {row?.Quiz_Result}
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_quiz_id(row?.QuizId);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_quiz_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
            </>
        );
      },
      sortable: false,
    },
    {
      name: 'Evaluation result',
      cell: (row) => {
        if (row?.Evaluation > 0) {
          return (
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_evaluation_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
          );
        }
      },
      sortable: false,
    },
    {
      name: 'Certificate',
      selector: row => row.Certificate_URL,
      format: (row) => {
        if(row.Certificate_URL === null || row.Certificate_URL === "") {
            return '';
        }

        return <>
          <Button 
            className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
            type="button" 
            onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
          >
            Print
          </Button>
        </>
      },
      sortable: true,
    }
  ];

  const association_columns = [
    {
      name: 'User ID',
      selector: row => row?.UserId,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Association Name',
      selector: row => parseDbStringToObj(row?.Association_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Contact Person',
      selector: row => parseDbStringToObj(row?.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row?.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email Address',
      selector: row => row?.Association_Email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Contact Number',
      selector: row => <PhoneDisplay number={row?.Mobile_Number} />,
      sortable: true,
      wrap: true,
      width: '200px',
    },
    {
      name: 'Token ID',
      selector: row => row?.Token_ID ?? '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token Quantities',
      selector: row => row?.Purchase_Course_Quantity ?? '',
      sortable: true,
    },
    {
      name: 'Confirmed Date',
      selector: row => row?.Confirmed_Date,
      format: (row) => {
        return row?.Confirmed_Date ? moment(row?.Confirmed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Details / Remarks',
      cell: (row) => {
        return (
          <>
            <span title="View">
              <button
                  className={"btn"}
                  onClick={
                    () => {
                      fetch_association_learner(row)
                    }
                  }
              >
                <EyeIcon />
              </button>
            </span>
            <span title="Remarks">
              <OverlayTrigger trigger="focus" placement="bottom" overlay={remarksPopover(row)}>
                <button className={"btn"}>
                  <ExclamationCircleIcon />
                </button>
              </OverlayTrigger>
            </span>
          </>
        );
      },
      sortable: false,
    },
  ];

  const association_learner_columns = [
    {
      name: 'User ID',
      selector: row => row.UserId,
      sortable: true,
      grow: 0,
    },
    {
      name: 'Assigned Learner Name',
      selector: row => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
      grow: 4,
    },
    {
      name: 'Chinese Name',
      selector: row => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token ID',
      selector: row => row.Token_ID ?? '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Assigned Date',
      selector: row => row.Assigned_Date,
      format: row => row.Assigned_Date ? moment(row.Assigned_Date).format('DD MMM YYYY') : '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Date Taken',
      selector: row => row.Taken_Date,
      format: (row) => {
        if (!row?.Taken_Date) {
          return '';
        }
        return row?.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Completion Date/ Date Completed',
      selector: row => row.Completed_Date,
      format: (row) => {
        if (row?.Complete_Status !== 'Finished') {
          return '';
        }

        return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Quiz Result',
      cell: (row) => {
        if (!row?.Quiz_Result) {
          return '';
        }

        return (
            <>
              {row?.Quiz_Result}
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_quiz_id(row?.QuizId);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_quiz_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
            </>
        );
      },
      sortable: false,
    },
    {
      name: 'Evaluation result',
      cell: (row) => {
        if (row?.Evaluation > 0) {
          return (
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_evaluation_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
          );
        }
      },
      sortable: false,
    },
    {
      name: 'Certificate',
      selector: row => row.Certificate_URL,
      format: (row) => {
        if(row.Certificate_URL === null || row.Certificate_URL === "") {
            return '';
        }

        return <>
          <Button 
            className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
            type="button" 
            onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
          >
            Print
          </Button>
        </>
      },
      sortable: true,
    }
  ];

  useEffect(() => {
    api
        .post("/user/view_own_courses") //no param needed for this API
        .then((response) => {
          set_own_coureses_response(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

    document.body.classList.add('noPrint');
  }, []);

  const handleDisplay = (e) => {
    let individual = document.getElementById("Individual");
    let organization = document.getElementById("Organization");
    let organization_learner = document.getElementById("Organization Learner DataTable");
    let association = document.getElementById("Association");
    let association_learner = document.getElementById("Association Learner DataTable");

    if(e.target.nodeName === 'SPAN') {
      if(e.target.id === "Individual") {
        setSelectedRole("Individual Learner");

        //handle className
        organization.classList.remove("text-eb6201");
        association.classList.remove("text-eb6201");
        individual.classList.add("text-eb6201");
        organization_learner.classList.add("display-none");
        association_learner.classList.add("display-none");

        //set filteredEnrollers for data table
        setFilteredEnrollers(individual_enrollers);
        setSelectedColumns(individual_columns);
        setSelectedOrganization('');
        setSelectedAssociation('');
        set_selected_for_csv(individual_learner_for_csv);
      } else if(e.target.id === "Organization") {
        setSelectedRole("Organization");

        //handle className
        individual.classList.remove("text-eb6201");
        association.classList.remove("text-eb6201");
        organization.classList.add("text-eb6201");
        association_learner.classList.add("display-none");

        //set filteredEnrollers for data table
        setFilteredEnrollers(organization_enrollers);
        setSelectedColumns(organization_columns);
        set_selected_for_csv(organization_for_csv);
      } else if(e.target.id === "Association") {
        setSelectedRole("Association");

        //handle className
        individual.classList.remove("text-eb6201");
        organization.classList.remove("text-eb6201");
        association.classList.add("text-eb6201");
        organization_learner.classList.add("display-none");

        //set filteredEnrollers for data table
        setFilteredEnrollers(association_enrollers);
        setSelectedColumns(association_columns);
        set_selected_for_csv(association_for_csv);
      }
    }
  }

  const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
  }

  const downloadCSV = (selected_for_csv) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(selected_for_csv);
    if (csv == null) return;

    const filename = selectedRole === "Individual Learner" ? 'Individual Learner Details.csv' : selectedRole === "Organization" ? 'Organization Details.csv' : 'Association Details.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${"\ufeff" + csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const downloadLearnerCSV = (learner_for_csv) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(learner_for_csv);
    if (csv == null) return;

    const filename = selectedRole === "Organization" ? `Organization Learner Details - ${selectedOrganization}.csv` : `Association Learner Details - ${selectedAssociation}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${"\ufeff" + csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  return (
    <LayoutCms route={'reports.index'}>
      <EvaluationModal
          is_show_modal={is_show_evaluation_modal}
          on_modal_hide={() => {
            set_is_show_evaluation_modal(false);
          }}
          learner_id={modal_learner_id}
          course={modal_course}
      />
      <QuizModal
          is_show_modal={is_show_quiz_modal}
          on_modal_hide={() => {
            set_is_show_quiz_modal(false);
          }}
          quiz_id={modal_quiz_id}
          learner_id={modal_learner_id}
          course={modal_course}
      />

      <div className={"row align-items-center"}>
        <div className={"col"}>
          <div className={"text-secondary font-size-20px fw-700"}>Reports</div>
          <div className={"text-adb5bd"}>
            Search a course to view the enrolled learner
          </div>
        </div>
      </div>

      <ShadowContainer>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (ENG)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(e)=> set_selected_course_id(e.target.value)}>
              <option></option>
              {
                own_coureses_response?.courses?.map((course) => (
                  <option value={course.id}>{ JSON.parse(course.Course_Name)?.EN }</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (繁)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(e)=> set_selected_course_id(e.target.value)}>
              <option></option>
              {
                own_coureses_response?.courses?.map((course) => (
                    <option value={course.id}>{ JSON.parse(course.Course_Name)?.TC }</option>
                ))
              }
            </select>
          </div>
        </div>

        <PrimaryButton
          type={"submit"}
          onClick={() => {
            api
                .post("/user/viewEnrolledReportsByCourse", {
                    course_id: selected_course_id,
                })
                .then((response) => {
                    set_search_result(response.data);
                    set_individual_enrollers(response?.data?.individual_learner);
                    set_organization_enrollers(response?.data?.organization);
                    set_association_enrollers(response?.data?.association);

                    set_individual_learner_for_csv(response?.data?.individual_learner_for_csv);
                    set_organization_for_csv(response?.data?.organization_for_csv);
                    set_association_for_csv(response?.data?.association_for_csv);
                    set_selected_for_csv(response?.data?.individual_learner_for_csv);

                    setSelectedRole("Individual Learner");

                    let individual = document.getElementById("Individual");
                    let organization = document.getElementById("Organization");
                    let organization_learner = document.getElementById("Organization Learner DataTable");
                    let association = document.getElementById("Association");
                    let association_learner = document.getElementById("Association Learner DataTable");
                    //handle className
                    organization.classList.remove("text-eb6201");
                    association.classList.remove("text-eb6201");
                    individual.classList.add("text-eb6201");
                    organization_learner.classList.add("display-none");
                    association_learner.classList.add("display-none");

                    //set filteredEnrollers for data table
                    setFilteredEnrollers(response?.data?.individual_learner);
                    setSelectedColumns(individual_columns);
                })
                .catch((error) => {
                    alert(error.response.data.message);
                });
          }}
        >
          Search
        </PrimaryButton>

        <hr />

        <SearchResult
            selected_course_id={selected_course_id}
            own_coureses_response={own_coureses_response}
            search_result={search_result}
        />

      </ShadowContainer>

      <Row className={"align-items-center"}>
        <Col className={"d-flex align-items-center gx-4"}>
          <span className={"text-secondary font-size-20px fw-700"}>Enrolled Learner</span>
        </Col>
      </Row>
      <Row className={"align-items-center"}>
        <Col className={"d-flex align-items-center gx-4"}>
          <span id="Individual" className={"font-size-20px fw-700 hover-eb6201-text"} onClick={(e) => handleDisplay(e)}>Individual</span>
          <span className={"m-3 text-secondary"}>|</span>
          <span id="Organization" className={"font-size-20px fw-700 hover-eb6201-text"} onClick={(e) => handleDisplay(e)}>Organization</span>
          <span className={"m-3 text-secondary"}>|</span>
          <span id="Association" className={"font-size-20px fw-700 hover-eb6201-text"} onClick={(e) => handleDisplay(e)}>Association</span>
        </Col>
      </Row>
      <ShadowContainer>
        <DataTable
            columns={selectedColumns}
            data={filteredEnrollers ?? []}
            pagination
            paginationPerPage={30}
            paginationRowsPerPageOptions={[30,50,100]}
        />
        {filteredEnrollers.length !== 0 &&
        <PrimaryButton
            type={"button"}
            onClick={() => downloadCSV(selected_for_csv)}
        >
          Export
        </PrimaryButton>
        }
      </ShadowContainer>
      <div id="Organization Learner DataTable" className={"display-none"}>
        <Row className={"align-items-center"}>
          <Col className={"d-flex align-items-center gx-4"}>
            <span className={"text-secondary font-size-20px fw-700"}>Organization Details - {selectedOrganization}</span>
          </Col>
        </Row>
        <ShadowContainer>
          <DataTable
              columns={organization_learner_columns}
              data={organization_learners}
              pagination
              paginationPerPage={30}
              paginationRowsPerPageOptions={[30,50,100]}
          />
          {/* {organization_learners.length !== 0 &&
          <PrimaryButton
            type={"button"}
            onClick={() => downloadLearnerCSV(organization_learner_for_csv)}
          >
            Export
          </PrimaryButton>
          } */}
        </ShadowContainer>
      </div>
      <div id="Association Learner DataTable" className={"display-none"}>
        <Row className={"align-items-center"}>
          <Col className={"d-flex align-items-center gx-4"}>
            <span className={"text-secondary font-size-20px fw-700"}>Association Details - {selectedAssociation}</span>
          </Col>
        </Row>
        <ShadowContainer>
          <DataTable
              columns={association_learner_columns}
              data={association_learners}
              pagination
              paginationPerPage={30}
              paginationRowsPerPageOptions={[30,50,100]}
          />
          {/* {association_learners.length !== 0 &&
          <PrimaryButton
            type={"button"}
            onClick={() => downloadLearnerCSV(association_learner_for_csv)}
          >
            Export
          </PrimaryButton>
          } */}
        </ShadowContainer>
      </div>
    </LayoutCms>
  );
}
