export default function Section(props) {
  const style = {
    paddingTop: "50px",
    paddingBottom: "50px",
  };

  return (
    <div style={{ ...style, ...props.style }} className={props.className}>
      {props.children}
    </div>
  );
}
