import ShadowContainer from "./ShadowContainer";
import {Button} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getTextByCode } from "../util/lang";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import PreviewCertificate from "./Cms/PreviewCertificate";

export default function CourseForm(props)
{
    const navigate = useNavigate();
    const handleSubmit = props?.handleSubmit;
    const data = props?.data;
    const course = data?.course?.[0];
    const readOnlyForAll = props?.readOnlyForAll ?? false;

    const [showModal, setShowModal] = useState(false);
    const course_name_en = props?.course_name_en;
    const course_name_tc = props?.course_name_tc;
    const course_name_sc = props?.course_name_sc;
    const course_master_language = props?.course_master_language;
    const user = props?.user;

    const role = localStorage.getItem("role");

    let Off_The_Shelf_Date = '';
    if (course?.Off_The_Shelf_Date) {
        Off_The_Shelf_Date = moment(course?.Off_The_Shelf_Date).format('Y-MM-DD');
    }

    const handleCloseModal = () => setShowModal(false);

    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handlePreview = (event) => {
        event.preventDefault();
        console.log("~ handlePreview ~")
        handleShowModal(event);
    };

    return (
        <>
            <form onSubmit={handleSubmit ? handleSubmit : () => {}}>
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    size={"lg"}
                    scrollable={true}
                    centered
                >
                    <Modal.Header closeButton><p className="m-0 font-size-20px text-a2a2a2">Preview Certificate</p></Modal.Header>
                    <Modal.Body>
                    <PreviewCertificate 
                        course_name_en={course_name_en} 
                        course_name_tc={course_name_tc} 
                        course_name_sc={course_name_sc} 
                        course_master_language={course_master_language} 
                        user={user}
                    />
                    </Modal.Body>
                </Modal>

                <ShadowContainer>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Name (ENG)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Course Name (ENG)"}
                                required={true}
                                readOnly={readOnlyForAll}
                                value={getTextByCode(course?.Course_Name, 'EN')}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Name (繁)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Course Name (繁)"}
                                required={true}
                                readOnly={readOnlyForAll}
                                value={getTextByCode(course?.Course_Name, 'TC')}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Name (简)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Course Name (简)"}
                                required={true}
                                readOnly={readOnlyForAll}
                                value={getTextByCode(course?.Course_Name, 'SC')}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Master Language<span className={"text-danger"}>*</span>
                        </label>
                        <props.CourseMasterLanguage />
                    </div>
                    <div className="mb-3 col-sm-6">
                        <label className="text-5c5c5c col-form-label">
                            Course Descriptions (ENG)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
              <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  required={true}
                  readOnly={readOnlyForAll}
                  value={getTextByCode(course?.Course_Descriptions, 'EN')}
              />
                        </div>
                    </div>
                    <div className="mb-3 col-sm-6">
                        <label className="text-5c5c5c col-form-label">
                            Course Descriptions (繁)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
              <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  required={true}
                  readOnly={readOnlyForAll}
                  value={getTextByCode(course?.Course_Descriptions, 'TC')}
              />
                        </div>
                    </div>
                    <div className="mb-3 col-sm-6">
                        <label className="text-5c5c5c col-form-label">
                            Course Descriptions (简)<span className={"text-danger"}>*</span>
                        </label>
                        <div className="">
              <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  required={true}
                  readOnly={readOnlyForAll}
                  value={getTextByCode(course?.Course_Descriptions, 'SC')}
              />
                        </div>
                    </div>
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Category
                        </label>
                        <props.Categories />
                    </div>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Industry
                        </label>
                        <props.Industries />
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Course Fee
                        </label>
                        <props.CourseFee />
                    </div>
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-4 row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Material Language
                        </label>
                        <props.MaterialLanguage />
                    </div>

                    <div className="row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Video Language
                        </label>
                    </div>

                    <div className="row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">Audio</label>
                        <props.Audio />
                    </div>
                    <div className="row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Subtitle
                        </label>
                        <props.Subtitle />
                    </div>
                </ShadowContainer>

                <ShadowContainer>
                    <props.PreviewVideo />
                    <props.CoursePhoto />
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Trainer
                        </label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" placeholder={""} readOnly={readOnlyForAll} value={course?.Trainer} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Trainer's Organization
                        </label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" placeholder={""} readOnly={readOnlyForAll} value={course?.Trainer_Organization} />
                        </div>
                    </div>
                     <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Trainer's Description
                        </label>
                        <div className="col-sm-4">
                            <textarea
                                className="form-control"
                                rows="5"
                                style={{ resize: "none" }}
                                readOnly={readOnlyForAll}
                                value={course?.Trainer_Description}
                              />
                        </div>
                    </div>
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-3 row">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Off-the-shelf Date<span className={"text-danger"}>*</span>
                        </label>
                        <div className="col-sm-4">
                            <input type="date" className="form-control" placeholder={""} readOnly={readOnlyForAll} value={Off_The_Shelf_Date} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="font-size-12px text-danger col-form-label">
                        *Enrollment validity: until 7 days before the expiry date.
                        </label>
                    </div>
                </ShadowContainer>

                <ShadowContainer>
                    <div className="mb-3 row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Does this course offer certificate?
                        </label>
                        <props.OfferCertificate />
                        <label className="font-size-12px text-danger col-form-label">
                         *The company name, participant name, and course title are shown based on the selected course master language.
                        </label>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <div className="col">
                            <Button variant={"secondary"} type={"button"} onClick={handlePreview}>
                                Preview certificate
                            </Button>
                        </div>
                    </div>
                </ShadowContainer>

                {user?.Role === 'Association'
                ?
                <ShadowContainer>
                    <div className="mb-3 row align-items-center">
                        <label className="text-5c5c5c col-sm-2 col-form-label">
                            Published Course
                        </label>
                        <props.IsPublic />
                    </div>
                </ShadowContainer>
                :
                ''
                }

                <div style={{height:100}}></div>
                {props?.IsHideSaveCancelButton !== true
                ?
                <div
                    className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
                    style={{ left: 240 }}
                >
                    <div className={"d-flex"}>
                        {readOnlyForAll ? <></> : 
                            <div className={"me-3"}>
                                <props.SubmitButton></props.SubmitButton>
                            </div>
                        }
                        <div className={"me-3"}>
                            <Button variant={"secondary"} type={"button"} onClick={()=>navigate(-1)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
                :
                ''
                }
            </form>
        </>
    );
}
