import React, { useEffect, useState } from "react";
import { getApiClient } from "../util/auth";
import { useTranslation } from 'react-i18next';

const get_url_extension = (url) => {
    if (!url) {
        return '';
    }
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

const PreviewElement = (props) => {
    const extension = get_url_extension(props?.url).toLowerCase();
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
        return (
            <img
                alt="img"
                src={props?.url}
                style={{
                    aspectRatio: "1 / 1",
                    objectFit: "cover",
                    width: 200,
                    height: 200,
                }}
            />
        );
    } else if (extension === 'mp4') {
        return (
            <img
                src={"https://images.placeholders.dev/?width=200&height=100&bgColor=%23000&textColor=rgba(255,255,255,0.5)&text=" + extension}
                style={{
                    aspectRatio: "200 / 100",
                    objectFit: "cover",
                    width: 200,
                    height: 100,
                }}
                alt="img"
            />
        );
    }

    return (
        <img
            src={"https://images.placeholders.dev/?width=200&height=100&bgColor=%23000&textColor=rgba(255,255,255,0.5)&text=" + extension}
            style={{
                aspectRatio: "200 / 100",
                objectFit: "cover",
                width: 200,
                height: 100,
            }}
            alt="img"
        />
    );
};

export default function UploadBox(props) {
    const { t } = useTranslation();

    const role = localStorage.getItem("role");

    const [width, set_width] = useState();
    const [height, set_height] = useState();
    const [filename, set_filename] = useState();

    useEffect(() => {
        const extension = get_url_extension(props?.url).toLowerCase();
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
            const img = document.createElement("img");
            img.src = props?.url;
            img.onload = () => {
                set_width(img.width);
                set_height(img.height);
            };
        } else {
            set_width(null);
            set_height(null);
        }

        if (props?.url) {
            const filename_from_url = new URL(props?.url).pathname.split('/').pop();
            set_filename(filename_from_url.substring(37));
        } else {
            set_filename(null);
        }

    }, [props?.url]);

    const viewFile = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }

    const getUrlFromAPI = (s3_url) => {
        getApiClient()
          .post("/user/previewVideo", {
            s3_url: s3_url,
          })
          .then((response) => {
            window.open(response?.data?.s3_signed_url, '_blank', 'noreferrer');
          })
          .catch((error) => {
            console.log(error?.response?.data?.message ?? error);
          })
    }

    return (
        <div className={props.disabled ? 'o-30' : ''}>
            <div
                className={"p-3 d-flex rounded align-items-center preview-box-container"}
                style={{ border: "1px dotted black" }}
            >
                <div className={"me-2"}>
                    <PreviewElement url={props.url} />
                </div>
                <div className={"me-auto"}>
                    {
                        props?.url
                        &&
                        get_url_extension(props?.url).toLowerCase() !== 'mp4'
                        &&
                        <button type="button" onClick={() => { viewFile(props?.url) }}>{role.includes('Learner') ? t('View the File') : 'View the File'}</button>
                    }
                    {
                        props?.url
                        &&
                        get_url_extension(props?.url).toLowerCase() === 'mp4'
                        &&
                        <button type="button" onClick={() => { getUrlFromAPI(props?.url) }}>Preview Video</button>
                    }
                    {
                        filename
                        &&
                        <div style={{ wordBreak: "break-all" }}>{decodeURIComponent(escape(decodeURIComponent(filename)))}</div>
                    }
                    {
                        width && height
                        &&
                        <div>{width} x {height}</div>
                    }
                    <div style={{ color: "#44df43" }}>{role.includes('Learner') ? t('Upload Completed') : 'Upload Completed'}</div>
                </div>
                <div className={"align-self-end"}>
                    {!props.disabled && 
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (props.onDelete) {
                                props.onDelete();
                            }
                        }}
                        className={"btn p-0 m-0"}
                        type={"button"}
                    >
                        {role.includes('Learner') ? t('Delete') : 'Delete'}
                    </button>
                    }
                </div>
            </div>
        </div>
    );
}
