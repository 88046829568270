import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Paragraph from "../../components/Paragraph";
import H2 from "../../components/H2";
import { getAssociations } from "../../services/association-service";
import AssociationNavigation from "../../components/AssociationNavigation";
import Banner from "../../components/Banner";
import LineLimiter from "../../components/LineLimiter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { parseDbStringToObj } from "../../util/lang";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function Association() {
  const { t } = useTranslation();

  const [associations, setAssociations] = useState([]);
  const [allAssociations, setAllAssociations] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
  });

  useEffect(() => {
    api
      .post("/public/view_associations", {
      })
      .then(function (response) {
        setAssociations(response.data.associations);
        setAllAssociations(response.data.associations);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });
  }, []);

  let lastUpdate;
  const search = (e) => {
    let s = e.target.value;
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 500) {
        if (s === "") {
          setAssociations(allAssociations)
        }
        else {
          let c = associations.filter(function (e) {
            let t = e.Association_Name.toLowerCase().includes(s.toLowerCase());
            let d = e.About_Us_Descriptions.toLowerCase().includes(s.toLowerCase());
            return t || d;
          });
          setAssociations(c);
        }
      }
    }, 500);
  };

  return (
    <Layout>
      <Banner
        backgroundImage="/assets/images/hero_banner_association.png"
        backgroundSize={"cover"}
      >
        {t('Association')}
      </Banner>
      <Section>
        <Container className="max-width-1304">
          <Row className="align-items-center flex-column flex-sm-row" style={{ marginBottom: 30 }}>
            <Col>
              <H2>{t('Association')}</H2>
              <div style={{width: 60}} className="hkqaa-divider"/>
            </Col>
            <Col>
              <form>
                <div className="input-group">
                  <span
                    className="input-group-text bg-white border-end-0 border-004a87"
                    id="search"
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0 border-004a87"
                    placeholder={t("Search")}
                    onChange={search}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <AssociationNavigation />
          <Row className="gy-4">
            {associations.map((association) => (
              <Col xs={12} md={6} key={association.id}>
                <div
                  className="bg-white p-3 h-100"
                  style={{ boxShadow: "0 0 20px -11px #000" }}
                >
                  <Row className="h-100">
                    <Col xs={4}>
                      <img
                        src={association.Association_Logo}
                        style={{ aspectRatio: "1 / 1", objectFit: "contain" }}
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={8} className="d-flex flex-column">
                      <Paragraph className="fw-900" style={{overflowWrap:"break-word" }}>
                        {parseDbStringToObj(association.Association_Name)[CURRENT_LANGUAGE]}
                      </Paragraph>
                      <Paragraph><LineLimiter className={'line-clamp-9'}>{parseDbStringToObj(association.About_Us_Descriptions)[CURRENT_LANGUAGE]}</LineLimiter></Paragraph>
                      <div className="mt-auto pt-3">
                      <Link
                        to={"/association/show/" + association.id}
                        style={{ color: "#266599", textDecoration: "none" }}
                      >
                        {t('EXPLORE MORE')} &gt;
                      </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Col xs={'auto'}>
              {/* <a href="#" className="btn bg-eb6201 text-white fw-bold">
                DISCOVER MORE
              </a> */}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}
