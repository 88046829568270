import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { getApiClient } from "../util/auth";
import { removeTokens } from "../util/auth";
import CopyRightLogin from "../components/CopyRightLogin";
import {useTranslation} from "react-i18next";

export default function ForgotUsername(props) {
  removeTokens();
  const { t } = useTranslation();

  return (
    <div
      className="vh-100 d-flex flex-column"
      style={{
        backgroundImage:
          "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png), url(/assets/images/home_background_insights_1.png)",
        backgroundPosition: "0px 5%, 95% 5%, 90% 70%",
        backgroundSize: "auto auto, auto auto, 300px",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundAttachment: "scroll, scroll",
      }}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <Container
          style={{
            maxWidth: "450px",
            boxShadow: "0px 3px 25px #0000001F",
            padding: 40,
          }}
        >
          <div className="text-center mb-3">
            <Link to="/">
              <img src="/assets/images/logo.png" width={175} alt="logo" />
            </Link>
          </div>
          <FormDiv></FormDiv>
          <div className="text-4a4a4a mt-3">
            <Link to="/forgot-password" className={"text-reset"}>
              {t('Forgot password?')}
            </Link>
          </div>
        </Container>
      </div>

      <CopyRightLogin></CopyRightLogin>
    </div>
  );
}

function FormDiv(props) {
  const [formValues, setFormValues] = useState({ email: '' });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const api = getApiClient();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValues.email) {
      return alert(t("Please enter email"));
    }

    api.post(`/user/forgotUsername`, { ...formValues })
      .then(function (response) {
        alert(t("Please check your email."));
        return navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          alert(t(error.response.data.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((formValues) => { return { ...formValues, [name]: value } });
  }

  return <form onSubmit={handleSubmit}>
    <div
      style={{
        fontSize: "24px",
        lineHeight: "24px",
        marginTop: "20px",
        marginBottom: "20px"
      }}
      className="fw-900"
    >
      {t('Forget username')}
    </div>
    <div className="mb-3">
      <label className="form-label fw-bold">{t('Please enter your email')}</label>

      <div className="input-group">
        <span
          className="input-group-text bg-white border-end-0"
          id="email"
        >
          <i className="bi bi-person" />
        </span>
        <input
          type="email"
          className="form-control border-start-0"
          name='email'
          value={formValues.email}
          onChange={(e) => handleChange(e)}
          required={true}
        />
      </div>
    </div>
    <button
      type="submit"
      className={"w-100 btn bg-eb6201 text-white fw-bold"}
    >
      {t('Forgot Username')}
    </button>
  </form>
}
