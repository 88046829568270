import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { getApiClient } from "../../util/auth";
import { getTextByCode } from "../../util/lang";
import PrimaryButton from "../PrimaryButton";
import ShadowContainer from "../ShadowContainer";
import UploadBox from "../UploadBox";
import PreviewBox from "../PreviewBox";

export default function CreateClass(props){
    const course = props.course;
    const course_id = course.id;

    const selectedModuleId = props.selectedModuleId;

    const [modules, setModules] = useState(props.modules);

    const [sequence, set_sequence] = useState(1);

    const [class_title_en, set_class_title_en] = useState('');
    const [class_title_tc, set_class_title_tc] = useState('');
    const [class_title_sc, set_class_title_sc] = useState('');

    const [class_description_en, set_class_description_en] = useState('');
    const [class_description_tc, set_class_description_tc] = useState('');
    const [class_description_sc, set_class_description_sc] = useState('');

    const [class_video, set_class_video] = useState('');
    const [class_materials, set_class_materials] = useState([]);

    const [video_duration, set_video_duration] = useState();

    const api = getApiClient();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedModuleId) {
          alert('Please select a module for the new class.');
        }

        if (!class_video) {
          alert('Please upload at least 1 class video.');
          return;
        }

        if(class_materials.length <= 0) {
          alert('Please upload at least 1 class material.');
          return;
        }

        const params = {
            "course_id": course_id,
            "module_id": selectedModuleId,
            "class_sequence": parseInt(sequence),
            "class_name_en": class_title_en,
            "class_name_tc": class_title_tc,
            "class_name_sc": class_title_sc,
            "class_descriptions_en": class_description_en,
            "class_descriptions_tc": class_description_tc,
            "class_descriptions_sc": class_description_sc,
            // "class_video": formData.get('class_video'),

            // The UploadBox for video and material do not have an input 'name' field defined
            // therefore formData.get() will not be able to get its value
            // instead, we can get it from state, since the custom handler onUploaded is defined

            "class_video": class_video,
            "class_materials": class_materials,
            "video_duration": video_duration,
        };
        api.post("/user/addOrEditClass", params)
            .then((response) => {
              window.location.reload();
            })
            .catch((error) => {
              console.log("/user/addOrEditClass encounters error");
              if(error?.response?.statusText === 'Payload Too Large') {
                alert('Your input text too long, maximum 5000 characters.');
              } else {
                alert(error?.response?.data?.message);
              }
            });
      };

    return <>
        <form onSubmit={handleSubmit}>
        <ShadowContainer>

        <div className="text-secondary h2 my-5">Creating a new class</div>

            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                Module
                </label>
                <div className="col-sm-4">
                <select className="form-control"
                    required={true}
                    disabled
                    readOnly={true}
                    value={selectedModuleId}>
                    {modules?.map((m)=>{
                        return <option key={m.id} value={m.id}>
                            {`${ getTextByCode(m.Module_Title,'EN')} (Code: ${m.Module_Code??''} Sequence: ${m.Module_Sequence} ID: ${m.id})`}
                        </option>;
                    })}
                </select>
                </div>
            </div>
            <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Class Sequence<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="number"
                className="form-control"
                placeholder={"Class Sequence"}
                name={"class_sequence"}
                value={sequence}
                min="1"
                max="999"
                onChange={(e) => set_sequence(e.target.value)}
                required={true}
              />
            </div>
          </div>

            {/*
                    Class Code is not editable
             */}

          <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Class Name (ENG)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder={"Class Name (ENG)"}
                name={"class_name_en"}
                required={true}
                value={class_title_en}
                onChange={(e) => set_class_title_en(e.target.value)}
                maxlength="100"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Class Name (繁)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder={"Class Name (繁)"}
                name={"class_name_tc"}
                required={true}
                value={class_title_tc}
                onChange={(e) => set_class_title_tc(e.target.value)}
                maxlength="50"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
              Class Name (简)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder={"Class Name (简)"}
                name={"class_name_sc"}
                required={true}
                value={class_title_sc}
                onChange={(e) => set_class_title_sc(e.target.value)}
                maxlength="50"
              />
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
              Class Descriptions (ENG)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"class_descriptions_en"}
                required={true}
                value={class_description_en}
                onChange={(e) => set_class_description_en(e.target.value)}
                maxlength="5000"
              />
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
              Class Descriptions (繁)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"class_descriptions_tc"}
                required={true}
                value={class_description_tc}
                onChange={(e) => set_class_description_tc(e.target.value)}
                maxlength="5000"
              />
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
              Class Descriptions (简)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"class_descriptions_sc"}
                required={true}
                value={class_description_sc}
                onChange={(e) => set_class_description_sc(e.target.value)}
                maxlength="5000"
              />
            </div>
          </div>

          {/* <div className={"my-3"}>
            {
                class_video
                &&
                <video width="320" height="240" controls key={class_video}>
                  <source src={class_video} key={`src-${class_video}`}/>
                </video>
            }
          </div> */}

            <div className="mb-3 row">
                <div className="col-sm-6">
                    <div className={"text-5c5c5c mb-3"}>{"Class Video (Maximum Upload 1 Class Video)"}<span className={"text-danger"}>*</span></div>
                    <UploadBox
                        onUploaded={(api_responses) => {
                            set_class_video(api_responses?.[0]?.urls?.[0]) 
                            }}
                        accept={{"video/*": ['.mp4']}}
                        endpoint={`/s3/video`}
                        maxSize={600 * 1024 * 1024}
                        onUpdatedDuration={(duration) => {
                            set_video_duration(duration);
                        }}
                    />
                </div>
            </div>
            {
                class_video
                &&
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <PreviewBox url={class_video} onDelete={() => { set_class_video(null); }} />
                    </div>
                </div>
            }

            <div className="mb-3 row">
                <div className="col-sm-6">
                    <div className={"text-5c5c5c mb-3"}>{"Materials (Minimum Upload 1 Material)"}<span className={"text-danger"}>*</span></div>
                    <UploadBox
                        onUploaded={(api_responses) => {
                            set_class_materials(current => [...current, ...api_responses.map((api_response) => api_response?.urls?.[0])]) }
                        }
                        accept={{
                            "image/*": ['.jpg','.jpeg','.png','.pdf','.doc','.docx','.xls','.xlsx','.ppt','.pptx','.txt','.csv'],
                            // hacky way to bypass the bug in the handling of mixed MIME type and file extension
                            // https://github.com/react-dropzone/react-dropzone/pull/1207/files#diff-ebd8554ec43b07366bd40d16b09d1d063d9e8d5921fa30de4eb80bffdf82668d
                            // there is a PR that fix this but the code was not merged as at 22 June 2022
                        }}
                        endpoint={`/s3/materials`}
                        maxFiles={'unlimited'}
                    />
                </div>
            </div>
            {
                class_materials
                &&
                class_materials.map((class_material, index) => (
                    <div className="mb-3 row" key={index}>
                        <div className="col-sm-6">
                            <PreviewBox url={class_material} onDelete={() => { set_class_materials(class_materials.filter((_, i) => {return i !== index;})); }} />
                        </div>
                    </div>
                ))
            }
        <div className={"d-flex"}>
            <div className={"me-3"}>
              <PrimaryButton
                type={"submit"}
              >
                Save
              </PrimaryButton>
            </div>
            <div className={"me-3"}>
              <Button variant={"secondary"} type={"button"}
              onClick={()=>props.setCreatingNewClass(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </ShadowContainer>
      </form>
    </>

}
