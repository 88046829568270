import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../../components/LayoutCms";
import { Button, Col, Row } from "react-bootstrap";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import { getApiClient, getTokens, removeTokens } from "../../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../../util/lang";
import moment from "moment";

export default function AdminContentManagementTrainerAcademy() {
    const navigate = useNavigate();
    const api = getApiClient();

    const role = localStorage.getItem("role");
    const [admin, setAdmin] = useState('');

    const [selected_trainer_id_no_1, set_selected_trainer_id_no_1] = useState("");
    const [selected_trainer_id_no_2, set_selected_trainer_id_no_2] = useState("");
    const [selected_trainer_id_no_3, set_selected_trainer_id_no_3] = useState("");
    const [selected_trainer_id_no_4, set_selected_trainer_id_no_4] = useState("");

    const [all_trainers_response, set_all_trainers_response] = useState([]);

    //for detect is it super admin
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const fetch_view_all_trainers = () => {
        api.post("/admin/viewAllTrainers")
            .then((response) => {
                set_all_trainers_response(response.data?.all_trainers);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });

        api.post("/admin/getHomePageTrainers")
            .then((response) => {
                set_selected_trainer_id_no_1(response.data.home_page_trainers_ids[0].Display_No_1);
                set_selected_trainer_id_no_2(response.data.home_page_trainers_ids[0].Display_No_2);
                set_selected_trainer_id_no_3(response.data.home_page_trainers_ids[0].Display_No_3);
                set_selected_trainer_id_no_4(response.data.home_page_trainers_ids[0].Display_No_4);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });
    }

    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            if(role === null || role === '') {
                navigate('/login');
            } else {
                navigate('/');
            }
            return;
        }

        api.get("/user/viewProfile")
            .then((response) => {
                setAdmin(response.data?.user);
                if(response.data?.user?.Is_Super_Admin === 'Yes') {
                    setIsSuperAdmin(true);
                } else {
                    setIsSuperAdmin(false);
                }
            })
            .catch((error) => {
                console.log(error);
                removeTokens();
                alert(error?.response?.data?.message ?? error?.message ?? error);
                navigate("/admin");
                return;
            });

        fetch_view_all_trainers();
    }, []);

    if (!admin) {
        return <>Loading...</>;
    }

    const handleSaveHomepageTrainers = () => {
        // if(
        //     selected_trainer_id_no_1 === null || 
        //     selected_trainer_id_no_2 === null || 
        //     selected_trainer_id_no_3 === null || 
        //     selected_trainer_id_no_4 === null
        // ) {
        //     alert('Error! You must select all display Trainer/Academy with the ordering!');
        //     return;
        // }

        if(window.confirm("Are you sure to update the home page ordering of Trainer/Academy?")) {
            api.post("/admin/saveHomePageTrainers", { 
                    display_no_1: selected_trainer_id_no_1,
                    display_no_2: selected_trainer_id_no_2,
                    display_no_3: selected_trainer_id_no_3,
                    display_no_4: selected_trainer_id_no_4
                })
                .then((response) => {
                    alert(response?.data?.message);
                })
                .catch((error) => {
                    alert(error?.response?.data?.message ?? error.message ?? error);
                });
        }
    }

    return (
        <LayoutCms route={'content_management.trainer_academy'}>

            {/** ----------- starting main section with Trainer/Academy content ----------- */}
            <div className={"row align-items-center"}>
                <div className={"col"}>
                    <div className={"text-secondary font-size-20px fw-700"}>
                        Trainer / Academy
                    </div>
                </div>
            </div>

            <ShadowContainer>

                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <div className={"text-secondary font-size-20px fw-700"}>Homepage & Trainer / Academy Page Highlight Trainer / Academy Display List</div>
                    </Col>
                </Row>

                <Row className={"search-results-div"}>
                    <Col>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.1 Highlight Trainer / Academy</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_trainer_id_no_1} 
                                    onChange={(event) => { set_selected_trainer_id_no_1(event.target.value) }}
                                    disabled={!isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_trainers_response.map((trainer) => (
                                            <option value={trainer.id} key={trainer.id}>{getTextByCode(trainer.Trainer_Name, 'EN')} (id: {trainer.id}, Username: {trainer.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.2 Highlight Trainer / Academy</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_trainer_id_no_2} 
                                    onChange={(event) => { set_selected_trainer_id_no_2(event.target.value) }}
                                    disabled={!isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_trainers_response.map((trainer) => (
                                            <option value={trainer.id} key={trainer.id}>{getTextByCode(trainer.Trainer_Name, 'EN')} (id: {trainer.id}, Username: {trainer.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.3 Highlight Trainer / Academy</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_trainer_id_no_3} 
                                    onChange={(event) => { set_selected_trainer_id_no_3(event.target.value) }}
                                    disabled={!isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_trainers_response.map((trainer) => (
                                            <option value={trainer.id} key={trainer.id}>{getTextByCode(trainer.Trainer_Name, 'EN')} (id: {trainer.id}, Username: {trainer.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.4 Highlight Trainer / Academy</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_trainer_id_no_4} 
                                    onChange={(event) => { set_selected_trainer_id_no_4(event.target.value) }}
                                    disabled={!isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_trainers_response.map((trainer) => (
                                            <option value={trainer.id} key={trainer.id}>{getTextByCode(trainer.Trainer_Name, 'EN')} (id: {trainer.id}, Username: {trainer.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {isSuperAdmin
                ?
                <PrimaryButton
                    type={"submit"}
                    className={"mb-2"}
                    style={{ width: '120px' }}
                    onClick={handleSaveHomepageTrainers}
                >
                    Save
                </PrimaryButton>
                :
                ''
                }
            </ShadowContainer>
        </LayoutCms>
    );
}
