import { Button } from "react-bootstrap";

export default function PrimaryButton({...rootDOMAttributes}) {
    const className = `${rootDOMAttributes?.className} bg-eb6201 border-eb6201 hover-eb6201 shadow-none active-ffe27b`;
    const attrs = {
        ...rootDOMAttributes,
        className
    };
    return (
        <Button
            {...attrs}
        >
            {rootDOMAttributes?.children}
        </Button>
    );
}
