import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Bowser from "bowser";
import axios from "axios";
import Layout from "../components/Layout";
import H2 from "../components/H2";
import H5 from "../components/H5";
import Section from "../components/Section";
import Paragraph from "../components/Paragraph";
import CourseItem from "../components/CourseItem";
import CourseCategoryNavBar from "../components/CourseCategoryNavBar";
import LineLimiter from "../components/LineLimiter";
import { getInsightsAndTrends } from "../services/insights-and-trends-service";
import { getHomeAssociations } from "../services/association-service";
import { getHighlightTrainerAcademy } from "../services/trainer-academy-service";
import { useTranslation } from "react-i18next";
import { getCourses_home } from "../services/courses-service";
import { getPartners } from "../services/partners-service";
import { parseDbStringToObj } from "../util/lang";
import CURRENT_LANGUAGE from "../getCurrentLanguage";  //for i18n to display backend data with user clicked language

// courses hard code
import { coursesDataHardcode } from "../services/courses-service";
import CourseItemHC from "../components/CourseItemHC";

export default function Home() {
  const associations = getHomeAssociations();
  const trainerAcademies = getHighlightTrainerAcademy();
  //const courses = getCourses_home();
  const partners = getPartners();
  const { t } = useTranslation();

  const [highlightInsightsAndTrends, setHighlightInsightsAndTrends] = useState([]);
  const [highlightCourses, setHighlightCourses] = useState([]);
  const [highlightTrainers, setHighlightTrainers] = useState([]);
  const [highlightassociations, setHighlightAssociations] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
  });

  useEffect(() => {
    //get the highlighted Insights And Trends List
    api
      .post("/public/view_homepage_insights_and_trends", {
      })
      .then(function (response) {
        setHighlightInsightsAndTrends(response.data.home_page_insights_and_trends);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });

    //get the highlighted Courses List
    api
      .post("/public/view_homepage_courses", {
      })
      .then(function (response) {
        setHighlightCourses(response.data.home_page_courses);
        setCourses(response.data.home_page_courses);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });

    //get the highlighted Trainers List
    api
      .post("/public/view_homepage_trainers", {
      })
      .then(function (response) {
        setHighlightTrainers(response.data.home_page_trainers);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });

    //get the highlighted Associations List
    api
      .post("/public/view_homepage_associations", {
      })
      .then(function (response) {
        setHighlightAssociations(response.data.home_page_associations);
      })
      .catch(function (error) {
        console.log(error.data.response);
      });
  }, []);

  // courses hard code
  const courses_hc = coursesDataHardcode();

  // useEffect(() => {
  //   let isChrome = false;
  //   let isEdge = false;
  //   let isSafari = false;
  //   let isFireFox = false;

  //   const browser = Bowser.getParser(window.navigator.userAgent);

  //   if(browser.getBrowserName() === "Chrome") {
  //     isChrome = true;
  //   }

  //   if(browser.getBrowserName() === "Microsoft Edge") {
  //     isEdge = true;
  //   }

  //   if(browser.getBrowserName() === "Firefox") {
  //     isFireFox = true;
  //   }

  //   if(browser.getBrowserName() === "Safari") {
  //     isSafari = true;
  //   }

  //   if(!isChrome && !isEdge && !isFireFox && !isSafari) {
  //     alert('It is recommended to use the latest version of Chrome, Edge, Firefox or Safari when viewing our platform. Your online experience may be affected if you are using other browsers or older versions of the above-mentioned browsers.');
  //   }
  // }, []);

  const [courses, setCourses] = useState(highlightCourses);

  let lastUpdate;
  const searchCourses = (e) => {
    let s = e.target.value;
    lastUpdate = Date.now();
    setTimeout(() => {
      const diff = Date.now() - lastUpdate;
      if (diff > 100) {
        if (s === "") {
          setCourses(highlightCourses)
        }
        else {
          let c = highlightCourses.filter(function (e) {
            let course_name = parseDbStringToObj(e.Course_Name).EN;
            let course_descriptions = parseDbStringToObj(e.Course_Descriptions).EN;
            let t = course_name.toLowerCase().includes(s.toLowerCase());
            let d = course_descriptions.toLowerCase().includes(s.toLowerCase());
            let cc = e.Course_Code.toLowerCase().includes(s.toLowerCase());
            return t || d || cc;
          });
          setCourses(c);
        }
      }
    }, 100);
  };


  return (
    <Layout>
      <div className="home-banner"
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(/assets/images/hero_background.png)",
          backgroundPosition: "0px 0px, 0px 0px",
          backgroundSize: "cover",
        }}
      >
        <Container
          className="max-width-1304"
          style={{ height: 600, paddingTop: 20 }}
        >
          <Row className="h-100 align-items-center">
            <Col>
              <H2
                className="font-size-sm-30px text-eb6201"
                style={{ textShadow: "4px 4px 6px #000" }}
              >
                {t('LIGHTSHARE')}
              </H2>
              <H2
                className="font-size-sm-32px text-white"
                style={{ textShadow: "4px 4px 6px #000" }}
              >
                {t('KNOWLEDGE EXCHANGE PLATFORM')}
              </H2>
              <div
                style={{
                  color: "#ffe500",
                  lineHeight: "32px",
                  fontWeight: "bold",
                  textShadow: "4px 4px 6px #000",
                  marginTop: "20px",
                  display: "flex",
                }}
                className={"font-size-18px font-size-md-32px"}
              >
                {t('INSPIRING')}{" "}
                <span style={{ fontSize: 16 }} className={"mx-2"}>
                  •
                </span>
                {t('SHARING')}{" "}
                <span style={{ fontSize: 16 }} className={"mx-2"}>
                  •
                </span>
                {t('NETWORKING')}
              </div>
              <Link
                to="/about-us"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
                style={{ marginTop: "1em" }}
              >
                {t('EXPLORE MORE')}
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Section className="home-insights-bg"
        style={{
          backgroundImage:
            "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png)",
          backgroundPosition: "0px 5%, 95% 5%",
          backgroundSize: "auto auto",
          backgroundRepeat: "no-repeat no-repeat",
          backgroundAttachment: "scroll, scroll",
        }}
      >
        <Container className="max-width-1304">
          <Row>
            <Col>
              <H2 className="font-size-sm-40px text-center">{t('Insights And Trends')}</H2>
              <div
                style={{ width: 350 }}
                className="hkqaa-divider mx-auto mb-4"
              />
              <H5 style={{ marginBottom: "40px" }} className="text-center">
                {t('Stay ahead of megatrends with visionary perspectives')}
              </H5>
            </Col>
          </Row>
          <Row>
            {highlightInsightsAndTrends.map((insightsAndTrend) => (
              <Col xs={6} md={3} key={insightsAndTrend.id} className="d-flex flex-column">
                <img
                  src={insightsAndTrend.Insights_And_Trends_Photo}
                  alt="thumb"
                  className="img-fluid w-100"
                  style={{ aspectRatio: "300 / 200", objectFit: "cover" }}
                />
                <div
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "20px",
                    minHeight: "72px",
                  }}
                  className="fw-900 limit-text limit-text-3"
                >
                  {parseDbStringToObj(insightsAndTrend.Insights_And_Trends_Title)[CURRENT_LANGUAGE]}
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    lineHeight: "24px",
                    marginTop: "20px",
                  }}
                >
                  {moment(insightsAndTrend.Last_Published_Date)?.format('DD MM YYYY')}
                </div>
                <LineLimiter>
                  {parseDbStringToObj(insightsAndTrend.Insights_And_Trends_Content)[CURRENT_LANGUAGE]}
                </LineLimiter>
                <div className="mt-auto pt-3">
                  <Link
                    to={"/insights-and-trends/show/" + insightsAndTrend.id}
                    style={{ color: "#266599", textDecoration: "none" }}
                  >
                    {t('EXPLORE MORE')} &gt;
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <div className="col-auto">
              <Link reloadDocument
                to="/insights-and-trends"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
              >
                {t('DISCOVER MORE')}
              </Link>
            </div>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container className="max-width-1304">
          <Row className="align-items-end" style={{ marginBottom: 30 }}>
            <Col>
              <H2 className="font-size-sm-40px" style={{ marginTop: "0 !important" }}>{t('Courses')}</H2>
              <div style={{ width: 60 }} className="hkqaa-divider" />
            </Col>
            {/* <Col>
              <div className="input-group">
                <span
                  className="input-group-text bg-white border-end-0"
                  id="search"
                >
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control border-start-0"
                  placeholder={t("Search")}
                  id="course-search-input"
                  onChange={searchCourses}
                />
              </div>
            </Col> */}
          </Row>
          <CourseCategoryNavBar />
          <Row className="gy-4" id="courses-container">
            {courses.map((course) => (
              <Col xs={6} md={4} className="d-flex flex-column course-item">
                <CourseItem
                  course={course}
                />
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <Col xs={'auto'}>
              <Link reloadDocument
                to="/courses"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
              >
                {t('DISCOVER MORE')}
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>


      {/* <Section>
        <Container className="max-width-1304">
          <Row className="align-items-end" style={{ marginBottom: 30 }}>
            <Col>
              <H2 className="font-size-sm-40px" style={{ marginTop: "0 !important" }}>{t('Courses')}</H2>
              <div style={{ width: 60 }} className="hkqaa-divider" />
            </Col>
            <Col>
              <form>
                <div className="input-group">
                  <span
                    className="input-group-text bg-white border-end-0"
                    id="search"
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0"
                    placeholder={t("Search")}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <CourseCategoryNavBar />
          <Row className="gy-4">
            {courses_hc.map((course) => (
              <Col xs={6} md={4} className="d-flex flex-column">
                <CourseItemHC
                  courseId={course.id}
                  imageUrl={course.Course_Photo}
                  title={course.Course_Name_EN}
                  description={course.Course_Description_EN}
                  trainer={course.Trainer}
                  trainer_link={course.Trainer_Id ? "trainers/show/" + course.Trainer_Id : ""}
                />
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <Col xs={'auto'}>
              <Link reloadDocument
                to="/courses"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
              >
                {t('DISCOVER MORE')}
              </Link>
            </Col>
          </Row>
        </Container>
      </Section> */}


      <Section
        style={{
          backgroundImage:
            "url(/assets/images/home_background_trainer_1.png), url(/assets/images/home_background_trainer.png)",
          backgroundColor: "#fafafa",
          backgroundPosition: "100% 0%, 0% 120%",
          backgroundSize: "40%, 40%",
          backgroundRepeat: "no-repeat no-repeat",
        }}
      >
        <Container className="max-width-1304">
          <Row className="mb-4">
            <Col>
              <H5 className="text-center">{t('Profile / Spotlight')}</H5>
              <H2 className="font-size-sm-40px text-center">{t('Trainer / Academy')}</H2>
              <div
                style={{ width: 200 }}
                className="hkqaa-divider mx-auto"
              ></div>
              <Paragraph className="text-center">
                {t('Pick the potential trainer/academy and review their offer')}
              </Paragraph>
            </Col>
          </Row>
          <Row className="gy-4">
            {highlightTrainers.map((trainerAcademy) => (
              <Col xs={12} md={6} key={trainerAcademy.id}>
                <div
                  className="bg-white p-3 h-100"
                  style={{ boxShadow: "0 0 20px -11px #000" }}
                >
                  <Row className="h-100">
                    <Col xs={4}>
                      <img
                        src={trainerAcademy.Trainer_Logo}
                        alt="Trainer_Logo"
                        style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                        className="img-fluid w-100"
                      />
                    </Col>
                    <Col xs={8} className="d-flex flex-column">
                      <Paragraph className="fw-900 limit-text limit-text-3">
                      {parseDbStringToObj(trainerAcademy.Trainer_Name)[CURRENT_LANGUAGE]} - {t(trainerAcademy.Industry)}
                      </Paragraph>
                      <Paragraph className="limit-text limit-text-4 limit-text-md-9">{parseDbStringToObj(trainerAcademy.About_Us_Descriptions)[CURRENT_LANGUAGE]}</Paragraph>
                      <div className="mt-auto pt-3">
                        <Link
                          to={"/trainers/show/" + trainerAcademy.id}
                          style={{ color: "#266599", textDecoration: "none" }}
                        >
                          {t('EXPLORE MORE')} &gt;
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <Col xs={'auto'}>
              <Link reloadDocument
                to="/trainer-academy"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
              >
                {t('DISCOVER MORE')}
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section
        style={{
          backgroundImage:
            "url(/assets/images/home_background_association_1.png)",
          backgroundColor: "#fff",
          backgroundPosition: "100% 0%",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="max-width-1304">
          <Row className="mb-4">
            <Col>
              <H5 className="text-center">{t('Profile / Spotlight')}</H5>
              <H2 className="text-center font-size-sm-40px">{t('Association')}</H2>
              <div
                style={{ width: 200 }}
                className="hkqaa-divider mx-auto"
              ></div>
              <Paragraph className="text-center">
                {t('Pick the potential association and review their offer')}
              </Paragraph>
            </Col>
          </Row>
          <Row className="gy-4">
            {highlightassociations.map((association) => (
              <div className="col-12 col-md-6" key={association.id}>
                <div
                  className="bg-white p-3 h-100"
                  style={{ boxShadow: "0 0 20px -11px #000" }}
                >
                  <div className="row h-100">
                    <div className="col-4">
                      <img
                        src={association.Association_Logo}
                        alt="thumb"
                        style={{ aspectRatio: "1 / 1", objectFit: "contain" }}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-8 d-flex flex-column">
                      <Paragraph className="fw-900">
                      {parseDbStringToObj(association.Association_Name)[CURRENT_LANGUAGE]}
                      </Paragraph>
                      <Paragraph className="limit-text limit-text-8 limit-text-md-9">{parseDbStringToObj(association.About_Us_Descriptions)[CURRENT_LANGUAGE]}</Paragraph>
                      <div className="mt-auto pt-3">
                        <Link
                          to={"/association/show/" + association.id}
                          style={{ color: "#266599", textDecoration: "none" }}
                        >
                          {t('EXPLORE MORE')} &gt;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
          <div
            className="row justify-content-center"
            style={{ marginTop: "50px" }}
          >
            <div className="col-auto">
              <Link reloadDocument
                to="/association"
                className="btn bg-eb6201 text-white fw-bold shadow-none"
              >
                {t('DISCOVER MORE')}
              </Link>
            </div>
          </div>
        </Container>
      </Section>
      <Section
        style={{
          backgroundImage: "url(/assets/images/home_background_started_1.png)",
          backgroundColor: "#fff",
          backgroundPosition: "0% 100%",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="max-width-1304">
          <Row className="mb-4">
            <Col className="d-flex flex-column align-items-center">
              <H5>{t('How We Work?')}</H5>
              <div className="d-flex flex-column align-items-center">
                <H2 className="font-size-sm-40px">{t('Let\'s get started.')}</H2>
                <div style={{ width: 100 }} className="hkqaa-divider"></div>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 100 }}>
            <Col xs={12} md={3} className="text-center">
              <img src="/assets/images/home_started_icon_1.png" alt="icon" />
              <Paragraph className="font-size-sm-22px fw-bold">{t('Individual Member')}</Paragraph>
              <Paragraph className="text-start">
                {t('Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan.')}
              </Paragraph>
            </Col>
            <Col xs={12} md={3} className="text-center">
              <img src="/assets/images/home_started_icon_2.png" alt="icon" />
              <Paragraph className="font-size-sm-22px fw-bold">{t('Organization Member')}</Paragraph>
              <Paragraph className="text-start">
                {t('Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report.')}
              </Paragraph>
            </Col>
            <Col xs={12} md={3} className="text-center">
              <img src="/assets/images/home_started_icon_3.png" alt="icon" />
              <Paragraph className="font-size-sm-22px fw-bold">
                {t('Trainer and Academy Member')}
              </Paragraph>
              <Paragraph className="text-start">
                {t('Can through this platform to share & promote their training course, also this platform is providing the participant analysis report.')}
              </Paragraph>
            </Col>
            <Col xs={12} md={3} className="text-center">
              <img src="/assets/images/home_started_icon_4.png" alt="icon" />
              <Paragraph className="font-size-sm-22px fw-bold">{t('Association Member')}</Paragraph>
              <Paragraph className="text-start">
                {t('Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report.')}
              </Paragraph>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column align-items-center">
                <H2 className="font-size-sm-40px">{t('Joining the course')}</H2>
                <div style={{ width: 140 }} className="hkqaa-divider"></div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 gy-4 joining-course-container">
            <Col xs={12} md={3} className="home-course-1">
              <Link to="/signup" style={{
                color: "#000",
                textDecoration: "none"
              }}>
                <div
                  className="d-flex flex-column"
                  style={{
                    backgroundColor: "#fbdfcc",
                    padding: "15px",
                    borderRadius: "5px",
                    height: "339px",
                  }}
                >
                  <div className="">
                    <H5 style={{ fontSize: 28 }}>{t('Step: 1')}</H5>
                    <div
                      style={{
                        fontSize: 20,
                        lineHeight: "18px",
                        marginTop: 0,
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {t('Registration')}
                    </div>
                  </div>

                  <Paragraph className="mt-auto" style={{ height: "25%" }}>
                    {t('Click the link and create your username & password then you will become a member right away.')}
                  </Paragraph>
                </div>
              </Link>
            </Col>
            <Col xs={12} md={3}>
              <div
                className="d-flex flex-column"
                style={{
                  backgroundColor: "#fbdfcc",
                  padding: "15px",
                  borderRadius: "5px",
                  height: "339px",
                }}
              >
                <div className="">
                  <H5 style={{ fontSize: 28 }}>{t('Step: 2')}</H5>
                  <div
                    style={{
                      fontSize: 20,
                      lineHeight: "18px",
                      marginTop: 0,
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t('Select the course')}
                  </div>
                </div>
                <Paragraph className="mt-auto" style={{ height: "25%" }}>
                  {t('Select the interesting course and apply it anytime, anywhere.')}
                </Paragraph>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div
                className="d-flex flex-column"
                style={{
                  backgroundColor: "#fbdfcc",
                  padding: "15px",
                  borderRadius: "5px",
                  height: "339px",
                }}
              >
                <div className="">
                  <H5 style={{ fontSize: 28 }}>{t('Step: 3')}</H5>
                  <div
                    style={{
                      fontSize: 20,
                      lineHeight: "18px",
                      marginTop: 0,
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Receive the token")}
                  </div>
                </div>
                <Paragraph className="mt-auto" style={{ height: "25%" }}>
                  {t('Trainer will assign the token number for you to join the specific course.')}
                </Paragraph>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div
                className="d-flex flex-column"
                style={{
                  backgroundColor: "#fbdfcc",
                  padding: "15px",
                  borderRadius: "5px",
                  height: "339px",
                }}
              >
                <div className="">
                  <H5 style={{ fontSize: 28 }}>{t('Step: 4')}</H5>
                  <div
                    style={{
                      fontSize: 20,
                      lineHeight: "18px",
                      marginTop: 0,
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t('Join the course')}
                  </div>
                </div>
                <Paragraph className="mt-auto" style={{ height: "25%" }}>
                  {t('Member can join the course.')}
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="home-signup-div"
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/images/home_background_signup_1.png)",
          backgroundPosition: "0 0 0 0",
          backgroundSize: "auto auto",
        }}
      >
        <Container className="max-width-1304">
          <Row>
            <Col className="text-center">
              <img src="/assets/images/logo_white.png" width={175} alt="logo" />
              <H2 className="hs-h2 font-size-sm-40px" style={{ color: "#eb6101" }}>
                {t('Inspiring, Sharing and Networking')}
              </H2>
              <Paragraph
                className="hs-t text-white fw-bold mt-4"
                style={{ fontSize: "24px" }}
              >
                {t('Sign up free today!')}
              </Paragraph>
            </Col>
          </Row>
          <Row
            className="hs-btn-div justify-content-center"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Col xs={'auto'}>
              <Link
                to="/signup"
                className="btn bg-eb6201 text-white fw-bold btn-lg px-5"
              >
                {t('SIGN UP')}
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container className="max-width-1304">
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column align-items-center">
                <H2 className="font-size-sm-40px">{t('HKQAA Services and Campaigns')}</H2>
                <div style={{ width: 190 }} className="hkqaa-divider"></div>
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-center"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            {partners.map((partner) => (
              <Col md={2} className="d-flex flex-column col-6">
                <a href={t(partner.url)} target="_blank" rel="noreferrer">
                  <img
                    alt="logo"
                    src={partner.image}
                    className={"img-fluid"}
                    style={{ width: "100%" }}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}