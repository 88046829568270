import React, { useEffect, useState } from "react";
import { getApiClient } from "../../util/auth";
import { parseDbStringToObj } from "../../util/lang";
import { get_filename_from_url } from "../../services/url-service";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function LearnerCertificates(props){
    const { t } = useTranslation();

    const [certificates, setCertificates] = useState([]);
    const [firstFourCertificates, setFirstFourCertificates] = useState([]);

    const api = getApiClient();
    useEffect(() => {
        api.post(props.endpoint, { learner_id: props.user.id })
            .then((response) => {
                setCertificates(response.data.total_certificate);
                setFirstFourCertificates(response.data.total_certificate);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleDisplayCertificates = () => {
        let firstFourCertificatesTableBody = document.getElementById("firstFourCertificatesTableBody");
        let allCertificatesTableBody = document.getElementById("allCertificatesTableBody");
        
        allCertificatesTableBody.classList.remove("d-none");
        firstFourCertificatesTableBody.classList.add("d-none");
    }

    return <>
        <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
            <div className="row align-items-end mb-3">
                <div className="col">
                    <div
                    className={
                        "mb-3 fw-bold font-size-20px text-decoration-underline"
                    }
                    >
                        {t('My Certificates')}
                    </div>
                    <div className={"fw-bold font-size-30px"}>{certificates.length ?? 0}</div>
                    <div>{t('Total Certificate')}</div>
                </div>
                <div className="col">
                    <div className="input-group">
                    {/* <span
                        className="input-group-text bg-white border-end-0"
                        id="search"
                    >
                        <i className="bi bi-search"></i>
                    </span>
                    <input
                        type="text"
                        className="form-control border-start-0"
                        placeholder={t("Search")}
                    /> */}
                    </div>
                </div>
            </div>
            <hr className="mb-5" />
            <div className="row mb-3 overflow-auto">
                <table className="table text-center">
                    <thead>
                        <tr className={"border"}>
                            <td scope="col">
                                {t('Course Name')} {/* <i className="bi bi-arrow-down-up" /> */}
                            </td>
                            <td scope="col">
                                {t('Certificate Code')} {/* <i className="bi bi-arrow-down-up" /> */}
                            </td>
                            <td scope="col">
                                {t('Given By')} {/* <i className="bi bi-arrow-down-up" /> */}
                            </td>
                            <td scope="col">
                                {t('Date')} {/* <i className="bi bi-arrow-down-up" /> */}
                            </td>
                            <td scope="col">{t('Actions')}</td>
                        </tr>
                    </thead>
                    <tbody id="firstFourCertificatesTableBody" className={"border-0"}>
                        {firstFourCertificates.slice(0, 4).map((certificate) => 
                            <tr className={"border"}>
                                <td style={{ paddingTop: 45, paddingBottom: 45, overflowWrap:"break-word", maxWidth:450, minWidth:100}}>
                                    {parseDbStringToObj(certificate.Course_Name)[CURRENT_LANGUAGE]}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {certificate.Certificate_No}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45,overflowWrap:"break-word", maxWidth:450, minWidth:100 }}>
                                    {
                                    certificate.Role === 'Organization'
                                    ?
                                    parseDbStringToObj(certificate.Organization_Name)[CURRENT_LANGUAGE]
                                    :
                                    certificate.Role === 'Trainer/Academy'
                                    ?
                                    parseDbStringToObj(certificate.Trainer_Name)[CURRENT_LANGUAGE]
                                    :
                                    certificate.Role === 'Association'
                                    ?
                                    parseDbStringToObj(certificate.Association_Name)[CURRENT_LANGUAGE]
                                    :
                                    ''
                                    }
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {moment(certificate?.updatedAt).utc(8).format('DD/MM/YYYY')}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    <button
                                        className={"btn p-0"}
                                        onClick={() => { window.open(certificate.Certificate_URL, '_blank', 'noreferrer') }}
                                    >
                                        <i className="bi bi-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        )}

                        <tr className={"border"}>
                            <td colSpan={5}>
                            {certificates.length ?
                            <button 
                                className="btn w-100 text-eb6201 rounded-0"
                                onClick={handleDisplayCertificates}
                            >
                                {t('View All Certificates')}
                            </button>
                            :
                            <div className="w-100 my-4 text-secondary">{t('No certificates to display.')}</div>
                            }
                            </td>
                        </tr>
                    </tbody>

                    <tbody id="allCertificatesTableBody" className={"border-0 d-none"}>
                        {certificates.map((certificate) => 
                            <tr className={"border"}>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {parseDbStringToObj(certificate.Course_Name)[CURRENT_LANGUAGE]}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {decodeURIComponent(escape(decodeURIComponent( get_filename_from_url(certificate.Certificate_URL).substring(37).split(".", 1) )))}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {
                                    certificate.Role === 'Organization'
                                    ?
                                    parseDbStringToObj(certificate.Organization_Name)[CURRENT_LANGUAGE]
                                    :
                                    certificate.Role === 'Trainer/Academy'
                                    ?
                                    parseDbStringToObj(certificate.Trainer_Name)[CURRENT_LANGUAGE]
                                    :
                                    certificate.Role === 'Association'
                                    ?
                                    parseDbStringToObj(certificate.Association_Name)[CURRENT_LANGUAGE]
                                    :
                                    ''
                                    }
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    {moment(certificate?.updatedAt).utc(8).format('DD/MM/YYYY')}
                                </td>
                                <td style={{ paddingTop: 45, paddingBottom: 45 }}>
                                    <button
                                        className={"btn p-0"}
                                        onClick={() => { window.open(certificate.Certificate_URL, '_blank', 'noreferrer') }}
                                    >
                                        <i className="bi bi-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}