import React from "react";

export default function AppVersion(props){
    const darkBackground = props.darkBackground ?? false;

    return <span 
    style={darkBackground ? 
        {marginLeft:15,fontSize:12,color:'#79a'}
        :
        {marginLeft:15,fontSize:12}
    }>ver{process.env.REACT_APP_VERSION}</span>;
    
}