import React from "react";
import { useTranslation } from 'react-i18next';

export default function AgeGroup(){
    const { t } = useTranslation();

    return <>
        <option value="" default hidden>--</option>
        <option value="16-20">16-20</option>
        <option value="21-30">21-30</option>
        <option value="31-40">31-40</option>
        <option value="41-50">41-50</option>
        <option value="51-60">51-60</option>
        <option value="61 and above">{t('61 and above')}</option>
    </>;
}