import React from "react";
import { useTranslation } from 'react-i18next';

export default function Department(){
    const { t } = useTranslation();

    return <>
        <option value="" default hidden>--</option>
        <option value={"Customer Service"}>{t('Customer Service')}</option>
        <option value={"Design"}>{t('Design')}</option>
        <option value={"UI/UX"}>{t('UI/UX')}</option>
        <option value={"Project Management"}>{t('Project Management')}</option>
        <option value={"Development"}>{t('Development')}</option>
        <option value={"Engineering"}>{t('Engineering')}</option>
        <option value={"Administrative"}>{t('Administrative')}</option>
        <option value={"Welfare"}>{t('Welfare')}</option>
        <option value={"Sales"}>{t('Sales')}</option>
        <option value={"Marketing"}>{t('Marketing')}</option>
        <option value={"Human Resources"}>{t('Human Resources')}</option>
        <option value={"R&D"}>{t('R&D')}</option>
        <option value={"Testing"}>{t('Testing')}</option>
        <option value={"Finance"}>{t('Finance')}</option>
        <option value={"Account"}>{t('Account')}</option>
        <option value={"Learning and Development"}>{t('Learning and Development')}</option>
        <option value={"Quality"}>{t('Quality')}</option>
        <option value={"Environmental"}>{t('Environmental')}</option>
        <option value={"Health & Safety"}>{t('Health & Safety')}</option>
        <option value={"Promotion"}>{t('Promotion')}</option>
        <option value={"I.T. Management"}>{t('I.T. Management')}</option>
        <option value={"Secretary"}>{t('Secretary')}</option>
        <option value={"Supporting Service"}>{t('Supporting Service')}</option>
        <option value={"Public Relation"}>{t('Public Relation')}</option>
        <option value={"Legal"}>{t('Legal')}</option>
        <option value={"Innovation"}>{t('Innovation ')}</option>
        <option value={"Transportation"}>{t('Transportation')}</option>
        <option value={"Others"}>{t('Others ')}</option>
    </>
}