import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../components/LayoutCms";
import { Button, Col, Row } from "react-bootstrap";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import EyeIcon from "../../../components/EyeIcon";
import EvaluationModal from "../../../components/Cms/EvaluationModal";
import QuizModal from "../../../components/Cms/QuizModal";
import {getApiClient} from "../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../util/lang";
import DataTable from "react-data-table-component";
import moment from "moment";

const SearchResult = ({ selected_course_id, own_coureses_response, assigned_staffs_response }) => {
  const [course, set_course] = useState();
  const [total_course_attended, set_total_course_attended] = useState();
  const [total_course_completed, set_total_course_completed] = useState();
  const [total_evaluation_completed, set_total_evaluation_completed] = useState();
  const [total_pass, set_total_pass] = useState();
  const [total_fail, set_total_fail] = useState();

  const navigate = useNavigate();
  
  const role = localStorage.getItem("role");

  useEffect(() => {
    if(role !== 'Organization') {
      alert('User not authorized! You will go back to Home Page.');
      if(role === null || role === '') {
        navigate('/login');
      } else {
        navigate('/');
      }
      return;
    }

    const selected_courses = own_coureses_response?.courses?.filter((course) => {
      return selected_course_id == course.id;
    });
    const selected_course = selected_courses?.[0];
    set_course(selected_course);
  }, [selected_course_id, own_coureses_response]);

  useEffect(() => {
    const attended = assigned_staffs_response?.assigned_staffs?.filter((assigned_staff) => {
      return assigned_staff?.Taken_Date;
    });
    set_total_course_attended(attended?.length);

    const completed = assigned_staffs_response?.assigned_staffs?.filter((assigned_staff) => {
      return assigned_staff?.Complete_Status === 'Finished';
    });
    set_total_course_completed(completed?.length);

    const evaluation_completed = assigned_staffs_response?.assigned_staffs?.filter((assigned_staff) => {
      return assigned_staff?.Evaluation > 0;
    });
    set_total_evaluation_completed(evaluation_completed?.length);

    const passes = assigned_staffs_response?.assigned_staffs?.filter((assigned_staff) => {
      return assigned_staff?.Quiz_Result == 'Pass';
    });
    set_total_pass(passes?.length);

    const fails = assigned_staffs_response?.assigned_staffs?.filter((assigned_staff) => {
      return assigned_staff?.Quiz_Result == 'Fail';
    });
    set_total_fail(fails?.length);
  }, [assigned_staffs_response]);

  if (!course) {
    return null;
  }

  return (
      <>
        <div className={"text-secondary font-size-20px fw-700"}>Search Result info</div>
        <Row className={"search-results-div"}>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Code</Col>
              <Col>#{course?.Course_Code}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Name</Col>
              <Col>{getTextByCode(course?.Course_Name, 'EN')}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Owner</Col>
              <Col>{getTextByCode(course?.Organization_Name, 'EN') ? getTextByCode(course?.Organization_Name, 'EN') : getTextByCode(course?.Trainer_Name, 'EN')}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Upload date</Col>
              <Col>{course?.createdAt ? moment(course?.createdAt).format('DD MMM YYYY') : '' }</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Off the shelf date</Col>
              <Col>{course?.Off_The_Shelf_Date ? moment(course?.Off_The_Shelf_Date).format('DD MMM YYYY') : ''}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Report date</Col>
              <Col>{moment().utc(8).format('DD MMM YYYY LT') }</Col>
            </Row>
          </Col>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Purchase date</Col>
              <Col>{course?.Purchase_Date ? moment(course?.Purchase_Date).format('DD MMM YYYY') : 'NA'}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Purchase token quantities</Col>
              <Col>{course?.Purchased_Token_Quantities ?? 'NA'}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Token ID</Col>
              <Col>{course?.Course_Token_ID}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Course fee</Col>
              <Col>{course?.Course_Fee === 'Pay' ? 'HKD ' + course?.Course_Fee_Amount : course?.Course_Fee}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Course attended</Col>
              <Col>
                {
                  assigned_staffs_response?.assigned_staffs
                    &&
                    <>
                      {total_course_attended}/{ assigned_staffs_response?.assigned_staffs?.length ?? 0 }
                    </>
                }
              </Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Course completed</Col>
              <Col>
                {
                  assigned_staffs_response?.assigned_staffs
                    &&
                    <>
                      {total_course_completed}/{ assigned_staffs_response?.assigned_staffs?.length ?? 0 }
                    </>
                }
              </Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Evaluation completed</Col>
              <Col>
                {
                    assigned_staffs_response?.assigned_staffs
                    &&
                    <>
                      {total_evaluation_completed}/{ assigned_staffs_response?.assigned_staffs?.length ?? 0 }
                    </>
                }
              </Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Quiz</Col>
              <Col>
                {
                    assigned_staffs_response?.assigned_staffs
                    &&
                    <>
                      Pass {total_pass} | Fail {total_fail}
                    </>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </>
  );
}

export default function Index() {
  const api = getApiClient();
  const [own_coureses_response, set_own_coureses_response] = useState();
  const [selected_course_id, set_selected_course_id] = useState();
  const [assigned_staffs_response, set_assigned_staffs_response] = useState();
  const [modal_course, set_modal_course] = useState();
  const [modal_learner_id, set_modal_learner_id] = useState();
  const [modal_quiz_id, set_modal_quiz_id] = useState();
  const [is_show_evaluation_modal, set_is_show_evaluation_modal] = useState(false);
  const [is_show_quiz_modal, set_is_show_quiz_modal] = useState(false);

  const [filteredStaffs, setFilteredStaffs] = useState([]);

  //for selected option
  const [filterBy, setFilterBy] = useState('All');
  const [courseCompletedStaffs, setCourseCompletedStaffs] = useState([]);
  const [courseNotCompleteStaffs, setCourseNotCompleteStaffs] = useState([]);
  const [quizPassStaffs, setQuizPassStaffs] = useState([]);
  const [quizFailStaffs, setQuizFailStaffs] = useState([]);

  const [organization_learner_for_csv, set_organization_learner_for_csv] = useState([]);

  const columns = [
    {
      name: 'Staff ID',
      selector: row => row.Staff_Id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Chinese Name',
      selector: row => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Email Address',
      selector: row => row.Organization_Email,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Token ID',
      selector: row => row.Token_ID ?? '',
      sortable: true,
    },
    {
      name: 'Assigned Date',
      selector: row => row.Assigned_Date,
      format: row => row.Assigned_Date ? moment(row.Assigned_Date).format('DD MMM YYYY') : '',
      sortable: true,
    },
    {
      name: 'Date Taken',
      selector: row => row.Taken_Date,
      format: (row) => {
        if (!row?.Taken_Date) {
          return '';
        }
        return row?.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
    },
    {
      name: 'Completion Date/ Date Completed',
      selector: row => row.Completed_Date,
      format: (row) => {
        if (row?.Complete_Status !== 'Finished') {
          return '';
        }

        return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
      },
      sortable: true,
    },
    {
      name: 'Quiz Result',
      cell: (row) => {
        if (!row?.Quiz_Result) {
          return '';
        }

        return (
            <>
              {row?.Quiz_Result}
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_quiz_id(row?.QuizId);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_quiz_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
            </>
        );
      },
      sortable: false,
    },
    {
      name: 'Evaluation result',
      cell: (row) => {
        if (row?.Evaluation > 0) {
          return (
              <span title="View">
                <button
                    className={"btn"}
                    onClick={
                      () => {
                        set_modal_course(own_coureses_response?.courses?.filter(course => selected_course_id == course.id)?.[0]);
                        set_modal_learner_id(row?.UserId);
                        set_is_show_evaluation_modal(true);
                      }
                    }
                >
                  <EyeIcon />
                </button>
              </span>
          );
        }
      },
      sortable: false,
    },
    {
      name: 'Certificate',
      selector: row => row.Certificate_URL,
      format: (row) => {
        if(row.Certificate_URL === null || row.Certificate_URL === "") {
            return '';
        }

        return <>
          <Button 
            className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
            type="button" 
            onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
          >
            Print
          </Button>
        </>
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    api
        .post("/user/view_own_courses") //no param needed for this API
        .then((response) => {
          set_own_coureses_response(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

    document.body.classList.add('noPrint');
  }, []);

  const handleFilter = (e) => {
    let value = e.target.value;

    //need to assign the original auth and course data into the object
    let selectedData = {};
    selectedData.auth = assigned_staffs_response.auth;
    selectedData.course = assigned_staffs_response.course;

    if(value === 'All') {
      setFilteredStaffs(assigned_staffs_response);
      setFilterBy('All');
    } else if(value === 'Course Completed') {
      selectedData.assigned_staffs = assigned_staffs_response.assigned_staffs.filter(staff => (String(staff.Complete_Status) == 'Finished'));
      setFilteredStaffs(selectedData);
      setCourseCompletedStaffs(selectedData);
      setFilterBy('Course Completed');
    } else if(value === 'Course Not Complete') {
      selectedData.assigned_staffs = assigned_staffs_response.assigned_staffs.filter(staff => (String(staff.Complete_Status) == 'Not Finish'));
      setFilteredStaffs(selectedData);
      setCourseNotCompleteStaffs(selectedData);
      setFilterBy('Course Not Complete');
    } else if(value === 'Quiz Pass') {
      selectedData.assigned_staffs = assigned_staffs_response.assigned_staffs.filter(staff => (String(staff.Quiz_Result) == 'Pass'));
      setFilteredStaffs(selectedData);
      setQuizPassStaffs(selectedData);
      setFilterBy('Quiz Pass');
    } else if(value === 'Quiz Fail') {
      selectedData.assigned_staffs = assigned_staffs_response.assigned_staffs.filter(staff => (String(staff.Quiz_Result) == 'Fail'));
      setFilteredStaffs(selectedData);
      setQuizFailStaffs(selectedData);
      setFilterBy('Quiz Fail');
    }
  }

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    let searchArray = {};
    searchArray.auth = array.auth;
    searchArray.course = array.course;
    
    searchArray.assigned_staffs = array.assigned_staffs.filter(value => {
      value.Name = parseDbStringToObj(value.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(value.Contact_Person_Last_Name)?.EN;
      value.Chinese_Name = parseDbStringToObj(value.Contact_Person_First_Name)?.TC + ' ' + parseDbStringToObj(value.Contact_Person_Last_Name)?.TC;
      
      return String(value.Staff_Id).replace(null, '').toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Chinese_Name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.Organization_Email.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      String(value.Token_ID).replace(null, '').toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      String(moment(value.Assigned_Date).utc(8).format('DD MMM YYYY')).toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      String(moment(value.Taken_Date).utc(8).format('DD MMM YYYY')).toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      String(moment(value.Completed_Date).utc(8).format('DD MMM YYYY')).toLowerCase().match(new RegExp(searchTerm, 'g'))
    });
    return searchArray;
  }

  const handleSearch = async (e) => {
    //user typed keywords
    let value = e.target.value;

    //checking condition to search the keywords base on the selected option
    let staffsArray = assigned_staffs_response;
    //console.log(staffsArray)
    if(filterBy === 'All') {
      staffsArray = assigned_staffs_response;
    } else if(filterBy === 'Course Completed') {
      staffsArray = courseCompletedStaffs;
    } else if(filterBy === 'Course Not Complete') {
      staffsArray = courseNotCompleteStaffs;
    } else if(filterBy === 'Quiz Pass') {
      staffsArray = quizPassStaffs;
    } else if(filterBy === 'Quiz Fail') {
      staffsArray = quizFailStaffs;
    }

    //check condition to handle search user typed keywords
    if (value.length > 0) {
      let search = await arraySearch(staffsArray, value);
      setFilteredStaffs(search);
    } else {
      setFilteredStaffs(staffsArray);
    }
  }

  const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
  }

  const downloadCSV = (organization_learner_for_csv) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(organization_learner_for_csv);
    if (csv == null) return;

    const filename = 'Organization Learner Details.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${"\ufeff" + csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  return (
    <LayoutCms route={'reports.index'}>
      <EvaluationModal
          is_show_modal={is_show_evaluation_modal}
          on_modal_hide={() => {
            set_is_show_evaluation_modal(false);
          }}
          learner_id={modal_learner_id}
          course={modal_course}
      />
      <QuizModal
          is_show_modal={is_show_quiz_modal}
          on_modal_hide={() => {
            set_is_show_quiz_modal(false);
          }}
          quiz_id={modal_quiz_id}
          learner_id={modal_learner_id}
          course={modal_course}
      />

      <div className={"row align-items-center"}>
        <div className={"col"}>
          <div className={"text-secondary font-size-20px fw-700"}>Reports</div>
          <div className={"text-adb5bd"}>
            Search a course to view the assigned details
          </div>
        </div>
      </div>

      <ShadowContainer>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (ENG)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(e)=> set_selected_course_id(e.target.value)}>
              <option></option>
              {
                own_coureses_response?.courses?.map((course) => (
                  <option value={course.id}>{ JSON.parse(course.Course_Name)?.EN }</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (繁)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(e)=> set_selected_course_id(e.target.value)}>
              <option></option>
              {
                own_coureses_response?.courses?.map((course) => (
                    <option value={course.id}>{ JSON.parse(course.Course_Name)?.TC }</option>
                ))
              }
            </select>
          </div>
        </div>

        <PrimaryButton
          type={"submit"}
          onClick={() => {
            api
                .post("/user/viewAssignedStaffsByCourse", {
                  course_id: selected_course_id,
                })
                .then((response) => {
                  set_assigned_staffs_response(response.data);
                  setFilteredStaffs(response.data);
                  set_organization_learner_for_csv(response.data.organization_learner_for_csv);
                })
                .catch((error) => {
                  alert(error.response.data.message);
                  set_assigned_staffs_response([]);
                  setFilteredStaffs([]);
                  set_organization_learner_for_csv([]);
                });
          }}
        >
          Search
        </PrimaryButton>

        <hr />

        <SearchResult
            selected_course_id={selected_course_id}
            own_coureses_response={own_coureses_response}
            assigned_staffs_response={assigned_staffs_response}
        />

      </ShadowContainer>

      <Row className={"align-items-center"}>
        <Col className={"d-flex align-items-center gx-4"}>
          <span className={"text-secondary font-size-20px fw-700"}>Assigned Staff</span>
          <span
            className={"ms-5 text-white p-2 rounded"}
            style={{ background: "#A2A2A2" }}
          >
            { filteredStaffs?.assigned_staffs?.length ?? 0 }
          </span>
        </Col>
        <Col xs={"auto"} className={"d-flex align-items-center"}>
          <div className={"mx-2"}>Filter By</div>
          <div className={"mx-2"}>
            <select className="form-select" onChange={handleFilter}>
              <option value="All">All</option>
              <option value="Course Completed">Course Completed</option>
              <option value="Course Not Complete">Course Not Complete</option>
              <option value="Quiz Pass">Quiz Pass</option>
              <option value="Quiz Fail">Quiz Fail</option>
            </select>
          </div>
          <div className={"mx-2"}>
            <input className="form-control" placeholder={"Search"} onChange={handleSearch} />
          </div>
        </Col>
      </Row>
      <ShadowContainer>
        <DataTable
            columns={columns}
            data={filteredStaffs?.assigned_staffs ?? []}
            pagination
            paginationPerPage={30}
            paginationRowsPerPageOptions={[30,50,100]}
        />
        {filteredStaffs?.assigned_staffs?.length !== 0 &&
        <PrimaryButton
            type={"button"}
            onClick={() => downloadCSV(organization_learner_for_csv)}
        >
          Export
        </PrimaryButton>
        }
      </ShadowContainer>
    </LayoutCms>
  );
}
