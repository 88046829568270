import React, { useRef, useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import CourseProgress from "../components/CourseProgress";
import ProfileLayout from "../components/ProfileLayout";
import ProfileMenu from "../components/ProfileMenu";
import { get_nav_headers } from "../services/sidebar-service";
import Container from "react-bootstrap/Container";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LearnerCertificates from "../components/Cms/LearnerCertificates";
import EyeIcon from "../components/EyeIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MyCourses from "../components/MyCourses";
import { getLangNameByCode, parseDbStringToObj } from "../util/lang";
import ProfileTop from "../components/ProfileTop";
import { getApiClient } from "../util/auth";
import PhoneDisplay from "../components/PhoneDisplay";
import PhoneInput from "react-phone-input-2";
import AssignedCoursesDatatable from "../components/AssignedCoursesDatatable";
import WatchAgainModal from "../components/WatchAgainModal";
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { i18n, t } = useTranslation();

  const [user, setUser] = useState('');
  const [is_show_watch_again_modal, set_is_show_watch_again_modal] = useState(false);
  const [selected_course, set_selected_course] = useState();
  const [allCourseModal, setAllCourseModal] = useState(false);

  const [createdAt, setCreatedAt] = useState('');

  let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage') ?? 'EN';

  const api = getApiClient();

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let temp_user = response.data.user;

        if(response.data.user.Role !== 'Individual Learner') {
          alert(t('User not authorized! You will go back to Home Page.'));
          navigate('/');
          return;
        }

        if(temp_user.Preferred_Language !== null && temp_user.Preferred_Language !== '') {
          // if user clicked other lang in home page and it is not their preferred language, it will do reload to prevent bug
          // if same as preferred language, no need to reload
          if(temp_user.Preferred_Language !== localStorage.getItem('i18nLanguage')) {
            i18n.changeLanguage(temp_user.Preferred_Language);
            localStorage.setItem('i18nLanguage', temp_user.Preferred_Language);
            CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
            window.location.reload();
          } else {
            i18n.changeLanguage(temp_user.Preferred_Language);
            localStorage.setItem('i18nLanguage', temp_user.Preferred_Language);
            CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
          }
        }

        temp_user.Contact_Person_First_Name = parseDbStringToObj(temp_user.Contact_Person_First_Name);
        temp_user.Contact_Person_Last_Name = parseDbStringToObj(temp_user.Contact_Person_Last_Name);

        setCreatedAt(moment(temp_user?.createdAt)?.format('DD/MM/YYYY'));

        setUser(temp_user);
      })
      .catch((error) => {
        alert(t(error.response.data.message ?? error.message ?? error));
      });
  }, []);

  const refs = {
    profileInformationRef: useRef(),
    dataAnalysisRef: useRef(),
    myCoursesRef: useRef(),
    myCertificatesRef: useRef(),
    CURRENT_LANGUAGE: CURRENT_LANGUAGE,
  }
  const nav_headers = get_nav_headers(refs);

  if (!user) {
    return <>{t('Loading')}...</>;
  }

  return (
    <ProfileLayout>
      <WatchAgainModal
          is_show_modal={is_show_watch_again_modal}
          on_modal_hide={() => {
            set_is_show_watch_again_modal(false);
          }}
          course={selected_course}
      />

      <Modal show={allCourseModal} onHide={() => { setAllCourseModal(false) }} centered size="lg">
        <Modal.Header className={"border-0 pb-0"} closeButton />
        <Modal.Body className={"pb-0"}>
          <div className={"mb-3"}>
            {t('Description')}
          </div>
        </Modal.Body>
        <Modal.Footer className={"border-0 pt-0"}>
          <Col>
            <Button
              variant="primary"
              className="w-100 bg-eb6201 text-white fw-bold shadow-none border-eb6201 my-0"
              onClick={() => { setAllCourseModal(false) }}
            >
              {t('View')}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <div style={{ backgroundColor: "#F5F5F8" }}>
        <Container fluid className="p-0">
          <Row className={"no-gutters gx-0"}>
            <ProfileMenu navHeader={nav_headers}></ProfileMenu>
            <Col className="profile-container p-5">
              <div className={"position-relative"} ref={nav_headers.get('profileInformation').ref} id={nav_headers.get('profileInformation').id}>
                <div
                  className={"position-absolute position-sm-relative"}
                  style={{ left: 0, top: -40, right: 0 }}
                >
                  <div className="ms-md--0 ms-5 max-width-1304 px-5">
                    <Row className={"align-items-center"}>
                      <ProfileTop user={user} CURRENT_LANGUAGE={CURRENT_LANGUAGE}/>
                      <div className="col-auto" style={{ marginTop: "8px"}}>
                        <Link
                          to={"/profile/edit"}
                          className={"btn bg-eb6201 text-white"}
                        >
                          {t('Edit')}
                        </Link>
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="profile-info-container ms-md--0 ms-5 max-width-1304 bg-white p-5 mb-5 mt-5">
                  <Row className="my-3">
                    <hr />
                  </Row>
                  <Row className="mb-3">
                    <div className="col fw-bold font-size-20px text-decoration-underline">
                      {t('Personal Information')}
                    </div>
                  </Row>

                  <Row className="staff-info-container font-size-16px">
                    <Col sm="12" md="6">
                      <Row>
                        <div className="col-4">{t('Username')}:</div>
                        <div className="col-8 break-word">{user.Username}</div>
                      </Row>
                      <div className="row">
                        <div className="col-4">{t('Email')} (1):</div>
                        <div className="col-8 break-word">{user.Personal_Email}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Email')} (2):</div>
                        <div className="col-8 break-word">
                          {user.Personal_Email_2nd}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Office Number')}:</div>
                        <div className="col-8 break-word">
                          <PhoneDisplay number={user?.Office_Number} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Mobile Number')}:</div>
                        <div className="col-8 break-word">
                          <PhoneDisplay number={user?.Mobile_Number} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Gender')}:</div>
                        <div className="col">{t(user.Gender)}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Age group')}:</div>
                        <div className="col">{user.Age_Group}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Education Level')}:</div>
                        <div className="col">{t(user.Education_Level)}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Join elearning date')}:</div>
                        <div className="col">{createdAt}</div>
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="row">
                        <div className="col-4">{t('Department')}:</div>
                        <div className="col">{t(user.Department_1)}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Year Joined')}:</div>
                        <div className="col-8 break-word">{user.Year_Joined}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Preferred Language')}:</div>
                        <div className="col">{getLangNameByCode(user.Preferred_Language)}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Interested Topics')}:</div>
                        <div className="col">{t(user.Interested_Topics)}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5" ref={nav_headers.get('dataAnalysis').ref} id={nav_headers.get('dataAnalysis').id}>
                <div className="row align-items-center mb-3">
                  <div className="col fw-bold font-size-20px text-decoration-underline">
                    {t('Data Analysis')}
                  </div>
                </div>

                <CourseProgress user={user} />
              </div>

              <div ref={nav_headers.get('myCourses').ref} id={nav_headers.get('myCourses').id}>
                <MyCourses view_courses_endpoint={'/individual_learner/view_courses'} CURRENT_LANGUAGE={CURRENT_LANGUAGE}></MyCourses>
              </div>

              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <Row>
                  <Col>
                    <AssignedCoursesDatatable 
                      on_watch_again_clicked={(row) => {
                        set_selected_course(row);
                        set_is_show_watch_again_modal(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>

              {/* Since there is no cert yet, we borrow LearnerCertificates component as a placeholder */}
              <div ref={nav_headers.get('myCertificates').ref} id={nav_headers.get('myCertificates').id}>
                <LearnerCertificates user={user} endpoint={"/user/viewTotalCertificate"} />
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ProfileLayout>
  );
}
