import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import H2 from "../../components/H2";
import ClassVideo from "../../components/ShowModule/ClassVideo";
import ClassItemHC from "../../components/ShowModule/ClassItemHC";
import { Collapse } from "bootstrap/dist/js/bootstrap.esm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getApiClient } from "../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../util/lang";
import { get_filename_from_url } from "../../services/url-service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { coursesDataHardcode } from "../../services/courses-service";

const sort_class_indicator = (a, b) => {
  if (a.ModuleId < b.ModuleId) {
    return -1;
  } else if (a.ModuleId == b.ModuleId) {
    return a.Class_Sequence - b.Class_Sequence;
  } else {
    return 1;
  }
};

// const get_viewed_class_responses_by_module_id = async (modules, course_id) => {
//   let viewed_class_responses_by_module_id = {};
//   for (const module of modules) {
//     const viewed_class_response = await getApiClient().post('/user/getViewedClassId', {
//       course_id: course_id,
//       module_id: module.id,
//     });
//     viewed_class_responses_by_module_id[module.id] = viewed_class_response?.data;
//   }
//   return viewed_class_responses_by_module_id;
// }

export default function CourseShowModuleHC() {

  const params = useParams();
  let course_id = parseInt(params.course_id);
  let class_id = parseInt(params.class_id);

  const co = coursesDataHardcode(course_id);
  const mo = co.modules
  const result = mo.reduce((r, { type, classes }) => {
    let o = classes.filter(({ id }) => id === class_id);
    if (o && o.length)
      r.push({ type, classes: [...o] });
    return r;
  }, []);

  const viewingClass = result[0].classes[0]

  const [activeCollapses, setActiveCollapses] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // const [viewingClass, setViewingClass] = useState("");

  const [course, setCourse] = useState();
  const [modules, setModules] = useState();
  const [classes, setClasses] = useState();
  const [view_quiz_response, set_view_quiz_response] = useState();
  const [viewed_class_responses, set_viewed_class_responses] = useState({});

  // the position of current class among all classes
  // we assume classes should be sorted by : ModuleId ASC, Class.Sequence ASC
  const [currentPosition, setCurrentPosition] = useState(0);


  /**
   * TODO rewrite needed :
   * - use useParam instead of useSearchParams so we can define and name the param in route
   * - Error handling when class is empty
   *
   * Here is what's going on :
   * - before arriving at this page, user click a `Course`
   * - no information about which module / class to view
   * - so we try to get the very first class available for this class
   */
  const api = getApiClient();
  useEffect(() => {
    // getAllInCourse();

    if (!class_id) {
      // getFirstClassByCourseId();
    }

    api.post("/user/view_quiz", { course_id: course_id })
      .then((response) => {
        set_view_quiz_response(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchParams]);

  /**
   * Get all Classes, all Modules in the Course
   * specified by course_id
   */
  // const getAllInCourse = () => {
  //   const params = {
  //     course_id: course_id,
  //   };
  //   api.post('/organization_learner/view_course', params)
  //     .then(async (response) => {
  //       setCourse(response?.data?.course?.[0]);
  //       setModules(response?.data?.modules);

  //       let res_classes = response?.data?.classes;
  //       res_classes.sort(sort_class_indicator);
  //       setClasses(res_classes);

  //       // const responses = await get_viewed_class_responses_by_module_id(response?.data?.modules, course_id);
  //       // set_viewed_class_responses(responses);

  //       if (class_id) {
  //         const target_classes = res_classes.filter((class_item) => class_item.id === parseInt(class_id));
  //         if (target_classes.length > 0) {
  //           const target_class = target_classes[0];

  //           setCurrentPosition(res_classes.indexOf(target_class));
  //           // setViewingClass(target_class);
  //           /**
  //            * Toggling the module is very confusing, when page loads, it should expand the module the class
  //            * is currently in. For some reason this is NOT managed in the state, but uses
  //            * HTML ID + css + class manipulation to achieve.
  //            * <Collapse> is a bootstrap.esm module
  //            */
  //           if (activeCollapses.length === 0) {
  //             onClickCollapse('', `course-module-${target_class.ModuleId}`);
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       alert(error.response.data.message);
  //     });
  // }

  /**
   * Find the course, and get ONE class only
   * It is the first class in the course, with the lowest Module ID and lowest class sequence
   */
  // const getFirstClassByCourseId = () => {
  //   const params = {
  //     course_id: searchParams.get("course_id"),
  //   };
  //   api.post("/organization_learner/view_first_class_by_course_id", params)
  //     .then((response) => {
  //       const first_class_in_course = response.data.class;
  //       const params2 = {
  //         course_id: first_class_in_course.CourseId,
  //         module_id: first_class_in_course.ModuleId,
  //         class_id: first_class_in_course.id
  //       };

  //       api.post('/organization_learner/view_class', params2)
  //         .then((view_class_res) => {
  //           // setViewingClass(view_class_res.data.class[0]);

  //           if (activeCollapses.length === 0) {
  //             onClickCollapse('', `course-module-${view_class_res.data.class[0].ModuleId}`);
  //           }
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       alert(error.response.data.message);
  //     });
  // }

  const onClickCollapse = (event, html_id) => {
    // html_id looks like this : `course-module-${module.id}`
    const element = document.querySelector("#" + html_id);

    if (element.classList.contains("show")) {
      setActiveCollapses(
        activeCollapses.filter((filter_id) => filter_id !== html_id)
      );
    } else {
      setActiveCollapses([html_id, ...activeCollapses]);
    }

    const collapse = new Collapse(element);
    collapse.toggle();
  };

  const handleSelectClass = (cid) => {
    setSearchParams({ course_id: course_id, class_id: cid });
  }

  return (
    <Layout>
      <div className={"border-top"} style={{ marginBottom: 28 }}></div>

      <Container className="max-width-1304">
        <Row>
          <div className={"col-12 col-md-9"}>
            <Row>
              <Col>
                <div>{co.id}&nbsp;{co?.Course_Name_EN}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <H2 style={{ fontSize: "40px", wordBreak: "break-word" }}>
                  Class {viewingClass?.Class_Index}:&nbsp;{viewingClass?.Class_Title_EN}
                </H2>
                <div style={{ fontSize: "20px" }} className={"text-535353"}>
                </div>
              </Col>
            </Row>
            {
              viewingClass?.Video_Url
              &&
              <Row className="mb-3">
                <Col>
                  <ClassVideo
                    key={viewingClass.id}
                    viewing_class={viewingClass}
                    viewed_class_responses={viewed_class_responses}
                    hardcode={true}
                  />
                </Col>
              </Row>
            }

            {viewingClass.Video_Url === '' &&
              <Row className="my-5">
                <Col>
                  <span>No video uploaded for this class.....</span>
                </Col>
              </Row>
            }
            <Row className="align-items-center">
              <Col>
                <H2 style={{ fontSize: "16px" }} className={"m-0"}>
                  By {co?.Trainer}
                </H2>
                <div style={{ fontSize: "16px", wordBreak: "break-word" }} className={"text-535353"}>
                  {co?.Trainer_Organization} - {co?.Trainer_Description_EN}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <H2 style={{ fontSize: "26px" }}>Class Description</H2>
                <div style={{ width: "100%" }} className="hkqaa-divider" />
              </Col>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <p className="font-size-16px" style={{ wordBreak: "break-word" }}>{viewingClass.Class_Description_EN}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={"auto"}>
                <H2 style={{ fontSize: "26px" }}>Material For Download</H2>
                <div style={{ width: "100%" }} className="hkqaa-divider" />
              </Col>
            </Row>
            <Row className="mb-3">
              {
                (() => {
                  const materials = parseDbStringToObj(viewingClass?.Class_Materials);
                  if (!Array.isArray(materials)) {
                    return '';
                  }

                  return materials.map((href) => (
                    <Col sm={6} md={3} className="text-center mb-3" key={href}>
                      <div className={"border"}>
                        <div className={"p-3"}>
                          <div className={"mb-2"}>
                            <img
                              src={"/assets/images/file.svg"}
                              className={"img-fluid"}
                              alt=""
                            />
                          </div>
                          <div className={"font-size-16px"} style={{ wordWrap: 'break-word' }}>{decodeURIComponent(escape(decodeURIComponent( get_filename_from_url(href).substring(37) )))}</div>
                        </div>
                        <div>
                          <a href={href} download>
                            <button
                              className={"w-100 btn bg-eb6201 text-white fw-bold"}
                            >
                              Download
                            </button>
                          </a>
                        </div>
                      </div>
                    </Col>
                  ));
                })()
              }
            </Row>
          </div>
          <Col>
            {mo?.map((module) => (
              <Row className={"mb-3"} key={module.id}>
                <Col>
                  <div>
                    <button
                      className={
                        activeCollapses.includes("course-module-" + module.id)
                          ? "btn w-100 text-start shadow-none border bg-4a4a4a text-white rounded-0 d-flex justify-content-between"
                          : "btn w-100 text-start shadow-none border rounded-0 d-flex justify-content-between"
                      }
                      style={{
                        borderColor: "#4a4a4a",
                      }}
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={(event) => {
                        onClickCollapse(event, "course-module-" + module.id);
                      }}
                    >
                      <div>Module {module?.Module_Index}: {module?.Module_Title_EN}</div>
                      <div>
                        {activeCollapses.includes("course-module-" + module.id)
                          ? "-"
                          : "+"}
                      </div>
                    </button>
                  </div>
                  <div className="collapse" id={"course-module-" + module.id}>
                    <div className="card card-body rounded-0 text-4a4a4a border-top-0 p-0">
                      {module.classes?.map((classItem) => {
                        return <ClassItemHC
                          key={classItem.id}
                          classItem={classItem}
                          viewingClass={viewingClass}
                          viewed_class_responses={viewed_class_responses}
                          handleSelectClass={handleSelectClass}
                          courseId={co.id}
                          is_hardcode={true}
                        />
                      }
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
            {/* <button className={"w-100 btn bg-4a4a4a text-white fw-bold rounded-0 disabled"}
              onClick={
                async () => {
                  let viewed_class_responses_by_module_id = {};
                  for (const module of modules) {
                    const viewed_class_response = await api.post('/user/getViewedClassId', {
                      course_id: course_id,
                      module_id: module.id,
                    });
                    viewed_class_responses_by_module_id[module.id] = viewed_class_response?.data;
                  }

                  let all_viewed = false;
                  for (const class_item of classes) {
                    if (viewed_class_responses?.[class_item.ModuleId]?.viewed_class_id.includes(class_item.id)) {
                      all_viewed = true;
                      break;
                    }
                  }

                  if (view_quiz_response?.remaining_attempts === 0) {
                    alert('No more remaining attempts');
                  } else {
                    if (!all_viewed) {
                      alert('Please complete the course before taking Evaluation Form.');
                    } else {
                      navigate(`/course/${course_id}/quiz`);
                    }
                  }
                }
              }>
              QUIZ
            </button> */}
          </Col>
        </Row>

      </Container>

      <Container className="max-width-1304 mb-5">
        <Row className="align-items-end">
          <Col xs={9}>
            <Row>
              <Col>
                <div className={"hkqaa-divider"} style={{ borderWidth: 1 }} />
              </Col>
            </Row>
            <Row className="fw-bold font-size-18px">
              {classes && currentPosition !== 0 &&
                <Col className="cursor-pointer"
                  onClick={() => handleSelectClass(classes[currentPosition - 1].id)}
                >
                  {"< Previous Class"}
                </Col>
              }

              {classes && currentPosition < classes.length - 1 &&
                <Col
                  onClick={() => handleSelectClass(classes[currentPosition + 1].id)}
                  className="text-end cursor-pointer"
                >
                  {"Next Class >"}
                </Col>
              }
            </Row>
          </Col>

        </Row>
      </Container>
    </Layout>
  );
}
