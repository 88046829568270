import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const nearestIndex = (
    currentPosition,
    sectionPositionEntries,
    startIndex,
    endIndex
) => {
    if (startIndex === endIndex) {
        return startIndex;
    } else if (startIndex === endIndex - 1) {
        if (
            Math.abs(
                sectionPositionEntries[startIndex][1].ref.current.offsetTop -
                currentPosition
            ) <
            Math.abs(
                sectionPositionEntries[endIndex][1].ref.current.offsetTop -
                currentPosition
            )
        ) {
            return startIndex;
        } else {
            return endIndex;
        }
    } else {
        const nextNearest = ~~((startIndex + endIndex) / 2);
        const a = Math.abs(
            sectionPositionEntries[nextNearest][1].ref.current.offsetTop -
            currentPosition
        );
        const b = Math.abs(
            sectionPositionEntries[nextNearest + 1][1].ref.current.offsetTop -
            currentPosition
        );
        if (a < b) {
            return nearestIndex(
                currentPosition,
                sectionPositionEntries,
                startIndex,
                nextNearest
            );
        } else {
            return nearestIndex(
                currentPosition,
                sectionPositionEntries,
                nextNearest,
                endIndex
            );
        }
    }
};

export default function ProfileMenu(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const navHeader = [...props.navHeader.entries()];

    useEffect(() => {
        const handleScroll = (e) => {
            const index = nearestIndex(
                window.scrollY,
                navHeader,
                0,
                navHeader.length - 1
            );
            setActiveIndex(index);
        };
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className="col d-none d-lg-flex profile-menu-div">
                <div className={"col"}>
                    <div
                        style={{
                            position: "sticky",
                            width: 240,
                            height: "100vh",
                            top: 0,
                        }}
                        className={"bg-white text-646777"}
                    >
                        <div className={"py-5"}>
                            {navHeader.map((item, index) => (
                                <a
                                    key={item[0]}
                                    className={activeIndex === index ? "px-5 py-1 d-block text-white text-decoration-none bg-eb6201" : "px-5 py-1 d-block text-reset text-decoration-none"}
                                    href={`#${item[1].id}`}
                                >
                                    {item[1].title}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-lg-none d-md-block profile-mobile-menu">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        ☰
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {navHeader.map((item, index) => (
                            <Dropdown.Item
                                key={item[0]}
                                className={activeIndex === index ? "px-5 py-1 d-block text-white text-decoration-none bg-eb6201" : "px-5 py-1 d-block text-reset text-decoration-none"}
                                href={`#${item[1].id}`}
                            >
                                {item[1].title}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}
