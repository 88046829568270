import Layout from "../components/Layout";
import React from "react";
import Section from "../components/Section";
import H2 from "../components/H2";
import H5 from "../components/H5";
import Banner from "../components/Banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner.png">{t('About Us')}</Banner>

      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 10%",
            backgroundSize: "300px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs={12} md={true}>
              <div className={ "frontend-page-mobile-container" } style={{ paddingRight: 25 }}>
                <img
                  className={"img-fluid w-100"}
                  src={"/assets/images/about-us-hero-banner-1.png"}
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <div className={ "frontend-page-mobile-container" } style={{ paddingLeft: 24 }}>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {t('About LightShare')}
                </H2>
                <H5
                  style={{ fontSize: 18 }}
                  className={"mb-4"}
                >
                  {t('"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA).')}
                </H5>
                <p style={{ fontSize: 16 }}>
                  {t('This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.')}
                </p>
                <p style={{ fontSize: 16 }}>
                  {t('Through the LightShare, you are also able to become a part of the meta-networking ecosystem, which enables you to extend your business network and raise market awareness across regions, and build your socially responsible image in the communities.')}
                </p>
                <p style={{ fontSize: 16 }}>
                  {t('You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!')}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mb-3 g-0">
            <Col>
              <div  style={{ paddingRight: 25 }} className={ "frontend-page-mobile-container" }>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {t('About HKQAA')}
                </H2>
                <H5
                  style={{ fontSize: 18 }}
                  className={"mb-4"}
                >
                  {t('Established in 1989 as a non-profit public organisation by the Hong Kong Government, HKQAA is committed to promoting good management practices.')}
                </H5>
                <p style={{ fontSize: 16 }}>
                  {t('Through knowledge sharing and technology transfer, we help industries enhance their management performance. We also provide innovative and professional services to support enterprises and society to address future challenges and foster sustainable development.')}
                </p>
                <p style={{ fontSize: 16 }}>
                  {t('HKQAA is devoted to creating value for enterprises and making contributions to shaping a better environment and society. We have developed a wide range of pioneering services to meet the needs of industry and society. These services are in the areas of social responsibility, environmental protection, energy management, carbon neutrality, green and sustainable finance, and barrier free accessibility management.')}
                </p>
              </div>
            </Col>
            <Col xs={12} md={true}>
              <div className={ "frontend-page-mobile-container" } style={{ paddingLeft: 24 }}>
                <img
                  className={"img-fluid w-100"}
                  src={"/assets/images/about-us-hero-banner-2.png"}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}
