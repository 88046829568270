import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { parseDbStringToObj, prepareDbString } from "../../../util/lang";
import ShadowContainer from "../../ShadowContainer";
import Salutation from "../../Options/Salutation";
import Department from "../../Options/Department";
import Country from "../../Options/Country";
import { use } from "i18next";

export default function CmsEditProfContactInfo(props) {
    const user = props.user;

    const role = user.Role;

    const c_firstn = parseDbStringToObj(user.Contact_Person_First_Name);
    const c_lastn = parseDbStringToObj(user.Contact_Person_Last_Name);

    const c_address = parseDbStringToObj(user.Contact_Address);
    const c_city = parseDbStringToObj(user.Contact_City);
    const c_province = parseDbStringToObj(user.Contact_Province);
    const c_country = parseDbStringToObj(user.Contact_Country);

    const handleUserChange = (e, lang = "EN") => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === "Contact_Person_First_Name") {
            value = prepareDbString(c_firstn, e.target.value, lang);
        } else if (name === "Contact_Person_Last_Name") {
            value = prepareDbString(c_lastn, e.target.value, lang);
        }

        else if (name === "Contact_Address") {
            value = prepareDbString(c_address, e.target.value, lang);
        }
        else if (name === "Contact_City") {
            value = prepareDbString(c_city, e.target.value, lang);
        } else if (name === "Contact_Province") {
            value = prepareDbString(c_province, e.target.value, lang);
        } else if (name === "Contact_Country") {
            value = prepareDbString(c_country, e.target.value, lang);
        }
        props.setUser((user) => {
            return { ...user, [name]: value };
        });
    };

    const mobile = user.Mobile_Number ? true : false;
    const fax = user.Fax_Number ? true : false;

    // if DB return null, the <select><option> handleUserChange will not be called
    // so we need to manually update the user state here
    if (!user.Salutation) {
        props.setUser((user) => {
            return { ...user, Salutation: '--' };
        });
    }

    return (
        <ShadowContainer>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Salutation
                </label>
                <div className="col-auto">
                    <select className="form-select"
                        value={user.Salutation ?? ''}
                        name="Salutation"
                        onChange={(e) => handleUserChange(e)}
                    >
                        <Salutation></Salutation>
                    </select>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Contact Person<span className={"text-danger"}>*</span>
                </label>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Last Name"}
                        value={c_lastn.EN}
                        name="Contact_Person_Last_Name"
                        onChange={(e) => handleUserChange(e, "EN")}
                        required={true}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"First Name"}
                        value={c_firstn.EN}
                        name="Contact_Person_First_Name"
                        onChange={(e) => handleUserChange(e, "EN")}
                        required={true}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Contact Person (繁)
                </label>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="姓氏"
                        value={c_lastn.TC}
                        name="Contact_Person_Last_Name"
                        onChange={(e) => handleUserChange(e, "TC")}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="名字"
                        value={c_firstn.TC}
                        name="Contact_Person_First_Name"
                        onChange={(e) => handleUserChange(e, "TC")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Contact Person (简)
                </label>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="姓氏"
                        value={c_lastn.SC}
                        name="Contact_Person_Last_Name"
                        onChange={(e) => handleUserChange(e, "SC")}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="名字"
                        value={c_firstn.SC}
                        name="Contact_Person_First_Name"
                        onChange={(e) => handleUserChange(e, 'SC')}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Title
                </label>
                <div className="col-sm-5">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Title"}
                        name="Title"
                        value={user.Title ?? ""}
                        onChange={(e) => handleUserChange(e)}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Department
                </label>
                <div className="col-sm-5">
                    <select
                        type="text"
                        className="form-select"
                        placeholder={"Department"}
                        name="Department_1"
                        value={user.Department_1 ?? ""}
                        onChange={(e) => handleUserChange(e)}
                    >
                        <Department></Department>
                    </select>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    E-mail<span className={"text-danger"}>*</span>
                </label>
                <div className="col-sm-5">
                    <input
                        type="email"
                        className="form-control"
                        placeholder={"E-mail"}
                        name={role === "Organization" ? "Organization_Email" : role === "Trainer/Academy" ? "Trainer_Email" : role === "Association" ? "Association_Email" : ""}
                        value={role === "Organization" ? user.Organization_Email : role === "Trainer/Academy" ? user.Trainer_Email : role === "Association" ? user.Association_Email : ""}
                        onChange={(e) => handleUserChange(e)}
                        required={true}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Phone No.
                </label>
                <div className="col-sm-5">
                    <PhoneInput
                        name="Mobile_Number"
                        value={user.Mobile_Number}
                        placeholder=""
                        onChange={(value) => {
                            props.setUser((user) => {
                                return { ...user, Mobile_Number: value };
                            });
                        }}
                        country={mobile ? undefined : "hk"}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Fax No.
                </label>
                <div className="col-sm-5">
                    <PhoneInput
                        name="Fax_Number"
                        value={user.Fax_Number}
                        placeholder=""
                        onChange={(value) => {
                            props.setUser((user) => {
                                return { ...user, Fax_Number: value };
                            });
                        }}
                        country={fax ? undefined : "hk"}
                    />
                </div>
            </div>

            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Country
                </label>
                <div className="col-sm-5">
                    <select
                        type="text"
                        className="form-select"
                        placeholder={"Country"}
                        value={c_country.EN}
                        name="Contact_Country"
                        onChange={(e) => handleUserChange(e, "EN")}
                    >
                        <Country />
                    </select>
                </div>
            </div>


            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address
                </label>
                <div className="col-sm-5">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={c_address.EN}
                        name="Contact_Address"
                        onChange={(e) => handleUserChange(e, "EN")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={c_city.EN}
                        name="Contact_City"
                        onChange={(e) => handleUserChange(e, "EN")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={c_province.EN}
                        name="Contact_Province"
                        onChange={(e) => handleUserChange(e, "EN")}
                    />
                </div>
            </div>



            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address (繁)
                </label>
                <div className="col-sm-5">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={c_address.TC}
                        name="Contact_Address"
                        onChange={(e) => handleUserChange(e, "TC")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={c_city.TC}
                        name="Contact_City"
                        onChange={(e) => handleUserChange(e, "TC")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={c_province.TC}
                        name="Contact_Province"
                        onChange={(e) => handleUserChange(e, "TC")}
                    />
                </div>
            </div>



            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Address (简)
                </label>
                <div className="col-sm-5">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Address"}
                        value={c_address.SC}
                        name="Contact_Address"
                        onChange={(e) => handleUserChange(e, "SC")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"City"}
                        value={c_city.SC}
                        name="Contact_City"
                        onChange={(e) => handleUserChange(e, "SC")}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-sm-5 offset-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={"Province"}
                        value={c_province.SC}
                        name="Contact_Province"
                        onChange={(e) => handleUserChange(e, "SC")}
                    />
                </div>
            </div>
        </ShadowContainer>
    );
}
