import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../../components/LayoutCms";
import { Button, Col, Row } from "react-bootstrap";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import { getApiClient, getTokens, removeTokens } from "../../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../../util/lang";
import DataTable from "react-data-table-component";
import moment from "moment";
import AdminInsightsAndTrendsActionColumn from "../../../../components/AdminInsightsAndTrendsActionColumn";

export default function AdminContentManagementViewAllInsightsAndTrends() {
    const navigate = useNavigate();
    const api = getApiClient();

    const role = localStorage.getItem("role");
    const [admin, setAdmin] = useState('');

    const [selected_insights_and_trends_id_no_1, set_selected_insights_and_trends_id_no_1] = useState("");
    const [selected_insights_and_trends_id_no_2, set_selected_insights_and_trends_id_no_2] = useState("");
    const [selected_insights_and_trends_id_no_3, set_selected_insights_and_trends_id_no_3] = useState("");
    const [selected_insights_and_trends_id_no_4, set_selected_insights_and_trends_id_no_4] = useState("");

    const [all_insights_and_trends_response, set_all_insights_and_trends_response] = useState([]);
    const [filteredInsightsAndTrends, setFilteredInsightsAndTrends] = useState([]);

    //for selected option
    const [filterBy, setFilterBy] = useState('All');

    //for detect is it super admin
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const fetch_view_all_insights_and_trends = () => {
        api.post("/admin/viewAllInsightsAndTrends")
            .then((response) => {
                set_all_insights_and_trends_response(response.data?.all_insights_and_trends);
                setFilteredInsightsAndTrends(response.data?.all_insights_and_trends);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });

        api.post("/admin/getHomePageInsightsAndTrends")
            .then((response) => {
                set_selected_insights_and_trends_id_no_1(response.data.home_page_insights_and_trends_ids[0].Display_No_1);
                set_selected_insights_and_trends_id_no_2(response.data.home_page_insights_and_trends_ids[0].Display_No_2);
                set_selected_insights_and_trends_id_no_3(response.data.home_page_insights_and_trends_ids[0].Display_No_3);
                set_selected_insights_and_trends_id_no_4(response.data.home_page_insights_and_trends_ids[0].Display_No_4);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });
    }

    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            if(role === null || role === '') {
                navigate('/login');
            } else {
                navigate('/');
            }
            return;
        }

        api.get("/user/viewProfile")
            .then((response) => {
                setAdmin(response.data?.user);
                if(response.data?.user?.Is_Super_Admin === 'Yes') {
                    setIsSuperAdmin(true);
                } else {
                    setIsSuperAdmin(false);
                }
            })
            .catch((error) => {
                console.log(error);
                removeTokens();
                alert(error?.response?.data?.message ?? error?.message ?? error);
                navigate("/admin");
                return;
            });

        fetch_view_all_insights_and_trends();
    }, []);

    if (!admin) {
        return <>Loading...</>;
    }

    const handleSaveHomepageInsightsAndTrends = () => {
        // if(
        //     selected_insights_and_trends_id_no_1 === null || 
        //     selected_insights_and_trends_id_no_2 === null || 
        //     selected_insights_and_trends_id_no_3 === null || 
        //     selected_insights_and_trends_id_no_4 === null
        // ) {
        //     alert('Error! You must select all display post with the ordering!');
        //     return;
        // }

        if(window.confirm("Are you sure to update the home page ordering of insights and trends?")) {
            api.post("/admin/saveHomePageInsightsAndTrends", { 
                    display_no_1: selected_insights_and_trends_id_no_1,
                    display_no_2: selected_insights_and_trends_id_no_2,
                    display_no_3: selected_insights_and_trends_id_no_3,
                    display_no_4: selected_insights_and_trends_id_no_4
                })
                .then((response) => {
                    alert(response?.data?.message);
                })
                .catch((error) => {
                    alert(error?.response?.data?.message ?? error.message ?? error);
                });
        }
    }

    const handleFilter = (e) => {
        let value = e.target.value;

        if(value === 'All') {
            setFilteredInsightsAndTrends(all_insights_and_trends_response);
            setFilterBy('All');
        } else if(value === 'Draft') {
            let selectedData = all_insights_and_trends_response.filter(item => item.Status === 'Draft');
            setFilteredInsightsAndTrends(selectedData);
            setFilterBy('Draft');
        } else if(value === 'Published') {
            let selectedData = all_insights_and_trends_response.filter(item => item.Status === 'Published');
            setFilteredInsightsAndTrends(selectedData);
            setFilterBy('Published');
        } else if(value === 'Archived') {
            let selectedData = all_insights_and_trends_response.filter(item => item.Status === 'Archive');
            setFilteredInsightsAndTrends(selectedData);
            setFilterBy('Archived');
        }
    }

    const handle_checkbox_updated = () => {
        fetch_view_all_insights_and_trends();
    };

    const columns = [
        {
            name: "Post Title",
            selector: (row) => parseDbStringToObj(row.Insights_And_Trends_Title)?.EN,
            sortable: true,
            wrap: true,
            width: '1000px',
        },
        {
            name: "Status",
            selector: (row) => row.Status,
            sortable: true,
            wrap: true,
        },
        {
            name: "Post Publish Date",
            selector: (row) => row.Last_Published_Date,
            format: (row) => row.Last_Published_Date ? moment(row.Last_Published_Date).utc(8).format("DD MMM YYYY") : '-',
            sortable: true,
            wrap: true,
        },
        {
            name: "Action",
            sortable: false,
            cell: (row) => {
                return (<AdminInsightsAndTrendsActionColumn insights_and_trends={row} checkbox_updated={handle_checkbox_updated} isSuperAdmin={isSuperAdmin} />);
            },
        },
    ];

    return (
        <LayoutCms route={'content_management.insights_and_trends'}>

            {/** ----------- starting main section with Insights And Trends content ----------- */}
            <div className={"row align-items-center"}>
                <div className={"col"}>
                    <div className={"text-secondary font-size-20px fw-700"}>
                        Insights And Trends
                        {!isSuperAdmin
                        ?
                        <PrimaryButton
                            type={"button"}
                            className={"mb-2"}
                            style={{float:'right'}}
                            onClick={()=>navigate('/cms/admin/content_management/create/insights_and_trends')}
                        >
                            Add New Insights And Trends
                        </PrimaryButton>
                        :
                        ''
                        }
                    </div>
                </div>
            </div>

            <ShadowContainer>

                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <div className={"text-secondary font-size-20px fw-700"}>Homepage Insights and Trends List</div>
                    </Col>
                </Row>

                <Row className={"search-results-div"}>
                    <Col>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={2}>Display No.1 Post</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_insights_and_trends_id_no_1} 
                                    onChange={(event) => { set_selected_insights_and_trends_id_no_1(event.target.value) }}
                                    disabled={isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_insights_and_trends_response.map((insights_and_trends) => (
                                            <option value={insights_and_trends.id} key={insights_and_trends.id}>{getTextByCode(insights_and_trends.Insights_And_Trends_Title, 'EN')}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={2}>Display No.2 Post</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_insights_and_trends_id_no_2} 
                                    onChange={(event) => { set_selected_insights_and_trends_id_no_2(event.target.value) }}
                                    disabled={isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_insights_and_trends_response.map((insights_and_trends) => (
                                            <option value={insights_and_trends.id} key={insights_and_trends.id}>{getTextByCode(insights_and_trends.Insights_And_Trends_Title, 'EN')}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={2}>Display No.3 Post</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_insights_and_trends_id_no_3} 
                                    onChange={(event) => { set_selected_insights_and_trends_id_no_3(event.target.value) }}
                                    disabled={isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_insights_and_trends_response.map((insights_and_trends) => (
                                            <option value={insights_and_trends.id} key={insights_and_trends.id}>{getTextByCode(insights_and_trends.Insights_And_Trends_Title, 'EN')}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3"}>
                            <Col xs={2}>Display No.4 Post</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_insights_and_trends_id_no_4} 
                                    onChange={(event) => { set_selected_insights_and_trends_id_no_4(event.target.value) }}
                                    disabled={isSuperAdmin}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_insights_and_trends_response.map((insights_and_trends) => (
                                            <option value={insights_and_trends.id} key={insights_and_trends.id}>{getTextByCode(insights_and_trends.Insights_And_Trends_Title, 'EN')}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {!isSuperAdmin
                ?
                <PrimaryButton
                    type={"submit"}
                    className={"mb-2"}
                    style={{ width: '120px' }}
                    onClick={handleSaveHomepageInsightsAndTrends}
                >
                    Save
                </PrimaryButton>
                :
                ''
                }
            </ShadowContainer>

            <Row className={"align-items-center"}>
                <Col className={"d-flex align-items-center gx-4"}></Col>
                <Col xs={"auto"} className={"d-flex align-items-center"}>
                    <div className={"mx-2"}>Filter By</div>
                    <div className={"mx-2"}>
                        <select className="form-select" onChange={handleFilter}>
                            <option value="All">All</option>
                            <option value="Draft">Draft</option>
                            <option value="Published">Published</option>
                            <option value="Archived">Archived</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <ShadowContainer>
                <div className={"text-secondary font-size-20px fw-700"}>Insights And Trends Page Display List</div>
                <DataTable
                    columns={columns}
                    data={filteredInsightsAndTrends}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[30,50,100]}
                    noDataComponent="You do not have any insights and trends"
                />
            </ShadowContainer>
        </LayoutCms>
    );
}
