import Layout from "../components/Layout";
import Section from "../components/Section";
import React from "react";
import H2 from "../components/H2";
import H5 from "../components/H5";
import Banner from "../components/Banner";
import { Container, Row, Col } from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default function PersonnelRegistrationService() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/personnel-registration-service-banner.png">
        {t('Personnel Registration Service')}
      </Banner>
      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 50%",
            backgroundSize: "250px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs={12} md={true}>
              <div className={ "frontend-page-mobile-container" } style={{ paddingRight: 25 }}>
                <img
                  className={"img-fluid"}
                  src={"/assets/images/personnel-registration-service.png"}
                />
              </div>
            </Col>
            <Col>
              <div className={ "frontend-page-mobile-container" } style={{ paddingLeft: 24 }}>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {t('Personnel Registration Service')}
                </H2>
                <H5 style={{ fontSize: 18 }} className={"mb-4"}>
                  {t('"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA).')}
                </H5>
                <p style={{ fontSize: 16 }}>
                  {t('This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.')}
                </p>
                <p style={{ fontSize: 16 }}>
                  {t('You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!')}
                </p>

                <div>
                  <a
                    href={t('HKQAAPersonnelSeries')}
                    className="btn bg-eb6201 text-white fw-bold shadow-none">
                    {t('DISCOVER MORE')}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}
