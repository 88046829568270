import React from "react";
import Col from "react-bootstrap/Col";

export default function ProfileTop(props){

    const user = props.user;
    const CURRENT_LANGUAGE = props?.CURRENT_LANGUAGE ?? 'EN';

    // Processing Organization_Name / Association_Name with different role
    let name_by_role = '';
    if(user?.Role === 'Organization' || user?.Role === 'Organization Learner') {
        name_by_role = user?.Organization_Name[CURRENT_LANGUAGE];
    } else if(user?.Role === 'Individual Learner') {
        name_by_role = '';
    } else if(user?.Role === 'Association' || user?.Role === 'Association Learner') {
        name_by_role = user?.Association_Name[CURRENT_LANGUAGE];
    }

    return <>
    <Col xs={"auto"}>
        <img
            src={ user?.Profile_Photo ?? "/assets/images/profile.jpeg"}
            className={"img-fluid"}
            style={{ width: 80 }}
            alt='profile'
        />
    </Col>
    <Col>
        <div style={{ fontSize: 21 }} className={"fw-bold"}>
            {user?.Contact_Person_Last_Name.TC}{user?.Contact_Person_First_Name.TC}
        </div>
        <div style={{ fontSize: 18 }} className={"fw-bold"}>
            {user?.Contact_Person_First_Name.EN}&nbsp;{user?.Contact_Person_Last_Name.EN}
        </div>
        <div style={{ fontSize: 16 }} className={"fw-normal"}>
            {name_by_role}
        </div>
    </Col>
    </>;

}
