import React from "react";
import { useTranslation } from 'react-i18next';

export default function CopyRight(){
    const { t } = useTranslation();

    return (
        <div className="footer-copyrights col-md-6 mt-auto text-left py-3 font-size-12px text-5c5c5c">
          <div>Copyright © { new Date().getFullYear() } {t('Hong Kong Quality Assurance Agency.')}</div>
          <div>{t('All rights reserved.')}</div>
        </div>
    );
}