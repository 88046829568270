import React from "react";
import ShadowContainer from "../../ShadowContainer";

export default function CmsEditProfAccountInfo(props) {
    return (
        <ShadowContainer>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Registered Date
                </label>
                <div className="col-sm-5">
                    <input
                        type="text"
                        className="form-control"
                        value={props.createdAt}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    Second Email
                </label>
                <div className="col-sm-5">
                    <input
                        type="email"
                        required
                        className="form-control"
                        placeholder={"Second Email"}
                        value={props.personal_email_2nd}
                        onChange={(e)=>props.set_personal_email_2nd(e.target.value)}
                    />
                </div>
            </div>
        </ShadowContainer>
    );
}
