import Paragraph from "./Paragraph";
import LineLimiter from "./LineLimiter";
import { Link } from "react-router-dom";
import { parseDbStringToObj } from "../util/lang";
import { useTranslation } from "react-i18next";
import CURRENT_LANGUAGE from "../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function CourseItem(props) {
  const { t } = useTranslation();
  const course = props.course;

  return (
    <>
      <img
        alt={"banner image for " + parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}
        src={course.Course_Photo || '/assets/images/home_courses_thumbnail_2.png'}
        style={{ aspectRatio: "137 / 86", objectFit: "cover" }}
        className="img-fluid w-100"
      />
      <div
        style={{ fontSize: "16px", lineHeight: "24px", marginTop: "20px" }}
        className="fw-900"
      >
        {course.Course_Code + ' - ' + parseDbStringToObj(course.Course_Name)[CURRENT_LANGUAGE]}
      </div>
      <div>
        <OwnerLink 
          owner_id={course.Course_Owner ?? null} 
          owner={course.Role === "Trainer/Academy" ? parseDbStringToObj(course.Trainer_Name)[CURRENT_LANGUAGE] : parseDbStringToObj(course.Association_Name)[CURRENT_LANGUAGE]} 
          role={course.Role}
        ></OwnerLink>
      </div>
      <div style={{ marginBottom: 10 }}>
      <LineLimiter>
        {parseDbStringToObj(course.Course_Descriptions)[CURRENT_LANGUAGE]}
      </LineLimiter>
      </div>
      <div className="mt-auto">
        <Link
          to={"/courses/show-data/" + course.id}
          style={{ color: "#266599", textDecoration: "none" }}
        >
          {t('EXPLORE MORE')} &gt;
        </Link>
      </div>
    </>
  );
}

function OwnerLink(props) {
  if (!props.owner_id) {
    return <a style={{ color: "#212529", textDecoration: "none" }}>
      {props.owner}
    </a>
  }
  else {
    let owner_link = props.role === "Trainer/Academy" ? `trainers/show/${props.owner_id}` : `association/show/${props.owner_id}`;
    return <a href={owner_link} style={{ color: "#266599", textDecoration: "none" }}>
      {props.owner}
    </a>
  }
}
