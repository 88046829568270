import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CourseCategoryNavBar(props) {
  const courseCategoryDictionary = {
    Sustainability: "sustainability",
    Innovation: "innovation",
    "Capacity Building": "capacity_building",
    "Greater Bay Area": "greater_bay_area",
    "Green Finance": "green_finance",
    "Soft Skill": "soft_skill",
    ISO: "iso",
  };

  const changeCourseCategory = (event, nextCourseCategory) => {
    event.preventDefault();

    const parent = event.target.parentNode;
    const element = event.target;

    const paddingRight = 40;
    const left =
      element.getBoundingClientRect().left -
      parent.getBoundingClientRect().left;
    const right =
      element.getBoundingClientRect().right -
      parent.getBoundingClientRect().left -
      paddingRight;
    const dividerWidth = 45;
    const position = (left + right) / 2 - dividerWidth / 2;
    setCssLeft(position);
    setCourseCategory(courseCategoryDictionary[nextCourseCategory]);
  };

  const getCourseCategoryStyle = (currentCourseCategory) => {
    const value = courseCategoryDictionary[currentCourseCategory];

    return {
      fontSize: "18px",
      fontHeight: "21.6px",
      textDecoration: "none",
      paddingRight: 40,
      color: value === courseCategory ? "#266599" : "#4a4a4a",
    };
  };

  const [courseCategory, setCourseCategory] = useState(
    courseCategoryDictionary["Sustainability"]
  );
  const [cssLeft, setCssLeft] = useState(30.8);

  const getLeft = () => {
    return cssLeft;
  };

  return (
    <div className={"d-none overflow-y-scroll overflow-md-y-visible"}>
      <Row className={`${props.className}`}>
        <Col>
          <div className="d-flex">
            <a
              onClick={(event) => changeCourseCategory(event, "Sustainability")}
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Sustainability")}
            >
              Sustainability
            </a>
            <a
              onClick={(event) => changeCourseCategory(event, "Innovation")}
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Innovation")}
            >
              Innovation
            </a>
            <a
              onClick={(event) =>
                changeCourseCategory(event, "Capacity Building")
              }
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Capacity Building")}
            >
              Capacity Building
            </a>
            <a
              onClick={(event) =>
                changeCourseCategory(event, "Greater Bay Area")
              }
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Greater Bay Area")}
            >
              Greater Bay Area
            </a>
            <a
              onClick={(event) => changeCourseCategory(event, "Green Finance")}
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Green Finance")}
            >
              Green Finance
            </a>
            <a
              onClick={(event) => changeCourseCategory(event, "Soft Skill")}
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("Soft Skill")}
            >
              Soft Skill
            </a>
            <a
              onClick={(event) => changeCourseCategory(event, "ISO")}
              href="#"
              className="d-block"
              style={getCourseCategoryStyle("ISO")}
            >
              ISO
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="div-block-courses">
            <div
              className="div-block-courses-top"
              style={{ left: getLeft() }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
