import React from "react";
import {useParams} from "react-router-dom";

export default function CourseTitle(props) {
    const { id } = useParams();
    const action = props.readOnly ? 'View' : props.isEditing ? 'Edit' : 'Create';
    const isAdmin = props?.isAdmin ?? false;

    return (
        <div className={"row align-items-center"}>
            <div className={"col-7"}>
                <div className={"text-secondary font-size-20px fw-700"}>{action} Course</div>
                <div className={"text-adb5bd"}>{action} {isAdmin ? 'selected' : 'your'} course.</div>
            </div>
        </div>
    );
}
