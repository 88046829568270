import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Button } from "react-bootstrap";
import { getApiClient } from "../util/auth";
import ShadowContainer from "./ShadowContainer";
import PrimaryButton from "./PrimaryButton";
import EduLevel from "./Options/EduLevel";
import AgeGroup from "./Options/AgeGroup";
import Department from "./Options/Department";

export default function AddOrEditMemberForm(props) {
  let isEditing = false;
  if (props.user) {
    isEditing = true;
  }

  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [contactPersonFirstName, setContactPersonFirstName] = useState({
    EN: "",
    TC: "",
    SC: "",
  });
  const [contactPersonLastName, setContactPersonLastName] = useState({
    EN: "",
    TC: "",
    SC: "",
  });
  const [officeNumber, setOfficeNumber] = useState("+852");
  const [mobileNumber, setMobileNumber] = useState("+852");
  const [gender, setGender] = useState('');
  const [status, setStatus] = useState("Active");
  const [department1, setDepartment1] = useState('');
  const [preferredLanguage, setPreferredLanguage] = useState('EN');
  const [ageGroup, setAgeGroup] = useState('');
  const [eduLevel, setEduLevel] = useState('');

  const api = getApiClient();

  useEffect(() => {
    if (isEditing) {
      api
        .post("/association/viewMember", { user_id: props.user.id })
        .then((response) => {
          console.log(response.data.user);
          setUser(response.data.user);
          setContactPersonFirstName(
            JSON.parse(response.data.user.Contact_Person_First_Name)
          );
          setContactPersonLastName(
            JSON.parse(response.data.user.Contact_Person_Last_Name)
          );
          setOfficeNumber(response.data.user.Office_Number);
          setMobileNumber(response.data.user.Mobile_Number);
          setGender(response.data.user.Gender);
          setStatus(response.data.user.Status);

          setDepartment1(response.data.user.Department_1);
          setPreferredLanguage(response.data.user.Preferred_Language);
          setAgeGroup(response.data.user.Age_Group);
          setEduLevel(response.data.user.Education_Level);
        })
        .catch(() => {
          console.log("Trying to edit user but the data has been corrupted");
          navigate(-1);
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    const formData = new FormData(e.currentTarget);
    e.preventDefault();

    let param = {};
    for (let [key, value] of formData.entries()) {
      param[key] = value;
    }
    param["office_number"] = officeNumber;
    param["mobile_number"] = mobileNumber;

    if (isEditing) {
      // API will verify User Role from token, and edit the existing Member base on the ID
      param["id"] = user.id;
    }

    api
      .post("/association/addOrEditMember", param)
      .then(function (response) {
        console.log(response);
        alert("Member saved successfully.");
        navigate("/cms/members");
      })
      .catch(function (error) {
        console.log(error);
        alert(
          error.response.data.message
            ? error.response.data.message
            : "Please enter all fields"
        );
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <ShadowContainer>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Username<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder={"Username"}
              name="username"
              defaultValue={isEditing ? user.Username : ""}
              required={true}
              title="Username can only use letters, numbers, underscores and periods."
              pattern="^([a-zA-Z0-9_.]{1,})$"
            />
          </div>
        </div>
        <div className="mb-3 font-size-12px text-danger">
          *Username can only use letters, numbers, underscores and periods.
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Contact Person<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"Last Name"}
              name="contact_person_last_name_en"
              defaultValue={contactPersonLastName.EN}
              required={true}
            />
          </div>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"First Name"}
              name="contact_person_first_name_en"
              defaultValue={contactPersonFirstName.EN}
              required={true}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Contact Person (繁)
          </label>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"Last Name"}
              name="contact_person_last_name_tc"
              defaultValue={contactPersonLastName.TC}
            />
          </div>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"First Name"}
              name="contact_person_first_name_tc"
              defaultValue={contactPersonFirstName.TC}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Contact Person (简)
          </label>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"Last Name"}
              name="contact_person_last_name_sc"
              defaultValue={contactPersonLastName.SC}
            />
          </div>
          <div className="col-sm-2">
            <input
              type="text"
              className="form-control"
              placeholder={"First Name"}
              name="contact_person_first_name_sc"
              defaultValue={contactPersonFirstName.SC}
            />
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label className="text-5c5c5c col-sm-2 col-form-label">Gender</label>
          <div className="col-sm-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="Male"
                name="gender"
                defaultChecked
                checked={gender === "Male"}
                onChange={(e) => setGender(e.target.value)}
              />
              <label className="form-check-label">Male</label>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="Female"
                name="gender"
                checked={gender === "Female"}
                onChange={(e) => setGender(e.target.value)}
              />
              <label className="form-check-label">Female</label>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Membership No.
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control"
              placeholder={"Membership No."}
              name="membership_no"
              defaultValue={isEditing ? user.Membership_No : ""}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Office Number
          </label>
          <div className="col-sm-6">
            <PhoneInput
              inputClass={"form-control"}
              inputStyle={{ width: "100%" }}
              name="office_number"
              value={officeNumber}
              placeholder=""
              onChange={(value) => {
                setOfficeNumber(value);
              }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Mobile Number
          </label>
          <div className="col-sm-6">
            <PhoneInput
              inputClass={"form-control"}
              inputStyle={{ width: "100%" }}
              name="mobile_number"
              value={mobileNumber}
              placeholder=""
              onChange={(value) => setMobileNumber(value)}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Email Address<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-3">
            <input
              type="email"
              className="form-control"
              placeholder={"Association email"}
              name="association_email"
              defaultValue={isEditing ? user.Association_Email : ""}
              required={true}
            />
          </div>
          <div className="col-sm-3">
            <input
              type="email"
              className="form-control"
              placeholder={"Personal email"}
              name="personal_email"
              defaultValue={isEditing ? user.Personal_Email : ""}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Age Group
          </label>
          <div className="col-sm-6">
            <select
              className="form-select"
              value={ageGroup}
              name={"age_group"}
              onChange={(e) => setAgeGroup(e.target.value)}
            >
              <AgeGroup></AgeGroup>
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Education Level
          </label>
          <div className="col-sm-6">
            <select
              className="form-select"
              value={eduLevel}
              name={"education_level"}
              onChange={(e) => setEduLevel(e.target.value)}
            >
              <EduLevel></EduLevel>
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Company Name
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control"
              placeholder={"Company Name"}
              name="company_name"
              defaultValue={isEditing ? user.Company_Name : ""}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Department<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-3">
            <select
              className="form-select"
              value={department1}
              name={"department_1"}
              onChange={(e) => setDepartment1(e.target.value)}
              required={true}
            >
              <Department />
            </select>
          </div>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              placeholder={"Branch"}
              name="branch"
              defaultValue={isEditing ? user.Branch : ""}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Position
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="position"
              defaultValue={isEditing ? user.Position : ""}
            />
          </div>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              placeholder={"Level"}
              name="level"
              defaultValue={isEditing ? user.Level : ""}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Year Joined
          </label>
          <div className="col-sm-6">
            <input
              type="number"
              className="form-control"
              name="year_joined"
              min={1900}
              max={new Date().getFullYear()}
              defaultValue={isEditing ? user.Year_Joined : new Date().getFullYear()}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Training Yearly Target<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-6">
            <input
              type="number"
              className="form-control"
              name="training_yearly_target"
              min="1"
              max="999"
              defaultValue={isEditing ? user.Training_Yearly_Target : 10}
              required
            />
            <div className="mb-4">Enter the target number of training to be achieved per year.</div>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Preferred Language
          </label>
          <div className="col-sm-6">
            <select
              className="form-select"
              value={preferredLanguage}
              name="preferred_language"
              onChange={(e) => setPreferredLanguage(e.target.value)}
            >
              <option value="Preferred Language..." disabled hidden></option>
              <option value="EN">English</option>
              <option value="TC">繁體中文</option>
              <option value="SC">简体中文</option>
            </select>
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Status<span className={"text-danger"}>*</span>
          </label>
          <div className="col-sm-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="Active"
                name="status"
                defaultChecked
                checked={status === "Active"}
                onChange={(e) => setStatus(e.target.value)}
              />
              <label className="form-check-label">Active</label>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="Disable"
                name="status"
                checked={status === "Disable"}
                onChange={(e) => setStatus(e.target.value)}
              />
              <label className="form-check-label">Disabled</label>
            </div>
          </div>
        </div>
        <div className="mb-3 font-size-12px text-danger col-form-label">
          *Having your member status set to “Active”, an account activation email will be sent to the email address input for the member once you saved the profile.
        </div>
      </ShadowContainer>

      <div
        className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
        style={{ left: 240 }}
      >
        <div className={"d-flex"}>
          <div className={"me-3"}>
            <PrimaryButton
              type={"submit"}
            >
              Save
            </PrimaryButton>
          </div>
          <div className={"me-3"}>
            <Button
              variant={"secondary"}
              type={"button"}
              onClick={() => {
                navigate('/cms/members');
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
