import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { parseDbStringToObj } from "../util/lang";
import CourseProgress from "./CourseProgress";
import { getApiClient } from "../util/auth";
import ProfileTop from "./ProfileTop";
import AdminViewLearnerInfo from "./AdminViewLearnerInfo";
import LearnerCertificates from "./Cms/LearnerCertificates";
import AdminViewLearnerCourses from "./Cms/AdminViewLearnerCourses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from 'react-i18next';

export default function AdminViewLearnerCard(props) {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const [user, setUser] = useState('');

  const [joinOrgDate, setJoinOrgDate] = useState('');

  let CURRENT_LANGUAGE = "EN";

  const api = getApiClient();

  useEffect(() => {
    api
      .post("/admin/viewUser", { selected_user_id: props.user.id })
      .then((response) => {
        let u = response.data.user;
        u.Contact_Person_First_Name = parseDbStringToObj(u.Contact_Person_First_Name);
        u.Contact_Person_Last_Name = parseDbStringToObj(u.Contact_Person_Last_Name);

        setJoinOrgDate(u.Year_Joined);
        setUser(u);

        //Admin only can view English
        if(localStorage.getItem('i18nLanguage') !== "EN") {
          i18n.changeLanguage("EN");
          localStorage.setItem('i18nLanguage', "EN");
          CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("Trying to view user but the data has been corrupted");
        console.log(error);
        alert("Cannot view user");
        navigate(-1);
      });
  }, []);

  if(!user){
    return <>Loading....</>;
  }

  return (
    <>
      <Container fluid className="cms-staff-container p-0">
        <Row className={"no-gutters gx-0"}>
          <Col>
            <div className={"position-relative"}>
              <div
                className={"position-absolute"}
                style={{ left: 0, top: -40, right: 0 }}
              >
                <div className="ms-5 max-width-1304 px-5">
                  <Row className={"align-items-center"}>
                    <ProfileTop user={user} CURRENT_LANGUAGE={CURRENT_LANGUAGE}/>
                    <div className="col-auto">
                      {/* <button className={"btn btn-danger text-white mx-1"} disabled>
                        Reset Password
                      </button> */}
                    </div>
                  </Row>
                </div>
              </div>


              <AdminViewLearnerInfo user={user} />


              <div className={"row"}>

              </div>
            </div>
            <div className="ms-5 max-width-1304 p-5 bg-white mb-5">
              <Row className="align-items-center mb-3">
                <div className="col fw-bold font-size-20px text-decoration-underline">
                  Data Analysis
                </div>
              </Row>

              <CourseProgress user={user} />
            </div>

            <AdminViewLearnerCourses user={user} CURRENT_LANGUAGE={CURRENT_LANGUAGE}/>

            <LearnerCertificates user={user} endpoint={"/user/viewAssignedLearnerTotalCertificate"} />

          </Col>
        </Row>
      </Container>
      <div style={{height:100}}></div>

      {props?.IsHideCancelButton !== true
      ?
      <div
        className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
        style={{ left: 240 }}
      >
        <div className={"d-flex"}>
          <div className={"me-3"}>
            <Button
              variant={"secondary"}
              type={"button"}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      :
      ''
      }
    </>
  );
}
