export function get_nav_headers(refs) {
    const nav_headers = new Map();
    nav_headers.set('profileInformation', {
        title: refs?.CURRENT_LANGUAGE === "TC" ? "個人資料" : refs?.CURRENT_LANGUAGE === "SC" ?  "个人资料" : "Profile Information",
        ref: refs.profileInformationRef,
        id: "profile-information",
    });
    nav_headers.set('dataAnalysis', {
        title: refs?.CURRENT_LANGUAGE === "TC" ? "數據分析" : refs?.CURRENT_LANGUAGE === "SC" ?  "数据分析" : "Data Analysis",
        ref: refs.dataAnalysisRef,
        id: "data-analysis",
    });
    nav_headers.set('myCourses', {
        title: refs?.CURRENT_LANGUAGE === "TC" ? "我的課程" : refs?.CURRENT_LANGUAGE === "SC" ?  "我的课程" : "My Courses",
        ref: refs.myCoursesRef,
        id: "my-courses",
    });
    nav_headers.set('myCertificates', {
        title: refs?.CURRENT_LANGUAGE === "TC" ? "我的證書" : refs?.CURRENT_LANGUAGE === "SC" ?  "我的证书" : "My Certificates",
        ref: refs.myCertificatesRef,
        id: "my-certificates",
    });
    return nav_headers;
}
