import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getLangNameByCode, parseDbStringToObj } from "../util/lang";
import { getApiClient } from "../util/auth";
import ProfileTop from "./ProfileTop";
import Container from "react-bootstrap/Container";
import ShadowContainer from "../components/ShadowContainer";
import PhoneDisplay from "../components/PhoneDisplay";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";

export default function AdminViewCompanyCard(props) {
  const navigate = useNavigate();

  const [user, setUser] = useState('');

  let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');

  const api = getApiClient();

  useEffect(() => {
    api
      .post("/admin/viewUser", { selected_user_id: props.user.id })
      .then((response) => {
        let res_user = response.data.user;

        //Conact Person
        res_user.Contact_Person_First_Name = parseDbStringToObj(res_user.Contact_Person_First_Name);
        res_user.Contact_Person_Last_Name = parseDbStringToObj(res_user.Contact_Person_Last_Name);
        // CONTACT - Address City Province Country (not general)
        res_user.Contact_Address = parseDbStringToObj(res_user.Contact_Address);
        res_user.Contact_City = parseDbStringToObj(res_user.Contact_City);
        res_user.Contact_Province = parseDbStringToObj(res_user.Contact_Province);
        res_user.Contact_Country = parseDbStringToObj(res_user.Contact_Country);
        // GENERAL - Address City Province Country
        res_user.General_Address = parseDbStringToObj(res_user.General_Address);
        res_user.General_City = parseDbStringToObj(res_user.General_City);
        res_user.General_Province = parseDbStringToObj(res_user.General_Province);
        res_user.General_Country = parseDbStringToObj(res_user.General_Country);

        res_user.About_Us_Descriptions = parseDbStringToObj(res_user.About_Us_Descriptions);

        setUser(res_user);
      })
      .catch((error) => {
        console.log("Trying to view user but the data has been corrupted");
        console.log(error);
        alert("Cannot view user");
        navigate(-1);
      });
  }, []);

  const companyName = JSON.parse(
    user?.Organization_Name ? user?.Organization_Name : "{}"
  );

  const trainerName = JSON.parse(
    user?.Trainer_Name ? user?.Trainer_Name : "{}"
  );

  const associationName = JSON.parse(
    user?.Association_Name ? user?.Association_Name : "{}"
  );

  if(!user){
    return <>Loading....</>;
  }

  return (
    <>
      <div className={"text-secondary font-size-20px fw-700"}>View Profile</div>
      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>General Information</div>
        <Row className={"align-items-center mb-3"}>
          <Col xs={3}>
            <img
              src={
                user.Role === 'Organization'
                ?
                  user.Organization_Logo ? user.Organization_Logo : "https://via.placeholder.com/300"
                :
                user.Role === 'Trainer/Academy'
                ?
                  user.Trainer_Logo ? user.Trainer_Logo : "https://via.placeholder.com/300"
                :
                user.Role === 'Association'
                ?
                  user.Association_Logo ? user.Association_Logo : "https://via.placeholder.com/300"
                :
                "https://via.placeholder.com/300"
              }
              alt={user.Role === 'Organization' ? "Organization Logo" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Logo" : user.Role === 'Association' ? "Association Logo" : ""}
              className={"img-fluid"}
              width="300px"
              height="300px"
            />
          </Col>
          <Col>
            <div className={"break-word"}>{user.Role === 'Organization' ? companyName.EN : user.Role === 'Trainer/Academy' ? trainerName.EN : user.Role === 'Association' ? associationName.EN : ""}</div>
            <div className={"break-word"}>{user.Role === 'Organization' ? user.Organization_Email : user.Role === 'Trainer/Academy' ? user.Trainer_Email : user.Role === 'Association' ? user.Association_Email : ""}</div>
          </Col>
        </Row>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Name" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Name" : user.Role === 'Association' ? "Association Name" : ""}</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? companyName.EN : user.Role === 'Trainer/Academy' ? trainerName.EN : user.Role === 'Association' ? associationName.EN : ""}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Name (繁)" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Name (繁)" : user.Role === 'Association' ? "Association Name (繁)" : ""}</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? companyName.TC : user.Role === 'Trainer/Academy' ? trainerName.TC : user.Role === 'Association' ? associationName.TC : ""}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Name (简)" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Name (简)" : user.Role === 'Association' ? "Association Name (简)" : ""}</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? companyName.SC : user.Role === 'Trainer/Academy' ? trainerName.SC : user.Role === 'Association' ? associationName.SC : ""}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Business Registration</Col>
              <Col xs={7} className={"text-eb6201"}><a href={user.Business_Registration} target="_blank" rel="noopener noreferrer">[View BR]</a></Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Industry</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Industry}</Col>
            </Row>
            {(user.Role === 'Organization' || user.Role === 'Association')
            ?
            <Row className={"mb-3"}>
              <Col xs={5}>No. of {user.Role === 'Organization' ? "Staffs" : user.Role === 'Association' ? "Members" : ""}</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? user.No_of_Staff : user.Role === 'Association' ? user.No_of_Member : ""}</Col>
            </Row>
            :
            ''
            }
            {/* <Row className={"mb-3"}>
              <Col xs={5}>Preferred Language</Col>
              <Col xs={7} className={"font-black break-word"}>{getLangNameByCode(user.Preferred_Language)}</Col>
            </Row> */}
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Website" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Website" : user.Role === 'Association' ? "Association Website" : ""}</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? user.Organization_Website : user.Role === 'Trainer/Academy' ? user.Trainer_Website : user.Role === 'Association' ? user.Association_Website : ""}</Col>
            </Row>

          </Col>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={3}>Country</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Country.EN}
                </div>
              </Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={3}>Address</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.EN}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.EN}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.EN}
                </div>
              </Col>
            </Row>



            <Row className={"mb-3"}>
              <Col xs={3}>Address (繁)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.TC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.TC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.TC}
                </div>
              </Col>
            </Row>



            <Row className={"mb-3"}>
              <Col xs={3}>Address (简)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Address.SC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_City.SC}
                </div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>
                  {user.General_Province.SC}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Contact Information</div>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Salutation !== '--' ? user.Salutation : ''}&nbsp;
                {user.Contact_Person_First_Name.EN}&nbsp;
                {user.Contact_Person_Last_Name.EN}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person (繁)</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Contact_Person_Last_Name.TC}
                {user.Contact_Person_First_Name.TC}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Contact Person (简)</Col>
              <Col xs={7} className={"font-black break-word"}>
                {user.Contact_Person_Last_Name.SC}
                {user.Contact_Person_First_Name.SC}
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Title</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Title}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Department</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Department_1}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>E-mail</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Role === 'Organization' ? user.Organization_Email : user.Role === 'Trainer/Academy' ? user.Trainer_Email : user.Role === 'Association' ? user.Association_Email : ""}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Phone No.</Col>
              <Col xs={7} className={"font-black break-word"}>
                <PhoneDisplay number={user?.Mobile_Number} />
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Fax No.</Col>
              <Col xs={7} className={"font-black break-word"}>
                <PhoneDisplay number={user?.Fax_Number} />
              </Col>
            </Row>
          </Col>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={3}>Country</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Country.EN}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address</Col>
              <Col>
                <div className={"font-black break-word"}>{user.Contact_Address.EN}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col>
                <div className={"font-black break-word"}>{user.Contact_City.EN}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col>
                <div className={"font-black break-word"}>{user.Contact_Province.EN}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address (繁)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Address.TC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_City.TC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Province.TC}</div>
              </Col>
            </Row>


            <Row className={"mb-3"}>
              <Col xs={3}>Address (简)</Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Address.SC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_City.SC}</div>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={3}></Col>
              <Col xs={9}>
                <div className={"font-black break-word"}>{user.Contact_Province.SC}</div>
              </Col>
            </Row>

          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Account Information</div>

        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>Registered Date</Col>
              <Col xs={7} className={"font-black break-word"}>{moment(user?.createdAt)?.format('DD MMM YYYY')}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>Second Email</Col>
              <Col xs={7} className={"font-black break-word"}>{user.Personal_Email_2nd}</Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>About Us</div>
        <Row>
          <Col className="col-lg-6 col-12 form-div">
            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.EN}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description (繁)</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.TC}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>About Us Description (简)</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black white-space-pre-wrap break-word"}>{user.About_Us_Descriptions.SC}</Col>
            </Row>

            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Photos" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Photos" : user.Role === 'Association' ? "Association Photos" : ""}</Col>
            </Row>
            <Row className={"mb-3"}>
              <Col className={"font-black break-word"}>
                {
                  user.Role === 'Organization'
                  ?
                    user.About_Us_Company_Photo ?
                    <img
                      src={user.About_Us_Company_Photo}
                      alt="About_Us_Company_Photo"
                      className={"img-fluid"}
                      width="300px"
                      height="300px"
                    />
                    :
                    ''
                  :
                  user.Role === 'Trainer/Academy'
                  ?
                    user.About_Us_Trainer_Photo ?
                    <img
                      src={user.About_Us_Trainer_Photo}
                      alt="About_Us_Trainer_Photo"
                      className={"img-fluid"}
                      width="300px"
                      height="300px"
                    />
                    :
                    ''
                  :
                  user.Role === 'Association'
                  ?
                    user.About_Us_Association_Photo ?
                    <img
                      src={user.About_Us_Association_Photo}
                      alt="About_Us_Association_Photo"
                      className={"img-fluid"}
                      width="300px"
                      height="300px"
                    />
                    :
                    ''
                  :
                  ''
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <ShadowContainer>
        <div className={"text-secondary font-size-20px fw-700"}>Certificate</div>

        <Row>
          <Col>
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Logo" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Logo" : user.Role === 'Association' ? "Association Logo" : ""}</Col>
              <Col className={"font-black"}>
                {
                  user.Role === 'Organization'
                  ?
                    user.Certificate_Company_Logo_Show ? 'Shown' : 'Not Shown'
                  :
                  user.Role === 'Trainer/Academy'
                  ?
                    user.Certificate_Trainer_Logo_Show ? 'Shown' : 'Not Shown'
                  :
                  user.Role === 'Association'
                  ?
                    user.Certificate_Association_Logo_Show ? 'Shown' : 'Not Shown'
                  :
                  ""
                }
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col xs={5}>{user.Role === 'Organization' ? "Company Name" : user.Role === 'Trainer/Academy' ? "Trainer/Academy Name" : user.Role === 'Association' ? "Association Name" : ""}</Col>
              <Col className={"font-black"}>
                {
                  user.Role === 'Organization'
                  ?
                    user.Certificate_Company_Name_Show ? 'Shown' : 'Not Shown'
                  :
                  user.Role === 'Trainer/Academy'
                  ?
                    user.Certificate_Trainer_Name_Show ? 'Shown' : 'Not Shown'
                  :
                  user.Role === 'Association'
                  ?
                    user.Certificate_Association_Name_Show ? 'Shown' : 'Not Shown'
                  :
                  ""
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>
      <div style={{height:100}}></div>

      {props?.IsHideCancelButton !== true
      ?
      <div
        className={"fixed-bottom w-100 bg-white p-3 shadow save-row"}
        style={{ left: 240 }}
      >
        <div className={"d-flex"}>
          <div className={"me-3"}>
            <Button
              variant={"secondary"}
              type={"button"}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      :
      ''
      }
    </>
  );
}
