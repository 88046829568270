import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Banner(props) {
  const url = props.backgroundImage
    ? props.backgroundImage
    : "";
  const size = props.backgroundSize ? props.backgroundSize : "cover";
  const backgroundPosition = props?.backgroundPosition ? props.backgroundPosition : "";
  return (
    <div style={{ background: `url(${url})`, backgroundSize: size, backgroundRepeat: 'no-repeat', backgroundPosition: backgroundPosition }}>
      <Container className="max-width-1304">
        <Row className="align-items-end" style={{ height: 200 }}>
          <Col>
            <div
              className={"text-white mb-5"}
              style={{
                fontSize: 26,
                fontWeight: "bold",
                textShadow: "4px 3px 4px #000000CC",
              }}
            >
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
