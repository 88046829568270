export default function Paragraph(props) {
  const style = {
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "10px",
    marginBottom: "10px",
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line'
  };

  return (
    <p style={{ ...style, ...props.style }} className={props.className}>
      {props.children}
    </p>
  );
}
