import React, {useEffect, useState} from "react";
import LayoutCms from "../../../components/LayoutCms";
import { Button, Col, Container, Row } from "react-bootstrap";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import axios from "axios";
import { getTextByCode } from "../../../util/lang";

export default function AssignedUser() {
  const token = localStorage.getItem("token");
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
    headers: { "x-access-token": token },
  });
  const [allCourses, setAllCourses] = useState([]);
  const [assigned_staffs, set_assigned_staffs] = useState([]);
  const [selected_course_id, set_selected_course_id] = useState();
  const [searched_course, set_searched_course] = useState();

  useEffect(() => {
    api
        .post("/user/view_own_courses") //no param needed for this API
        .then((response) => {
          setAllCourses(response.data.courses);
        })
        .catch((error) => {});
  }, []);

  const handleSearch = () => {
    const searched = allCourses.filter((course) => {
      return course.id == selected_course_id;
    });

    if (searched.length > 0) {
      set_searched_course(searched[0]);

      api
          .post("/user/viewAssignedStaffsByCourse", {
            course_id: searched[0].id,
          })
          .then((response) => {
            set_assigned_staffs([]);
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
    }
  };

  const handleUnassigned = () => {
    const result = window.confirm('Are you sure wish to unassign?');
    if (result && searched_course?.id) {
      api
          .post("/organization/unassign_course", {
            course_id: searched_course.id,
            staff_id: "501",
          })
          .then((response) => {
            alert('Success');
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
    }
  };

  return (
    <LayoutCms route={'staffs.assigned-user'}>
      <div className={"row align-items-center"}>
        <div className={"col"}>
          <div className={"text-secondary h1"}>Assigned User</div>
          <div className={"text-adb5bd"}>
            Search a course to view the assigned user or unassign a staff
          </div>
        </div>
      </div>

      <ShadowContainer>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (ENG)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(event) => {set_selected_course_id(event.target.value)}}>
              <option>Please select</option>
              {
                allCourses.map((course) => (
                    <option value={course.id} key={course.id}>{getTextByCode(course.Course_Name, 'EN')}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Course Name (繁)
          </label>
          <div className="col-sm-6">
            <select className="form-select" value={selected_course_id} onChange={(event) => {set_selected_course_id(event.target.value)}}>
              <option>Please select</option>
              {
                allCourses.map((course) => (
                    <option value={course.id} key={course.id}>{getTextByCode(course.Course_Name, 'TC')}</option>
                ))
              }
            </select>
          </div>
        </div>

        <PrimaryButton
          type={"submit"}
          onClick={handleSearch}
        >
          Search
        </PrimaryButton>

        <hr />

        <div className={"text-secondary h1"}>Search Result info</div>
        <Row>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Code</Col>
              <Col>{searched_course?.Course_Code}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Name</Col>
              <Col>{getTextByCode(searched_course?.Course_Name, 'EN')}</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Owner</Col>
              <Col></Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Upload date</Col>
              <Col></Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Off the shelf date</Col>
              <Col>{searched_course?.Off_The_Shelf_Date}</Col>
            </Row>
          </Col>
          <Col>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Purchase date</Col>
              <Col>NA</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Purchase token quantities</Col>
              <Col>NA</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Token ID</Col>
              <Col>NA</Col>
            </Row>
            <Row className={"align-items-center mb-3"}>
              <Col xs={4}>Course fee</Col>
              <Col>NA</Col>
            </Row>
          </Col>
        </Row>
      </ShadowContainer>

      <Row className={"align-items-center"}>
        <Col className={"d-flex align-items-center gx-4"}>
          <span className={"text-secondary h1"}>Assigned Staff</span>
          <span
            className={"ms-5 text-white p-2 rounded"}
            style={{ background: "#A2A2A2" }}
          >
            {assigned_staffs.length}
          </span>
        </Col>
        <Col xs={"auto"} className={"d-flex align-items-center"}>
          {/* <div className={"mx-2"}>Filter By</div>
          <div className={"mx-2"}>
            <select className="form-select">
              <option>Status</option>
            </select>
          </div>
          <div className={"mx-2"}>Sort By</div>
          <div className={"mx-2"}>
            <select className="form-select">
              <option>Status</option>
            </select>
          </div>
          <div className={"mx-2"}>
            <input className="form-control" placeholder={"Search"} />
          </div> */}
        </Col>
      </Row>
      <ShadowContainer>
        <table className="table text-start" style={{ verticalAlign: "middle" }}>
          <thead>
            <tr>
              <td scope="col">
                Staff ID <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col">
                Name <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col">
                Chinese Name <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col">
                Mobile Number <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col">
                Email Address <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col">
                Token ID <i className="bi bi-arrow-down-up" />
              </td>
              <td scope="col" className={"text-center"}>
                Unassign <i className="bi bi-arrow-down-up" />
              </td>
            </tr>
          </thead>
          <tbody className={"border-0"}>
            <tr>
              <td>1</td>
              <td>Lee Wei Chia</td>
              <td>李维嘉</td>
              <td>+852 12345678</td>
              <td>leewc@gmail.com</td>
              <td>1234578</td>
              <td className={"text-center"}>
                <button className={"btn btn-primary"} onClick={handleUnassigned}>Unassign</button>
              </td>
            </tr>
          </tbody>
        </table>
      </ShadowContainer>
    </LayoutCms>
  );
}
