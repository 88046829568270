import Layout from "../../components/Layout";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Section from "../../components/Section";
import H2 from "../../components/H2";
import Banner from "../../components/Banner";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { parseDbStringToObj } from "../../util/lang";
import '../../App.css';
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function Show() {
  const { t } = useTranslation();

  const params = useParams();
  const id = params.id;

  const [trainer, setTrainer] = useState([]);

  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
    timeout: 5000,
  });
  useEffect(() => {
    api
      .post("/public/view_trainer", {
        trainer_id: id,
      })
      .then(function (response) {
        setTrainer(response.data.trainer[0])
      })
      .catch(function (error) {
        console.log(error.data.response);
      });
  }, []);

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/membership-banner.png">
        {t('Trainer / Academy')}
      </Banner>
      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 50%",
            backgroundSize: "300px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >

          <Row className="align-items-start mb-3">
            <Col className="col-md-4 col-12">
              <img
                className={"img-fluid"}
                style={{ aspectRatio: "1 / 1", objectFit: "cover", width: "100%" }}
                src={trainer.Trainer_Logo}
                alt=""
              />
            </Col>
            <Col className={"col-md-7 col-12"}>
              <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                {parseDbStringToObj(trainer.Trainer_Name)[CURRENT_LANGUAGE]}
              </H2>
              <p style={{ fontSize: 20, color: "#535353" }} className={"mb-4"}>
                {trainer.Industry}
              </p>
              <p style={{ fontSize: 16, whiteSpace: "pre-line", verticalAlign: "bottom" }}>
                {parseDbStringToObj(trainer.About_Us_Descriptions)[CURRENT_LANGUAGE]}
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}