import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import H5 from "./H5";
import Paragraph from "./Paragraph";

export default function MembershipCard(props){

    const membership = props.membership;

    return <>
    <Col xs={12} md={3} key={membership.id} className="membership-div">
        <div
            // className={(membership.isClickable === "no" ? "d-flex flex-column" : "d-flex flex-column hover") }
            className="d-flex flex-column hover"
            style={{
            backgroundColor: membership.backgroundColor,
            padding: 33,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: "450px",
            }}
        >
            <div className="mb-1">
            <H5 style={{ fontSize: 28, lineHeight: "normal" }}>
                {membership.title}
            </H5>
            </div>
            <Paragraph style={{ fontSize: 14 }}>
            {membership.description}
            </Paragraph>
            <div>
            {/* <Link
                to={membership.exploreLink}
                style={{ color: "#266599", textDecoration: "none" }}
            >
                EXPLORE MORE&nbsp;&gt;
            </Link> */}
            </div>
        </div>
        <div>
            <Link

            reloadDocument
            to={membership.applyLink}

            className={(membership.isClickable === "no" ? "disabled btn text-white fw-bold shadow-none w-100 btn-lg" : "btn text-white fw-bold shadow-none w-100 btn-lg")}
            style={{ background: membership.applyLinkBG, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            >
            {membership.applyLinkText}
            </Link>
        </div>
    </Col>
    </>;
}