import LayoutCms from "../../../../components/LayoutCms";
import React, {useEffect, useState} from "react";
import CourseTabNavigation from "../../../../components/CourseTabNavigation";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import {getApiClient} from "../../../../util/auth";
import { useNavigate, useParams } from "react-router-dom";
import { parseDbStringToObj, getTextByCode } from "../../../../util/lang";
import CourseTitle from "../../../../components/CourseTitle";
import CourseNameCard from "../../../../components/Cms/CourseNameCard";
import DraggableDataTable from "../../courses/edit/draggableDataTable";
import DataTable from "react-data-table-component";
import EditClass from "../../../../components/Cms/EditClass";
import { Button } from "react-bootstrap";

export default function AdminMembershipViewClass(props) {

  const params = useParams();
  const navigate = useNavigate();
  const course_id = params.course_id;

  const readOnly = props.readOnly;
  const isEditing = props.isEditing;

  const [course, setCourse] = useState([]);
  const [modules, setModules] = useState([]);
  const [classes, setClasses] = useState([]);
  const [editClassId, setEditClassId] = useState('');

  const [creatingNewClass, setCreatingNewClass] = useState(false);

  const [selectedModule, setSelectedModule] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState();
  const [filteredClasses, setFilteredClasses] = useState([]);

  useEffect(() => {
    if (course_id) {
      getApiClient()
          .post("/admin/viewCourse", {
            course_id: course_id,
          })
          .then((response) => {
            setCourse(response.data.course[0]);
            const unsortedModules = response.data?.modules ?? [];
            const sortedModules = unsortedModules.sort((a, b) => a.Module_Sequence - b.Module_Sequence);
            setModules(sortedModules);
            setClasses(response.data?.classes ?? []);
            // if (response?.data?.classes?.[0]?.Class_Materials) {
            //   const json = JSON.parse(response?.data?.classes?.[0]?.Class_Materials);
            //   set_class_material(json?.[0] ?? '');
            // }
          })
          .catch((error) => {
            console.log(error);
          })
    }
  }, []);

  const getDataAgain = async(selectedModuleId) => {
    getApiClient()
          .post("/admin/viewCourse", {
            course_id: course_id,
          })
          .then((response) => {
            let classes_data = (response.data?.classes).filter((theClass) => {
              return String(theClass.ModuleId).match(String(selectedModuleId));
            })
            const unsortedClasses = classes_data ?? [];
            const sortedClasses = unsortedClasses.sort((a, b) => a.Class_Sequence - b.Class_Sequence);
            setFilteredClasses(sortedClasses);
          })
          .catch((error) => {
            console.log(error);
          })
  }

  const updateClassSequence = (filteredClasses) => {
    const classes_ids = filteredClasses.map(theClass => theClass.id);
    console.log(classes_ids);
    getApiClient()
      .post("/user/editClassesSequence", {course_id : course_id, module_id : selectedModuleId, classes_ids })
      .then((response) => {
        //window.location.reload();
        getDataAgain(selectedModuleId);
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  const classesDisplayColumnsDraggable = [
    {
      headerName: 'Module',
      valueGetter: row => {
        let m = modules.filter((element)=> element.id === row.data.ModuleId)[0];
        m = parseDbStringToObj(m.Module_Title);
        return `${row.data.ModuleId} - ${m.EN}`;
      },
      rowDrag: true,
    },
    {
      field: 'id',
      headerName: 'Class ID',
      width: 150
    },
    {
      headerName: 'Sequence',
      valueGetter: row => row.data.Class_Sequence,
      width: 150,
    },
    {
      headerName: 'Code',
      valueGetter: row => row.data.Class_Code,
    },
    {
      headerName: 'Title',
      valueGetter: row => parseDbStringToObj(row.data.Class_Name).EN
    },
    {
      headerName: 'Updated At',
      valueGetter: (row) => new Date(row.data.updatedAt).toLocaleString(),
    },
  ];


  const columns = [
    {
      name: 'Module',
      selector: row => {
        let m = modules.filter((element)=> element.id === row.ModuleId)[0];
        m = parseDbStringToObj(m.Module_Title);
        return row.ModuleId + ' - ' + m.EN;
      },
      width: '250px',
      sortable: true,
    },
    {
      name: 'Class ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Sequence',
      selector: row => row.Class_Sequence,
      sortable: true,
    },
    {
      name: ' Code',
      selector: row => row.Class_Code,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => parseDbStringToObj(row.Class_Name).EN,
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: row => new Date(row.updatedAt).toLocaleString(),
      sortable: true,
    },
    readOnly &&
    {
      name: 'View',
      cell: (row) => {
        if(creatingNewClass){
          return <span title="View"><Button disabled variant="outline-secondary">View</Button></span>;
        }else{
          return <span title="View"><PrimaryButton onClick={()=> {setSelectedModule(true); setEditClassId(row.id)}}>View</PrimaryButton></span>;
        }
      },
    },
  ];

  const handleSelectedModule = (e) => {

    setSelectedModuleId(e.target.value);
    let filteredClassesArray = classes.filter((theClass) => {
      return String(theClass.ModuleId).match(String(e.target.value));
    });
    const unsortedClasses = filteredClassesArray ?? [];
    const sortedClasses = unsortedClasses.sort((a, b) => a.Class_Sequence - b.Class_Sequence);
    setFilteredClasses(sortedClasses);
    setSelectedModule(false);
  }

  return (
    <LayoutCms route={'membership.viewCourses'}>
      <CourseTitle readOnly={readOnly} isEditing={isEditing} isAdmin={true} />

      <CourseTabNavigation readOnly={readOnly} isEditing={true} activeNavItem={"class"} />

      <CourseNameCard course={course} />

      <div className="mb-3 row">
          <label className="text-5c5c5c col-sm-2 col-form-label">
            Module
          </label>
          <div className="col-sm-4">
            <select className="form-select"
              required
              onChange={handleSelectedModule}
            >
              <option disabled selected>Please select a module to show classes...</option>
                {modules?.map((m)=>{
                  return <option key={m.id} value={m.id}>
                          {`${ getTextByCode(m.Module_Title,'EN')} (Sequence: ${m.Module_Sequence} ID: ${m.id})`}
                        </option>;
              })}
            </select>
          </div>
      </div>

      {!readOnly &&
      <ShadowContainer className={"edit-class-tbl"}>
        {filteredClasses.length === 0 ? <center className={"m-4"}>There are no records to display</center> : ''}
        {filteredClasses.length > 0 && (
          <DraggableDataTable
            tableData={filteredClasses}
            columnDefs={classesDisplayColumnsDraggable}
            updateSequence={updateClassSequence}
          />
        )}
      </ShadowContainer>
      }

      {readOnly &&
      <ShadowContainer className={"edit-class-tbl"}>
        <DataTable
          columns={columns}
          data={filteredClasses}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30,50,100]}
        />
      </ShadowContainer>
      }

      {editClassId && selectedModule &&
        <EditClass
          key={editClassId}

          editingClass={
            filteredClasses.filter((c)=> c.id === editClassId)[0]
          }
          setEditClassId={setEditClassId}
          course={course}
          modules={modules}
          readOnlyForAll={readOnly}
        />
      }
      <div style={{height:100}}></div>
    </LayoutCms>
  );
}
