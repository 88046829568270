import React, { useState } from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Section from "../components/Section";
import H2 from "../components/H2";
import { Container, Row, Col } from "react-bootstrap";
import Salutation from "../components/Options/Salutation";
import Country from "../components/Options/Country";
import { getApiClient } from "../util/auth";
import { useTranslation } from "react-i18next";
import CURRENT_LANGUAGE from "../getCurrentLanguage";

export default function ContactUs() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner.png">
        {t('Contact Us')}
      </Banner>

      <Section>
        <Container
          className="max-width-1304 mb-3"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "50% 50%",
            backgroundSize: "200px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="mb-5 g-0">
            <Col className="d-none d-md-block col-md-6">
              <div style={{ paddingRight: 25 }}>
                <img
                  className={"img-fluid w-100"}
                  src={"/assets/images/contact-us-hero.jpg"}
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <FormDiv></FormDiv>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xs={"auto"}>
              <span style={{ fontSize: 24 }} className={"fw-bold"}>{t('Office Addresses')}</span>
              <div
                style={{ width: "100%" }}
                className="hkqaa-divider"
              ></div>
            </Col>
          </Row>
          <Row className={"gy-5"}>
            <Col xs={6} sm={4}>
              <div className={"font-size-20px text-eb6201"}>{t('Hong Kong')}</div>
              <div className={"font-size-16px"}>
                {t("Hong Kong Quality Assurance Agency")}<br />
                {t('19 / F., K. Wah Centre, 191 Java Road,')}<br />
                {t('North Point, Hong Kong')}<br />
                {t('Tel')}: (852) 2202 9111
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className={"font-size-20px text-eb6201"}>{t('Guangzhou')}</div>
              <div className={"font-size-16px"}>
                {t('HKQAA Certification (Shanghai) Ltd.')}<br />
                {t('Guangzhou Branch')}<br />
                {t('22 / F., Nova Tower, No. 185 Yue Xiu Road South,')}<br />
                {t('Guangzhou, China.')}<br />
                {t('Postal Code: 510100')}<br />
                {t('Tel')} : (86 20) 8383 3777
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className={"font-size-20px text-eb6201"}>{t('Xi’an')}</div>
              <div className={"font-size-16px"}>
                {t('HKQAA Certification (Shanghai) Ltd.')}<br />
                {t('Xian Representative Office')}<br />
                {t('Room 1608, 16/F., New World, 70 Weibin Road,')}<br />
                {t('Weiyang District, Xi’an City, Shanxi Province, China')}<br />
                {t('Postal Code: 710021')}<br />
                {t('Tel')} : (86 29) 8636 0030
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className={"font-size-20px text-eb6201"}>{t('Shanghai')}</div>
              <div className={"font-size-16px"}>
                {t('HKQAA Certification (Shanghai) Ltd.')}<br />
                {t('Unit 2007, 20/F., Pole Tower, 425 Yishan Road,')}<br />
                {t('Xuhui District, Shanghai, China.')}<br />
                {t('Postal Code: 200235')}<br />
                {t('Tel')}: (86 21) 6876 9911
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className={"font-size-20px text-eb6201"}>{t('Macau')}</div>
              <div className={"font-size-16px"}>
                {t('HKQAA Certification (Macau) Ltd.')}<br />
                {t('Level 20, AIA Tower, Nos 251A-301 Avenida')}<br />
                {t('Comercial De Macau, Macau')}<br />
                {t('Tel')} : (853) 6238 8759 / (852) 6293 0647
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}

function FormDiv(props) {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({});

  const api = getApiClient();
  const handleSubmit = async (e) => {
    e.preventDefault();

    api.post(`/public/submitContactUsForm`, { ...formValues })
      .then(function (response) {
        alert(t('Submitted'))
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          alert(t(error.response.data.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValues(values => ({ ...values, [name]: value }))
  }

  return <form onSubmit={(e) => handleSubmit(e)}>
    <div className="pl-sm-0" style={{ paddingLeft: 24 }}>
      <Row>
        <div className="col-auto">
          <H2 style={{ fontSize: 40 }} className={"mt-0"}>
            {t('Contact Us')}
          </H2>
          <div
            style={{ width: "100%" }}
            className="hkqaa-divider"
          ></div>
        </div>
      </Row>

      <p
        style={{ fontSize: 16, color: "#212529" }}
        className={"mb-4"}
      >
        {t('If you have any enquiries, please complete the below e-form. We will be contacting you later')}
      </p>

      <Row className="mb-3">
        <label className="col-3 col-form-label">{t('Title')}</label>
        <Col>
          <select
            type="text"
            className="form-select"
            placeholder={t("Title")}
            name="title"
            onChange={(e) => handleChange(e)}
          >
            <Salutation></Salutation>
          </select>
        </Col>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label">{t('First Name')}</label>
        <Col>
          <input
            type="text"
            className="form-control"
            placeholder={t("First Name")}
            name="first_name"
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label">{t('Last Name')}</label>
        <Col>
          <input
            type="text"
            className="form-control"
            placeholder={t("Last Name")}
            name="last_name"
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label">{t('Email')}</label>
        <Col>
          <input
            type="email"
            className="form-control"
            placeholder={t("Email")}
            name="email"
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label">
          {t('Country/Region')}
        </label>
        <Col>
          <select
            type="text"
            className="form-select"
            placeholder={t("Country/Region")}
            name="country_region"
            onChange={(e) => handleChange(e)}
          >
            <Country lang={CURRENT_LANGUAGE}></Country>
          </select>
        </Col>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label">{t('Message')}</label>
        <div className="col">
          <textarea
            className="form-control"
            rows="3"
            placeholder={t("Message")}
            name="message"
            onChange={(e) => handleChange(e)}
          ></textarea>
        </div>
      </Row>
      <Row className="mb-3">
        <label className="col-3 col-form-label"></label>
        <Col>
          <button className="btn bg-eb6201 text-white fw-bold shadow-none">
            {t('Submit')}
          </button>
        </Col>
      </Row>
    </div>
  </form>
}
