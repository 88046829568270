import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Section from "../components/Section";
import H5 from "../components/H5";
import Paragraph from "../components/Paragraph";
import { Container, Row, Col } from "react-bootstrap";
import { getFAQs } from "../services/faq-service";
import {useTranslation} from "react-i18next";

export default function FAQ() {
    const { t } = useTranslation();
  const FAQs = getFAQs();

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner.png">
          {t('FAQ')}
      </Banner>

      <Section>
        <Container
          className="max-width-1304 mb-3"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "50% 50%",
            backgroundSize: "200px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="mb-5 g-0">
            <Col className="mb-5">
              <FormDiv></FormDiv>
            </Col>
            <Col className="col-sm-12">
              {FAQs.map((F) => (
                <>
                  <H5 style={{ marginBottom: "30px", color: "#eb6201" }}>{F.title}</H5>
                  <Paragraph style={{ marginBottom: "40px" }}>{F.description}</Paragraph>
                </>
              ))}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}

function FormDiv(props) {
  return <div style={{ maxWidth: "400px" }}>
    <form>
      <div className="input-group">
        {/* <span
          className="input-group-text bg-white border-end-0"
          id="search"
        >
          <i className="bi bi-search"></i>
        </span>
        <input
          type="text"
          className="form-control border-start-0"
          placeholder="Search"
        /> */}
      </div>
    </form>
  </div>
}
