import React from "react";
import ShadowContainer from "../../ShadowContainer";

export default function CmsEditProfCert(props) {
    // 0 = not set, 1 = show , 2 = not shown
    // MySQL null and 0 are both returned as zero string "0"
    // so we have to use 1 and 2 instead
    const showLogo = parseInt(props.logo, 10);
    const showName = parseInt(props.name, 10);

    const role = localStorage.getItem("role");

    return (
        <ShadowContainer>
            <div className={"text-5c5c5c"}>
                Do you wish to show {role === "Organization" ? "Company" : role === "Trainer/Academy" ? "Trainer/Academy" : role === "Association" ? "Association" : ""} Name/Logo on the certificate?
            </div>
            <div className="mb-3 row align-items-center">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    {role === "Organization" ? "Company" : role === "Trainer/Academy" ? "Trainer/Academy" : role === "Association" ? "Association" : ""} Logo
                </label>
                <div className="col-sm-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                        name="logo"
                        value="1"
                        checked={showLogo === 1}
                        onChange={(e)=>props.setLogo(e.target.value)} />
                        <label className="form-check-label">Show</label>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                        name="logo"
                        value="0"
                        checked={showLogo === 0}
                        onChange={(e)=>props.setLogo(e.target.value)} />
                        <label className="form-check-label">Not Show</label>
                    </div>
                </div>
            </div>
            <div className="mb-3 row align-items-center">
                <label className="text-5c5c5c col-sm-2 col-form-label">
                    {role === "Organization" ? "Company" : role === "Trainer/Academy" ? "Trainer/Academy" : role === "Association" ? "Association" : ""} Name
                </label>
                <div className="col-sm-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                        value="1"
                        checked={showName === 1}
                        onChange={(e)=>props.setName(e.target.value)} />
                        <label className="form-check-label">Show</label>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                        value="0"
                        checked={showName === 0}
                        onChange={(e)=>props.setName(e.target.value)} />
                        <label className="form-check-label">Not Show</label>
                    </div>
                </div>
            </div>
        </ShadowContainer>
    );
}
