import React, { useState } from "react";
import axios from "axios";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import CopyRightLogin from "../components/CopyRightLogin";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const reset_password_token = searchParams.get("reset_password_token"); // Defined in backend src models/auth/sendEMailResetPassword.js:45
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let param = {
      reset_password_token: reset_password_token,
      password: password,
    };

    if (password === passwordRepeat && password.length >= 6) {
      //TODO validate password length, upper lower case etc
      axios
        .post(`${process.env.REACT_APP_API_HOST}/auth/resetPassword`, param)
        .then(function (response) {
          if (response.status === 202) {
            navigate("/login");
          }
        })
        .catch((error) => {
          if (error.response) {
            alert(t(error.response.data.message)); // => the response payload
          }
        });
    } else {
      alert(t("Confirm Password is not equal to Password"));
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown2(!passwordShown2);
  };

  return (
    <div
      className="vh-100 d-flex flex-column"
      style={{
        backgroundImage:
          "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png), url(/assets/images/home_background_insights_1.png)",
        backgroundPosition: "0px 5%, 95% 5%, 90% 70%",
        backgroundSize: "auto auto, auto auto, 300px",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundAttachment: "scroll, scroll",
      }}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <Container
          style={{
            maxWidth: "450px",
            boxShadow: "0px 3px 25px #0000001F",
            padding: 40,
          }}
        >
          <div className="text-center mb-3">
            <Link to="/">
              <img src="/assets/images/logo.png" width={175} alt="logo" />
            </Link>
          </div>
          <div className="d-flex font-size-24px text-4a4a4a mb-3">
            {t('Please enter your password')}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label fw-bold">{t('Password')}</label>
              <div className="input-group">
                <span
                  className="input-group-text bg-white border-end-0"
                  id="password"
                >
                  <i className="bi bi-lock" />
                </span>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control border-start-0"
                  name="password"
                  required={true}
                  pattern="^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})$"
                  title={t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="input-group-text bg-white"
                  id="eye"
                  onClick={togglePassword}
                  style={{ cursor: "pointer" }}
                >
                  <i className={passwordShown ? "bi bi-eye-slash" : "bi bi-eye"} />
                </span>
              </div>
            </div>
            <div className="mb-3 font-size-12px text-muted">
              *{t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
            </div>
            <div className="mb-3">
              <label className="form-label fw-bold">{t('Confirm Password')}</label>
              <div className="input-group">
                <span
                  className="input-group-text bg-white border-end-0"
                  id="password"
                >
                  <i className="bi bi-lock" />
                </span>

                <input
                  type={passwordShown2 ? "text" : "password"}
                  className="form-control border-start-0"
                  name="password_repeat"
                  required={true}
                  pattern="^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})$"
                  title={t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                />
                <span
                  className="input-group-text bg-white"
                  id="eye"
                  onClick={togglePassword2}
                  style={{ cursor: "pointer" }}
                >
                  <i className={passwordShown2 ? "bi bi-eye-slash" : "bi bi-eye"} />
                </span>
              </div>
            </div>
            <button
              type="submit"
              className={"w-100 btn bg-eb6201 text-white fw-bold"}
            >
              {t('Next')}
            </button>
          </form>
        </Container>
      </div>

      <CopyRightLogin></CopyRightLogin>
    </div>
  );
}
