export default function H5(props) {
  const style = {
    fontSize: "24px",
    lineHeight: "20px",
    marginTop: 0,
    marginBottom: "10px",
    fontWeight: "900",
  };

  return (
    <div style={{ ...style, ...props.style }} className={props.className}>
      {props.children}
    </div>
  );
}
