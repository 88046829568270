import Layout from "../../components/Layout";
import Section from "../../components/Section";
import H2 from "../../components/H2";
import H5 from "../../components/H5";
import { getApiClient } from "../../util/auth";
import { parseDbStringToObj } from "../../util/lang";
import React, { useEffect, useState } from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import Paragraph from "../../components/Paragraph";
import Banner from "../../components/Banner";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import '../../App.css';
import { useTranslation } from 'react-i18next';
import CURRENT_LANGUAGE from "../../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function InsightsAndTrendsShow() {
  const { t } = useTranslation();

  const api = getApiClient();

  const params = useParams();
  const id = params.id;

  const [insightsAndTrends, setInsightsAndTrends] = useState([]);

  useEffect(() => {
    api
      .post("/public/view_insights_and_trends", {
        insights_and_trends_id: id
      })
      .then(function (response) {
        setInsightsAndTrends(response.data.insights_and_trends[0]);
      })
      .catch(function (error) {
        console.log(error?.data?.response?.message);
      });
  }, []);
  
  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner_insight.png">
        {t('Insights And Trends')}
      </Banner>
      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 0%",
            backgroundSize: "280px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="align-items-center mb-3 g-0">
            <Col>
              <div className="insights-container">
                <H5 style={{ fontSize: 18 }} className={"mb-4"}>
                {t('Insights and Trends')}
                </H5>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {parseDbStringToObj(insightsAndTrends.Insights_And_Trends_Title)[CURRENT_LANGUAGE]}
                </H2>
                <p style={{ fontSize: 16 }}>
                  {t('Published')} {moment(insightsAndTrends.Last_Published_Date)?.format('DD MM YYYY')}
                </p>
                <div className="mb-3">
                  <video 
                    key={insightsAndTrends.Insights_And_Trends_Video}
                    onContextMenu={(e) => e.preventDefault()} 
                    width="100%" height="auto" style={{ maxHeight: "500px" }} 
                    controls autoPlay muted 
                    controlsList="nodownload">
                    <source src={insightsAndTrends.Insights_And_Trends_Video} />
                  </video>
                </div>
                <p style={{ fontSize: 16 }} className="pre-line break-word">
                  {parseDbStringToObj(insightsAndTrends.Insights_And_Trends_Content)[CURRENT_LANGUAGE]}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout >
  );
}
