import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LayoutCms from "../../../../components/LayoutCms";
import { Button, Col, Row } from "react-bootstrap";
import ShadowContainer from "../../../../components/ShadowContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import { getApiClient, getTokens, removeTokens } from "../../../../util/auth";
import { getTextByCode, parseDbStringToObj } from "../../../../util/lang";
import moment from "moment";

export default function AdminContentManagementAssociation() {
    const navigate = useNavigate();
    const api = getApiClient();

    const role = localStorage.getItem("role");
    const [admin, setAdmin] = useState('');

    const [selected_association_id_no_1, set_selected_association_id_no_1] = useState("");
    const [selected_association_id_no_2, set_selected_association_id_no_2] = useState("");
    const [selected_association_id_no_3, set_selected_association_id_no_3] = useState("");
    const [selected_association_id_no_4, set_selected_association_id_no_4] = useState("");

    const [all_associations_response, set_all_associations_response] = useState([]);

    const fetch_view_all_associations = () => {
        api.post("/admin/viewAllAssociations")
            .then((response) => {
                set_all_associations_response(response.data?.all_associations);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });

        api.post("/admin/getHomePageAssociations")
            .then((response) => {
                set_selected_association_id_no_1(response.data.home_page_associations_ids[0].Display_No_1);
                set_selected_association_id_no_2(response.data.home_page_associations_ids[0].Display_No_2);
                set_selected_association_id_no_3(response.data.home_page_associations_ids[0].Display_No_3);
                set_selected_association_id_no_4(response.data.home_page_associations_ids[0].Display_No_4);
            })
            .catch((error) => {
                console.log(error?.response?.data?.message);
            });
    }

    useEffect(() => {
        if(role !== 'Admin') {
            alert('User not authorized! You will go back to Home Page.');
            if(role === null || role === '') {
                navigate('/login');
            } else {
                navigate('/');
            }
            return;
        }

        api.get("/user/viewProfile")
            .then((response) => {
                setAdmin(response.data?.user);
            })
            .catch((error) => {
                console.log(error);
                removeTokens();
                alert(error?.response?.data?.message ?? error?.message ?? error);
                navigate("/admin");
                return;
            });

            fetch_view_all_associations();
    }, []);

    if (!admin) {
        return <>Loading...</>;
    }

    const handleSaveHomepageAssociations = () => {
        // if(
        //     selected_association_id_no_1 === null || 
        //     selected_association_id_no_2 === null || 
        //     selected_association_id_no_3 === null || 
        //     selected_association_id_no_4 === null
        // ) {
        //     alert('Error! You must select all display Association with the ordering!');
        //     return;
        // }

        if(window.confirm("Are you sure to update the home page ordering of Association?")) {
            api.post("/admin/saveHomePageAssociations", { 
                    display_no_1: selected_association_id_no_1,
                    display_no_2: selected_association_id_no_2,
                    display_no_3: selected_association_id_no_3,
                    display_no_4: selected_association_id_no_4
                })
                .then((response) => {
                    alert(response?.data?.message);
                })
                .catch((error) => {
                    alert(error?.response?.data?.message ?? error.message ?? error);
                });
        }
    }

    return (
        <LayoutCms route={'content_management.association'}>

            {/** ----------- starting main section with Association content ----------- */}
            <div className={"row align-items-center"}>
                <div className={"col"}>
                    <div className={"text-secondary font-size-20px fw-700"}>
                        Association
                    </div>
                </div>
            </div>

            <ShadowContainer>

                <Row className={"search-results-div mb-3"}>
                    <Col>
                        <div className={"text-secondary font-size-20px fw-700"}>Homepage Association List</div>
                    </Col>
                </Row>

                <Row className={"search-results-div"}>
                    <Col>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.1 Association</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_association_id_no_1} 
                                    onChange={(event) => { set_selected_association_id_no_1(event.target.value) }}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_associations_response.map((association) => (
                                            <option value={association.id} key={association.id}>{getTextByCode(association.Association_Name, 'EN')} (id: {association.id}, Username: {association.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.2 Association</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_association_id_no_2} 
                                    onChange={(event) => { set_selected_association_id_no_2(event.target.value) }}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_associations_response.map((association) => (
                                            <option value={association.id} key={association.id}>{getTextByCode(association.Association_Name, 'EN')} (id: {association.id}, Username: {association.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.3 Association</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_association_id_no_3} 
                                    onChange={(event) => { set_selected_association_id_no_3(event.target.value) }}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_associations_response.map((association) => (
                                            <option value={association.id} key={association.id}>{getTextByCode(association.Association_Name, 'EN')} (id: {association.id}, Username: {association.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className={"align-items-center mb-3 mt-3"}>
                            <Col xs={3}>Display No.4 Association</Col>
                            <Col xs={6}>
                                <select 
                                    className="form-select" 
                                    value={selected_association_id_no_4} 
                                    onChange={(event) => { set_selected_association_id_no_4(event.target.value) }}
                                >
                                    <option value="" default>Please select</option>
                                    {
                                        all_associations_response.map((association) => (
                                            <option value={association.id} key={association.id}>{getTextByCode(association.Association_Name, 'EN')} (id: {association.id}, Username: {association.Username})</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <PrimaryButton
                    type={"submit"}
                    className={"mb-2"}
                    style={{ width: '120px' }}
                    onClick={handleSaveHomepageAssociations}
                >
                    Save
                </PrimaryButton>
            </ShadowContainer>
        </LayoutCms>
    );
}
