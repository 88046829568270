import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TermsAndConditionTrainerComponent from "../components/TermsAndConditionTrainer";
import PrivacyPolicyTrainerComponent from "../components/PrivacyPolicyTrainer";
import Form from "react-bootstrap/Form";
import { FileUploader } from "react-drag-drop-files";
import CopyRightLogin from "../components/CopyRightLogin";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CURRENT_LANGUAGE from "../getCurrentLanguage";  //for i18n to display backend data with user clicked language

export default function SignupTrainer() {
  let navigate = useNavigate();

  const [businessRegistration, setBusinessRegistration] = useState();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [trainerNameEN, setTrainerNameEN] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [fileMessage, setFileMessage] = useState(null);
  const [understandAndAccept, setUnderstandAndAccept] = useState(false);

  const [showModel, setShowModel] = useState(false);
  const { t } = useTranslation();

  const handleCloseModel = () => setShowModel(false);
  const handleShowModel = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert(t("Confirm Password is not equal to Password"));
      setShowModel(false);
      return;
    }

    setShowModel(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const api = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}`,
      timeout: 5000,
    });

    api
      .post("/user/signup", {
        business_registration: businessRegistration,
        username,
        password,
        first_name: firstName,
        last_name: lastName,
        trainer_email: email,
        trainer_name: { EN: trainerNameEN, TC: "", SC: "" },
        role: "Trainer/Academy",
      })
      .then(function (response) {
        if (response.status === 201) {
          localStorage.setItem("auth", JSON.stringify(response.data.auth));
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("role", response.data.role);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          navigate("/cms/trainer-profile");
        }
      })
      .catch(function (error) {
        alert(t(error.response.data.message));
        setShowModel(false);
      });
  };

  // File Upload using FileUploader from "react-drag-drop-files"
  //const [brFile, setBrFile] = useState(null);
  const fileTypes = ["JPG", "PNG", "PDF"];
  const handleBrUpload = (file) => {
    let formData = new FormData();
    formData.append("file", file);

    const api = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}`,
      timeout: 30000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    api
      .post(`${process.env.REACT_APP_API_HOST}/s3/user`, formData)
      .then(function (response) {

        // TODO check if response is valid and is it really timeout 

        // set the business registration with uploaded and returned s3 file url link
        setBusinessRegistration(response.data.urls[0]);
        //let d = document.querySelector(".cXBrtO span");
        //d.innerHTML = file.name;
        setFileMessage(file.name+t(" is uploaded successfully"));
      })
      .catch(function (error) {
        let d = document.querySelector(".cXBrtO span");
        d.innerHTML = t("Upload Failed! Please try again.");
        alert(t(error?.response?.data?.message ?? error));
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  return (
    <>
      <Modal
        show={showModel}
        onHide={handleCloseModel}
        size={"xl"}
        scrollable={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <TermsAndConditionTrainerComponent lang={CURRENT_LANGUAGE} />
          <PrivacyPolicyTrainerComponent lang={CURRENT_LANGUAGE} />

          <Form className={"d-flex align-items-center w-100"}>
            <Form.Check
              type={"checkbox"}
              label={t("By signing up, I agree to Lightshare's Privacy Policy and Terms of Use.")}
              id={"i-understand-and-accept"}
              checked={understandAndAccept}
              onChange={(e) => {
                setUnderstandAndAccept(e.target.checked);
              }}
            />

            <Button
              className={"ms-auto bg-eb6201 text-white shadow-none"}
              variant=""
              onClick={handleSubmit}
              disabled={!understandAndAccept}
            >
              {t('Sign up')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <div
        className="signup-bg vh-100 d-flex flex-column"
        style={{
          backgroundImage:
            "url(/assets/images/home_background_insights_1.png), url(/assets/images/home_background_insights_2.png), url(/assets/images/home_background_insights_1.png)",
          backgroundPosition: "0px 5%, 95% 5%, 90% 70%",
          backgroundSize: "auto auto, auto auto, 300px",
          backgroundRepeat: "no-repeat no-repeat",
          backgroundAttachment: "scroll, scroll",
        }}
      >
        <div className="flex-grow-1 d-flex align-items-center">
          <Container
            style={{
              maxWidth: "450px",
              boxShadow: "0px 3px 25px #0000001F",
              padding: 40,
            }}
          >
            <Row>
              <Col>
                <form onSubmit={handleShowModel}>
                  <div className="mb-3">
                    <button
                      type={"button"}
                      className={"btn px-0"}
                      onClick={() => navigate("/signup")}
                    >
                      <i className="bi bi-chevron-left" /> {t('Back')}
                    </button>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      {t('Trainer/Academy Name (same as B.R.)')}
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="English"
                      required={true}
                      onChange={(e) => setTrainerNameEN(e.target.value)}
                    />
                    <FileUploader
                      handleChange={handleBrUpload}
                      name="file"
                      types={fileTypes}
                      multiple={false}
                      classes="fileuploader_br"
                      label={t("Upload Your BR copy. Drag file here or click here")}
                      maxSize="5"
                      children={<div style={{ fontSize:"12px", color: "#6C757D" ,padding:"10px"}}><div>{t("Upload Your BR copy. Drag file here or click here")}</div><div>JPG,PNG,PDF</div></div>}
                      onTypeError={(err) => { alert(t("Please upload the correct file type")); }}
                      onSizeError={(err) => { alert(t('Maximum upload file size:')+" 5 MB"); }}
                    />
                    <div style={{ fontSize:"12px", color: "#6C757D"}}>{fileMessage}</div>
                  </div>
                  <div className="mb-3 font-size-12px text-muted">
                    *{t('Maximum upload file size:')} 5 MB
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">{t('Username')}</label>

                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        required={true}
                        onChange={(e) => setUserName(e.target.value)}
                        name="username"
                        autocomplete="username"
                        title={t('Username can only use letters, numbers, underscores and periods.')}
                        pattern="^([a-zA-Z0-9_.]{1,})$"
                      />
                    </div>
                  </div>
                  <div className="mb-3 font-size-12px text-muted">
                    *{t('Username can only use letters, numbers, underscores and periods.')}
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">{t('Contact name')}</label>
                    <div className="row">
                      <div className="col-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("First name")}
                          aria-label="First name"
                          required={true}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Last name")}
                          aria-label="Last name"
                          required={true}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">{t('Email address')}</label>
                    <div className="input-group">
                      <span
                        className="input-group-text bg-white border-end-0"
                        id="email"
                      >
                        <i className="bi bi-envelope" />
                      </span>
                      <input
                        type="email"
                        className="form-control border-start-0"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">{t('Password')}</label>
                    <div className="input-group">
                      <span
                        className="input-group-text bg-white border-end-0"
                        id="password"
                      >
                        <i className="bi bi-lock" />
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control border-start-0"
                        required={true}
                        pattern="^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})$"
                        title={t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="input-group-text bg-white"
                        id="eye"
                        onClick={togglePassword}
                        style={{ cursor: "pointer" }}
                      >
                        <i className={passwordShown ? "bi bi-eye-slash" : "bi bi-eye"} />
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 font-size-12px text-muted">
                    *{t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      {t('Confirm Password')}
                    </label>
                    <div className="input-group">
                      <span
                        className="input-group-text bg-white border-end-0"
                        id="confirm_password"
                      >
                        <i className="bi bi-lock" />
                      </span>
                      <input
                        type={passwordShown2 ? "text" : "password"}
                        className="form-control border-start-0"
                        required={true}
                        pattern="^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})$"
                        title={t('Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.')}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span
                        className="input-group-text bg-white"
                        id="eye"
                        onClick={togglePassword2}
                        style={{ cursor: "pointer" }}
                      >
                        <i className={passwordShown2 ? "bi bi-eye-slash" : "bi bi-eye"} />
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={"w-100 btn bg-eb6201 text-white fw-bold"}
                  >
                    {t('Next')}
                  </button>
                </form>
              </Col>
            </Row>
          </Container>
        </div>

        <CopyRightLogin></CopyRightLogin>
      </div>
    </>
  );
}
