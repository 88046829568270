import React from "react";
import { useTranslation } from 'react-i18next';

export default function InterestedTopics(props){
    const { t } = useTranslation();

    return <>
        {
        props?.publicCoursesPage === true
        ?
        <option value="All Category" default>{t('All Category')}</option>
        :
        <option value="" default hidden>--</option>
        }
        <option value="Sustainability">{t('Sustainability')}</option>
        <option value="Innovation">{t('Innovation')}</option>
        <option value="Capacity Building">{t('Capacity Building')}</option>
        <option value="Management">{t('Management')}</option>
        <option value="Green Finance">{t('Green Finance')}</option>
        <option value="Soft Skill">{t('Soft Skill')}</option>
        <option value="ISO">{t('ISO')}</option>
        <option value="Medical">{t('Medical')}</option>
        <option value="Pharmaceutical">{t('Pharmaceutical')}</option>
        <option value="Greater Bay Area">{t('Greater Bay Area')}</option>
        <option value="Cosmetic">{t('Cosmetic')}</option>
        <option value="Construction">{t('Construction')}</option>
        <option value="Food & Beverage">{t('Food & Beverage')}</option>
        <option value="Retail">{t('Retail')}</option>
        <option value="Property Management">{t('Property Management')}</option>
        <option value="Elderly Care Service">{t('Elderly Care Service')}</option>
        <option value="Hotel">{t('Hotel')}</option>
        <option value="Law and Legislation">{t('Law and Legislation')}</option>
        <option value="I.T.">{t('I.T.')}</option>
        <option value="Education">{t('Education')}</option>
        <option value="Service">{t('Service')}</option>
        <option value="Communication">{t('Communication')}</option>
        <option value="Other">{t('Other')}</option>
    </>;
}