import React, { useRef, useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { getApiClient } from "../../util/auth";
import Paragraph from "../../components/Paragraph";
import CourseProgress from "../../components/CourseProgress";
import ProfileLayout from "../../components/ProfileLayout";
import { parseDbStringToObj, prepareDbString } from "../../util/lang";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import ProfileMenu from "../../components/ProfileMenu";
import AgeGroup from "../../components/Options/AgeGroup";
import EduLevel from "../../components/Options/EduLevel";
import Department from "../../components/Options/Department";
import InterestedTopics from "../../components/Options/InterestedTopics";
import { get_nav_headers } from "../../services/sidebar-service";
import UploadBox from "../../components/UploadBox";
import PreviewBox from "../../components/PreviewBox";
import MyCourses from "../../components/MyCourses";
import AssignedCoursesDatatable from "../../components/AssignedCoursesDatatable";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LearnerCertificates from "../../components/Cms/LearnerCertificates";
import EyeIcon from "../../components/EyeIcon";
import "../../App.css";
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { i18n, t } = useTranslation();

  let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage') ?? 'EN';

  const [user, setUser] = useState('');
  const [allCourseModal, setAllCourseModal] = useState(false);
  const [edited, setEdited] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [profile_link, set_profile_link] = useState();

  const navigate = useNavigate();
  const api = getApiClient();

  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let u = response.data.user;

        if(response.data.user.Role !== 'Individual Learner') {
          alert(t('User not authorized! You will go back to Home Page.'));
          navigate('/');
          return;
        }

        if(u.Preferred_Language !== null && u.Preferred_Language !== '') {
          localStorage.setItem('i18nLanguage', u.Preferred_Language);
          CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
          i18n.changeLanguage(CURRENT_LANGUAGE);
        }

        setCreatedAt(moment(u?.createdAt)?.format('DD/MM/YYYY'));

        // parse DB string into js Obj for multilanguage handling
        u.Contact_Person_First_Name = parseDbStringToObj(u.Contact_Person_First_Name);
        u.Contact_Person_Last_Name = parseDbStringToObj(u.Contact_Person_Last_Name);

        setUser(u);
        set_profile_link(u.Profile_Photo);
      })
      .catch((error) => {
        alert(t(error.response.data.message ?? error.message ?? error));
      });
  }, []);

  const refs = {
    profileInformationRef: useRef(),
    dataAnalysisRef: useRef(),
    myCoursesRef: useRef(),
    myCertificatesRef: useRef(),
    CURRENT_LANGUAGE: CURRENT_LANGUAGE,
  }
  const nav_headers = get_nav_headers(refs);

  const handleChange = (e, lang = 'EN') => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "Contact_Person_First_Name") {
      value = prepareDbString(user.Contact_Person_First_Name, value, lang);
    } else if (name === "Contact_Person_Last_Name") {
      value = prepareDbString(user.Contact_Person_Last_Name, value, lang);
    }

    setUser((user) => { return { ...user, [name]: value } });
    setEdited(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const param = {
      profile_photo: profile_link,

      contact_personal_first_name: user.Contact_Person_First_Name,
      contact_personal_last_name: user.Contact_Person_Last_Name,

      personal_email: user.Personal_Email,
      personal_email_2nd: user.Personal_Email_2nd,

      mobile_number: user.Mobile_Number,
      office_number: user.Office_Number,

      gender: user.Gender,
      age_group: user.Age_Group,
      education_level: user.Education_Level,

      department_1: user.Department_1,
      year_joined: user.Year_Joined,
      training_yearly_target: user.Training_Yearly_Target,
      preferred_language: user.Preferred_Language,
      interested_topics: user.Interested_Topics,
    }
    api.post("/individual_learner/edit_profile", param)
      .then(function (response) {
        setEdited(false);
        console.log(response);
        alert(t("Saved successfully."));
      })
      .catch(function (error) {
        console.log(error);
        alert(
          error.response.data.message
            ? error.response.data.message
            : "Please enter all fields"
        );
      })
      .then(() => {
        localStorage.setItem('i18nLanguage', user.Preferred_Language);
        navigate('/profile');
        window.location.reload();
      });
  };

  const mobile = user.Mobile_Number ? true : false;
  const office = user.Office_Number ? true : false;

  if (!user) {
    return <>{t('Loading')}...</>;
  }

  return (
    <ProfileLayout>
      <div style={{ backgroundColor: "#F5F5F8" }}>
        <Container fluid className="p-0">
          <Row className={"no-gutters gx-0 edit-profile-row"}>
            <ProfileMenu navHeader={nav_headers}></ProfileMenu>
            <Col className={"profile-container p-5"}>
              <div className={"position-relative"} ref={nav_headers.get('profileInformation').ref} id={nav_headers.get('profileInformation').id}>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="ms-md--0 ms-5 max-width-1304 bg-white p-5 mb-5 mt-5">
                    <div className="row mb-3">
                      <div className="col fw-bold font-size-20px text-decoration-underline">
                        {t('Personal Information')}
                      </div>
                    </div>
                    {/* <button disabled={!edited} className={"btn bg-eb6201 text-white"} type="submit">
                      {t('Save')}
                    </button>
                    &nbsp;
                    <button
                      className={"btn bg-6c757d text-white"}
                      type={"button"}
                      onClick={() => {
                        navigate('/profile');
                      }}
                    >
                      {t('Cancel')}
                    </button> */}

                    <div className="my-3">
                      <img className="img-fluid" src={profile_link ?? 'https://via.placeholder.com/150x150'} alt="profile"
                        width="300px"
                        height="300px" />
                    </div>
                    <div className="mb-3 row">
                      <div className="col-sm-6">
                        <UploadBox
                          onUploaded={(api_responses) => {
                            setEdited(true);
                            set_profile_link(api_responses?.[0]?.urls?.[0]);
                          }}
                          width_ratio={1}
                          height_ratio={1}
                          tolerance={0.1}
                          allowImageEdit
                        />
                      </div>
                    </div>
                    {
                      profile_link
                      &&
                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <PreviewBox url={profile_link} onDelete={() => { set_profile_link(null); }} />
                        </div>
                      </div>
                    }

                    <div>
                      <div className="row font-size-16px edit-staff-info-container">
                        <div className="col">
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Username')}:
                            </label>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Username")}
                                defaultValue={user.Username}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className={"text-5c5c5c col-4 col-form-label"}>{t('Contact Name')}:<span className={"text-danger"}>*</span></div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={"Last Name"}
                                name="Contact_Person_Last_Name"
                                value={user.Contact_Person_Last_Name.EN}
                                onChange={(e) => handleChange(e, 'EN')}
                                required={true}
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={"First Name"}
                                name="Contact_Person_First_Name"
                                value={user.Contact_Person_First_Name.EN}
                                onChange={(e) => handleChange(e, 'EN')}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className={"text-5c5c5c col-4 col-form-label"}>{t('Contact Name')}(繁):</div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Last Name")}
                                name="Contact_Person_Last_Name"
                                value={user.Contact_Person_Last_Name.TC}
                                onChange={(e) => handleChange(e, 'TC')}
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("First Name")}
                                name="Contact_Person_First_Name"
                                value={user.Contact_Person_First_Name.TC}
                                onChange={(e) => handleChange(e, 'TC')}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className={"text-5c5c5c col-4 col-form-label"}>{t('Contact Name')}(简):</div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Last Name")}
                                name="Contact_Person_Last_Name"
                                value={user.Contact_Person_Last_Name.SC}
                                onChange={(e) => handleChange(e, 'SC')}
                              />
                            </div>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("First Name")}
                                name="Contact_Person_First_Name"
                                value={user.Contact_Person_First_Name.SC}
                                onChange={(e) => handleChange(e, 'SC')}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Email')} (1):<span className={"text-danger"}>*</span>
                            </label>
                            <div className="col">
                              <input
                                type="email"
                                className="form-control"
                                placeholder={`${t("Email")} (1)`}
                                name="Personal_Email"
                                value={user.Personal_Email}
                                onChange={(e) => handleChange(e)}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Email')} (2):
                            </label>
                            <div className="col">
                              <input
                                type="email"
                                className="form-control"
                                placeholder={`${t("Email")} (2)`}
                                name="Personal_Email_2nd"
                                value={user.Personal_Email_2nd}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Office Number')}:
                            </label>
                            <div className="col">
                              <PhoneInput
                                name="Office_Number"
                                value={user.Office_Number}
                                placeholder=""
                                onChange={(value) => {
                                  setEdited(true);
                                  setUser((user) => {
                                    return { ...user, Office_Number: value };
                                  });
                                }}
                                country={office ? undefined : "hk"}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Mobile Number')}:
                            </label>
                            <div className="col">
                              <PhoneInput
                                name="Mobile_Number"
                                value={user.Mobile_Number}
                                placeholder=""
                                onChange={(value) => {
                                  setEdited(true);
                                  setUser((user) => {
                                    return { ...user, Mobile_Number: value };
                                  });
                                }}
                                country={mobile ? undefined : "hk"}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Gender')}:
                            </label>
                            <div className="col">
                              <select
                                className={"form-select"}
                                name="Gender"
                                value={user.Gender}
                                onChange={(e) => handleChange(e)}
                              >
                                <option default hidden>--</option>
                                <option value="Male">{t('Male')}</option>
                                <option value="Female">{t('Female')}</option>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Age group')}:
                            </label>
                            <div className="col">
                              <select
                                className={"form-select"}
                                name="Age_Group"
                                value={user.Age_Group}
                                onChange={(e) => handleChange(e)}
                              >
                                <AgeGroup></AgeGroup>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Education Level')}:
                            </label>
                            <div className="col">
                              <select
                                type="text"
                                className="form-select"
                                placeholder={t("Education Level")}
                                name="Education_Level"
                                value={user.Education_Level}
                                onChange={(e) => handleChange(e)}
                              >
                                <EduLevel></EduLevel>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Join elearning date')}:
                            </label>
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Join elearning date")}
                                readOnly={true}
                                value={createdAt}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Department')}:
                            </label>
                            <div className="col">
                              <select
                                type="text"
                                className="form-select"
                                placeholder={t("Department")}
                                name="Department_1"
                                value={user.Department_1}
                                onChange={(e) => handleChange(e)}
                              >
                                <Department />
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Year Joined')}:
                            </label>
                            <div className="col">
                              <input
                                type="number"
                                className="form-control"
                                placeholder={t("Year Joined")}
                                name="Year_Joined"
                                value={user.Year_Joined}
                                min={1900}
                                max={new Date().getFullYear()}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="text-5c5c5c col-4 col-form-label">
                              {t('Training Yearly Target')}:<span className={"text-danger"}>*</span>
                            </div>
                            <div className="col">
                              <input
                                type="number"
                                className="form-control"
                                placeholder={t("Training Yearly Target")}
                                name="Training_Yearly_Target"
                                value={user.Training_Yearly_Target}
                                onChange={(e) => handleChange(e)}
                                min={1}
                                max={999}
                                required
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Preferred Language')}:
                            </label>
                            <div className="col">
                              <select
                                className="form-select"
                                name="Preferred_Language"
                                value={user.Preferred_Language}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="EN">English</option>
                                <option value="TC">繁體中文</option>
                                <option value="SC">简体中文</option>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="text-5c5c5c col-4 col-form-label">
                              {t('Interested Topics')}:
                            </label>
                            <div className="col">
                              <select
                                type="text"
                                className="form-select"
                                placeholder={t("Interested Topics")}
                                name="Interested_Topics"
                                value={user.Interested_Topics}
                                onChange={(e) => handleChange(e)}
                              >
                                <InterestedTopics />
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button disabled={!edited} className={"btn bg-eb6201 text-white"} type="submit">
                        {t('Save')}
                      </button>
                      &nbsp;
                      <button
                        className={"btn bg-6c757d text-white"}
                        type={"button"}
                        onClick={() => {
                          navigate('/profile');
                        }}
                      >
                        {t('Cancel')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <div className="row align-items-center mb-3">
                  <div className="col fw-bold font-size-20px text-decoration-underline">
                    {t('Data Analysis')}
                  </div>
                </div>

                <CourseProgress user={user} />
              </div>

              <div ref={nav_headers.get('myCourses').ref} id={nav_headers.get('myCourses').id}>
                <MyCourses view_courses_endpoint={'/individual_learner/view_courses'} CURRENT_LANGUAGE={CURRENT_LANGUAGE}></MyCourses>
              </div>

              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <Row>
                  <Col>
                    <AssignedCoursesDatatable />
                  </Col>
                </Row>
              </div>

              {false && <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <div className="row align-items-end mb-3">
                  <div className="col">
                    <div className={"mb-3 font-size-18px text-4a4a4a"}>
                      {t('All courses')}
                    </div>
                  </div>
                </div>
                <div className="row mb-3 table-responsive">
                  <table
                      className="table text-center"
                      style={{verticalAlign: "middle"}}
                  >
                    <thead>
                    <tr className={"border"}>
                      <td scope="col">
                        Preview Image <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Course Code <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Course Title <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Date Taken <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Completion Date/ Date Completed <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Module <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Quiz <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Certificate <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Expired <i className="bi bi-arrow-down-up"/>
                      </td>
                      <td scope="col">
                        Actions <i className="bi bi-arrow-down-up"/>
                      </td>
                    </tr>
                    </thead>
                    <tbody className={"border-0"}>
                    <tr className={"border"}>
                      <td>
                        <img src={"https://via.placeholder.com/111x71"}/>
                      </td>
                      <td>C123456</td>
                      <td>Lorem Ipsum</td>
                      <td>12/10/2021</td>
                      <td>12/12/2021</td>
                      <td>10/10</td>
                      <td>Completed</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>
                        <button
                            className={"btn p-0"}
                            onClick={() => {
                              setAllCourseModal(true)
                            }}
                        >
                          <EyeIcon/>
                        </button>
                      </td>
                    </tr>
                    <tr className={"border"}>
                      <td colSpan={9}>
                        <button className={"btn w-100 text-eb6201 rounded-0"}>
                          {t('View All Certificates')}
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              }

              {/* Since there is no cert yet, we borrow LearnerCertificates component as a placeholder */}
              <div ref={nav_headers.get('myCertificates').ref} id={nav_headers.get('myCertificates').id}>
                <LearnerCertificates user={user} />
              </div>
              
            </Col>
          </Row>
        </Container>
      </div>
    </ProfileLayout>
  );
}
