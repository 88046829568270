import React from "react";
import { Col, Row } from "react-bootstrap";
import { getTextByCode, parseDbStringToObj } from "../../util/lang";
import ShadowContainer from "../ShadowContainer";

export default function CourseNameCard(props){
    const course = props.course;

    const courseTitle = parseDbStringToObj(course.Course_Name);

    return <>
        <ShadowContainer className="text-5c5c5c">
          <Row className="my-2">
            <Col sm="2">Course Code</Col>
            <Col sm="10">{course.Course_Code}</Col>
          </Row>
          <Row className="my-2">
            <Col sm="2">Course Name (ENG)</Col>
            <Col sm="10">{courseTitle.EN}</Col>
          </Row>
          <Row className="my-2">
            <Col sm="2">Course Name (繁)</Col>
            <Col sm="10">{courseTitle.TC}</Col>
          </Row>
          <Row className="my-2">
            <Col sm="2">Course Name (简)</Col>
            <Col sm="10">{courseTitle.SC}</Col>
          </Row>
        </ShadowContainer>
    </>

}