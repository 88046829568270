import LayoutCms from "../../../components/LayoutCms";
import React from "react";
import { useLocation } from "react-router-dom";
import StaffCard from "../../../components/StaffCard";

export default function ViewStaff() {
  const location = useLocation();

  let user = null;
  // TODO double check if this condition is solid
  // user may be set using navigate(), see routes/cms/staffs/index.js:goEditStaff()
  if (location.state != null && "user" in location.state) {
    user = location.state.user;
  }

  return (
    <LayoutCms route={'staffs.show'}>
      <StaffCard user={user} />
    </LayoutCms>
  );
}
