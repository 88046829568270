import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function AssociationNavigation(props) {
  const associationCategoryDictionary = {
    Sustainability: "sustainability",
    Innovation: "innovation",
    "Capacity Building": "capacity_building",
    "Greater Bay Area": "greater_bay_area",
    "Green Finance": "green_finance",
    "Soft Skill": "soft_skill",
    ISO: "iso",
    Medical: "medical",
    Pharmaceutical: "pharmaceutical",
  };

  const changeAssociationCategory = (event, nextAssociationCategory) => {
    event.preventDefault();

    const parent = event.target.parentNode;
    const element = event.target;

    const paddingRight = 40;
    const left =
      element.getBoundingClientRect().left -
      parent.getBoundingClientRect().left;
    const right =
      element.getBoundingClientRect().right -
      parent.getBoundingClientRect().left -
      paddingRight;
    const dividerWidth = 45;
    const position = (left + right) / 2 - dividerWidth / 2;
    setCssLeft(position);
    setAssociationCategory(
      associationCategoryDictionary[nextAssociationCategory]
    );
  };

  const getAssociationCategoryStyle = (currentAssociationCategory) => {
    const value = associationCategoryDictionary[currentAssociationCategory];

    return {
      fontSize: "18px",
      fontHeight: "21.6px",
      textDecoration: "none",
      paddingRight: 40,
      color: value === associationCategory ? "#266599" : "#4a4a4a",
    };
  };

  const [associationCategory, setAssociationCategory] = useState(
    associationCategoryDictionary["Sustainability"]
  );
  const [cssLeft, setCssLeft] = useState(30.8);

  const getLeft = () => {
    return cssLeft;
  };

  return (
    <div className={"d-none overflow-y-scroll overflow-md-y-visible"}>
      <Row className={`${props.className}`}>
        <Col>
          <div className="d-flex">
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Sustainability")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Sustainability")}
            >
              Sustainability
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Innovation")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Innovation")}
            >
              Innovation
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Capacity Building")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Capacity Building")}
            >
              Capacity Building
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Greater Bay Area")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Greater Bay Area")}
            >
              Greater Bay Area
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Green Finance")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Green Finance")}
            >
              Green Finance
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Soft Skill")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Soft Skill")}
            >
              Soft Skill
            </a>
            <a
              onClick={(event) => changeAssociationCategory(event, "ISO")}
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("ISO")}
            >
              ISO
            </a>
            <a
              onClick={(event) => changeAssociationCategory(event, "Medical")}
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Medical")}
            >
              Medical
            </a>
            <a
              onClick={(event) =>
                changeAssociationCategory(event, "Pharmaceutical")
              }
              href="#"
              className="d-block"
              style={getAssociationCategoryStyle("Pharmaceutical")}
            >
              Pharmaceutical
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="div-block-courses">
            <div
              className="div-block-courses-top"
              style={{ left: getLeft() }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
