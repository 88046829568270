import { getApiClient } from '../util/auth';
import keysToCamel from '../util/data-utils';

export function getCourses() {
  const user = JSON.parse(localStorage.getItem('user'));
  return getApiClient()
    .post(user ? '/organization_learner/view_courses' : '/public/view_courses')
    .then((response) => {
      return keysToCamel(response.data.courses);
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getCourseDetails(courseId) {
  const user = JSON.parse(localStorage.getItem('user'));
  return getApiClient()
    .post(user ? '/organization_learner/view_course' : '/public/view_course', { course_id: courseId })
    .then((response) => {
      return keysToCamel(response.data);
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getCourses_home() {
  return [
    {
      id: 471,
      title: "Training Workshop on Customer Exellence",
      description: "In this course, you will be able to mainly understand the concepts of customer behavior and complaint handling. The first part focuses on the essence of customer service, giving you an idea of the communication skills, attitudes, and tone you need to have a conference call or face-to-face meeting with your customers. The content of the second part is based on efficient complaint management skills, so that you can understand some of the reasons that often make customers dissatisfied, so as to learn the correct attitude when handling complaints.",
      image: "/assets/images/Training Workshop on Customer Excellence.png"
    },
    {
      id: 472,
      title: "Business Writing Course - Talking about TIME",
      description: "This training helps you to improve the business writing skills and your written communications. From learning the correct time expression and understand the timelines. You will be able to express yourself in a clear, concise, courteous and correct manner. You will learn how to avoid the common mistakes.",
      image: "/assets/images/Business Writing Course - Talking about TIME.png"
    },
    {
      id: 473,
      title: "Occupational Health and Safety Management",
      description: "This course provides guidance on the concepts and practices of occupational health and safety (OH&S) management that are important to and have an impact on the successful delivery of a business.",
      image: "/assets/images/Occupational Health and Safety Management.png"
    },
    {
      id: 4,
      title: "SDG17 – Foundation Training (Economic)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in economic related.",
      image: "/assets/images/SDG17 – Foundation Training (Economic).png"
    },
    {
      id: 5,
      title: "SDG17 – Foundation Training (Social)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      image: "/assets/images/SDG17 – Foundation Training (Social).png"
    },
    {
      id: 6,
      title: "SDG17 – Foundation Training (Environmental)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      image: "/assets/images/SDG17 – Foundation Training (Environmental).png"
    },
    // {
    //   id: 7,
    //   title: "ESG Report 101 – Awareness",
    //   description: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
    //   image: "/assets/images/ESG Report 101 – Awareness.png"
    // },
    // {
    //   id: 8,
    //   title: "ESG Report 102 – Implementation",
    //   description: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
    //   image: "/assets/images/ESG Report 102 – Implementation.png"
    // },
    // {
    //   id: 9,
    //   title: "ESG Report 103 – Sustain Success",
    //   description: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
    //   image: "/assets/images/ESG Report 103 – Sustain Success.png"
    // }
  ];
}

export function getCourses_courses(search = "") {
  var j = [
    {
      id: 471,
      title: "Training Workshop on Customer Exellence",
      description: "In this course, you will be able to mainly understand the concepts of customer behavior and complaint handling. The first part focuses on the essence of customer service, giving you an idea of the communication skills, attitudes, and tone you need to have a conference call or face-to-face meeting with your customers. The content of the second part is based on efficient complaint management skills, so that you can understand some of the reasons that often make customers dissatisfied, so as to learn the correct attitude when handling complaints.",
      image: "/assets/images/Training Workshop on Customer Excellence.png",
      trainer_id: 4,
      trainer: "Wilson Chu",
    },
    {
      id: 472,
      title: "Business Writing Course - Talking about TIME",
      description: "This training helps you to improve the business writing skills and your written communications. From learning the correct time expression and understand the timelines. You will be able to express yourself in a clear, concise, courteous and correct manner. You will learn how to avoid the common mistakes.",
      image: "/assets/images/Business Writing Course - Talking about TIME.png",
      trainer_id: 0,
      trainer: "Joe Leung",
    },
    {
      id: 473,
      title: "Occupational Health and Safety Management",
      description: "This course provides guidance on the concepts and practices of occupational health and safety (OH&S) management that are important to and have an impact on the successful delivery of a business.",
      image: "/assets/images/Occupational Health and Safety Management.png",
      trainer_id: 0,
      trainer: "Andy Fung",
    },
    {
      id: 4,
      title: "SDG17 – Foundation Training (Economic)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in economic related.",
      image: "/assets/images/SDG17 – Foundation Training (Economic).png",
      trainer_id: 0,
      trainer: "HKQAA",
    },
    {
      id: 5,
      title: "SDG17 – Foundation Training (Social)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      image: "/assets/images/SDG17 – Foundation Training (Social).png",
      trainer_id: 0,
      trainer: "HKQAA",
    },
    {
      id: 6,
      title: "SDG17 – Foundation Training (Environmental)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      image: "/assets/images/SDG17 – Foundation Training (Environmental).png",
      trainer_id: 0,
      trainer: "HKQAA",
    },
    {
      id: 7,
      title: "ESG Report 101 – Awareness",
      description: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
      image: "/assets/images/ESG Report 101 – Awareness.png",
      trainer_id: 0,
      trainer: "HKQAA",
    },
    {
      id: 8,
      title: "ESG Report 102 – Implementation",
      description: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
      image: "/assets/images/ESG Report 102 – Implementation.png",
      trainer_id: 0,
      trainer: "HKQAA",
    },
    {
      id: 9,
      title: "ESG Report 103 – Sustain Success",
      description: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
      image: "/assets/images/ESG Report 103 – Sustain Success.png",
      trainer_id: 0,
      trainer: "HKQAA",
    }
  ];

  var jj = j;

  if (search === "") {
    jj = j;
  }
  else {
    jj = j.filter(function (e) {
      let t = e.title.toLowerCase().includes(search.toLowerCase());
      let d = e.description.toLowerCase().includes(search.toLowerCase());
      let r = e.trainer.toLowerCase().includes(search.toLowerCase());
      return t || d || r;
    });
  }
  return jj;
}

export function getCourseDetail(id) {
  var js = {
    "1": {
      id: 471,
      title: "Training Workshop on Customer Exellence",
      description: "In this course, you will be able to mainly understand the concepts of customer behavior and complaint handling. The first part focuses on the essence of customer service, giving you an idea of the communication skills, attitudes, and tone you need to have a conference call or face-to-face meeting with your customers. The content of the second part is based on efficient complaint management skills, so that you can understand some of the reasons that often make customers dissatisfied, so as to learn the correct attitude when handling complaints.",
      image: "/assets/images/Training Workshop on Customer Excellence.png"
    },
    "2": {
      id: 472,
      title: "Business Writing Course - Talking about TIME",
      description: "This training helps you to improve the business writing skills and your written communications. From learning the correct time expression and understand the timelines. You will be able to express yourself in a clear, concise, courteous and correct manner. You will learn how to avoid the common mistakes.",
      image: "/assets/images/Business Writing Course - Talking about TIME.png"
    },
    "3": {
      id: 473,
      title: "Occupational Health and Safety Management",
      description: "This course provides guidance on the concepts and practices of occupational health and safety (OH&S) management that are important to and have an impact on the successful delivery of a business.",
      image: "/assets/images/Occupational Health and Safety Management.png"
    },
    "4": {
      id: 4,
      title: "SDG17 – Foundation Training (Economic)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in economic related.",
      image: "/assets/images/SDG17 – Foundation Training (Economic).png"
    },
    "5": {
      id: 5,
      title: "SDG17 – Foundation Training (Social)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      image: "/assets/images/SDG17 – Foundation Training (Social).png"
    },
    "6": {
      id: 6,
      title: "SDG17 – Foundation Training (Environmental)",
      description: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      image: "/assets/images/SDG17 – Foundation Training (Environmental).png"
    },
    "7": {
      id: 7,
      title: "ESG Report 101 – Awareness",
      description: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
      image: "/assets/images/ESG Report 101 – Awareness.png"
    },
    "8": {
      id: 8,
      title: "ESG Report 102 – Implementation",
      description: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
      image: "/assets/images/ESG Report 102 – Implementation.png"
    },
    "9": {
      id: 9,
      title: "ESG Report 103 – Sustain Success",
      description: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
      image: "/assets/images/ESG Report 103 – Sustain Success.png"
    }
  };

  return js[id];
}

// hardcode data for

export function coursesDataHardcode(id) {
  var js = [
    {
      id: 471,
      Course_Name_EN: "Training Workshop on Customer Excellence",
      Course_Name_TC: "優質客戶服務技巧培訓工作坊",
      Course_Name_SC: "优质客户服务技巧培训工作坊",
      Course_Master_Language: "Cantonese",
      Course_Description_EN: "In this course, you will be able to mainly understand the concepts of customer behavior and complaint handling. The first part focuses on the essence of customer service, giving you an idea of the communication skills, attitudes, and tone you need to have a conference call or face-to-face meeting with your customers. The content of the second part is based on efficient complaint management skills, so that you can understand some of the reasons that often make customers dissatisfied, so as to learn the correct attitude when handling complaints.",
      Course_Description_TC: "透過觀看本課程，你能夠主要認識到客戶行為和投訴處理的概念。 第一部分的內容以客戶服務的精髓為主，讓你認識與客戶進行電話會議或者面對面會議時需要的溝通技巧、態度和語氣。第二部分的內容以高效投訴管理技巧為主， 讓你理解一些經常令客戶不滿的原因，從而學會處理投訴時的正確態度。",
      Course_Description_SC: "透过观看本课程，你能够主要认识到客户行为和投诉处理的概念。 第一部分的内容以客户服务的精髓为主，让你认识与客户进行电话会议或者面对面会议时需要的沟通技巧、态度和语气。 第二部分的内容以高效投诉管理技巧为主， 让你理解一些经常令客户不满的原因，从而学会处理投诉时的正确态度。",
      Course_Category: "Soft skill, Management, Service",
      Industry: "Service, Hotel, Tourism",
      Course_Fee: "Free",
      Material_Language: "Traditional Chinese",
      Video_Language_Audio: "Cantonese",
      Video_Language_Subtitle: "NA",
      Preview_Video: "/assets/images/course-1-preview.png",
      Course_Photo: "/assets/images/Training Workshop on Customer Excellence.png",
      Trainer_Id: 4,
      Trainer: "Wilson Chu",
      Trainer_Organization: "HMI Consulting Limited",
      Off_the_Shelf_Date: "10/5/2023",
      Enrollment_Validity_until: "7 days before the expiry date",
      Does_this_course_offer_certificate: "No",
      Certificate_Orientation: "NA",
      Quiz: "No",
      modules: [
        {
          id: 101,
          Module_Index: 1,
          Module_Title_EN: "Customer service communication skills",
          Module_Title_TC: "客戶服務溝通技巧",
          Module_Title_SC: "客户服务沟通技巧",
          Module_Description_EN: "- The importance of ality service \n- Communication skills (listening skills, phrasing skills, service empathy, emotional processing skills and how to say no effectively)",
          Module_Description_TC: "- 優質服務的重要性\n- 溝通技巧（聆聽技巧、措辭技巧、服務同理心、處理情緒技巧和如何高效地說不）",
          Module_Description_SC: "-  优质服务的重要性\n- 沟通技巧（聆听技巧、措辞技巧、服务同理心、处理情绪技巧和如何高效地说不）",
          classes: [
            {
              id: 10101,
              Class_Index: 1,
              Class_Title_EN: "What is the responsibility and rights of quality customer service and customer service staff",
              Class_Title_TC: "何謂優質客戶服務和客戶服務員之責任及權益",
              Class_Title_SC: "何谓优质客户服务和客户服务员之责任及权益",
              Class_Description_EN: "Describe the responsibility and rights of quality customer service and customer service staff",
              Class_Description_TC: "描述何謂優質客戶服務和客戶服務員之責任及權益",
              Class_Description_SC: "描述何谓优质客户服务和客户服务员之责任及权益",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/ad3745c2-7e75-4f7f-9b51-ee617e52e016-Wilson+Chu-M1-class1+2022-05-05+01_47_41.mp4",
            },
            {
              id: 10102,
              Class_Index: 2,
              Class_Title_EN: "Telephone etiquette, voice and body language use",
              Class_Title_TC: "電話禮儀、聲線及身體語言運用",
              Class_Title_SC: "礼仪、声线及身体语言运用",
              Class_Description_EN: "Describe appropriate telephone etiquette, voice and body language use",
              Class_Description_TC: "描述適當的電話禮儀、語音和肢體語言用法",
              Class_Description_SC: "描述适当的电话礼仪、语音和肢体语言用法",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/c9408c3c-0bc6-4ebb-8d9d-833df2bbc71e-Wilson+Chu-M1-class2+2022-05-05+01_48_39.mp4",
            }
          ]
        },
        {
          id: 102,
          Module_Index: 2,
          Module_Title_EN: "Effective complaint management skills ",
          Module_Title_TC: "高效投訴管理技巧",
          Module_Title_SC: "高效投诉管理技巧",
          Module_Description_EN: "- Why the customer doesn't come back\n- Benefits of efficient handling of complaints\n- Steps and techniques for handling complaints (complaint handling methods and methods of handling different types of customers)",
          Module_Description_TC: "- 為什麼客戶不再回來\n- 高效處理投訴的好處\n- 處理投訴的步驟及技巧（投訴處理法和不同類型客戶的處理方法）",
          Module_Description_SC: "- 为什么客户不再回来\n- 高效处理投诉的好处\n- 处理投诉的步骤及技巧（投诉处理法和不同类型客户的处理方法）",
          classes: [
            {
              id: 10201,
              Class_Index: 1,
              Class_Title_EN: "Procedures for handling customer complaints",
              Class_Title_TC: "處理客戶投訴程序",
              Class_Title_SC: "处理客户投诉程序",
              Class_Description_EN: "Describe the procedure of how to handle customer complaints",
              Class_Description_TC: "描述如何處理客戶投訴的過程",
              Class_Description_SC: "描述如何处理客户投诉的过程",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/b649daa6-0b0c-4563-9217-775f780aeed7-Wilson+Chu-M2-class+1+2022-05-05+02_04_29.mp4",
            },
            {
              id: 10202,
              Class_Index: 2,
              Class_Title_EN: "The characteristics of the customers who complaint and how to deal with their emotions",
              Class_Title_TC: "投訴客戶的特徵和如何處理他們的情緒 ",
              Class_Title_SC: "投诉客户的特征和如何处理他们的情绪",
              Class_Description_EN: "Describe characteristics of the customers who complaint and how to deal with their emotions",
              Class_Description_TC: "描述投訴客戶的特徵和如何處理他們的情緒 ",
              Class_Description_SC: "描述投诉客户的特征和如何处理他们的情绪",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/a5d1289b-2461-4e26-8152-cd92b1202d27-Wilson+Chu-M2-class+2+2022-05-05+02_05_27.mp4",
            }
          ]
        }
      ]
    },
    {
      id: 472,
      Course_Name_EN: "Business Writing Course - Talking about TIME",
      Course_Name_TC: "商務寫作課程 - 談論時間",
      Course_Name_SC: "商务写作课程 - 谈论时间",
      Course_Master_Language: "Cantonese",
      Course_Description_EN: "This training helps you to improve the business writing skills and your written communications.   From learning the correct time expression and understand the timelines.   You will be able to express yourself in a clear, concise, courteous and correct manner.  You will learn how to avoid the common mistakes.",
      Course_Description_TC: "本培訓可幫助您提高商務寫作技巧和書面溝通。  通過學習正確的時間表達式並理解時間線。  您將能夠以清晰，簡潔，禮貌和正確的方式表達自己。 您將學習如何避免常見的錯誤。",
      Course_Description_SC: "本培训可帮助您提高商务写作技巧和书面沟通。  通过学习正确的时间表达式并理解时间线。  您将能够以清晰，简洁，礼貌和正确的方式表达自己。 您将学习如何避免常见的错误。",
      Course_Category: "Capacity Building, Communication",
      Industry: "Others",
      Course_Fee: "Free",
      Material_Language: "English",
      Video_Language_Audio: "Cantonese",
      Video_Language_Subtitle: "NA",
      Preview_Video: "/assets/images/course-2-preview.png",
      Course_Photo: "/assets/images/Business Writing Course - Talking about TIME.png",
      Trainer_Id: 0,
      Trainer: "Joe Leung",
      Trainer_Organization: "English Pro Learning Centre ",
      Off_the_Shelf_Date: "10/5/2023",
      Enrollment_Validity_until: "7 days before the expiry date",
      Does_this_course_offer_certificate: "No",
      Certificate_Orientation: "NA",
      Quiz: "No",
      modules: [
        {
          id: 201,
          Module_Index: 1,
          Module_Title_EN: "Expressions with NO Prepositions",
          Module_Title_TC: "無介詞的表達式",
          Module_Title_SC: "无介词的表达式",
          Module_Description_EN: "Expressions with NO Prepositions",
          Module_Description_TC: "無介詞的表達式",
          Module_Description_SC: "无介词的表达式",
          classes: [
            {
              id: 20101,
              Class_Index: 1,
              Class_Title_EN: "The Use of BY",
              Class_Title_TC: "BY的使用",
              Class_Title_SC: "BY的使用",
              Class_Description_EN: "The Use of BY",
              Class_Description_TC: "BY的使用",
              Class_Description_SC: "BY的使用",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/7f8e64df-fdc2-49f9-89ad-c7d8e9440158-M1-class1.mp4",
            },
            {
              id: 20102,
              Class_Index: 2,
              Class_Title_EN: "IN + Period of Time",
              Class_Title_TC: "IN + 時間段",
              Class_Title_SC: "IN + 时间段",
              Class_Description_EN: "IN + Period of Time",
              Class_Description_TC: "IN + 時間段",
              Class_Description_SC: "IN + 时间段",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/74561c3b-34eb-4b15-84bb-41292ae632ff-M1-class2.mp4",
            }
          ]
        },
        {
          id: 202,
          Module_Index: 2,
          Module_Title_EN: "Timelines",
          Module_Title_TC: "時限",
          Module_Title_SC: "时限",
          Module_Description_EN: "Describe a period of time",
          Module_Description_TC: "描述一段時間",
          Module_Description_SC: "描述一段时间",
          classes: [
            {
              id: 20201,
              Class_Index: 1,
              Class_Title_EN: "From … to vs. Between … and",
              Class_Title_TC: "從。。。到 vs. 之間 ...和 ",
              Class_Title_SC: "从。。。到 vs. 之间 ...和 ",
              Class_Description_EN: "From … to vs. Between … and",
              Class_Description_TC: "從。。。到 vs. 之間 ...和 ",
              Class_Description_SC: "从。。。到 vs. 之间 ...和 ",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/71d48aee-4d28-40ad-b064-2d6f90bb8a02-M2-class+1.mp4",
            },
            {
              id: 20202,
              Class_Index: 2,
              Class_Title_EN: "Weekdays and Weekends",
              Class_Title_TC: "平日和週末",
              Class_Title_SC: "平日和周末",
              Class_Description_EN: "Describe Weekdays and Weekends",
              Class_Description_TC: "描述平日和週末",
              Class_Description_SC: "描述平日和周末",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/dc4accf1-f2cd-40d1-8524-ee4f78e93acc-M2-class+2.mp4",
            }
          ]
        }
      ]
    },
    {
      id: 473,
      Course_Name_EN: "Occupational Health and Safety Management",
      Course_Name_TC: "職業健康及安全管理",
      Course_Name_SC: "職業健康及安全管理",
      Course_Master_Language: "Cantonese",
      Course_Description_EN: "This course provides guidance on the concepts and practices of occupational health and safety (OH&S) management that are important to and have an impact on the successful delivery of a business.",
      Course_Description_TC: "本課程為職業健康與安全 (OH&S) 管理的概念和實踐提供指導，這些概念和實踐對企業的成功交付很重要並對其產生影響。",
      Course_Description_SC: "本课程为职业健康与安全 （OH&S） 管理的概念和实践提供指导，这些概念和实践对企业的成功交付很重要并对其产生影响。",
      Course_Category: "Management, Other",
      Industry: "Health and Social Work, Service, Others",
      Course_Fee: "Free",
      Material_Language: "Traditional Chinese",
      Video_Language_Audio: "Cantonese",
      Video_Language_Subtitle: "NA",
      Preview_Video: "/assets/images/course-3-preview.png",
      Course_Photo: "/assets/images/Occupational Health and Safety Management.png",
      Trainer_Id: 0,
      Trainer: "Andy Fung",
      Trainer_Organization: "Hong Kong Quality Assurance Agency",
      Off_the_Shelf_Date: "10/5/2023",
      Enrollment_Validity_until: "7 days before the expiry date",
      Does_this_course_offer_certificate: "No",
      Certificate_Orientation: "NA",
      Quiz: "No",
      modules: [
        {
          id: 301,
          Module_Index: 1,
          Module_Title_EN: "Workplace module",
          Module_Title_TC: "辦公室篇",
          Module_Title_SC: "办公室篇",
          Module_Description_EN: "- Occupational safety and health legislation and the focus on the seven self-awareness of occupational safety and health\n- Surrounding working environment and anti-epidemic awareness in the workplace",
          Module_Description_TC: "- 職業安全及健康法例及重點認識7個自身的職安健\n- 周邊的工作環境和工作間抗疫意識",
          Module_Description_SC: "- 职业安全及健康法例及重点认识7个自身的职安健周边的工作环境和工作间抗疫意识",
          classes: [
            {
              id: 30101,
              Class_Index: 1,
              Class_Title_EN: "Occupational safety and health legislation and the focus on the seven self-awareness of occupational safety and health",
              Class_Title_TC: "職業安全及健康法例及重點認識7個自身的職安健",
              Class_Title_SC: "职业安全及健康法例及重点认识7个自身的职安健",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/57804f7d-d33e-4d56-a12f-018a52627d8c-M1-class+1.mp4",
            },
            {
              id: 30102,
              Class_Index: 2,
              Class_Title_EN: "Surrounding working environment and anti-epidemic awareness in the workplace",
              Class_Title_TC: "周邊的工作環境和工作間抗疫意識",
              Class_Title_SC: "周边的工作环境和工作间抗疫意识",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/69283c49-9568-4046-b13e-cfe7ae5019a7-M1-class+2.mp4",
            }
          ]
        },
        {
          id: 302,
          Module_Index: 2,
          Module_Title_EN: "Customer Service Counter",
          Module_Title_TC: "顧客服務櫃檯篇",
          Module_Title_SC: "顾客服务柜台篇",
          Module_Description_EN: "- Risk assessment and preventive measures and service counter design advice\n- Eight awareness of occupational health and safety management",
          Module_Description_TC: "- 風險評估和預防措施及服務櫃檯設計建議  \n- 八項關於職業健康及安全管理的意識",
          Module_Description_SC: "- 风险评估和预防措施及服务柜台设计建议\n- 八项关于职业健康及安全管理的意识",
          classes: [
            {
              id: 30201,
              Class_Index: 1,
              Class_Title_EN: "Risk assessment and preventive measures and service counter design advice",
              Class_Title_TC: "風險評估和預防措施及服務櫃檯設計建議",
              Class_Title_SC: "风险评估和预防措施及服务柜台设计建议",
              Class_Description_EN: "Describe how to perform risk assessment and preventive measures and service counter design advice",
              Class_Description_TC: "描述風險評估和預防措施及服務櫃檯設計建議",
              Class_Description_SC: "描述风险评估和预防措施及服务柜台设计建议",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/5b1e4da3-fa0b-4ea8-831a-e8f5a344ecab-M2-+class+1.mp4",
            },
            {
              id: 30202,
              Class_Index: 2,
              Class_Title_EN: "Eight awareness of occupational health and safety management",
              Class_Title_TC: "八項關於職業健康及安全管理的意識",
              Class_Title_SC: "八项关于职业健康及安全管理的意识",
              Class_Decription_EN: "Describe what the eight awareness of occupational health and safety management",
              Class_Description_TC: "描述八項關於職業健康及安全管理的意識",
              Class_Description_SC: "描述八项关于职业健康及安全管理的意识",
              Video_Url: "https://lightshare-images-uat.s3.ap-east-1.amazonaws.com/upload/video/course/46590cab-36a2-4f74-a9b7-d0e5385d36d3-M2-+class+2.mp4",
            }
          ]
        }
      ]
    },
    {
      id: 4,
      Course_Name_EN: "SDG17 – Foundation Training (Economic)",
      Course_Name_TC: "SDG17 – Foundation Training (Economic)",
      Course_Name_SC: "SDG17 – Foundation Training (Economic)",
      Course_Master_Language: "",
      Course_Description_EN: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
      Course_Description_TC: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
      Course_Description_SC: "This course is going to share about the ESG report framework to you. It is included the principle, structure, and best practices.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/SDG17 – Foundation Training (Economic).png",
      Course_Photo: "/assets/images/SDG17 – Foundation Training (Economic).png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    },
    {
      id: 5,
      Course_Name_EN: "SDG17 – Foundation Training (Social)",
      Course_Name_TC: "SDG17 – Foundation Training (Social)",
      Course_Name_SC: "SDG17 – Foundation Training (Social)",
      Course_Master_Language: "",
      Course_Description_EN: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      Course_Description_TC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      Course_Description_SC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in social related.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/SDG17 – Foundation Training (Social).png",
      Course_Photo: "/assets/images/SDG17 – Foundation Training (Social).png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    },
    {
      id: 6,
      Course_Name_EN: "SDG17 – Foundation Training (Environmental)",
      Course_Name_TC: "SDG17 – Foundation Training (Environmental)",
      Course_Name_SC: "SDG17 – Foundation Training (Environmental)",
      Course_Master_Language: "",
      Course_Description_EN: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Description_TC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Description_SC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/SDG17 – Foundation Training (Environmental).png",
      Course_Photo: "/assets/images/SDG17 – Foundation Training (Environmental).png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    },
    {
      id: 7,
      Course_Name_EN: "ESG Report 101 – Awareness",
      Course_Name_TC: "ESG Report 101 – Awareness",
      Course_Name_SC: "ESG Report 101 – Awareness",
      Course_Master_Language: "",
      Course_Description_EN: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Description_TC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Description_SC: "SDG17 refers to the need for cross sector and cross-country collaboration in pursuit of all the goals by the year 2030. It is a call for countries to align policies. This section is focusing to introduce the goals in environmental related.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/ESG Report 101 – Awareness.png",
      Course_Photo: "/assets/images/ESG Report 101 – Awareness.png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    },
    {
      id: 8,
      Course_Name_EN: "ESG Report 102 – Implementation",
      Course_Name_TC: "ESG Report 102 – Implementation",
      Course_Name_SC: "ESG Report 102 – Implementation",
      Course_Master_Language: "",
      Course_Description_EN: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
      Course_Description_TC: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
      Course_Description_SC: "After you learnt about the framework of ESG report and now you should be looking for the tool and method to implement your first ESG report.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/ESG Report 102 – Implementation.png",
      Course_Photo: "/assets/images/ESG Report 102 – Implementation.png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    },
    {
      id: 9,
      Course_Name_EN: "ESG Report 103 – Sustain Success",
      Course_Name_TC: "ESG Report 103 – Sustain Success",
      Course_Name_SC: "ESG Report 103 – Sustain Success",
      Course_Master_Language: "",
      Course_Description_EN: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
      Course_Description_TC: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
      Course_Description_SC: "ESG report is a never end project. Once we start, we have to ensure this report can sustain success. This section is introducing you for about 5 Additional tools and turn your ESG report sustain success.",
      Course_Category: "",
      Industry: "",
      Course_Fee: "",
      Material_Language: "",
      Video_Language_Audio: "",
      Video_Language_Subtitle: "",
      Preview_Video: "/assets/images/ESG Report 103 – Sustain Success.png",
      Course_Photo: "/assets/images/ESG Report 103 – Sustain Success.png",
      Trainer_Id: 0,
      Trainer: "HKQAA",
      Trainer_Organization: "",
      Off_the_Shelf_Date: "",
      Enrollment_Validity_until: "",
      Does_this_course_offer_certificate: "",
      Certificate_Orientation: "",
    }
  ];

  return (!id ? js : js[js.findIndex(item => item.id === id)]);
  ;
}
