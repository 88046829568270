import React from "react";
import { getLangNameByCode } from "../util/lang";
import PhoneDisplay from "./PhoneDisplay";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";


export default function AdminViewLearnerInfo(props){
    const { t } = useTranslation();

    const user = props.user;

    let joinElearningDate = null;
    if (user?.createdAt) {
      joinElearningDate = new Date(user?.createdAt).toLocaleDateString();
    }

    return (
        <div className="ms-md--0 ms-5 max-width-1304 bg-white p-5 mb-5 mt-5">
                  <div className="row my-3">
                    <hr />
                  </div>
                  <div className="row mb-3">
                    <div className="col fw-bold font-size-20px text-decoration-underline">
                      {t('User Information')}
                    </div>
                  </div>
                  <div className="staff-info-container row font-size-16px">
                    <div className="col">
                      <div className="row">
                        <div className="col-4">{t('Username')}:</div>
                        <div className="col-8 break-word">{user?.Username}</div>
                      </div>
                      {(user?.Role === 'Association Learner' || user?.Role === 'Organization Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">{t('Company email')}:</div>
                            <div className="col-8 break-word">{user?.Role === 'Association Learner' ? user?.Association_Email : user?.Role === 'Organization Learner' ? user.Organization_Email : ''}</div>
                        </div>
                        <div className="row">
                            <div className="col-4">{t('Personal email')}:</div>
                            <div className="col-8 break-word">{user?.Personal_Email}</div>
                        </div>
                      </>
                      :
                      <>
                        <div className="row">
                            <div className="col-4">{t('Email (1)')}:</div>
                            <div className="col-8 break-word">{user?.Personal_Email}</div>
                        </div>
                        <div className="row">
                            <div className="col-4">{t('Email (2)')}:</div>
                            <div className="col-8 break-word">{user?.Personal_Email_2nd}</div>
                        </div>
                      </>
                      }
                      <div className="row">
                        <div className="col-4">{t('Office Number')}:</div>
                        <div className="col-8 break-word">
                          <PhoneDisplay number={user?.Office_Number} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Mobile Number')}:</div>
                        <div className="col-8 break-word">
                          <PhoneDisplay number={user?.Mobile_Number} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Gender')}:</div>
                        <div className="col">{user?.Gender}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Age group')}:</div>
                        <div className="col">{user?.Age_Group}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{t('Education Level')}:</div>
                        <div className="col">{user?.Education_Level}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          {t('Join elearning date')}:
                        </div>
                        <div className="col-8 break-word">{joinElearningDate}</div>
                      </div>
                    </div>
                    <div className="col">
                      {(user?.Role === 'Association Learner' || user?.Role === 'Organization Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">{user?.Role === 'Association Learner' ? t('Membership No.') : t('Staff ID')}:</div>
                            <div className="col-8 break-word">{user?.Role === 'Association Learner' ? user?.Membership_No : user?.Staff_Id}</div>
                        </div>
                        {user?.Role === 'Association Learner'
                        ?
                        <div className="row">
                            <div className="col-4">{t('Company Name')}:</div>
                            <div className="col-8 break-word">{user?.Company_Name}</div>
                        </div>
                        :
                        ''
                        }
                      </>
                      :
                      ''
                      }
                      <div className="row">
                        <div className="col-4">{t('Department')}:</div>
                        <div className="col">{user?.Department_1}</div>
                      </div>
                      {(user?.Role === 'Association Learner' || user?.Role === 'Organization Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">{t('Branch')}:</div>
                            <div className="col-8 break-word">{user?.Branch}</div>
                        </div>
                        <div className="row">
                            <div className="col-4">{t('Level')}:</div>
                            <div className="col-8 break-word">{user?.Level}</div>
                        </div>
                        <div className="row">
                            <div className="col-4">{t('Position')}:</div>
                            <div className="col-8 break-word">{user?.Position}</div>
                        </div>
                      </>
                      :
                      ''
                      }
                      <div className="row">
                        <div className="col-4">{t('Year Joined')}:</div>
                        <div className="col-8 break-word">{user?.Year_Joined}</div>
                      </div>
                      {(user?.Role === 'Association Learner' || user?.Role === 'Organization Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">
                            {t('Training Yearly Target')}:
                            </div>
                            <div className="col-8 break-word">{user?.Training_Yearly_Target}</div>
                        </div>
                      </>
                      :
                      ''
                      }
                      <div className="row">
                        <div className="col-4">
                          {t('Preferred Language')}:
                        </div>
                        <div className="col">{ getLangNameByCode(user?.Preferred_Language)}</div>
                      </div>
                      {(user?.Role === 'Association Learner' || user?.Role === 'Organization Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">{t('Status')}:</div>
                            <div className="col">{user?.Status}</div>
                        </div>
                      </>
                      :
                      ''
                      }
                      {(user?.Role === 'Individual Learner')
                      ?
                      <>
                        <div className="row">
                            <div className="col-4">{t('Interested Topics')}:</div>
                            <div className="col">{user?.Interested_Topics}</div>
                        </div>
                      </>
                      :
                      ''
                      }
                    </div>
                  </div>
                </div>
    );
}
