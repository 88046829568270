import React, {useEffect, useState} from "react";
import {getApiClient} from "../../util/auth";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import moment from "moment";
import EyeIcon from "../../components/EyeIcon";
import {
    course_code_column,
    course_title_column,
    date_taken_column,
    completion_date_column,
    certificate_column,
} from "../AssignedCoursesDatatable";
import QuizModal from "../../components/Cms/QuizModal";

export default function StaffCourses(props){
    const [view_assigned_courses_response, set_view_assigned_courses_response] = useState();
    const [is_show_quiz_model, set_is_show_quiz_model] = useState(false);
    const [modal_quiz_id, set_modal_quiz_id] = useState();
    const [modal_course, set_modal_course] = useState();

    const api = getApiClient();
    useEffect(() => {
        api.post('/user/viewOwnLearnerAssignedCourses', { learner_id: props.user.id })
            .then((response) => {
                set_view_assigned_courses_response(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    /*
    const handleUnassign = (staff_id, course_id) => {
        api.post('/organization/unassign_course', {
            staff_id,
            course_id,
        })
            .then((response) => {
                set_view_assigned_courses_response(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    */

    const handelViewQuiz = (row) => {
        set_modal_course(view_assigned_courses_response?.learner_courses_details_list?.filter(course => row.CourseId == course.CourseId)?.[0]);
        set_modal_quiz_id(row?.QuizId);
        set_is_show_quiz_model(true);
    };

    const columns = [
        {
            name: 'Course Code',
            selector: row => row.Course_Code,
            sortable: true,
        },
        {
            name: 'Course Title',
            cell: (row) => {
                const courseName = JSON.parse(row.Course_Name);
                return (<div>
                    <div>{courseName.EN}</div>
                    <div>{courseName.TC}</div>
                    <div>{courseName.SC}</div>
                </div>);
            },
        },
        {
            name: 'Token ID',
            selector: row => row.Course_Token_ID,
            sortable: true,
        },
        {
            name: 'Assign Date',
            selector: row => row.Assigned_Date,
            format: row => row.Assigned_Date ? moment(row.Assigned_Date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Date Taken',
            selector: row => row.Taken_Date,
            format: row => row.Taken_Date ? moment(row.Taken_Date).format('DD MMM YYYY') : '',
            sortable: true,
        },
        {
            name: 'Completion Date/ Date Completed',
            selector: row => row.Completed_Date,
            format: (row) => {
                if (row?.Complete_Status !== 'Finished') {
                    return '';
                }
        
                return row.Completed_Date ? moment(row.Completed_Date).format('DD MMM YYYY') : '';
            },
            sortable: true,
        },
        {
            name: 'Quiz Marks',
            cell: (row) => {
                if (!row?.Quiz_Result) {
                    return '';
                }

                return (<div>
                    <div>{row.Correct_Percentage}%</div>
                    <div>{row.Quiz_Result}</div>
                    <div>
                        <span title="View">
                            <button
                                className={"btn p-0"}
                                onClick={() => {
                                    handelViewQuiz(row);
                                }}
                            >
                                <EyeIcon />
                            </button>
                        </span>
                    </div>
                </div>);
            },
        },
        {
            name: 'Certificate',
            selector: row => row.Certificate_URL,
            format: (row) => {
                if(row.Certificate_URL === null || row.Certificate_URL === "") {
                    return '';
                }

                return <>
                    <Button 
                        className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"} 
                        type="button" 
                        onClick={() => { window.open(row.Certificate_URL, '_blank', 'noreferrer') }}
                    >
                        Print
                    </Button>
                </>
            },
            sortable: true,
        },
    ];

    return <>
        <QuizModal
            is_show_modal={is_show_quiz_model}
            on_modal_hide={() => {
                set_is_show_quiz_model(false);
            }}
            quiz_id={modal_quiz_id}
            learner_id={props.user.id}
            course={modal_course}
        />

        <div className="ms-5 max-width-1304 p-2 bg-white mb-5">
            <div className={"row"}>
                <div className={"col"}>
                    <DataTable
                        columns={columns}
                        data={view_assigned_courses_response?.learner_courses_details_list ?? []}
                        pagination
                        paginationPerPage={30}
                        paginationRowsPerPageOptions={[30,50,100]}
                    />
                </div>
            </div>
        </div>
    </>
}
