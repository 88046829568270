import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiClient } from "../util/auth";
import EyeIcon from "../components/EyeIcon";
import EditIcon from "../components/EditIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function TrainerArchivedCourseActionColumn(props) {
    const navigate = useNavigate();
    const user = props.user;
    const course_id = props.course_id;

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    let [count, setCount] = useState(0);

    const [remarks, setRemarks] = useState();

    const handleCloseModal = () => setShowModal(false);
    const handleCloseModal2 = () => setShowModal2(false);

    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const api = getApiClient();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(count < 1 || (user.Role === "Individual Learner" && count !== 1)) {
            if(user.Role === "Individual Learner") {
                return alert("The Purchase Course Quantity must be 1 for individual learner!");
            } else {
                return alert("Please input correct Purchase Course Quantity!");
            }
        }
        setShowModal(false);
        setShowModal2(true);
    };

    const handleConfirm = (e) => {
        e.preventDefault();
        api
            .post("/trainer_academy/enrollCourseApproval", {
                enrollment_id: user.EnrollmentId,
                enroller_id: user.id,
                course_id: course_id,
                approval_action: "Accept",
                purchase_course_quantity: count,
                remarks: remarks
            })
            .then((response) => {
                alert(response?.data?.message);
                setShowModal2(false);
                navigate(`/cms/trainer-course/${course_id}/enrolled-learner`);
            })
            .catch((error) => {
                console.log(error);
                alert(error?.response?.data?.message);
            });
    };

    const handleBack = (e) => {
        e.preventDefault();
        setShowModal2(false);
        setShowModal(true);
    };

    const handleProcess = (event) => {
        event.preventDefault();
        console.log("~ handleProcess ~")
        handleShowModal(event);
    };

    function incrementCount(e) {
        e.preventDefault();
        count = count < 0 ? 0 : count + 1;
        setCount(count);
    }
    function decrementCount(e) {
        e.preventDefault();
        count = count > 0 ? count - 1 : 0;
        setCount(count);
    }

    return (
        <>
            {/* form modal */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size={"md"}
                scrollable={true}
                centered
            >
                <Modal.Header closeButton><p className="m-0 font-size-20px text-a2a2a2 fw-700">Process</p></Modal.Header>
                <Modal.Body>
                    <Form className={"d-flex w-100"}>
                        <Row style={{ flexFlow: "column", width: "100%", margin: "0" }}>
                            <Col>
                                <label className="font-size-18px fw-700 mb-2">Purchase Course Quantity</label>
                            </Col>
                            <Col className="font-size-18px fw-700 mb-2 counter-div">
                                <button onClick={decrementCount}>-</button>
                                <input
                                    type="number"
                                    name="qty"
                                    min="1"
                                    step="1"
                                    value={count}
                                    onChange={({ target: { value } }) => {
                                        if (value < 0) {
                                            setCount(0);
                                        }
                                        else {
                                            setCount(value);
                                        }
                                    }} />
                                <button onClick={incrementCount}>+</button>
                            </Col>
                            <Col>
                                <label className="font-size-18px fw-700 mb-2">Remarks</label>
                            </Col>
                            <Col>
                                <textarea 
                                    name="remarks" 
                                    className="font-size-18px mb-2" 
                                    style={{ border: "1px solid #ddd", width: "100%", minHeight: "100px" }}
                                    onChange={(e) => {setRemarks(e.target.value)}}
                                ></textarea>
                            </Col>
                            <Col>
                                <Button
                                    className={"ms-auto bg-eb6201 text-white shadow-none"}
                                    variant=""
                                    onClick={handleSubmit}
                                >
                                    Confirm & Generate Token
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* confirmation modal */}
            <Modal
                show={showModal2}
                onHide={handleCloseModal2}
                size={"md"}
                scrollable={true}
                centered
            >
                <Modal.Body>
                    <Form className={"d-flex w-100"}>
                        <Row style={{ flexFlow: "column", width: "100%", margin: "0", textAlign: "center" }}>
                            <Col>
                                <img
                                    alt=""
                                    src="/assets/images/logo.png"
                                    width="100%"
                                    className="d-inline-block align-top"
                                />
                            </Col>
                            <Col>
                                <label className="font-size-18px fw-700 mb-2">Confirm Purchase Course Quantity?</label>
                            </Col>
                            <Col className="text-eb6201 font-size-30px fw-700 mb-2">
                                {count}
                            </Col>
                            <Col>
                                <Button
                                    className={"ms-auto bg-eb6201 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                    onClick={handleConfirm}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className={"ms-auto bg-b1b1b1 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className={"d-flex align-items-center"}>
                <button
                    style={{ marginRight: "3px", padding: "5px 10px" }}
                    className={"btn bg-eb6201 text-fff fw-700"}
                    onClick={handleProcess}
                >
                    Process
                </button>
            </div>
        </>
    );
}
