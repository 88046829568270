import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getApiClient } from "../util/auth";
import EyeIcon from "../components/EyeIcon";
import EditIcon from "../components/EditIcon";
import { parseDbStringToObj } from "../util/lang";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function AdminTerminateCourseActionColumn(props) {
    const navigate = useNavigate();
    const course = props.course;
    const isSuperAdmin = props.isSuperAdmin;

    const api = getApiClient();

    const [showModal, setShowModal] = useState(false);

    const [terminateRemarks, setTerminateRemarks] = useState(null);

    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleProcessTerminate = (event) => {
        event.preventDefault();
        console.log("~ handleProcessTerminate ~")
        handleShowModal(event);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleSaveTerminate = (e) => {
        e.preventDefault();
        let confirmTerminate = window.confirm(`Are you sure to terminate the course (${course.Course_Code} ${parseDbStringToObj(course.Course_Name).EN})?`);
        if(confirmTerminate === true) {
            api
                .post("/admin/terminateCourse", {
                    course_id: course.id,
                    terminate_remarks: terminateRemarks
                })
                .then((response) => {
                    alert(response?.data?.message);
                    setShowModal(false);
                    props.handleGenerateReportByDate(); //fetch the updated data again
                })
                .catch((error) => {
                    console.log(error);
                    alert(error.response?.data?.message);
                });
        }
    };

    const handleCancelTerminate = (e) => {
        e.preventDefault();
        setShowModal(false);
    };

    return (
        <>
            {/* form modal */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size={"md"}
                scrollable={true}
                centered
            >
                <Modal.Header closeButton><p className="m-0 font-size-20px text-a2a2a2 fw-700 m-2">Terminate Course</p></Modal.Header>
                <Modal.Body>
                    <Form className={"d-flex w-100"} onSubmit={handleSaveTerminate}>
                        <Row style={{ flexFlow: "column", width: "100%", margin: "0" }}>
                            <Col>
                                Confirm to terminate the course ({course.Course_Code} {parseDbStringToObj(course.Course_Name).EN})?
                            </Col>
                            <Col>
                                Once it is terminated, the course <b style={{color: 'red'}}>CANNOT</b> be resumed.
                            </Col>
                            <Col>
                                <label className="font-size-18px text-a2a2a2 fw-700 mt-3 mb-2">Remarks<span className={"text-danger"}>*</span></label>
                            </Col>
                            <Col>
                                <textarea 
                                    name="Terminate_Remarks" 
                                    className="font-size-18px mb-2" 
                                    style={{ border: "1px solid #ddd", width: "100%", minHeight: "100px" }}
                                    onChange={(e) => {setTerminateRemarks(e.target.value)}}
                                    required={true}
                                ></textarea>
                            </Col>
                            <Col>
                                <Button
                                    type="submit"
                                    className={"ms-auto bg-eb6201 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                >
                                    Save
                                </Button>
                                <Button
                                    className={"ms-auto bg-b1b1b1 text-white shadow-none m-2"}
                                    style={{ minWidth: "100px" }}
                                    variant=""
                                    onClick={handleCancelTerminate}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className={"d-flex align-items-center"}>
                <span title="View">
                    <Link 
                        to={`/cms/admin/membership/course/${course.id}`} 
                        onClick={() => localStorage.setItem("selectedCourse", JSON.stringify(course))} 
                        target="_blank" rel="noopener noreferrer"
                    >
                        <button className={"btn p-0"}>
                            <EyeIcon />
                        </button>
                    </Link>
                </span>
                {isSuperAdmin === true
                ?
                <>
                    <span title="Terminate Course?" className={"m-2"}>
                        <input
                            id={`terminate-course-${course.id}`}
                            className="form-check-input"
                            type="checkbox"
                            style={{ backgroundColor: course.Is_Terminated === 'Yes' ? 'black' : 'white'}}
                            onChange={() => {}}
                            onClick={handleProcessTerminate}
                            disabled={course.Is_Terminated === 'Yes' ? true : false}
                        />
                    </span>
                    <span title="Status" className={"m-1"}>
                        {course.Is_Terminated === 'Yes' ? 'Terminated' : course.Course_Status}
                    </span>
                </>
                :
                ''
                }
            </div>
        </>
    );
}
