import LayoutCms from "../../../components/LayoutCms";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getTextByCode, parseDbStringToObj } from "../../../util/lang";
import ShadowContainer from "../../../components/ShadowContainer";
import PrimaryButton from "../../../components/PrimaryButton";
import DataTable from 'react-data-table-component';
import { getApiClient } from "../../../util/auth";
import PhoneDisplay from "../../../components/PhoneDisplay";
import EyeIcon from "../../../components/EyeIcon";
import EditIcon from "../../../components/EditIcon";
import PhoneInput from "react-phone-input-2";

export default function Index() {
  const navigate = useNavigate();
  const api = getApiClient();

  const role = localStorage.getItem("role");

  const [allMembers, setAllMembers] = useState([]);
  const [allCourses, setAllCourses] = useState(null);

  const [course, setCourse] = useState('');
  const [enrolledMembers, setEnrolledMembers] = useState([]);
  const [assignableMembers, setAssignableMembers] = useState([]);

  const [selectedMembers, setSelectedMembers] = useState([]);

  const columns = [
    {
      name: 'Membership No.',
      selector: row => row.Membership_No,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => parseDbStringToObj(row.Contact_Person_First_Name)?.EN + ' ' + parseDbStringToObj(row.Contact_Person_Last_Name)?.EN,
      sortable: true,
    },
    {
      name: 'Chinese Name',
      selector: row => parseDbStringToObj(row.Contact_Person_Last_Name)?.TC + parseDbStringToObj(row.Contact_Person_First_Name)?.TC,
      sortable: true,
    },
    // {
    //   name: 'Office Number',
    //   selector: row => row?.Office_Number,
    //   sortable: false,
    // },
    {
      name: 'Email Address',
      selector: row => row.Association_Email,
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: row => row.Company_Name,
      sortable: true,
    },
    {
      name: 'Position',
      selector: row => row.Position,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.Status,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <span title="View">
            <button
              className={"btn p-0"}
              onClick={() =>
                navigate("/cms/members/viewMember", { state: { user: row } })
              }
            >
              <EyeIcon />
            </button>
          </span>
          {row.Status !== 'Terminated'
          ?
          <span title="Edit">
            <button
              className={"btn p-0"}
              onClick={() =>
                navigate("/cms/members/create", { state: { user: row } })
              }
            >
              <EditIcon />
            </button>
          </span>
          :
          ''
          }
        </>
      ),
    },
  ];

  useEffect(() => {
    if(role !== 'Association') {
      alert('User not authorized! You will go back to Home Page.');
      if(role === null || role === '') {
        navigate('/login');
      } else {
        navigate('/');
      }
      return;
    }

    api.get("/association/viewMembers")
      .then((response) => {
        setAllMembers(response.data?.user);
      })
      .catch((error) => {
        console.log(error);
      });

    api.post("/user/view_own_courses")
      .then((response) => {
        setAllCourses(response.data?.courses);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelectCourse = (course_id) => {
    api.post("/association/memberIdsByCourseId", { course_id: course_id })
      .then((response) => {
        setCourse(allCourses.find((c) => c?.id === parseInt(course_id)));

        const enrolledIds = response.data?.UserId;

        console.log(enrolledIds);
        const enrolled = allMembers.filter((member) => {
          return enrolledIds.includes(member?.id);
        });

        const assignable = allMembers.filter((member) => {
          return !enrolledIds.includes(member?.id);
        });

        setEnrolledMembers(enrolled);
        setAssignableMembers(assignable);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSelectedMember = ({ selectedRows }) => {
    setSelectedMembers(selectedRows);
  }

  const handleAssign = () => {
    let t = "Confirm to assign the members? Once the members are assigned, the assigned members CANNOT be removed from the course.";
    if (window.confirm(t) === true) {
      if (!selectedMembers.length) {
        return alert('Please select at least one member');
      }
      let members_ids = [];

      selectedMembers.map((s) => {
        members_ids.push(s.id);
      });

      const param = {
        member_ids: JSON.stringify(members_ids),
        course_id: course?.id,
      };
      api.post("/association/assign_course", param)
        .then((response) => {
          const success_id_string = response?.data?.success;
          const failed_id_string = response?.data?.failed;
          alert(`The successful assignment: ${success_id_string ?? 'None'}\nThe unsuccessful assignment: ${failed_id_string ?? 'None'}`);
          window.location.reload();
        })
        .catch((error) => {
          alert("Assign failed : " + error.response.data.message);
          console.log(error);
        });
    }
  };

  return (
    <LayoutCms route={'members.index'}>
      <div className={"d-flex justify-content-between align-items-center"}>
        <div className={"text-secondary font-size-20px fw-700"}>
          Member Management
        </div>
        {/* <div style={{ color: "#adb5bd" }}>Lorem ipsum</div> */}
        <PrimaryButton className="font-size-14px" onClick={() => navigate("/cms/members/create")} >
          Add Member
        </PrimaryButton>
      </div>


      <ShadowContainer>
        <div className="d-flex justify-content-between align-items-center my-2 assign-course-div">
          <div className="text-secondary font-size-20px fw-700">Assign course</div>
          <select className="btn border mx-2" style={{ minWidth: 600, flexGrow: 1 }}
            value={course?.id}
            onChange={(e) => handleSelectCourse(e.target.value)}>
            <option default hidden>Please select a course... </option>
            {allCourses?.map((c) => (
              <option key={c.id} value={c.id}>
                {c.Course_Code}&nbsp;-&nbsp;{getTextByCode(c.Course_Name, 'EN')}
              </option>
            ))}
          </select>
          <Button variant="outline-danger"
            onClick={() => window.location.reload()}>
            Refresh
          </Button>

        </div>
        {course ?
          <>
            <div className="text-secondary my-4 font-size-20px fw-700">
              Member available
            </div>

            <div className={"my-3 staff-tbl-div"}>
              <DataTable
                columns={columns}
                data={assignableMembers}
                selectableRows
                selectableRowDisabled={(row) => row.Status === 'Disable' || row.Status === 'Terminated'}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[30, 50, 100]}
                onSelectedRowsChange={handleSelectedMember}
              />
            </div>
            <PrimaryButton onClick={handleAssign} >Assign</PrimaryButton>
          </>
          : ''}
      </ShadowContainer>
      {course ?
        <AssignedMembers columns={columns} enrolledMembers={enrolledMembers} />
        :
        <AllMembersTable columns={columns} allMembers={allMembers} />
      }
    </LayoutCms>
  );
}

function AssignedMembers(props) {
  return <>
    <ShadowContainer>
      <div className="text-secondary font-size-20px fw-700 my-4">
        Assigned members:
        <span
          className={"ms-5 text-white p-2 rounded"}
          style={{ background: "#A2A2A2" }}
        >
          {props.enrolledMembers.length}
        </span>
      </div>
      <div className="my-3 staff-tbl-div">
        <DataTable
          columns={props.columns}
          data={props.enrolledMembers}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 50, 100]}
          noDataComponent="None of your members have enrolled in this course yet."
        />
      </div>
    </ShadowContainer>
  </>
}


function AllMembersTable(props) {
  return <>
    <ShadowContainer>
      <div className="text-secondary font-size-20px fw-700 my-4">
        All Member:
        <span
          className={"ms-5 text-white p-2 rounded"}
          style={{ background: "#A2A2A2" }}
        >
          {props.allMembers.length}
        </span>
      </div>
      <div className="my-3 staff-tbl-div">
        <DataTable
          columns={props.columns}
          data={props.allMembers}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 50, 100]}
          noDataComponent="You do not have any members"
        />
      </div>
    </ShadowContainer>
  </>
}
