import React, { useEffect, useState, useRef } from "react";
import "react-circular-progressbar/dist/styles.css";
import CourseProgress from "../components/CourseProgress";
import ProfileLayout from "../components/ProfileLayout";
import { getApiClient } from "../util/auth";
import MyCourses from "../components/MyCourses";
import { Link, useNavigate } from "react-router-dom";
import ProfileMenu from "../components/ProfileMenu";
import { parseDbStringToObj } from "../util/lang";
import MemberInfo from "../components/MemberInfo";
import { get_nav_headers } from "../services/sidebar-service";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LearnerCertificates from "../components/Cms/LearnerCertificates";
import ProfileTop from "../components/ProfileTop";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AssignedCoursesDatatable from "../components/AssignedCoursesDatatable";
import WatchAgainModal from "../components/WatchAgainModal";

// this is Association Learner profile
export default function AssociationLearnerProfile() {
  const { i18n, t } = useTranslation();

  const [user, setUser] = useState();
  const [is_show_watch_again_modal, set_is_show_watch_again_modal] = useState(false);
  const [selected_course, set_selected_course] = useState();
  const api = getApiClient();

  const navigate = useNavigate();
  
  let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage') ?? 'EN';

  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let u = response.data.user;

        if(response.data.user.Role !== 'Association Learner') {
          alert(t('User not authorized! You will go back to Home Page.'));
          navigate('/');
          return;
        }
        
        if(u.Preferred_Language !== null && u.Preferred_Language !== '') {
          // if user clicked other lang in home page and it is not their preferred language, it will do reload to prevent bug
          // if same as preferred language, no need to reload
          if(u.Preferred_Language !== localStorage.getItem('i18nLanguage')) {
            i18n.changeLanguage(u.Preferred_Language);
            localStorage.setItem('i18nLanguage', u.Preferred_Language);
            CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
            window.location.reload();
          } else {
            i18n.changeLanguage(u.Preferred_Language);
            localStorage.setItem('i18nLanguage', u.Preferred_Language);
            CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage');
          }
        }

        u.Association_Name = parseDbStringToObj(u.Association_Name);
        u.Contact_Person_First_Name = parseDbStringToObj(u.Contact_Person_First_Name);
        u.Contact_Person_Last_Name = parseDbStringToObj(u.Contact_Person_Last_Name);

        setUser(u);
      })
      .catch((error) => {
        alert(t(error.response.data.message ?? error.message ?? error));
      });
  }, []);

  const refs = {
    profileInformationRef: useRef(),
    dataAnalysisRef: useRef(),
    myCoursesRef: useRef(),
    myCertificatesRef: useRef(),
    CURRENT_LANGUAGE: CURRENT_LANGUAGE,
  }
  const nav_headers = get_nav_headers(refs);

  if (!user) {
    return <>{t('Loading')}...</>;
  }

  return (
    <ProfileLayout>
      <WatchAgainModal
          is_show_modal={is_show_watch_again_modal}
          on_modal_hide={() => {
            set_is_show_watch_again_modal(false);
          }}
          course={selected_course}
      />

      <div style={{ backgroundColor: "#F5F5F8" }}>
        <Container fluid className="p-0">
          <Row className={"no-gutters gx-0"}>
            <ProfileMenu navHeader={nav_headers}></ProfileMenu>
            <Col className="profile-container p-5">
              <div className={"position-relative"} ref={nav_headers.get('profileInformation').ref} id={nav_headers.get('profileInformation').id}>
                <div
                  className={"position-absolute position-sm-relative"}
                  style={{ left: 0, top: -40, right: 0 }}
                >
                  <div className="ms-md--0 ms-5 max-width-1304 px-5">
                    <Row className={"align-items-center"}>
                      <ProfileTop user={user} CURRENT_LANGUAGE={CURRENT_LANGUAGE} />
                      <div className="col-auto" style={{ marginTop: "8px"}}>
                        <Link
                          to={"/profile-association/edit"}
                          className={"btn bg-eb6201 text-white"}
                        >
                          {t('Edit')}
                        </Link>
                      </div>
                    </Row>
                  </div>
                </div>
                <MemberInfo user={user}></MemberInfo>
              </div>
              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5" ref={nav_headers.get('dataAnalysis').ref} id={nav_headers.get('dataAnalysis').id}>
                <div className="row align-items-center mb-3">
                  <div className="col fw-bold font-size-20px text-decoration-underline">
                    {t('Data Analysis')}
                  </div>
                </div>
                <CourseProgress user={user} />
              </div>

              <div ref={nav_headers.get('myCourses').ref} id={nav_headers.get('myCourses').id}>
                <MyCourses view_courses_endpoint={'/association_learner/view_courses'} CURRENT_LANGUAGE={CURRENT_LANGUAGE}></MyCourses>
              </div>

              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <Row>
                  <Col>
                    <AssignedCoursesDatatable
                        on_watch_again_clicked={(row) => {
                          set_selected_course(row);
                          set_is_show_watch_again_modal(true);
                        }}
                    />
                  </Col>
                </Row>
              </div>

              {/* Since there is no cert yet, we borrow LearnerCertificates component as a placeholder */}
              <div ref={nav_headers.get('myCertificates').ref} id={nav_headers.get('myCertificates').id}>
                <LearnerCertificates user={user} endpoint={"/user/viewTotalCertificate"} />
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ProfileLayout>
  );
}
