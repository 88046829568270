import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PrimaryButton from "./PrimaryButton";
import {createSearchParams, useNavigate} from "react-router-dom";
import {getApiClient} from "../util/auth";
import {getTextByCode} from "../util/lang";
import moment from "moment";
import { useTranslation } from 'react-i18next';

export default function WatchAgainModal (props) {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const role = localStorage.getItem("role");

    const [course, set_course] = useState();

    let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage') ?? 'EN';

    useEffect(() => {
        let view_course_api_endpoint = ``;
        if(role === 'Organization Learner') {
            view_course_api_endpoint = `/organization_learner/view_course`;
        } else if(role === 'Individual Learner') {
            view_course_api_endpoint = `/individual_learner/view_course`;
        } else if(role === 'Association Learner') {
            view_course_api_endpoint = `/association_learner/view_course`;
        }

        getApiClient().post(view_course_api_endpoint, {
            course_id: props?.course?.CourseId,
        })
            .then((response) => {
                set_course(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props?.course]);

    const handleViewClass = () => {
        const today = moment();
        const off_the_shelf_date = moment(course?.course?.[0]?.Off_The_Shelf_Date);

        let is_before_expired = false;
        if (course?.course?.[0]?.Off_The_Shelf_Date) {
            is_before_expired = today.isBefore(off_the_shelf_date);
        }

        if (is_before_expired) {
            navigate(`/courses/show/${props?.course?.CourseId}`);
        } else {
            alert(t("The course has expired."));
        }
    };

    return (
        <>
            <Modal
                show={props.is_show_modal}
                onHide={() => {
                    props.on_modal_hide();
                }}
                centered={true}
                scrollable={true}
            >
                <Modal.Body className={"p-0"}>
                    <Container className={"p-3"}>
                        <Row className={"mb-5"}>
                            <Col>
                                <div className={"font-size-20px font-bold"} style={{lineHeight: 2}}>{course?.course?.[0]?.Course_Code} {getTextByCode(course?.course?.[0]?.Course_Name, CURRENT_LANGUAGE)}</div>
                                <div className={"mb-2"}style={{fontSize: 16, lineHeight: '30px'}}>{getTextByCode(course?.course_owner_name, CURRENT_LANGUAGE)} {course?.course?.[0]?.Trainer}</div>
                                <div style={{fontSize: 16, lineHeight: '20px'}}>{getTextByCode(course?.course?.[0]?.Course_Descriptions, CURRENT_LANGUAGE)}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PrimaryButton className={"w-100"} onClick={handleViewClass}>{t('View')}</PrimaryButton>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}
