import React, { useState } from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Section from "../components/Section";
import H2 from "../components/H2";
import { Container, Row, Col } from "react-bootstrap";
import Salutation from "../components/Options/Salutation";
import { getApiClient } from "../util/auth";
import {useTranslation} from "react-i18next";

export default function SubscribeNewsletter() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/hero_banner.png">
        {t('Subscribe Newsletter')}
      </Banner>

      <Section>
        <Container
          className="max-width-1304 mb-3"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "50% 50%",
            backgroundSize: "200px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="mb-5 g-0">
            <Col className="d-none d-md-block col-md-6">
              <div style={{ paddingRight: 25 }}>
                <img
                  className={"img-fluid w-100"}
                  src={"/assets/images/contact-us-hero.jpg"}
                  alt=""
                />
              </div>
            </Col>
            <Col className="pl-sm-0" style={{ paddingLeft: 24 }}>
              <FormDiv></FormDiv>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
}

function FormDiv(props) {
  const [formValues, setFormValues] = useState({});
  const { t } = useTranslation();

  const api = getApiClient();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(document.getElementById("agree").checked === false) {
      alert(t('Please confirm the agreement.'));
      return;
    }

    api.post(`/public/submitSubscribe`, { ...formValues })
      .then(function (response) {
        alert(t('Submitted'))
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          alert(t(error.response.data.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValues(values => ({ ...values, [name]: value }))
  }

  return <form onSubmit={(e) => handleSubmit(e)}>
    <Row style={{ textAlign: "center" }}>
      <div>
        <label className="col-12 col-form-label">
          {t('Keep pace with market trends & visionary strategies')}
        </label>
        <H2 style={{ fontSize: 20, textAlign: "center" }} className={"mt-0"}>
          {t('SUBSCRIBE NOW')}
        </H2>
      </div>
    </Row>

    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('Organisation Name')}</label>
      <Col>
        <input
          type="text"
          className="form-control"
          placeholder={t('Organisation Name')}
          name="organisation_name"
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('Position')}</label>
      <Col>
        <input
          type="text"
          className="form-control"
          placeholder={t('Position')}
          name="position"
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('Title')}</label>
      <Col>
        <select
          type="text"
          className="form-select"
          placeholder={t('Title')}
          name="prefix"
          onChange={(e) => handleChange(e)}
        >
          <Salutation></Salutation>
        </select>
      </Col>
    </Row>
    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('First Name')}</label>
      <Col>
        <input
          type="text"
          className="form-control"
          placeholder={t('First Name')}
          name="first_name"
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('Last Name')}</label>
      <Col>
        <input
          type="text"
          className="form-control"
          placeholder={t('Last Name')}
          name="last_name"
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <label className="col-4 col-form-label">{t('Email')}</label>
      <Col>
        <input
          type="email"
          className="form-control"
          placeholder={t('Email')}
          name="email"
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>

    <Row className="mb-3">
      <div>
        <label className="col-12 col-form-label">
          <input type="checkbox" id="agree" />&nbsp;
          {t('I argee to receive important updates and news from HKQAA LightShare including market trends, expert insights, hot courses, events and services, other educational and publicity information.')}
        </label>
      </div>
    </Row>
    
    <Row style={{ textAlign: "center" }}>
      <label className="col-4 col-form-label"></label>
      <div>
        <button className="btn bg-eb6201 text-white fw-bold shadow-none">
          {t('Submit')}
        </button>
      </div>
    </Row>
  </form>
}
