import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import CourseProgress from "../../components/CourseProgress";
import { Link, useNavigate } from "react-router-dom";
import ProfileLayout from "../../components/ProfileLayout";
import { getApiClient } from "../../util/auth";
import ProfileMenu from "../../components/ProfileMenu";
import { parseDbStringToObj, prepareDbString } from "../../util/lang";
import { get_nav_headers } from "../../services/sidebar-service";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LearnerCertificates from "../../components/Cms/LearnerCertificates";
import MyCourses from "../../components/MyCourses";
import EduLevel from "../../components/Options/EduLevel";
import AgeGroup from "../../components/Options/AgeGroup";
import Department from "../../components/Options/Department";
import UploadBox from "../../components/UploadBox";
import PreviewBox from "../../components/PreviewBox";
import AssignedCoursesDatatable from "../../components/AssignedCoursesDatatable";
import WatchAgainModal from "../../components/WatchAgainModal";
import "../../App.css";
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { i18n, t } = useTranslation();

  let CURRENT_LANGUAGE = localStorage.getItem('i18nLanguage') ?? 'EN';
  
  const info_label_width = "col-4";
  const [user, setUser] = useState('');
  const [is_show_watch_again_modal, set_is_show_watch_again_modal] = useState(false);
  const [selected_course, set_selected_course] = useState();
  const [edited, setEdited] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [profile_link, set_profile_link] = useState();

  const navigate = useNavigate();
  const api = getApiClient();

  useEffect(() => {
    api
      .get("/user/viewProfile")
      .then((response) => {
        let u = response.data.user;

        if(response.data.user.Role !== 'Organization Learner') {
          alert('User not authorized! You will go back to Home Page.');
          navigate('/');
          return;
        }

        setCreatedAt(moment(u?.createdAt)?.format('DD/MM/YYYY'));

        // parse DB string into js Obj for multilanguage handling
        u.Contact_Person_First_Name = parseDbStringToObj(u.Contact_Person_First_Name);
        u.Contact_Person_Last_Name = parseDbStringToObj(u.Contact_Person_Last_Name);

        setUser(u);
        set_profile_link(u.Profile_Photo);
      })
      .catch((error) => {
        alert(t(error.response.data.message ?? error.message ?? error));
      });
  }, []);

  const refs = {
    profileInformationRef: useRef(),
    dataAnalysisRef: useRef(),
    myCoursesRef: useRef(),
    myCertificatesRef: useRef(),
    CURRENT_LANGUAGE: CURRENT_LANGUAGE,
  }
  const nav_headers = get_nav_headers(refs);


  const handleChange = (e, lang = 'EN') => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "Contact_Person_First_Name") {
      value = prepareDbString(user.Contact_Person_First_Name, value, lang);
    } else if (name === "Contact_Person_Last_Name") {
      value = prepareDbString(user.Contact_Person_Last_Name, value, lang);
    }

    setUser((user) => { return { ...user, [name]: value } });
    setEdited(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const param = {
      organization_email: user.Organization_Email,
      personal_email: user.Personal_Email,
      contact_personal_first_name: user.Contact_Person_First_Name,
      contact_personal_last_name: user.Contact_Person_Last_Name,

      profile_photo: profile_link,

      mobile_number: user.Mobile_Number,
      office_number: user.Office_Number,

      gender: user.Gender,
      age_group: user.Age_Group,
      education_level: user.Education_Level,

      department_1: user.Department_1,
      branch: user.Branch,
      level: user.Level,
      position: user.Position,

      year_joined: user.Year_Joined,
      training_yearly_target: user.Training_Yearly_Target,

      preferred_language: user.Preferred_Language,
    }
    api.post('/organization_learner/edit_profile', param)
      .then((response) => {
        setEdited(false);
        alert(t('Staff info saved successfully.'));
      })
      .catch((error) => {
        alert('Error cannot save Staff Info.')
        console.log(error.message);
      })
      .then(() => {
        localStorage.setItem('i18nLanguage', user.Preferred_Language);
        navigate('/profile-company');
        window.location.reload();
      })
  };

  const mobile = user.Mobile_Number ? true : false;
  const office = user.Office_Number ? true : false;

  if (!user) {
    return <>{t('Loading')}....</>
  }

  return (
    <ProfileLayout>
      <WatchAgainModal
          is_show_modal={is_show_watch_again_modal}
          on_modal_hide={() => {
            set_is_show_watch_again_modal(false);
          }}
          course={selected_course}
      />

      <div style={{ backgroundColor: "#F5F5F8" }}>
        <Container fluid className="p-0">
          <Row className={"no-gutters gx-0 edit-profile-row"}>
            <ProfileMenu navHeader={nav_headers}></ProfileMenu>
            <Col className="p-5 profile-container">
              <div className={"position-relative"} ref={nav_headers.get('profileInformation').ref} id={nav_headers.get('profileInformation').id}>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                    <div className="row mb-3">
                      <div className="col fw-bold font-size-20px text-decoration-underline">
                        {t('Staff Information')}
                      </div>
                    </div>
                    {/* <button disabled={!edited} className={"btn bg-eb6201 text-white"} type="submit">
                      {t('Save')}
                    </button>
                    &nbsp;
                    <button
                      className={"btn bg-6c757d text-white"}
                      type={"button"}
                      onClick={() => {
                        navigate('/profile-company');
                      }}
                    >
                      {t('Cancel')}
                    </button> */}

                    <div className="my-3">
                      <img className="img-fluid" src={profile_link ?? 'https://via.placeholder.com/150x150'} alt="img"
                        width="300px"
                        height="300px" />
                    </div>
                    <div className="mb-3 row">
                      <div className="col-sm-6">
                        <UploadBox
                          onUploaded={(api_responses) => {
                            setEdited(true);
                            set_profile_link(api_responses?.[0]?.urls?.[0]);
                          }}
                          width_ratio={1}
                          height_ratio={1}
                          tolerance={0.1}
                          allowImageEdit
                        />
                      </div>
                    </div>
                    {
                      profile_link
                      &&
                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <PreviewBox url={profile_link} onDelete={() => { set_profile_link(null); }} />
                        </div>
                      </div>
                    }


                    <div className="row font-size-16px edit-staff-info-container">
                      <div className="col">
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Username')}:<span className={"text-danger"}>*</span></div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Username")}
                              defaultValue={user.Username}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Contact Name')}:<span className={"text-danger"}>*</span></div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"Last Name"}
                              name="Contact_Person_Last_Name"
                              value={user.Contact_Person_Last_Name.EN}
                              onChange={(e) => handleChange(e, 'EN')}
                              required={true}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("First Name")}
                              name="Contact_Person_First_Name"
                              value={user.Contact_Person_First_Name.EN}
                              onChange={(e) => handleChange(e, 'EN')}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Contact Name')}(繁):</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Last Name")}
                              name="Contact_Person_Last_Name"
                              value={user.Contact_Person_Last_Name.TC}
                              onChange={(e) => handleChange(e, 'TC')}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("First Name")}
                              name="Contact_Person_First_Name"
                              value={user.Contact_Person_First_Name.TC}
                              onChange={(e) => handleChange(e, 'TC')}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Contact Name')}(简):</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Last Name")}
                              name="Contact_Person_Last_Name"
                              value={user.Contact_Person_Last_Name.SC}
                              onChange={(e) => handleChange(e, 'SC')}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("First Name")}
                              name="Contact_Person_First_Name"
                              value={user.Contact_Person_First_Name.SC}
                              onChange={(e) => handleChange(e, 'SC')}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Company email')}:<span className={"text-danger"}>*</span></div>
                          <div className="col">
                            <input
                              type="email"
                              className="form-control"
                              placeholder={t("Company email")}
                              name="Organization_Email"
                              value={user.Organization_Email}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>
                            {t('Personal email')}:
                          </div>
                          <div className="col">
                            <input
                              type="email"
                              className="form-control"
                              placeholder={t("Personal email")}
                              name="Personal_Email"
                              value={user.Personal_Email}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className={info_label_width}>{t('Office Number')}:</div>
                          <div className="col">
                            <PhoneInput
                              name="Office_Number"
                              value={user.Office_Number}
                              placeholder=""
                              onChange={(value) => {
                                setEdited(true);
                                setUser((user) => {
                                  return { ...user, Office_Number: value };
                                });
                              }}
                              country={office ? undefined : "hk"}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Mobile Number')}:</div>
                          <div className="col">
                            <PhoneInput
                              name="Mobile_Number"
                              value={user.Mobile_Number}
                              placeholder=""
                              onChange={(value) => {
                                setEdited(true);
                                setUser((user) => {
                                  return { ...user, Mobile_Number: value };
                                });
                              }}
                              country={mobile ? undefined : "hk"}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Gender')}:</div>
                          <div className="col">
                            <select
                              className={"form-select"}
                              name="Gender"
                              value={user.Gender}
                              onChange={(e) => handleChange(e)}
                            >
                              <option default hidden>--</option>
                              <option value="Male">{t('Male')}</option>
                              <option value="Female">{t('Female')}</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Age group')}:</div>
                          <div className="col">
                            <select
                              className={"form-select"}
                              name="Age_Group"
                              value={user.Age_Group}
                              onChange={(e) => handleChange(e)}
                            >
                              <AgeGroup></AgeGroup>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>
                            {t('Education Level')}:
                          </div>
                          <div className="col">
                            <select
                              type="text"
                              className="form-select"
                              placeholder={t("Education Level")}
                              name="Education_Level"
                              value={user.Education_Level}
                              onChange={(e) => handleChange(e)}
                            >
                              <EduLevel></EduLevel>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className={info_label_width}>
                            {t('Join elearning date')}:
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Join elearning date")}
                              readOnly={true}
                              value={createdAt}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Staff ID')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Staff ID")}
                              readOnly={true}
                              value={user.Staff_Id}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Department')}:<span className={"text-danger"}>*</span></div>
                          <div className="col">
                            <select
                              type="text"
                              className="form-select"
                              placeholder={t("Department")}
                              name="Department_1"
                              value={user.Department_1}
                              onChange={(e) => handleChange(e)}
                              required={true}
                            >
                              <Department />
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Branch')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Branch")}
                              name="Branch"
                              value={user.Branch}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Level')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Level")}
                              name="Level"
                              value={user.Level}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Position')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Position")}
                              name="Position"
                              value={user.Position}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>{t('Year Joined')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Year Joined")}
                              readOnly={true}
                              value={user.Year_Joined}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>
                            {t('Training Yearly Target')}:<span className={"text-danger"}>*</span>
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("Training Yearly Target")}
                              name="Training_Yearly_Target"
                              value={user.Training_Yearly_Target}
                              onChange={(e) => handleChange(e)}
                              min={1}
                              max={999}
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className={info_label_width}>
                            {t('Preferred Language')}:
                          </div>
                          <div className="col">
                            <select
                              className="form-select"
                              name="Preferred_Language"
                              value={user.Preferred_Language}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="EN">English</option>
                              <option value="TC">繁體中文</option>
                              <option value="SC">简体中文</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className={info_label_width}>{t('Status')}:</div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("Status")}
                              readOnly={true}
                              value={user.Status}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button disabled={!edited} className={"btn bg-eb6201 text-white"} type="submit">
                        {t('Save')}
                      </button>
                      &nbsp;
                      <button
                        className={"btn bg-6c757d text-white"}
                        type={"button"}
                        onClick={() => {
                          navigate('/profile-company');
                        }}
                      >
                        {t('Cancel')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5" ref={nav_headers.get('dataAnalysis').ref} id={nav_headers.get('dataAnalysis').id}>
                <div className="row align-items-center mb-3">
                  <div className="col fw-bold font-size-20px text-decoration-underline">
                    {t('Data Analysis')}
                  </div>
                </div>

                <CourseProgress user={user} />
              </div>

              <div ref={nav_headers.get('myCourses').ref} id={nav_headers.get('myCourses').id}>
                <MyCourses view_courses_endpoint={'/organization_learner/view_courses'} CURRENT_LANGUAGE={CURRENT_LANGUAGE}></MyCourses>
              </div>

              <div className="ms-md--0 ms-5 max-width-1304 p-5 bg-white mb-5">
                <Row>
                  <Col>
                    <AssignedCoursesDatatable
                        on_watch_again_clicked={(row) => {
                          set_selected_course(row);
                          set_is_show_watch_again_modal(true);
                        }}
                    />
                  </Col>
                </Row>
              </div>

              {/* Since there is no cert yet, we borrow LearnerCertificates component as a placeholder */}
              <div ref={nav_headers.get('myCertificates').ref} id={nav_headers.get('myCertificates').id}>
                <LearnerCertificates user={user} />
              </div>
              
            </Col>
          </Row>
        </Container>
      </div>
    </ProfileLayout>
  );
}
