import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApiClient } from "../../util/auth";
import PrimaryButton from "../PrimaryButton";


import ShadowContainer from "../ShadowContainer";

export default function CreateModule(props){

    const [sequence, set_sequence] = useState('');
    const [module_title_en, set_module_title_en] = useState('');
    const [module_title_tc, set_module_title_tc] = useState('');
    const [module_title_sc, set_module_title_sc] = useState('');
    const [module_description_en, set_module_description_en] = useState('');
    const [module_description_tc, set_module_description_tc] = useState('');
    const [module_description_sc, set_module_description_sc] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!props.course_id) {
            return alert('Please create the course first.');
        }

        const params = {
            course_id: props.course_id,
            module_sequence: parseInt(sequence),
            module_title_en: module_title_en,
            module_title_tc: module_title_tc,
            module_title_sc: module_title_sc,
            module_descriptions_en: module_description_en,
            module_descriptions_tc: module_description_tc,
            module_descriptions_sc: module_description_sc,
        };

        getApiClient()
            .post("/user/addOrEditModule", params)
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("/user/addOrEditModule encounters error");
                if(error?.response?.statusText === 'Payload Too Large') {
                    alert('Your input text too long, maximum 5000 characters.');
                } else {
                    alert(error?.response?.data?.message);
                }
            });
    };

    return <>
        <ShadowContainer>
        <form onSubmit={handleSubmit}>
        <div className="mb-3 row">
            <div className="text-secondary h1 my-4">Create a new module</div>
            <label className="text-5c5c5c col-sm-2 col-form-label">
            Module Sequence<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
            <input
                type="number"
                className="form-control"
                placeholder={"Module Sequence"}
                name={"module_sequence"}
                value={sequence}
                onChange={(e) => set_sequence(e.target.value)}
                required={true}
                min="1"
                max="999"
            />
            </div>
        </div>
        <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
            Module Title (ENG)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
            <input
                type="text"
                className="form-control"
                placeholder={"Module Title (ENG)"}
                name={"module_title_en"}
                required={true}
                value={module_title_en}
                onChange={(e) => set_module_title_en(e.target.value)}
                maxlength="100"
            />
            </div>
        </div>
        <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
            Module Title (繁)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
            <input
                type="text"
                className="form-control"
                placeholder={"Module Title (繁)"}
                name={"module_title_tc"}
                required={true}
                value={module_title_tc}
                onChange={(e) => set_module_title_tc(e.target.value)}
                maxlength="50"
            />
            </div>
        </div>
        <div className="mb-3 row">
            <label className="text-5c5c5c col-sm-2 col-form-label">
            Module Title (简)<span className={"text-danger"}>*</span>
            </label>
            <div className="col-sm-4">
            <input
                type="text"
                className="form-control"
                placeholder={"Module Title (简)"}
                name={"module_title_sc"}
                required={true}
                value={module_title_sc}
                onChange={(e) => set_module_title_sc(e.target.value)}
                maxlength="50"
            />
            </div>
        </div>
        <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
            Module Descriptions (ENG)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
            <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"module_descriptions_en"}
                required={true}
                value={module_description_en}
                onChange={(e) => set_module_description_en(e.target.value)}
            />
            </div>
        </div>
        <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
            Module Descriptions (繁)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
            <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"module_descriptions_tc"}
                required={true}
                value={module_description_tc}
                onChange={(e) => set_module_description_tc(e.target.value)}
            />
            </div>
        </div>
        <div className="mb-3 col-sm-6">
            <label className="text-5c5c5c col-form-label">
            Module Descriptions (简)<span className={"text-danger"}>*</span>
            </label>
            <div className="">
            <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                name={"module_descriptions_sc"}
                required={true}
                value={module_description_sc}
                onChange={(e) => set_module_description_sc(e.target.value)}
            />
            </div>
        </div>
        
        <div className={"d-flex"}>
            <div className={"me-3"}>
            <PrimaryButton
                type={"submit"}
            >
                Create Module
            </PrimaryButton>
            </div>
            <div className={"me-3"}>
            <Button variant={"secondary"} type={"button"} onClick={()=>props.setCreatingNewModule(false)}>
                Cancel
            </Button>
            </div>
        </div>
        </form>
        </ShadowContainer>
        <div style={{height:100}}></div>
    </>

}