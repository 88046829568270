import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { removeTokens } from "../util/auth";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from 'react-i18next';

export default function ProfileLayout(props) {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  let navigate = useNavigate();

  const onLogout = () => {
    removeTokens();
    navigate("/login");
  };

  return (
    <div>
      <div className={"px-5 text-right d-flex justify-content-end"}>
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            className={"border-eb6201 bg-eb6201 text-white rounded-0 py-3 px-4"}
          >
            {t('Welcome back')} {user.Username}!
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={onLogout}>{t('Log out')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ background: "#004A87" }} className={"py-3 px-5"}>
        <Link to={"/"} className={"text-white text-decoration-none"}>
          &lt; {t('Back to Home')}
        </Link>
      </div>

      {props.children}
    </div>
  );
}
