import React from "react";
import { useTranslation } from 'react-i18next';

export default function Salutation(){
    const { t } = useTranslation();

    return <>
        <option value="" default disabled selected>--</option>
        <option value="Mr.">{t('Mr.')}</option>
        <option value="Mrs.">{t('Mrs.')}</option>
        <option value="Ms.">{t('Ms.')}</option>
        <option value="Miss">{t('Miss')}</option>
    </>;
}