import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Section from "../components/Section";
import Paragraph from "../components/Paragraph";

export default function Maintenance() {

    return (
        <div>
            <img src="/assets/images/logo.png" 
                height={100} 
                alt="logo" 
                style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: '20px'}} 
            />

            <div style={{paddingLeft: '50px', paddingRight: '50px'}}>
                {/** Simplified Chinese */}
                <h2 style={{textAlign: 'center', paddingTop: '40px'}}>
                    重要提示：系统维护通知
                </h2>
                <h2 style={{textAlign: 'center'}}>
                    我们将于2023年5月25日 早上11：00 至下午1时进行系统保养。系统保养预计持续2小时， 期间平台将暂停运作。不便之处， 敬请原谅， 感谢你的理解和支持！
                </h2>
                
                {/** English */}
                <h2 style={{textAlign: 'center', paddingTop: '40px'}}>
                    Important notice: system maintenance
                </h2>
                <h2 style={{textAlign: 'center'}}>
                    Please note that due to system maintenance, our platform will be temporarily suspended from 11:00am to 1:00 pm on 25th May. We apologise for any inconvenience caused. Thank you for your understanding and support!
                </h2>
                
                {/** Traditional Chinese */}
                <h2 style={{textAlign: 'center', paddingTop: '40px'}}>
                    重要提示：系統維護通知
                </h2>
                <h2 style={{textAlign: 'center'}}>
                    我們將於2023年5月25日 早上11：00 至下午1時進行系統保養。系統保養預計持續2小時， 期間平台將暫停運作。不便之處， 敬請原諒， 感謝你的理解和支持！
                </h2>
            </div>
        </div>
    );
}