import Paragraph from "./Paragraph";
import LineLimiter from "./LineLimiter";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CourseItemHC(props) {
  const { t } = useTranslation();

  const should_go_to_hardcode_page = props.courseId === 1 || props.courseId === 2 || props.courseId === 3;

  return (
    <>
      <img
        alt="banner"
        src={props.imageUrl}
        style={{ aspectRatio: "137 / 86", objectFit: "cover" }}
        className="img-fluid w-100"
      />
      <div
        style={{ fontSize: "16px", lineHeight: "24px", marginTop: "20px" }}
        className="fw-900"
      >
        {props.title}
      </div>
      <div className="mb-3">
        <TrainerLink trainer_link={props.trainer_link} trainer={props.trainer}></TrainerLink>
      </div>
      <LineLimiter>
        {props.description}
      </LineLimiter>
      <div className="mt-auto">
        <Link
          to={should_go_to_hardcode_page ? ("/courses/show-data/" + props.courseId) : '/coming-soon'}
          style={{ color: "#266599", textDecoration: "none" }}
        >
          {t('EXPLORE MORE')} &gt;
        </Link>
      </div>
    </>
  );
}

function TrainerLink(props) {
  if (!props.trainer_link) {
    return <a style={{ color: "#212529", textDecoration: "none" }}>
      {props.trainer}
    </a>
  }
  else {
    return <a href={props.trainer_link} style={{ color: "#266599", textDecoration: "none" }}>
      {props.trainer}
    </a>
  }
}