import Layout from "../components/Layout";
import React from "react";
import Section from "../components/Section";
import H2 from "../components/H2";
import H5 from "../components/H5";
import Banner from "../components/Banner";
import {Container, Row, Col} from "react-bootstrap";
import MembershipCard from "../components/MembershipCard";
import {useTranslation} from "react-i18next";

export default function Membership() {
  const { t } = useTranslation();

  const memberships = [
    {
      id: 1,
      title: t("Individual Membership"),
      description:
        t("Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan."),
      backgroundColor: "#fbdfcc",
      exploreLink:"#",
      applyLink:"/signup-individual",
      applyLinkText: t("Apply Now"),
      applyLinkBG: "#eb6201",
      isClickable: "yes"
    },
    {
      id: 2,
      title: t("Organization Membership"),
      description:
        t("Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report."),
      backgroundColor: "#fbdfcc",
      exploreLink:"#",
      applyLink:"/signup-organization",
      applyLinkText: t("Apply Now"),
      applyLinkBG: "#eb6201",
      isClickable: "yes"
    },
    {
      id: 3,
      title: t("Trainer/Academy Membership"),
      description:
        t("Can through this platform to share & promote their training course, also this platform is providing the participant analysis report."),
      backgroundColor: "#fbdfcc",
      exploreLink:"#",
      applyLink:"/signup-trainer",
      applyLinkText: t("Apply Now"),
      applyLinkBG: "#eb6201",
      isClickable: "yes"
    },
    {
      id: 4,
      title: t("Association Membership"),
      description:
        t("Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report."),
      backgroundColor: "#fbdfcc",
      exploreLink:"#",
      applyLink:"/signup-association",
      applyLinkText: t("Apply Now"),
      applyLinkBG: "#eb6201",
      isClickable: "yes"
    },
  ];

  return (
    <Layout>
      <Banner backgroundImage="/assets/images/membership-banner.png">
        {t('Membership')}
      </Banner>
      <Section>
        <Container
          className="max-width-1304"
          style={{
            backgroundImage:
              "url(/assets/images/home_background_insights_1.png)",
            backgroundPosition: "95% 50%",
            backgroundSize: "300px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
          }}
        >
          <Row className="align-items-center mb-3">
            <Col xs={12} md={true}>
              <div className={ "frontend-page-mobile-container" } style={{ paddingRight: 25 }}>
                <img
                  className={"img-fluid"}
                  alt="banner"
                  src={"/assets/images/membership-hero-banner.png"}
                />
              </div>
            </Col>
            <Col>
              <div className={ "frontend-page-mobile-container" } style={{ paddingLeft: 24 }}>
                <H2 style={{ fontSize: 40 }} className={"mt-0"}>
                  {t('Lightshare Membership')}
                </H2>
                <H5 style={{ fontSize: 18 }} className={"mb-4"}>
                  {t('Lightshare platform is offering four different finds of memberships to our society, which included')}
                </H5>
                <p style={{ fontSize: 16 }}>
                  {t('Membership_Content1')}
                  <span className={"text-eb6201"}>{t('Individual member')}</span>{t(" ")}
                  {t('is supporting you to enhance your personal knowledge & social network,')}{t(" ")}
                  <span className={"text-eb6201"}>{t('Organization membership')}</span>{t(" ")}
                  {t('is supporting all kind of organization to enhance their staff competence and knowledge also the organization can through this platform to share their knowledge and technology to the potential partner.')}{t(" ")}
                  <span className={"text-eb6201"}>
                    {t('Trainer & Academy membership')}
                  </span>{t(" ")}
                  {t('is supporting the individual trainer or academy through the lightshare platform to reach their potential client. It is low-cost tools to enhancing their business performance during the pandemic period.')}{t(" ")}
                  <span className={"text-eb6201"}>{t('Association membership')}</span>{t(" ")}{t('is going to support the industry association to share their industry news or knowledge to the stakeholder.')}
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="max-width-1304" style={{ paddingTop: 80 }}>
          <Row className={"gy-3"}>
            {memberships.map((membership) => (
              <MembershipCard
                membership={membership}
              />
            ))}
          </Row>
        </Container>
      </Section>
      <div className="d-none" style={{ backgroundColor: "#e1e9f1" }}>
        <Container className="max-width-1304" style={{ marginTop: 50 }}>
          <Row className="justify-content-center">
            <Col xs={10} md={6}>
              <iframe
                src="https://www.youtube.com/embed/2XQZQR477fg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ aspectRatio: "16 / 9", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
