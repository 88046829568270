import React from "react";
import ShadowContainer from "../../ShadowContainer";
import UploadBox from "../../UploadBox";
import PreviewBox from "../../PreviewBox";

export default function CmsEditProfAboutUs(props) {
    return (
        <ShadowContainer>
            <div className="mb-3">
              <label className="text-5c5c5c col-form-label">
                About Us Description
              </label>
              <div className="">
                <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  value={props.about_desc_en}
                  onChange={(e)=> props.set_about_desc_en(e.target.value) }
                  maxlength="5000"
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="text-5c5c5c col-form-label">
                About Us Description (繁)
              </label>
              <div className="">
                <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  value={props.about_desc_tc}
                  onChange={(e)=> props.set_about_desc_tc(e.target.value) }
                  maxlength="5000"
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="text-5c5c5c col-form-label">
                About Us Description (简)
              </label>
              <div className="">
                <textarea
                  className="form-control"
                  rows="5"
                  style={{ resize: "none" }}
                  value={props.about_desc_sc}
                  onChange={(e)=> props.set_about_desc_sc(e.target.value) }
                  maxlength="5000"
                />
              </div>
            </div>


            <div className="mb-3 row">
                <div className="col-sm-6">
                    <div className={"text-5c5c5c mb-3"}>{props?.role === "Organization" ? "Company Photo" : props?.role === "Trainer/Academy" ? "Trainer/Academy Photo" : props?.role === "Association" ? "Association Photo" : "Photo"}</div>
                    <UploadBox
                        onUploaded={(api_responses) => { 
                          props?.role === "Organization" 
                          ? 
                          props?.set_company_photo(api_responses?.[0]?.urls?.[0]) 
                          :
                          props?.role === "Trainer/Academy"
                          ?
                          props?.set_trainer_photo(api_responses?.[0]?.urls?.[0])
                          :
                          props?.set_association_photo(api_responses?.[0]?.urls?.[0])
                        }}
                        width_ratio={3}
                        height_ratio={2}
                        tolerance={0.1}
                        allowImageEdit
                    />
                </div>
            </div>
            {
                (props?.company_photo || props?.trainer_photo || props?.association_photo)
                &&
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <PreviewBox 
                          url={
                            props?.role === "Organization" 
                            ? 
                            props?.company_photo 
                            : 
                            props?.role === "Trainer/Academy" 
                            ? 
                            props?.trainer_photo 
                            : 
                            props?.role === "Association" 
                            ? 
                            props?.association_photo 
                            : 
                            ''
                          } 
                          onDelete={() => { 
                            props?.role === "Organization" 
                            ? 
                            props?.set_company_photo(null) 
                            : 
                            props?.role === "Trainer/Academy" 
                            ? 
                            props?.set_trainer_photo(null) 
                            : 
                            props?.set_association_photo(null)
                          }} />
                    </div>
                </div>
            }
          </ShadowContainer>
    );
}
