import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import ImageCrop from "../components/ImageCrop/ImageCrop";
import { Modal } from "react-bootstrap";
import { first } from "lodash";
import { useTranslation } from 'react-i18next';

export default function UploadBox(props) {
  const { t } = useTranslation();
  
  const role = localStorage.getItem("role");

  const {
    allowImageEdit,
    width_ratio = 1,
    height_ratio = 1
  } = props

  const [is_uploading, set_is_uploading] = useState({
    progress: 100,
  });

  const [imageFileEdit, setImageFileEdit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleUploadFiles = useCallback(async (acceptedFiles) => {
    const api = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}`,
      timeout: 3600000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const endpoint = props?.endpoint ?? `/s3/user`;
    const handleOnUpload = (progressEvent) => {
      set_is_uploading({
        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      });
    };

    const upload_to_s3 = (acceptedFile) => {

      return new Promise((resolutionFunc, rejectionFunc) => {
        const formData = new FormData();
        formData.append("file", acceptedFile);
        api
          .post(endpoint, formData, {
            onUploadProgress: handleOnUpload,
          })
          .then(function (response) {
            resolutionFunc(response.data);
          })
          .catch(function (error) {
            let d = document.querySelector(".cXBrtO span");
            d.innerHTML = t("Upload Failed! Please try again.");
            alert(error?.response?.data?.message ?? error?.message ?? error);

            rejectionFunc(error);
          });
      });
    };

    const responses = [];

    set_is_uploading({
      progress: 0,
    });
    for (const acceptedFile of acceptedFiles) {
      if (acceptedFile.type === 'image/png' || acceptedFile.type === 'image/jpeg') {
        const promise = new Promise((resolve, reject) => {
          const img = document.createElement("img");
          img.src = URL.createObjectURL(acceptedFile);
          img.onload = async () => {
            if (props.width_ratio && props.height_ratio && props.tolerance) {
              const expected_ratio = props.width_ratio / props.height_ratio;
              const actual_ratio = img.width / img.height;
              const difference = Math.abs(expected_ratio - actual_ratio);
              if (difference < props.tolerance) {
                const response = await upload_to_s3(acceptedFile);
                resolve(response);
              } else {
                alert(t('Image ratio must be ') + props.width_ratio + ':' + props.height_ratio);
                reject();
              }
            } else {
              const response = await upload_to_s3(acceptedFile);
              resolve(response);
            }
          };
        });

        try {
          const response = await promise;
          responses.push(response);
        } catch (e) {
        }
      } else if (acceptedFile.type === 'video/mp4') {
        const promise = new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.preload = 'metadata';
          video.src = URL.createObjectURL(acceptedFile);

          video.onloadedmetadata = async () => {
            window.URL.revokeObjectURL(video.src);
            const response = await upload_to_s3(acceptedFile);

            if (props.onUpdatedDuration) {
              const duration = video.duration;
              props.onUpdatedDuration(duration);
            }

            resolve(response);
          };
        });

        try {
          const response = await promise;
          responses.push(response);
        } catch (e) {
        }
      } else {
        const response = await upload_to_s3(acceptedFile);
        responses.push(response);
      }
    }

    if (props.onUploaded) {
      props.onUploaded(responses);
    }

    set_is_uploading({
      progress: 100,
    });
  }, [])
  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (!acceptedFiles) {
      return;
    }
    const firstImageFile = first(acceptedFiles);
    if (allowImageEdit && (firstImageFile.type === 'image/png' || firstImageFile.type === 'image/jpeg')) {
      setImageFileEdit(Object.assign(firstImageFile, {
        preview: URL.createObjectURL(firstImageFile),
      }))
      return
    }
    await handleUploadFiles(acceptedFiles)

  }, []);
  const onDropRejected = useCallback((fileRejections, event) => {
    const messages = [];
    for (const fileRejection of fileRejections) {
      for (const error of fileRejection.errors) {
        messages.push(error.message);
      }
    }
    if(messages.join(', ') === 'File is larger than 629145600 bytes') {
      alert(t('The file size should be less than 600MB'));
    } else if(messages.join(', ') === 'File is larger than 5242880 bytes') {
      alert(t('The file size should be less than 5MB'));
    } else {
      alert(t(messages.join(', ')));
    }
  }, []);

  const handleOnEditComplete = async (file) => {
    setShowEditModal(false)
    await handleUploadFiles([file])
  }

  let maxFiles;
  if (props?.maxFiles === 'unlimited') {
    maxFiles = undefined;
  } else {
    maxFiles = props?.maxFiles ?? 1;
  }

  const maxSize = props?.maxSize ?? 5242880;

  useEffect(() => {
    setShowEditModal(allowImageEdit && Boolean(imageFileEdit))
  }, [imageFileEdit, allowImageEdit])
  const {getRootProps, getInputProps} = useDropzone({
    disabled: props.disabled,
    onDrop,
    onDropRejected,
    accept: props?.accept ?? {
      "image/jpeg": [],
      "image/png": [],
    },
    maxSize: maxSize,
    maxFiles: maxFiles,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: "p-3 rounded",
          style: {backgroundColor: "#f5f5f8", border: "1px dotted black"},
        })}
      >
        <input disabled={props.disabled} {...getInputProps()} />

        <div className={props.disabled ? 'o-30 text-center' : 'text-center'}>
          {
            is_uploading.progress !== 100
              ?
              <div className={"h5"}>Uploading... {is_uploading.progress}%</div>
              :
              <>
                <div className={"h5"}>{role.includes('Learner') ? t('Drop file here or click to upload.') : 'Drop file here or click to upload.'}</div>
                <div>{role.includes('Learner') ? t('Maximum upload file size:') : 'Maximum upload file size:'} {maxSize / 1024 / 1024}MB</div>
                <div>{role.includes('Learner') ? t('File name must only contain alphanumeric characters and no spaces.') : 'File name must only contain alphanumeric characters and no spaces.'}</div>
                {
                  maxSize === (600*1024*1024)
                  ?
                  <div>Recommended video resolution: 720p at 30 FPS.  If a higher resolution video is uploaded, the viewers may experience video lagging.</div>
                  :
                  ''
                }
                {
                  maxSize !== (600*1024*1024) && allowImageEdit
                  ?
                  props.width_ratio === 1 && props.height_ratio === 1
                  ?
                  <div>{role.includes('Learner') ? t('Image aspect ratio: Square 1:1') : 'Image aspect ratio: Square 1:1'}</div>
                  :
                  <div>Image aspect ratio: Rectangle 3:2</div>
                  :
                  ''
                }
              </>
          }
        </div>
      </div>
      <Modal show={showEditModal} onHide={() => {
        setShowEditModal(false)
      }}>
        <Modal.Header closeButton>
          Edit Your Photo
        </Modal.Header>
        <Modal.Body>
          <ImageCrop
            initialImageSource={imageFileEdit}
            onUploadFile={handleOnEditComplete}
            aspectRatio={width_ratio / height_ratio}/>
        </Modal.Body>
      </Modal>
    </>
  );
}
