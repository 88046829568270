import { Link, NavLink, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import CopyRight from "./CopyRight";
import { useTranslation } from 'react-i18next';
import Dropdown from "react-bootstrap/Dropdown";
import { getApiClient, getTokens, removeTokens } from "../util/auth";
import AppVersion from "./Cms/AppVersion";
import NavbarEnvNotice from "./NavbarEnvNotice";
import CURRENT_LANGUAGE from '../getCurrentLanguage';  //for i18n to display backend data with user clicked language

export default function Layout(props) {
  const { i18n, t } = useTranslation();

  let navigate = useNavigate();

  const onLogout = () => {
    removeTokens();
    navigate("/login");
  };

  const [showModel, setShowModel] = useState(false);
  const [user, set_user] = useState();
  const handleCloseModel = () => setShowModel(false);
  const getProfileRoute = (role) => {
    // Navigate to different pages depending on user.Role
    switch (role) {
      case "Individual Learner":
        return "/profile";
      case "Organization Learner":
        return "/profile-company";
      case "Organization":
        return "/cms/profile";
      case "Trainer/Academy":
        return "/cms/trainer-profile";
      case "Association Learner":
        return "/profile-association";
      case "Association":
        return "/cms/association-profile";
      case "Admin":
        return "/cms/admin/membership/viewUsers"
      default:
        return "/login";
    }
  };

  useEffect(() => {
    if (getTokens()) {
      getApiClient()
        .get("/user/viewProfile")
        .then((response) => {
          set_user(response.data?.user);
        })
        .catch((error) => {
          removeTokens();
          alert(t(error.response.data.message ?? error.message ?? error));
        });
    }
  }, []);

  return (
    <div>
      <Modal
        show={showModel}
        onHide={handleCloseModel}
        fullscreen={true}
      >
        <Modal.Header closeButton className={"bg-eb6201 border-0"}></Modal.Header>
        <Modal.Body className={"bg-eb6201"}>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/"
              >
                {t('Home')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/insights-and-trends"
              >
                {t('Insights and Trends')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/courses"
              >
                {t('Courses')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/trainer-academy"
              >
                {t('Trainer / Academy')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/association"
              >
                {t('Association')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/membership"
              >
                {t('Membership')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/about-us"
              >
                {t('About Us')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/personnel-registration-service"
              >
                {t('Personnel Registration')}
              </NavLink>
            </li>
            <li className="nav-item d-flex align-items-center px-2 justify-content-center">
              <NavLink
                className="nav-link text-white"
                to="/contact-us"
              >
                {t('Contact Us')}
              </NavLink>
            </li>
            {process.env.REACT_APP_API_HOST !== 'http://uatls.hkqaa.org:8000' &&
              <li className="nav-item d-flex align-items-center px-2 justify-content-center">
                <span
                  style={{ paddingTop: "17px", paddingBottom: "17px" }}
                  className="nav-link text-white"
                >
                  <button 
                    type="button" 
                    className={CURRENT_LANGUAGE === "EN" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                    onClick={(e) => { 
                      e.preventDefault();
                      i18n.changeLanguage("EN"); 
                      localStorage.setItem('i18nLanguage','EN'); 
                      window.location.reload(); 
                    }}
                  >
                    EN
                  </button>
                  <div className={CURRENT_LANGUAGE === "EN" ? "text-hidden text-inline" : "text-inline"} >
                  ｜
                  </div>
                  <button 
                    type="button" 
                    className={CURRENT_LANGUAGE === "TC" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                    onClick={(e) => { 
                      e.preventDefault();
                      i18n.changeLanguage("TC"); 
                      localStorage.setItem('i18nLanguage','TC'); 
                      window.location.reload(); 
                    }}
                  >
                    繁
                  </button>
                  <div className={CURRENT_LANGUAGE === "SC" || CURRENT_LANGUAGE === "TC" ? " text-hidden text-inline" : " text-inline"} >
                    ｜
                  </div>
                  <button 
                    type="button" 
                    className={CURRENT_LANGUAGE === "SC" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                    onClick={(e) => { 
                      e.preventDefault();
                      i18n.changeLanguage("SC"); 
                      localStorage.setItem('i18nLanguage','SC'); 
                      window.location.reload(); 
                    }}
                  >
                    簡
                  </button>
                </span>
              </li>
            }
          </ul>
        </Modal.Body>
      </Modal>

      <div className="bg-004a87 d-none d-lg-block">
        <nav className="navbar navbar-expand-lg navbar-light py-0">
          <Container className="max-width-1304">
            <div className="collapse navbar-collapse d-flex justify-content-between">
              <form>
                <div className="input-group">
                  {/* <span
                    className="input-group-text bg-white border-end-0"
                    id="search"
                  >
                    <i className="bi bi-search" />
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0"
                    placeholder={t("Search")}
                  /> */}
                  {/* <AppVersion darkBackground={true} /> */}

                </div>
              </form>
              <NavbarEnvNotice />
              <ul className="navbar-nav  mb-2 mb-lg-0 font-size-12px fw-bold">
                <li className="nav-item px-2">
                  <Link
                    style={{
                      paddingTop: "17px",
                      paddingBottom: "17px",
                      width: 130,
                    }}
                    className="nav-link text-white text-center"
                    to="/contact-us"
                  >
                    {t('Contact Us')}
                  </Link>
                </li>
                {!user && (
                  <li
                    className="nav-item px-2"
                    style={{ backgroundColor: "#4F89AE" }}
                  >
                    <NavLink
                      style={{
                        paddingTop: "17px",
                        paddingBottom: "17px",
                        width: 130,
                      }}
                      className="nav-link text-white text-center"
                      to="/signup"
                    >
                      {t('Sign Up')}
                    </NavLink>
                  </li>
                )}
                <li
                  className="nav-item px-2"
                  style={{ backgroundColor: "#eb6201" }}
                >
                  <NavLink
                    style={{
                      paddingTop: "17px",
                      paddingBottom: "17px",
                      minWidth: 130,
                    }}
                    className="nav-link text-white text-center"
                    to={getProfileRoute(user?.Role)}
                  >
                    {t(user?.Username ? user?.Username : "Log In")}
                  </NavLink>
                </li>
                {process.env.REACT_APP_API_HOST !== 'http://uatls.hkqaa.org:8000' &&
                  <li className="nav-item px-2">
                    <span
                      style={{ paddingTop: "17px", paddingBottom: "17px" }}
                      className="nav-link text-white"
                    >
                      <button 
                        type="button" 
                        className={CURRENT_LANGUAGE === "EN" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                        onClick={(e) => { 
                          e.preventDefault();
                          i18n.changeLanguage("EN"); 
                          localStorage.setItem('i18nLanguage','EN'); 
                          window.location.reload(); 
                        }}
                      >
                        EN
                      </button>
                      <div className={CURRENT_LANGUAGE === "EN" ? "text-hidden text-inline" : "text-inline"}>
                      ｜
                      </div>
                      <button 
                        type="button" 
                        className={CURRENT_LANGUAGE === "TC" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                        onClick={(e) => { 
                          e.preventDefault();
                          i18n.changeLanguage("TC"); 
                          localStorage.setItem('i18nLanguage','TC'); 
                          window.location.reload(); 
                        }}
                      >
                        繁
                      </button>
                      <div className={CURRENT_LANGUAGE === "SC" || CURRENT_LANGUAGE === "TC" ? " text-hidden text-inline" : " text-inline"} >
                      ｜
                      </div>
                      <button 
                        type="button" 
                        className={CURRENT_LANGUAGE === "SC" ? "text-hidden text-decoration-none btn p-0 font-size-12px" : "text-reset text-decoration-none btn p-0 font-size-12px"} 
                        onClick={(e) => { 
                          e.preventDefault();
                          i18n.changeLanguage("SC"); 
                          localStorage.setItem('i18nLanguage','SC'); 
                          window.location.reload(); 
                        }}
                      >
                        簡
                      </button>
                    </span>
                  </li>
                }
              </ul>
            </div>
          </Container>
        </nav>
      </div>
      <Navbar bg="white" expand="lg" className={"py-10px d-none d-lg-flex"}>
        <Container className="max-width-1304">
          <Link className="navbar-brand p-0" to="/">
            <img src="/assets/images/logo.png" height={50} alt="logo" />
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/insights-and-trends"
                >
                  {t('Insights and Trends')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/courses"
                >
                  {t('Courses')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/trainer-academy"
                >
                  {t('Trainer / Academy')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/association"
                >
                  {t('Association')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/membership"
                >
                  {t('Membership')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/about-us"
                >
                  {t('About Us')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <NavLink
                  style={{ color: "#222222" }}
                  className="nav-link"
                  to="/personnel-registration-service"
                >
                  {t('Personnel Registration')}
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center px-2">
                <a className="nav-link" href={t('LinkToHKQAA')} target="_blank" rel="noreferrer">
                  <img alt="" src="/assets/images/hkqaa.png" className="img-fluid" style={{
                    height: "50px",
                    objectFit: "contain"
                  }} />
                </a>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={"d-lg-none bg-004a87"} >
        <Container>
          <Row className={"gx-0"}>
            <Col className={"pe-3 d-flex align-items-center"}>
              <form>
                <div className="input-group">
                  {/* <span
                    className="input-group-text bg-white border-end-0"
                    id="search"
                  >
                    <i className="bi bi-search" />
                  </span>
                  <input
                    type="text"
                    className="form-control border-start-0"
                    placeholder={t("Search")}
                  /> */}
                </div>
              </form>
            </Col>
            <Col><NavbarEnvNotice /></Col>
            {!user ? (
              <><Col xs={"auto"} className={"d-flex align-items-stretch"}>
                <Link to={"/signup"} style={{height: "58px", padding: "17px",background: '#4f89ae'}} className={"d-block text-white text-decoration-none "}>{t('Sign Up')}</Link>
              </Col><Col xs={"auto"} className={"d-flex align-items-stretch"}>
                  <Link to={"/login"} style={{height: "58px",padding: "17px" }} className={"d-block text-white bg-eb6201 text-decoration-none "}>{t('Login')}</Link>
                </Col></>
            ) : (
              <Col xs={"auto"} className="d-flex align-items-stretch col-auto">
                <NavLink
                  style={{
                    paddingTop: "17px",
                    paddingBottom: "17px",
                    width: 130,
                    textDecoration: "none",
                    height: "58px", 
                  }}
                  className={"d-block d-sm-none border-eb6201 bg-eb6201 text-white rounded-0 py-3 px-4 text-center"}
                  to={getProfileRoute(user?.Role)}
                >{user?.Username.length>10?
                  t(user?.Username ? user?.Username.substring(0, 10)+"..." : "Log In"):
                  t(user?.Username ? user?.Username : "Log In")
                }
                </NavLink>
                <NavLink
                    style={{
                      paddingTop: "17px",
                      paddingBottom: "17px",
                      minWidth: 130,
                    }}
                    className="d-none d-md-block d-sm-block d-lg-none bg-eb6201 nav-link text-white text-center"
                    to={getProfileRoute(user?.Role)}
                  >
                    {t(user?.Username ? user?.Username : "Log In")}
                  </NavLink>
              </Col>
            )
            }

            <Col xs={"auto"} className={"d-flex align-items-stretch navbar-dark"}>
              <button className="navbar-toggler border-0" type="button" onClick={() => {
                setShowModel(true);
              }}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={"d-lg-none"}>
        <Container>
          <Row className={"py-4"}>
            <Col className={"text-end"}>
              <Link to="/">
                <img alt="logo"
                  src="/assets/images/logo.png"
                  className="img-fluid"
                  style={{ height: 50 }}
                />
              </Link>
            </Col>
            <Col className={"text-start"}>
              <a className="nav-link" href={t('LinkToHKQAA')} target="_blank" rel="noreferrer" style={{
                padding: "0"
              }}>
                <img alt="hkqaa logo"
                  src="/assets/images/hkqaa.png"
                  className="img-fluid"
                  style={{ height: 50 }} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      {props.children}

      <div style={{ backgroundColor: "#EBEBEB", color: "#767979" }}>
        <Container className="max-width-1304 py-5">
          <Row>
            <Col xs={12} md={'auto'}>
              <Row className="align-items-center h-100">
                <Col xs={6}>
                  <Link to="/">
                    <img alt="logo" src="/assets/images/logo.png" className="img-fluid" />
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col className="footer-menu" xs={12} md={2}>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/insights-and-trends"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Insights and Trends')}
                </Link>
              </div>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/courses"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Courses')}
                </Link>
              </div>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/trainer-academy"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Trainer / Academy')}
                </Link>
              </div>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/association"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Association')}
                </Link>
              </div>
            </Col>
            <Col className="footer-menu" xs={12} md={2}>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/membership"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Membership')}
                </Link>
              </div>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/about-us"
                  className={"text-reset text-decoration-none"}
                >
                  {t('About Us')}
                </Link>
              </div>
              <div style={{ marginBottom: "30px", fontSize: "14px" }}>
                <Link
                  to="/personnel-registration-service"
                  className={"text-reset text-decoration-none"}
                >
                  {t('Personnel Registration')}
                </Link>
              </div>
            </Col>
            <Col className="footer-contact" style={{ fontSize: "14px" }}>
              <p className={"fw-bold"}>
                <Link
                  to={"/contact-us"}
                  className={"text-reset text-decoration-none"}
                >
                  {t('Contact Us')}
                </Link>
              </p>
              <p>{t('Tel')} : (852) 2202 9111</p>
              <p>
                {t('Address: 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong')}
              </p>
              <p>{t('Email')} : <a href="mailto:lightshare@hkqaa.org">lightshare@hkqaa.org</a></p>
              <p className="footer-subscribe-p">
                <Link
                  to="/subscribe-newsletter"
                  style={{ fontSize: 18 }}
                  className={"text-eb6201 fw-bold text-decoration-none"}
                >
                  {t('Subscribe our news')} &gt;
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        className="max-width-1304 py-2"
        style={{ fontSize: "14px", color: "#767979" }}
      >
        <Row className="footer-container">
          <Col xs={"auto"} className="footer-logo d-flex flex-column justify-content-center">
            <a className="nav-link" href={t('LinkToHKQAA')} target="_blank" rel="noreferrer" style={{
              padding: "0"
            }}>
              <img alt="" src="/assets/images/hkqaa.png" className="img-fluid" style={{
                height: "50px"
              }} />
            </a>
          </Col>

          <CopyRight></CopyRight>

          <Col xs={"auto"} className="footer-links d-flex flex-column justify-content-center">
            <div>
              <span>
                <Link
                  to={"/terms-and-condition"}
                  className={"text-reset text-decoration-none"}
                >
                  {t('Terms & Condition')}
                </Link>
                <span className="d-none d-md-inline-block">　　|　　</span>
              </span>
              <span>

                <Link
                  to={"/privacy-policy"}
                  className={"text-reset text-decoration-none"}
                >
                  {t('Privacy Policy')}
                </Link>

              </span>
              {/* <span><span className="d-none d-md-inline-block">　　|　　</span> <Link
                to={"/faq"}
                className={"text-reset text-decoration-none"}
              >
                {t('FAQs')}
              </Link></span> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
