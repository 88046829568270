import {Link, useParams} from "react-router-dom";
import React from "react";

export default function CourseTabNavigation(props) {

  //this is course ID
  const params = useParams();
  const course_id = params.course_id ?? '';
  const module_id = params.module_id ?? '';
  const class_id = params.class_id ?? '';
  const quiz_id = params.quiz_id ?? '';
  const evaform_id = params.evaform_id ?? '';

  const activeNavItem = props?.activeNavItem;

  const readOnly = props.readOnly;
  const isEditing = props.isEditing;

  const role = localStorage.getItem("role");

  const cssActiveLink = "border-bottom border-width-2 me-4 border-bottom-eb6201 -mb-2px text-eb6201";
  const cssNormalLink = "me-4 text-adb5bd";

  const all_links =
    readOnly?
    {
      course: `/cms/course/${course_id}`,
      module: `/cms/course/${course_id}/modules`,
      class:  `/cms/course/${course_id}/classes`,
      quiz:   `/cms/course/${course_id}/quiz`,
      evaForm:`/cms/course/${course_id}/evaluation-form`,
      admin_course: `/cms/admin/membership/course/${course_id}`,
      admin_module: `/cms/admin/membership/course/${course_id}/modules`,
      admin_class:  `/cms/admin/membership/course/${course_id}/classes`,
      admin_quiz:   `/cms/admin/membership/course/${course_id}/quiz`,
      admin_evaForm:`/cms/admin/membership/course/${course_id}/evaluation-form`
    } :
    isEditing?
    {
      course: `/cms/edit/course/${course_id}`,
      module: `/cms/course/${course_id}/edit/module`,
      class:  `/cms/course/${course_id}/edit/class`,
      quiz:   `/cms/course/${course_id}/edit/quiz`,
      evaForm:`/cms/course/${course_id}/edit/evaluation-form`
    }
    :
    {
      course: `/cms/create/course`,
    }
    ;




  return (
    <div className={"my-3"}>
      <ul
        className={"d-flex p-0 border-bottom border-width-2 flex-flow-column-sm"}
        style={{ listStyle: "none" }}
      >
        <li className={activeNavItem === "course" ? cssActiveLink : cssNormalLink}>
          {role === "Admin"
          ?
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.admin_course}
          >
            Course
          </Link>
          :
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.course}
          >
            Course
          </Link>
          }
        </li>
        <li className={activeNavItem === "module" ? cssActiveLink : cssNormalLink}>
          {role === "Admin"
          ?
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.admin_module}
          >
            Module
          </Link>
          :
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.module}
          >
            Module
          </Link>
          }
        </li>
        <li className={activeNavItem === "class" ? cssActiveLink : cssNormalLink}>
          {role === "Admin"
          ?
          <Link
              className={"text-decoration-none fw-bold text-reset"}
            to={all_links.admin_class}
          >
            Class
          </Link>
          :
          <Link
              className={"text-decoration-none fw-bold text-reset"}
            to={all_links.class}
          >
            Class
          </Link>
          }
        </li>
        <li className={activeNavItem === "quiz" ? cssActiveLink : cssNormalLink}>
          {role === "Admin"
          ?
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.admin_quiz}
          >
            Quiz
          </Link>
          :
          <Link
            className={"text-decoration-none fw-bold text-reset"}
            to={all_links.quiz}
          >
            Quiz
          </Link>
          }
        </li>
        <li className={activeNavItem === "evaluation-form" ? cssActiveLink : cssNormalLink}>
          {role === "Admin"
          ?
          <Link
              className={"text-decoration-none fw-bold text-reset"}
            to={all_links.admin_evaForm}
          >
            Evaluation Form
          </Link>
          :
          <Link
              className={"text-decoration-none fw-bold text-reset"}
            to={all_links.evaForm}
          >
            Evaluation Form
          </Link>
          }
        </li>
      </ul>
    </div>
  );
}
