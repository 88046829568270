import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button} from "react-bootstrap";
import {getApiClient} from "../../util/auth";
import {getTextByCode} from "../../util/lang";
import moment from "moment";

export default function QuizModal (props) {
    const checkbox_class =
        "form-check-input rounded-0 shadow-none bg-eb6201-checked border-eb6201-checked background-image-initial";
    const api = getApiClient();

    const [quiz_result_response, set_quiz_result_response] = useState();

    useEffect(() => {
        const course_id = props?.course?.id ?? props?.course?.CourseId;
        if (!(props?.quiz_id && course_id)) {
            return;
        }
        api.post('/user/viewLearnerQuizResultByCourse', {
            quiz_id: props.quiz_id,
            course_id: course_id,
        })
            .then((response) => {
                set_quiz_result_response(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.quiz_id, props.course]);

    const getQuestionTitle = () => {
        const question_list = quiz_result_response?.quiz_question_answer_list ?? [];

        let dictionary = {};

        for (const question_list_item of question_list) {
            if (!dictionary[question_list_item.quiz_question_id]) {
                dictionary[question_list_item.quiz_question_id] = question_list_item;
            }
        }

        return Object.values(dictionary);
    };

    const getChoices = (quiz_question_id) => {
        const question_list = quiz_result_response?.quiz_question_answer_list ?? [];
        return question_list.filter((question_list_item) => quiz_question_id == question_list_item.quiz_question_id);
    };

    const getCorrectAnswer = (quiz_question_id) => {
        const choices = getChoices(quiz_question_id) ?? [];

        for (const [index, question_list_item] of choices.entries()) {
            if (question_list_item.Is_Correct_Answer === "True") {
                return String.fromCharCode(index + 97).toUpperCase();
            }
        }

        return '';
    };

    const getLearnerQuizAnswer = () => {
        const learner_answers = quiz_result_response?.learner_quiz_answer ?? [];
        return learner_answers.filter((learner_answer) => {
            return learner_answer.UserId == props.learner_id;
        })?.[0];
    };

    const getUserAnswer = (quiz_question_id) => {
        const learner_quiz_answer = getLearnerQuizAnswer();

        const learner_answer_list = JSON.parse(learner_quiz_answer?.Learner_Answers);
        const learner_answer = learner_answer_list.filter((learner_answer_item) => learner_answer_item.quiz_question_id == quiz_question_id)?.[0];
        const user_quiz_question_choice_id = learner_answer.quiz_question_choice_id;
        const user_quiz_question_id = learner_answer.quiz_question_id;

        const choices = getChoices(user_quiz_question_id) ?? [];
        for (const [index, question_list_item] of choices.entries()) {
            if (question_list_item.quiz_question_choice_id == user_quiz_question_choice_id) {
                return String.fromCharCode(index + 97).toUpperCase();
            }
        }

        return '';
    };

    const printModal = async() => {
        let elem = document.getElementsByClassName(`print-QuizContent-${getLearnerQuizAnswer()?.UserId}`);
        let domClone = elem[0].cloneNode(true);

        let $printSection = document.getElementById("printSection");

        if(!$printSection) {
            let $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }

    return (
        <>
            <Modal
                show={props.is_show_modal}
                onHide={() => {
                    props.on_modal_hide();
                }}
                size={"xl"}
                scrollable={true}
            >
                <Modal.Body className={"p-0"}>
                    <div className={`print-QuizContent-${getLearnerQuizAnswer()?.UserId}`}>
                        <Container className={"p-3"}>
                            <Row className={"align-items-center mb-2"}>
                                <Col>
                                    <img src="/assets/images/logo.png" width={175} />
                                </Col>
                                <Col xs={"auto"}>
                                    <Button
                                        className={"bg-eb6201 border-eb6201 hover-eb6201 shadow-none"}
                                        type={"button"}
                                        onClick={printModal}
                                    >
                                        Print
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"fw-bold mb-3"}>
                                    <div>Quiz Result</div>
                                    <div>{"<"}Passing Grade {quiz_result_response?.passing_grade_percentage}%{">"}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className={"mb-2"}>
                                        <Col>Course code</Col>
                                        <Col>{ quiz_result_response?.course_code }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Course owner</Col>
                                        <Col>{ quiz_result_response?.course_owner }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Username</Col>
                                        <Col>{ getLearnerQuizAnswer()?.Username }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>{ getLearnerQuizAnswer()?.Role === "Association Learner" ? "Member name" : "Staff name" }</Col>
                                        <Col>{ getTextByCode(getLearnerQuizAnswer()?.Contact_Person_First_Name, 'EN') } { getTextByCode(getLearnerQuizAnswer()?.Contact_Person_Last_Name, 'EN') }</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className={"mb-2"}>
                                        <Col>Course name</Col>
                                        <Col>{ getTextByCode(props?.course?.Course_Name, 'EN') }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Quiz Date</Col>
                                        <Col>{ getLearnerQuizAnswer()?.Quiz_Date ? moment(getLearnerQuizAnswer()?.Quiz_Date).format('Y-MM-DD') : '' }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>{ getLearnerQuizAnswer()?.Role  === "Association Learner" ? "Member ID" : "Staff ID" }</Col>
                                        <Col>{ getLearnerQuizAnswer()?.Role  === "Association Learner" ? getLearnerQuizAnswer()?.Membership_No : getLearnerQuizAnswer()?.Staff_Id }</Col>
                                    </Row>
                                    <Row className={"mb-2"}>
                                        <Col>Email</Col>
                                        <Col>{ getLearnerQuizAnswer()?.Role  === "Association Learner" ? getLearnerQuizAnswer()?.Association_Email : getLearnerQuizAnswer()?.Role  === "Organization Learner" ? getLearnerQuizAnswer()?.Organization_Email : getLearnerQuizAnswer()?.Personal_Email }</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        <div style={{ background: "rgba(162, 162, 162, 0.1)" }} className={"p-3"}>
                            <div>
                                <Row className={"mb-2"}>
                                    <Col className={"text-eb6201 font-size-20px fw-bold"}>Quiz Mark: {getLearnerQuizAnswer()?.Correct_Percentage}% {getLearnerQuizAnswer()?.Quiz_Result}</Col>
                                </Row>
                                <Row className="quiz-modal-row">
                                    <Col xs={6} className={"fw-bold mb-2"}>Question</Col>
                                    <Col className={"text-center"}>Answer</Col>
                                    <Col className={"text-center"}>A</Col>
                                    <Col className={"text-center"}>B</Col>
                                    <Col className={"text-center"}>C</Col>
                                    <Col className={"text-center"}>D</Col>
                                </Row>

                                {
                                    getQuestionTitle()?.map((question, index) => (
                                        <Row className="quiz-modal-row" key={question.quiz_question_id}>
                                            <Col xs={6}>{index + 1}. {question.Question_Title}</Col>

                                            <Col className={"text-center"}>
                                                { getCorrectAnswer(question.quiz_question_id) }
                                            </Col>

                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={getUserAnswer(question.quiz_question_id) === 'A'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={getUserAnswer(question.quiz_question_id) === 'B'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={getUserAnswer(question.quiz_question_id) === 'C'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className={"d-flex justify-content-center"}>
                                                <div className="form-check">
                                                    <input
                                                        className={checkbox_class}
                                                        type={"checkbox"}
                                                        checked={getUserAnswer(question.quiz_question_id) === 'D'}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </div>

                            <hr />

                            <div style={{background: '#A2A2A2'}} className={"mb-3"}>
                                <Container className={"p-3"}>
                                    <Row className={"text-white"}>
                                        <Col>
                                            Answer List
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                            <Container className={"p-0"}>
                                <Row className={"gy-3"}>
                                    {
                                        getQuestionTitle()?.map((question, index) => (
                                            <Col xs={6} key={question.quiz_question_id}>
                                                <div>{index + 1}. {question.Question_Title}</div>
                                                {
                                                    getChoices(question.quiz_question_id)?.map((question_by_id, choice_loop_index) => {
                                                        return (
                                                            <div key={`${question_by_id}-${choice_loop_index}`}>
                                                                {String.fromCharCode(choice_loop_index + 97)}) {question_by_id.Choice_Title}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
