import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/home";
import InsightsAndTrends from "./routes/insights-and-trends/index";
import InsightsAndTrendsShow from "./routes/insights-and-trends/show";
import PersonnelRegistrationService from "./routes/personnel-registration-service";
import Login from "./routes/login";
import AdminLogin from "./routes/admin";
import Signup from "./routes/signup";
import SignupIndividual from "./routes/signup-individual";
import SignupOrganization from "./routes/signup-organization";
import SignupTrainer from "./routes/signup-trainer";
import SignupAssociation from "./routes/signup-association";
import CourseIndex from "./routes/courses/index";
import CourseShow from "./routes/courses/show";
import CourseShowModule from "./routes/courses/show-module";
import Profile from "./routes/profile";
import ProfileCompany from "./routes/profile-company";
import ProfileAssociation from "./routes/profile-association";
import TrainerShow from "./routes/trainers/show";
import NoPage from "./components/NoPage";
import ProfileCms from "./routes/cms/profile";
import ProfileEditCms from "./routes/cms/profile/edit";
import StaffIndexCms from "./routes/cms/staffs/index";
import StaffCreateCms from "./routes/cms/staffs/addOrEditUser";
import MemberIndexCms from "./routes/cms/members/index";
import MemberCreateCms from "./routes/cms/members/addOrEditUser";
import TrainerProfileCms from "./routes/cms/trainer-profile";
import TrainerProfileEditCms from "./routes/cms/trainer-profile/edit";
import AssociationProfileCms from "./routes/cms/association-profile";
import AssociationProfileEditCms from "./routes/cms/association-profile/edit";
import CourseIndexCms from "./routes/cms/courses/index";
import TrainerCourseIndexCms from "./routes/cms/trainer-courses/index";
import TrainerCourseNewEnrollment from './routes/cms/trainer-courses/newEnrollment';
import TrainerCourseEnrolledLearner from './routes/cms/trainer-courses/enrolledLearner';
import TrainerCourseArchived from './routes/cms/trainer-courses/archived';
import AssociationCourseIndexCms from "./routes/cms/association-courses/index";
import CreateOrEditCourse from "./routes/cms/courses/create";
// import ModulePage from "./routes/cms/courses/edit/module";
import ModulePage from "./routes/cms/courses/edit/moduleWithDraggableTable";
// import CourseClassPage from "./routes/cms/courses/edit/class";
import CourseClassPage from "./routes/cms/courses/edit/classWithDraggableTable";
import CourseQuizPage from "./routes/cms/courses/edit/quiz";
import CourseEvaFormPage from "./routes/cms/courses/edit/evaluation-form";
import TrainerAcademy from "./routes/trainer-academy";
import Association from "./routes/association/index";
import AssociationShow from "./routes/association/show";
import Membership from "./routes/membership";
import ResetPassword from "./routes/reset-password";
import AboutUs from "./routes/about-us";
import ContactUs from "./routes/contact-us";
import Quiz from "./routes/quiz";
import EvaluationForm from "./routes/evaluation-form";
import TermsAndCondition from "./routes/terms-and-condition";
import PrivacyPolicy from "./routes/privacy-policy";
import ProfileCompanyEdit from "./routes/edit/profile-company";
import ProfileEdit from "./routes/edit/profile";
import ProfileAssociationEdit from "./routes/edit/profile-association";
import ViewStaff from "./routes/cms/staffs/viewStaff";
import ViewMember from "./routes/cms/members/viewMember";
import ViewCourse from "./routes/cms/courses/viewCourse";
import AssignedUser from "./routes/cms/staffs/assigned-user";
import ReportIndex from "./routes/cms/reports/index";
import TrainerReportIndex from "./routes/cms/trainer-reports/index";
import AssociationReportIndex from "./routes/cms/association-reports/index";
import AdminMembershipViewUsers from "./routes/cms/admin/membership/viewUsers";
import AdminMembershipViewUser from "./routes/cms/admin/membership/viewUser";
import AdminMembershipViewCourses from "./routes/cms/admin/membership/viewCourses";
import AdminMembershipViewCourse from "./routes/cms/admin/membership/viewCourse";
import AdminMembershipViewModule from "./routes/cms/admin/membership/viewModule";
import AdminMembershipViewClass from "./routes/cms/admin/membership/viewClass";
import AdminMembershipViewQuiz from "./routes/cms/admin/membership/viewQuiz";
import AdminMembershipViewEvaluationForm from "./routes/cms/admin/membership/viewEvaluationForm";
import AdminContentManagementInsightsAndTrends from "./routes/cms/admin/content_management/viewAllInsightsAndTrends";
import AdminContentManagementCreateOrEditInsightsAndTrends from "./routes/cms/admin/content_management/createInsightsAndTrends";
import AdminContentManagementCourses from "./routes/cms/admin/content_management/courses";
import AdminContentManagementTrainerAcademy from "./routes/cms/admin/content_management/trainer_academy";
import AdminContentManagementAssociation from "./routes/cms/admin/content_management/association";
import history from "./util/history";
import './i18n';  //for i18n to display static text with user clicked language
import CURRENT_LANGUAGE from './getCurrentLanguage';  //for i18n to display backend data with user clicked language
import ScrollToTop from "./components/ScrollToTop";
import ForgotUsername from "./routes/forgot-username";
import ForgotPassword from "./routes/forgot-password";
import SubscribeNewsletter from "./routes/subscribe-newsletter";
import FAQ from "./routes/faq";

// for courses hard coded data
import CourseShowHC from "./routes/courses/show-data";
import CourseShowModuleHC from "./routes/courses/show-module-data";
import ComingSoon from "./routes/comingsoon";
import ActivateAccount from "./routes/activate-account";

// Maintenance page
import Maintenance from "./routes/maintenance";

function App() {

  return (
    <Router history={history}>
      <ScrollToTop />
      <Routes>
        {/** Only for show Maintenance in home page */}
        {/* <Route path="/" element={<Maintenance />} /> */}
        
        <Route path="/" element={<Home />} />
        <Route path="/cms/profile" element={<ProfileCms />} />
        <Route path="/cms/profile/edit" element={<ProfileEditCms />} />

        <Route path="/cms/staffs" element={<StaffIndexCms />} />
        <Route path="/cms/staffs/create" element={<StaffCreateCms />} />
        <Route path="/cms/staffs/viewStaff" element={<ViewStaff />} />
        <Route path="/cms/staffs/assigned-user" element={<AssignedUser />} />

        <Route path="/cms/members" element={<MemberIndexCms />} />
        <Route path="/cms/members/create" element={<MemberCreateCms />} />
        <Route path="/cms/members/viewMember" element={<ViewMember />} />

        <Route path="/cms/trainer-profile" element={<TrainerProfileCms />} />
        <Route path="/cms/trainer-profile/edit" element={<TrainerProfileEditCms />} />

        <Route path="/cms/association-profile" element={<AssociationProfileCms />} />
        <Route path="/cms/association-profile/edit" element={<AssociationProfileEditCms />} />

        <Route path="/cms/courses/all" element={<CourseIndexCms />} />

        {/*
          For course / module / class / quiz / evaluation forms
          we need create / edit keyword in the route, or it will be read only mode

          for class we will always display all class from all modules , thus we do not need module id
         */}

        <Route path="/cms/course/:course_id" element={<ViewCourse readOnly={true} />} />
        <Route path="/cms/course/:course_id/modules" element={<ModulePage readOnly={true} />} />
        <Route path="/cms/course/:course_id/classes" element={<CourseClassPage  readOnly={true} />} />
        <Route path="/cms/course/:course_id/quiz" element={<CourseQuizPage readOnly={true} />} />
        <Route path="/cms/course/:course_id/evaluation-form" element={<CourseEvaFormPage readOnly={true} />} />

        <Route path="/cms/edit/course/:course_id" element={<CreateOrEditCourse isEditing={true}/>} />
        <Route path="/cms/course/:course_id/edit/module" element={<ModulePage readOnly={false} isEditing={true} />} />
        <Route path="/cms/course/:course_id/edit/class" element={<CourseClassPage readOnly={false} isEditing={true} />} />
        
        {/* trainer pages */}
        <Route path="/cms/trainer-courses/all" element={<TrainerCourseIndexCms/>} />
        <Route path="/cms/trainer-course/:course_id/new-enrollment/" element={<TrainerCourseNewEnrollment readOnly={true} />} />
        <Route path="/cms/trainer-course/:course_id/enrolled-learner/" element={<TrainerCourseEnrolledLearner  readOnly={true} />} />
        <Route path="/cms/trainer-course/:course_id/archived/" element={<TrainerCourseArchived readOnly={true} />} />

        {/* association pages */}
        <Route path="/cms/association-courses/all" element={<AssociationCourseIndexCms/>} />
        
        {/*
          no need quiz_id because a course only has one quiz
         */}
        <Route path="/cms/course/:course_id/edit/quiz" element={<CourseQuizPage isEditing={true}/>} />

        {/*
          no need evaform_id because a course has one eva form
         */}
        <Route path="/cms/course/:course_id/edit/evaluation-form" element={<CourseEvaFormPage isEditing={true} />} />

        <Route path="/cms/create/course" element={<CreateOrEditCourse />} />
        {/*
            We do not allow creating Module / Class / Quiz / Evaluation Form without first creating a course
            therefore there will be no routes for create these child objects
         */}


        <Route path="/cms/reports" element={<ReportIndex />} />
        <Route path="/cms/trainer-reports" element={<TrainerReportIndex />} />
        <Route path="/cms/association-reports" element={<AssociationReportIndex />} />

        <Route path="/cms/admin/membership/viewUsers" element={<AdminMembershipViewUsers />} />
        <Route path="/cms/admin/membership/viewUser" element={<AdminMembershipViewUser />} />
        <Route path="/cms/admin/membership/viewCourses" element={<AdminMembershipViewCourses />} />
        <Route path="/cms/admin/membership/course/:course_id" element={<AdminMembershipViewCourse readOnly={true} />} />
        <Route path="/cms/admin/membership/course/:course_id/modules" element={<AdminMembershipViewModule readOnly={true} />} />
        <Route path="/cms/admin/membership/course/:course_id/classes" element={<AdminMembershipViewClass readOnly={true} />} />
        <Route path="/cms/admin/membership/course/:course_id/quiz" element={<AdminMembershipViewQuiz readOnly={true} />} />
        <Route path="/cms/admin/membership/course/:course_id/evaluation-form" element={<AdminMembershipViewEvaluationForm readOnly={true} />} />
        <Route path="/cms/admin/content_management/insights_and_trends" element={<AdminContentManagementInsightsAndTrends />} />
        <Route path="/cms/admin/content_management/create/insights_and_trends" element={<AdminContentManagementCreateOrEditInsightsAndTrends />} />
        <Route path="/cms/admin/content_management/edit/insights_and_trends/:insights_and_trends_id" element={<AdminContentManagementCreateOrEditInsightsAndTrends isEditing={true} />} />
        <Route path="/cms/admin/content_management/view/insights_and_trends/:insights_and_trends_id" element={<AdminContentManagementCreateOrEditInsightsAndTrends readOnly={true} />} />
        <Route path="/cms/admin/content_management/courses" element={<AdminContentManagementCourses />} />
        <Route path="/cms/admin/content_management/trainer_academy" element={<AdminContentManagementTrainerAcademy />} />
        <Route path="/cms/admin/content_management/association" element={<AdminContentManagementAssociation />} />

        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-individual" element={<SignupIndividual />} />
        <Route path="/signup-organization" element={<SignupOrganization />} />
        <Route path="/signup-trainer" element={<SignupTrainer />} />
        <Route path="/signup-association" element={<SignupAssociation />} />
        <Route path="/courses" element={<CourseIndex />} />
        <Route path="/courses/show" element={<CourseShow />} />
        <Route path="/courses/show/:id" element={<CourseShow />} />
        <Route path="/courses/show-module" element={<CourseShowModule />} />
        <Route path="/insights-and-trends" element={<InsightsAndTrends />} />
        <Route path="/insights-and-trends/show/:id" element={<InsightsAndTrendsShow />} />
        <Route path="/trainer-academy" element={<TrainerAcademy />} />
        <Route path="/trainers/show/:id" element={<TrainerShow />} />
        <Route
          path="/personnel-registration-service"
          element={<PersonnelRegistrationService />}
        />
        <Route path="/association" element={<Association />} />
        <Route path="/association/show/:id" element={<AssociationShow />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<ProfileEdit />} />
        <Route path="/profile-company" element={<ProfileCompany />} />
        <Route path="/profile-company/edit" element={<ProfileCompanyEdit />} />
        <Route path="/profile-association" element={<ProfileAssociation />} />
        <Route path="/profile-association/edit" element={<ProfileAssociationEdit />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/activate-account" element={<ActivateAccount />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/course/:course_id/quiz" element={<Quiz />} />
        <Route path="/evaluation-form" element={<EvaluationForm />} />
        <Route path="/forgot-username" element={<ForgotUsername />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/subscribe-newsletter" element={<SubscribeNewsletter />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="*" element={<NoPage />} />

        {/* for courses hard coded data */}
        <Route path="/courses/show-data/:id" element={<CourseShowHC />} />
        <Route path="/courses/show-module-data/:course_id/:class_id" element={<CourseShowModuleHC />} />

        <Route path="/coming-soon" element={<ComingSoon />} />

      </Routes>
    </Router>
  );
}

export default App;
